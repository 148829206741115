import React,{useState,useEffect,}from 'react'
import { Table,Space,Button, Tag,} from 'antd';
import Main from '../../cpn/Main'
import {useGwGoodsListQuery,} from '../../store/ReactWebSiteApi';
import WebGoodsAdd from './WebGoodsAdd';
import WebGoodsEdit from './WebGoodsEdit';
import WebGoodsDel from './WebGoodsDel';
import WebGoodsPreview from './WebGoodsPreview';
import WebGoodsAudit from './WebGoodsAudit';
import WebGoodsQuery from './WebGoodsQuery';
function WebGoods() {     
    const [query,setQuery] = useState({
        eowg_e_id:localStorage.getItem('e_id'),
        eowg_sub_type_id:'',
        eowg_type_id:'',
    })
    const {data,isSuccess} = useGwGoodsListQuery(query,{refetchOnMountOrArgChange:1})    
    const [list, setList] = useState();
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                console.log(data);                
                const newArr = data.Result.map(item => {
                    const {eowg_id:key,eowg_name,eowg_sub_name,eowg_content,eowg_uname,eowg_ctime,eowg_update_ctime,eowg_update_uname,eowgt_name,eowgts_name,eowg_image_file,eowg_state,eowg_serial_number} = item;
                    return item = {key,eowg_name,eowg_sub_name,eowg_content,eowg_uname,eowg_ctime,eowg_update_ctime,eowg_update_uname,eowgt_name,eowgts_name,eowg_image_file,eowg_state,eowg_serial_number}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eowg_serial_number;
                    let bTimeString = b.eowg_serial_number;
                    return aTimeString - bTimeString;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    const columns = [                               
        {
            key: 'eowg_serial_number',
            title: '序号',
            dataIndex: 'eowg_serial_number'
        }, 
        {
            key: 'eowg_name',
            title: '标题',
            dataIndex:'eowg_name', 
            render:(text,record) => {
                return <WebGoodsPreview  eowg_name={text} eowg_content={record.eowg_content}/>
            }
        },            
        {
            key: '3',
            title: '类型/序号',
            dataIndex:'eowgt_name', 
            render:(text,record) => {
                return<>{text} / {record.eowgts_name}</>
            }           
        },            
        {
            key: 'num',
            title: '状态',            
            dataIndex: 'eowg_state',
            render:(text) => {
                if(text==='2'){
                    return<Tag color='#e20001'>待上架</Tag>
                }else{
                    return<Tag color='green'>已上架</Tag>
                }
            }
        },
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        }, 
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.eowg_ctime + record.eowg_uname}</div>
                    <div>修改时间：{record.eowg_update_ctime + record.eowg_update_uname}</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space>
                        <WebGoodsEdit eowg_id={record.key} />
                        <WebGoodsDel  eowg_id={record.key} />
                        <WebGoodsAudit eowg_state={record.eowg_state}  eowg_id={record.key} />
                    </Space>
                </>
            }
        },
    ]
    return (
        <Main  t1='商城' n1='/GfIndexMall' t2='官网' n2='/WebIndex' t3='教程' >
            <Space>
                <Button type='primary'>
                    <WebGoodsAdd />
                </Button>
                <WebGoodsQuery query={(e) => {
                        console.log(e);
                        setQuery(prevState=>({
                            ...prevState,
                            eowg_sub_type_id:[e.eowg_sub_type_id],
                            eowg_type_id:[e.eowg_type_id],
                        }))
                    }}/>
            </Space>            
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table>            
        </Main>
    )
}

export default WebGoods