import React,{useState,} from 'react'
import {Modal,Form} from 'antd';
import LedgerStartdate from './LedgerStartdate';
function SetLedgerStartdate() {    
    const [open, setOpen] = useState(false);
    return (
        <>
            <Form.Item>
                <a onClick={() => {setOpen(true)}}>设置</a>
            </Form.Item>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {}}
                footer={null}
                width={800}
            >
                <LedgerStartdate />
            </Modal>
            
        </>
    )
}

export default SetLedgerStartdate