import React from 'react'
import Main from '../../cpn/Main'
import BalanceElseList from '../balance/BalanceElseList'
import { indexFinance } from '../balance/balanceType'
function ElseIncome() {
    return (
        <Main  t1='财务' n1={indexFinance} t2='其他收支' n2='/ElseIndex' t3='其他收入单'>
            <BalanceElseList
                main_type_id='4'
                main_type_name='其他收入单'
            />
        </Main>
    )
}

export default ElseIncome