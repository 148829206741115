import React, { useEffect, useState } from 'react'
import { message, Space, Table, } from 'antd'
import OpFilePriceAdd from './FilePriceAdd'
import { useDijieBaoJiaDanFeiYongMingXiDelMutation, useDijieBaoJiaDanFeiYongMingXiListQuery } from '../../../../store/ReactWebSiteApi'
import { localCostType } from '../customType'
import ModalDel from '../../../../cpn/ModalDel'
import FilePriceEdit from './FilePriceEdit'

function FilePrice(props) {
    const [list,setList] = useState()
    const {data,isSuccess} = useDijieBaoJiaDanFeiYongMingXiListQuery({bjdfymx_bjd_id:props.bjdfymx_bjd_id},{refetchOnFocus:true})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                // console.log(data);
                const arr = data.Result.map((item) => {
                    const {bjdfymx_id:key,bjdfymx_name,bjdfymx_num,bjdfymx_price,bjdfymx_type_id,bjdfymx_ctime,bjdfymx_unit,pingtan} = item
                    return item = {key,bjdfymx_name,bjdfymx_num,bjdfymx_price,bjdfymx_type_id,bjdfymx_ctime,bjdfymx_unit,pingtan}
                })
                setList(arr)
            }else{
                setList([])
            }
        }
    },[data,isSuccess])
    //删除
    const [del] = useDijieBaoJiaDanFeiYongMingXiDelMutation()
    const column = [
        {
            key:'1',
            title:'类型',
            dataIndex:'bjdfymx_type_id',
            render:(text) => {
                let arr = localCostType.filter((item) => item.value===text)
                let type = arr[0].label    
                return type
            },
            width:'120px'
        },
        {
            key:'2',
            title:'名称',
            dataIndex:'bjdfymx_name'
        },
        {
            key:'3',
            title:'单价',
            dataIndex:'bjdfymx_price'
        },                        
        {
            key:'13',
            title:'数量',
            dataIndex:'bjdfymx_num'
        },                        
        {
            key:'4',
            title:'小计',
            render:(_,record) => {
                return <>{record.bjdfymx_price*record.bjdfymx_num + '元'}</>
            }
        },                        
        {
            key:'14',
            title:'人均费用',
            dataIndex:'pingtan',
            render:(text) => {
                const arr = text.map((item) => {
                    let remark = ''
                    if(item.bjdfymxpt_pingtanshuiming){
                        remark = '（' +item.bjdfymxpt_pingtanshuiming+'）'
                    }
                    return <div key={item.bjdfymxpt_egg_id}>{item.bjdfymxpt_egg_name + '：' +item.bjdfymxpt_pingtanjiage  + '元/人' + remark}</div>
                })
                return <>{arr}</>
            }
        },                                        
        {
            key:'5',
            title:'操作',            
            width:'120px',
            render:(_,record) => {
                return<Space>
                    <FilePriceEdit
                        bjdfymx_id={record.key} 
                        bjdfymx_bjd_id={props.bjdfymx_bjd_id}
                    />
                    <ModalDel onOk={() => {
                        del({bjdfymx_id:record.key}).then((res) => {
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                </Space>
            }
        },        
    ]
    if(props.noAdd || props.preview){
        column.pop()
    }
    return (
        <Table 
            title={() => <Space><span style={{fontWeight:'600'}}>费用明细</span> {(!props.noAdd && !props.preview) && <OpFilePriceAdd 
                ltbjd_dept_id={props.ltbjd_dept_id}
                bjdfymx_bjd_id={props.bjdfymx_bjd_id}
            />}</Space>}
            columns={column}
            dataSource={list}
            style={{marginTop:"10px"}}
            pagination={{position:['none']}}
            size={props.customType==='1'?'middle':'small'}
            summary={(pageData) => {
                // console.log(pageData);
                let totalPrice = 0;
                pageData.forEach(({bjdfymx_num, bjdfymx_price }) => {
                    totalPrice += +bjdfymx_num*bjdfymx_price;
                });
                let arr = []
                let newArr  = pageData.map(item=>{
                    let obj
                    obj =item.pingtan.map((item) => {
                        arr.push(item)
                        return []
                    })
                    console.log(obj);
                    return []                    
                });
                console.log(newArr);
                const mergedArray = arr.reduce((accumulator, current) => {
                    const existingItem = accumulator.find(item => item.bjdfymxpt_egg_name === current.bjdfymxpt_egg_name);

                    if (existingItem) {
                        existingItem.bjdfymxpt_pingtanjiage = +existingItem.bjdfymxpt_pingtanjiage
                        existingItem.bjdfymxpt_pingtanjiage += +current.bjdfymxpt_pingtanjiage;
                    } else {
                        accumulator.push({ ...current });
                    }
                    return accumulator;
                  }, []);
                // console.log(mergedArray,'GuigePrice');
                return <Table.Summary>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                <Table.Summary.Cell index={3}>合计</Table.Summary.Cell>
                                <Table.Summary.Cell index={4}>{totalPrice.toFixed(2)}元</Table.Summary.Cell>
                                <Table.Summary.Cell index={5}>
                                    {mergedArray.map((item) => {
                                        const {...obj} = item;
                                        return <div key={obj.bjdfymxpt_egg_id}>{obj.bjdfymxpt_egg_name + '：' + obj.bjdfymxpt_pingtanjiage+'元/人'}</div>
                                    })}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
            }}
        />
       
    )
}

export default FilePrice