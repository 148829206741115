import React from 'react'
import { message,Modal} from 'antd'
import { useAppStationDelMutation } from '../store/ReactWebSiteApi';

function SetPostDel(props) {    
    const {confirm} = Modal; 
    const [submitDel] = useAppStationDelMutation()   
    return (
        <a onClick={() => {
            if(props.num==='0'){
                confirm({
                    title: '确认删除该岗位吗？',
                    okText: '确认',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        submitDel({ess_id:props.ess_id}).then((res) => {
                            if (res.data.Status === 'success') {
                                message.info('删除成功！');
                            }
                            if (res.data.Status === "fail") {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    },
                    onCancel() {
                    console.log('Cancel');
                    },
                })
            }else{
                message.error('该岗位有用户，不能删除！')
            }
        }}>删除</a>
    )
}

export default SetPostDel