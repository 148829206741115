import React,{useState,useEffect} from 'react'
import Main from '../../cpn/Main'
import { Table,  Button,  Space,Modal,message,Input,Cascader, Radio} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useLandmarkDelMutation, useLandmarkListQuery } from '../../store/ReactWebSiteApi';
import { useAreaTreeQuery } from '../../store/ReactWebSiteApi'
function Landmark() {
    //查询数据
    const [queryData, setQueryData] = useState({
        keywords: '',
        action:'search',
    });
     //获取地区树
     const { data:dataArea, isSuccess:isSuccessArea } = useAreaTreeQuery();
     //设置地区树
     const [areaTree, setAreaTree] = useState();
     //加载数据
     useEffect(() => {        
         if (isSuccessArea) {
             try {
                 setAreaTree(dataArea);                
             } catch (error) {
             }
         }
     }, [dataArea, isSuccessArea]) 
    //获取省数据
    const [infoList, setInfoList] = useState();
    //API
    const { data, isSuccess } = useLandmarkListQuery(queryData,{refetchOnMountOrArgChange:100});
    useEffect(() => {
        if (isSuccess) {
            console.log(data);
            if(data.Status==='success'){
                const newArr = data.Result.map((item) => {
                    const { al_id:key,al_name,al_type_id,ctap_name,ctaci_name,ctad_name,al_uname,al_ctime,al_longitude,al_latitude} = item
                    return item = { key,al_name,al_type_id,ctap_name,ctaci_name,ctad_name,al_uname,al_ctime,al_longitude,al_latitude }
                })

                setInfoList(newArr);
            }else{
                setInfoList([])
            }
            
        }
    },[data,isSuccess])
    //设置链接
    const navi = useNavigate();  
    //删除数据
    const [landmarkDel] = useLandmarkDelMutation();
    const { confirm } = Modal;
    //表头
    const columns = [
        {
        title: 'ID',
        dataIndex: 'key',
        width: '3%',
        },        
        {
        title: '地区',
        dataIndex: 'ctap_name',
        width: '15%',
        render: (_, record) => {
            return <>
            {record.ctap_name +'/' +record.ctaci_name +'/' +record.ctad_name}
            </>
        }
        },
        {
        title: '类型',
        dataIndex: 'al_type_id',
        width: '15%',
        render: (text) => {
            let type
            switch (text) {
                case "1":
                type = '飞机场';
                    break;
                case "2":
                type = '火车站';
                    break;
                case "3":
                type = '长途汽车站';
                    break;
                case "4":
                type = '码头';
                    break;
                case "5":
                type = '景区';
                    break;
                case "6":
                type = '购物中心';
                    break;
                default: break;
            }
            return <>{type}</>
        }
        },
        {
        title: '名称',
        dataIndex: 'al_name',
        width:'15%'
        },    
        {
        title: '经纬度',
        dataIndex: 'al_longitude',
        width: '15%',
        render: (text, record) => {
            return <>{text + ',' + record.al_latitude}</>
        }
        }, 
        {
        title: '时间',
        dataIndex: 'al_ctime',
        width: '15%',
        render: (text, record) => {
            return <>
            {text + record.al_uname}
            </>
        }
        }, 
        {
        title: '操作',
        width: '15%',
        render: (record) => (
            <Space size="middle">
            <a onClick={() => {
                navi(`/LandmarkEdit?id=${record.key}`)   
            }}>修改</a>
            <a onClick={() => {
                confirm({
                    title: '确定删除图片吗?',
                    content: '删除后不能恢复',
                    okText: '确认',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    landmarkDel({al_id:record.key}).then((res) => {
                        if (res.data.Status === 'success') {
                            message.info('删除成功！');
                        }
                        if (res.data.Status === "fail") {
                            message.error('删除失败！'+res.data.Message)
                        }
                    })
                    },
                    onCancel() {
                    console.log('Cancel');
                    },
                });
            }}>删除</a>
            </Space>
        )
        }
    ];
    
    //列表分页
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };
  
    return (
        <Main title1='地区' title2='地标'>
            <div style={{display:'flex'}}>
                <Button href='/LandmarkAdd' type='primary'>新增地标</Button>
                <div style={{marginLeft:"10px"}}>
                    <Input
                        placeholder='地标关键字'  
                        onChange={(e) => {setQueryData(prevState=>({...prevState, keywords: e.target.value}))}} 
                    />
                </div>
                <div style={{marginLeft:"10px"}}>
                    <Cascader 
                        options={areaTree} 
                        onChange={(e) => {setQueryData(prevState => ({ ...prevState, al_province_id: e[0],al_city_id:e[1],al_district_id:e[2] }))}} 
                        placeholder="选择省市区" 
                    />
                </div>
                <div style={{marginLeft:"10px"}}>
                    <Radio.Group onChange={(e) => {setQueryData(prevState=>({...prevState,al_type_id:e.target.value}))}}>
                        <Radio value={1}>飞机场</Radio>
                        <Radio value={2}>火车站</Radio>
                        <Radio value={3}>长途汽车站</Radio>
                        <Radio value={4}>码头</Radio>
                        <Radio value={5}>景区</Radio>
                        <Radio value={6}>购物中心</Radio>
                    </Radio.Group>
                </div>

            </div>
            <Table
                columns={columns}
                dataSource={infoList}
                onChange={onChange}
                style={{marginTop:'30px'}}
                pagination={{ showTotal: total => `共${infoList.length}条数据`, showSizeChange: true, showQuickJumper: true }} />
        </Main>
    )
}
export default Landmark