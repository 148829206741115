import React,{useState,useEffect,useRef} from 'react'
import Main from '../../cpn/Main'
import { useEditDistrictMutation,useEditDistrictByIdQuery} from '../../store/ReactWebSiteApi'
import { message,Button, Form, Input, Row,  Col,Select } from 'antd'
import { useNavigate,useParams } from 'react-router-dom'
import { useGetProvinceQuery } from '../../store/ReactWebSiteApi';
import { useGetCityQuery } from '../../store/ReactWebSiteApi';
function DistrictEdit(props) {  
  //获取参数
  const param = useParams();
  const districtId = param.id
  //获取初始值
  const editForm = useRef();
  const { data, isSuccess } = useEditDistrictByIdQuery({ id: districtId })
  const [defaultData, setDefaultData] = useState({
    province: '',
    city:'',
  });
  useEffect(() => {    
    if (isSuccess) {
      setDefaultData(prevState=>({...prevState,
        province: data.Result.ctap_name,
        city:data.Result.ctaci_name,
      }))
      setDistrict(prevState => ({
        ...prevState,
        ctad_province_id: data.Result.ctap_id,
        ctad_city_id: data.Result.ctaci_id,
        ctad_name: data.Result.ctad_name,
        ctad_remark:data.Result.ctad_remark,
      }))
      editForm.current.setFieldsValue({
        'name': data.Result.ctad_name,
        'remark':data.Result.ctad_remark,
      })
    }
  },[data,isSuccess])//获取省份数据
  const [province, setProvince] = useState();
  const [selectProvince, setSelectProvince] = useState();
  //获取城市数据
  const [city, setCity] = useState();
  const [selectCity, setSelectCity] = useState();  
  //API
  const { data: dataProvince, isSuccess: isSuccessProvince } = useGetProvinceQuery();
  const { data: dataCity, isSuccess: isSuccessCity,refetch } = useGetCityQuery({ province_id: selectProvince,action:'search' }, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isSuccessProvince) {
      console.log(dataProvince);
      const newArr = dataProvince.Result.map((item) => {
        const { ctap_id:value, ctap_name:label } = item
        return item = { value, label }
      })
      setProvince(newArr);
    }
    
  }, [dataProvince, isSuccessProvince,])
  
  useEffect(() => {
    if (isSuccessCity) {
      const newArr = dataCity.Result.map((item) => {
        const { ctaci_id:value, ctaci_name:label } = item
        return item = { value, label }
      })
      setCity(newArr);
    }
  },[ dataCity, isSuccessCity,selectProvince])
  
  //绑定查询省
  const handleChange = (e) => {
    setSelectProvince(e);
    refetch();
    setSelectCity(undefined);
    setDistrict(prevState => ({ ...prevState, ctad_province_id: e}));
  }
  //绑定查询城市
  const handleChangeCity = (e) => {    
    setSelectCity(e)
    setDistrict(prevState => ({ ...prevState, ctad_city_id: e}));
  }
  //修改数据
  const [district, setDistrict] = useState({
    id:districtId,
    ctad_province_id: '',
    ctad_city_id:'',
    ctad_name:'',
    ctad_remark: '',
  });
  //绑定区县名称
  const nameInput = (e) => {
    setDistrict(prevState =>({...prevState,ctad_name:e.target.value}))
  }
  //绑定区县备注
  const remarkInput = (e) => {
    setDistrict(prevState =>({...prevState,ctad_remark:e.target.value}))
  }
  //链接
  const navi = useNavigate();
  //上传数据
  const [EditDistrict] = useEditDistrictMutation();
  //提交对话框表单
  const onFinish = () => {
    EditDistrict(district).then(
      (res) => {
        if (res.data.Status === 'success') {
          message.success('修改区县成功！')
          navi('/district');                                    
        }
        if (res.data.Status === 'fail') {
          message.error('修改区县失败！'+res.data.Message)
        }
      })
  }
  const onFinishFailed = () => {}
  return (
    <Main title1='系统' title2='地区' title3='区县' title4='修改区县'>
      <Row>
        <Col span={12}>
          <Form
              name="basic"
              labelCol={{
                  span: 4,
              }}
              wrapperCol={{
                  span: 20,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            autoComplete="off"
            ref={editForm}
          >
            <Form.Item
              label="1、选择城市"              
              required
            >
              <Select
                name="province"
                style={{ width: 120,}}
                onChange={handleChange}
                options={province}
                value={selectProvince}
                placeholder={defaultData.province}
                disabled
              />
              <Select
                name="city"
                style={{ width: 120,}}
                onChange={handleChangeCity}
                options={city}
                value={selectCity}
                placeholder={defaultData.city}
                disabled
              />
            </Form.Item>        
            <Form.Item
                label="2、填写区县名称"
                name="name"
                rules={[{
                    required: true,
                    message: '请填写区县名称！',
                },]}
                wrapperCol={{
                    span: 4,
                }}
            >
                <Input value={district.ctad_name} onChange={nameInput} />
            </Form.Item>
            <Form.Item
                label="3、填写备注"
                name='remark'              
            >
              <Input value={district.ctad_remark} onChange={remarkInput} />
            </Form.Item>                    
            <Form.Item
                wrapperCol={{
                    offset: 2,
                    span: 16,
                }}
            >
              <Button type="primary" htmlType="submit">提交</Button>
            </Form.Item>
          </Form>          
        </Col>
      </Row>
    </Main>
  )
}
export default DistrictEdit