import { Button, Form, Input, message, Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useShangpinTypeUpdateQuery, useShangpinTypeUpdateSaveMutation } from '../../store/ReactWebSiteApi';

function CommodityTypeEdit(props) {
    const [form] = Form.useForm();
    const [open,setOpen] = useState(false)
    const [info,setInfo] = useState()
    const {data,isSuccess} = useShangpinTypeUpdateQuery({spt_id:props.sp_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                editForm.current.setFieldsValue({
                    spt_name:data.Result.spt_name,

                })
                setInfo(prevState=>({...prevState,
                    spt_name:data.Result.spt_name,
                    spt_parent_id:data.Result.spt_parent_id,
                    
                }))
            }
        }
    },[data,isSuccess])
    const editForm = useRef()
    const [submitAdd] = useShangpinTypeUpdateSaveMutation()
    return (
        <>
            <Button onClick={() => {setOpen(true)}}>修改</Button>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false)}}
                width={600}
                title='修改商品类型'
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        spt_update_uid:localStorage.getItem('eu_id'),
                        spt_update_uname:localStorage.getItem('eu_name'),
                        spt_parent_id:info.spt_parent_id,
                        spt_id:props.spt_id,
                        spt_name:info.spt_name,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    labelCol={{span:5}}
                    ref={editForm}
                >
                                    
                    <Form.Item label='权限名称' wrapperCol={{span:10}} name='spt_name'>
                        <Input onChange={(e) => {setInfo(prevState=>({...prevState,spt_name:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default CommodityTypeEdit