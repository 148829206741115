import React ,{useState,}from 'react'
import { Modal,} from 'antd';
function WebGoodsPreview(props) {
    const [open, setOpen] = useState(false);
    const Ok = () => {setOpen(false);};
    const Cancel = () => {setOpen(false);};
    return (
        <>
            <a onClick={() => {setOpen(true)}}>{props.eowg_name}</a> 
            <Modal title='预览' open={open} onOk={Ok} onCancel={Cancel} okText='关闭' width={800}>
                <div>
                    <div style={{padding:'10px'}}>
                        <div style={{fontSize:"24px",fontWeight:'600'}}>{props.eowg_name}</div>
                        <div style={{marginTop:'10px'}}>
                            <div dangerouslySetInnerHTML={{__html:props.eowg_content}}></div>
                        </div>
                    </div>
                </div>                
            </Modal>
        </>
    )
}

export default WebGoodsPreview