import React from 'react'
import Main from '../../cpn/Main'
import {  Row, } from 'antd';
import Folder from '../../cpn/Folder';
import { indexFinance } from '../balance/balanceType';
function ExpenseIndex() {
    return (
        <Main t1='财务' n1={indexFinance} t2='费用支出'>
            <Row>                
                <Folder title='费用支出单'  link='/Expense' />            
                <Folder title='报表'  link='/ExpenseTable' />            
            </Row>
        </Main>
    )
}

export default ExpenseIndex