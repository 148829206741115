import React from 'react'
import Main from '../../../../cpn/Main'
import Group from '../group/Group'

function CustomGroup() {
    return (
        <Main t1='地接业务' n1='/LocalIndex' t2='定制游' n2='/CustomIndex' t3='团队计划'>
            <Group 
                type='1'
                fileTypesId={['1','2','3','4']}
            />
        </Main>
    )
}

export default CustomGroup