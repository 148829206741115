import React, { useState } from 'react'
import { Button,Form,Modal, Input,DatePicker,} from 'antd'
import GetUserDept from '../../cpn/GetUserDept';
import GetClient from '../../finance/client/GetClient';
import GetSalesman from './GetSalesman';
import GetGroupNum from './GetGroupNum';
import GetOperator from './GetOperator';
import dayjs from 'dayjs';
const {RangePicker} = DatePicker
function LedgerGroupQuery(props) {
    const [open,setOpen] = useState()
    const [form] = Form.useForm();   
    return (
        <>
            <Button  style={{marginLeft:"15px"}} onClick={() => {setOpen(true)}}>查询</Button>            
            <Modal
                open={open}
                title='查询团队报账单'
                okText="提交"
                cancelText="取消"
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        <Button onClick={() => {    
                            localStorage.setItem('ebn_query_dept','')
                            localStorage.setItem('ebn_query_client','')
                            localStorage.setItem('ebn_query_uid','')
                            localStorage.setItem('ebn_query_team_num','')
                            localStorage.setItem('ebn_query_date_s','')
                            localStorage.setItem('ebn_query_date_e','')
                            localStorage.setItem('ebn_query_dateNew','')
                            localStorage.setItem('ebn_query_ec_content','')
                            localStorage.setItem('ebn_query_yw_id','')                                    
                            localStorage.setItem('ebn_query_action','')                                    
                            form.resetFields();
                            props.getQuery({action:'',ebn_query_dept:'',ebn_query_client:'',ebn_query_uid:'',ebn_query_team_num:'',ebn_query_date_s:'',ebn_query_date_e:'',ebn_query_dateNew:'',ebn_query_ec_content:'',ebn_query_yw_id:''})  
                            setOpen(false)                            
                            }}>重置</Button>
                        <CancelBtn />
                        <OkBtn />
                    </>
                )}
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            // console.log(values,"values");
                            props.getQuery({
                                action:"search",
                                ebn_dept_id:values.ebn_dept_id,
                                ebn_client_id:values.ebn_client_id,
                                ebn_uid:values.ebn_uid,
                                ebn_team_num:values.ebn_team_num,
                                ebn_date_s:values.ebn_date_s?dayjs(values.ebn_date_s[0]).format('YYYY-MM-DD'):'',
                                ebn_date_e:values.ebn_date_s?dayjs(values.ebn_date_s[1]).format('YYYY-MM-DD'):'',
                                ebn_ctime:values.ebn_ctime?dayjs(values.ebn_ctime).format('YYYY-MM-DD'):'',
                                ebn_content:values.ebn_content,
                                ebn_yw_id:values.salesman,                                
                            })
                            localStorage.setItem('ebn_query_dept',values.ebn_dept_id?values.ebn_dept_id:'')
                            localStorage.setItem('ebn_query_client',values.ebn_client_id?values.ebn_client_id:'')
                            localStorage.setItem('ebn_query_uid',values.ebn_uid?values.ebn_uid:'')
                            localStorage.setItem('ebn_query_team_num',values.ebn_team_num?values.ebn_team_num:'')
                            localStorage.setItem('ebn_query_date_s',values.ebn_date_s?dayjs(values.ebn_date_s[0]).format('YYYY-MM-DD'):'')
                            localStorage.setItem('ebn_query_date_e',values.ebn_date_s?dayjs(values.ebn_date_s[1]).format('YYYY-MM-DD'):'')
                            localStorage.setItem('ebn_query_dateNew',values.ebn_ctime?dayjs(values.ebn_ctime).format('YYYY-MM-DD'):'')
                            localStorage.setItem('ebn_query_ec_content',values.ebn_content?values.ebn_content:'')
                            localStorage.setItem('ebn_query_yw_id',values.salesman?values.salesman:'')
                            localStorage.setItem('ebn_query_action','search')
                            setOpen(false)
                        })
                        .catch((info) => {
                            // console.log('Validate Failed:', info);
                        });
                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_in_modal"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                >                                
                    <GetUserDept  
                        label='部门' 
                        wrapperCol={{span:10}}
                        getUserDept={(e) => {}}
                        name='ebn_dept_id'
                        selectAll='true'
                        initialValues={localStorage.getItem('ebn_query_dept')}
                    />
                    <GetClient 
                        label='客户'
                        getClient={(e) => {}}  
                        name='ebn_client_id'
                        style={{width:'290px'}}
                        initialValues={localStorage.getItem('ebn_query_client')}
                    />
                    <GetOperator 
                        label='操作员'
                        wrapperCol={{span:10}} 
                        getOperator={(e) => {}} 
                        name='ebn_uid'
                    />                                 
                    <GetSalesman 
                        label='销售员' 
                        name='salesman' 
                        getSalesman={(e) => {}}
                        wrapperCol={{span:10}} 
                    />
                    <GetGroupNum 
                        label='团号' 
                        wrapperCol={{span:10}} 
                        getGroupNum={(e) => {}}
                        name='ebn_team_num'
                        initialValues={localStorage.getItem('ebn_query_team_num')}
                    />
                    <Form.Item name="ebn_date_s" label='出发日期' >
                        <RangePicker />                                                  
                    </Form.Item>
                    <Form.Item name="ebn_ctime" label='新增日期' >
                        <DatePicker  />                                                  
                    </Form.Item>
                    <Form.Item name="ebn_content" label='备注' wrapperCol={{span:15}}>
                        <Input />
                    </Form.Item>                                                            
                </Form>
            </Modal>
        </>
    )
}

export default LedgerGroupQuery