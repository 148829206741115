import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import form_data from "./formData";

const zd_enterprise_userApi = createApi({
    reducerPath: 'zd_enterprise_userApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/zd_enterprise_user'        
    }),
    tagTypes:['bank'],
    endpoints(build) {
        return {
            //员工列表
            all_dept_user_search: build.query({
                query(user) {
                    return {
                        url: '/all_dept_user_search',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            
        }
    }
})

export const {
    useAll_dept_user_searchQuery,
} = zd_enterprise_userApi;
export default zd_enterprise_userApi;