import React,{useRef, useState}from 'react'
import { Form,Input,message} from 'antd';
import { useDijieTemplateGuiGeAddMutation,} from '../../../../../store/ReactWebSiteApi';
import ModalAdd from '../../../../../cpn/ModalAdd';
import GetUserDept from '../../../../../cpn/GetUserDept';
function CrowdAdd() {
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        ltgg_e_id:localStorage.getItem('e_id'),
        ltgg_uid:localStorage.getItem('eu_id'),
        ltgg_uname:localStorage.getItem('eu_name'),
    });
    const [submitAdd] = useDijieTemplateGuiGeAddMutation()
    const [open,setOpen] = useState(false)
    const editForm = useRef()
    return (
        <ModalAdd 
            title='人群类型模板'
            onOk={(e) => {
                submitAdd(info).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('新增成功！') 
                        setOpen(false)                                                 
                    }
                    if (res.data.Status === 'fail') {
                        message.error('新增失败！'+res.data.Message)
                    }
                })
            }}
            open={open}
            getOpen={(e) => {
                setOpen(prevsState=>e)
                if(e){
                    setInfo(prevState=>({...prevState,ltgg_dept_id:'',}))
                    form.resetFields(); 
                }
            }}
        >
            <Form
                ref={editForm}
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 6 }}
            >                                           
                <GetUserDept 
                    label='部门' 
                    name='dept'
                    required 
                    wrapperCol={{span:18}}
                    getUserDept={(e) => {setInfo(prevsState=>({...prevsState,ltgg_dept_id:e}))}}
                />
                <Form.Item name="remark" label='人群类型名称' wrapperCol={{span:18}} extra='比如成人、儿童、全票、半票'>
                    <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,ltgg_name:e.target.value}))}}/>
                </Form.Item>
            </Form>
        </ModalAdd>
    )
}

export default CrowdAdd