import React, { useEffect, useState } from 'react'
import { message, Space, Table, } from 'antd'
import { useDijieBaoJiaDanJieSuanFangShiListQuery, useDijieBaoJiaDanZhuYiShiXiangDelMutation, } from '../../../../store/ReactWebSiteApi'
import ModalDel from '../../../../cpn/ModalDel'
import OpFilePayAdd from './FilePayAdd'
import payType from './payType'
import FilePayEdit from './FilePayEdit'

function FilePay(props) {
    const [list,setList] = useState()
    const {data,isSuccess} = useDijieBaoJiaDanJieSuanFangShiListQuery({bjdjsfs_bjd_id:props.bjdjsfs_bjd_id},{refetchOnFocus:true})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                const arr = data.Result.map((item) => {
                    const {bjdjsfs_id:key,bjdjsfs_amount,bjdjsfs_type,bjdjsfs_ctime,bjdjsfs_content} = item

                    return item = {key,bjdjsfs_amount,bjdjsfs_type,bjdjsfs_ctime,bjdjsfs_content}
                })
                setList(arr)
            }else{
                setList([])
            }
        }
    },[data,isSuccess])
    //删除
    const [del] = useDijieBaoJiaDanZhuYiShiXiangDelMutation()
    const column = [        
        {
            key:'type',
            title:'类型',
            dataIndex:'bjdjsfs_type',
            width:'120px',
            render:(text) => {
                const type = payType(text)
                return type
            },
        },                           
        {
            key:'1',
            title:'结算金额',
            dataIndex:'bjdjsfs_amount',
            width:'120px'
        },                           
        {
            key:'2',
            title:'结算方式',
            dataIndex:'bjdjsfs_content'
        },                           
        {
            key:'5',
            title:'操作',
            render:(_,record) => {
                return<Space>                    
                    <FilePayEdit
                        bjdjsfs_id={record.key}
                        bjdjsfs_bjd_id={props.bjdjsfs_bjd_id}
                    />
                    <ModalDel onOk={() => {
                        del({bjdjsfs_id:record.key}).then((res) => {
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                </Space>
            },
            width:'120px',
        },        
    ]
    if(props.noAdd){
        column.pop()
    }
    return (        
        <Table
            title={() => <Space><span style={{fontWeight:'600'}}>结算金额</span> {(!props.noAdd && !props.preview) && <OpFilePayAdd
                ltbjd_dept_id={props.ltbjd_dept_id}
                bjdjsfs_bjd_id={props.bjdjsfs_bjd_id}
            />}</Space>}
            columns={column}
            dataSource={list}
            style={{marginTop:"10px"}}
            pagination={{position:['none']}}
            size={props.customType==='1'?'middle':'small'}
            summary={(pageData) => {
                // console.log(pageData);
                let totalPrice = 0;
                pageData.forEach(({bjdjsfs_amount}) => {
                    totalPrice += +bjdjsfs_amount;
                });
                
                // console.log(mergedArray,'GuigePrice');
                return <Table.Summary>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>{totalPrice}元</Table.Summary.Cell>
                                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                
                                
                            </Table.Summary.Row>
                        </Table.Summary>
            }}
        />
    )
}

export default FilePay