import React,{useState,useEffect} from 'react'
import Main from '../../cpn/Main'
import { useGetCountryQuery } from '../../store/ReactWebSiteApi'
import { Table, Button, Form, Input, Radio,Space} from 'antd';
import { Link, useNavigate } from 'react-router-dom';

function Country(props) {
//默认列表
    //查询数据
    const [queryData, setQueryData] = useState({
        ctac_continent_id: '',
        ctac_name: '',
        action:'search',
    });

    //绑定洲选择框
    const onChange = (e) => {
        setQueryData(prevState => ({ ...prevState, ctac_continent_id: e.target.value }));
    }
    
    //获取数据
    const { data, isSuccess} = useGetCountryQuery(queryData,{refetchOnMountOrArgChange:1});
    //默认列表数据
    const [countryList, setcountryList] = useState(); 
    //加载数据
    useEffect(() => {
        if (isSuccess) {
            if(data.Status === 'success'){
                const countryList = data.Result.map(item => {
                    let { ctac_id, ctac_continent_name, ctac_name, ctac_remark, ctac_id: key, ctac_continent_id, ctac_uname, ctac_ctime } = item;
                    const newTime = ctac_ctime + ctac_uname
                    return item = { ctac_id, ctac_continent_name, ctac_name, ctac_remark, key,ctac_continent_id,newTime }
                })
                setcountryList(countryList)
            }
            if(data.Status === 'fail') {
                setcountryList([])   
            }
        }
    }, [data, isSuccess,queryData])
    //修改链接
    const navi = useNavigate();
    //表头
    const columns = [
        {
            title: 'ID',
            dataIndex: 'ctac_id',
            width: '10%',
            defaultSortOrder: 'descend',
            sorter:  (a, b) => {
                return a.ctac_id - b.ctac_id;
              } 
        },
        {
            title: '洲ID',
            dataIndex: 'ctac_continent_id',
            width: '15%',
        },
        {
            title: '洲',
            dataIndex: 'ctac_continent_name',
            width: '15%',
        },
        {
            title: '国家名称',
            dataIndex: 'ctac_name',
            width: '10%'
        },
        {
            title: '备注',
            dataIndex: 'ctac_remark',
            width: '10%',
        },
        {
            title: '更新时间',
            dataIndex: 'newTime',
            width: '15%',
        },
        {
            title: '操作',
            width: '15%',
            key:'action',
            render: (_,record) => (
                <Space size="middle">
                    <a onClick={() => {                        
                        navi(`/CountryEdit/${record.ctac_id}`)
                    }}>修改</a>
                </Space>
            )
        }
    ];
    return (
        <Main  t1='商城' n1='/GfIndexMall' t2='地区' n2='/AreaIndex' t3="国家">
            <div style={{ marginBottom: '20px', display: 'flex' }}>
                <Link to='/CountryAdd'><Button type="primary">新增国家</Button></Link>
                <Form
                    name='query'
                    style={{ marginLeft: '30px' }}
                    layout={'inline'}
                >
                    <Form.Item name='ctac_name' label="国家" >
                        <Input placeholder="请输入国家名称"  onChange={(e) => {setQueryData(prevState=>({...prevState, ctac_name: e.target.value}))}} />
                    </Form.Item>
                    <Form.Item name='ctac_continent_id'>
                        <Radio.Group onChange={onChange} value={queryData.ctac_continent_id}>
                            <Radio.Button value="">全部</Radio.Button>
                            <Radio.Button value="1">亚洲</Radio.Button>
                            <Radio.Button value="2">欧洲</Radio.Button>
                            <Radio.Button value="3">北美洲</Radio.Button>
                            <Radio.Button value="4">大洋洲</Radio.Button>
                            <Radio.Button value="5">非洲</Radio.Button>
                            <Radio.Button value="6">南美洲</Radio.Button>
                        </Radio.Group>
                    </Form.Item>                   
                </Form>
            </div>
            <Table 
                columns={columns} 
                dataSource={countryList} 
                onChange={onChange} 
                pagination={{ 
                    showTotal: total => `共${ countryList.length}条数据`,
                    showSizeChange:true,
                    showQuickJumper:true,
                    defaultPageSize:50
                    }} />
        </Main>
    )
}
export default Country