import React,{useEffect, useRef, useState,}from 'react'
import { Modal,message,Form,Input, InputNumber, DatePicker,} from 'antd';
import { useEntPayGoodsUpdateQuery, useEntPayGoodsUpdateSaveMutation, } from '../../store/ReactWebSiteApi';
import GetGoods from '../../finance/goods/GetGoods';
import dayjs from 'dayjs';
import GetFirm from './GetFirm';
const {TextArea} = Input
function FirmOrderEdit(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreate = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const editForm = useRef()
    const {data,isSuccess} = useEntPayGoodsUpdateQuery({epg_id:props.epg_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                editForm.current.setFieldsValue({
                    epg_e_id:data.Result.epg_e_id,
                    epg_eg_id:data.Result.epg_eg_id,
                    eg_egt_name:data.Result.eg_egt_name,
                    epg_amount:data.Result.epg_amount,
                    epg_content:data.Result.epg_content,
                    epg_date:dayjs(data.Result.epg_date),                   
                })
                setInfo(prevState=>({...prevState,
                    epg_e_id:data.Result.epg_e_id,
                    epg_eg_id:data.Result.epg_eg_id,
                    epg_amount:data.Result.epg_amount,
                    epg_content:data.Result.epg_content,              
                    epg_date:data.Result.epg_date,              
                }))
            }
        }
    },[data,isSuccess])
    const [info,setInfo] = useState({       
       
    });
    //客户类型
    const [submitAdd] = useEntPayGoodsUpdateSaveMutation()
    return (
        <>
            <a onClick={() => {setOpen(true)}}>修改</a>
            <Modal
                open={open}
                title='修改订单'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        epg_e_id:info.epg_e_id,
                        epg_eg_id:info.epg_eg_id,
                        epg_amount:info.epg_amount,
                        epg_content:info.epg_content,
                        epg_update_uid:localStorage.getItem('eu_id'),
                        epg_update_uname:localStorage.getItem('eu_name'),
                        epg_date:info.epg_date,
                        epg_id:props.epg_id
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
                width={800}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                    ref={editForm}
                >                            
                    <GetFirm 
                        label='企业'
                        wrapperCol={{span:15}}
                        required
                        getFirm={(e) => {
                            setInfo(prevState=>({...prevState,epg_e_id:e}))
                        }}
                        name='epg_e_id'
                    />
                    <GetGoods
                        typeName='eg_egt_name'
                        name='epg_eg_id'
                        label='产品'
                        getGoods={(e) => {setInfo(prevState=>({...prevState,epg_eg_id:e}))}}
                        required
                    />
                    <Form.Item name="epg_amount" label='金额' wrapperCol={{span:15}} required>
                        <InputNumber  onChange={(e) => {setInfo(prevState=>({...prevState,epg_amount:e}))}} />
                    </Form.Item>
                    <Form.Item name="epg_date" label='截止日期' wrapperCol={{span:15}} required>
                        <DatePicker  onChange={(e) => {setInfo(prevState=>({...prevState,epg_date:dayjs(e).format('YYYY-MM-DD')}))}} />
                    </Form.Item>
                    <Form.Item name="epg_content" label='备注' wrapperCol={{span:15}}>
                        <TextArea  onChange={(e) => {setInfo(prevState=>({...prevState,epg_content:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default FirmOrderEdit