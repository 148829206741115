import React from 'react'
import classes from './web.module.css'
function TaPriceCustom() {
    return (
        <>
            <div className={classes.priceTitle1}>定制游模块适用于组团社和地接社的定制游业务</div>
            <div className={classes.priceTitle2}>1线路方案</div>
            <div className={classes.priceTitle3}>系统生成《线路方案》，包含线路名称、行程安排和接待标准，可以生成图文版PDF（以下同）。</div>
            <div className={classes.priceTitle2}>2报价单</div>
            <div className={classes.priceTitle3}>系统生成《报价单》，包含客户信息、出发日期、线路名称、行程安排、接待标准，收费明细、费用不含、注意事项。</div>
            <div className={classes.priceTitle2}>3确认件</div>
            <div className={classes.priceTitle3}>系统生成《确认件》，包含客户信息、出发日期、线路名称、行程安排、接待标准，收费明细、费用不含、注意事项、结算金额。</div>
            <div className={classes.priceTitle2}>4结算单</div>
            <div className={classes.priceTitle3}>系统生成《结算单》，包含客户信息、出发日期、线路名称、行程安排、接待标准，收费明细、费用不含、注意事项、结算金额。</div>
            <div className={classes.priceTitle2}>5导游计划单</div>
            <div className={classes.priceTitle3}>系统生成《导游计划单》，包含导游信息、导游计划、出发日期、线路名称、行程安排、导游费用。</div>
            <div className={classes.priceTitle2}>6游客行程单</div>
            <div className={classes.priceTitle3}>系统生成《游客行程单》，包含游客信息、出发日期、线路名称、行程安排、接待标准、注意事项、反馈表。</div>
            <div className={classes.priceTitle2}>7模版</div>
            <div className={classes.priceTitle3}>系统生成《页眉页脚模版》，《行程安排模版》，《接待标准模版》，《收费明细模版》，《费用不含模版》，《注意事项模版》</div>
            <div className={classes.priceTitle2}>8团队计划表</div>
            <div className={classes.priceTitle3}>系统生成《团队计划表》，包含团号、客户信息、线路名称、出发日期、《报价单》、《确认件》、《结算单》、《导游计划单》、《游客行程单》。</div>
            <div className={classes.priceTitle1}>价格</div>  
            <div className={classes.priceTitle2}>1800元/年，一次性购买2年按1500元/年，一次性购买3年按1200元/年。</div> 
            <div className={classes.priceTitle3}>备注：每个模块可以单独使用，可以选择购买一个或者多个模块。</div>
        </>
    )
}

export default TaPriceCustom