import React from 'react'
import Main from '../../../../cpn/Main'
import CustomGoods from './CustomGoods'

function CustomGoodsOrgan() {
    return (
        <Main  t1='计调' n1='/TaOpIndex' t2='定制游' n2='/CustomIndex' t3='组团商品'>
            <CustomGoods  type='1'/>
        </Main>
    )
}

export default CustomGoodsOrgan