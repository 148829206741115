
import React from 'react'
import classes from './cpn.module.css'
import {WechatOutlined} from '@ant-design/icons';
export default function Foot() {
    return (
        <>
            <div className={classes.foot}>
                <div>
                    <span>软件开发及版权所有 </span>
                    <a onClick={() => { window.open('https://www.bnsrj.com')}} className={classes.footLink}>湖北伯纳思网络科技有限公司</a> 
                    <span>  © 2021-现在 备案号 <a target="_blank" onClick={() => {window.open('https://beian.miit.gov.cn/')}} style={{cursor:'pointer'}}>鄂ICP备18025660号-1</a></span> 
                    <a onClick={() => { window.open('https://work.weixin.qq.com/kfid/kfcc1a9500fb365f216')}} className={classes.footLinkWechat}><WechatOutlined /> 在线客服</a>
                </div>
                {/* <div><a href='/Start'>关于我们</a></div> */}
            </div>            
        </>       
    )
}
