import React,{useState,useEffect,useRef}from 'react'
import { Table,Button,Space,Modal,message,Form,Input, DatePicker, InputNumber, Select} from 'antd';
import { useSearchParams, } from 'react-router-dom';
import {useInOutWarehouseAddMutation, useInOutWarehouseListQuery, useInOutWarehouseDelMutation, useInOutWarehouseUpdateSaveMutation, useInOutWarehouseUpdateQuery, useGetPropertyWarehousePriceListQuery, usePropertyAllKcQuery} from '../../store/ReactWebSiteFinanceApi';
import GetStaff from '../../cpn/GetUser';
import GetWarehouse from '../../cpn/GetWarehouse';
import dayjs from 'dayjs';
import GetWarehouseOfSupplies from '../../cpn/GetWarehouseOfSupplies';
import MainKeeper from '../MainKeeper';
function InAndOut() {
    const [param] = useSearchParams();
    const [form] = Form.useForm();
//新增入库-----------------------------------------------------------------------------------------------------------------------------新增入库
    //获取员工
    const getStaff = (e,f,g) => {
        // console.log(e,f,g);
        setInfoAdd(prevState=>({...prevState,eiw_jsr_uid:e,eiw_jsr_uname:f,}))
    }
    //获取仓库
    const getWarehouse = (e,f,g) => {
        // console.log(e,f,g);
        setInfoAdd(prevState=>({...prevState,eiw_ewi_id:e}))
    }
    
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateAdd = (values) => {
        
        setOpenAdd(false);

    };
    const [infoAdd,setInfoAdd] = useState({eiw_content:'',});
    const [submitAdd] = useInOutWarehouseAddMutation()
    const modalStorageIn= <Modal
                        open={openAdd}
                        title='新增入库'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        onOk={() => {
                            if(infoAdd.eiw_num<1){
                                message.error('入库数量必须大于1');
                                return false;
                            }
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateAdd(values);
                                    submitAdd({
                                        eiw_ep_id:param.get('id'),
                                        eiw_ep_name:param.get('name'),
                                        eiw_uname:localStorage.getItem('eu_name'),
                                        eiw_uid:localStorage.getItem('eu_id'),
                                        eiw_amount:infoAdd.eiw_num*infoAdd.eiw_price,
                                        eiw_date:infoAdd.eiw_date,
                                        eiw_price:infoAdd.eiw_price,
                                        eiw_num:infoAdd.eiw_num,
                                        eiw_jsr_uid:infoAdd.eiw_jsr_uid,
                                        eiw_jsr_uname:infoAdd.eiw_jsr_uname,
                                        eiw_ewi_id:infoAdd.eiw_ewi_id,
                                        eiw_content:infoAdd.eiw_content,
                                    }).then((res) => {
                                            console.log(res);
                                            if (res.data.Status === 'success') {
                                                message.success('新增成功！')  
                                                setOpenAdd(false)                         
                                            }
                                            if (res.data.Status === 'fail') {
                                                message.error('新增失败！'+res.data.Message)
                                            }
                                        }) 
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                              
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                        >
                            <Form.Item name="type" label='物资名称' wrapperCol={{span:10}} required>
                                <div>{param.get('name')}</div>
                            </Form.Item>
                            <GetWarehouse label='库房' getWarehouse={getWarehouse} />  
                            <Form.Item name='time' label='入库时间' wrapperCol={{span:15}} required>
                                <DatePicker showTime onChange={(e) => {setInfoAdd(prevState=>({...prevState,eiw_date:dayjs(e)}))}}/>
                            </Form.Item>                           
                            <Form.Item name="num" label='数量' wrapperCol={{span:10}} required extra='入库数量必须大于1'>
                                <InputNumber  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eiw_num:e}))}}/>
                            </Form.Item>
                            <Form.Item name="price" label='单价' wrapperCol={{span:10}} required>
                                <InputNumber onChange={(e) => {setInfoAdd(prevState=>({...prevState,eiw_price:e}))}}/>
                            </Form.Item>
                            <Form.Item name="sum" label='合计金额' wrapperCol={{span:10}}>
                                {infoAdd.eiw_num*infoAdd.eiw_price}
                            </Form.Item>
                            <GetStaff label='经手人'  getStaff={getStaff}/>
                            <Form.Item name="name" label='备注说明' wrapperCol={{span:15}}>
                                <Input onChange={(e) => {setInfoAdd(prevState=>({...prevState,eiw_content:e.target.value}))}}/>
                            </Form.Item>                                                      
                        </Form>
                    </Modal>     
//新增出库-----------------------------------------------------------------------------------------------------------------------------新增出库
    const formOut = useRef()
    const [infoAddOut,setInfoAddOut] = useState({eiw_content:'',});
    //获取员工
    const getStaffOut = (e,f,g) => {
        // console.log(e,f,g);
        setInfoAddOut(prevState=>({...prevState,eiw_jsr_uid:e,eiw_jsr_uname:f,}))
    }
    //获取仓库
    const getWarehouseOut = (e,f,g) => {
        // console.log(e,f,g);
        setInfoAddOut(prevState=>({...prevState,eiw_ewi_id:e}));
        formOut.current.setFieldsValue({price:''})
    }
    //获取单价列表
    const {data:dataPrice,isSuccess:isSuccessPrice} = useGetPropertyWarehousePriceListQuery({eiw_ep_id:param.get('id'),eiw_ewi_id:infoAddOut.eiw_ewi_id},{refetchOnMountOrArgChange:1})
    
    const [listPrice, setListPrice] = useState();
    
    useEffect(() => {
        if(isSuccessPrice){            
            if(dataPrice.Status==='success'){
                console.log(dataPrice);
                
                const newArr = dataPrice.Result.map(item => {
                    const {eiw_id:key,eiw_price,eiw_num,} = item;
                    return item = {value:key,label:'单价'+eiw_price+' —— 库存'+eiw_num,eiw_price:eiw_price,eiw_num:eiw_num}
                })                               
                setListPrice(newArr)
            }else{
                setListPrice('')
            }
            
        }
    }, [dataPrice, isSuccessPrice])    
    const [openOut, setOpenOut] = useState(false);
    const onCreateOut = (values) => {
        
        console.log('Received values of form: ', values);
        setOpenOut(false);
    };
    
    const modalStorageOut= <Modal
                        open={openOut}
                        title='新增出库'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenOut(false);}}
                        onOk={() => {  
                            console.log(Math.abs(infoAddOut.eiw_num),infoAddOut.warehouseNum);
                            if(infoAddOut.eiw_num>-1){
                                message.error('入库数量必须小于0');
                                return false;
                            }                          
                            if(Math.abs(infoAddOut.eiw_num)>+infoAddOut.warehouseNum){
                                message.error('出库数量必须小于库存数量');
                                return false;
                            }                          
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateOut(values)
                                    submitAdd({
                                        eiw_ep_id:param.get('id'),
                                        eiw_ep_name:param.get('name'),
                                        eiw_uname:localStorage.getItem('eu_name'),
                                        eiw_uid:localStorage.getItem('eu_id'),
                                        eiw_amount:infoAddOut.eiw_num*infoAddOut.eiw_price,
                                        eiw_date:infoAddOut.eiw_date,
                                        eiw_price:infoAddOut.eiw_price,
                                        eiw_num:infoAddOut.eiw_num,
                                        eiw_jsr_uid:infoAddOut.eiw_jsr_uid,
                                        eiw_jsr_uname:infoAddOut.eiw_jsr_uname,
                                        eiw_ewi_id:infoAddOut.eiw_ewi_id,
                                        eiw_content:infoAddOut.eiw_content,
                                    }).then((res) => {
                                            console.log(res);
                                            if (res.data.Status === 'success') {
                                                message.success('新增成功！')  
                                                setOpenAdd(false)                         
                                            }
                                            if (res.data.Status === 'fail') {
                                                message.error('新增失败！'+res.data.Message)
                                            }
                                        })   
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                           
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={formOut}
                        >
                            <Form.Item name="type" label='物资名称' wrapperCol={{span:10}} required>
                                <div>{param.get('name')}</div>
                            </Form.Item>
                            <GetWarehouseOfSupplies label='库房' getWarehouse={getWarehouseOut} suppliesId={param.get('id')}/>  
                            <Form.Item name="price" label='单价' wrapperCol={{span:10}} required>
                                <Select options={listPrice} onChange={(e,f) => {setInfoAddOut(prevState=>({...prevState,eiw_price:f.eiw_price,warehouseNum:f.eiw_num}))}}/>
                            </Form.Item>
                            <Form.Item name='time' label='出库时间' wrapperCol={{span:15}} required>
                                <DatePicker showTime onChange={(e) => {setInfoAddOut(prevState=>({...prevState,eiw_date:dayjs(e)}))}}/>
                            </Form.Item>             
                            <Form.Item name="num" label='数量' wrapperCol={{span:10}} required extra='入库数量必须小于0'>
                                <InputNumber  onChange={(e) => {setInfoAddOut(prevState=>({...prevState,eiw_num:e}))}}/>
                            </Form.Item>                            
                            <Form.Item name="sum" label='合计金额' wrapperCol={{span:10}}>
                                {infoAddOut.eiw_num*infoAddOut.eiw_price}
                            </Form.Item>
                            <GetStaff label='经手人'  getStaff={getStaffOut}/>
                            <Form.Item name="name" label='备注说明' wrapperCol={{span:15}}>
                                <Input onChange={(e) => {setInfoAddOut(prevState=>({...prevState,eiw_content:e.target.value}))}}/>
                            </Form.Item>                                                      
                        </Form>
                    </Modal>     
//修改入库-----------------------------------------------------------------------------------------------------------------------------修改出入库
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateClientEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    const [infoEdit,setInfoEdit] = useState({
        eiw_num:'',
        eiw_price:'',
    });
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useInOutWarehouseUpdateQuery({eiw_id:editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    eiw_date:dayjs(dataEdit.Result.eiw_date),
                    eiw_content:dataEdit.Result.eiw_content,
                    eiw_num:dataEdit.Result.eiw_num,
                    eiw_price:dataEdit.Result.eiw_price,
                })
                setInfoEdit(prevState=>({...prevState,
                    eiw_date:dataEdit.Result.eiw_date,
                    eiw_content:dataEdit.Result.eiw_content,              
                    eiw_num:dataEdit.Result.eiw_num,   
                    eiw_price:dataEdit.Result.eiw_price,           
                    eiw_amount:dataEdit.Result.eiw_amount,           
                    eiw_jsr_uname:dataEdit.Result.eiw_jsr_uname,           
                    eiw_jsr_uid:dataEdit.Result.eiw_jsr_uid,           
                    eiw_ewi_id:dataEdit.Result.eiw_ewi_id,           
                    ewi_name:dataEdit.Result.ewi_name,           
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useInOutWarehouseUpdateSaveMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改出入库'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClientEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitUpdateSave({
                                eiw_ep_id:param.get('id'),
                                eiw_ep_name:param.get('name'),
                                eiw_update_uname:localStorage.getItem('eu_name'),
                                eiw_update_uid:localStorage.getItem('eu_id'),
                                eiw_amount:infoEdit.eiw_num*infoEdit.eiw_price,
                                eiw_date:infoEdit.eiw_date,
                                eiw_price:infoEdit.eiw_price,
                                eiw_num:infoEdit.eiw_num,
                                eiw_jsr_uid:infoEdit.eiw_jsr_uid,
                                eiw_jsr_uname:infoEdit.eiw_jsr_uname,
                                eiw_ewi_id:infoEdit.eiw_ewi_id,
                                eiw_content:infoEdit.eiw_content,
                                eiw_id:editId,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >                           
                           <Form.Item name="type" label='物资名称' wrapperCol={{span:10}} required>
                                <div>{param.get('name')}</div>
                            </Form.Item>
                            <Form.Item name='eiw_date' label='出入库时间' wrapperCol={{span:15}} required>
                                <DatePicker showTime onChange={(e) => {setInfoEdit(prevState=>({...prevState,eiw_date:dayjs(e)}))}}/>
                            </Form.Item>                           
                            <Form.Item name="eiw_num" label='数量' wrapperCol={{span:10}} required extra='入库填正数，出库填负数'>
                                <InputNumber  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eiw_num:e}))}}/>
                            </Form.Item>
                            <Form.Item name="eiw_price" label='单价' wrapperCol={{span:10}} extra='入库必填单价'>
                                <InputNumber onChange={(e) => {setInfoEdit(prevState=>({...prevState,eiw_price:e}))}}/>
                            </Form.Item>
                            <Form.Item name="sum" label='合计金额' wrapperCol={{span:10}}>
                                {infoEdit.eiw_num*infoEdit.eiw_price}
                            </Form.Item>
                            <GetStaff label='经手人'  getStaff={getStaff} defaultId={infoEdit.eiw_jsr_uname}/>
                            <Form.Item name="eiw_content" label='备注说明' wrapperCol={{span:15}}>
                                <Input onChange={(e) => {setInfoEdit(prevState=>({...prevState,eiw_content:e.target.value}))}}/>
                            </Form.Item>
                            <GetWarehouse label='库房' getWarehouse={getWarehouse}  defaultId={infoEdit.ewi_name}/>   
                        </Form>
                    </Modal>
    //删除出入库-----------------------------------------------------------------------------------------------------------------------------删除出入库
    const {confirm} = Modal; 
    const [submitDel] = useInOutWarehouseDelMutation()
    //出入库列表-----------------------------------------------------------------------------------------------------------------------------出入库列表
    //仓库列表
    const [warehouse,setWarehouse] = useState({id:'',num:0})
    const [ListWarehouse,setListWarehouse] = useState();
    const {data:dataList,isSuccess:isSuccessList} = usePropertyAllKcQuery({eiw_ep_id:param.get('id')})
    useEffect(() => {
        if(isSuccessList){
            if(dataList.Status==='success'){
                console.log(dataList);
                
                const newArr = dataList.Result.map(item => {
                    const {ewi_id:key,ewi_name,eiw_num} = item;
                    return item = {value:key,label:ewi_name + ', 库存 ' + eiw_num,eiw_num:eiw_num}
                })                 
                newArr.push({value:'',label:'所有库房'})
                setListWarehouse(newArr)
            }else{
                setListWarehouse('')
            }
        }
    },[dataList,isSuccessList])
    
    const {data,isSuccess} = useInOutWarehouseListQuery({eiw_ep_id:param.get('id'),eiw_ewi_id:warehouse.id})
    
    const [list, setList] = useState();
    //最后一条
    const [lastData,setLastData] = useState()
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {eiw_id:key,eiw_name,eiw_num,eiw_content,eiw_uname,eiw_ctime,eiw_jsr_uname,eiw_price,eiw_update_ctime,eiw_update_uname,ewi_name} = item;
                    return item = {key,eiw_name,eiw_num,eiw_content,eiw_uname,eiw_ctime,eiw_jsr_uname,eiw_price,eiw_update_ctime,eiw_update_uname,ewi_name}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eiw_ctime;
                    let bTimeString = b.eiw_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
                const [max] = [...newArr].sort((a, b) => b.key - a.key)
                //console.log(max.key);
                setLastData(max.key)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])      
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'store',
            title: '库房',
            dataIndex: 'ewi_name',
        },   
        {
            key: 'in',
            title: '出入库时间',
            dataIndex: 'eiw_name',
            render:(text) => {
                return<>{dayjs(text).format('YYYY-MM-DD hh:mm:ss')}</>
            }
        },
        {
            key: 'sum',
            title: '数量',
            dataIndex: 'eiw_num',
            render:(text) => {
                if(text>0){
                    return <>
                        <span style={{color:'red',fontWeight:'bold'}}>{text}</span>
                    </>
                }else{
                    return <>
                        <span style={{color:'green',fontWeight:'bold'}}>{text}</span>
                    </>
                }
            }
        },
        {
            key: 'price',
            title: '单价',
            dataIndex: 'eiw_price',
        },
        {
            key: 'total',
            title: '合计',
            render:(_,record) => {
                return<>{record.eiw_num*record.eiw_price}</>
            }
        },
     
        {
            key: 'people',
            title: '经手人',
            dataIndex: 'eiw_jsr_uname',
        },
        {
            key: 'room',
            title: '备注说明',
            dataIndex: 'eiw_content',
        },       
            
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.eiw_ctime + record.eiw_uname}</div>
                    <div>修改时间：{record.eiw_update_ctime+record.eiw_update_uname}</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    {warehouse.id !== '' && <Space direction='vertical'>                        
                        {record.key===lastData && <a onClick={() => {
                            setEditId(record.key);
                            setOpenEdit(true)
                        }}>修改</a>}
                        {record.key===lastData && <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({eiw_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>}
                    </Space>}
                </>
            }
        },
    ]

    
    return (
        <MainKeeper  t1='物品管理' t2='物品列表'  t3={param.get('name')} t4='出入库'>
            <div>                
                <Select 
                    placeholder='请选择库房'
                    style={{width:"220px"}} 
                    options={ListWarehouse} 
                    defaultValue={param.get('wid')} 
                    onChange={(e,f) => {setWarehouse(prevState=>({...prevState,id:e,num:f.eiw_num}))}}/>
                { warehouse.id !== '' && 
                    <>
                        <Button type='primary' onClick={() => {setOpenAdd(true);}} style={{marginLeft:"20px"}}>新增入库</Button>
                        {warehouse.num > 0 && <Button color='success' onClick={() => {setOpenOut(true);}} style={{marginLeft:"20px"}}>新增出库</Button>}
                    </>
                }
            </div>            
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalEdit}   
            {modalStorageIn}             
            {modalStorageOut}             
        </MainKeeper>
    )
}

export default InAndOut