import React, { useEffect, useRef, useState } from 'react'
import ModalEdit from '../../../../cpn/ModalEdit'
import { DatePicker, Form,Input,message, } from 'antd'
import dayjs from 'dayjs';
import GetUserDept from '../../../../cpn/GetUserDept';
import { useYewuTuanHaoUpdateSaveMutation,useYewuTuanHaoUpdateQuery} from '../../../../store/ReactWebSiteApi';
import ClientForm from '../temp/client/ClientForm';
const {TextArea} = Input
function GroupEdit(props) {
    const [form] = Form.useForm();  
    const editForm = useRef()
    const [info,setInfo] = useState({
        lth_e_id:localStorage.getItem('e_id'),
        lth_update_uid:localStorage.getItem('eu_id'),
        lth_update_uname:localStorage.getItem('eu_name'),
        lth_id:props.lth_id,
        lth_date:'',
        lth_client_name:'',
    });
    const [open,setOpen] = useState()
    //获取
    const {data,isSuccess} = useYewuTuanHaoUpdateQuery({lth_id:props.lth_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){               
                editForm.current.setFieldsValue({
                    lth_dept_name:data.Result.lth_dept_name,                         
                    lth_date:dayjs(data.Result.lth_date),    
                    lth_type:data.Result.lth_type,                   
                    lth_kehu_name:data.Result.lth_kehu_name,                   
                    lth_xianlu_name:data.Result.lth_xianlu_name,                   
                    lth_phone:data.Result.lth_phone,                          
                    lth_content:data.Result.lth_content,               
                })                                   
                setInfo(prevsState=>({...prevsState,
                    lth_dept_id:data.Result.lth_dept_id,                         
                    lth_dept_name:data.Result.lth_dept_name,                         
                    lth_date:dayjs(data.Result.lth_date),    
                    lth_kehu_name:data.Result.lth_kehu_name,                   
                    lth_xianlu_name:data.Result.lth_xianlu_name,                   
                    lth_phone:data.Result.lth_phone,       
                    lth_content:data.Result.lth_content,       
                    lth_ltkh_id:data.Result.lth_ltkh_id,       
                }))
            }
        }
    },[data,isSuccess,])
    const [updateSave] = useYewuTuanHaoUpdateSaveMutation()
    
    return (
        <ModalEdit
            getOpen={(e) => {setOpen(prevsState=>e );}}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        //获取产品人群类型
                        let arr = []
                        for (let key in values) {
                            if (values.hasOwnProperty(key)) { // 确保key是对象自身的属性
                                if(key.includes('egg')){
                                    arr.push(values[key])
                                }
                            }
                        } 
                        console.log(values);                       
                        updateSave({
                            lth_id:props.lth_id,
                            lth_e_id:localStorage.getItem('e_id'),                            
                            lth_e_name:localStorage.getItem('e_name'),
                            lth_dept_id:info.lth_dept_id,
                            lth_dept_name:info.lth_dept_name,
                            lth_xianlu_name:info.lth_xianlu_name,
                            lth_kehu_name:info.lth_kehu_name,
                            lth_update_uid:localStorage.getItem('eu_id'),
                            lth_update_uname:localStorage.getItem('eu_name'),
                            lth_ltkh_id:info.lth_ltkh_id,
                            lth_content:info.lth_content,
                            lth_phone:info.lth_phone,
                        }).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('修改成功！') 
                                setOpen(false)                                                 
                            }
                            if (res.data.Status === 'fail') {
                                message.error('修改失败！'+res.data.Message)
                            }
                        })
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
                
            }}
            open={open}
            title='团队计划'
        >
            <Form
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 4 }}
                ref={editForm}
            >
                <GetUserDept 
                    label='部门'  
                    wrapperCol={{span:10}} 
                    required 
                    name='lth_dept_name'
                    getUserDept={(e,f) => {setInfo(prevState=>({...prevState,lth_dept_id:e,lth_dept_name:f}))}}
                />
                <ClientForm
                    getClient={(e) => {
                        setInfo(prevState=>({
                            ...prevState,
                            lth_ltkh_id:e.ltbjd_ltkh_id,
                            lth_kehu_name:e.ltbjd_client_name,
                            lth_phone:e.ltkh_phone,
                        }))                                    
                    }}
                    ltbjd_client_name={info.lth_kehu_name}
                    lth_phone={info.lth_phone}
                    name='lth_kehu_name'
                />
                <Form.Item name="lth_xianlu_name" label='线路名称' wrapperCol={{span:10}} required>
                    <Input  onChange={(e) => {setInfo(prevState=>({...prevState,lth_xianlu_name:e.target.value}))}}/>
                </Form.Item>
                <Form.Item label='出发日期' wrapperCol={{span:10}} required name='lth_date'>
                    <DatePicker 
                        disabled
                        onChange={(e) => {setInfo(prevState=>({...prevState,lth_date:dayjs(e).format('YYYY-MM-DD')}))}}
                    />
                </Form.Item>
                <Form.Item 
                    name="lth_content" 
                    label='备注' 
                    wrapperCol={{span:10}}
                >
                    <TextArea
                        onChange={(e) => {
                            setInfo(prevState=>({...prevState,lth_content:e.target.value}))
                        }}
                    />
                </Form.Item>
            </Form>
        </ModalEdit>
    )
}

export default GroupEdit