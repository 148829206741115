import React from 'react'
import Main from '../cpn/Main'
import {Row } from 'antd'
import Folder from '../cpn/Folder'
function GfIndex() {
    return (
        <Main >
            <Row>
                {(localStorage.getItem('eu_role')==='2' ||(localStorage.getItem('ess_menu_id').includes('13'))) && <Folder title='任务' link='/TaskIndex'/>}    
                {(localStorage.getItem('eu_role')==='2' ||(localStorage.getItem('ess_menu_id').includes('42'))) && <Folder title='商城' link='/GfIndexMall'/>}  
                {(localStorage.getItem('eu_role')==='2' ||(localStorage.getItem('ess_menu_id').includes('44'))) && <Folder title='财务' link='/GfIndexFinance'/>}                    
                {(localStorage.getItem('eu_role')==='2' ||(localStorage.getItem('ess_menu_id').includes('45'))) && <Folder title='人事' link='/GfIndexPersonnel'/>}
                {localStorage.getItem('eu_role') === '2' && <Folder title='系统设置' link='/SetIndex'/>}
                <Folder title='我的' link='/MineIndex?esv_id=8'/>
            </Row>
        </Main>
    )
}

export default GfIndex