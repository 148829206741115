import React from 'react'
import Main from '../../cpn/Main'
import { Row, } from 'antd';
import { useNavigate } from 'react-router-dom';
import Program from '../../cpn/Program';
import Client from '../../svg/Client.svg'
import LedgerIncomeType from '../../svg/LedgerIncomeType.svg'
function ClientIndex() {
    const navi = useNavigate()
    return (
        <Main>            
            <Row>                
                <Program title='客户管理'  onClick={() => {navi('/Client?indexType=2&indexName=业务&esv_id=9')}} icon={<img alt='' src={Client} width={40}/>}/>            
                <Program title='客户类型'  onClick={() => {navi('/ClientType?indexType=2&indexName=业务&esv_id=9')}} icon={<img alt='' src={LedgerIncomeType} width={42}/>}/>            
            </Row>
        </Main>
    )
}

export default ClientIndex