import React,{ useState, useEffect,useRef }  from 'react'
import TaTop from '../ta/web/TaTop'
import classes from './site.module.css'
import { Form, Input, Button,Row,Col,message,Modal} from 'antd'
import { MobileOutlined ,ShopOutlined,KeyOutlined,UserOutlined} from '@ant-design/icons'
import { useAutoCbExpenditureTypeMutation, useAutoClientTypeMutation, useAutoCostTypeMutation, useAutoGoodsTypeMutation, useAutoQtExpenditureTypeMutation, useAutoQtIncomeTypeMutation, useAutoXsIncomeTypeMutation, useRegisterCodeMutation, useUserRegisterMutation } from '../store/ReactWebSiteApi';
import { useNavigate } from 'react-router-dom';
import Foot from '../ta/web/Foot';
function Register() {
    const {confirm} = Modal; 
    //跳转
    const navi = useNavigate();
    //获取已登录用户数据
    const prevUserPhone = JSON.parse(localStorage.getItem('eu_ba_phone'));
    //绑定旅行社名称
    const [firm, setFirm] = useState();
    const firmInput = (e) => {setFirm(prevState => e.target.value);}
    //绑定姓名
    const [name, setName] = useState();
    const nameInput = (e) => {setName(prevState => e.target.value);}
    //绑定手机号
    const [adminPhone, setAdminPhone] = useState(prevUserPhone);
    const adminPhoneInput = (e) => {setAdminPhone(prevState => e.target.value);}
    //绑定验证码
    const [adminCode, setAdminCode] = useState();
    const adminCodeInput = (e) => {setAdminCode(prevState => e.target.value);}

    //验证码倒计时
    const [isNote, setIsNote] = useState(false);
    const [time, setTime] = useState(null);
    const timeRef = useRef();
    useEffect(() => {
        if (time && time !== 0) {
            timeRef.current = setTimeout(() => {
                setTime((time) => time - 1);
            }, 1000);
        } else {
            setIsNote(false);
        }
        return () => {
            clearInterval(timeRef.current);
        };
    }, [time]);
  
    //获取验证码数据
    const [getCode] = useRegisterCodeMutation();
    //获取验证码
    const getCodeBtn = () => {
        setTime(60);
        setIsNote(true);
        getCode({
            phone:adminPhone,
        }).then((res) => {
            console.log(res);
            if(res.data.Status==='success'){
                setTime(60);
                setIsNote(true);
            }
            if (res.data.Status === 'fail') {
                message.error('该手机号码未注册！'+ res.data.Message)    
            }
        })    
    }
    //数据上传
    const [userRegister] = useUserRegisterMutation();    
    //自动生成类型
    const [autoCbExpenditureType] = useAutoCbExpenditureTypeMutation();
    const [autoClientType] = useAutoClientTypeMutation();
    const [autoGoodsType] = useAutoGoodsTypeMutation();
    const [autoCostType] = useAutoCostTypeMutation();
    const [autoQtExpenditureType] = useAutoQtExpenditureTypeMutation();
    const [autoQtIncomeType] = useAutoQtIncomeTypeMutation();
    const [autoXsIncomeType] = useAutoXsIncomeTypeMutation();
    //提交表单（成功）
    const onFinish = (values) => {
        //  console.log('Success:', values);
        userRegister({
            eu_ba_phone: adminPhone,
            phone_code:adminCode,
            e_name:firm,
            eu_name:name,
            e_soft_name:'旅当家',
            e_content:'pc官网注册'
        }).then(registerRes => {
            console.log(registerRes,'registerRes');     
            if (registerRes.data.Status === "success") {
                message.success('正在初始化系统...')
                const e_id = registerRes.data.Result.e_id
                const eu_id = registerRes.data.Result.eu_id
                const eu_name = registerRes.data.Result.eu_name
                //自动生成成本支出类型
                autoCbExpenditureType({
                    e_id:e_id,
                    efst_uid:eu_id,
                    efst_uname:eu_name,
                    efst_name:'门票费,住宿费,餐费,交通费,导游费,保险费,杂费,地接团费',
                }).then((res) => {
                    console.log(res,'res');
                    if (res.data.Status === "success") {
                        //自动生成客户类型
                        autoClientType({
                            e_id:e_id,
                            ect_uid:eu_id,
                            ect_uname:eu_name,
                            ect_name:'组团客户,地接客户,电商平台,员工,供应商,政府部门,老板和股东,导游,门市部,办事处',
                        }).then((res) => {
                            if (res.data.Status === "success") {
                                //自动生成产品类型
                                autoGoodsType({
                                    e_id:e_id,
                                    egt_uid:eu_id,
                                    egt_uname:eu_name,
                                    egt_name:'跟团游,定制游,自由行,研学旅行,门票,包车,酒店,用餐,导游,保险,研学课程',
                                }).then((res) => {
                                    if (res.data.Status === "success") {
                                        //自动生成销售收入类型
                                        autoXsIncomeType({
                                            e_id:e_id,
                                            efst_uid:eu_id,
                                            efst_uname:eu_name,
                                            efst_name:'客户转款,导游代收,供应商返利',
                                        }).then((res) => {
                                            if (res.data.Status === "success") {
                                                //自动生成费用支出类型
                                                autoCostType({
                                                    e_id:e_id,
                                                    efst_uid:eu_id,
                                                    efst_uname:eu_name,
                                                    efst_name:'房租费,水电费,电话费,工资,社保,税金,办公用品,招待费,杂费,贷款利息,银行账户管理费,手续费',
                                                }).then((res) => {
                                                    if (res.data.Status === "success") {
                                                        //自动生成其他支出类型
                                                        autoQtExpenditureType({
                                                            e_id:e_id,
                                                            efst_uid:eu_id,
                                                            efst_uname:eu_name,
                                                            efst_name:'股东减资,归还贷款,对外借款',
                                                        }).then((res) => {
                                                            if (res.data.Status === "success") {
                                                                //自动生成其他收入类型
                                                                autoQtIncomeType({
                                                                    e_id:e_id,
                                                                    efst_uid:eu_id,
                                                                    efst_uname:eu_name,
                                                                    efst_name:'股东增资,银行贷款,归还借款,银行利息,变卖资产',
                                                                }).then((res) => {
                                                                    if (res.data.Status === "success") {
                                                                        confirm({
                                                                            title: '恭喜您，注册成功!',
                                                                            content: '初始密码123456，点击系统登录',
                                                                            okText: '前往系统登录',
                                                                            okType: 'primary',
                                                                            cancelText: '暂不登录',
                                                                            onOk() {
                                                                                navi('/Login', { replace: true });         
                                                                            },
                                                                            onCancel() {
                                                                                console.log('Cancel');
                                                                            },
                                                                        });                                                                         
                                                                    } else {
                                                                        message.error('其他收入类型生成失败！请登录后自行设置' +res.data.Message);             
                                                                    }
                                                                })
                                                            } else {
                                                                message.error('其他支出类型生成失败！请登录后自行设置' +res.data.Message);             
                                                            }
                                                        })
                                                    } else {
                                                        message.error('费用支出类型生成失败！请登录后自行设置' +res.data.Message);             
                                                    }
                                                })
                                            } else {
                                                message.error('销售收入类型生成失败！请登录后自行设置' +res.data.Message);             
                                            }
                                        })
                                    } else {
                                        message.error('产品收入类型生成失败！请登录后自行设置' +res.data.Message);             
                                    }
                                })
                            } else {
                                message.error('客户类型生成失败！请登录后自行设置' +res.data.Message);             
                            }
                        })                                         
                    }else{
                        message.error('成本支出类型生成失败！请登录后自行设置' +res.data.Message);    
                    }
                })
            } else {
                message.error('注册失败!'+ registerRes.data.Message);             
            }            
            
        })        
    };
    //提交表单（失败）  
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <>
            <TaTop activeKey='2' />
            <div className={classes.registerBack}>
                <div className={classes.loginCenter}>
                    <div className={classes.loginLeft}>
                        <div>旅当家</div>
                        <div className={classes.loginTitle}>旅行社管理系统</div>
                    </div>
                    <div className={classes.loginRight}>
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{remember: true,}}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}   
                        >
                            <Form.Item
                                name="firm"
                                validateTrigger="onBlur"
                                rules={[{
                                    required: true,
                                    message: '请填写旅行社名称!',
                                },
                                ]}                            
                            >
                                <Input 
                                    prefix={<ShopOutlined  className="site-form-item-icon" />} 
                                    placeholder="请填写旅行社名称" 
                                    value={firm} 
                                    onChange={firmInput} 
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item
                                name="name"
                                validateTrigger="onBlur"
                                rules={[{
                                    required: true,
                                    message: '请填写系统管理员姓名!',
                                },
                                ]}                            
                            >
                                <Input 
                                    prefix={<UserOutlined  className="site-form-item-icon" />} 
                                    placeholder="请填写系统管理员姓名" 
                                    value={name} 
                                    onChange={nameInput} 
                                    size="large"
                                />
                            </Form.Item>                        
                            <Form.Item
                                name="phone"
                                validateTrigger="onBlur"
                                rules={[
                                        {
                                            required: true,
                                            message: '请填写手机号!',
                                        },
                                        {
                                            pattern: /^1[3-9]\d{9}$/,
                                            message: '请输入正确的手机号'
                                        }
                                    ]}                            
                            >
                                <Input 
                                    prefix={<MobileOutlined className="site-form-item-icon" />} 
                                    placeholder="请填写系统管理员手机号" 
                                    value={adminPhone} 
                                    onChange={adminPhoneInput} 
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Row gutter={5}>
                                    <Col span={14}>
                                        <Form.Item
                                            name="captcha"
                                            validateTrigger="onBlur"    
                                            noStyle
                                            rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入短信验证码!',
                                                    },
                                                    {
                                                        pattern: new RegExp('^\\d{6}$'),
                                                        message: '请输入正确的6位数字验证码!',
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    size="large" 
                                                    placeholder='请输入短信验证码' 
                                                    prefix={<KeyOutlined className="site-form-item-icon" />} 
                                                    value={adminCode} 
                                                    onChange={adminCodeInput} 
                                                />
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        {isNote ? <Button disabled size="large" >{time}秒后重新发送</Button> : <Button size="large"  onClick={getCodeBtn}>获取验证码</Button>}
                                    </Col>
                                </Row>
                            </Form.Item>                            
                            <Form.Item>
                                <Button type='primary' htmlType="submit" block size="large">免费试用</Button>                                
                            </Form.Item>
                            {/* <div className={classes.loginTips}>
                                <a onClick={() => {setOpenAdd(true)}}>还没有注册？请使用手机注册</a>
                                <a onClick={() => {navi('/')}}>返回首页</a>
                            </div> */}
                        </Form> 
                        
                    </div>
                </div>
            </div>
            <Foot />
        </>
    )
}

export default Register