import React, { useEffect, useState } from 'react'
import {  Image, message,  Space,  } from 'antd'
import TableCheck from '../../../../cpn/TableCheck'
import { useDijieBaoJiaDanXingChengDelMutation, useDijieBaoJiaDanXingChengListQuery } from '../../../../store/ReactWebSiteApi'
import ModalDel from '../../../../cpn/ModalDel'
import OpFileRouteEdit from './FileRouteEdit'
import FileRouteAdd from './FileRouteAdd'
function FileRoute(props) {
    const [list,setList] = useState()
    const {data,isSuccess} = useDijieBaoJiaDanXingChengListQuery({bjdxc_bjd_id:props.bjdxc_bjd_id})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                const arr = data.Result.map((item) => {
                    // console.log(data);
                    const {bjdxc_id:key,bjdxc_daoyoujihua,bjdxc_image,bjdxc_ctime,bjdxc_ck_time,bjdxc_name,bjdxc_type_id,bjdxc_day_num,bjdxc_content} = item
                    let img = bjdxc_image.map((item) => {
                        return item
                    })      
                    return item={key,bjdxc_daoyoujihua,img,bjdxc_ctime,bjdxc_ck_time,bjdxc_name,bjdxc_type_id,bjdxc_day_num,bjdxc_content}
                })
                const sortArr = arr.sort((a, b) => {
                    if (a.bjdxc_day_num === b.bjdxc_day_num) {
                        return a.bjdxc_ck_time.localeCompare(b.bjdxc_ck_time);
                    }                    
                    return a.bjdxc_day_num - b.bjdxc_day_num
                  });
                
                const handleData = (array, key) => {
                    if(array.length === 0) return
                    let arr = [...array]
                    // 1、startItem(默认rowSpan = 1)记录开始计数的对象
                    let startItem = arr[0]
                    startItem.rowSpan = 1
                    // 2、遍历数组，取下一项进行比较，当name相同则startItem的rowSpan+1, 否则设置新的startItem为下一项
                    arr.forEach((item, index) => {
                        let nextItem = arr[index+1] || {}
                        if(item[key] === nextItem[key]){
                            startItem.rowSpan++
                        }else{
                            startItem = nextItem
                            startItem.rowSpan = 1
                        }
                    })
                    return arr
                }
                let newArr=handleData(sortArr, 'bjdxc_day_num')
                setList(newArr)
            }else{
                setList([])
            }
        }
    },[data,isSuccess])
    //删除
    const [del] = useDijieBaoJiaDanXingChengDelMutation()
   
    const column = [
        {
            key:'1',
            title:'天数',
            dataIndex:'bjdxc_day_num',
            render:(text) => `第${text}天`,
            onCell: (row) => ({ rowSpan: row.rowSpan || 0 }),            
            width:'120px',
        },
        {
            key:'2',
            title:'参考时间',
            dataIndex:'bjdxc_ck_time',
            width:'120px',
        }, 
        {
            key:'13',
            title:'行程安排',
            dataIndex:'img',
            render:(text,record) => {     
                if(props.customType==='1'){
                    return <Space  direction='vertical'>
                                <div style={{fontSize:"16px",fontWeight:"600"}}>{record.bjdxc_name}</div>
                                <Space>
                                    {text && text.map((item) => {
                                        return <Image src={item} alt='' width={200} />
                                    })}
                                </Space>
                                <div>{record.bjdxc_content}</div>
                            </Space>
                }else{
                    return record.bjdxc_name
                }
            }
        },           
        {
            key:'4',
            title:'导游计划',
            dataIndex:'bjdxc_daoyoujihua',
            width:'120px',
        },              
        {
            key:'5',
            title:'操作',            
            width:'120px',
            render:(_,record) => {
                return<Space>
                    <OpFileRouteEdit
                        bjdxc_id={record.key}
                        bjdxc_bjd_id={props.bjdxc_bjd_id}
                    />
                    <ModalDel onOk={() => {
                        del({bjdxc_id:record.key}).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                </Space>
            }
        },        
    ]
    if(props.noAdd || props.preview){
        column.pop()
    }
    if(props.customType==='1' || props.customType==='6'){
        column.splice(3, 1)
    }
    return (    
        <TableCheck 
            title='行程安排'
            titleRight={(!props.noAdd && !props.preview) && <FileRouteAdd
                bjdxc_bjd_id={props.bjdxc_bjd_id}
                ltbjd_dept_id={props.ltbjd_dept_id}
                />}
            columns={column}
            dataSource={list}
            size={props.customType==='1'?'middle':'small'}
        />
    )
}

export default FileRoute