import React,{useState,useEffect,}from 'react'
import Main from '../../cpn/Main'
import { Table,message,Modal} from 'antd';
import { useApplyforInfoDetailMutation, useApplyforInfoListQuery,} from '../../store/ReactWebSiteApi';

function WebApply() {
    //试用申请列表-----------------------------------------------------------------------------------------------------------------------------试用申请列表
    
    const {data,isSuccess} = useApplyforInfoListQuery({eail_uid:localStorage.getItem('eu_id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                // console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {eai_id:key,eai_name,eai_content,eai_company_name,eai_ctime,eai_phone,user_read_data} = item;
                    return item = {key,eai_name,eai_content,eai_company_name,eai_ctime,eai_phone,user_read_data}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eai_ctime;
                    let bTimeString = b.eai_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    //查看
    const [submit] = useApplyforInfoDetailMutation()
    //详情框
    const [open,setOpen] = useState(false);
    const [info,setInfo] = useState()
    const modal = <Modal
                    open={open}
                    title='申请详情'
                    okText="提交"
                    cancelText="取消"
                    onCancel={() => {setOpen(false);}}
                    width={1000}                    
                >
                    {info}
                </Modal>
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },       
        {
            key: 'eai_company_name',
            title: '旅行社名称',
            dataIndex: 'eai_company_name',
            render:(e,f) => {
                return <>
                    <a onClick={() => {
                        setInfo(f.eai_content)
                        submit({
                            eail_eai_id: f.key,
                            eail_uid: localStorage.getItem('eu_id'),
                            eail_uname: localStorage.getItem('eu_name'),
                        }).then((res) => {
                            // console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('已读成功！')   
                                setOpen(true)                             
                            }
                            if (res.data.Status === 'fail') {
                                message.error('已读失败！'+res.data.Message)
                            }
                        })
                    }}>{e}</a>
                </>
            },
        },                 
        {
            key: 'eai_name',
            title: '联系人姓名',
            dataIndex: 'eai_name',
        },       
        {
            key: 'eai_phone',
            title: '手机号码',
            dataIndex: 'eai_phone',
        },       
        {
            key: 'eai_content',
            title: '备注',
            dataIndex: 'eai_content',
        },       
        {
            key: 'user_read_data',
            title: '未读/已读',
            dataIndex: 'user_read_data',
            render:(e) => {
                if(e){
                    return <>{e.eail_uname+e.eail_ctime}</>                  
                }else{
                    return <>未读</>
                }
            }
        },       
        {
            key: 'time',
            title: '新增',
            render: (_,record) => {
                return <>
                    <div>{record.eai_ctime}</div>
                </>
            }
        },
    ]
    
    return (
        <Main t1='商城' n1='/GfIndexMall' t2='官网' n2='/WebIndex' t3='意见反馈'>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modal}
        </Main>
    )
}

export default WebApply