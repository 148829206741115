import React,{useState,useEffect,}from 'react'
import Main from '../cpn/Main'
import { Table,Button,Space,Modal,message,Form} from 'antd';
import {
    usePhotoAlbumSYSAddMutation,
    usePhotoAlbumSYSDelMutation,
    usePhotoAlbumSYSListQuery
} from '../store/ReactWebSiteApi';
import { useSearchParams } from 'react-router-dom';
import classes from './album.module.css'
import GetUser from '../cpn/GetUser';
function AlbumMan() {
    const [param] = useSearchParams()
    //新增摄影师-----------------------------------------------------------------------------------------------------------------------------新增摄影师
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [infoAdd,setInfoAdd] = useState({
        epau_content:'',
    });
    const [submitAdd] = usePhotoAlbumSYSAddMutation()
    const modalAdd= <Modal
                        open={openAdd}
                        title='新增摄影师'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClient(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitAdd({
                                epau_epa_id:param.get('aid'),
                                epau_epag_id:param.get('gid'),
                                epau_eu_id:infoAdd.epau_eu_id,
                                epau_uid:localStorage.getItem('eu_id'),
                                epau_uname:localStorage.getItem('eu_name'),
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpenAdd(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                        >                            
                            <GetUser label='用户' getUser={(e) => {setInfoAdd(prevState=>({...prevState,epau_eu_id:e}))}}/>
                        </Form>
                    </Modal> 
    
    //删除摄影师-----------------------------------------------------------------------------------------------------------------------------删除摄影师
    const {confirm} = Modal; 
    const [submitDel] = usePhotoAlbumSYSDelMutation()
    //摄影师列表-----------------------------------------------------------------------------------------------------------------------------摄影师列表
    
    const {data,isSuccess} = usePhotoAlbumSYSListQuery({epau_epag_id:param.get('gid'),})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){     
            // //console.log(data);       
            if(data.Status==='success'){
                
                
                const newArr = data.Result.map(item => {
                    const {epau_id:key,eu_name,eu_ba_phone,epau_content,epau_uname,epau_ctime,epau_update_ctime,epau_update_uname,goods_num} = item;
                    return item = {key,eu_name,eu_ba_phone,epau_content,epau_uname,epau_ctime,epau_update_ctime,epau_update_uname,goods_num}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.epau_ctime;
                    let bTimeString = b.epau_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])    
    const columns = [
        // {
        //     key: 'id',
        //     title: '编号',
        //     dataIndex: 'key'
        // },
       
        {
            key: 'type',
            title: '用户',
            dataIndex: 'eu_name',
        },                                          
        {
            key: 'eu_ba_phone',
            title: '手机号码',
            dataIndex: 'eu_ba_phone',
        },                                          
                                           
        // {
        //     key: 'time',
        //     title: '操作记录',
        //     render: (_,record) => {
        //         return <>
        //             <div>新建时间：{record.epau_ctime + record.epau_uname}</div>
        //             <div>修改时间：{record.epau_update_ctime + record.epau_update_uname}</div>
        //         </>
        //     }
        // },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <Space >  
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({epau_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                    console.log('Cancel');
                                },
                            });
                        }}>删除</a>                       
                    </Space>
            }
        },
    ]
    


    return (
        <Main t1='相册' t2={param.get('name')} t3={param.get('gname')} t4='摄影师' n1={`/AlbumGroup?esv_id=7&id=${param.get('aid')}&name=${param.get('aname')}`}>
            <div className={classes.buttonRow}>
                <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增</Button>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalAdd}
        </Main>
    )
}

export default AlbumMan