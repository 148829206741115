import { Form, Radio } from 'antd'
import React from 'react'

function GetFileType(props) {
    return (
        <Form.Item 
            label={props.label} 
            wrapperCol={props.wrapperCol} 
            required={props.required}
            name={props.name}
        >
            <Radio.Group 
                options={props.options}
                optionType='button'
                onChange={(e) => {props.getOpFileType(e.target.value)}}
            />
        </Form.Item>
    )
}

export default GetFileType