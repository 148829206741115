import { Descriptions, Divider, Flex, Modal } from 'antd';
import React, { useState } from 'react'
import CustomCustomLine from './CustomGoodsLine';

function CustomGoodsCheckOrgan() {
    const [open,setOpen] = useState(false)
    const items = [        
        {
            key: '5',
            label: '商品名称',
            children: '北京3-10天高端定制，承接各种会议研学亲子',
        },                  
        {
            key: '15',
            label: '出发城市',
            children: '北京',
        },                  
    ]
    return (
        <>
            <a onClick={() => {setOpen(true)}}> 北京3-10天高端定制，承接各种会议研学亲子</a>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {}}
                footer={null}
                width={1200}
            >
                <div  style={{border:'2px solid #eee',margin:"10px"}}>
                    <Flex justify='center'><h2>商品信息</h2></Flex>
                    <Descriptions items={items} bordered />
                    <Divider>参考线路</Divider>
                    <CustomCustomLine />                    
                </div>
            </Modal>
        </>
    )
}

export default CustomGoodsCheckOrgan