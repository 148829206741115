import React from 'react'
import { message,} from 'antd'
import { useDuizhangDelMutation,} from '../../store/ReactWebSiteApi';
import ModalDel from '../../cpn/ModalDel';

function ArapStatementSendDel(props) {
    const [submitDel] = useDuizhangDelMutation()   
    return (
        <>
            {
                (!props.edz_audit_ctime && !props.edz_affirm_ctime) && <ModalDel onOk={() => {
                    submitDel({edz_id:props.edz_id}).then((res) => {
                        // console.log(res);
                        if (res.data.Status === 'success') {
                            message.info('删除成功！');
                        }
                        if (res.data.Status === "fail") {
                            message.error('删除失败！'+res.data.Message)
                        }
                    })
                }}/>
            }
        </>
    )
}

export default ArapStatementSendDel