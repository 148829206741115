import React,{useState}from 'react'
import { Button, Form,Input,message, Modal} from 'antd';
import { useShangpinTypeAddMutation,} from '../../store/ReactWebSiteApi';
function CommodityTypeAdd(props) {
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();  
    const [info,setInfo] = useState();
    const [submitAdd] = useShangpinTypeAddMutation()
    return (
        <>
            <Button type='primary' onClick={() => {setOpen(true)}}>新增</Button>
            <Modal 
                open={open}
                title='新增商品类型'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    console.log(props);
                    submitAdd({
                        spt_uid:localStorage.getItem('eu_id'),
                        spt_uname:localStorage.getItem('eu_name'),
                        spt_parent_id:props.spt_parent_id,
                        spt_name:info.spt_name,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 6 }}
                >                                           
                    <Form.Item name="remark" label='名称' wrapperCol={{span:10}}>
                        <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,spt_name:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default CommodityTypeAdd