import React,{useState,useEffect,}from 'react'
import { Table,Space,} from 'antd';
import {  useNavigate, } from 'react-router-dom';
import Main from '../cpn/Main'
import {useWorkPlanListQuery,  } from '../store/ReactWebSiteApi';
import TrainPlanAdd from './TrainPlanAdd';
import TrainPlanEdit from './TrainPlanEdit';
import TrainPlanDel from './TrainPlanDel';
import TrainPlanCheck from './TrainPlanCheck';
import { indexPersonnel } from '../finance/balance/balanceType';
function TrainPlan() {
    const {data,isSuccess} = useWorkPlanListQuery({ewp_e_id:localStorage.getItem('e_id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                // console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {ewp_id:key,ewp_name,ewp_content,ewp_uname,ewp_ctime,ewp_update_ctime,ewp_update_uname,cs_num,cet_num,ewp_file_file,ewp_file_name} = item;
                    return item = {key,ewp_name,ewp_content,ewp_uname,ewp_ctime,ewp_update_ctime,ewp_update_uname,cs_num,cet_num,ewp_file_file,ewp_file_name}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ewp_ctime;
                    let bTimeString = b.ewp_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])  
    const navi = useNavigate();  
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'type',
            title: '计划名称',
            render:(_,record) => {
                return  <TrainPlanCheck 
                            ewp_name={record.ewp_name}
                            ewp_content={record.ewp_content}
                            ewp_file_file={record.ewp_file_file}
                            ewp_file_name={record.ewp_file_name}
                        />
            }
        },     
        {
            key: 'cs_num',
            title: '测试题',
            dataIndex: 'cs_num',
        },     
        {
            key: 'cet_num',
            title: '培训任务',
            dataIndex: 'cet_num',
        },     
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    {record.ewp_ctime + record.ewp_uname}
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space>                                               
                        <TrainPlanEdit ewp_id={record.key} />
                        <TrainPlanDel cs_num={record.cs_num} cet_num={record.cet_num} ewp_id={record.key}/>
                        <a onClick={() =>{navi(`/TrainQuestion?esv_id=5&id=${record.key}&name=${record.ewp_name}`, { state: {openKeys:'performance',active:"TaskModel"}})}}>测试题</a>
                        <a onClick={() =>{navi(`/TrainTask?esv_id=5&id=${record.key}&name=${record.ewp_name}`, { state: {openKeys:'performance',active:"TaskModel"}})}}>培训任务</a>
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main  t1='人事' n1={indexPersonnel} t2='培训' n2='/TrainIndex' t3='培训计划'>
            <Space>
                <TrainPlanAdd />
            </Space>
            <Table columns={columns} style={{marginTop:'10px'}} dataSource={list}></Table> 
        </Main>
    )
}

export default TrainPlan