import React from 'react'
import { Button, Space,Divider, Row, Col, Card,} from 'antd';
import Foot from './Foot';
import TaTop from './TaTop';
import { useNavigate } from 'react-router-dom';
function TaHome() { 
    const navi= useNavigate()      
    const logo = [
        {
            src:'./img/home/chrome.png',
            key:'1'
        },
        {
            src:'./img/home/nginx.png',
            key:'2'
        },
        {
            src:'./img/home/react.png',
            key:'3'
        },
        {
            src:'./img/home/php.png',
            key:'4'
        },
        {
            src:'./img/home/mySql.png',
            key:'5'
        },
        {
            src:'./img/home/tencent.png',
            key:'6'
        },

    ]
    return (
        <>
            <TaTop />
            <div style={{display:'flex',alignItems:"center",justifyContent:'center',height:'500px'}}>
                <Space direction='vertical' align='center' size='large'>
                    <img alt='' src='./img/home/logo.png' width='80px' />
                    <div style={{fontSize:'60px',fontWeight:"bolder",letterSpacing:"16px"}} >旅当家</div>
                    <div style={{fontSize:'80px',fontWeight:"bolder",letterSpacing:"6px"}}>人人会用的旅行社管理系统</div>
                    <Space size='large' style={{marginTop:'40px'}}>
                        <Button size='large' type='primary' onClick={() => {navi('/TaPriceFinance')}}>快速上手</Button>
                        <Button size='large' color='warning' onClick={() => {navi('/Register')}}>免费试用</Button>
                    </Space>
                </Space>
            </div>
            <Row style={{backgroundColor:"#eee",padding:'60px 0'}}>
                <Col span={6}></Col>
                <Col span={4} style={{padding:"3px"}}>
                    <Card title='易学易用' bordered={false}>
                        <div>基于旅行社真实的管理需求打造，提供容易上手的交互界面和一流的教程。</div>
                    </Card>
                </Col>
                <Col span={4} style={{padding:"3px"}}>
                    <Card title='性价比高' bordered={false}>
                        <div>不限部门和用户数，系统各模块可以单独运行，旅行社可购买一个或者多个模块。</div>
                    </Card>
                </Col>
                <Col span={4} style={{padding:"3px"}}>
                    <Card title='安全可靠' bordered={false}>
                        <div>采用内部数据和外部访问完全隔离的设计思路，保证旅行社的信息安全。</div>
                    </Card>
                </Col>
                <Col span={6}></Col>
            </Row>
            <Divider>技术支持</Divider>
            <Row>
                <Col span={6}></Col>
                <Col span={12}>
                    <Row style={{backgroundColor:"#fff"}}>
                        {logo.map(item=>{
                            return <Col span={8} style={{background:"#e8e8e8",textAlign:'center',border:'3px solid #fff'}} key={item.key}>
                                <img src={item.src} alt='' />
                            </Col>
                        })}
                    </Row>
                </Col>
                <Col span={6}></Col>
            </Row>
            <Divider>旅行社</Divider>
            <Row>
                <Col span={5}></Col>
                <Col span={14}>
                    <img src='./img/home/service.jpg' alt='' width={'100%'}/>
                </Col>
                <Col span={5}></Col>
            </Row>
            <Foot />
        </>
    )
}

export default TaHome