import { Select,Form, Space} from 'antd'
import React, { useEffect, useState } from 'react'
import { useGoodsTypeListQuery } from '../../store/ReactWebSiteApi';
import SetGoodsType from './SetGoodsType';

function GetGoodsType(props) {
    const [list,setList] = useState();
    const {data, isSuccess} = useGoodsTypeListQuery({egt_e_id:localStorage.getItem('e_id'),egt_esv_id:props.egt_esv_id});
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                //console.log(data);
                const newArr = data.Result.map((item) => {
                    const {egt_id,egt_name,}=item;
                    return item = {value:egt_id,label:egt_name}
                })
                setList(newArr)
            }
        }
    },[data,isSuccess])
    return (
        <>
            <Form.Item
                label={props.label} 
                wrapperCol={props.wrapperCol} 
                required={props.required} 
                style={{marginBottom:"0"}}
            >
                <Space style={{width:"300px"}}>
                    <Form.Item        
                        name={props.name}
                        style={{width:'250px'}}
                    >                
                        <Select 
                            options={list} 
                            onChange={(_,f) => {
                                // console.log(f);
                                props.getGoodsType(f.value,f.label)
                            }}
                            placeholder={props.defaultId}
                            style={props.style}
                        />            
                    </Form.Item>
                    <SetGoodsType />
                </Space>
            </Form.Item>
        </>
    )
}

export default GetGoodsType