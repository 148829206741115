import React from 'react'
import Main from '../../cpn/Main'
import { Row } from 'antd';
import Folder from '../../cpn/Folder';
function WebIndex() {
    return (
        <Main t1='商城' n1='/GfIndexMall' t2='官网'>
            <Row>
                <Folder title='教程' link='/WebGoods'/>
                <Folder title='教程分类' link='/WebGoodsType'/>
                <Folder title='新闻' link='/WebNews'/>
                <Folder title='新闻类型' link='/WebNewsType'/>
                <Folder title='意见反馈' link='/WebApply'/>
            </Row>
        </Main>
    )
}

export default WebIndex