import React, { useState }  from 'react'
import TaTop from './TaTop'
import {  Layout, Menu,} from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import Foot from './Foot';
const {  Content,  Sider } = Layout;
const list = [
    {
        key:'1',
        label:'财务模块',
        children:[
            {
                key:'11',
                label:"功能及价格"
            },
            // {
            //     key:'12',
            //     label:"教程",
            //     children:[
            //         {
            //             key:'111',
            //             label:'系统设置'
            //         }
            //     ]
            // },
            {
                key:'13',
                label:"更新日志"
            },
        ]
    },
    // {
    //     key:'2',
    //     label:'定制游模块',
    //     children:[
    //         {
    //             key:'21',
    //             label:"功能及价格"
    //         },
    //     ]
    // },
]
const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
};
const levelKeys = getLevelKeys(list);
function TaPrice(props) {
    const navi = useNavigate() 
    const [stateOpenKeys, setStateOpenKeys] = useState([]);
    const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
    // open
    if (currentOpenKey !== undefined) {
        const repeatIndex = openKeys
          .filter((key) => key !== currentOpenKey)
          .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
            setStateOpenKeys(
            openKeys
                // remove repeat key
                .filter((_, index) => index !== repeatIndex)
                // remove current level all child
                .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
            );
        } else {
            // close
            setStateOpenKeys(openKeys);
        }
    };
    return (
        <>
            <TaTop activeKey='1'></TaTop>
            <Content style={{padding: '0 10px',}}>                
                <Layout
                    style={{padding: '24px 0',background: '#fff',borderRadius:'20px',}}
                >
                    <Sider
                        style={{background: '#fff',}}
                        width={300}
                    >
                        <Menu
                            mode="inline"
                            style={{height: '100%',fontSize:"20px"}}
                            items={list}
                            onClick={(e) => {
                                if(e.key==='1'){
                                }                                
                                if(e.key==='11'){
                                    navi('/TaPrice/TaPriceFinance')
                                }                                
                                if(e.key==='13'){
                                    navi('/TaPrice/TaPriceUpdate')
                                }
                                if(e.key==='21'){
                                    navi('/TaPriceCustom')                                    
                                }
                            }}
                            openKeys={stateOpenKeys}
                            onOpenChange={onOpenChange}                                           
                        />
                    </Sider>
                    <Content
                        style={{
                            padding: '0 50px',
                            minHeight: 280,
                        }}
                    >
                       <Outlet></Outlet>                      
                    </Content>
                </Layout>
            </Content>
            <Foot />
        </>
    )
}

export default TaPrice