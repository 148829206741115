import React from 'react'
import { Modal,message,} from 'antd';
import {  usePhotoAlbumDelMutation,} from '../store/ReactWebSiteApi';
function AlbumDel(props) {
    //一键真删除相册-----------------------------------------------------------------------------------------------------------------------------删除相册
    const {confirm} = Modal; 
    const [submitDel] = usePhotoAlbumDelMutation()
    return (
        <>
            <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({epa_id:props.epa_id}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>
        </>
    )
}

export default AlbumDel