import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import form_data from "./formData";


const ReactWebSiteApi = createApi({
    reducerPath: 'ReactWebSiteApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/ReactWebSite'        
    }),
    tagTypes:[
        'user',
        'gwGoods',
        'dept',
    'gwGoodsTypeSub',
    'news',
    'system',
    'handleLimit',
    'goodsSpec',
    'goodsSpecSub',
    'entApp',
    'goods',
    'contract',
    'incomeExpenditure',
    'workPlan',
    'test',
    'topic',
    'client',
    'clientType',
    'incomeExpenditureHx',
    'entAdmin',
    'ent',
    'purviewSetting',
    'gwGoodsType',
    'goodsType',
    'financeSubType',
    'photoAlbum',
    'photoAlbumGroup',
    'photoAlbumGroupUpload',
    'capitalAccountDetail',
    'capitalAccount',
    'dijieBaoJiaDanJieDaiBiaoZhun',
    ],
    endpoints(build) {
        return {
            //新增用户
            user_add: build.mutation({
                query(user) {
                    return {
                        url: '/user_add',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['user']
            }),
            //删除用户
            user_del: build.mutation({
                query(user) {
                    return {
                        url: '/user_del',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['user']
            }),
            //多级部门用户
            all_dept_user: build.query({
                query(user) {
                    return {
                        url: '/all_dept_user',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['user']
            }),
            //企业用户
            entUserList: build.query({
                query(user) {
                    return {
                        url: '/entUserList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['user']
            }),
            //修改用户
            user_update: build.query({
                query(user) {
                    return {
                        url: '/user_update',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['user']
            }),
            //修改保存用户
            user_update_save: build.mutation({
                query(user) {
                    return {
                        url: '/user_update_save',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['user']
            }),
            //获取用户部门操作权限
            getUserDeptManage: build.query({
                query(user) {
                    return {
                        url: '/getUserDeptManage',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['user']
            }),
            //新增部门
            dept_add: build.mutation({
                query(user) {
                    return {
                        url: '/dept_add',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dept']
            }),
            //修改保存部门
            dept_update_save: build.mutation({
                query(user) {
                    return {
                        url: '/dept_update_save',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dept']
            }),
            //删除部门
            dept_del: build.mutation({
                query(user) {
                    return {
                        url: '/dept_del',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dept']
            }),
            //修改部门
            dept_update: build.query({
                query(user) {
                    return {
                        url: '/dept_update',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dept']
            }),
            //多级部门
            all_dept: build.query({
                query(user) {
                    return {
                        url: '/all_dept',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dept']
            }),
            //单级部门列表
            deptSubDeptAndUser: build.query({
                query(user) {
                    return {
                        url: '/deptSubDeptAndUser',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dept']
            }),
            //获取企业全部部门
            getEnterpriseDept: build.query({
                query(user) {
                    return {
                        url: '/getEnterpriseDept',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dept']
            }),
            //修改用户状态
            user_state: build.mutation({
                query(user) {
                    return {
                        url: '/user_state',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['user']
            }),
            //新增新闻
            addNews: build.mutation({
                query(user) {
                    return {
                        url: '/newsAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['news']
            }),
            //新闻列表
            newsList: build.query({
                query(user) {
                    return {
                        url: '/newsList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['news']
            }),
            //新闻修改
            newsUpdate: build.query({
                query(user) {
                    return {
                        url: '/newsUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['news']
            }),
            //修改保存新闻
            updateSaveNews: build.mutation({
                query(user) {
                    return {
                        url: '/newsUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['news']
            }),
            //删除新闻
            delNews: build.mutation({
                query(user) {
                    return {
                        url: '/newsDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['news']
            }),
            //上下架新闻
            newsState: build.mutation({
                query(user) {
                    return {
                        url: '/newsState',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['news']
            }),
            //新增系统
            addSystem: build.mutation({
                query(user) {
                    return {
                        url: '/systemAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['system']
            }),
            //系统列表
            systemList: build.query({
                query(user) {
                    return {
                        url: '/systemList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['system']
            }),
            //系统修改
            systemUpdate: build.query({
                query(user) {
                    return {
                        url: '/systemUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['system']
            }),
            //修改保存系统
            updateSaveSystem: build.mutation({
                query(user) {
                    return {
                        url: '/systemUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['system']
            }),
            //删除系统
            delSystem: build.mutation({
                query(user) {
                    return {
                        url: '/systemDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['system']
            }),
            //上下级系统
            systemState: build.mutation({
                query(user) {
                    return {
                        url: '/systemState',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['system']
            }),
            //用户操作权限修改
            userOperateManageUpdate: build.query({
                query(user) {
                    return {
                        url: '/userOperateManageUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['handleLimit']
            }),
            //修改保存用户操作权限
            userOperateManageUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/userOperateManageUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['handleLimit']
            }),
            //短信验证码登录
            userLogin: build.mutation({
                query(user) {
                    return {
                        url: '/userLogin',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //密码登录
            userMmLogin: build.mutation({
                query(user) {
                    return {
                        url: '/userMmLogin',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //登录短信验证码
            loginCode: build.mutation({
                query(user) {
                    return {
                        url: '/loginCode',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //注册
            userRegister: build.mutation({
                query(user) {
                    return {
                        url: '/userRegister',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //注册短信验证码
            registerCode: build.mutation({
                query(user) {
                    return {
                        url: '/registerCode',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //自动生成产品类型
            autoGoodsType: build.mutation({
                query(user) {
                    return {
                        url: '/autoGoodsType',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //自动生成客户类型
            autoClientType: build.mutation({
                query(user) {
                    return {
                        url: '/autoClientType',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //自动生成销售收入类型
            autoXsIncomeType: build.mutation({
                query(user) {
                    return {
                        url: '/autoXsIncomeType',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //自动生成成本支出类型
            autoCbExpenditureType: build.mutation({
                query(user) {
                    return {
                        url: '/autoCbExpenditureType',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //自动生成费用类型
            autoCostType: build.mutation({
                query(user) {
                    return {
                        url: '/autoCostType',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //自动生成其他收入类型
            autoQtIncomeType: build.mutation({
                query(user) {
                    return {
                        url: '/autoQtIncomeType',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //自动生成其他支出类型
            autoQtExpenditureType: build.mutation({
                query(user) {
                    return {
                        url: '/autoQtExpenditureType',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //切换企业
            entSwitchover: build.query({
                query(user) {
                    return {
                        url: '/entSwitchover',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //切换部门
            deptSwitchover: build.query({
                query(user) {
                    return {
                        url: '/deptSwitchover',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //新增规格
            addGoodsSpec: build.mutation({
                query(user) {
                    return {
                        url: '/goodsSpecAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['goodsSpec']
            }),
            //规格列表
            goodsSpecList: build.query({
                query(user) {
                    return {
                        url: '/goodsSpecList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['goodsSpec']
            }),
            //规格修改
            goodsSpecUpdate: build.query({
                query(user) {
                    return {
                        url: '/goodsSpecUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['goodsSpec']
            }),
            //修改保存规格
            updateSaveGoodsSpec: build.mutation({
                query(user) {
                    return {
                        url: '/goodsSpecUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['goodsSpec']
            }),
            //删除规格
            delGoodsSpec: build.mutation({
                query(user) {
                    return {
                        url: '/goodsSpecDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['goodsSpec']
            }),
            //新增子规格
            addGoodsSpecSup: build.mutation({
                query(user) {
                    return {
                        url: '/goodsSpecSubAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['goodsSpecSub','goodsSpec']
            }),
            //子规格列表
            goodsSpecSubList: build.query({
                query(user) {
                    return {
                        url: '/goodsSpecSubList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['goodsSpecSub']
            }),
            //子规格修改
            goodsSpecSubUpdate: build.query({
                query(user) {
                    return {
                        url: '/goodsSpecSubUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['goodsSpecSub']
            }),
            //修改保存子规格
            updateSaveGoodsSpecSup: build.mutation({
                query(user) {
                    return {
                        url: '/goodsSpecSubUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['goodsSpecSub']
            }),
            //删除子规格
            delGoodsSpecSup: build.mutation({
                query(user) {
                    return {
                        url: '/goodsSpecSubDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['goodsSpecSub','goodsSpec']
            }),
            //新增桌面应用
            entAppAdd: build.mutation({
                query(user) {
                    return {
                        url: '/entAppAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['entApp']
            }),
            //删除桌面应用
            entAppDel: build.mutation({
                query(user) {
                    return {
                        url: '/entAppDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['entApp']
            }),
            //上传用户应用打开数量
            userUseAppLog: build.mutation({
                query(user) {
                    return {
                        url: '/userUseAppLog',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['entApp']
            }),
            //桌面应用列表
            entAppList: build.query({
                query(user) {
                    return {
                        url: '/entAppList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['entApp']
            }),
             //新增合同
             contractAdd: build.mutation({
                query(user) {
                    return {
                        url: '/contractAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['contract']
            }),
            //合同列表
            contractList: build.query({
                query(user) {
                    return {
                        url: '/contractList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['contract']
            }),
            //合同修改
            contractUpdate: build.query({
                query(user) {
                    return {
                        url: '/contractUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['contract']
            }),
            //修改保存合同
            contractUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/contractUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['contract']
            }),
            //删除合同
            contractDel: build.mutation({
                query(user) {
                    return {
                        url: '/contractDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['contract']
            }),
            //合同操作员
            getContractUid: build.query({
                query(user) {
                    return {
                        url: '/getContractUid',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['contract']
            }),
            //新增产品
            goodsAdd: build.mutation({
                query(user) {
                    return {
                        url: '/goodsAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['goods']
            }),
            //产品列表
            goodsList: build.query({
                query(user) {
                    return {
                        url: '/goodsList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['goods']
            }),
            //产品修改
            goodsUpdate: build.query({
                query(user) {
                    return {
                        url: '/goodsUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['goods']
            }),
            //修改保存产品
            goodsUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/goodsUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['goods']
            }),
            //删除产品
            goodsDel: build.mutation({
                query(user) {
                    return {
                        url: '/goodsDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['goods']
            }),
            //新增流水号
            businessNumAdd: build.mutation({
                query(user) {
                    return {
                        url: '/businessNumAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['businessNum']
            }),
            //流水号列表
            businessNumList: build.query({
                query(user) {
                    return {
                        url: '/businessNumList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['businessNum']
            }),
            //流水号修改
            businessNumUpdate: build.query({
                query(user) {
                    return {
                        url: '/businessNumUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['businessNum']
            }),
            //修改保存流水号
            businessNumUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/businessNumUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['businessNum']
            }),
            //删除流水号
            businessNumDel: build.mutation({
                query(user) {
                    return {
                        url: '/businessNumDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['businessNum',]
            }),
            //审核流水号
            businessNumAudit: build.mutation({
                query(user) {
                    return {
                        url: '/businessNumAudit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['businessNum','incomeExpenditure']
            }),
            //取消审核流水号
            businessNumAuditCancel: build.mutation({
                query(user) {
                    return {
                        url: '/businessNumAuditCancel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['businessNum','incomeExpenditure']
            }),
            
            //获取流水号操作员
            getBusinessNumUid: build.query({
                query(user) {
                    return {
                        url: '/getBusinessNumUid',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['businessNum']
            }),
            //团号列表（相册）
            businessNumPhotoList: build.query({
                query(user) {
                    return {
                        url: '/businessNumPhotoList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['businessNum']
            }),
            //团号列表（评价）
            businessNumEvaluateList: build.query({
                query(user) {
                    return {
                        url: '/businessNumEvaluateList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['businessNum']
            }),
            //获取团号
            ebnJsonp: build.query({
                query(user) {
                    return {
                        url: '/ebnJsonp',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['businessNum']
            }),
            //台账统计部门
            ebnStatistics: build.query({
                query(user) {
                    return {
                        url: '/ebnStatistics',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['businessNum','incomeExpenditure']
            }),
            //台账统计客户
            clientStatistics: build.query({
                query(user) {
                    return {
                        url: '/clientStatistics',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['businessNum','incomeExpenditure']
            }),
            //台账统计产品
            goodsStatistics: build.query({
                query(user) {
                    return {
                        url: '/goodsStatistics',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['businessNum','incomeExpenditure']
            }),
            //台账统计操作员
            uidStatistics: build.query({
                query(user) {
                    return {
                        url: '/uidStatistics',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['businessNum','incomeExpenditure']
            }),
            //台账统计销售员
            ywStatistics: build.query({
                query(user) {
                    return {
                        url: '/ywStatistics',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['businessNum','incomeExpenditure']
            }),
            //台账统计产品类型
            goodsTypeStatistics: build.query({
                query(user) {
                    return {
                        url: '/goodsTypeStatistics',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['businessNum','incomeExpenditure']
            }),
            //台账统计客户类型
            ecTypeStatistics: build.query({
                query(user) {
                    return {
                        url: '/ecTypeStatistics',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['businessNum','incomeExpenditure']
            }),
            //业务员列表
            businessNumYwList: build.query({
                query(user) {
                    return {
                        url: '/businessNumYwList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['businessNum']
            }),
            //获取流水号部门
            getBusinessDept: build.query({
                query(user) {
                    return {
                        url: '/getBusinessDept',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['businessNum']
            }),
            //新增收支
            incomeExpenditureAdd: build.mutation({
                query(user) {
                    return {
                        url: '/incomeExpenditureAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['incomeExpenditure','businessNum']
            }),
            //收支列表
            incomeExpenditureList: build.query({
                query(user) {
                    return {
                        url: '/incomeExpenditureList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditure']
            }),
            //收支修改
            incomeExpenditureUpdate: build.query({
                query(user) {
                    return {
                        url: '/incomeExpenditureUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditure','businessNum']
            }),
            //修改保存收支
            incomeExpenditureUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/incomeExpenditureUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['incomeExpenditure','businessNum']
            }),
            //删除收支
            incomeExpenditureDel: build.mutation({
                query(user) {
                    return {
                        url: '/incomeExpenditureDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['incomeExpenditure']
            }),
            //审核收支
            incomeExpenditureAudit: build.mutation({
                query(user) {
                    return {
                        url: '/incomeExpenditureAudit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['incomeExpenditure','incomeExpenditureHx']
            }),
            //取消审核收支
            incomeExpenditureCancelAudit: build.mutation({
                query(user) {
                    return {
                        url: '/incomeExpenditureCancelAudit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['incomeExpenditure','incomeExpenditureHx']
            }),
            //收支转团
            incomeExpenditureChangeTeam: build.mutation({
                query(user) {
                    return {
                        url: '/incomeExpenditureChangeTeam',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['incomeExpenditure',]
            }),
            //其他收支部门汇总
            qtszDeptProfit: build.query({
                query(user) {
                    return {
                        url: '/qtszDeptProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditure']
            }),
            //单类型收支部门汇总
            fyDeptProfit: build.query({
                query(user) {
                    return {
                        url: '/fyDeptProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditure']
            }),
            //部门净利润
            fyDeptJingProfit: build.query({
                query(user) {
                    return {
                        url: '/fyDeptJingProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditure']
            }),
            //单类型收支类型汇总
            fyTypeProfit: build.query({
                query(user) {
                    return {
                        url: '/fyTypeProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditure']
            }),
            //单类型收支客户汇总
            fyClientProfit: build.query({
                query(user) {
                    return {
                        url: '/fyClientProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditure']
            }),
            //其他收支客户汇总
            qtszClientProfit: build.query({
                query(user) {
                    return {
                        url: '/qtszClientProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditure']
            }),
            //收支操作员
            getIncomeExpenditureUid: build.query({
                query(user) {
                    return {
                        url: '/getIncomeExpenditureUid',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditure']
            }),
             //新增培训计划
             workPlanAdd: build.mutation({
                query(user) {
                    return {
                        url: '/workPlanAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['workPlan']
            }),
            //删除培训计划
            workPlanDel: build.mutation({
                query(user) {
                    return {
                        url: '/workPlanDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['workPlan']
            }),
            //修改保存培训计划
            workPlanUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/workPlanUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['workPlan']
            }),           
            //培训计划列表
            workPlanList: build.query({
                query(user) {
                    return {
                        url: '/workPlanList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['workPlan']
            }),
            //培训计划修改
            workPlanUpdate: build.query({
                query(user) {
                    return {
                        url: '/workPlanUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['workPlan']
            }),
            //新增测试题
            topicAdd: build.mutation({
                query(user) {
                    return {
                        url: '/topicAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['topic']
            }),
            //删除测试题
            topicDel: build.mutation({
                query(user) {
                    return {
                        url: '/topicDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['topic']
            }),
            //修改保存测试题
            topicUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/topicUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['topic']
            }),           
            //测试题列表
            topicList: build.query({
                query(user) {
                    return {
                        url: '/topicList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['topic']
            }),
            //测试题修改
            topicUpdate: build.query({
                query(user) {
                    return {
                        url: '/topicUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['topic']
            }),
            //新增培训任务
            testAdd: build.mutation({
                query(user) {
                    return {
                        url: '/testAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['test']
            }),
            //删除培训任务
            testDel: build.mutation({
                query(user) {
                    return {
                        url: '/testDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['test']
            }),
            //修改保存培训任务
            testUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/testUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['test']
            }),            
            //培训任务列表
            testList: build.query({
                query(user) {
                    return {
                        url: '/testList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['test']
            }),
            //培训任务修改
            testUpdate: build.query({
                query(user) {
                    return {
                        url: '/testUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['test']
            }),
            //我参加的培训任务
            mypxList: build.query({
                query(user) {
                    return {
                        url: '/mypxList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['test']
            }),
            //我参加的培训任务的评价问答
            mypxEvaluate: build.query({
                query(user) {
                    return {
                        url: '/mypxEvaluate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['topic']
            }),
            //保存我参加的培训任务的评价问答
            mypxEvaluateSave: build.mutation({
                query(user) {
                    return {
                        url: '/mypxEvaluateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['test']
            }), 
            //我负责的培训任务
            myResponsibleList: build.query({
                query(user) {
                    return {
                        url: '/myResponsibleList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['test']
            }), 
            //新增客户
            addClient: build.mutation({
                query(user) {
                    return {
                        url: '/clientAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['client']
            }),
            //客户列表
            clientList: build.query({
                query(user) {
                    
                    return {
                        url: '/clientList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['client']
            }),
            //客户修改
            clientUpdate: build.query({
                query(user) {
                    return {
                        url: '/clientUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['client']
            }),
            //客户类型ID
            getClientID: build.query({
                query(user) {
                    return {
                        url: '/getClientID',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['client']
            }),
            //修改保存客户
            updateSaveClient: build.mutation({
                query(user) {
                    return {
                        url: '/clientUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['client']
            }),
            //删除客户
            delClient: build.mutation({
                query(user) {
                    return {
                        url: '/clientDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['client']
            }),
            //客户全部操作员
            getClientUid: build.query({
                query(user) {
                    return {
                        url: '/getClientUid',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['client']
            }),
            //新增客户类型
            clientTypeAdd: build.mutation({
                query(user) {
                    return {
                        url: '/clientTypeAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['clientType']
            }),
            //客户类型列表
            clientTypeList: build.query({
                query(user) {
                    
                    return {
                        url: '/clientTypeList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['clientType']
            }),
            //客户类型修改
            clientTypeUpdate: build.query({
                query(user) {
                    return {
                        url: '/clientTypeUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['clientType']
            }),
            //修改保存客户类型
            clientTypeUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/clientTypeUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['clientType']
            }),
            //删除客户类型
            clientTypeDel: build.mutation({
                query(user) {
                    return {
                        url: '/clientTypeDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['clientType']
            }),
            //新增工作计划
            performancePlanAdd: build.mutation({
                query(user) {
                    return {
                        url: '/performancePlanAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['performancePlan']
            }),
            //工作计划列表
            performancePlanList: build.query({
                query(user) {
                    
                    return {
                        url: '/performancePlanList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['performancePlan']
            }),
            //工作计划修改
            performancePlanUpdate: build.query({
                query(user) {
                    return {
                        url: '/performancePlanUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['performancePlan']
            }),
            //修改保存工作计划
            performancePlanUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/performancePlanUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['performancePlan']
            }),
            //删除工作计划
            performancePlanDel: build.mutation({
                query(user) {
                    return {
                        url: '/performancePlanDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['performancePlan']
            }),
            //新增工作任务
            taskAdd: build.mutation({
                query(user) {
                    return {
                        url: '/taskAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['task']
            }),
            //工作任务列表
            taskList: build.query({
                query(user) {
                    
                    return {
                        url: '/taskList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['task']
            }),
            //工作任务修改
            taskUpdate: build.query({
                query(user) {
                    return {
                        url: '/taskUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['task']
            }),
            //修改保存工作任务
            taskUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/taskUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['task']
            }),
            //删除工作任务
            taskDel: build.mutation({
                query(user) {
                    return {
                        url: '/taskDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['task']
            }),
            //我负责的工作任务
            myTaskList: build.query({
                query(user) {
                    return {
                        url: '/myTaskList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['task']
            }),
            //未下账列表
            incomeExpenditureHxList: build.query({
                query(user) {
                    return {
                        url: '/incomeExpenditureHxList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditureHx']
            }),
            //保存下账
            incomeExpenditureHxSave: build.mutation({
                query(user) {
                    return {
                        url: '/incomeExpenditureHxSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['incomeExpenditureHx']
            }),
            //取消下账
            incomeExpenditureHxCancel: build.mutation({
                query(user) {
                    return {
                        url: '/incomeExpenditureHxCancel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['incomeExpenditureHx']
            }),
            //已下账列表
            incomeExpenditureHxFinishList: build.query({
                query(user) {
                    return {
                        url: '/incomeExpenditureHxFinishList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditureHx']
            }),
            //获取下账凭证
            huoquxiazhangpingzhengList: build.query({
                query(user) {
                    return {
                        url: '/huoquxiazhangpingzhengList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditureHx']
            }),
            //已下账记录明细
            incomeExpenditureHxFinishLogList: build.query({
                query(user) {
                    return {
                        url: '/incomeExpenditureHxFinishLogList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditureHx']
            }),
            //往来部门汇总
            incomeExpenditureDeptStatistics: build.query({
                query(user) {
                    return {
                        url: '/incomeExpenditureDeptStatistics',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditureHx']
            }),
            //已下账部门利润
            wlDeptProfit: build.query({
                query(user) {
                    return {
                        url: '/wlDeptProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditureHx']
            }),
            //已下账客户利润
            wlClientProfit: build.query({
                query(user) {
                    return {
                        url: '/wlClientProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditureHx']
            }),
            //已下账客户类型利润
            wlClientTypeProfit: build.query({
                query(user) {
                    return {
                        url: '/wlClientTypeProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditureHx']
            }),
            //已下账操作员利润
            wlUidProfit: build.query({
                query(user) {
                    return {
                        url: '/wlUidProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditureHx']
            }),
            //已下账销售员利润
            wlywProfit: build.query({
                query(user) {
                    return {
                        url: '/wlywProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditureHx']
            }),
            //已下账产品类型利润
            wlGoodsTypeProfit: build.query({
                query(user) {
                    return {
                        url: '/wlGoodsTypeProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditureHx']
            }),
            //已下账产品利润
            wlGoodsProfit: build.query({
                query(user) {
                    return {
                        url: '/wlGoodsProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditureHx']
            }),
            //往来客户汇总
            incomeExpenditureClientStatistics: build.query({
                query(user) {
                    return {
                        url: '/incomeExpenditureClientStatistics',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditureHx']
            }),
            //新增企业
            entAdd: build.mutation({
                query(user) {
                    return {
                        url: '/entAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['ent']
            }),
            //企业列表
            entList: build.query({
                query(user) {
                    
                    return {
                        url: '/entList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['ent']
            }),
            //企业修改
            entUpdate: build.query({
                query(user) {
                    return {
                        url: '/entUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['ent']
            }),
            //修改保存企业
            entUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/entUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['ent']
            }),
            //修改企业用户状态
            entAdminState: build.mutation({
                query(user) {
                    return {
                        url: '/entAdminState',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['entAdmin']
            }),
            //企业应用列表
            entJyAppList: build.query({
                query(user) {
                    
                    return {
                        url: '/entJyAppList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['entMoney']
            }),
            //企业用户列表
            entAdminList: build.query({
                query(user) {
                    
                    return {
                        url: '/entAdminList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['entAdmin']
            }),
            //企业用户修改
            entAdminUpdate: build.query({
                query(user) {
                    return {
                        url: '/entAdminUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['entAdmin']
            }),
            //修改保存企业用户
            entAdminUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/entAdminUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['entAdmin']
            }),
            //金鱼/企业管理/账户管理/新增充值
            entMoneyAdd: build.mutation({
                query(user) {
                    return {
                        url: '/entMoneyAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['entMoney']
            }),
            //金鱼/企业管理/账户管理/购买应用
            entMoneyAppAdd: build.mutation({
                query(user) {
                    return {
                        url: '/entMoneyAppAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['entMoney']
            }),
            //金鱼/企业管理/账户管理/删除充值
            entMoneyDel: build.mutation({
                query(user) {
                    return {
                        url: '/entMoneyDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['entMoney']
            }),
            //金鱼/企业管理/账户管理/账户明细列表
            entMoneyList: build.query({
                query(user) {
                    return {
                        url: '/entMoneyList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['entMoney']
            }),
            //应用商店/未购买
            entAppNoPayList: build.query({
                query(user) {
                    return {
                        url: '/entAppNoPayList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['entAppNoPayList']
            }),
            //修改权限设置 
            purviewSettingUpdate: build.query({
                query(user) {
                    return {
                        url: '/purviewSettingUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['purviewSetting']
            }),
            //企业用户查询 
            allDeptUserSearch: build.query({
                query(user) {
                    return {
                        url: '/allDeptUserSearch',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['purviewSetting']
            }),
            //保存权限设置 
            purviewSettingUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/purviewSettingUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['purviewSetting']
            }),
            //获取用户权限 
            getUserPower: build.query({
                query(user) {
                    return {
                        url: '/getUserPower',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['purviewSetting']
            }),
             //新增新闻类型
             newsTypeAdd: build.mutation({
                query(user) {
                    return {
                        url: '/newsTypeAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['newsType']
            }),
            //新闻类型列表
            newsTypeList: build.query({
                query(user) {
                    
                    return {
                        url: '/newsTypeList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['newsType']
            }),
            //新闻类型修改
            newsTypeUpdate: build.query({
                query(user) {
                    return {
                        url: '/newsTypeUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['newsType']
            }),
            //修改保存新闻类型
            newsTypeUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/newsTypeUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['newsType']
            }),
            //删除新闻类型
            newsTypeDel: build.mutation({
                query(user) {
                    return {
                        url: '/newsTypeDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['newsType']
            }),
            //新增商品主类型
            gwGoodsTypeAdd: build.mutation({
                query(user) {
                    return {
                        url: '/gwGoodsTypeAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['gwGoodsType']
            }),
            //商品主类型列表
            gwGoodsTypeList: build.query({
                query(user) {
                    
                    return {
                        url: '/gwGoodsTypeList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['gwGoodsType']
            }),
            //商品主类型修改
            gwGoodsTypeUpdate: build.query({
                query(user) {
                    return {
                        url: '/gwGoodsTypeUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['gwGoodsType']
            }),
            //修改保存商品主类型
            gwGoodsTypeUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/gwGoodsTypeUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['gwGoodsType']
            }),
            //删除商品主类型
            gwGoodsTypeDel: build.mutation({
                query(user) {
                    return {
                        url: '/gwGoodsTypeDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['gwGoodsType']
            }),
            //新增商品子类型
            gwGoodsTypeSubAdd: build.mutation({
                query(user) {
                    return {
                        url: '/gwGoodsTypeSubAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['gwGoodsTypeSub']
            }),
            //商品子类型列表
            gwGoodsTypeSubList: build.query({
                query(user) {
                    
                    return {
                        url: '/gwGoodsTypeSubList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['gwGoodsTypeSub']
            }),
            //商品子类型修改
            gwGoodsTypeSubUpdate: build.query({
                query(user) {
                    return {
                        url: '/gwGoodsTypeSubUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['gwGoodsTypeSub']
            }),
            //修改保存商品子类型
            gwGoodsTypeSubUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/gwGoodsTypeSubUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['gwGoodsTypeSub']
            }),
            //删除商品子类型
            gwGoodsTypeSubDel: build.mutation({
                query(user) {
                    return {
                        url: '/gwGoodsTypeSubDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['gwGoodsTypeSub']
            }),
            //新增商品
            gwGoodsAdd: build.mutation({
                query(user) {
                    return {
                        url: '/gwGoodsAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['gwGoods']
            }),
            //商品列表
            gwGoodsList: build.query({
                query(user) {
                    
                    return {
                        url: '/gwGoodsList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['gwGoods']
            }),
            //商品修改
            gwGoodsUpdate: build.query({
                query(user) {
                    return {
                        url: '/gwGoodsUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['gwGoods']
            }),
            //根据key查询商品
            getGoodsID: build.query({
                query(user) {
                    return {
                        url: '/getGoodsID',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['gwGoods']
            }),
            //修改保存商品
            gwGoodsUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/gwGoodsUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['gwGoods']
            }),
            //删除商品
            gwGoodsDel: build.mutation({
                query(user) {
                    return {
                        url: '/gwGoodsDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['gwGoods']
            }),
            //获取商品目录
            getGoodsTree: build.query({
                query(user) {
                    return {
                        url: '/getGoodsTree',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['gwGoods']
            }),
            //上下架商品
            gwGoodsState: build.mutation({
                query(user) {
                    return {
                        url: '/gwGoodsState',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['gwGoods']
            }),
            //新增产品类型
            goodsTypeAdd: build.mutation({
                query(user) {
                    return {
                        url: '/goodsTypeAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['goodsType']
            }),
            //产品类型列表
            goodsTypeList: build.query({
                query(user) {
                    
                    return {
                        url: '/goodsTypeList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['goodsType']
            }),
            //产品类型修改
            goodsTypeUpdate: build.query({
                query(user) {
                    return {
                        url: '/goodsTypeUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['goodsType']
            }),
            //修改保存产品类型
            goodsTypeUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/goodsTypeUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['goodsType']
            }),
            //删除产品类型
            goodsTypeDel: build.mutation({
                query(user) {
                    return {
                        url: '/goodsTypeDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['goodsType']
            }),
            //新增商品规格
            goodsGuigeAdd: build.mutation({
                query(user) {
                    return {
                        url: '/goodsGuigeAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['goodsGuige']
            }),
            //商品规格列表
            goodsGuigeList: build.query({
                query(user) {
                    
                    return {
                        url: '/goodsGuigeList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['goodsGuige']
            }),
            //商品规格修改
            goodsGuigeUpdate: build.query({
                query(user) {
                    return {
                        url: '/goodsGuigeUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['goodsGuige']
            }),
            //修改保存商品规格
            goodsGuigeUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/goodsGuigeUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['goodsGuige']
            }),
            //删除商品规格
            goodsGuigeDel: build.mutation({
                query(user) {
                    return {
                        url: '/goodsGuigeDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['goodsGuige']
            }),
            //新增收支子类型
            financeSubTypeAdd: build.mutation({
                query(user) {
                    return {
                        url: '/financeSubTypeAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['financeSubType']
            }),
            //收支子类型列表
            financeSubTypeList: build.query({
                query(user) {
                    
                    return {
                        url: '/financeSubTypeList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['financeSubType']
            }),
            //收支子类型修改
            financeSubTypeUpdate: build.query({
                query(user) {
                    return {
                        url: '/financeSubTypeUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['financeSubType']
            }),
            //修改保存收支子类型
            financeSubTypeUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/financeSubTypeUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['financeSubType']
            }),
            //删除收支子类型
            financeSubTypeDel: build.mutation({
                query(user) {
                    return {
                        url: '/financeSubTypeDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['financeSubType']
            }),
            //获取单个子类型
            getFinanceSubType: build.query({
                query(user) {
                    return {
                        url: '/getFinanceSubType',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['financeSubType']
            }),
            //新增相册
            photoAlbumAdd: build.mutation({
                query(user) {
                    return {
                        url: '/photoAlbumAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['photoAlbum']
            }),
            //相册列表
            photoAlbumList: build.query({
                query(user) {
                    
                    return {
                        url: '/photoAlbumList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['photoAlbum']
            }),
            //相册修改
            photoAlbumUpdate: build.query({
                query(user) {
                    return {
                        url: '/photoAlbumUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['photoAlbum']
            }),
            //修改保存相册
            photoAlbumUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/photoAlbumUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['photoAlbum']
            }),
            //删除相册
            photoAlbumDel: build.mutation({
                query(user) {
                    return {
                        url: '/photoAlbumDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['photoAlbum']
            }),
            //一键真删除相册
            photoAlbumOneKeyDel: build.mutation({
                query(user) {
                    return {
                        url: '/photoAlbumOneKeyDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['photoAlbum']
            }),
            //新增相册分组
            photoAlbumGroupAdd: build.mutation({
                query(user) {
                    return {
                        url: '/photoAlbumGroupAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['photoAlbumGroup']
            }),
            //相册分组列表
            photoAlbumGroupList: build.query({
                query(user) {
                    
                    return {
                        url: '/photoAlbumGroupList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['photoAlbumGroup']
            }),
            //相册分组修改
            photoAlbumGroupUpdate: build.query({
                query(user) {
                    return {
                        url: '/photoAlbumGroupUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['photoAlbumGroup']
            }),
            //修改保存相册分组
            photoAlbumGroupUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/photoAlbumGroupUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['photoAlbumGroup']
            }),
            //删除相册分组
            photoAlbumGroupDel: build.mutation({
                query(user) {
                    return {
                        url: '/photoAlbumGroupDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['photoAlbumGroup']
            }),
            //新增分组照片
            photoAlbumGroupUploadAdd: build.mutation({
                query(user) {
                    return {
                        url: '/photoAlbumGroupUploadAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['photoAlbumGroupUpload']
            }),
            //分组照片列表
            photoAlbumGroupUploadList: build.query({
                query(user) {
                    
                    return {
                        url: '/photoAlbumGroupUploadList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['photoAlbumGroupUpload']
            }),
            //分组照片修改
            photoAlbumGroupUploadUpdate: build.query({
                query(user) {
                    return {
                        url: '/photoAlbumGroupUploadUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['photoAlbumGroupUpload']
            }),
            //修改保存分组照片
            photoAlbumGroupUploadUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/photoAlbumGroupUploadUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['photoAlbumGroupUpload']
            }),
            //删除分组照片
            photoAlbumGroupUploadDel: build.mutation({
                query(user) {
                    return {
                        url: '/photoAlbumGroupUploadDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['photoAlbumGroupUpload']
            }),
            //班级图片打包
            photoAlbumGroupZip: build.mutation({
                query(user) {
                    return {
                        url: '/photoAlbumGroupZip',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['photoAlbumGroup']
            }),
            //新增相册摄影师
            photoAlbumSYSAdd: build.mutation({
                query(user) {
                    return {
                        url: '/photoAlbumSYSAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['photoAlbumSYS']
            }),
            //相册摄影师列表
            photoAlbumSYSList: build.query({
                query(user) {
                    
                    return {
                        url: '/photoAlbumSYSList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['photoAlbumSYS']
            }),
            //相册摄影师修改
            photoAlbumSYSUpdate: build.query({
                query(user) {
                    return {
                        url: '/photoAlbumSYSUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['photoAlbumSYS']
            }),
            //修改保存相册摄影师
            photoAlbumSYSUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/photoAlbumSYSUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['photoAlbumSYS']
            }),
            //删除相册摄影师
            photoAlbumSYSDel: build.mutation({
                query(user) {
                    return {
                        url: '/photoAlbumSYSDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['photoAlbumSYS']
            }),
            //新增应用设置
            appSettingAdd: build.mutation({
                query(user) {
                    return {
                        url: '/appSettingAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['appSetting']
            }),
            //应用设置列表
            appSettingList: build.query({
                query(user) {
                    
                    return {
                        url: '/appSettingList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['appSetting']
            }),
            //应用设置修改
            appSettingUpdate: build.query({
                query(user) {
                    return {
                        url: '/appSettingUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['appSetting']
            }),
            //修改保存应用设置
            appSettingUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/appSettingUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['appSetting']
            }),
            //删除应用设置
            appSettingDel: build.mutation({
                query(user) {
                    return {
                        url: '/appSettingDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['appSetting']
            }),
            //新增应用菜单权限
            appMenuAdd: build.mutation({
                query(user) {
                    return {
                        url: '/appMenuAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['appMenu']
            }),
            //应用菜单权限列表
            appMenuList: build.query({
                query(user) {
                    
                    return {
                        url: '/appMenuList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['appMenu']
            }),
            //应用菜单权限修改
            appMenuUpdate: build.query({
                query(user) {
                    return {
                        url: '/appMenuUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['appMenu']
            }),
            //修改保存应用菜单权限
            appMenuUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/appMenuUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['appMenu']
            }),
            //删除应用菜单权限
            appMenuDel: build.mutation({
                query(user) {
                    return {
                        url: '/appMenuDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['appMenu']
            }),
            //新增应用操作权限
            appOperationAdd: build.mutation({
                query(user) {
                    return {
                        url: '/appOperationAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['appOperation']
            }),
            //应用操作权限列表
            appOperationList: build.query({
                query(user) {
                    
                    return {
                        url: '/appOperationList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['appOperation']
            }),
            //应用操作权限修改
            appOperationUpdate: build.query({
                query(user) {
                    return {
                        url: '/appOperationUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['appOperation']
            }),
            //修改保存应用操作权限
            appOperationUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/appOperationUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['appOperation']
            }),
            //删除应用操作权限
            appOperationDel: build.mutation({
                query(user) {
                    return {
                        url: '/appOperationDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['appOperation']
            }),
            //获取企业应用、菜单、操作数据
            getEntAppMenuOperation: build.query({
                query(user) {
                    return {
                        url: '/getEntAppMenuOperation',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['appOperation','appMenu']
            }),
            //新增岗位
            appStationAdd: build.mutation({
                query(user) {
                    return {
                        url: '/appStationAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['appStation']
            }),
            //岗位列表
            appStationList: build.query({
                query(user) {
                    
                    return {
                        url: '/appStationList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['appStation']
            }),
            //岗位修改
            appStationUpdate: build.query({
                query(user) {
                    return {
                        url: '/appStationUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['appStation']
            }),
            //修改保存岗位
            appStationUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/appStationUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['appStation']
            }),
            //删除岗位
            appStationDel: build.mutation({
                query(user) {
                    return {
                        url: '/appStationDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['appStation']
            }),
            //利润查询年份
            getProfitYear: build.query({
                query(user) {
                    return {
                        url: '/getProfitYear',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditure']
            }),
            //经办人下账利润表(带应用id)
            jbrESVProfitList: build.query({
                query(user) {
                    return {
                        url: '/jbrESVProfitList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditure']
            }),
            //操作员下账利润表(带应用id)
            uidESVProfitList: build.query({
                query(user) {
                    return {
                        url: '/uidESVProfitList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditure']
            }),
            //部门下账利润表(带应用id)
            deptESVProfitList: build.query({
                query(user) {
                    return {
                        url: '/deptESVProfitList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditure']
            }),
            //产品下账利润表(带应用id)
            goodsESVProfitList: build.query({
                query(user) {
                    return {
                        url: '/goodsESVProfitList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditure']
            }),
            //BOSS管账月度利润表
            monthProfitList: build.query({
                query(user) {
                    return {
                        url: '/monthProfitList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['incomeExpenditure']
            }),
            //新增研学评价点类型
            evaluateTypeAdd: build.mutation({
                query(user) {
                    return {
                        url: '/evaluateTypeAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['evaluateType']
            }),
            //研学评价点类型列表
            evaluateTypeList: build.query({
                query(user) {
                    
                    return {
                        url: '/evaluateTypeList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['evaluateType']
            }),
            //研学评价点类型修改
            evaluateTypeUpdate: build.query({
                query(user) {
                    return {
                        url: '/evaluateTypeUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['evaluateType']
            }),
            //修改保存研学评价点类型
            evaluateTypeUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/evaluateTypeUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['evaluateType']
            }),
            //删除研学评价点类型
            evaluateTypeDel: build.mutation({
                query(user) {
                    return {
                        url: '/evaluateTypeDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['evaluateType']
            }),
            //新增研学评价点
            evaluatePointAdd: build.mutation({
                query(user) {
                    return {
                        url: '/evaluatePointAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['evaluatePoint']
            }),
            //研学评价点列表
            evaluatePointList: build.query({
                query(user) {
                    
                    return {
                        url: '/evaluatePointList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['evaluatePoint']
            }),
            //研学评价点修改
            evaluatePointUpdate: build.query({
                query(user) {
                    return {
                        url: '/evaluatePointUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['evaluatePoint']
            }),
            //修改保存研学评价点
            evaluatePointUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/evaluatePointUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['evaluatePoint']
            }),
            //删除研学评价点
            evaluatePointDel: build.mutation({
                query(user) {
                    return {
                        url: '/evaluatePointDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['evaluatePoint']
            }),
            //新增导游
            tourGuideAdd: build.mutation({
                query(user) {
                    return {
                        url: '/tourGuideAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['tourGuide']
            }),
            //导游列表
            tourGuideList: build.query({
                query(user) {
                    
                    return {
                        url: '/tourGuideList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['tourGuide']
            }),
            //导游修改
            tourGuideUpdate: build.query({
                query(user) {
                    return {
                        url: '/tourGuideUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['tourGuide']
            }),
            //修改保存导游
            tourGuideUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/tourGuideUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['tourGuide']
            }),           
            //新增任务
            entEvaluatePointAdd: build.mutation({
                query(user) {
                    return {
                        url: '/entEvaluatePointAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['entEvaluatePoint']
            }),
            //任务列表
            entEvaluatePointList: build.query({
                query(user) {
                    
                    return {
                        url: '/entEvaluatePointList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['entEvaluatePoint']
            }),
            //研学团号评价点修改
            entEvaluatePointUpdate: build.query({
                query(user) {
                    return {
                        url: '/entEvaluatePointUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['entEvaluatePoint']
            }),
            //修改保存研学团号评价点
            entEvaluatePointUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/entEvaluatePointUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['entEvaluatePoint']
            }),           
            //删除研学团号评价点
            entEvaluatePointDel: build.mutation({
                query(user) {
                    return {
                        url: '/entEvaluatePointDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['entEvaluatePoint']
            }),   
            //团号评价列表
            userEbnEvaluateList: build.query({
                query(user) {
                    return {
                        url: '/userEbnEvaluateList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),        
            //申请试用列表
            applyforInfoList: build.query({
                query(user) {
                    return {
                        url: '/applyforInfoList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['applyforInfo']
            }), 
            //申请试用
            applyforInfoAdd: build.mutation({
                query(user) {
                    return {
                        url: '/applyforInfoAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['applyforInfo']
            }),
            //详情阅读
            applyforInfoDetail: build.mutation({
                query(user) {
                    return {
                        url: '/applyforInfoDetail',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['applyforInfo','purviewSetting']
            }),
            //新增任务
            taskManageAdd: build.mutation({
                query(user) {
                    return {
                        url: '/taskManageAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['taskManage']
            }),
            //任务列表
            taskManageList: build.query({
                query(user) {
                    
                    return {
                        url: '/taskManageList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['taskManage']
            }),
            //任务修改
            taskManageUpdate: build.query({
                query(user) {
                    return {
                        url: '/taskManageUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['taskManage']
            }),
            //修改保存任务
            taskManageUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/taskManageUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['taskManage']
            }),           
            //删除任务
            taskManageDel: build.mutation({
                query(user) {
                    return {
                        url: '/taskManageDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['taskManage']
            }), 
            //审核任务
            taskManageAudit: build.mutation({
                query(user) {
                    return {
                        url: '/taskManageAudit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['taskManage']
            }), 
            //获取任务参与人列表
            taskManageUserList: build.query({
                query(user) {
                    return {
                        url: '/taskManageUserList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['taskManage']
            }),              
            //获取任务发布人列表
            taskManageFaBuUserList: build.query({
                query(user) {
                    return {
                        url: '/taskManageFaBuUserList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['taskManage']
            }),              
            //新增任务留言
            taskMessageAdd: build.mutation({
                query(user) {
                    return {
                        url: '/taskMessageAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['taskManage','taskMessage']
            }),
            //任务留言列表
            taskMessageList: build.query({
                query(user) {
                    
                    return {
                        url: '/taskMessageList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['taskMessage']
            }),
            //任务留言修改
            taskMessageUpdate: build.query({
                query(user) {
                    return {
                        url: '/taskMessageUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['taskMessage']
            }),
            //修改保存任务留言
            taskMessageUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/taskMessageUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['taskMessage','taskManage']
            }),           
            //删除任务留言
            taskMessageDel: build.mutation({
                query(user) {
                    return {
                        url: '/taskMessageDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['taskMessage']
            }), 
            //新增应用教程
            appSynthesisAdd: build.mutation({
                query(user) {
                    return {
                        url: '/appSynthesisAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['appSynthesis']
            }),
            //应用教程列表
            appSynthesisList: build.query({
                query(user) {                    
                    return {
                        url: '/appSynthesisList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['appSynthesis']
            }),
            //应用教程修改
            appSynthesisUpdate: build.query({
                query(user) {
                    return {
                        url: '/appSynthesisUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['appSynthesis']
            }),
            //修改保存应用教程
            appSynthesisUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/appSynthesisUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['appSynthesis',]
            }),           
            //删除应用教程
            appSynthesisDel: build.mutation({
                query(user) {
                    return {
                        url: '/appSynthesisDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['appSynthesis']
            }),    
            //应用教程阅读日志
            appSynthesisUserReadLog: build.mutation({
                query(user) {
                    return {
                        url: '/appSynthesisUserReadLog',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['appSynthesis']
            }),    
            //新增支付帐户明细
            capitalAccountDetailAdd: build.mutation({
                query(user) {
                    return {
                        url: '/capitalAccountDetailAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['capitalAccountDetail','capitalAccount','incomeExpenditureHx']
            }),  
            //记账收支明细
            jizhangshouzhiList: build.query({
                query(user) {
                    return {
                        url: '/jizhangshouzhiList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['capitalAccountDetail','capitalAccount','incomeExpenditureHx']
            }),  
            //获取转账凭证
            zhuanzhangpinpingzhengList: build.query({
                query(user) {
                    return {
                        url: '/zhuanzhangpinpingzhengList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['capitalAccountDetail','capitalAccount','incomeExpenditureHx']
            }),  
            //已记账部门利润
            zjDeptProfit: build.query({
                query(user) {
                    return {
                        url: '/zjDeptProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['capitalAccountDetail','capitalAccount']
            }),  
            //已记账多收支类型操作员利润
            zjUidProfit: build.query({
                query(user) {
                    return {
                        url: '/zjUidProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['capitalAccountDetail','capitalAccount']
            }),  
            //已记账多收支类型销售员利润
            zjJbrUidProfit: build.query({
                query(user) {
                    return {
                        url: '/zjJbrUidProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['capitalAccountDetail','capitalAccount']
            }),  
            //已记账多收支类型产品类型利润
            zjGoogdsTypeProfit: build.query({
                query(user) {
                    return {
                        url: '/zjGoogdsTypeProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['capitalAccountDetail','capitalAccount']
            }),  
            //已记账多收支类型产品利润
            zjGoogdsProfit: build.query({
                query(user) {
                    return {
                        url: '/zjGoogdsProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['capitalAccountDetail','capitalAccount']
            }),  
            //已记账多收支类型客户类型利润
            zjClientTypeProfit: build.query({
                query(user) {
                    return {
                        url: '/zjClientTypeProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['capitalAccountDetail','capitalAccount']
            }),  
            //已记账多收支类型客户利润
            zjClientProfit: build.query({
                query(user) {
                    return {
                        url: '/zjClientProfit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['capitalAccountDetail','capitalAccount']
            }),  
            //删除支付帐户明细
            capitalAccountLogDel: build.mutation({
                query(user) {
                    return {
                        url: '/capitalAccountLogDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['capitalAccountDetail','capitalAccount']
            }),  
            //对账支付帐户明细
            capitalAccountConfirm: build.mutation({
                query(user) {
                    return {
                        url: '/capitalAccountConfirm',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['capitalAccountDetail']
            }),  
            //支付帐户明细列表
            capitalAccountLogList: build.query({
                query(user) {
                    return {
                        url: '/capitalAccountLogList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['capitalAccountDetail']
            }),  
            //新增支付帐户
            capitalAccountAdd: build.mutation({
                query(user) {
                    return {
                        url: '/capitalAccountAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['capitalAccount']
            }),  
            //删除支付帐户
            capitalAccountDel: build.mutation({
                query(user) {
                    return {
                        url: '/capitalAccountDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['capitalAccount']
            }),  
            //支付帐户列表
            capitalAccountList: build.query({
                query(user) {
                    return {
                        url: '/capitalAccountList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['capitalAccount']
            }),
            //修改支付帐户
            capitalAccountUpdate: build.query({
                query(user) {
                    return {
                        url: '/capitalAccountUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['capitalAccount']
            }),
            //修改保存支付帐户
            capitalAccountUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/capitalAccountUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['capitalAccount']
            }),
            //企业管理/收支汇总
            entRegisterShouZhiList: build.query({
                query(user) {
                    return {
                        url: '/entRegisterShouZhiList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //企业管理/往来汇总
            entRegisterWangLaiList: build.query({
                query(user) {
                    return {
                        url: '/entRegisterWangLaiList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //企业管理/任务汇总
            entRegisterRenWuList: build.query({
                query(user) {
                    return {
                        url: '/entRegisterRenWuList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //企业管理/台账汇总
            entRegisterTaiZhangList: build.query({
                query(user) {
                    return {
                        url: '/entRegisterTaiZhangList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //往来管理/生成对账单
            duizhangAdd: build.mutation({
                query(user) {
                    return {
                        url: '/duizhangAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['duizhang']
            }),
            //往来管理/删除对账单
            duizhangDel: build.mutation({
                query(user) {
                    return {
                        url: '/duizhangDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['duizhang']
            }),
            //往来管理/发送对账单列表
            duizhangSendList: build.query({
                query(user) {
                    return {
                        url: '/duizhangSendList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['duizhang']
            }),
            //往来管理/获取对账单
            getDuiZhang: build.query({
                query(user) {
                    return {
                        url: '/getDuiZhang',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['duizhang']
            }),
            //往来管理/对账单审核
            duizhangAudit: build.mutation({
                query(user) {
                    return {
                        url: '/duizhangAudit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['duizhang']
            }),
            //往来管理/对账单绑定
            duizhangReceptionBaning: build.mutation({
                query(user) {
                    return {
                        url: '/duizhangReceptionBaning',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['duizhang']
            }),
            //往来管理/对账单接收列表
            duizhangReceptionList: build.query({
                query(user) {
                    return {
                        url: '/duizhangReceptionList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['duizhangReception']
            }),
            //往来管理/对账单接收确认
            duizhangAffirm: build.mutation({
                query(user) {
                    return {
                        url: '/duizhangAffirm',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['duizhangReception']
            }),
            //企业/新增订单
            entPayGoodsAdd: build.mutation({
                query(user) {
                    return {
                        url: '/entPayGoodsAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['entPayGoods']
            }),
            //企业/订单修改保存
            entPayGoodsUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/entPayGoodsUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['entPayGoods']
            }),
            //企业/订单修改
            entPayGoodsUpdate: build.query({
                query(user) {
                    return {
                        url: '/entPayGoodsUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['entPayGoods']
            }),
            //企业/订单列表
            entPayGoodsList: build.query({
                query(user) {
                    return {
                        url: '/entPayGoodsList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['entPayGoods']
            }),
            //修改用户密码验证码
            userUpdatePwdCode: build.mutation({
                query(user) {
                    return {
                        url: '/userUpdatePwdCode',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //修改用户密码
            userUpdatePwd: build.mutation({
                query(user) {
                    return {
                        url: '/userUpdatePwd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //修改保存台账截止日期
            deptTzEndDateUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/deptTzEndDateUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['deptTzEndDate','dept']
            }),
            //获取台账截止日期
            getDeptTzEndDate: build.query({
                query(user) {
                    return {
                        url: '/getDeptTzEndDate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['deptTzEndDate','dept']
            }),
            //地接业务/新增抬头模板
            dijieTemplateTaiTouAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateTaiTouAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateTaiTou']
            }),  
            //地接业务/删除抬头模板
            dijieTemplateTaiTouDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateTaiTouDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateTaiTou']
            }),  
            //地接业务/抬头模板列表
            dijieTemplateTaiTouList: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateTaiTouList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateTaiTou']
            }),
            //地接业务/修改抬头模板
            dijieTemplateTaiTouUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateTaiTouUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateTaiTou']
            }),
            //地接业务/修改保存抬头模板
            dijieTemplateTaiTouUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateTaiTouUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateTaiTou']
            }),
            //地接业务/新增线路名称模板
            dijieTemplateXianLuAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateXianLuAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateXianLu']
            }),  
            //地接业务/删除线路名称模板
            dijieTemplateXianLuDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateXianLuDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateXianLu']
            }),  
            //地接业务/线路名称模板列表
            dijieTemplateXianLuList: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateXianLuList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateXianLu']
            }),
            //地接业务/修改线路名称模板
            dijieTemplateXianLuUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateXianLuUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateXianLu']
            }),
            //地接业务/修改保存线路名称模板
            dijieTemplateXianLuUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateXianLuUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateXianLu']
            }),
            //地接业务/新增行程模板
            dijieTemplateXingChengAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateXingChengAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateXingCheng']
            }),  
            //地接业务/删除行程模板
            dijieTemplateXingChengDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateXingChengDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateXingCheng']
            }),  
            //地接业务/行程模板列表
            dijieTemplateXingChengList: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateXingChengList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateXingCheng']
            }),
            //地接业务/修改行程模板
            dijieTemplateXingChengUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateXingChengUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateXingCheng']
            }),
            //地接业务/修改保存行程模板
            dijieTemplateXingChengUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateXingChengUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateXingCheng']
            }),
            //地接业务/新增接待标准模板
            dijieTemplateJieDaiBiaoZhunAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateJieDaiBiaoZhunAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateJieDaiBiaoZhun']
            }),  
            //地接业务/删除接待标准模板
            dijieTemplateJieDaiBiaoZhunDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateJieDaiBiaoZhunDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateJieDaiBiaoZhun']
            }),  
            //地接业务/接待标准模板列表
            dijieTemplateJieDaiBiaoZhunList: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateJieDaiBiaoZhunList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateJieDaiBiaoZhun']
            }),
            //地接业务/修改接待标准模板
            dijieTemplateJieDaiBiaoZhunUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateJieDaiBiaoZhunUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateJieDaiBiaoZhun']
            }),
            //地接业务/修改保存接待标准模板
            dijieTemplateJieDaiBiaoZhunUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateJieDaiBiaoZhunUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateJieDaiBiaoZhun']
            }),
            //地接业务/新增费用明细模板
            dijieTemplateFeiYongMingXiAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateFeiYongMingXiAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateFeiYongMingXi']
            }),  
            //地接业务/删除费用明细模板
            dijieTemplateFeiYongMingXiDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateFeiYongMingXiDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateFeiYongMingXi']
            }),  
            //地接业务/费用明细模板列表
            dijieTemplateFeiYongMingXiList: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateFeiYongMingXiList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateFeiYongMingXi']
            }),
            //地接业务/修改费用明细模板
            dijieTemplateFeiYongMingXiUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateFeiYongMingXiUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateFeiYongMingXi']
            }),
            //地接业务/修改保存费用明细模板
            dijieTemplateFeiYongMingXiUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateFeiYongMingXiUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },                invalidatesTags:['dijieTemplateFeiYongMingXi']
            }),
            //地接业务/新增费用不含模板
            dijieTemplateFeiYongBuHanAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateFeiYongBuHanAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateFeiYongBuHan']
            }),  
            //地接业务/删除费用不含模板
            dijieTemplateFeiYongBuHanDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateFeiYongBuHanDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateFeiYongBuHan']
            }),  
            //地接业务/费用不含模板列表
            dijieTemplateFeiYongBuHanList: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateFeiYongBuHanList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateFeiYongBuHan']
            }),
            //地接业务/修改费用不含模板
            dijieTemplateFeiYongBuHanUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateFeiYongBuHanUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateFeiYongBuHan']
            }),
            //地接业务/修改保存费用不含模板
            dijieTemplateFeiYongBuHanUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateFeiYongBuHanUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateFeiYongBuHan']
            }),
            //地接业务/新增注意事项模板
            dijieTemplateZhuYiShiXiangAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateZhuYiShiXiangAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateZhuYiShiXiang']
            }),  
            //地接业务/删除注意事项模板
            dijieTemplateZhuYiShiXiangDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateZhuYiShiXiangDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateZhuYiShiXiang']
            }),  
            //地接业务/注意事项模板列表
            dijieTemplateZhuYiShiXiangList: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateZhuYiShiXiangList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateZhuYiShiXiang']
            }),
            //地接业务/修改注意事项模板
            dijieTemplateZhuYiShiXiangUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateZhuYiShiXiangUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateZhuYiShiXiang']
            }),
            //地接业务/修改保存注意事项模板
            dijieTemplateZhuYiShiXiangUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateZhuYiShiXiangUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateZhuYiShiXiang']
            }),
            //地接业务/新增规格模板
            dijieTemplateGuiGeAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateGuiGeAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateGuiGe']
            }),  
            //地接业务/删除规格模板
            dijieTemplateGuiGeDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateGuiGeDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateGuiGe']
            }),  
            //地接业务/规格模板列表
            dijieTemplateGuiGeList: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateGuiGeList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateGuiGe']
            }),
            //地接业务/修改规格模板
            dijieTemplateGuiGeUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateGuiGeUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateGuiGe']
            }),
            //地接业务/修改保存规格模板
            dijieTemplateGuiGeUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateGuiGeUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateGuiGe']
            }),
            //地接业务/新增报价单
            dijieBaoJiaDanAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDan']
            }),  
            //地接业务/删除报价单
            dijieBaoJiaDanDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDan']
            }),  
            //地接业务/报价单列表
            dijieBaoJiaDanList: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDan']
            }),
            //地接业务/修改报价单
            dijieBaoJiaDanUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDan']
            }),
            //地接业务/修改保存报价单
            dijieBaoJiaDanUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDan']
            }),
            //地接业务/复制报价单
            dijieBaoJiaDanCopyList: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanCopyList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDan']
            }),
            //地接业务/复制导游计划单和游客行程单
            dijieBaoJiaDanDaoYouCopyList: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanDaoYouCopyList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDan']
            }),
            //企业管理/权限管理/新增
            quanxianAdd: build.mutation({
                query(user) {
                    return {
                        url: '/quanxianAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['quanxian']
            }),
            //企业管理/权限管理/删除
            quanxianDel: build.mutation({
                query(user) {
                    return {
                        url: '/quanxianDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['quanxian']
            }),
            //企业管理/权限管理/修改保存
            quanxianUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/quanxianUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['quanxian']
            }),
            //企业管理/权限管理/修改
            quanxianUpdate: build.query({
                query(user) {
                    return {
                        url: '/quanxianUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['quanxian']
            }),
            //企业管理/权限管理/列表
            quanxianTreeList: build.query({
                query(user) {
                    return {
                        url: '/quanxianTreeList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['quanxian']
            }),
            //企业管理/账号列表
            account_list: build.query({
                query(user) {
                    return {
                        url: '/account_list',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //业务/地接/定制游报价单行程安排/新增
            dijieBaoJiaDanXingChengAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanXingChengAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanXingCheng']
            }),
            //业务/地接/定制游报价单行程安排/删除
            dijieBaoJiaDanXingChengDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanXingChengDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanXingCheng']
            }),
            //业务/地接/定制游报价单行程安排/修改保存
            dijieBaoJiaDanXingChengUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanXingChengUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanXingCheng']
            }),
            //业务/地接/定制游报价单行程安排/修改
            dijieBaoJiaDanXingChengUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanXingChengUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDanXingCheng']
            }),
            //业务/地接/定制游报价单行程安排/列表
            dijieBaoJiaDanXingChengList: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanXingChengList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDanXingCheng']
            }),
            //业务/地接/定制游报价单接待标准/新增
            dijieBaoJiaDanJieDaiBiaoZhunAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanJieDaiBiaoZhunAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanJieDaiBiaoZhun']
            }),
            //业务/地接/定制游报价单接待标准/删除
            dijieBaoJiaDanJieDaiBiaoZhunDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanJieDaiBiaoZhunDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanJieDaiBiaoZhun']
            }),
            //业务/地接/定制游报价单接待标准/修改保存
            dijieBaoJiaDanJieDaiBiaoZhunUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanJieDaiBiaoZhunUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanJieDaiBiaoZhun']
            }),
            //业务/地接/定制游报价单接待标准/修改
            dijieBaoJiaDanJieDaiBiaoZhunUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanJieDaiBiaoZhunUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDanJieDaiBiaoZhun']
            }),
            //业务/地接/定制游报价单接待标准/列表
            dijieBaoJiaDanJieDaiBiaoZhunList: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanJieDaiBiaoZhunList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDanJieDaiBiaoZhun']
            }),
            //业务/地接/定制游报价单费用不含/新增
            dijieBaoJiaDanFeiYongBuHanAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanFeiYongBuHanAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanFeiYongBuHan']
            }),
            //业务/地接/定制游报价单费用不含/删除
            dijieBaoJiaDanFeiYongBuHanDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanFeiYongBuHanDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanFeiYongBuHan']
            }),
            //业务/地接/定制游报价单费用不含/修改保存
            dijieBaoJiaDanFeiYongBuHanUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanFeiYongBuHanUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanFeiYongBuHan']
            }),
            //业务/地接/定制游报价单费用不含/修改
            dijieBaoJiaDanFeiYongBuHanUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanFeiYongBuHanUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDanFeiYongBuHan']
            }),
            //业务/地接/定制游报价单费用不含/列表
            dijieBaoJiaDanFeiYongBuHanList: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanFeiYongBuHanList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDanFeiYongBuHan']
            }),
            //业务/地接/定制游报价单注意事项/新增
            dijieBaoJiaDanZhuYiShiXiangAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanZhuYiShiXiangAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanZhuYiShiXiang']
            }),
            //业务/地接/定制游报价单注意事项/删除
            dijieBaoJiaDanZhuYiShiXiangDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanZhuYiShiXiangDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanZhuYiShiXiang']
            }),
            //业务/地接/定制游报价单注意事项/修改保存
            dijieBaoJiaDanZhuYiShiXiangUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanZhuYiShiXiangUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanZhuYiShiXiang']
            }),
            //业务/地接/定制游报价单注意事项/修改
            dijieBaoJiaDanZhuYiShiXiangUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanZhuYiShiXiangUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDanZhuYiShiXiang']
            }),
            //业务/地接/定制游报价单注意事项/列表
            dijieBaoJiaDanZhuYiShiXiangList: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanZhuYiShiXiangList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDanZhuYiShiXiang']
            }),
            //业务/地接/定制游报价单费用明细/新增
            dijieBaoJiaDanFeiYongMingXiAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanFeiYongMingXiAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanFeiYongMingXi']
            }),
            //业务/地接/定制游报价单费用明细/删除
            dijieBaoJiaDanFeiYongMingXiDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanFeiYongMingXiDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanFeiYongMingXi']
            }),
            //业务/地接/定制游报价单费用明细/修改保存
            dijieBaoJiaDanFeiYongMingXiUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanFeiYongMingXiUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanFeiYongMingXi']
            }),
            //业务/地接/定制游报价单费用明细/修改
            dijieBaoJiaDanFeiYongMingXiUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanFeiYongMingXiUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDanFeiYongMingXi']
            }),
            //业务/地接/定制游报价单费用明细/列表
            dijieBaoJiaDanFeiYongMingXiList: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanFeiYongMingXiList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDanFeiYongMingXi']
            }),
            //业务/地接/定制游报价单规格人数/新增
            dijieBaoJiaDanGuiGeAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanGuiGeAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanGuiGe']
            }),
            //业务/地接/定制游报价单规格人数/删除
            dijieBaoJiaDanGuiGeDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanGuiGeDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanGuiGe']
            }),
            //业务/地接/定制游报价单规格人数/修改保存
            dijieBaoJiaDanGuiGeUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanGuiGeUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanGuiGe']
            }),
            //业务/地接/定制游报价单规格人数/修改
            dijieBaoJiaDanGuiGeUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanGuiGeUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDanGuiGe']
            }),
            //业务/地接/定制游报价单规格人数/列表
            dijieBaoJiaDanGuiGeList: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanGuiGeList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDanGuiGe']
            }),
            //业务/地接/定制游报价单结算方式/新增
            dijieBaoJiaDanJieSuanFangShiAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanJieSuanFangShiAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanJieSuanFangShi']
            }),
            //业务/地接/定制游报价单结算方式/删除
            dijieBaoJiaDanJieSuanFangShiDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanJieSuanFangShiDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanJieSuanFangShi']
            }),
            //业务/地接/定制游报价单结算方式/修改保存
            dijieBaoJiaDanJieSuanFangShiUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanJieSuanFangShiUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanJieSuanFangShi']
            }),
            //业务/地接/定制游报价单结算方式/修改
            dijieBaoJiaDanJieSuanFangShiUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanJieSuanFangShiUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDanJieSuanFangShi']
            }),
            //业务/地接/定制游报价单结算方式/列表
            dijieBaoJiaDanJieSuanFangShiList: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanJieSuanFangShiList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDanJieSuanFangShi']
            }),
            //业务/地接/定制游报价单导游费用/新增
            dijieBaoJiaDanDaoYouFeiYongAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanDaoYouFeiYongAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanDaoYouFeiYong']
            }),
            //业务/地接/定制游报价单导游费用/删除
            dijieBaoJiaDanDaoYouFeiYongDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanDaoYouFeiYongDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanDaoYouFeiYong']
            }),
            //业务/地接/定制游报价单导游费用/修改保存
            dijieBaoJiaDanDaoYouFeiYongUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanDaoYouFeiYongUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanDaoYouFeiYong']
            }),
            //业务/地接/定制游报价单导游费用/修改
            dijieBaoJiaDanDaoYouFeiYongUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanDaoYouFeiYongUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDanDaoYouFeiYong']
            }),
            //业务/地接/定制游报价单导游费用/列表
            dijieBaoJiaDanDaoYouFeiYongList: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanDaoYouFeiYongList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDanDaoYouFeiYong']
            }),
            //商品管理/商品类型/新增
            shangpinTypeAdd: build.mutation({
                query(user) {
                    return {
                        url: '/shangpinTypeAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['shangpinType']
            }),
            //商品管理/商品类型/删除
            shangpinTypeDel: build.mutation({
                query(user) {
                    return {
                        url: '/shangpinTypeDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['shangpinType']
            }),
            //商品管理/商品类型/修改保存
            shangpinTypeUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/shangpinTypeUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['shangpinType']
            }),
            //商品管理/商品类型/修改
            shangpinTypeUpdate: build.query({
                query(user) {
                    return {
                        url: '/shangpinTypeUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['shangpinType']
            }),
            //商品管理/商品类型/列表
            shangpinTypeList: build.query({
                query(user) {
                    return {
                        url: '/shangpinTypeList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['shangpinType']
            }),
            //商品管理/商品/新增
            shangpinAdd: build.mutation({
                query(user) {
                    return {
                        url: '/shangpinAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['shangpin']
            }),
            //商品管理/商品/删除
            shangpinDel: build.mutation({
                query(user) {
                    return {
                        url: '/shangpinDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['shangpin']
            }),
            //商品管理/商品/修改保存
            shangpinUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/shangpinUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['shangpin']
            }),
            //商品管理/商品/上下架
            shangpinState: build.mutation({
                query(user) {
                    return {
                        url: '/shangpinState',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['shangpin']
            }),
            //商品管理/商品/修改
            shangpinUpdate: build.query({
                query(user) {
                    return {
                        url: '/shangpinUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['shangpin']
            }),
            //商品管理/商品/列表
            shangpinList: build.query({
                query(user) {
                    return {
                        url: '/shangpinList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['shangpin']
            }),
//国家------------------------------------------------------------------------------------------------------------------国家
            getCountry: build.query({
                query(user) {                    
                    return {
                        url: '/country_list',
                        method: 'post',
                        body:form_data(user),
                    };                      
                },
                providesTags:['country'],
            }),
            addCountry: build.mutation({
                query(user) {
                    return {
                        url: '/country_add',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags: ['country']
            }),
            editCountry: build.mutation({
                query(user) {
                    return {
                        url: '/country_update_save',
                        method: 'post',
                        body: form_data(user)
                    };
                },
                invalidatesTags: ['country']
            }),
            deleteCountry: build.mutation({
                query(user) {
                    return {
                        url: '/country_del',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags: ['country']
            }),
            editCountryById:build.query({
                query(user) {                    
                    return {
                        url: '/country_update',
                        method: 'post',
                        body: form_data(user)
                    };                      
                },
                providesTags: ['country'],
            }),
//省份------------------------------------------------------------------------------------------------------------------省份
            getProvince: build.query({
                query(user) {                    
                    return {
                        url: '/province_list',
                        method: 'post',
                        body: form_data(user)
                    };                      
                },
                providesTags:['province'],
            }),
            addProvince: build.mutation({
                query(user) {
                    return {
                        url: '/province_add',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags: ['province']
            }),
            editProvinceById: build.query({
                query(user) {
                    return {
                        url: '/province_update',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['province'],
            }),
            editProvince: build.mutation({
                query(user) {
                    return {
                        url: '/province_update_save',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags: ['province']
            }),
//城市------------------------------------------------------------------------------------------------------------------城市
            getCity: build.query({
                query(user) {                    
                    return {
                        url: '/city_list',
                        method: 'post',
                        body: form_data(user)
                    };                      
                },
                providesTags:['city'],
            }),
            addCity: build.mutation({
                query(user) {
                    return {
                        url: '/city_add',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags: ['city']
            }),
            editCityById: build.query({
                query(user) {
                    return {
                        url: '/city_update',
                        method: 'post',
                        body: form_data(user)
                    };
                },
                providesTags:['city'],
            }),
            editCity: build.mutation({
                query(user) {
                    return {
                        url: '/city_update_save',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags: ['city']
            }),
//区县------------------------------------------------------------------------------------------------------------------区县
            getDistrict: build.query({
                query(user) {                    
                    return {
                        url: '/district_list',
                        method: 'post',
                        body: form_data(user)
                    };                      
                },
                providesTags:['district'],
            }),
            addDistrict: build.mutation({
                query(user) {
                    return {
                        url: '/district_add',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags: ['district']
            }),
            editDistrictById: build.query({
                query(user) {
                    return {
                        url: '/district_update',
                        method: 'post',
                        body: form_data(user)
                    };
                },
                providesTags:['district'],
            }),
            editDistrict: build.mutation({
                query(user) {
                    return {
                        url: '/district_update_save',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags: ['district']
            }),
            
//地标------------------------------------------------------------------------------------------------------------------街道
            landmarkAdd: build.mutation({
                query(user) {
                    return {
                        url: '/areaLandmarkAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['landmark']
            }),
            landmarkList: build.query({
                query(user) {
                    return {
                        url: '/areaLandmarkList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['landmark']
            }),
            landmarkUdate: build.query({
                query(user) {
                    return {
                        url: '/areaLandmarkUdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['landmark']
            }),
            landmarkUdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/areaLandmarkUdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['landmark']
            }),
            landmarkDel: build.mutation({
                query(user) {
                    return {
                        url: '/areaLandmarkDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['landmark']
            }),
//目的地------------------------------------------------------------------------------------------------------------------目的地
            destinationAdd: build.mutation({
                query(user) {
                    return {
                        url: '/destinationAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags: ['destination']
            }),
            destinationList: build.query({
                query(user) {
                    return {
                        url: '/destinationList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags: ['destination']
            }),
            destinationUpdate: build.query({
                query(user) {
                    return {
                        url: '/destinationUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags: ['destination']
            }),
            destinationDel: build.mutation({
                query(user) {
                    return {
                        url: '/destinationDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags: ['destination']
            }),
            destinationUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/destinationUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags: ['destination']
            }),

//地区树------------------------------------------------------------------------------------------------------------------地区树
            areaTree: build.query({
                query() {                    
                    return {
                        url: '/get_region_tree',
                        method: 'post',
                    };                      
                }
            }),
            //获取地域树 国家省市 
            get_region_tree2: build.query({
                query() {                    
                    return {
                        url: '/get_region_tree2',
                        method: 'post',
                    };                      
                }
            }),
            //业务团号/新增
            yewuTuanHaoAdd: build.mutation({
                query(user) {
                    return {
                        url: '/yewuTuanHaoAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['yewuTuanHao']
            }),
            //业务团号/删除
            yewuTuanHaoDel: build.mutation({
                query(user) {
                    return {
                        url: '/yewuTuanHaoDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['yewuTuanHao']
            }),
            //业务团号/修改保存
            yewuTuanHaoUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/yewuTuanHaoUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['yewuTuanHao']
            }),
            //业务团号/修改
            yewuTuanHaoUpdate: build.query({
                query(user) {
                    return {
                        url: '/yewuTuanHaoUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['yewuTuanHao']
            }),
            //业务团号/列表
            yewuTuanHaoList: build.query({
                query(user) {
                    return {
                        url: '/yewuTuanHaoList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['yewuTuanHao']
            }),
            //手机门店/新增
            entDianPuAdd: build.mutation({
                query(user) {
                    return {
                        url: '/entDianPuAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['entDianPu']
            }),           
            //手机门店/修改保存
            entDianPuUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/entDianPuUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['entDianPu']
            }),
            //手机门店/修改
            entDianPuUpdate: build.query({
                query(user) {
                    return {
                        url: '/entDianPuUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['entDianPu']
            }),
            //手机门店/列表
            entDianPuList: build.query({
                query(user) {
                    return {
                        url: '/entDianPuList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['entDianPu']
            }),
            //微信分享
            wxShareConfig: build.mutation({
                query(user) {
                    return {
                        url: '/wxShareConfig',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //日期图片列表
            photoDataList: build.query({
                query(user) {
                    
                    return {
                        url: '/photoDataList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['photoAlbumGroupUpload']
            }),
            //图片点赞量接口
            photoClickNnum: build.mutation({
                query(user) {
                    return {
                        url: '/photoClickNnum',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //图片浏览量接口
            photoBrowerNnum: build.mutation({
                query(user) {
                    return {
                        url: '/photoBrowerNnum',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //所在相册列表
            sysInAlbumList: build.query({
                query(user) {
                    return {
                        url: '/sysInAlbumList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['photoAlbumSYS']
            }),
            //所在班级列表
            sysInClassList: build.query({
                query(user) {
                    return {
                        url: '/sysInClassList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['photoAlbumSYS']
            }),
            //计调客户/新增
            dijieTemplateKeHuAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateKeHuAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateKeHu']
            }),
            //计调客户/删除
            dijieTemplateKeHuDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateKeHuDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateKeHu']
            }),
            //计调客户/修改保存
            dijieTemplateKeHuUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateKeHuUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateKeHu']
            }),
            //计调客户/修改
            dijieTemplateKeHuUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateKeHuUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateKeHu']
            }),
            //计调客户/列表
            dijieTemplateKeHuList: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateKeHuList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateKeHu']
            }),
            //计调导游/新增
            dijieTemplateDaoYouAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateDaoYouAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateDaoYou']
            }),
            //计调导游/删除
            dijieTemplateDaoYouDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateDaoYouDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateDaoYou']
            }),
            //计调导游/修改保存
            dijieTemplateDaoYouUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateDaoYouUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateDaoYou']
            }),
            //计调导游/修改
            dijieTemplateDaoYouUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateDaoYouUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateDaoYou']
            }),
            //计调导游/列表
            dijieTemplateDaoYouList: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateDaoYouList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateDaoYou']
            }),
            //报价单模板/课程/新增
            dijieTemplateKeChengAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateKeChengAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateKeCheng']
            }),
            //报价单模板/课程/删除
            dijieTemplateKeChengDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateKeChengDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateKeCheng']
            }),
            //报价单模板/课程/修改保存
            dijieTemplateKeChengUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieTemplateKeChengUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieTemplateKeCheng']
            }),
            //报价单模板/课程/修改
            dijieTemplateKeChengUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateKeChengUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateKeCheng']
            }),
            //报价单模板/课程/列表
            dijieTemplateKeChengList: build.query({
                query(user) {
                    return {
                        url: '/dijieTemplateKeChengList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieTemplateKeCheng']
            }),
            //报价单/课程安排/新增
            dijieBaoJiaDanDaoKeChengAdd: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanDaoKeChengAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanDaoKeCheng']
            }),
            //报价单/课程安排/删除
            dijieBaoJiaDanDaoKeChengDel: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanDaoKeChengDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanDaoKeCheng']
            }),
            //报价单/课程安排/修改保存
            dijieBaoJiaDanDaoKeChengUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanDaoKeChengUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['dijieBaoJiaDanDaoKeCheng']
            }),
            //报价单/课程安排/修改
            dijieBaoJiaDanDaoKeChengUpdate: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanDaoKeChengUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDanDaoKeCheng']
            }),
            //报价单/课程安排/列表
            dijieBaoJiaDanDaoKeChengList: build.query({
                query(user) {
                    return {
                        url: '/dijieBaoJiaDanDaoKeChengList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dijieBaoJiaDanDaoKeCheng']
            }),
            //地接业务/团队计划/导游计划单/新增
            daoyoujihuadanAdd: build.mutation({
                query(user) {
                    return {
                        url: '/daoyoujihuadanAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['daoyoujihuadan']
            }),
            //地接业务/团队计划/导游计划单/删除
            daoyoujihuadanDel: build.mutation({
                query(user) {
                    return {
                        url: '/daoyoujihuadanDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['daoyoujihuadan']
            }),
            //地接业务/团队计划/导游计划单/修改保存
            daoyoujihuadanUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/daoyoujihuadanUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['daoyoujihuadan']
            }),
            //地接业务/团队计划/导游计划单/修改
            daoyoujihuadanUpdate: build.query({
                query(user) {
                    return {
                        url: '/daoyoujihuadanUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['daoyoujihuadan']
            }),
            //地接业务/团队计划/导游计划单/列表
            daoyoujihuadanList: build.query({
                query(user) {
                    return {
                        url: '/daoyoujihuadanList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['daoyoujihuadan']
            }),
            //地接业务/团队计划/导游计划单/行程安排/新增
            daoyoujihuadanxingchengAdd: build.mutation({
                query(user) {
                    return {
                        url: '/daoyoujihuadanxingchengAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['daoyoujihuadanxingcheng']
            }),
            //地接业务/团队计划/导游计划单/行程安排/删除
            daoyoujihuadanxingchengDel: build.mutation({
                query(user) {
                    return {
                        url: '/daoyoujihuadanxingchengDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['daoyoujihuadanxingcheng']
            }),
            //地接业务/团队计划/导游计划单/行程安排/修改保存
            daoyoujihuadanxingchengUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/daoyoujihuadanxingchengUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['daoyoujihuadanxingcheng']
            }),
            //地接业务/团队计划/导游计划单/行程安排/修改
            daoyoujihuadanxingchengUpdate: build.query({
                query(user) {
                    return {
                        url: '/daoyoujihuadanxingchengUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['daoyoujihuadanxingcheng']
            }),
            //地接业务/团队计划/导游计划单/行程安排/列表
            daoyoujihuadanxingchengList: build.query({
                query(user) {
                    return {
                        url: '/daoyoujihuadanxingchengList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['daoyoujihuadanxingcheng']
            }),
        }
    }
})

export const {
    useUser_addMutation,
    useUser_delMutation,
    useAll_dept_userQuery,
    useUser_updateQuery,
    useEntUserListQuery,
    useUser_update_saveMutation,
    useUser_stateMutation,
    useGetUserDeptManageQuery,
    useDept_addMutation,
    useDept_updateQuery,
    useDept_update_saveMutation,
    useDept_delMutation,
    useAll_deptQuery,
    useDeptSubDeptAndUserQuery,
    useGetEnterpriseDeptQuery,
    useAddNewsMutation,
    useDelNewsMutation,
    useNewsListQuery,
    useNewsUpdateQuery,
    useUpdateSaveNewsMutation,
    useNewsStateMutation,
    useSystemListQuery,
    useAddSystemMutation,
    useDelSystemMutation,
    useSystemUpdateQuery,
    useUpdateSaveSystemMutation,
    useUserOperateManageUpdateQuery,
    useUserOperateManageUpdateSaveMutation,
    useLoginCodeMutation,
    useUserMmLoginMutation,
    useUserLoginMutation,
    useRegisterCodeMutation,
    useUserRegisterMutation,
    useAutoCbExpenditureTypeMutation,
    useAutoClientTypeMutation,
    useAutoCostTypeMutation,
    useAutoGoodsTypeMutation,
    useAutoQtExpenditureTypeMutation,
    useAutoQtIncomeTypeMutation,
    useAutoXsIncomeTypeMutation,
    useEntSwitchoverQuery,
    useDeptSwitchoverQuery,
    useSystemStateMutation,
    useAddGoodsSpecMutation,
    useDelGoodsSpecMutation,
    useGoodsSpecListQuery,
    useGoodsSpecUpdateQuery,
    useUpdateSaveGoodsSpecMutation,
    useAddGoodsSpecSupMutation,
    useDelGoodsSpecSupMutation,
    useGoodsSpecSubListQuery,
    useGoodsSpecSubUpdateQuery,
    useUpdateSaveGoodsSpecSupMutation,
    useEntAppAddMutation,
    useEntAppDelMutation,
    useUserUseAppLogMutation,
    useEntAppListQuery,
    useContractAddMutation,
    useContractDelMutation,
    useContractListQuery,
    useContractUpdateQuery,
    useContractUpdateSaveMutation,
    useGetContractUidQuery,
    useGoodsAddMutation,
    useGoodsDelMutation,
    useGoodsListQuery,
    useGoodsUpdateQuery,
    useGoodsUpdateSaveMutation,
    useBusinessNumAddMutation,
    useBusinessNumDelMutation,
    useBusinessNumUpdateQuery,
    useBusinessNumPhotoListQuery,
    useBusinessNumListQuery,
    useBusinessNumUpdateSaveMutation,
    useBusinessNumAuditMutation,
    useBusinessNumAuditCancelMutation,
    useGetBusinessNumUidQuery,
    useBusinessNumYwListQuery,
    useBusinessNumEvaluateListQuery,
    useEbnStatisticsQuery,
    useClientStatisticsQuery,
    useGoodsStatisticsQuery,
    useUidStatisticsQuery,
    useYwStatisticsQuery,
    useEcTypeStatisticsQuery,
    useGoodsTypeStatisticsQuery,
    useGetBusinessDeptQuery,
    useIncomeExpenditureAddMutation,
    useIncomeExpenditureDelMutation,
    useIncomeExpenditureListQuery,
    useIncomeExpenditureUpdateQuery,
    useIncomeExpenditureUpdateSaveMutation,
    useIncomeExpenditureAuditMutation,
    useIncomeExpenditureCancelAuditMutation,
    useIncomeExpenditureChangeTeamMutation,
    useQtszClientProfitQuery,
    useQtszDeptProfitQuery,
    useFyClientProfitQuery,
    useFyDeptProfitQuery,
    useFyDeptJingProfitQuery,
    useFyTypeProfitQuery,
    useGetIncomeExpenditureUidQuery,
    useWorkPlanAddMutation,
    useWorkPlanDelMutation,
    useWorkPlanListQuery,
    useWorkPlanUpdateQuery,
    useWorkPlanUpdateSaveMutation,
    useTopicAddMutation,
    useTopicDelMutation,
    useTopicUpdateSaveMutation,
    useTopicListQuery,
    useTopicUpdateQuery,
    useTestAddMutation,
    useTestDelMutation,
    useTestListQuery,
    useTestUpdateQuery,
    useTestUpdateSaveMutation,
    useMypxListQuery,
    useMypxEvaluateQuery,
    useMypxEvaluateSaveMutation,
    useMyResponsibleListQuery,
    useClientListQuery,
    useClientUpdateQuery,
    useAddClientMutation,
    useDelClientMutation,
    useUpdateSaveClientMutation,
    useGetClientUidQuery,
    useClientTypeAddMutation,
    useClientTypeDelMutation,
    useClientTypeListQuery,
    useClientTypeUpdateQuery,
    useClientTypeUpdateSaveMutation,
    usePerformancePlanAddMutation,
    usePerformancePlanDelMutation,
    usePerformancePlanListQuery,
    usePerformancePlanUpdateQuery,
    usePerformancePlanUpdateSaveMutation,
    useTaskAddMutation,
    useTaskDelMutation,
    useTaskListQuery,
    useTaskUpdateQuery,
    useTaskUpdateSaveMutation,
    useMyTaskListQuery,
    useIncomeExpenditureHxListQuery,
    useIncomeExpenditureHxSaveMutation,
    useIncomeExpenditureHxCancelMutation,
    useIncomeExpenditureHxFinishListQuery,
    useHuoquxiazhangpingzhengListQuery,
    useIncomeExpenditureHxFinishLogListQuery,
    useIncomeExpenditureClientStatisticsQuery,
    useIncomeExpenditureDeptStatisticsQuery,
    useWlDeptProfitQuery,
    useWlClientProfitQuery,
    useWlClientTypeProfitQuery,
    useWlGoodsProfitQuery,
    useWlGoodsTypeProfitQuery,
    useWlUidProfitQuery,
    useWlywProfitQuery,
    useEntAddMutation,
    useEntListQuery,
    useEntUpdateQuery,
    useEntUpdateSaveMutation,
    useEntAdminStateMutation,
    useEntAdminListQuery,
    useEntAdminUpdateQuery,
    useEntAdminUpdateSaveMutation,
    useEntMoneyAddMutation,
    useEntMoneyListQuery,
    useEntMoneyDelMutation,
    useEntMoneyAppAddMutation,
    useEntAppNoPayListQuery,
    usePurviewSettingUpdateQuery,
    usePurviewSettingUpdateSaveMutation,
    useAllDeptUserSearchQuery,
    useGetUserPowerQuery,
    useNewsTypeAddMutation,
    useNewsTypeListQuery,
    useNewsTypeUpdateQuery,
    useNewsTypeUpdateSaveMutation,
    useNewsTypeDelMutation,
    useGwGoodsTypeAddMutation,
    useGwGoodsTypeDelMutation,
    useGwGoodsTypeUpdateQuery,
    useGwGoodsTypeUpdateSaveMutation,
    useGwGoodsTypeListQuery,
    useGwGoodsTypeSubAddMutation,
    useGwGoodsTypeSubDelMutation,
    useGwGoodsTypeSubUpdateQuery,
    useGwGoodsTypeSubListQuery,
    useGwGoodsTypeSubUpdateSaveMutation,
    useGwGoodsDelMutation,
    useGetGoodsTreeQuery,
    useGwGoodsAddMutation,
    useGwGoodsListQuery,
    useGwGoodsUpdateQuery,
    useGetGoodsIDQuery,
    useGwGoodsUpdateSaveMutation,
    useGwGoodsStateMutation,
    useGoodsTypeAddMutation,
    useGoodsTypeDelMutation,
    useGoodsTypeListQuery,
    useGoodsTypeUpdateQuery,
    useGoodsTypeUpdateSaveMutation,
    useGoodsGuigeAddMutation,
    useGoodsGuigeDelMutation,
    useGoodsGuigeListQuery,
    useGoodsGuigeUpdateQuery,
    useGoodsGuigeUpdateSaveMutation,
    useEntJyAppListQuery,
    useGetClientIDQuery,
    useFinanceSubTypeAddMutation,
    useFinanceSubTypeDelMutation,
    useFinanceSubTypeListQuery,
    useFinanceSubTypeUpdateQuery,
    useFinanceSubTypeUpdateSaveMutation,
    useGetFinanceSubTypeQuery,
    usePhotoAlbumAddMutation,
    usePhotoAlbumDelMutation,    
    usePhotoAlbumOneKeyDelMutation,    
    usePhotoAlbumListQuery,
    usePhotoAlbumUpdateQuery,
    usePhotoAlbumUpdateSaveMutation,
    usePhotoAlbumGroupAddMutation,
    usePhotoAlbumGroupDelMutation,
    usePhotoAlbumGroupListQuery,
    usePhotoAlbumGroupZipMutation,
    usePhotoAlbumGroupUpdateQuery,
    usePhotoAlbumGroupUpdateSaveMutation,
    usePhotoAlbumGroupUploadAddMutation,
    usePhotoAlbumGroupUploadDelMutation,
    usePhotoAlbumGroupUploadListQuery,
    usePhotoAlbumGroupUploadUpdateQuery,
    usePhotoAlbumGroupUploadUpdateSaveMutation,
    usePhotoAlbumSYSAddMutation,
    usePhotoAlbumSYSDelMutation,
    usePhotoAlbumSYSListQuery,
    usePhotoAlbumSYSUpdateQuery,
    usePhotoAlbumSYSUpdateSaveMutation,
    useAppSettingAddMutation,
    useAppSettingDelMutation,
    useAppSettingListQuery,
    useAppSettingUpdateQuery,
    useAppSettingUpdateSaveMutation,
    useAppMenuAddMutation,
    useAppMenuDelMutation,
    useAppMenuListQuery,
    useAppMenuUpdateQuery,
    useAppMenuUpdateSaveMutation,
    useAppOperationAddMutation,
    useAppOperationDelMutation,
    useAppOperationListQuery,
    useAppOperationUpdateQuery,
    useAppOperationUpdateSaveMutation,
    useGetEntAppMenuOperationQuery,
    useAppStationAddMutation,
    useAppStationDelMutation,
    useAppStationListQuery,
    useAppStationUpdateQuery,
    useAppStationUpdateSaveMutation,
    useGetProfitYearQuery,
    useJbrESVProfitListQuery,
    useUidESVProfitListQuery,
    useDeptESVProfitListQuery,
    useGoodsESVProfitListQuery,
    useMonthProfitListQuery,
    useEvaluateTypeAddMutation,
    useEvaluateTypeDelMutation,
    useEvaluateTypeListQuery,
    useEvaluateTypeUpdateQuery,
    useEvaluateTypeUpdateSaveMutation,
    useEvaluatePointAddMutation,
    useEvaluatePointDelMutation,
    useEvaluatePointListQuery,
    useEvaluatePointUpdateQuery,
    useEvaluatePointUpdateSaveMutation,
    useTourGuideAddMutation,
    useTourGuideListQuery,
    useTourGuideUpdateQuery,
    useTourGuideUpdateSaveMutation,
    useEntEvaluatePointAddMutation,
    useEntEvaluatePointDelMutation,
    useEntEvaluatePointListQuery,
    useEntEvaluatePointUpdateQuery,
    useEntEvaluatePointUpdateSaveMutation,
    useUserEbnEvaluateListQuery,
    useApplyforInfoListQuery,
    useApplyforInfoDetailMutation,
    useApplyforInfoAddMutation,
    useTaskManageAddMutation,
    useTaskManageListQuery,
    useTaskManageDelMutation,
    useTaskManageUserListQuery,
    useTaskManageFaBuUserListQuery,
    useTaskManageUpdateSaveMutation,
    useTaskManageUpdateQuery,
    useTaskManageAuditMutation,
    useTaskMessageAddMutation,
    useTaskMessageDelMutation,
    useTaskMessageListQuery,
    useTaskMessageUpdateQuery,
    useTaskMessageUpdateSaveMutation,
    useAppSynthesisAddMutation,
    useAppSynthesisDelMutation,
    useAppSynthesisListQuery,
    useAppSynthesisUpdateQuery,
    useAppSynthesisUpdateSaveMutation,
    useAppSynthesisUserReadLogMutation,
    useCapitalAccountDetailAddMutation,
    useZhuanzhangpinpingzhengListQuery,
    useJizhangshouzhiListQuery,
    useZjDeptProfitQuery,
    useZjUidProfitQuery,
    useZjJbrUidProfitQuery,
    useZjGoogdsTypeProfitQuery,
    useZjGoogdsProfitQuery,
    useZjClientProfitQuery,
    useZjClientTypeProfitQuery,
    useCapitalAccountAddMutation,
    useCapitalAccountListQuery,
    useCapitalAccountDelMutation,
    useCapitalAccountUpdateQuery,
    useCapitalAccountUpdateSaveMutation,
    useCapitalAccountConfirmMutation,
    useCapitalAccountLogListQuery,
    useCapitalAccountLogDelMutation,
    useEntRegisterShouZhiListQuery,
    useEntRegisterTaiZhangListQuery,
    useEntRegisterRenWuListQuery,
    useEntRegisterWangLaiListQuery,
    useDuizhangAddMutation,
    useDuizhangDelMutation,
    useDuizhangSendListQuery,
    useGetDuiZhangQuery,
    useDuizhangAuditMutation,
    useDuizhangReceptionBaningMutation,
    useDuizhangAffirmMutation,
    useDuizhangReceptionListQuery,
    useEntPayGoodsAddMutation,
    useEntPayGoodsListQuery,
    useEntPayGoodsUpdateQuery,
    useEntPayGoodsUpdateSaveMutation,
    useUserUpdatePwdCodeMutation,
    useUserUpdatePwdMutation,
    useGetDeptTzEndDateQuery,
    useDeptTzEndDateUpdateSaveMutation,
    useDijieTemplateTaiTouAddMutation,
    useDijieTemplateTaiTouDelMutation,
    useDijieTemplateTaiTouListQuery,
    useDijieTemplateTaiTouUpdateQuery,
    useDijieTemplateTaiTouUpdateSaveMutation,
    useDijieTemplateXianLuAddMutation,
    useDijieTemplateXianLuDelMutation,
    useDijieTemplateXianLuListQuery,
    useDijieTemplateXianLuUpdateQuery,
    useDijieTemplateXianLuUpdateSaveMutation,
    useDijieTemplateXingChengAddMutation,
    useDijieTemplateXingChengDelMutation,
    useDijieTemplateXingChengListQuery,
    useDijieTemplateXingChengUpdateQuery,
    useDijieTemplateXingChengUpdateSaveMutation,
    useDijieTemplateJieDaiBiaoZhunAddMutation,
    useDijieTemplateJieDaiBiaoZhunDelMutation,
    useDijieTemplateJieDaiBiaoZhunListQuery,
    useDijieTemplateJieDaiBiaoZhunUpdateQuery,
    useDijieTemplateJieDaiBiaoZhunUpdateSaveMutation,
    useDijieTemplateFeiYongMingXiAddMutation,
    useDijieTemplateFeiYongMingXiDelMutation,
    useDijieTemplateFeiYongMingXiListQuery,
    useDijieTemplateFeiYongMingXiUpdateQuery,
    useDijieTemplateFeiYongMingXiUpdateSaveMutation,
    useDijieTemplateFeiYongBuHanAddMutation,
    useDijieTemplateFeiYongBuHanDelMutation,
    useDijieTemplateFeiYongBuHanListQuery,
    useDijieTemplateFeiYongBuHanUpdateQuery,
    useDijieTemplateFeiYongBuHanUpdateSaveMutation,
    useDijieTemplateZhuYiShiXiangAddMutation,
    useDijieTemplateZhuYiShiXiangDelMutation,
    useDijieTemplateZhuYiShiXiangListQuery,
    useDijieTemplateZhuYiShiXiangUpdateQuery,
    useDijieTemplateZhuYiShiXiangUpdateSaveMutation,
    useDijieTemplateGuiGeAddMutation,
    useDijieTemplateGuiGeDelMutation,
    useDijieTemplateGuiGeListQuery,
    useDijieTemplateGuiGeUpdateQuery,
    useDijieTemplateGuiGeUpdateSaveMutation,
    useDijieBaoJiaDanAddMutation,
    useDijieBaoJiaDanDelMutation,
    useDijieBaoJiaDanListQuery,
    useDijieBaoJiaDanUpdateQuery,
    useDijieBaoJiaDanUpdateSaveMutation,
    useDijieBaoJiaDanCopyListMutation,
    useDijieBaoJiaDanDaoYouCopyListMutation,
    useQuanxianAddMutation,
    useQuanxianDelMutation,
    useQuanxianTreeListQuery,
    useQuanxianUpdateQuery,
    useQuanxianUpdateSaveMutation,
    useAccount_listQuery,
    useDijieBaoJiaDanXingChengAddMutation,
    useDijieBaoJiaDanXingChengDelMutation,
    useDijieBaoJiaDanXingChengListQuery,
    useDijieBaoJiaDanXingChengUpdateQuery,
    useDijieBaoJiaDanXingChengUpdateSaveMutation,
    useDijieBaoJiaDanJieDaiBiaoZhunAddMutation,
    useDijieBaoJiaDanJieDaiBiaoZhunDelMutation,
    useDijieBaoJiaDanJieDaiBiaoZhunListQuery,
    useDijieBaoJiaDanJieDaiBiaoZhunUpdateQuery,
    useDijieBaoJiaDanJieDaiBiaoZhunUpdateSaveMutation,
    useDijieBaoJiaDanFeiYongBuHanAddMutation,
    useDijieBaoJiaDanFeiYongBuHanDelMutation,
    useDijieBaoJiaDanFeiYongBuHanListQuery,
    useDijieBaoJiaDanFeiYongBuHanUpdateQuery,
    useDijieBaoJiaDanFeiYongBuHanUpdateSaveMutation,
    useDijieBaoJiaDanZhuYiShiXiangAddMutation,
    useDijieBaoJiaDanZhuYiShiXiangDelMutation,
    useDijieBaoJiaDanZhuYiShiXiangListQuery,
    useDijieBaoJiaDanZhuYiShiXiangUpdateQuery,
    useDijieBaoJiaDanZhuYiShiXiangUpdateSaveMutation,
    useDijieBaoJiaDanFeiYongMingXiAddMutation,
    useDijieBaoJiaDanFeiYongMingXiDelMutation,
    useDijieBaoJiaDanFeiYongMingXiListQuery,
    useDijieBaoJiaDanFeiYongMingXiUpdateQuery,
    useDijieBaoJiaDanFeiYongMingXiUpdateSaveMutation,
    useDijieBaoJiaDanGuiGeAddMutation,
    useDijieBaoJiaDanGuiGeDelMutation,
    useDijieBaoJiaDanGuiGeListQuery,
    useDijieBaoJiaDanGuiGeUpdateQuery,
    useDijieBaoJiaDanGuiGeUpdateSaveMutation,
    useDijieBaoJiaDanJieSuanFangShiAddMutation,
    useDijieBaoJiaDanJieSuanFangShiDelMutation,
    useDijieBaoJiaDanJieSuanFangShiListQuery,
    useDijieBaoJiaDanJieSuanFangShiUpdateQuery,
    useDijieBaoJiaDanJieSuanFangShiUpdateSaveMutation,
    useDijieBaoJiaDanDaoYouFeiYongAddMutation,
    useDijieBaoJiaDanDaoYouFeiYongDelMutation,
    useDijieBaoJiaDanDaoYouFeiYongListQuery,
    useDijieBaoJiaDanDaoYouFeiYongUpdateQuery,
    useDijieBaoJiaDanDaoYouFeiYongUpdateSaveMutation,
    useShangpinTypeAddMutation,
    useShangpinTypeDelMutation,
    useShangpinTypeListQuery,
    useShangpinTypeUpdateQuery,
    useShangpinTypeUpdateSaveMutation,
    useShangpinAddMutation,
    useShangpinDelMutation,
    useShangpinListQuery,
    useShangpinUpdateQuery,
    useShangpinUpdateSaveMutation,
    useShangpinStateMutation,
    useGetCountryQuery,
    useAddCountryMutation,
    useEditCountryMutation,
    useDeleteCountryMutation,
    useEditCountryByIdQuery,
    useGetProvinceQuery,
    useAddProvinceMutation,
    useEditProvinceByIdQuery,
    useEditProvinceMutation,
    useGetCityQuery,
    useAddCityMutation,
    useEditCityByIdQuery,
    useEditCityMutation,
    useGetDistrictQuery,
    useAddDistrictMutation,
    useEditDistrictMutation,
    useEditDistrictByIdQuery,
    useGetStreetQuery,
    useAddStreetMutation,
    useEditStreetByIdQuery,
    useEditStreetMutation,
    useDestinationAddMutation,
    useDestinationListQuery,
    useDestinationDelMutation,
    useDestinationUpdateQuery,
    useDestinationUpdateSaveMutation,
    useAreaTreeQuery,
    useGet_region_tree2Query,
    useLandmarkAddMutation,
    useLandmarkListQuery,
    useLandmarkUdateQuery,
    useLandmarkUdateSaveMutation,
    useLandmarkDelMutation,
    useYewuTuanHaoAddMutation,
    useYewuTuanHaoListQuery,
    useYewuTuanHaoUpdateQuery,
    useYewuTuanHaoUpdateSaveMutation,
    useYewuTuanHaoDelMutation,
    useEntDianPuAddMutation,
    useEntDianPuListQuery,
    useEntDianPuUpdateQuery,
    useEntDianPuUpdateSaveMutation,
    useWxShareConfigMutation,
    usePhotoDataListQuery,
    usePhotoClickNnumMutation,
    usePhotoBrowerNnumMutation,
    useSysInAlbumListQuery,
    useSysInClassListQuery,
    useDijieTemplateKeHuAddMutation,
    useDijieTemplateKeHuListQuery,
    useDijieTemplateKeHuUpdateQuery,
    useDijieTemplateKeHuUpdateSaveMutation,
    useDijieTemplateKeHuDelMutation,
    useDijieTemplateDaoYouAddMutation,
    useDijieTemplateDaoYouListQuery,
    useDijieTemplateDaoYouUpdateQuery,
    useDijieTemplateDaoYouUpdateSaveMutation,
    useDijieTemplateDaoYouDelMutation,
    useDijieTemplateKeChengAddMutation,
    useDijieTemplateKeChengListQuery,
    useDijieTemplateKeChengUpdateQuery,
    useDijieTemplateKeChengUpdateSaveMutation,
    useDijieTemplateKeChengDelMutation,
    useDijieBaoJiaDanDaoKeChengAddMutation,
    useDijieBaoJiaDanDaoKeChengListQuery,
    useDijieBaoJiaDanDaoKeChengUpdateQuery,
    useDijieBaoJiaDanDaoKeChengUpdateSaveMutation,
    useDijieBaoJiaDanDaoKeChengDelMutation,
    useDaoyoujihuadanAddMutation,
    useDaoyoujihuadanListQuery,
    useDaoyoujihuadanUpdateQuery,
    useDaoyoujihuadanUpdateSaveMutation,
    useDaoyoujihuadanDelMutation,
    useDaoyoujihuadanxingchengAddMutation,
    useDaoyoujihuadanxingchengListQuery,
    useDaoyoujihuadanxingchengUpdateQuery,
    useDaoyoujihuadanxingchengUpdateSaveMutation,
    useDaoyoujihuadanxingchengDelMutation,
} = ReactWebSiteApi;
export default ReactWebSiteApi;