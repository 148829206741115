import React, { useEffect, useRef, useState } from 'react'
import ModalEdit from '../../../../cpn/ModalEdit'
import { DatePicker, Form,Input,message, Modal, Radio, Space } from 'antd'
import { useDijieBaoJiaDanUpdateQuery, useDijieBaoJiaDanUpdateSaveMutation, } from '../../../../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import { fileType } from '../customType';
import Client from '../temp/client/Client'
import LineName from '../temp/lineName/LineName';
const { TextArea } = Input;
function FileEdit(props) {
    const [form] = Form.useForm();  
    const editForm = useRef()
    const [info,setInfo] = useState({
        ltbjd_e_id:localStorage.getItem('e_id'),
        ltbjd_update_uid:localStorage.getItem('eu_id'),
        ltbjd_update_uname:localStorage.getItem('eu_name'),
        ltbjd_id:props.ltbjd_id,
        ltbjd_sp_name:'',
        ltbjd_date:'',
        ltbjd_sp_id:'',
        ltbjd_daoyou:'',
        ltbjd_jietuan_content:'',
        ltbjd_client_name:'',
    });
    const [open,setOpen] = useState()
    //获取
    const {data,isSuccess} = useDijieBaoJiaDanUpdateQuery({ltbjd_id:props.ltbjd_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){               
                editForm.current.setFieldsValue({
                    ltbjd_dept_id:data.Result.ed_name,                         
                    ltbjd_type_id:data.Result.ltbjd_type_id,                         
                    ltbjd_date:dayjs(data.Result.ltbjd_date),    
                    ltbjd_ltxl_name:data.Result.ltbjd_ltxl_name,                   
                    ltbjd_client_name:data.Result.ltbjd_client_name,                   
                    ltbjd_content:data.Result.ltbjd_content,                   
                    ltbjd_daoyou:data.Result.ltbjd_daoyou,                   
                    ltbjd_jietuan_content:data.Result.ltbjd_jietuan_content,                   
                })                                   
                setInfo(prevsState=>({...prevsState,
                    ltbjd_dept_id:data.Result.ltbjd_dept_id,                  
                    ltbjd_type_id:data.Result.ltbjd_type_id,                  
                    ltbjd_ltxl_name:data.Result.ltbjd_ltxl_name,
                    ltbjd_date:data.Result.ltbjd_date,
                    ltbjd_client_name:data.Result.ltbjd_client_name,
                    ltbjd_content:data.Result.ltbjd_content,
                    ltbjd_yemei:data.Result.ltbjd_yemei,
                    ltbjd_yejiao:data.Result.ltbjd_yejiao,
                    ltbjd_daoyou:data.Result.ltbjd_daoyou,                   
                    ltbjd_jietuan_content:data.Result.ltbjd_jietuan_content,          
                    ed_name:data.Result.ed_name,          
                }))
            }
        }
    },[data,isSuccess,])
    const [updateSave] = useDijieBaoJiaDanUpdateSaveMutation()    
    //模版
    const [openName,setOpenName] = useState(false)    
    //组团社
    const [openClient,setOpenClient] = useState(false)
    
    return (
        <ModalEdit
            getOpen={(e) => {setOpen(prevsState=>e );}}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        //获取产品人群类型
                        let arr = []
                        for (let key in values) {
                            if (values.hasOwnProperty(key)) { // 确保key是对象自身的属性
                                if(key.includes('egg')){
                                    arr.push(values[key])
                                }
                            }
                        } 
                        console.log(values);                       
                        updateSave({
                            ltbjd_id:props.ltbjd_id,
                            ltbjd_e_id:localStorage.getItem('e_id'),                            
                            ltbjd_e_name:localStorage.getItem('e_name'),
                            ltbjd_client_name:info.ltbjd_client_name,
                            ltbjd_update_uid:localStorage.getItem('eu_id'),
                            ltbjd_update_uname:localStorage.getItem('eu_name'),
                            ltbjd_sp_id:info.ltbjd_sp_id,
                            ltbjd_sp_name:info.ltbjd_sp_name,
                            ltbjd_dept_id:localStorage.getItem('ed_id'),
                            ltbjd_dept_name:localStorage.getItem('ed_name'),
                            ltbjd_ltxl_name:info.ltbjd_ltxl_name,
                            ltbjd_date:info.ltbjd_date,
                            ltbjd_type_id:info.ltbjd_type_id,
                            ltbjd_content:info.ltbjd_content, 
                            ltbjd_yemei:info.ltbjd_yemei,
                            ltbjd_yejiao:info.ltbjd_yejiao,
                            ltbjd_daoyou:info.ltbjd_daoyou,
                            ltbjd_jietuan_content:info.ltbjd_jietuan_content,
                            ltbjd_kehu_type_id:info.ltbjd_kehu_type_id,
                        }).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('修改成功！') 
                                setOpen(false)                                                 
                            }
                            if (res.data.Status === 'fail') {
                                message.error('修改失败！'+res.data.Message)
                            }
                        })
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
                
            }}
            open={open}
            title='文件'
        >
            <Form
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 4 }}
                ref={editForm}
            >
                <Form.Item 
                    label='类型'
                    required
                    name='ltbjd_type_id'
                >
                    <Radio.Group 
                        options={fileType}
                        optionType='button'
                        onChange={(e) => {setInfo(prevState=>({...prevState,ltbjd_type_id:e.target.value,}))}}
                    />
                </Form.Item>      
                <Form.Item
                    label='组团社'                    
                    required                    
                >
                    <Space align='center'>
                        <div>{info.ltbjd_client_name}</div>                     
                        <a onClick={() => {setOpenClient(true)}}>请选择</a>
                        <Modal
                            open={openClient}
                            okText="提交"
                            cancelText="取消"
                            onCancel={() => {setOpenClient(false);}}
                            onOk={() => {}}
                            footer={null}
                            width={800}
                            title='选择组团社'
                        >
                            <Client
                                type='select'
                                getClient={(e) => {
                                    setInfo(prevState=>({
                                        ...prevState,
                                        ltbjd_ltkh_id:e.ltkh_id,
                                        ltbjd_client_name:e.ltkh_name,
                                        ltbjd_kehu_type_id:e.ltkh_type_id,
                                    }))                                    
                                }}
                                getOpen={(e) => {setOpenClient(false)}}
                            />
                        </Modal> 
                    </Space>
                </Form.Item>
                <Form.Item  label='线路名称' required >
                    <Space>
                        <div>{info.ltbjd_ltxl_name}</div>
                        <a onClick={() => {setOpenName(true)}}>请选择</a>
                    </Space>               
                    <Modal
                        open={openName}
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenName(false);}}
                        onOk={() => {}}
                        footer={null}
                        width={1000}
                        title='线路名称模板'
                    >
                        <LineName
                            get={true}
                            ltbjd_dept_id={props.ltbjd_dept_id}
                            getName={(e) => {setInfo(prevState=>({...prevState,ltbjd_ltxl_name:e}))}}
                            getOpen={(e) => {setOpenName(e)}}
                        />
                    </Modal>
                </Form.Item>
                <Form.Item name="ltbjd_date" label='出发日期' wrapperCol={{span:19}} required>
                    <DatePicker  
                        value={info.ltbjd_date}
                        onChange={(e) => {setInfo(prevState=>({...prevState,ltbjd_date:dayjs(e).format('YYYY-MM-DD')}))}}
                    />
                </Form.Item> 
                <Form.Item  label='备注' wrapperCol={{span:19}} name='ltbjd_content'>
                    <TextArea 
                        onChange={(e) => {setInfo(prevState=>({...prevState,ltbjd_content:e.target.value}))}}
                        autoSize={{
                            minRows: 2,                                
                            }}
                    />
                </Form.Item>  
                {(props.customType==='4' || props.customType==='5' )&& <>
                    <Form.Item name="num" label='团号' wrapperCol={{span:19}} required>
                        <Input onChange={(e) => {setInfo(prevState=>({...prevState,ltbjd_client_name:e.target.value}))}}/>
                    </Form.Item>                
                </>}
                {(props.customType==='4')&& <>
                    <Form.Item 
                        name="ltbjd_daoyou" 
                        label='导游姓名' 
                        wrapperCol={{span:19}} 
                        required
                        initialValue={'姓名：；手机号码：；导游证号：；'}
                    >
                        <Input 
                            onChange={(e) => {setInfo(prevState=>({...prevState,ltbjd_daoyou:e.target.value}))}}
                        />
                    </Form.Item>
                    <Form.Item  
                        label='接团备注' 
                        wrapperCol={{span:19}} 
                        required
                        name="ltbjd_jietuan_content" 
                        initialValue={'接团时间：；接团地点：；送团时间：；送团地点：；领队姓名：；领队联系方式：；'}
                    >
                        <TextArea 
                            onChange={(e) => {setInfo(prevState=>({...prevState,ltbjd_jietuan_content:e.target.value}))}}
                            autoSize={{
                                minRows: 4,                                
                              }}
                        />
                    </Form.Item>                
                </>}
                
            </Form>
        </ModalEdit>
    )
}

export default FileEdit