import React, { useEffect, useState } from 'react'
import { message,  Space,  } from 'antd'
import TableCheck from '../../../../../cpn/TableCheck'
import ModalDel from '../../../../../cpn/ModalDel'
import GuidePlanRouteAdd from './GuidePlanRouteAdd'
import GuidePlanRouteEdit from './GuidePlanRouteEdit'
import { useDaoyoujihuadanxingchengDelMutation, useDaoyoujihuadanxingchengListQuery } from '../../../../../store/ReactWebSiteApi'
function GuidePlanRoute(props) {
    const [list,setList] = useState()
    const {data,isSuccess} = useDaoyoujihuadanxingchengListQuery({dyjhdxc_jhd_id:props.dyjhdxc_jhd_id})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                const arr = data.Result.map((item) => {
                    // console.log(data);
                    const {dyjhdxc_id:key,dyjhdxc_jihua,dyjhdxc_ctime,dyjhdxc_cankaoshijian,dyjhdxc_mingcheng,dyjhdxc_leixing,dyjhdxc_day,dyjhdxc_content} = item
                    
                    return item={key,dyjhdxc_jihua,dyjhdxc_ctime,dyjhdxc_cankaoshijian,dyjhdxc_mingcheng,dyjhdxc_leixing,dyjhdxc_day,dyjhdxc_content}
                })
                const sortArr = arr.sort((a, b) => {
                    if (a.dyjhdxc_day === b.dyjhdxc_day) {
                        return a.dyjhdxc_cankaoshijian.localeCompare(b.dyjhdxc_cankaoshijian);
                    }                    
                    return a.dyjhdxc_day - b.dyjhdxc_day
                  });
                
                const handleData = (array, key) => {
                    if(array.length === 0) return
                    let arr = [...array]
                    // 1、startItem(默认rowSpan = 1)记录开始计数的对象
                    let startItem = arr[0]
                    startItem.rowSpan = 1
                    // 2、遍历数组，取下一项进行比较，当name相同则startItem的rowSpan+1, 否则设置新的startItem为下一项
                    arr.forEach((item, index) => {
                        let nextItem = arr[index+1] || {}
                        if(item[key] === nextItem[key]){
                            startItem.rowSpan++
                        }else{
                            startItem = nextItem
                            startItem.rowSpan = 1
                        }
                    })
                    return arr
                }
                let newArr=handleData(sortArr, 'dyjhdxc_day')
                setList(newArr)
            }else{
                setList([])
            }
        }
    },[data,isSuccess])
    //删除
    const [del] = useDaoyoujihuadanxingchengDelMutation()
   
    const column = [
        {
            key:'1',
            title:'天数',
            dataIndex:'dyjhdxc_day',
            render:(text) => `第${text}天`,
            onCell: (row) => ({ rowSpan: row.rowSpan || 0 }),            
            width:'120px',
        },
        {
            key:'2',
            title:'参考时间',
            dataIndex:'dyjhdxc_cankaoshijian',
            width:'120px',
        }, 
        {
            key:'13',
            title:'行程安排',
            dataIndex:'dyjhdxc_mingcheng',
        },           
        {
            key:'4',
            title:'导游计划',
            dataIndex:'dyjhdxc_jihua',
            width:'120px',
        },              
        {
            key:'5',
            title:'操作',            
            width:'120px',
            render:(_,record) => {
                return<Space>
                    <GuidePlanRouteEdit
                        dyjhdxc_id={record.key}
                        dyjhdxc_bjd_id={props.dyjhdxc_bjd_id}
                    />
                    <ModalDel onOk={() => {
                        del({dyjhdxc_id:record.key}).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                </Space>
            }
        },        
    ]
    if(props.noAdd || props.preview){
        column.pop()
    }
    if(props.customType==='1' || props.customType==='6'){
        column.splice(3, 1)
    }
    return (    
        <TableCheck 
            title='行程安排'
            titleRight={(!props.noAdd && !props.preview) && <GuidePlanRouteAdd
                dyjhdxc_jhd_id={props.dyjhdxc_jhd_id}
                />}
            columns={column}
            dataSource={list}
            size={props.customType==='1'?'middle':'small'}
        />
    )
}

export default GuidePlanRoute