import React,{useState,useEffect}from 'react'
import { Modal,Descriptions,Tag, Space,} from 'antd';
import {useTaskManageUpdateQuery,} from '../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import TaskMessageAdd from './TaskMessageAdd';
import TaskMessageList from './TaskMessageList';
import TaskEdit from './TaskEdit';
import TaskDel from './TaskDel';
function TaskCheck(props) {
    const [open,setOpen] = useState()
    const [info,setInfo] = useState({
        et_content: '',
        et_e_id: localStorage.getItem('e_id'),
        et_name: '',
        et_uname: '',
        et_ctime:'',
        etu_user_id: '',
        etu_user_name: '',
        et_date:'',
        et_id:props.et_id
    });
    const {data,isSuccess} = useTaskManageUpdateQuery({et_id:props.et_id},{skip:!open})    
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                let etu_user_id;
                etu_user_id = data.Result.user.map((item) => {
                    return item.etu_user_id
                })
                let etu_user_name;
                etu_user_name = data.Result.user.map((item,index) => {
                    return <span key={index}>{item.etu_user_name}</span>
                })
                let et_status_name
                switch (data.Result.et_status) {
                    case '1':
                        et_status_name = <Tag color='#f50'>待完成</Tag>
                        break;
                    case '2':
                        et_status_name = <Tag color='#2db7f5'>待回复</Tag>
                        break;
                    case '3':
                        et_status_name = <Tag color='#87d068'>已结束</Tag>
                        break;
                    default:
                        break;
                }
                // console.log(data.Result.et_content);
                setInfo(prevState=>({...prevState,
                    et_date:dayjs(data.Result.et_date),
                    et_content:data.Result.et_content,
                    et_name:data.Result.et_name, 
                    et_uname:data.Result.et_uname, 
                    et_ctime:data.Result.et_ctime, 
                    etu_user_id,    
                    etu_user_name,             
                    et_status_name,
                    et_status:data.Result.et_status, 
                    et_uid:data.Result.et_uid,            
                }))
            }
        }
    },[data,isSuccess])
    const descriptions = [
        
        {
            key:"1",
            label: '任务名称',
            children: <div style={{fontWeight:"bold"}}>{info.et_name}</div>,
            span:3
        },
        {
            key:"2",
            label: '操作',
            children: <Space>
                <TaskEdit 
                    et_id={props.et_id} 
                    et_uid={info.et_uid}
                />
                <TaskDel 
                    et_id={props.et_id} 
                    et_uid={info.et_uid}
                />
                <TaskMessageAdd  
                    et_id={props.et_id}
                    et_uid={info.et_uid}
                    etu_user_id={props.etu_user_id} 
                />                
            </Space>,
            span:3
        },
        {
            key:"3",
            label: <div style={{minWidth:"60px"}}>任务内容</div>,
            children: <div  dangerouslySetInnerHTML={{__html:info.et_content}}></div>,
            span:3
        }, 
        {
            key:"4",
            label: '发布人',
            children: <>{info.et_uname + info.et_ctime}</>,
        },
        {
            key:"5",
            label: '参与人',
            children: info.etu_user_name,            
        },
        {
            key:"6",
            label: '状态',
            children: info.et_status_name,            
        },               
    ]

    return (
        <>
            <a onClick={() => {
                setInfo(prevState=>({...prevState,eie_id:props.eie_id}))
                setOpen(true)}}>{props.name}</a>
            <Modal
                open={open}
                title='任务详情'
                okText="提交"
                cancelText="取消"
                width={1190}
                onCancel={() => {setOpen(false);}}                        
                footer={null}
            >
                <Descriptions                                  
                    items={descriptions} 
                    size='small'
                />                
                <TaskMessageList et_id={props.et_id} open={open} et_status={info.et_status} et_uid={info.et_uid} etu_user_id={props.etu_user_id}/>                
            </Modal>
            
        </>
    )
}

export default TaskCheck