import React,{useState,useEffect} from 'react'
import Main from '../../cpn/Main'
import { Table,  Button,  Space,Modal,message} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDestinationDelMutation, useDestinationListQuery } from '../../store/ReactWebSiteApi';

function Destination() {
  //获取省数据
  const [infoList, setInfoList] = useState();
  //API
  const { data, isSuccess } = useDestinationListQuery();
  useEffect(() => {
    if (isSuccess) {
      if (data.Status === 'success') {
        const newArr = data.Result.map((item) => {
          const { d_id:key,d_type_id,d_name,d_abbreviation,d_uid,d_uname,d_ctime} = item
          return item = { key,d_type_id,d_name,d_abbreviation,d_uid,d_uname,d_ctime }
        })
        setInfoList(newArr);
      }
      if (data.Status === 'fail') {
        setInfoList([])
      }
    }
  },[data,isSuccess])
  //设置链接
  const navi = useNavigate();  
  //删除数据
  const [destinationDel] = useDestinationDelMutation();
  const { confirm } = Modal;
  //表头
  const columns = [
    {
      title: 'ID',
      dataIndex: 'key',
      width: '3%',
    },        
    {
      title: '国内/国外',
      dataIndex: 'd_type_id',
      width: '15%',
      render: (text) => {
        if (text === '1') {
          return<>国内</>
        } else {
          return<>国外</>
        }
      }
    },
    {
      title: '中文名称',
      dataIndex: 'd_name',
      width: '15%',
    },
    {
      title: '拼音',
      dataIndex: 'd_abbreviation',
      width:'15%'
    },       
    {
      title: '时间',
      dataIndex: 'd_ctime',
      width: '15%',
      render: (text, record) => {
        return <>
          {text + record.d_uname}
        </>
      }
    }, 
    {
      title: '操作',
      width: '15%',
      render: (record) => (
        <Space size="middle">
          <a onClick={() => {
            navi(`/DestinationEdit?id=${record.key}`)   
          }}>修改</a>
          <a onClick={() => {
            confirm({
                title: '确定删除目的地吗?',
                content: '删除后不能恢复',
                okText: '确认',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                  destinationDel({d_id:record.key}).then((res) => {
                      if (res.data.Status === 'success') {
                        message.info('删除成功！');
                      }
                      if (res.data.Status === "fail") {
                          message.error('删除失败！'+res.data.Message)
                      }
                  })
                },
                onCancel() {
                  console.log('Cancel');
                },
              });
        }}>删除</a>
        </Space>
      )
    }
  ];
    
  //列表分页
  const onChange = (pagination, filters, sorter, extra) => {
      console.log('params', pagination, filters, sorter, extra);
  };
  
  return (
    <Main title1='地区' title2='地标'>
      <div><Button href='/DestinationAdd' type='primary'>新增目的地</Button></div>
      <Table
        columns={columns}
        dataSource={infoList}
        onChange={onChange}
        style={{marginTop:'30px'}}
        pagination={{ showTotal: total => `共${infoList.length}条数据`, showSizeChange: true, showQuickJumper: true }} />
    </Main>
    )
}
export default Destination