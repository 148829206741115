import React from 'react'
import Main from '../cpn/Main'
import Course from '../cpn/Course'

function SetCourse() {
    return (
        <Main t1='教程'>
            <Course esv_id='19'/>
        </Main>
    )
}

export default SetCourse