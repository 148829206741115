import React,{useEffect, useState}from 'react'
import { Space, TreeSelect,} from 'antd';
import Main from '../../cpn/Main';
import { useShangpinTypeListQuery } from '../../store/ReactWebSiteApi';
import {  } from 'react-router-dom';
import CommodityTypeAdd from './CommodityTypeAdd';
import CommodityTypeEdit from './CommodityTypeEdit';

function CommodityType() {
    const [list,setList] = useState()    
    const [info,setInfo] = useState({spt_id:'',spt_parent_id:''})
    const {data,isSuccess} = useShangpinTypeListQuery()
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                setList([data.Result])
            }
        }
    },[data,isSuccess])
    return (
        <Main t1='商城' n1='/GfIndexMall' t2='商品' n2='/CommodityIndex' t3='商品类型'>
            <Space>
                <TreeSelect
                    showSearch
                    style={{
                        width: '300px',
                    }}
                    dropdownStyle={{
                        maxHeight: 400,
                        overflow: 'auto',
                    }}
                    placeholder="请选择商品类型"
                    allowClear
                    treeDefaultExpandAll
                    onChange={(e) => {
                        console.log(e);
                        setInfo(prevState=>({...prevState,spt_parent_id:e,spt_id:e,}))
                    }}
                    treeData={list}
                />
                {info.spt_parent_id && <CommodityTypeAdd spt_parent_id={info.spt_parent_id}/>}
                <CommodityTypeEdit spt_id={info.spt_id} />
            </Space>
        </Main>
    )
}

export default CommodityType