import React,{useState,useEffect,useRef}from 'react'
import { Modal,message,Form,Checkbox, Input,Space} from 'antd';
import {  useAppStationUpdateQuery, useAppStationUpdateSaveMutation, useGetEntAppMenuOperationQuery,  } from '../store/ReactWebSiteApi';
function SetPostEdit(props) {
    const [form] = Form.useForm(); 
    //获取企业应用权限
    const {data:dataApp,isSuccess:isSuccessApp} = useGetEntAppMenuOperationQuery({esv_type_id:localStorage.getItem('e_id')==='218'?'1,3':'1,5'})
    //修改
    const [infoApp,setInfoApp] = useState()
    useEffect(() => {
        if(isSuccessApp){
            if(dataApp.Status==='success'){
                console.log(dataApp);                
                const arrEdit = dataApp.Result.map((item) => {
                    const {esv_id,esv_name,menu,esv_image2_name} = item; 
                    let menuCheckbox                   
                    if(menu){
                        menuCheckbox = menu.map((item) => {
                            const {esvm_id,esvm_name} = item
                            return <Checkbox value={esvm_id} key={esvm_id}>{esvm_name}</Checkbox>
                        })
                    }                    
                    return  <Form.Item 
                                label={<Space><img src={`/api/assets/images/application/${esv_image2_name}`} alt='' width={30} />{esv_name}</Space>} 
                                name={'edit'+esv_id}  
                                wrapperCol={{span:15}} 
                                key={esv_id} 
                                esv_id={esv_id}
                            >
                                <Checkbox.Group onChange={(e) => {setInfo(prevState=>({...prevState,ess_menu_id:e}))}}>
                                    {menuCheckbox}
                                </Checkbox.Group>
                            </Form.Item>                            
                    
                })
                // 删除系统管理和我的
                let idsToRemove = ['8','19'];                
                const array = arrEdit.filter(item => !idsToRemove.includes(item.props.esv_id));
            
                const sortArrEdit = array.sort( (a, b) => {      
                    let aTime = a.props.esv_id;
                    let bTime = b.props.esv_id;
                    return aTime - bTime;     
                    },)
                setInfoApp(sortArrEdit)
            }
        }
    },[dataApp, isSuccessApp]) 
    //修改岗位-----------------------------------------------------------------------------------------------------------------------------修改岗位
    const [info,setInfo] = useState();
    const [openEdit, setOpenEdit] = useState(false);
    const {data:dataLimit,isSuccess:isSuccessLimit} = useAppStationUpdateQuery({ess_id:props.ess_id},{refetchOnMountOrArgChange:1,skip:!openEdit})
    useEffect(() => {
        if(isSuccessLimit){
            if(dataLimit.Status==='success'){
                console.log(dataLimit,'dataLimit');
                let newObj = {}
                for (let i = 0; i < dataLimit.Result.station.length; i++) {
                    let name = 'edit'+ dataLimit.Result.station[i].esv_id
                    newObj[name] =  dataLimit.Result.station[i].esvm
                }
                newObj['ess_name'] = dataLimit.Result.ess_name
                console.log(newObj);
                editForm.current.setFieldsValue(newObj)
                setInfo(prevState=>({...prevState,
                    ess_name:dataLimit.Result.ess_name,
                    ess_menu_id:dataLimit.Result.ess_menu_id,
                }))
            }            
        }
    },[dataLimit,isSuccessLimit])    
    const editForm = useRef()
    const [submitUpdateSave] = useAppStationUpdateSaveMutation()    
    return (
        <>
            <a onClick={() => {setOpenEdit(true)}}>修改</a>
            <Modal
                open={openEdit}
                width={800}
                title='修改岗位'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenEdit(false);}}
                onOk={() => {
                    let menuArr = [];
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            for (let key in values) {                                        
                                if (key.includes("edit") && values[key]) { // 确保属性是对象自身的，而不是从原型链继承的
                                    for (let i = 0; i < values[key].length; i++) {
                                        menuArr.push(values[key][i])    
                                    }                                            
                                    console.log('menuArr',menuArr);
                                }
                            }                                    
                            console.log(values);
                            submitUpdateSave({
                                ess_e_id:localStorage.getItem('e_id'),
                                ess_name:info.ess_name,
                                ess_menu_id:menuArr,
                                ess_update_uid:localStorage.getItem('eu_id'),
                                ess_update_uname:localStorage.getItem('eu_name'),
                                ess_id:props.ess_id,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })     
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    labelCol={{ span: 5 }}
                    ref={editForm}
                >
                    <Form.Item name="ess_name" label='岗位名称' wrapperCol={{span:15}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,ess_name:e.target.value}))}}/>
                    </Form.Item>
                    {infoApp}
                </Form>
            </Modal>
        </>
    )
}

export default SetPostEdit