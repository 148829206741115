import React,{useState,useEffect} from 'react'
import { Table,DatePicker,Space, Button, Modal, Flex, Descriptions} from 'antd'
import {useWlGoodsProfitQuery,} from '../../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import Main from '../../cpn/Main';
import { indexFinance } from '../../finance/balance/balanceType';
const {RangePicker} = DatePicker
function ArapSumGoods() {
    const [open,setOpen] = useState(false)
    const [query,setQuery] = useState({
        s_date:dayjs().startOf('month').format('YYYY-MM-DD'),
        e_date:dayjs().endOf('month').format('YYYY-MM-DD'),
        e_id:localStorage.getItem('e_id'),
        eie_type_id:'1,2,3'
    })    
    const {data,isSuccess} = useWlGoodsProfitQuery({
        e_id:localStorage.getItem('e_id'),
        eie_type_id:'1,2,3',
        s_date:query.s_date,
        e_date:query.e_date,
    },{refetchOnMountOrArgChange:1,skip:!open})    
    const [list, setList] = useState();    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){    
                const newArr = data.Result.map(item => {
                    const {eie_eg_id:key,eg_name,type1,type2,type3} = item;
                    return item = {key,eg_name,type1,type2,type3}
                })                
                setList(newArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])        
    const columns = [
        {
            key: 'id',
            title: '产品',
            dataIndex: 'eg_name'
        },         
        {
            key: 'type1',
            title: '团队收入单已下账金额之和',
            dataIndex: 'type1'
        },         
        {
            key: 'type2',
            title: '团队支出单已下账金额之和',
            dataIndex: 'type2'
        },         
        {
            key: 'type3',
            title: '费用支出单已下账金额之和',
            dataIndex: 'type3'
        },         
        {
            key: 'ar',
            title: '利润',
            render:(_,record) => {
                return<>{+record.type1 + +record.type2 + +record.type3}</>
            }
        },                                  
    ]
    const items = [        
        {
            key:'2',
            label:'下账日期',
            children:<>{query.s_date}至{query.e_date}</>,
        },
        {
            key:'3',
            label:'制表人',
            children:<>{localStorage.getItem('ed_name').replace(/"/g,'')} / {localStorage.getItem('eu_name').replace(/"/g,'')}</>
        },
        {
            key:'4',
            label:'制表时间',
            children:<>{dayjs().format('YYYY-MM-DD HH:MM:ss')}</>
        },
    ]
    return (
        <Main t1='财务' n1={indexFinance} t2='下账' n2='/ArapIndex' t3='报表' n3='/ArapTable' t4='产品利润表'>
            <div style={{marginBottom:'30px'}}>本表说明：按下账日期，统计每个产品的团队收入单、团队支出单和费用支出单已下账金额之和、已下账利润。</div>
            <Space style={{marginBottom:"10px"}}>
                <span>下账日期：</span>
                <RangePicker 
                    defaultValue={[dayjs().startOf('month'),dayjs().endOf('month')]} 
                    onChange={(e) => {
                        if(e){
                            setQuery(prevState=>({...prevState,s_date:dayjs(e[0]).format('YYYY-MM-DD'),e_date:dayjs(e[1]).format('YYYY-MM-DD')})) 
                        }else{
                            setQuery(prevState=>({...prevState,s_date:'',date_e:''}))
                        }
                    }}
                />
                <Button type='primary' onClick={() => {setOpen(true)}}>查询</Button>                
            </Space>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false)}}
                width={1200}
                footer={null}
            >            
                <Flex justify='center'>
                    <h2>产品利润表</h2>
                </Flex>
                <Descriptions items={items} bordered/>
                <Table 
                    columns={columns} 
                    dataSource={list} 
                    style={{marginTop:'10px'}}
                    summary={(pageData) => {
                        console.log(pageData);
                        let totaltype1 = 0;
                        let totaltype2 = 0;
                        let totaltype3 = 0;
                        pageData.forEach(({type1,type2,type3 }) => {                        
                            totaltype1 += +type1;
                            totaltype2 += +type2;
                            totaltype3 += +type3;
                        });
                        return <Table.Summary>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>{(totaltype1)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}>{(totaltype2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}>{(totaltype3)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}>{(totaltype1 + totaltype2 + totaltype3)}</Table.Summary.Cell>                                    
                                    </Table.Summary.Row>
                                </Table.Summary>
                    }}
                />
            </Modal>
        </Main>
    )
}

export default ArapSumGoods