import React from 'react'
import Main from '../../cpn/Main'
import { Row} from 'antd';
import Folder from '../../cpn/Folder';
import { indexFinance } from '../balance/balanceType';

function FundIndex() {
    return (
        <Main t1='财务' n1={indexFinance} t2='转账'>            
            <Row>                
                <Folder title='新增转账凭证'  link='/FundAdd' />            
                <Folder title='转账凭证'  link='/FundList' />             
                <Folder title='报表'  link='/FundTable' />         
            </Row>
        </Main>
    )
}

export default FundIndex