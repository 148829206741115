import React,{ useState,}from 'react'
import { Modal,message,Form,Input,Button,Upload, InputNumber,} from 'antd';
import MyEditor from '../../cpn/MyEditor'
import { useGwGoodsAddMutation,} from '../../store/ReactWebSiteApi';
import GetType from './GetType';
import { UploadOutlined} from '@ant-design/icons';
function WebGoodsAdd() {
    const [form] = Form.useForm();   
    const [open, setOpen] = useState(false);
    //上传图片
    const handleChange = (e) => {
        if (e.file.status === 'done'||e.file.status==='removed') {
            const fileArr = e.fileList.map((item) => {
                const { response } = item
                return response.Result
            })
            // console.log(e);
            let fileStr = fileArr + "";  
            if(fileStr === null){
                fileStr = ''
            }
            const nameArr = e.fileList.map((item) => {
                const { name} = item
                return name
            })
            let nameStr = nameArr + ""
            setInfo(prevState=>({...prevState,eowg_image_file:fileStr,eowg_image_name:nameStr}))
        }
    }
    const onCreate = (values) => {
        
        submitAdd({
                    eowg_name:info.eowg_name,
                    eowg_e_id:localStorage.getItem('e_id'),
                    eowg_type_id:info.eowg_type_id, 
                    eowg_sub_type_id:info.eowg_sub_type_id,
                    eowg_uid:localStorage.getItem('eu_id'),
                    eowg_uname:localStorage.getItem('eu_name'),
                    eowg_sub_name:'',
                    eowg_content:info.eowg_content,
                    eowg_image_name:info.eowg_image_name,
                    eowg_image_file:info.eowg_image_file,
                    eowg_serial_number:info.eowg_serial_number,
                }).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('新增成功！')  
                        setOpen(false)                         
                    }
                    if (res.data.Status === 'fail') {
                        message.error('新增失败！'+res.data.Message)
                    }
                })
        setOpen(false);
    };
    const [info,setInfo] = useState({
        eowg_content:'',
        eowg_sub_type_id:[],
        eowg_sub_type_name:[],
        eowg_type_id:[],
        eowg_image_name:'',
        eowg_image_file:'',
        eowg_sub_name:'',
    });
    const [submitAdd] = useGwGoodsAddMutation()    
   
    return (
        <>
            <a onClick={() => {setOpen(true)}}>新增</a>
            <Modal
                open={open}
                title='新增教程'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                >                           
                    <GetType 
                        label='类型' 
                        name='type'
                        subname='subType' 
                        required
                        getType={(e,f) => {
                            setInfo(prevState=>({...prevState,eowg_type_id:e,eowg_sub_type_id:f}))
                        }}
                    />
                    <Form.Item name="serial" label='序号' wrapperCol={{span:10}} required>
                        <InputNumber  onChange={(e) => {setInfo(prevState=>({...prevState,eowg_serial_number:e}))}}/>
                    </Form.Item>
                    <Form.Item name="name" label='标题' wrapperCol={{span:20}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,eowg_name:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item name="desc" label='正文' wrapperCol={{span:20}}>
                        <MyEditor  onChange={(e) => {setInfo(prevState=>({...prevState,eowg_content:e}));}} value={info.eowg_content} />                               
                    </Form.Item>
                    <Form.Item label="图片"                         
                        valuePropName='fileList'
                        getValueFromEvent={e => {
                            if (Array.isArray(e)) {
                                return e;
                            }
                            return e && e.fileList;
                        }}
                    >
                        <Upload
                            name="image_file"
                            action="/api/ReactApi/zd_single_upload_image"
                            listType="picture"
                            className="upload-list-inline"
                            onChange={handleChange}
                            >
                            <Button icon={<UploadOutlined />}>点击上传</Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default WebGoodsAdd