import React from 'react'
import Main from '../cpn/Main'
import {Row} from 'antd';
import Folder from '../cpn/Folder';
function SetIndex() {
    return (
        <Main t1='系统设置'>
            <Row>
                <Folder title='部门和用户' link='/SetDept'width={42}/>
                <Folder title='岗位'  link='/SetRole'  width={42}/>                            
                <Folder title='订阅'  link='/SetSubscription' width={42}/>                            
            </Row>                     
        </Main>
    )
}

export default SetIndex