import React,{useState,useEffect,useRef}from 'react'
import { Table,Space,Modal,message,Form,Input,Button} from 'antd';
import { useGoodsGuigeAddMutation, useGoodsGuigeDelMutation, useGoodsGuigeListQuery, useGoodsGuigeUpdateQuery, useGoodsGuigeUpdateSaveMutation,} from '../../store/ReactWebSiteApi';
function SetGoodsSpec(props) {   
    const [openWhole,setOpenWhole] = useState(false);
    //新增规格----------------------------------------------------------------------------------------------------------------------------------------新增规格    
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateAdd = (values) => {
        console.log('Received values of form: ', values);
        systemAdd(infoAdd).then((res) => {
            if (res.data.Status === 'success') {
                message.info('新增成功！');
                setOpenAdd(false);
            }
            if (res.data.Status === "fail") {
                message.error('新增失败！'+res.data.Message)
            }
        })
        
    };
    const [infoAdd,setInfoAdd] = useState({
        egg_eg_id:props.egg_eg_id,
        egg_uid:localStorage.getItem('eu_id'),
        egg_e_id:localStorage.getItem('e_id'),
        egg_uname:localStorage.getItem('eu_name'),
        
    });
    const [systemAdd] = useGoodsGuigeAddMutation()
    const  modalAdd =   <Modal
                            width={1000}
                            open={openAdd}
                            title='新增规格'
                            okText="提交"
                            cancelText="取消"
                            onCancel={() => {setOpenAdd(false);}}
                            onOk={() => {
                                form
                                    .validateFields()
                                    .then((values) => {
                                        form.resetFields();
                                        onCreateAdd(values);
                                    })
                                    .catch((info) => {
                                        console.log('Validate Failed:', info);
                                    });
                                
                            }}
                        >
                            <Form
                                form={form}
                                layout="horizonal"
                                name="form_client"
                                initialValues={{modifier: 'public',}}
                                labelCol={{ span: 5 }}
                            >
                                <Form.Item name="name" label='规格名称' wrapperCol={{span:10}} required>
                                    <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,egg_name:e.target.value}))}}/>
                                </Form.Item>
                            </Form>
                        </Modal>
    //修改规格----------------------------------------------------------------------------------------------------------------------------------------修改规格
    const [openEdit, setOpenEdit] = useState(false);
    const [goodsId,setGoodsId] = useState()
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    const [editGoods,setEditGoods] = useState({egg_remark:''});
    
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useGoodsGuigeUpdateQuery({egg_id:goodsId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);                
                editForm.current.setFieldsValue({
                    egg_name:dataEdit.Result.egg_name,
                })
                setEditGoods(prevState=>({...prevState,
                    egg_name:dataEdit.Result.egg_name,                  
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [goodsEdit] = useGoodsGuigeUpdateSaveMutation()
    const modalEdit =   <Modal
                            width={1000}                        
                            open={openEdit}
                            title='修改规格'
                            okText="提交"
                            cancelText="取消"
                            onCancel={() => {setOpenEdit(false);}}
                            onOk={() => {
                                form
                                    .validateFields()
                                    .then((values) => {
                                        form.resetFields();
                                        onCreateEdit(values);
                                    })
                                    .catch((info) => {
                                        console.log('Validate Failed:', info);
                                    });
                                goodsEdit({
                                    egg_name:editGoods.egg_name,
                                    egg_eg_id:props.egg_eg_id,
                                    egg_update_uid:localStorage.getItem('eu_id'),
                                    egg_e_id:localStorage.getItem('e_id'),
                                    egg_update_uname:localStorage.getItem('eu_name'),
                                    egg_id:goodsId,                                   
                                }).then((res) => {
                                    console.log(res);
                                    if (res.data.Status === 'success') {
                                        message.success('修改成功！')   
                                        setOpenEdit(false)                             
                                    }
                                    if (res.data.Status === 'fail') {
                                        message.error('修改失败！'+res.data.Message)
                                    }
                                })
                            }}
                        >
                            <Form
                                form={form}
                                layout="horizonal"
                                name="form_client_edit"
                                initialValues={{modifier: 'public',}}
                                labelCol={{ span: 5 }}
                                ref={editForm}
                            >
                               
                                <Form.Item name="egg_name" label='规格名称' wrapperCol={{span:10}} required>
                                    <Input  onChange={(e) => {setEditGoods(prevState=>({...prevState,egg_name:e.target.value}))}}/>
                                </Form.Item>                                
                            </Form>
                        </Modal> 
    //删除规格----------------------------------------------------------------------------------------------------------------------------------------删除规格//
    const {confirm} = Modal; 
    // const [delBusiness] = useDelSystemMutation()
    //删除规格----------------------------------------------------------------------------------------------------------------------------------------删除规格//
    // const [systemState] = useSystemStateMutation()
    //规格列表----------------------------------------------------------------------------------------------------------------------------------------规格列表//
    
    const {data, isSuccess} = useGoodsGuigeListQuery({egg_e_id:localStorage.getItem('e_id'),egg_eg_id:props.egg_eg_id},{skip:!openWhole});
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if (isSuccess) {
            console.log(data);
            if(data.Status==='success'){
                
                const newArr = data.Result.map(item => {
                    const {egg_id:key,egg_name,} = item;
                    return item = {key,egg_name,}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.egg_ctime;
                    let bTimeString = b.egg_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess]) 
    //删除
    const [delBusiness] = useGoodsGuigeDelMutation()
    const columns = [        
        {
            key: 'type',
            title: '规格名称',            
            dataIndex: 'egg_name',
        },
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
       
        {
            key: 'action',
            title:'操作',
            render: (_, record) => {
                return <>
                    <Space>                            
                        <a onClick={() => {setGoodsId(record.key);setOpenEdit(true)}}>修改</a>
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    delBusiness({egg_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>
                    </Space>
                </>
            }
        },
    ]
   
 
    return (
        <>
            <a onClick={() => {setOpenWhole(true)}}>规格</a>
            <Modal
                open={openWhole}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenWhole(false);}}
                onOk={() => {}}
                footer={null}
                width={800}
                title={`设置${props.goodsName}`}
            >
                 <Space>
                    <Button type='primary' onClick={() => {setOpenAdd(true);}} style={{marginBottom:'10px'}}>新增</Button>
                </Space>
                {modalAdd}
                {modalEdit}            
                <Table columns={columns} dataSource={list} pagination={{position:['none']}}></Table>
            </Modal>
           
        </>
    )
}

export default SetGoodsSpec