import React,{useState,useEffect} from 'react'
import { useAppSynthesisListQuery,} from '../store/ReactWebSiteApi';
import { Table,Space } from 'antd';
import CourseDesc from './CourseDesc';
import {QuestionCircleOutlined,QuestionCircleFilled,EyeOutlined} from '@ant-design/icons';
function Course(props) {
    
    const {data, isSuccess} = useAppSynthesisListQuery({esvs_esv_id:props.esv_id,eu_id:localStorage.getItem('eu_id')});
    
    const [list, setList] = useState([]);
    
    useEffect(() => {
        if (isSuccess) {
            console.log(data);
            if(data.Status==='success'){
                
                const newArr = data.Result.map(item => {
                    const {esvs_id:key,esvs_name,esvs_type_id,esvs_content,esvs_uname,esvs_ctime,esvs_image2_name,esvs_image_name,read_num,read_time} = item;
                    return item = {key,esvs_name,esvs_type_id,esvs_content,esvs_uname,esvs_ctime,esvs_image2_name,esvs_image_name,read_num,read_time}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.esvs_ctime;
                    let bTimeString = b.esvs_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList([])
            }
            
        }
    }, [data, isSuccess])  
    
    const columns = [
        
        {
            key: '1',
            title: '教程名称',
            dataIndex: 'esvs_name',
            render:(_,record) => {
                return <Space direction='vertical'>
                            <Space>
                                {record.read_time?<QuestionCircleOutlined />:<QuestionCircleFilled />}
                                <CourseDesc 
                                    esvs_id={record.key} 
                                    esvs_name={record.esvs_name} 
                                    esvs_content={record.esvs_content}
                                />
                            </Space>
                            <Space>
                                <EyeOutlined />{record.read_num}
                            </Space>
                        </Space>
            }
        },         
        
    ]
    return (
        <Table 
            dataSource={list}
            columns={columns}
        ></Table>
    )
}

export default Course