import { Select,Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAppStationListQuery } from '../store/ReactWebSiteApi';

function GetPost(props) {
    const [list,setList] = useState();
    const {data, isSuccess} = useAppStationListQuery({ess_e_id:localStorage.getItem('e_id')});
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                const newArr = data.Result.map((item) => {
                    const {ess_id,ess_name,}=item;
                    return item = {value:ess_id,label:ess_name}
                })
                setList(newArr)
            }
        }
    },[data,isSuccess])
    return (
        <>
            <Form.Item 
                label={props.label} 
                wrapperCol={props.wrapperCol} 
                required={props.required} 
                name={props.name}           
            >
                <Select 
                    options={list} 
                    onChange={(_,f) => {
                        // console.log(f);
                        props.getPost(f.value,f.label)
                    }}
                    placeholder={props.placeholder}
                    style={props.style}
                />
            </Form.Item>
        </>
    )
}

export default GetPost