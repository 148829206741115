import React, { useEffect, useState } from 'react'
import { message, Space, Table } from 'antd'
import CustomGoodsAdd from './CustomGoodsAdd'
import { useShangpinDelMutation, useShangpinListQuery } from '../../../../store/ReactWebSiteApi'
import CustomGoodsEdit from './CustomGoodsEdit'
import ModalDel from '../../../../cpn/ModalDel'
import CustomGoodsAudit from './CustomGoodsAudit'
import CustomGoodsCheckOrgan from './CustomGoodsCheckOrgan'

function CustomGoods(props) {
    const [list,setList] = useState()   
    const {data, isSuccess} = useShangpinListQuery({sp_e_id:localStorage.getItem('e_id'),sp_spt1_id:props.type},{refetchOnMountOrArgChange:1});
    useEffect(() => {
        if (isSuccess) {
            // console.log(data);
            if(data.Status==='success'){                
                const newArr = data.Result.map(item => {
                    const {sp_id:key,sp_name,sp_spt1_id,sp_spt1_name,sp_spt2_name,sp_spt3_name,sp_spt4_name,sp_dept_name,sp_country_name,sp_province_name,sp_city_name,sp_ctime,sp_state} = item;
                     return item = {key,sp_name,sp_spt1_id,sp_spt1_name,sp_spt2_name,sp_spt3_name,sp_spt4_name,sp_dept_name,sp_country_name,sp_province_name,sp_city_name,sp_ctime,sp_state}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.sp_ctime;
                    let bTimeString = b.sp_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }            
        }
        
    }, [data, isSuccess])
    //删除
    const [del] = useShangpinDelMutation()
    const column = [
        {
            key:'1',
            title:'商品名称',
            dataIndex:'sp_name',
            render:(_,record) => {
                if(record.sp_spt1_id==='1'||record.sp_spt1_id==='3'){
                    return <CustomGoodsCheckOrgan />
                }else{
                    return <CustomGoodsCheckOrgan />
                }
            }           
        },     
        {
            key:'2',
            title:'商品类型',
            dataIndex:'sp_spt1_name',            
        }, 
        {
            key:'4',
            title:'品类',
            render:(_,record) => {
                return <>{record.sp_spt2_name + ' - ' + record.sp_spt3_name + ' - ' + record.sp_spt4_name}</>
            }
        },  
        {
            key:'3',
            title:'出发城市',
            render:(_,record) => {
                return <>{record.sp_country_name + ' - ' + record.sp_province_name + ' - ' + record.sp_city_name}</>
            }
        }, 
            
        {
            key:'8',
            title:'部门',
            dataIndex:'sp_dept_name'
        },                       
        {
            key:'9',
            title:'编号',
            dataIndex:'key'
        },       
        {
            key:'10',
            title:'状态',
            dataIndex:'sp_state',
            render:(text) => {
                if(text==='2'){
                    return '待上架'
                }else{
                    return '已上架'
                }
            }
        },       
        {
            key:'11',
            title:'操作',
            render:(_,record) => {
                return <Space> 
                    <CustomGoodsEdit sp_id={record.key}/>
                    <ModalDel onOk={() => {
                        del({sp_id:record.key}).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                    <CustomGoodsAudit state={record.sp_state} sp_id={record.key} />
                    
                </Space>
            }
        },
    ]
    return (
        <>
            <Space>
                <CustomGoodsAdd type={props.type}/>
            </Space>
            <Table columns={column} dataSource={list} style={{marginTop:"10px"}}/>
        </>
    )
}

export default CustomGoods