import React,{useState}from 'react'
import { Button,Modal,message,Form, Input,} from 'antd';
import GetUser from '../cpn/GetUser';
import { useTestAddMutation,  } from '../store/ReactWebSiteApi';
const { TextArea } = Input;
function TrainTaskAdd(props) {
    const [form] = Form.useForm();
    const [open, setOpenAdd] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [info,setInfo] = useState()
    const getUser = (e) => {
        setInfo(prevState=>({...prevState,ewptr_user_id:e}))
    }
    const [submitAdd] = useTestAddMutation()
    return (
        <>
            <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增</Button>
            <Modal
                open={open}
                title='新增任务'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenAdd(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        ewptr_ewp_id:props.ewptr_ewp_id,
                        ewptr_user_id:info.ewptr_user_id,
                        ewptr_content:info.ewptr_content,
                        ewptr_uid:localStorage.getItem('eu_id'),
                        ewptr_uname:localStorage.getItem('eu_name'),
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            setOpenAdd(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                >
                    <GetUser label='被培训人' getUser={getUser}/>                            
                    <Form.Item labelCol={{ span: 5 }} label='备注' name='content'>
                        <TextArea rows={5} onChange={(e) => {setInfo(prevState=>({...prevState,ewptr_content:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default TrainTaskAdd