import React,{useState,useEffect} from 'react'
import Main from '../../cpn/Main'
import { useLandmarkAddMutation } from '../../store/ReactWebSiteApi'
import { message,Button, Form, Input, Row,  Col,Cascader,Radio,InputNumber} from 'antd'
import { useNavigate } from 'react-router-dom'
import { useAreaTreeQuery } from '../../store/ReactWebSiteApi'
function LandmarkAdd(props) {  
    const [infoAdd,setInfoAdd] = useState();
    //获取地区树
    const { data, isSuccess } = useAreaTreeQuery();
    //设置地区树
    const [areaTree, setAreaTree] = useState();
    //加载数据
    useEffect(() => {        
        if (isSuccess) {
            try {
                setAreaTree(data);                
            } catch (error) {
            }
        }
    }, [data, isSuccess])

    //链接
    const navigate = useNavigate();
    //上传数据
    const [LandmarkAdd] = useLandmarkAddMutation();
    //提交对话框表单
    const onFinish = () => {
        LandmarkAdd({
        al_province_id: infoAdd.area[0],
        al_city_id: infoAdd.area[1],
        al_district_id: infoAdd.area[2],
        al_type_id: infoAdd.al_type_id,
        al_name: infoAdd.al_name,
        al_uid: localStorage.getItem('userId'),
        al_uname: localStorage.getItem('userName'),
        al_longitude: infoAdd.al_longitude,
        al_latitude: infoAdd.al_latitude,
        }).then(
        (res) => {
            if (res.data.Status === 'success') {
            message.success('新增成功！')
            navigate('/Landmark');                                    
            }
            if (res.data.Status === 'fail') {
            message.error('新增失败！'+res.data.Message)
            }
        })
    }
    const onFinishFailed = () => {}
    return (
        <Main title1='系统' title2='地区' title3='地标' title4='新增地标'>
        <Row>
            <Col span={12}>
            <Form
                name="basic"
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 20,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="1、选择地区"
                    name="area"
                    rules={[{
                        required: true,
                        message: '请选择地区！',
                    },]}
                    wrapperCol={{span: 10,}}
                >
                <Cascader options={areaTree} onChange={(e) => {
                    setInfoAdd(prevState => ({ ...prevState, area: e }))
                }} placeholder="Please select" />
                </Form.Item>        
                <Form.Item
                    label="2、选择类型"
                    name="type"
                    rules={[{
                        required: true,
                        message: '请选择类型！',
                    },]}
                    wrapperCol={{ span: 10 }}
                >
                <Radio.Group onChange={(e) => {setInfoAdd(prevState=>({...prevState,al_type_id:e.target.value}))}}>
                    <Radio value={1}>飞机场</Radio>
                    <Radio value={2}>火车站</Radio>
                    <Radio value={3}>长途汽车站</Radio>
                    <Radio value={4}>码头</Radio>
                    <Radio value={5}>景区</Radio>
                    <Radio value={6}>购物中心</Radio>
                </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="3、填写名称"
                    name='name'              
                >
                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,al_name:e.target.value}))}} />
                </Form.Item>
                <Form.Item
                    label="4、位置经度"
                    name="longitude"
                    rules={[{required: true,message: '请填写经度!'}]}
                    extra={<a href='https://lbs.qq.com/tool/getpoint/get-point.html'rel="noreferrer" target='_blank'>坐标拾取器</a>}
                >
                    <InputNumber min="0"  max="360" step="0.001"  onChange={(e) => {setInfoAdd(prevState=>({...prevState,al_longitude:e}))}} stringMode/>
                </Form.Item>
                <Form.Item
                    label="5、位置纬度"
                    name="latitude"
                    rules={[{required: true,message: '请填写经度!',}]}
                >
                    <InputNumber min="0"  max="360" step="0.001"  onChange={(e) => {setInfoAdd(prevState=>({...prevState,al_latitude:e}))}} stringMode/>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 2,
                        span: 16,
                    }}
                >
                <Button type="primary" htmlType="submit">提交</Button>
                </Form.Item>
            </Form>          
            </Col>
        </Row>
    </Main>
  )
}
export default LandmarkAdd