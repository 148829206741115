import React from 'react'
import Main from '../../cpn/Main'
import { Row, } from 'antd';
import Folder from '../../cpn/Folder';
function AppIndex() {
    return (
        <Main t1='商城' n1='/GfIndexMall' t2='应用'>           
            <Row>                
                <Folder title='金鱼电脑版权限'  link='/AppPower?qx_type=1&name=金鱼电脑版权限' /> 
                <Folder title='旅当家电脑版权限'  link='/AppPower?qx_type=2&name=旅当家电脑版权限' />            
                <Folder title='旧版'  link='/AppList' />            
            </Row>
        </Main>
    )
}

export default AppIndex