import React,{useState,useEffect} from 'react'
import FirmOrderAdd from './FirmOrderAdd'
import { Space, Table } from 'antd'
import Main from '../../cpn/Main'
import { useSearchParams } from 'react-router-dom'
import { useEntPayGoodsListQuery } from '../../store/ReactWebSiteApi'
import FirmOrderEdit from './FirmOrderEdit'

function FirmOrder() {
    const [param] = useSearchParams();
    const {data,isSuccess} = useEntPayGoodsListQuery()    
    const [list, setList] = useState();    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                console.log(data);                
                const newArr = data.Result.map(item => {
                    const {epg_id:key,epg_date,e_name,egt_name,epg_content,eg_name,epg_ctime,epg_amount,epg_uname,epg_update_ctime,epg_update_uname} = item;
                    return item = {key,epg_date,e_name,egt_name,epg_content,eg_name,epg_ctime,epg_amount,epg_uname,epg_update_ctime,epg_update_uname}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.epg_ctime;
                    let bTimeString = b.epg_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])       
    const columns = [
              
        {
            key: 'e_name',
            title: '客户名称',
            dataIndex: 'e_name',
        },
        {
            key: 'egt_name',
            title: '产品类型',
            dataIndex: 'egt_name',
        },
        {
            key: 'eg_name',
            title: '产品名称',
            dataIndex: 'eg_name',
        },
        {
            key: 'epg_amount',
            title: '金额',
            dataIndex: 'epg_amount',
        },
        {
            key: 'epg_date',
            title: '截止日期',
            dataIndex: 'epg_date',
        },
        {
            key: 'epg_content',
            title: '备注',
            dataIndex: 'epg_content',
        },
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        }, 
        {
            key: 'time',
            title: '操作员',
            render: (_,record) => {
                return <>
                    <div>{record.epg_ctime + record.epg_uname}新建</div>
                    {record.epg_update_ctime && <div>{record.epg_update_ctime + record.epg_update_uname}修改</div>}
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space>                        
                        <FirmOrderEdit epg_id={record.key}/>
                    </Space>
                </>
            }
        },
    ]
    return (
        <Main  t1='商城' n1='/GfIndexMall' t2='企业' n2='/FirmIndex' t3='订单'>
            <Space style={{marginBottom:"10px"}}>
                <FirmOrderAdd epg_e_id={param.get('id')} />                
            </Space>
            <Table dataSource={list} columns={columns}/>
        </Main>
    )
}

export default FirmOrder