import React,{useState,useEffect}from 'react'
import Main from '../../cpn/Main'
import { Table,Space,} from 'antd';
import { useEntJyAppListQuery} from '../../store/ReactWebSiteApi';
import { useSearchParams } from 'react-router-dom';
function FirmApp() {
    const [param] = useSearchParams();
    //应用列表----------------------------------------------------------------------------------------------------------------应用列表//
    
    const {data, isSuccess} = useEntJyAppListQuery({e_id:param.get('id')});
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if (isSuccess) {
            //console.log(data);
            if(data.Status==='success'){
                
                const newArr = data.Result.map(item => {
                    const {esv_id:key,esv_name,esv_num,esv_content,esv_uname,esv_ctime,esv_image2_name,esv_state} = item;
                    return item = {key,esv_name,esv_num,esv_content,esv_uname,esv_ctime,esv_image2_name,esv_state}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.esv_ctime;
                    let bTimeString = b.esv_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'name',
            title: '应用',
            dataIndex:'esv_name',
            render:(text,record) => {
                return <Space><img src={`/api/assets/images/application/${record.esv_image2_name}`} alt='' width='60'/> {text} </Space>
            }
        },            
       
    ]

      
    return (
        <Main t1='企业管理' t2={param.get('name')} t3='应用管理' topActive='BnsFirm' app='goldfish'>            
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table>
        </Main>
    )
}

export default FirmApp