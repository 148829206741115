import React,{useState,useEffect}from 'react'
import Main from '../cpn/Main'
import { Table,Space,} from 'antd';
import { usePhotoAlbumListQuery, } from '../store/ReactWebSiteApi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AlbumAdd from './AlbumAdd';
import AlbumDel from './AlbumDel';
import AlbumEdit from './AlbumEdit';
function AlbumGroupDetail() {
    const [param] = useSearchParams()
    
    const {data,isSuccess} = usePhotoAlbumListQuery({epa_e_id:localStorage.getItem('e_id'),epau_eu_id:localStorage.getItem('eu_id'),epa_ebn_id:param.get('id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                // //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {epa_id:key,epa_group_name,epa_s_date,epa_e_date,epa_zb_company,epa_xb_company,epa_name,epa_content,epa_uname,epa_ctime,epa_update_ctime,epa_update_uname,brower_num,click_num,image_num,image_size} = item;
                    return item = {key,epa_group_name,epa_s_date,epa_e_date,epa_zb_company,epa_xb_company,epa_name,epa_content,epa_uname,epa_ctime,epa_update_ctime,epa_update_uname,brower_num,click_num,image_num,image_size}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.epa_ctime;
                    let bTimeString = b.epa_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])  
    const navi = useNavigate();    
    const columns = [
        {
            key: 'type',
            title: '相册名称',
            dataIndex: 'epa_name',
        },                                 
        {
            key: 'epa_group_name',
            title: '分组名称',
            dataIndex: 'epa_group_name',
        },                                 
        {
            key: 'epa_s_date',
            title: '起止日期',
            render:(_,record) => {
                return <>{record.epa_s_date}至{record.epa_e_date}</>
            }
        },                                 
        {
            key: 'epa_zb_company',
            title: '主办单位',
            dataIndex: 'epa_zb_company',
        },                                 
        {
            key: 'epa_xb_company',
            title: '协办单位',
            dataIndex: 'epa_xb_company',
        },                                 
        {
            key: 'image_num',
            title: '图片数量',
            dataIndex:'image_num',
        },                                          
        {
            key: 'image_size',
            title: '占用空间',
            dataIndex:'image_size',
        },
        {
            key: 'brower_num',
            title: '浏览',
            dataIndex:'brower_num',
        },                                          
        {
            key: 'click_num',
            title: '点赞',
            dataIndex:'click_num',
        },                                                
        // {
        //     key: 'time',
        //     title: '操作记录',
        //     render: (_,record) => {
        //         return <>
        //             <div>新建时间：{record.epa_ctime + record.epa_uname}</div>
        //             <div>修改时间：{record.epa_update_ctime + record.epa_update_uname}</div>
        //         </>
        //     }
        // },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space size='middle'>                        
                        <AlbumEdit  epa_id={record.key} />
                        <AlbumDel epa_id={record.key} />
                        <a onClick={() => {navi(`/AlbumSection?esv_id=7&id=${record.key}&name=${record.epa_name}&ebn_team_num=${param.get('ebn_team_num')}`)}}>分组</a>                        
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main  t1='团队列表' n1='/AlbumGroup?esv_id=7' t2={`团号${param.get('ebn_team_num')}`} t3='相册列表'>
            <Space>
                <AlbumAdd epa_ebn_id={param.get('id')}/>
            </Space>
            <Table columns={columns} dataSource={list} style={{marginTop:'10px'}}></Table> 
          
        </Main>
    )
}

export default AlbumGroupDetail