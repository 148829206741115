export default function useHandle(initialState) {
    
    let result
    if(localStorage.getItem('eu_operate_id')){
        const arr = localStorage.getItem('eu_operate_id').split(",");
        result=arr.includes(initialState)        
    }else{
        result = false;  
    }
    return result
}
