import React, { useState } from 'react';
import { Image, } from 'antd';
const ImgPreviewCustom = (props) => {
    const [visible, setVisible] = useState(false);
    return (
        <>
            <a onClick={() => setVisible(true)}>{props.content}</a>
            <Image
                style={{
                    display: 'none',
                }}
                src={props.src}
                preview={{
                    visible,
                    src: props.src,
                    onVisibleChange: (value) => {
                        setVisible(value);
                    },
                }}
            />
        </>
    );
};
export default ImgPreviewCustom;