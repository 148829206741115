import React from 'react'
import Main from '../cpn/Main'
import Index from '../cpn/Index';
import { ScheduleOutlined} from '@ant-design/icons';
function EvaluateIndex() {
    const menu = [
        {
            router:'/EvaluateGroup',
            icon:<ScheduleOutlined />,
            name:'团队列表',
            key:'1'
        },     
      
    ]
    return (
        <Main>
            <Index  esv_id='25' menu={menu}/>           
        </Main>
    )
}

export default EvaluateIndex