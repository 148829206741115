import { Space, Table } from 'antd'
import React, { useState } from 'react'
import FeedBackAdd from './FeedBackAdd'
import FeedBackCheck from './FeedBackCheck'

function FeedBack() {
    const [list] = useState([
        {
            key:'1',
            lth_num:'1',
            driver:'司机张三，13972008078，30人'
        }
    ])
    const column = [             
        {
            key:'1',
            title:'意见单',
            dataIndex:'lth_num',  
            width:'200px',
            render:() => {
                return <FeedBackCheck 
                guideName='张三-13972008078'
            />
            }         
        },              
        {
            key:'2',
            title:'分车序号',    
            width:'100px',        
        },
        {
            key:'4',
            title:'二维码',            
        },        
        {
            key:'5',
            title:'操作',
            width:"120px",
            render:(_,record) => {
                return  <Space>
                            <a>修改</a>
                            <a>删除</a>
                        </Space>
            }
        },
    ]
    return (
        <>
            <div style={{marginLeft:'20px',marginTop:'10px'}}>
                <FeedBackAdd />
            </div>
            <Table 
                columns={column}
                dataSource={list}
                pagination={{position:['none']}}
            />
        </>
    )
}

export default FeedBack