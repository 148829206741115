import React, { useState,} from 'react'
import { Form,Modal,message,Input,DatePicker, Button,} from 'antd'
import { usePhotoAlbumAddMutation, } from '../store/ReactWebSiteApi'
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;
function AlbumAdd(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({
        epa_content:'',
        epa_name:props.name,
        epa_zb_company:'',
        epa_xb_company:'',
    });
    const [submitAdd] = usePhotoAlbumAddMutation()
    return (
        <>
            <Button onClick={() => {setOpen(true)}} type='primary'>新增</Button>
            <Modal
                open={open}
                title='新增相册'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        epa_e_id:localStorage.getItem('e_id'),
                        epa_name:info.epa_name,                                
                        epa_group_name:info.epa_group_name,                                
                        epa_zb_company:info.epa_zb_company,                                
                        epa_xb_company:info.epa_xb_company,                                
                        epa_s_date:info.epa_s_date,                                
                        epa_e_date:info.epa_e_date,                                
                        epa_uid:localStorage.getItem('eu_id'),
                        epa_uname:localStorage.getItem('eu_name'),
                        epa_ebn_id:props.epa_ebn_id,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                >                            
                    <Form.Item name="name" label='相册名称' wrapperCol={{span:19}} required initialValue={info.epa_name}>
                        <Input onChange={(e) => {setInfo(prevState=>({...prevState,epa_name:e.target.value}))}} />
                    </Form.Item>
                    <Form.Item name="group" label='分组名称' wrapperCol={{span:10}} required>
                        <Input onChange={(e) => {setInfo(prevState=>({...prevState,epa_group_name:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item name="date" label='起止日期' wrapperCol={{span:19}} required>
                        <RangePicker onChange={(e) => {setInfo(prevState=>({...prevState,epa_s_date:dayjs(e[0]).format('YYYY-MM-DD'),epa_e_date:dayjs(e[1]).format('YYYY-MM-DD')}))}} />
                    </Form.Item>
                    <Form.Item name="zb_company" label='主办单位' wrapperCol={{span:19}}>
                        <Input onChange={(e) => {setInfo(prevState=>({...prevState,epa_zb_company:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item name="xb_company" label='协办单位' wrapperCol={{span:19}}>
                        <Input onChange={(e) => {setInfo(prevState=>({...prevState,epa_zb_company:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default AlbumAdd