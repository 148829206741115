import React, { useEffect, useState }  from 'react'
import {Flex, Modal} from 'antd'
import {useSearchParams } from 'react-router-dom';
import LedgerGroupBalanceList from './LedgerGroupBalanceList';
import LedgerGroupInfo from './LedgerGroupInfo';
import { useBusinessNumUpdateQuery } from '../../store/ReactWebSiteApi';
import LedgerGroupBalanceAdd from './LedgerGroupBalanceAdd';

function LedgerGroupCheck(props) {
    const [param,] = useSearchParams();    
    const [open,setOpen] = useState(false)
    const [info,setInfo] = useState(
        {
            ebn_team_num:'',
        }
    )
    const {data,isSuccess} = useBusinessNumUpdateQuery({ebn_id:props.ebn_id},{refetchOnFocus:true,skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                let audit
                if(data.Result.ebn_audit_ctime){
                    audit = true
                }else{
                    audit = false
                }
                setInfo(
                    {
                        ebn_audit_ctime:data.Result.ebn_audit_ctime,
                        ebn_dept_id:data.Result.ebn_dept_id,
                        audit:audit,
                        ebn_eg_id:data.Result.ebn_eg_id,
                        ebn_team_num:data.Result.ebn_team_num,                        
                        eg_name:data.Result.eg_name,                        
                        guige:data.Result.guige, 
                        ec_name:data.Result.ec_name, 
                        ebn_client_id:data.Result.ebn_client_id, 
                        ebn_yw_id:data.Result.ebn_yw_id,                                                
                        ebn_yw_name:data.Result.ebn_yw_name,                                                
                    }
                )

            }
        }
    },[data,isSuccess])
    return (
        <>            
            <a onClick={() => {setOpen(true)}}>{props.text}</a>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false)}}
                width={1440}
                footer={null}                
            > 
                <div style={{border:'2px solid #eee',margin:"10px"}}>
                    <Flex justify='center'>
                        <h2>团队报账单</h2>
                    </Flex>
                    <LedgerGroupInfo 
                        ebn_dept_id={props.ebn_dept_id}
                        ebn_id={props.ebn_id}
                        ledgerName={param.get('ledgerName')}
                        ebn_team_num={props.ebn_team_num}
                    />
                    <div style={{marginTop:"15px"}}>
                        <LedgerGroupBalanceAdd style={{marginLeft:"15px"}} 
                            ebn_audit_ctime={info.ebn_audit_ctime}
                            eie_type_id='1' 
                            title='团队收入单'
                            ebn_dept_id={props.ebn_dept_id}
                            eie_ebn_id={props.ebn_id}
                            eie_eg_id={info.ebn_eg_id}
                            eg_name={info.eg_name}
                            guige={info.guige}
                            ebn_client_id={info.ebn_client_id}
                            ec_name={info.ec_name}
                            eie_jbr_uid={info.ebn_yw_id}
                            ebn_yw_id={info.ebn_yw_id}
                            ebn_yw_name={info.ebn_yw_name}
                        />            
                    </div>
                    <LedgerGroupBalanceList 
                        eie_type_id='1'//主类型id
                        title='团队收入单'
                        action='search'
                        ebn_dept_id={props.ebn_dept_id}
                        eie_ebn_id={props.ebn_id}
                        pagination={{position:['none'],defaultPageSize:100}}
                        audit={info.audit}
                        ebn_eg_id={info.ebn_eg_id}
                        eg_name={info.eg_name}
                        guige={info.guige}
                    />
                    <div style={{marginTop:"15px"}}>
                        <LedgerGroupBalanceAdd style={{marginLeft:"15px"}} 
                            ebn_audit_ctime={info.ebn_audit_ctime}
                            eie_type_id='2' 
                            title='团队支出单'
                            ebn_dept_id={props.ebn_dept_id}
                            eie_ebn_id={props.ebn_id}
                            eie_eg_id={info.ebn_eg_id}
                            eie_jbr_uid={info.ebn_yw_id}
                        />  
                    </div>
                    <LedgerGroupBalanceList 
                        eie_type_id='2'//主类型id
                        title='团队支出单'
                        eie_esv_id='14'
                        action='search'
                        ebn_dept_id={props.ebn_dept_id}
                        eie_ebn_id={props.ebn_id}
                        pagination={{position:['none'],defaultPageSize:100}}
                        audit={info.audit}
                        ebn_eg_id={info.ebn_eg_id}
                        ebn_yw_id={info.ebn_yw_id}
                        ebn_yw_name={info.ebn_yw_name}
                    />
                </div>
                {/* <Divider orientation="left">费用支出</Divider>
                <BalanceList 
                    eie_type_id='3'//主类型id
                    title='费用支出'
                    eie_esv_id='14'
                    action='search'
                    ebn_dept_id={param.get('ebn_dept_id')}
                    eie_ebn_id={props.ebn_id}
                    pagination={{position:['none']}}
                /> */}
                {/* <div style={{marginTop:"20px"}}>销售收入{income}-成本支出{cost}=利润{income+cost}</div> */}
            </Modal>
        </>
    )
}

export default LedgerGroupCheck