import React, { useEffect, useState } from 'react'
import Main from '../../cpn/Main'
import { Space, Table} from 'antd'
import FirmAccountQuery from './FirmAccountQuery';
import { useAccount_listQuery } from '../../store/ReactWebSiteApi';
function FirmAccount() {
    const [list,setList] = useState()
    const columns = [
        {
            title: 'Id',
            dataIndex: 'key',      
            width: '10%',
        },
        {
            title: '账号',
            dataIndex: 'ba_phone',    
            width: '20%',
        },
        {
            title: '密码',
            dataIndex: 'ba_show',    
            width: '20%',
        },
        {
            title: '最近登录时间',
            dataIndex: 'ba_login_time',
            sorter: (a, b) => {      
                let aTimeString = a.ba_login_time;
                let bTimeString = b.ba_login_time;
                let aTime = new Date(aTimeString).getTime();
                let bTime = new Date(bTimeString).getTime();
                return aTime - bTime;     
            },
            width: '20%'
        },
        {
            title: '最近登录系统',
            dataIndex: 'ba_login_system',    
            width: '20%',
        },
        {
            title: '注册时间',
            dataIndex: 'ba_ctime',
            width: '20%',
            sorter:  (a, b) => {
                let aTimeString = a.ba_ctime;
                let bTimeString = b.ba_ctime;
                let aTime = new Date(aTimeString).getTime();
                let bTime = new Date(bTimeString).getTime();
                return aTime - bTime;
            } 
        }    
    ];
    const [query,setQuery] = useState('')
    const { data, isSuccess,} = useAccount_listQuery(query,{refetchOnMountOrArgChange:1});  
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                const arr = data.Result.map((item) => {
                    const {ba_id:key,ba_phone,ba_show,ba_ctime,ba_login_system,ba_login_time} = item;
                    return item = {key,ba_phone,ba_show,ba_ctime,ba_login_system,ba_login_time}
                })
                const sortArr = arr.sort( (a, b) => {      
                    let aTimeString = a.key;
                    let bTimeString = b.key;
                    return bTimeString - aTimeString;     
                    },)
                setList(sortArr)
            }
        }
    },[data,isSuccess])
    
    return (
        <Main t1='商城' n1='/GfIndexMall' t2='企业' n2='/FirmIndex' t3='账号列表'>
            <Space>
                <FirmAccountQuery getQuery={(e) => {
                    setQuery(prevState=>({
                        ...prevState,
                        ba_phone:e.ba_phone,
                        action:'search'
                    }))
                }} />
            </Space>
            <Table 
                columns={columns} 
                dataSource={list} 
                pagination={{
                    showTotal:total => `共${data.Result.length}条数据`,
                    showSizeChange:true,
                    showQuickJumper:true
                    }} 
                style={{marginTop:"10px"}}
                />
                
        </Main>
    )
}

export default FirmAccount