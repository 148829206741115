import React,{useState,useRef,useEffect} from 'react'
import Main from '../../cpn/Main'
import { useEditProvinceMutation ,useEditProvinceByIdQuery} from '../../store/ReactWebSiteApi'
import { message,Button, Form, Input, Row,  Col } from 'antd'
import {useNavigate, useParams} from 'react-router-dom'

function ProvinceEdit(props) {  
  //获取默认值
  const param = useParams();
  const id = param.id
  
  //获取省份数据
  const { data, isSuccess } = useEditProvinceByIdQuery({id:id});
  useEffect(() => {
    if (isSuccess) {
      if (data.Status === 'success') {
        setProvince(prevState => ({ ...prevState, ctap_name: data.Result.ctap_name, ctap_remark: data.Result.ctap_remark }));
        form.current.setFieldsValue({
          'name': data.Result.ctap_name,
          'remark':data.Result.ctap_remark,
        })
      }
    }
  },[data,isSuccess])
  
  //指定表单
  const form = useRef();

  //修改数据
  const [province, setProvince] = useState({
    id:id,
    ctap_name: '',
    ctap_remark: '',
  });
  
  //绑定省份名称
  const nameInput = (e) => {
    setProvince(prevState =>({...prevState,ctap_name:e.target.value}))
  }
  //绑定省份备注
  const remarkInput = (e) => {
    setProvince(prevState =>({...prevState,ctap_remark:e.target.value}))
  }
  //链接
  const navigate = useNavigate();
  //上传数据
  const [EditProvince] = useEditProvinceMutation();
  //提交对话框表单
  const onFinish = () => {
    EditProvince(province).then(
      (res) => {
        if (res.data.Status === 'success') {
          message.success('修改省份成功！')
          navigate('/province');                                    
        }
        if (res.data.Status === 'fail') {
          message.error('修改省份失败！'+res.data.Message)
        }
      })
  }
  const onFinishFailed = () => {}
  return (
    <Main title1='系统' title2='地区' title3='省份' title4='新增省份'>
      <Row>
        <Col span={12}>
          <Form
            name="basic"
            labelCol={{span: 4,}}
            wrapperCol={{span: 20,}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            ref={form}
          >         
            <Form.Item
                label="1、填写省份名称"
                name="name"
                rules={[{
                    required: true,
                    message: '请填写省份名称！',
                },]}
                wrapperCol={{
                    span: 4,
                }}
            >
              <Input value={province.ctap_name} onChange={nameInput} />
            </Form.Item>
            <Form.Item
              label="2、填写备注"
              name='remark'
              rules={[{
                  required: true,
                  message: '请填写备注！',
              },]}
            >
              <Input value={province.ctap_remark} onChange={remarkInput} />
            </Form.Item>                    
            <Form.Item
              wrapperCol={{
                  offset: 2,
                  span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">提交</Button>
            </Form.Item>
          </Form>          
        </Col>
      </Row>
    </Main>
  )
}
export default ProvinceEdit