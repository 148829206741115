import React,{useState,useEffect} from 'react'
import { Table,Space,DatePicker, Radio, Modal, Flex, Descriptions, Button} from 'antd'
import { useEcTypeStatisticsQuery, } from '../../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import Main from '../../cpn/Main';

const {RangePicker} = DatePicker
function LedgerSumClientType() {
    const [open,setOpen] = useState(false)
    const [info,setInfo] = useState({
        date_s:'',
        date_e:'',
        ebn_audit:'0',
        name:'全部'
    })
    
    const {data,isSuccess} = useEcTypeStatisticsQuery({
        e_id:localStorage.getItem('e_id'),
        date_s:info.date_s,
        date_e:info.date_e,
        eu_id:localStorage.getItem('eu_id'),
        ebn_audit:info.ebn_audit,
    },{refetchOnMountOrArgChange:1});
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                // console.log(data);                
                const newArr = data.Result.map(item => {
                    const {ebn_ect_id:key,ebn_ect_name,expenditure_num,income_num,ebn_num} = item;
                    return item = {key,ebn_ect_name,expenditure_num,income_num,ebn_num}
                })                                
                setList(newArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])    
    const columns = [
                  
        {
            key: '1',
            title: '客户',
            dataIndex: 'ebn_ect_name',
        },     
        {
            key: '2',
            title: '团队报账单数量',
            dataIndex:'ebn_num',
        },     
        {
            key: '3',
            title: '团队收入单合计',
            dataIndex:'income_num'
        },     
        {
            key: '4',
            title: '团队支出单合计',
            dataIndex:'expenditure_num',
        },     
        {
            key: '5',
            title: '利润',
            render:(_,record) => {
                return <>{(+record.income_num + +record.expenditure_num).toFixed(2)}</>
            }
        },  
        {
            key: '6',
            title: '利润率',
            render:(_,record) => {
                return <>{(+record.income_num !== +record.expenditure_num)?(((+record.income_num + +record.expenditure_num)/+record.income_num)*100).toFixed(2)+'%':''}</>
            }
        },     
       
    ]
    const items = [        
        {
            key:'1',
            label:'团队报账单的出发日期',
            children:<>{info.date_s}至{info.date_e}</>,
        },
       
        {
            key:'3',
            label:'制表人',
            children:<>{localStorage.getItem('ed_name').replace(/"/g,'')} / {localStorage.getItem('eu_name').replace(/"/g,'')}</>,
            span:2,
        },
        {
            key:'2',
            label:'审核',
            children:<>{info.name}</>,
            
        },
        {
            key:'4',
            label:'制表时间',
            children:<>{dayjs().format('YYYY-MM-DD HH:MM:ss')}</>
        },
    ]
    return (
        <Main t1='财务' n1='/TaFinanceIndex' t2='团队收支' n2='/LedgerIndex' t3='报表' n3='/LedgerTable' t4='客户类型利润表'>
             <div style={{marginBottom:'30px'}}>本表说明：按团队报账单的出发日期和审核状态，统计每个客户类型的团队报账单数量、团队收入单金额之和、团队支出单金额之和、利润之和、利润率。</div>
            <Space>
                <span>团队报账单的出发日期：</span>
                <RangePicker 
                    onChange={(e) => {
                        if(e){
                            setInfo(prevState=>({...prevState,date_s:dayjs(e[0]).format('YYYY-MM-DD'),date_e:dayjs(e[1]).format('YYYY-MM-DD')})) 
                        }else{
                            setInfo(prevState=>({...prevState,date_s:'',date_e:''}))
                        }
                    }}
                />
                <Radio.Group 
                    optionType='button' 
                    onChange={(e) => {
                        let name 
                        if(e.target.value==='0'){
                            name='全部'
                        }else if(e.target.value==='1'){
                            name='已审核'
                        }else{
                            name='未审核'
                        }
                        setInfo(prevState=>({...prevState,ebn_audit:e.target.value,name}))
                    }} 
                    value={info.ebn_audit}
                >
                    <Radio value='0'>全部</Radio>
                    <Radio value='1'>已审核</Radio>
                    <Radio value='2'>未审核</Radio>
                </Radio.Group>
                <Button type='primary' onClick={() => {setOpen(true)}}>查询</Button>
            </Space>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false)}}
                width={1200}
                footer={null}
            >            
                <Flex justify='center'>
                    <h2>客户类型利润表</h2>
                </Flex>
                <Descriptions items={items} bordered/>
                <Table 
                    columns={columns} 
                    dataSource={list} 
                    pagination={{position:['none'],defaultPageSize:100}}
                    summary={(pageData) => {
                        console.log(pageData);
                        let totalebn_num = 0;
                        let totalincome_num = 0;
                        let totalnexpenditure_num = 0;
                        pageData.forEach(({ebn_num, income_num,expenditure_num, }) => {
                            totalebn_num += +ebn_num;
                            totalincome_num += +income_num;
                            totalnexpenditure_num += +expenditure_num;
                        });
                        return <Table.Summary>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>{totalebn_num}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}>{(totalincome_num).toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}>{(totalnexpenditure_num).toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}>{(totalincome_num+totalnexpenditure_num).toFixed(2)}</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                    }}
                />   
            </Modal>
        </Main>
    )
}

export default LedgerSumClientType