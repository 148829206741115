import React, { useEffect, useRef, useState } from 'react'
import { useDeptTzEndDateUpdateSaveMutation, useGetDeptTzEndDateQuery } from '../../store/ReactWebSiteApi'
import { DatePicker, Form, message, Modal, Space } from 'antd';
import dayjs from 'dayjs';
import {CalendarOutlined} from '@ant-design/icons';
function LedgerStartDateEdit(props) {
    console.log('props' ,props);
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const {data,isSuccess} = useGetDeptTzEndDateQuery({ed_id:props.ed_id},{skip:!open})
    const [info,setInfo] = useState({ed_name:'',ed_id:'',ed_tz_e_date:''});
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data,'111');
                editForm.current.setFieldsValue({
                    ed_tz_e_date:data.Result.ed_tz_e_date?dayjs(data.Result.ed_tz_e_date):''
                })
                setInfo(prevState=>({
                    ...prevState,
                    ed_tz_e_date:data.Result.ed_tz_e_date
                }))
            }
        }
    },[data, isSuccess])
    const [submit] = useDeptTzEndDateUpdateSaveMutation()
    const editForm = useRef()
    return (
        <>
            <a onClick={() => {setOpen(true);}}>
                <Space>
                    <CalendarOutlined /> {props.text ? <>{dayjs(props.text).format('YYYY年MM月DD日')}至2099年12月31日}</> : <> 未设置</>}
                </Space>
            </a>
            <Modal
                open={open}
                title='设置《团队报账单》出发日期'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            console.log(values);                            
                            submit({
                                ed_id:props.ed_id,
                                ed_tz_e_date:info.ed_tz_e_date,                               
                                ed_tz_uid:localStorage.getItem('eu_id'),
                                ed_tz_uname:localStorage.getItem('eu_name'),                               
                            }).then((res) => {
                                // console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')  
                                    setOpen(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })    
                        })
                        .catch((info) => {
                            // console.log('Validate Failed:', info);
                        }); 
                                               
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_date"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 7 }}
                    ref={editForm}
                >                                                       
                    <Form.Item label='部门' wrapperCol={{span:15}}>
                        {props.ed_name}
                    </Form.Item>
                    <Form.Item name="ed_tz_e_date" label='开始日期' wrapperCol={{span:15}} required >
                        <DatePicker
                            onChange={(e) => {setInfo(prevState=>({...prevState,ed_tz_e_date:dayjs(e).format('YYYY-MM-DD')}))}}
                            value={info.ed_tz_e_date}
                        />                        
                    </Form.Item>
                    <Form.Item label='结束日期'>2099年12月31日</Form.Item>

                </Form>
            </Modal>
        </>
    )
}

export default LedgerStartDateEdit