import React,{useState,useEffect,useRef} from 'react'
import Main from '../../cpn/Main'
import { useEditStreetMutation,useEditStreetByIdQuery} from '../../store/ReactWebSiteApi'
import { message,Button, Form, Input, Row,  Col,Select } from 'antd'
import { useNavigate,useParams } from 'react-router-dom'
function StreetEdit(props) {  
  //获取参数
  const param = useParams();
  const streetId = param.id
  //获取初始值
  const editForm = useRef();
  const { data, isSuccess } = useEditStreetByIdQuery({ id: streetId })
  const [defaultData, setDefaultData] = useState({
    province: '',
    city: '',
    district:'',
  });
  useEffect(() => {    
    if (isSuccess) {
      setDefaultData(prevState=>({...prevState,
        province: data.Result.ctap_name,
        city: data.Result.ctaci_name,
        district:data.Result.ctad_name,
      }))
      setStreet(prevState => ({
        ...prevState,
        ctas_province_id: data.Result.ctap_id,
        ctas_city_id: data.Result.ctaci_id,
        ctas_district_id: data.Result.ctad_id,
        ctas_name: data.Result.ctas_name,
        ctad_remark:data.Result.ctas_remark,
      }))
      editForm.current.setFieldsValue({
        'name': data.Result.ctas_name,
        'remark':data.Result.ctas_remark,
      })
    }
  }, [data, isSuccess])  

  //修改数据
  const [street, setStreet] = useState({
    id:streetId,
    ctas_province_id: '',
    ctas_city_id: '',
    ctas_district_id:'',
    ctas_name:'',
    ctas_remark: '',
  });
  //绑定街道名称
  const nameInput = (e) => {
    setStreet(prevState =>({...prevState,ctas_name:e.target.value}))
  }
  //绑定区县备注
  const remarkInput = (e) => {
    setStreet(prevState =>({...prevState,ctas_remark:e.target.value}))
  }
  //链接
  const navi = useNavigate();
  //上传数据
  const [EditStreet] = useEditStreetMutation();
  //提交对话框表单
  const onFinish = () => {
    EditStreet(street).then(
      (res) => {
        if (res.data.Status === 'success') {
          message.success('修改街道成功！')
          navi('/StreetList');                                    
        }
        if (res.data.Status === 'fail') {
          message.error('修改街道失败！'+res.data.Message)
        }
      })
  }
  const onFinishFailed = () => {}
  return (
    <Main title1='系统' title2='地区' title3='街道' title4='修改街道'>
      <Row>
        <Col span={12}>
          <Form
              name="basic"
              labelCol={{
                  span: 4,
              }}
              wrapperCol={{
                  span: 20,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            autoComplete="off"
            ref={editForm}
          >
            <Form.Item
              label="1、选择城市"              
              required
            >
              <Select
                name="province"
                style={{ width: 120,}}
                placeholder={defaultData.province}
                disabled
              />
              <Select
                name="city"
                style={{ width: 120,}}
                placeholder={defaultData.city}
                disabled
              />
              <Select
                name="district"
                style={{ width: 120,}}
                placeholder={defaultData.district}
                disabled
              />
            </Form.Item>        
            <Form.Item
                label="2、填写街道名称"
                name="name"
                rules={[{
                    required: true,
                    message: '请填写街道名称！',
                },]}
                wrapperCol={{
                    span: 4,
                }}
            >
                <Input value={street.ctas_name} onChange={nameInput} />
            </Form.Item>
            <Form.Item
                label="3、填写备注"
                name='remark'              
            >
              <Input value={street.ctas_remark} onChange={remarkInput} />
            </Form.Item>                    
            <Form.Item
                wrapperCol={{
                    offset: 2,
                    span: 16,
                }}
            >
              <Button type="primary" htmlType="submit">提交</Button>
            </Form.Item>
          </Form>          
        </Col>
      </Row>
    </Main>
  )
}
export default StreetEdit