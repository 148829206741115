import React from 'react'
import Main from '../../cpn/Main'
import ClientType from '../../finance/client/ClientType'


function TaFinanceClientType() {
    return (
        <Main  t1='财务' n1='/TaFinanceIndex' t2='设置' n2='/TaFinanceSetIndex' t3='客户类型'>
            <ClientType />
        </Main>
    )
}

export default TaFinanceClientType