import React  from 'react'
import {Space, Table } from 'antd'
function TableCheck(props) {
    return (
        <Table 
            title={() => <Space><span style={{fontWeight:'600'}}>{props.title}</span> {props.titleRight}</Space>}
            columns={props.columns}
            dataSource={props.dataSource}
            style={props.style}
            pagination={{position:['none']}}
            size={props.size}
            
        />
    )
}

export default TableCheck