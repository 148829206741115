import React from 'react'
import { Modal,message,} from 'antd';
import {usePhotoAlbumGroupDelMutation,  } from '../store/ReactWebSiteApi';
function AlbumSectionDel(props) {
    //删除分组-----------------------------------------------------------------------------------------------------------------------------删除分组
    const {confirm} = Modal; 
    const [submitDel] = usePhotoAlbumGroupDelMutation()
    return (
        <>
            <a onClick={() => {
                confirm({
                    title: '确定删除吗?',
                    content: '删除后不能恢复',
                    okText: '确认',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        submitDel({epag_id:props.epag_id}).then((res) => {
                            if (res.data.Status === 'success') {
                                message.info('删除成功！');
                            }
                            if (res.data.Status === "fail") {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    },
                    onCancel() {
                    console.log('Cancel');
                    },
                });
            }}>删除</a>
        </>
    )
}

export default AlbumSectionDel