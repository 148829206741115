import React,{useState,useEffect,useRef}from 'react'
import Main from '../../cpn/Main'
import { Table,Button,Space,Modal,message,Form,Input,} from 'antd';
import { useFinanceSubTypeAddMutation,  useFinanceSubTypeDelMutation,  useFinanceSubTypeListQuery,  useFinanceSubTypeUpdateQuery,  useFinanceSubTypeUpdateSaveMutation,} from '../../store/ReactWebSiteApi';
import { useSearchParams } from 'react-router-dom';

function FirmTypeBalance() {
    const [param] = useSearchParams();
    let typeName
    switch (param.get('type')) {
        case '3':
            typeName = '借款'
            break;    
        case '4':
            typeName = '费用'
            break;    
        default:
            break;
    }
    //新增子类型-----------------------------------------------------------------------------------------------------------------------------新增子类型
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [infoAdd,setInfoAdd] = useState({
        efst_content:'',
    });
    const [submitAdd] = useFinanceSubTypeAddMutation()
    const modalAdd= <Modal
                        open={openAdd}
                        title={`新增${typeName}子类型`}
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClient(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            let typeCode = ''
                            switch (infoAdd.efst_name) {
                                case '网站保证金':
                                    typeCode = 'OtaSiteEarnest'
                                    break;                            
                                case '网站费用':
                                    typeCode = 'OtaSitesExpense'
                                    break;                            
                                default:
                                    break;
                            }
                            submitAdd({
                                efst_e_id:param.get('id'),
                                efst_finance_type_id:param.get('type'),
                                efst_name:infoAdd.efst_name,
                                efst_content:typeCode,                                
                                efst_uid:localStorage.getItem('eu_id'),
                                efst_uname:localStorage.getItem('eu_name'),
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpenAdd(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 7 }}
                        >                            
                            <Form.Item name="name" label='类型名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,efst_name:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal> 
    //修改子类型-----------------------------------------------------------------------------------------------------------------------------修改子类型
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    const [infoEdit,setInfoEdit] = useState();
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useFinanceSubTypeUpdateQuery({efst_id:editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    efst_name:dataEdit.Result.efst_name,
                    efst_content:dataEdit.Result.efst_content,
                })
                setInfoEdit(prevState=>({...prevState,
                    efst_name:dataEdit.Result.efst_name,
                    efst_content:dataEdit.Result.efst_content,              
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useFinanceSubTypeUpdateSaveMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改子类型'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            
                            submitUpdateSave({
                                efst_name:infoEdit.efst_name,    
                                efst_content:infoEdit.efst_content,
                                efst_finance_type_id:param.get('type'),                            
                                efst_e_id:param.get('id'),
                                efst_update_uid:localStorage.getItem('eu_id'),
                                efst_update_uname:localStorage.getItem('eu_name'),
                                efst_id:editId,                                
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 7 }}
                            ref={editForm}
                        >
                            <Form.Item name="efst_name" label='子类型名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,efst_name:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal>
    //删除子类型-----------------------------------------------------------------------------------------------------------------------------删除子类型
    const {confirm} = Modal; 
    const [submitDel] = useFinanceSubTypeDelMutation()
    //子类型列表-----------------------------------------------------------------------------------------------------------------------------子类型列表
    
    const {data,isSuccess} = useFinanceSubTypeListQuery({efst_e_id:param.get('id'),efst_finance_type_id:param.get('type')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {efst_id:key,efst_name,efst_content,efst_uname,efst_ctime,efst_update_ctime,efst_update_uname,goods_num} = item;
                    return item = {key,efst_name,efst_content,efst_uname,efst_ctime,efst_update_ctime,efst_update_uname,goods_num}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.efst_ctime;
                    let bTimeString = b.efst_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])      
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },       
        {
            key: 'type',
            title: '类型名称',
            dataIndex: 'efst_name',
        },                                          
        {
            key: 'code',
            title: '防改码',
            dataIndex: 'efst_content',            
        },                                          
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.efst_ctime + record.efst_uname}</div>
                    <div>修改时间：{record.efst_update_ctime + record.efst_update_uname}</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                
                return <>
                    <Space direction='vertical'>                        
                        {!record.efst_content && <>
                            <a onClick={() => {
                                setEditId(record.key);
                                setOpenEdit(true)
                            }}>修改</a>
                            <a onClick={() => {
                                confirm({
                                    title: '确定删除吗?',
                                    content: '删除后不能恢复',
                                    okText: '确认',
                                    okType: 'danger',
                                    cancelText: '取消',
                                    onOk() {
                                        submitDel({efst_id:record.key}).then((res) => {
                                            if (res.data.Status === 'success') {
                                                message.info('删除成功！');
                                            }
                                            if (res.data.Status === "fail") {
                                                message.error('删除失败！'+res.data.Message)
                                            }
                                        })
                                    },
                                    onCancel() {
                                    console.log('Cancel');
                                    },
                                });
                            }}>删除</a>                        
                        </>}
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main  topActive='BnsFirm' app='goldfish' t1={param.get('name')} t2='数据类型' t3={typeName}>
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增{typeName}类型</Button>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalAdd}
            {modalEdit}            
        </Main>
    )
}

export default FirmTypeBalance