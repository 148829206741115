export const MenuName = [
    {
        id:'31',
        name:'考勤条'
    },
    {
        id:'32',
        name:'工资条'
    },
    {
        id:'33',
        name:'银行卡'
    },
    {
        id:'34',
        name:'用户权限'
    },
    
    {
        id:'51',
        name:'培训计划'
    },
    {
        id:'52',
        name:'我参加的'
    },
    {
        id:'53',
        name:'我负责的'
    },
    {
        id:'54',
        name:'用户权限'
    },
]