import React,{useState,useEffect,useRef}from 'react'
import MainKeeper from '../MainKeeper';
import ImagePreview from '../../cpn/ImagePreview'
import { Table,Space,Modal,message,Select,Form,DatePicker,Image,Button,Upload,Input,InputNumber} from 'antd';
import {  useNavigate, } from 'react-router-dom';
import { useBillDelMutation, useBillListQuery, useCapitalAccountDetailAddMutation,useCapitalAccountListQuery, useDisburseAuditCancelMutation,useBillGoodsListQuery, useBillUpdateQuery,useDisburseAuditMutation,useBillUpdateSaveMutation} from '../../store/ReactWebSiteFinanceApi';
import dayjs from 'dayjs';
import { UploadOutlined} from '@ant-design/icons';
import GetStaff from '../../cpn/GetUser';
const { TextArea } = Input;

function Balance() {
    //修改收支----------------------------------------------------------------------------------------------------------------------------------------修改收支//
    const [form] = Form.useForm();
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {    
        if(infoEdit.ef_amount !== 0){
            EditSave({
                ef_main_id:infoEdit.ef_main_id,
                ef_main_name:infoEdit.ef_main_name,
                ef_sublevel_id:infoEdit.ef_sublevel_id,
                ef_sublevel_name:infoEdit.ef_sublevel_name,
                ef_efg_id:infoEdit.ef_efg_id,
                ef_efg_name:infoEdit.ef_efg_name,
                ef_ec_id:infoEdit.ef_ec_id,
                ef_ec_name:infoEdit.ef_ec_name,
                ef_responsible_uid:infoEdit.ef_responsible_uid,
                ef_responsible_uname:infoEdit.ef_responsible_uname,
                ef_amount:+infoEdit.ef_amount,
                ef_content:infoEdit.ef_content,
                ef_update_uid:infoEdit.ef_update_uid,
                ef_update_uname:infoEdit.ef_update_uname,
                ef_e_id:infoEdit.ef_e_id,
                ef_dept_id:infoEdit.ef_dept_id,
                ef_id:infoEdit.ef_id,
                ef_image_name:infoEdit.ef_image_name,
                ef_image_file:infoEdit.ef_image_file,
            }).then((res) => {
                console.log(res);
                if (res.data.Status === 'success') {
                    message.success('修改成功！')   
                    setOpenEdit(false)                             
                }
                if (res.data.Status === 'fail') {
                    message.error('修改失败！'+res.data.Message)
                }
            })
        }else{
            message.error('金额不能为零');
            return false;  
        }
    };
    //获取员工
    const getStaff = (e,f,g) => {
        // console.log(e,f,g);
        setInfo(prevState=>({...prevState,ef_responsible_uid:e,ef_responsible_uname:f,}))
    }
    //默认图片
    const [defaultPic,setDefaultPic] = useState();
    const [infoEdit,setInfoEdit] = useState({
        ef_e_id:localStorage.getItem('e_id'),
        ef_dept_id:localStorage.getItem('ed_id'),
        ef_update_uname:localStorage.getItem('eu_name'),
        ef_update_uid:localStorage.getItem('eu_id'),
        ef_efg_id:'',
        ef_efg_name:'',
        ef_image_name:'',
        ef_image_file:'',
    });
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useBillUpdateQuery({ef_id:editId},{skip:!editId,refetchOnMountOrArgChange:1})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log('dataEdit',dataEdit);
                //默认图片
                if (dataEdit.Result.ef_image_file) {
                    let options = [];
                    let size = dataEdit.Result.ef_image_file.length;
                    for (let i = 0; i < size; i++) {
                        let a = {};
                        a.uid = i;
                        a.url = `/api/assets/images/application/${dataEdit.Result.ef_image_file[i]}`
                        a.response = {Result:dataEdit.Result.ef_image_file[i]}
                        options.push(a);
                    }
                    //console.log(options);
                    setDefaultPic(<Upload
                        name="image_file"
                        action="/api/ReactApi/zd_single_upload_image"
                        listType="picture"
                        defaultFileList={options}
                        className="upload-list-inline"
                        onChange={handleChange}
                        >
                        <Button icon={<UploadOutlined />}>点击上传</Button>
                    </Upload>)
                }else{
                    setDefaultPic(<Upload
                        name="image_file"
                        action="/api/ReactApi/zd_single_upload_image"
                        listType="picture"
                        className="upload-list-inline"
                        onChange={handleChange}
                        >
                        <Button icon={<UploadOutlined />}>点击上传</Button>
                    </Upload>)
                }
                editForm.current.setFieldsValue({
                    ef_ec_id:dataEdit.Result.ef_ec_id,
                    ef_ec_name:dataEdit.Result.ef_ec_name,
                    ef_responsible_uname:dataEdit.Result.ef_responsible_uname,
                    ef_amount:dataEdit.Result.ef_amount,
                    ef_content:dataEdit.Result.ef_content,
                    'ef_efg_id':dataEdit.Result.efg_id,
                    subject:[dataEdit.Result.ef_main_id,dataEdit.Result.ef_sublevel_id],
                })
                setInfoEdit(prevState=>({...prevState,
                    ef_main_id:dataEdit.Result.ef_main_id,
                    ef_main_name:dataEdit.Result.ef_main_name,
                    ef_sublevel_id:dataEdit.Result.ef_sublevel_id,
                    ef_sublevel_name:dataEdit.Result.ef_sublevel_name,
                    ef_ec_id:dataEdit.Result.ef_ec_id,
                    ef_ec_name:dataEdit.Result.ef_ec_name,
                    ef_amount:+dataEdit.Result.ef_amount,
                    ef_content:dataEdit.Result.ef_content,
                    ef_responsible_uname:dataEdit.Result.ef_responsible_uname,               
                    ef_responsible_uid:dataEdit.Result.ef_responsible_uid,               
                    ef_efg_id:dataEdit.Result.ef_efg_id,               
                    ef_efg_name:dataEdit.Result.ef_efg_name,                
                    ef_image_file:dataEdit.Result.ef_image_file,               
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    //上传图片
    const handleChange = (e) => {
        if (e.file.status === 'done'||e.file.status==='removed') {
            const fileArr = e.fileList.map((item) => {
                const { response } = item
                return response.Result
            })
            console.log(e);
            let fileStr = fileArr + "";  
            if(fileStr === null){
                fileStr = ''
            }
            const nameArr = e.fileList.map((item) => {
                const { name} = item
                return name
            })
            let nameStr = nameArr + ""
            setInfoEdit(prevState=>({...prevState,ef_image_name:nameStr,ef_image_file:fileStr}))
        }
    }
    //产品列表
    
    const {data:dataGoods, isSuccess:isSuccessGoods} = useBillGoodsListQuery({efg_e_id:localStorage.getItem('e_id')});
    
    const [goods, setGoods] = useState();
    
    useEffect(() => {
        if (isSuccessGoods) {
            if(dataGoods.Status==='success'){
                
                const newArr = dataGoods.Result.map(item => {
                    const {efg_id:key,efg_name} = item;
                    return item = {value:key,label:efg_name}
                })                                
                setGoods(newArr)
            }else{
                setGoods('')
            }
            
        }
    }, [dataGoods, isSuccessGoods])
    const [EditSave] = useBillUpdateSaveMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改产品'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });

                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >
                            <Form.Item label='客户' name='ef_ec_name' wrapperCol={{span:10}} required>
                                <Input disabled/>
                            </Form.Item>
                            <GetStaff label='经手人'  getStaff={getStaff} defaultId={infoEdit.ef_responsible_uname}/>
                            <Form.Item name="ef_amount" label='金额' wrapperCol={{span:5}} required>
                                <InputNumber onChange={(e) => {setInfoEdit(prevState=>({...prevState,ef_amount:e}))}}/>
                            </Form.Item>
                            <Form.Item name="ef_content" label='备注' wrapperCol={{span:15}} required>
                                <TextArea rows={4}  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ef_content:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item label="图片"                             
                                valuePropName='fileList'
                                getValueFromEvent={e => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e && e.fileList;
                                }}
                                >
                                {defaultPic}
                            </Form.Item>                
                            <Form.Item name="ef_efg_id" label='选择产品' wrapperCol={{span:15}} initialValue={infoEdit.ef_efg_id}>
                                <Select 
                                    options={goods} 
                                    onChange={(e,f) => {setInfoEdit(prevState=>({...prevState,ef_efg_id:f.value,ef_efg_name:f.label}))}} 
                                />
                            </Form.Item>
                        </Form>
                    </Modal>
    
    //删除收支----------------------------------------------------------------------------------------------------------------------------------------删除收支//
    const {confirm} = Modal; 
    const [delBusiness] = useBillDelMutation()
    //收支列表----------------------------------------------------------------------------------------------------------------------------------------收支列表//
    const {data, isSuccess} = useBillListQuery({ef_e_id:localStorage.getItem('e_id')});
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if (isSuccess) {
            //console.log(data);
            if(data.Status==='success'){
                
                const newArr = data.Result.map(item => {
                    const {ef_id:key,ef_main_name,ef_main_id,ef_ec_id,ef_sublevel_name,ef_sublevel_id,ef_efg_name,ef_responsible_uname,ef_ctime,ef_amount,ef_content,ef_ec_name,ef_uname,ef_state,ef_image_file,ef_responsible_uid,ef_update_ctime,ef_update_uname,ecal_log_date} = item;
                    return item = {key,ef_main_name,ef_main_id,ef_ec_id,ef_sublevel_name,ef_sublevel_id,ef_efg_name,ef_responsible_uname,ef_ctime,ef_amount,ef_content,ef_ec_name,ef_uname,ef_state,ef_image_file,ef_responsible_uid,ef_update_ctime,ef_update_uname,ecal_log_date}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ef_ctime;
                    let bTimeString = b.ef_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    //获取参数
    const navi = useNavigate();
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'type',
            title: '科目',
            render:(_,record) => {
                return <>{record.ef_main_name}</>
            }
        },
        {
            key: 'type',
            title: '子科目',
            render:(_,record) => {
                return <>{record.ef_sublevel_name}</>
            }
        },
        {
            key: 'min',
            title: '金额',
            dataIndex: 'ef_amount',
            render:(text) => {
                if(text>0){
                    return <>
                        <span style={{color:'red',fontWeight:'bold'}}>{text}</span>
                    </>
                }else{
                    return <>
                        <span style={{color:'green',fontWeight:'bold'}}>{text}</span>
                    </>
                }
            }
        },
        {
            key: 'client',
            title: '客户',
            dataIndex:'ef_ec_name'
        },
        {
            key: 'room',
            title: '经手人',
            dataIndex: 'ef_responsible_uname',
        },
        
        {
            key: 'remark',
            title: '备注',
            dataIndex: 'ef_content',
        },     
        {
            key: 'pic',
            title: '图片',
            dataIndex: 'ef_image_file',
            render: (text) => {
                let newArr
                if (text) {
                    newArr = text.map((item,index) => {
                        return <Image src={`/api/assets/images/application/${item}`} key={index}  />
                    })
                    return <ImagePreview
                        firstPic={`/api/assets/images/application/${text[0]}`}
                        pics={newArr}
                    />
                }
            }
        },     
        {
            key: 'goods',
            title: '产品',
            dataIndex: 'ef_efg_name',
        },
        {
            key: 'status',
            title: '状态',
            render:(_,record) => {
                if(record.ef_amount>0){
                    if(record.ecal_log_date){
                        if(record.ecal_log_date.ecal_state==='0'){
                            return<>待对账</>
                        }else{
                            return<>已对账</>
                        }
                    }else{
                        return<>待新增账户明细</>
                    }
                }else{
                    if(record.ef_state==='0'){
                        return <>
                            <div>待审核</div>                     
                        </>
                    }else{
                        if(record.ecal_log_date){
                            if(record.ecal_log_date.ecal_state==='0'){
                                return<>待对账</>
                            }else{
                                return<>已对账</>
                            }
                        }else{
                            return<>
                                <div>已审核</div>
                                <div>待新增账户明细</div>
                            </>
                        }
                    }
                }               
            }
        },
        {
            key: 'time',
            title: '新增/修改时间',
            render: (_,record) => {
                return <>
                    <div>{record.ef_ctime + record.ef_uname}</div>
                    {record.ef_update_ctime && <div>{record.ef_update_ctime + record.ef_update_uname}</div>}
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>
                    {((record.ef_amount>0 && !record.ecal_log_date)||(record.ef_amount<0 && record.ef_state==='0'))&&
                        <a onClick={() => {
                            setEditId(record.key);
                            setOpenEdit(true) 
                            setInfoEdit(prevState=>({
                                ...prevState,
                                ef_id:record.key,
                                ef_sublevel_id:record.ef_sublevel_id,
                                ef_sublevel_name:record.ef_sublevel_name,
                                ef_main_name:record.ef_main_name,
                                ef_ec_id:record.ef_ec_id,
                                ef_ec_name:record.ef_ec_name,
                                ef_responsible_uid:record.ef_responsible_uid,
                                ef_responsible_uname:record.ef_responsible_uname,
                                ef_content:record.ef_content,
                            }))                     
                        }}>修改</a>}
                       {((record.ef_amount>0 && !record.ecal_log_date)||(record.ef_amount<0 && record.ef_state==='0'))&& <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    delBusiness({ef_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>}                                  
                        {(record.ef_amount<0 && record.ef_state==='0') && <a onClick={() => {
                            confirm({
                                title: '确定审核吗?',
                                content: '审核后可以取消',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    disburseAudit({ef_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('审核成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('审核失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>审核</a>}
                        {(record.ef_amount<0 && record.ef_state==='1') && <a onClick={() => {
                            confirm({
                                title: '确定审核吗?',
                                content: '审核后可以取消',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    disburseAuditCancel({ef_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('取消审核成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('取消审核失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>取消审核</a>}
                    </Space>
                </>
            }
        },
    ]
    //支出审核--------------------------------------------------------------------------------------------------------------------------------------------------支出审核//
    const [disburseAudit] =useDisburseAuditMutation()
    //取消审核
    const [disburseAuditCancel] = useDisburseAuditCancelMutation()
    //新增账户明细--------------------------------------------------------------------------------------------------------------------------------------------新增账户明细//
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const start = () => {
        setLoading(true);
        // ajax request after empty completing
        setTimeout(() => {
        setSelectedRowKeys([]);
        setLoading(false);
        }, 1000);
    };
    const onSelectChange = (newSelectedRowKeys,f) => {
        console.log(newSelectedRowKeys,f);
        if(f.length>1){
            if(f[f.length - 1].ef_ec_id !== f[f.length - 2].ef_ec_id){
                message.error('客户不相同，不能新增账户明细！')
                return false;
            }
        }
        let sum3 
        sum3 = f.reduce((sum3, obj) => (sum3 += +obj.ef_amount), 0)
        setInfo(prevState=>({...prevState,ecal_amount:sum3,ecal_ef_id:newSelectedRowKeys+''}))        
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        hideSelectAll:true,
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            disabled: ((record.ef_amount<0 && record.ef_state==='0')||(record.ef_amount<0 && record.ef_state==='2')||(record.ef_amount>0 && record.ecal_log_date)),
            // Column configuration not to be checked
            name: record.name,
        }),
    };
    const hasSelected = selectedRowKeys.length > 0;
    const [info,setInfo] = useState({
        ecal_uid:localStorage.getItem('eu_id'),
        ecal_uname:localStorage.getItem('eu_name'),
    });
    const [addBalance] = useCapitalAccountDetailAddMutation()
    const [open, setOpen] = useState(false);
    //账户列表
    const {data:dataBank, isSuccess:isSuccessBank} = useCapitalAccountListQuery({eca_e_id:localStorage.getItem('e_id')});
    
    const [bank, setBank] = useState();
    //选中的数据
    const [bankName,setBankName] = useState()
    
    useEffect(() => {
        if (isSuccessBank) {
            console.log(dataBank);
            if(dataBank.Status==='success'){
                
                let newArr
                if(info.ecal_amount=== 0){
                    newArr = dataBank.Result.map(item => {
                        const {eca_id:key,eca_type_id,eca_bank_kh,eca_bank_zh,eca_bank_crad,eca_wx_phone,eca_wx_account,eca_alipay_phone,eca_alipay_account,eca_amount} = item;
                        if(eca_type_id==='0' ){
                            return item = {value:key,label:eca_bank_kh + '/' + eca_bank_zh + '/' +eca_bank_crad}
                        }else if(eca_type_id==='1'){
                            return item = {value:key,label:eca_bank_kh + '/' + eca_bank_zh + '/' +eca_bank_crad,eca_amount,eca_type_id,disabled:true}
                        }else if(eca_type_id==='2'){
                            return item = {value:key,label:eca_wx_phone + '/' + eca_wx_account,eca_amount,disabled:true}
                        }else{
                            return item = {value:key,label:eca_alipay_phone + '/' + eca_alipay_account,eca_amount,eca_type_id,disabled:true}
                        }
                    })   
                }else if(info.ecal_amount < 0){
                    newArr = dataBank.Result.map(item => {                        
                        const {eca_id:key,eca_type_id,eca_bank_kh,eca_bank_zh,eca_bank_crad,eca_wx_phone,eca_wx_account,eca_alipay_phone,eca_alipay_account,eca_amount} = item;
                        console.log(+info.ecal_amount,+eca_amount);
                        if(eca_type_id==='0' ){
                            return item = {value:key,label:eca_bank_kh + '/' + eca_bank_zh + '/' +eca_bank_crad,disabled:true}
                        }else if(eca_type_id==='1'){
                            return item = {value:key,label:eca_bank_kh + '/' + eca_bank_zh + '/' +eca_bank_crad+ '，余额：' + eca_amount,disabled:-info.ecal_amount > +eca_amount}
                        }else if(eca_type_id==='2'){
                            return item = {value:key,label:eca_wx_phone + '/' + eca_wx_account+ '，余额：' + eca_amount,disabled:-info.ecal_amount > +eca_amount}
                        }else{
                            return item = {value:key,label:eca_alipay_phone + '/' + eca_alipay_account+ '，余额：' + eca_amount,disabled:-info.ecal_amount > +eca_amount}
                        }
                    })   
                }else{
                    newArr = dataBank.Result.map(item => {                        
                        const {eca_id:key,eca_type_id,eca_bank_kh,eca_bank_zh,eca_bank_crad,eca_wx_phone,eca_wx_account,eca_alipay_phone,eca_alipay_account,eca_amount} = item;
                        console.log(+info.ecal_amount,+eca_amount);
                        if(eca_type_id==='0' ){
                            return item = {value:key,label:eca_bank_kh + '/' + eca_bank_zh + '/' +eca_bank_crad,disabled:true}
                        }else if(eca_type_id==='1'){
                            return item = {value:key,label:eca_bank_kh + '/' + eca_bank_zh + '/' +eca_bank_crad+ '，余额：' + eca_amount}
                        }else if(eca_type_id==='2'){
                            return item = {value:key,label:eca_wx_phone + '/' + eca_wx_account+ '，余额：' + eca_amount}
                        }else{
                            return item = {value:key,label:eca_alipay_phone + '/' + eca_alipay_account+ '，余额：' + eca_amount}
                        }
                    })   
                }
                             
                console.log(newArr);
                setBank(newArr)
            }else{
                setList('')
            }
            
        }
    }, [dataBank,isSuccessBank,info.ecal_amount])
    const modalDetail = <Modal
                            open={open}
                            title='新增账户明细'
                            okText="提交"
                            cancelText="取消"
                            onCancel={() => {setOpen(false);}}
                            onOk={() => {
                                form
                                    .validateFields()
                                    .then((values) => {
                                        form.resetFields();
                                        addBalance(info).then((res) => {
                                            console.log(res);
                                            if (res.data.Status === 'success') {
                                                message.success('新增成功！')
                                                navi(`/BankAccountDetail?id=${info.ecal_eca_id}&name=${bankName}`);                                    
                                            }
                                            if (res.data.Status === 'fail') {
                                                message.error('新增失败！'+res.data.Message)
                                            }
                                        })
                                    })
                                    .catch((info) => {
                                        console.log('Validate Failed:', info);
                                    });
                                

                            }}
                        >
                            <Form
                                form={form}
                                layout="horizonal"
                                name="form_in_modal"
                                initialValues={{modifier: 'public',}}
                                labelCol={{ span: 5 }}
                            >
                                <Form.Item name="sum" label='金额' wrapperCol={{span:15}}>
                                    <InputNumber placeholder={info.ecal_amount} disabled/>
                                </Form.Item>
                                <Form.Item name="app" label='选择账户' wrapperCol={{span:15}}>
                                    <Select options={bank} onChange={(e,f) => {                            
                                        setInfo(prevState=>({...prevState,ecal_eca_id:e}))
                                        setBankName(f.label)
                                    }} />
                                </Form.Item>
                                <Form.Item name='time' label='选择交易时间' wrapperCol={{span:15}}>
                                    <DatePicker showTime 
                                        onChange={(e) => {
                                            setInfo(prevState=>({...prevState,ecal_bank_detail_date:dayjs(e).format('YYYY-MM-DD HH:mm:ss')}))
                                        }}
                                        onOk={(e) => {
                                            setInfo(prevState=>({...prevState,ecal_bank_detail_date:dayjs(e).format('YYYY-MM-DD HH:mm:ss')}))
                                        }} 
                                    />
                                </Form.Item>
                            </Form>
                        </Modal> 
    return (
        <MainKeeper  topActive='Balance'>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list} rowSelection={rowSelection}></Table>  
            <div
                style={{
                marginTop: 16,
                fontSize:16
                }}
            >
                {hasSelected ? `已选择 ${selectedRowKeys.length}笔收支，合计金额：${info.ecal_amount}元` : ''}
                <Button type="primary" onClick={() => { setOpen(true);}}  disabled={!hasSelected} style={{marginLeft:'10px'}}>
                    新增账户明细
                </Button>
                <Button onClick={start} disabled={!hasSelected} loading={loading} style={{marginLeft:'10px'}}>
                    重新选择
                </Button>
                
                <span
                style={{
                    marginLeft: 8,
                }}
                >
                
                </span>
            </div>
            {modalEdit}             
            {modalDetail}   
                            
        </MainKeeper>
    )
}

export default Balance