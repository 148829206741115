import { configureStore} from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/dist/query"
//网站
import { loginReducer } from './loginSlice'
import { deptReducer } from './deptSlice';
import zd_enterprise_userApi from './zd_enterprise_userApi'
//业务管理
import ReactWebSiteApi from './ReactWebSiteApi'
//人事管理
import zd_enterprise_deptApi from './zd_enterprise_deptApi'
import React_enterpriseApi from './React_enterpriseApi'
//财务管理
import ReactWebSiteFinanceApi from './ReactWebSiteFinanceApi'
import reactapiApi from './reactapiApi'
const store = configureStore({
    reducer: {
        //网站       
        login: loginReducer,    
        dept: deptReducer,     
        [zd_enterprise_userApi.reducerPath]: zd_enterprise_userApi.reducer,
        //我的
        [zd_enterprise_deptApi.reducerPath]: zd_enterprise_deptApi.reducer,
        
        //业务管理
        [ReactWebSiteApi.reducerPath]: ReactWebSiteApi.reducer,   
        //人事管理
        [React_enterpriseApi.reducerPath]: React_enterpriseApi.reducer,   
        //财务管理
        [ReactWebSiteFinanceApi.reducerPath]: ReactWebSiteFinanceApi.reducer,   
        [reactapiApi.reducerPath]: reactapiApi.reducer,   
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(
            //网站
            zd_enterprise_deptApi.middleware,
            zd_enterprise_userApi.middleware,
            //业务管理
            ReactWebSiteApi.middleware,
            //人事管理
            React_enterpriseApi.middleware,
            //财务管理
            ReactWebSiteFinanceApi.middleware,
            reactapiApi.middleware,
        ),
    
    }
);

setupListeners(store.dispatch)

export default store;