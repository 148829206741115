import React,{useState,useEffect,useRef}from 'react'
import Main from '../../cpn/Main'
import { Table,Button,Space,Modal,message,Form,Input,} from 'antd';
import { useSearchParams, } from 'react-router-dom';
import { useAddGoodsSpecMutation, useDelGoodsSpecMutation, useGoodsSpecListQuery, useGoodsSpecUpdateQuery, useUpdateSaveGoodsSpecMutation } from '../../store/ReactWebSiteApi';

function AppSpec() {
    const [param] = useSearchParams()
    //新增规格-----------------------------------------------------------------------------------------------------------------------------新增规格
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateClient = (values) => {
        // console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [infoAdd,setInfoAdd] = useState({
        ess_content:'',
    });
    const [submitAdd] = useAddGoodsSpecMutation()
    const modalAdd= <Modal
                        open={openAdd}
                        title='新增规格'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClient(values);
                                })
                                .catch((info) => {
                                    // console.log('Validate Failed:', info);
                                });
                            submitAdd({
                                ess_esv_id:param.get('id'),
                                ess_name:infoAdd.ess_name,
                                ess_content:infoAdd.ess_content,
                                ess_price:infoAdd.ess_price,
                                ess_num:infoAdd.ess_num,
                                ess_uid:localStorage.getItem('eu_id'),
                                ess_uname:localStorage.getItem('eu_name'),
                            }).then((res) => {
                                // console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpenAdd(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                        >
                            <Form.Item label='应用名称' wrapperCol={{span:10}} required>
                                {param.get('name')}
                            </Form.Item>
                            <Form.Item name="name" label='规格名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ess_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="price" label='价格' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ess_price:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="year" label='年限' wrapperCol={{span:10}}  required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ess_num:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="content" label='规格备注' wrapperCol={{span:15}}>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ess_content:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal> 
    //修改规格-----------------------------------------------------------------------------------------------------------------------------修改规格
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {
        // console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    const [infoEdit,setInfoEdit] = useState();
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useGoodsSpecUpdateQuery({ess_id:editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                // console.log(dataEdit);
                editForm.current.setFieldsValue({
                    ess_name:dataEdit.Result.ess_name,
                    ess_content:dataEdit.Result.ess_content,
                    ess_price:dataEdit.Result.ess_price,
                    ess_num:dataEdit.Result.ess_num,
                })
                setInfoEdit(prevState=>({...prevState,
                    ess_name:dataEdit.Result.ess_name,
                    ess_content:dataEdit.Result.ess_content,              
                    ess_price:dataEdit.Result.ess_price,              
                    ess_num:dataEdit.Result.ess_num,              
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useUpdateSaveGoodsSpecMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改规格'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    // console.log('Validate Failed:', info);
                                });
                            submitUpdateSave({
                                ess_esv_id:param.get('id'),
                                ess_name:infoEdit.ess_name,
                                ess_content:infoEdit.ess_content,
                                ess_price:infoEdit.ess_price,
                                ess_num:infoEdit.ess_num,
                                ess_update_uid:localStorage.getItem('eu_id'),
                                ess_update_uname:localStorage.getItem('eu_name'),
                                ess_id:editId,
                            }).then((res) => {
                                // console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >
                            <Form.Item label='系统名称' wrapperCol={{span:10}} required>
                                {param.get('id')}
                            </Form.Item>
                            <Form.Item name="ess_name" label='规格名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ess_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="ess_price" label='价格' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ess_price:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="ess_num" label='年限' wrapperCol={{span:10}}  required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ess_num:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="ess_content" label='规格备注' wrapperCol={{span:15}}>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ess_content:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal>
    //删除规格-----------------------------------------------------------------------------------------------------------------------------删除规格
    const {confirm} = Modal; 
    const [submitDel] = useDelGoodsSpecMutation()
    //规格列表-----------------------------------------------------------------------------------------------------------------------------规格列表
    
    const {data,isSuccess} = useGoodsSpecListQuery({ess_esv_id:param.get('id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {ess_id:key,ess_name,ess_content,ess_uname,ess_ctime,ess_update_ctime,ess_update_uname,ess_num,ess_price,sub_num} = item;
                    return item = {key,ess_name,ess_content,ess_uname,ess_ctime,ess_update_ctime,ess_update_uname,ess_num,ess_price,sub_num}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ess_ctime;
                    let bTimeString = b.ess_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])       
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
       
        {
            key: 'type',
            title: '规格名称',
            dataIndex: 'ess_name',
        },
        {
            key: 'price',
            title: '价格',
            dataIndex: 'ess_price',
        },
        {
            key: 'year',
            title: '年限',
            dataIndex: 'ess_num',
        },       
        {
            key: 'room',
            title: '规格备注',
            dataIndex: 'ess_content',
        },       
        {
            key: 'num',
            title: '已购数量',
            dataIndex: 'sub_num',
        },       
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.ess_ctime + record.ess_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>                        
                        <a onClick={() => {
                            setEditId(record.key);
                            setOpenEdit(true)
                        }}>修改</a>
                        {record.sub_num === '0' && <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({ess_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                // console.log('Cancel');
                                },
                            });
                        }}>删除</a>}
                        {/* <a onClick={() => {navi( `/TypeSon?id=${param.get('id')}&name=${param.get('name')}&tid=${record.key}&tname=${record.ess_name}`, { state: {openKeys:'shop',active:"System"}})}}>子规格</a> */}
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main t1={param.get('name')} t2='规格表'>
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增</Button>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalAdd}
            {modalEdit}            
        </Main>
    )
}

export default AppSpec