import React,{useState,useEffect,useRef}from 'react'
import {Modal,message,Form,Input, DatePicker,} from 'antd';
import { usePhotoAlbumUpdateQuery, usePhotoAlbumUpdateSaveMutation } from '../store/ReactWebSiteApi';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;
function AlbumEdit(props) {
    const [form] = Form.useForm()
    const [open, setOpen] = useState(false);
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({
        epa_content:'',
        epa_zb_company:'',
        epa_xb_company:'',
    });
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = usePhotoAlbumUpdateQuery({epa_id:props.epa_id},{skip:!open})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    epa_name:dataEdit.Result.epa_name,
                    epa_content:dataEdit.Result.epa_content,
                    epa_group_name:dataEdit.Result.epa_group_name,
                    epa_zb_company:dataEdit.Result.epa_zb_company,
                    epa_xb_company:dataEdit.Result.epa_xb_company,
                    epa_date:[dayjs(dataEdit.Result.epa_s_date),dayjs(dataEdit.Result.epa_e_date)],
                })
                setInfo(prevState=>({...prevState,
                    epa_name:dataEdit.Result.epa_name,
                    epa_content:dataEdit.Result.epa_content,       
                    epa_group_name:dataEdit.Result.epa_group_name,
                    epa_zb_company:dataEdit.Result.epa_zb_company,
                    epa_xb_company:dataEdit.Result.epa_xb_company,
                    epa_s_date:dataEdit.Result.epa_s_date,
                    epa_e_date:dataEdit.Result.epa_e_date,       
                    epa_ebn_id:dataEdit.Result.epa_ebn_id,       
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = usePhotoAlbumUpdateSaveMutation()
    return (
        <>
            <a onClick={() => {setOpen(true)}}>修改</a>
            <Modal
                open={open}
                title='修改相册'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateEdit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitUpdateSave({
                        epa_name:info.epa_name,
                        epa_group_name:info.epa_group_name,                                
                        epa_zb_company:info.epa_zb_company,                                
                        epa_xb_company:info.epa_xb_company,                                
                        epa_s_date:info.epa_s_date,                                
                        epa_e_date:info.epa_e_date, 
                        epa_e_id:localStorage.getItem('e_id'),
                        epa_update_uid:localStorage.getItem('eu_id'),
                        epa_update_uname:localStorage.getItem('eu_name'),
                        epa_id:props.epa_id,
                        epa_ebn_id:info.epa_ebn_id,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')   
                            setOpen(false)                             
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                    ref={editForm}
                >
                    <Form.Item name="epa_name" label='相册名称' wrapperCol={{span:19}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,epa_name:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item name="epa_group_name" label='分组名称' wrapperCol={{span:10}} required>
                        <Input onChange={(e) => {setInfo(prevState=>({...prevState,epa_group_name:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item name="epa_date" label='起止日期' wrapperCol={{span:19}} required>
                        <RangePicker onChange={(e) => {setInfo(prevState=>({...prevState,epa_s_date:dayjs(e[0]).format('YYYY-MM-DD'),epa_e_date:dayjs(e[1]).format('YYYY-MM-DD')}))}} />
                    </Form.Item>
                    <Form.Item name="epa_zb_company" label='主办单位' wrapperCol={{span:19}}>
                        <Input onChange={(e) => {setInfo(prevState=>({...prevState,epa_zb_company:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item name="epa_xb_company" label='协办单位' wrapperCol={{span:19}}>
                        <Input onChange={(e) => {setInfo(prevState=>({...prevState,epa_xb_company:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default AlbumEdit