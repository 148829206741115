import React from 'react'
import Main from '../../../../../cpn/Main'
import Page from './Page'
function CustomPage() {
    return (
        <Main t1='定制游' n1='/CustomIndex' t2='模板' n2='/CustomTempIndex' t3='页面页脚'>
            <Page />
        </Main>
    )
}

export default CustomPage