import React  from 'react'
import Main from '../cpn/Main'
import {Row } from 'antd'
import Folder from '../cpn/Folder'
function GfIndexMall() {
    return (
        <Main t1='商城'>
            <Row>
                <Folder title='官网' link='/WebIndex'/>
                <Folder title='应用' link='/AppIndex'/>
                <Folder title='企业' link='/FirmIndex'/>
                <Folder title='商品' link='/CommodityIndex'/>                   
                <Folder title='平价点' link='/StudyEvaluatePointIndex'/>                    
                <Folder title='导游' link='/GuideIndex'/>
                <Folder title='地区' link='/AreaIndex'/>
            </Row>
        </Main>
    )
}

export default GfIndexMall