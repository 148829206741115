import React from 'react'
import { message,} from 'antd';
import {useGwGoodsStateMutation, } from '../../store/ReactWebSiteApi';
function WebGoodsAudit(props) {
    const [gwGoodsState] = useGwGoodsStateMutation()
    return (
        <>
            {(props.eowg_state==='1') ? <>
                                <a onClick={() => {
                                    gwGoodsState({eowg_id:props.eowg_id,eowg_state:'1'}).then((res) => {
                                        if(res.data.Status==='success'){
                                            message.info('下架成功！')
                                        }else{
                                            message.error('下架失败！' + res.data.Message)
                                        }
                                    })
                                }}>下架</a>
                            </>:<>
                                <a onClick={() => {
                                    gwGoodsState({eowg_id:props.eowg_id,eowg_state:'2'}).then((res) => {
                                        if(res.data.Status==='success'){
                                            message.info('上架成功！')
                                        }else{
                                            message.error('上架失败！' + res.data.Message)
                                        }
                                    })
                                }}>上架</a>
                            </>}
        </>
    )
}

export default WebGoodsAudit