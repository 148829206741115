import React,{useState,useEffect,useRef}from 'react'
import Main from '../cpn/Main'
import { Table,Button,Space,Modal,message,Form,Input,} from 'antd';
import {  useNavigate, useSearchParams, } from 'react-router-dom';
import { useAddGoodsSpecSupMutation,useDelGoodsSpecSupMutation, useGoodsSpecSubListQuery, useGoodsSpecSubUpdateQuery, useUpdateSaveGoodsSpecSupMutation } from '../store/ReactWebSiteApi';
import GetStaff from '../cpn/GetUser';
import MyEditor from '../cpn/MyEditor';
function Task() {
    const [param] = useSearchParams()
    //新增任务-----------------------------------------------------------------------------------------------------------------------------新增任务
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [infoAdd,setInfoAdd] = useState({
        esss_content:'',
    });
    const [submitAdd] = useAddGoodsSpecSupMutation()
    const modalAdd= <Modal
                        open={openAdd}
                        title='新增任务'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        width={1000}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClient(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitAdd({
                                esss_ess_id:param.get('tid'),
                                esss_name:infoAdd.esss_name,
                                esss_content:infoAdd.esss_content,
                                esss_uid:localStorage.getItem('eu_id'),
                                esss_uname:localStorage.getItem('eu_name'),
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpenAdd(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 7 }}
                        >
                            <Form.Item label='工作计划' wrapperCol={{span:10}} required>
                                <div>更换防盗门密码</div>
                            </Form.Item>
                            <GetStaff label='负责人' />  
                            <Form.Item name="name" label='任务名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,esss_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="content" label='任务备注' wrapperCol={{span:15}}>
                                <MyEditor onChange={(e) => {setInfoAdd(prevState=>({...prevState,en_content:e}))}} value={infoAdd.en_content}/>
                            </Form.Item>
                        </Form>
                    </Modal> 
    //修改任务-----------------------------------------------------------------------------------------------------------------------------修改任务
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    const [infoEdit,setInfoEdit] = useState();
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useGoodsSpecSubUpdateQuery({esss_id:editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    esss_name:dataEdit.Result.esss_name,
                    esss_content:dataEdit.Result.esss_content,
                })
                setInfoEdit(prevState=>({...prevState,
                    esss_name:dataEdit.Result.esss_name,
                    esss_content:dataEdit.Result.esss_content,              
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useUpdateSaveGoodsSpecSupMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改任务'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitUpdateSave({
                                esss_ess_id:param.get('id'),
                                esss_name:infoEdit.esss_name,
                                esss_content:infoEdit.esss_content,
                                esss_update_uid:localStorage.getItem('eu_id'),
                                esss_update_uname:localStorage.getItem('eu_name'),
                                esss_id:editId,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >
                            <Form.Item name="name" label='系统名称' wrapperCol={{span:10}} required>
                                {param.get('id')}
                            </Form.Item>
                            <Form.Item name="esss_name" label='任务名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,esss_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="esss_content" label='任务备注' wrapperCol={{span:15}}>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,esss_content:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal>
    //删除任务-----------------------------------------------------------------------------------------------------------------------------删除任务
    const {confirm} = Modal; 
    const [submitDel] = useDelGoodsSpecSupMutation()
    //任务列表-----------------------------------------------------------------------------------------------------------------------------任务列表
    
    const {data,isSuccess} = useGoodsSpecSubListQuery({esss_ess_id:param.get('tid')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {esss_id:key,esss_name,esss_content,esss_uname,esss_ctime,esss_update_ctime,esss_update_uname} = item;
                    return item = {key,esss_name,esss_content,esss_uname,esss_ctime,esss_update_ctime,esss_update_uname}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.esss_ctime;
                    let bTimeString = b.esss_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList([{key:'1',esss_name1:'张三',esss_name:'更换防盗门密码'}])
            }
            
        }
    }, [data, isSuccess])  
    const navi = useNavigate();  
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'name',
            title: '负责人',
            dataIndex: 'esss_name1',
        },
        {
            key: 'type',
            title: '任务名称',
            dataIndex: 'esss_name',
        },
        {
            key: 'room',
            title: '任务备注',
            dataIndex: 'esss_content',
        },       
        {
            key: 'back',
            title: '状态',
            dataIndex: 'esss_content',
        },       
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.esss_ctime + record.esss_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>                        
                        <a onClick={() => {
                            setEditId(record.key);
                            setOpenEdit(true)
                        }}>修改</a>
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({esss_id:record.key}).then((res) => {
                                        console.log(res);
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>
                        <a onClick={() =>{navi('/TrainPlan', { state: {openKeys:'performance',active:"TaskModel"}})}}>评价</a>
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main t1='人事管理' t2='绩效管理' t3='工作计划' t4='更换防盗门密码' t5='任务列表' >
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增任务</Button>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalAdd}
            {modalEdit}            
        </Main>
    )
}

export default Task