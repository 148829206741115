import React,{useState,useEffect,useRef}from 'react'
import { Table,Button,Space,Modal,message,Form, Input,} from 'antd';
import { useSearchParams, } from 'react-router-dom';
import GetStaff from '../cpn/GetUser';
import Main from '../cpn/Main'
import { useMyResponsibleListQuery, useTestAddMutation, useTestDelMutation,useTestUpdateQuery, useTestUpdateSaveMutation } from '../store/ReactWebSiteApi';
import { indexPersonnel } from '../finance/balance/balanceType';
const { TextArea } = Input;
function TrainCharge() {
    const [param] = useSearchParams()
    //新增任务-----------------------------------------------------------------------------------------------------------------------------新增任务
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [infoAdd,setInfoAdd] = useState()
    const getStaff = (e) => {
        setInfoAdd(prevState=>({...prevState,ewptr_user_id:e}))
    }
    const [submitAdd] = useTestAddMutation()
    const modalAdd= <Modal
                        open={openAdd}
                        title='新增任务'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClient(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitAdd({
                                ewptr_ewp_id:param.get('id'),
                                ewptr_user_id:infoAdd.ewptr_user_id,
                                ewptr_content:infoAdd.ewptr_content,
                                ewptr_uid:localStorage.getItem('eu_id'),
                                ewptr_uname:localStorage.getItem('eu_name'),
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpenAdd(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                        >
                            <GetStaff label='被培训人' getStaff={getStaff}/>                            
                            <Form.Item labelCol={{ span: 5 }} label='备注' name='content'>
                                <TextArea rows={5} onChange={(e) => {setInfoAdd(prevState=>({...prevState,ewptr_content:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal> 
    //修改任务-----------------------------------------------------------------------------------------------------------------------------修改任务
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    
    const [infoEdit,setInfoEdit] = useState({name:''});
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useTestUpdateQuery({ewptr_id:editId},{skip:!editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);                
                editForm.current.setFieldsValue({                    
                    ewptr_content:dataEdit.Result.ewptr_content,
                })
                setInfoEdit(prevState=>({...prevState,
                    ewptr_content:dataEdit.Result.ewptr_content,       
                    ewptr_user_id:dataEdit.Result.ewptr_user_id,       
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useTestUpdateSaveMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改培训任务'
                        okText="提交"
                        cancelText="取消"
                        width={1000}
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitUpdateSave({
                                ewptr_id:editId,                               
                                ewptr_content:infoEdit.ewptr_content,
                                ewptr_update_uid:localStorage.getItem('eu_id'),
                                ewptr_update_uname:localStorage.getItem('eu_name'),
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >
                            <Form.Item labelCol={{ span: 5 }} label='被培训人' >
                                <div>{infoEdit.eu_name}</div>
                            </Form.Item>                            
                            <Form.Item labelCol={{ span: 5 }} label='备注' name='ewptr_content'>
                                <TextArea rows={5} onChange={(e) => {setInfoAdd(prevState=>({...prevState,ewptr_content:e.target.value}))}}/>
                            </Form.Item>                            
                        </Form>
                    </Modal>
    //删除任务-----------------------------------------------------------------------------------------------------------------------------删除任务
    const {confirm} = Modal; 
    const [submitDel] = useTestDelMutation()
    //任务列表-----------------------------------------------------------------------------------------------------------------------------任务列表
    
    const {data,isSuccess} = useMyResponsibleListQuery({ewptr_uid:localStorage.getItem('eu_id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {ewptr_id:key,eu_name,eu_ba_phone,ewptr_uname,ewptr_ctime,ewptr_update_ctime,ewptr_update_uname,ewptr_content,ewptr_result,ewptr_evaluate_content,cs_num,ewptr_evaluate_uname,ewptr_evaluate_ctime} = item;
                    return item = {key,eu_name,eu_ba_phone,ewptr_uname,ewptr_ctime,ewptr_update_ctime,ewptr_update_uname,ewptr_content,ewptr_result,ewptr_evaluate_content,cs_num,ewptr_evaluate_uname,ewptr_evaluate_ctime}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ewptr_ctime;
                    let bTimeString = b.ewptr_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])  
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
       
        {
            key: 'type',
            title: '被培训人',
            render:(_,record) => {
                return<>{record.ewptr_uname}</>
            }
        },
        {
            key: 'room',
            title: '备注',
            dataIndex: 'ewptr_content',
        },       
        {
            key: 'status',
            title: '状态',
            render:(_,record) => {
                if(!record.ewptr_result){
                    return<>
                    <div>待评价，共{record.cs_num}个</div>                   
                </>
                }else{
                    return<>已评价，正确{record.ewptr_result}个，共{record.cs_num}个</>
                }
            }
        },  
        {
            key: 'remark',
            title: '评价备注',
            dataIndex: 'ewptr_evaluate_content',
        },         
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.ewptr_ctime + record.ewptr_uname}</div>
                    <div>修改时间：{record.ewptr_update_ctime + record.ewptr_update_uname}</div>
                    <div>评价时间：{record.ewptr_evaluate_ctime + record.ewptr_evaluate_uname}</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>
                        <a onClick={() => {
                            setOpenEdit(true);
                            setEditId(record.key);
                            setInfoEdit(prevState=>({...prevState,eu_name:record.eu_name}))

                            }}>修改</a>     
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({ewptr_id:record.key}).then((res) => {
                                        console.log(res);
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>                        
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main  t1='人事' n1={indexPersonnel} t2='培训' n2='/TrainIndex' t3='我负责的'>
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增培训任务</Button>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalAdd}  
            {modalEdit}     
        </Main>
    )
}

export default TrainCharge