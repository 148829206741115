import React,{useState,useEffect}from 'react'
import Main from '../../cpn/Main'
import { Table,} from 'antd';
import { useSpareListQuery } from '../../store/ReactWebSiteFinanceApi';
function PettyCash() {
    
    const {data, isSuccess} = useSpareListQuery({ec_e_id:localStorage.getItem('e_id')});
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if (isSuccess) {
            //console.log(data);
            if(data.Status==='success'){
                
                const newArr = data.Result.map(item => {
                    const {ec_id:key,byj_amount,ec_name} = item;
                    return item = {key,byj_amount,ec_name}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.byj_amount;
                    let bTimeString = b.byj_amount;
                    return bTimeString - aTimeString;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    const columns = [
        {
            key: 'id',
            title: '客户编号',
            dataIndex: 'key'
        },      
        {
            key: 'client',
            title: '客户',
            dataIndex: 'ec_name'
        },
        {
            key: 'min',
            title: '备用金余额',
            dataIndex: 'byj_amount',
        },
    ]

      
    return (
        <Main t1='财务管理' t2='收支管理' t3='备用金'>            
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table>
        </Main>
    )
}

export default PettyCash