import React,{useState,useEffect,}from 'react'
import Main from '../cpn/Main'
import { Table,Space,Modal,} from 'antd';
import {usePhotoAlbumGroupListQuery,} from '../store/ReactWebSiteApi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AlbumSectionAdd from './AlbumSectionAdd';
import AlbumSectionEdit from './AlbumSectionEdit';
import AlbumSectionDel from './AlbumSectionDel';
import AlbumSectionZip from './AlbumSectionZip';
function AlbumSection() {
    const [param] = useSearchParams()
    
    const {data,isSuccess} = usePhotoAlbumGroupListQuery({epag_epa_id:param.get('id'),})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){     
            //console.log(data);       
            if(data.Status==='success'){                
                
                const newArr = data.Result.map(item => {
                    const {epag_id:key,epag_name,epag_content,epag_uname,epag_ctime,epag_update_ctime,epag_update_uname,epag_zip,brower_num,click_num,image_num,image_size,} = item;
                    return item = {key,epag_name,epag_content,epag_uname,epag_ctime,epag_update_ctime,epag_update_uname,epag_zip,brower_num,click_num,image_num,image_size}
                })                
                setList(newArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])  
    const navi = useNavigate();    
    const columns = [
        // {
        //     key: 'id',
        //     title: '编号',
        //     dataIndex: 'key'
        // },
       
        {
            key: 'type',
            title: '名称',
            dataIndex: 'epag_name',
        },                                          
        {
            key: 'image_num',
            title: '图片数量',
            dataIndex:'image_num',
        },                                          
        {
            key: 'image_size',
            title: '占用空间',
            dataIndex:'image_size',
        },
        {
            key: 'brower_num',
            title: '浏览',
            dataIndex:'brower_num',
        },                                          
        {
            key: 'click_num',
            title: '点赞',
            dataIndex:'click_num',
        },                                                   
        {
            key: 'epag_zip',
            title: '是否压缩',
            dataIndex: 'epag_zip',
            render:(text) => {
                if(text){
                    return <>已压缩</>
                }else{
                    return <>未压缩</>
                }
            }
        },                                          
        // {
        //     key: 'time',
        //     title: '操作记录',
        //     render: (_,record) => {
        //         return <>
        //             <div>新建时间：{record.epag_ctime + record.epag_uname}</div>
        //             <div>修改时间：{record.epag_update_ctime + record.epag_update_uname}</div>
        //         </>
        //     }
        // },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <Space >                        
                        <AlbumSectionEdit epag_id={record.key}  epag_epa_id={param.get('id')}/>
                        <AlbumSectionDel  epag_id={record.key} />
                        <a onClick={() => {navi(`/AlbumPic?esv_id=7&gid=${record.key}&aid=${param.get('id')}&aname=${param.get('name')}&gname=${record.epag_name}`)}}>照片</a>
                        <a onClick={() => {navi(`/AlbumMan?esv_id=7&gid=${record.key}&gname=${record.epag_name}&aid=${param.get('id')}&aname=${param.get('name')}`)}}>研学导师</a>
                        <a onClick={() => {setOpenPreview(true);setModalInfo('')}}>预览相册</a>
                        <AlbumSectionZip 
                            epa_id={param.get('id')}
                            epa_name={param.get('name')}
                            epag_id={record.key}
                            epag_name={record.epag_name}
                        />                        
                        {record.epag_zip && <a href={`http://api.zzddww.com/${record.epag_zip}`}>下载压缩包</a>}
                    </Space>
            }
        },
    ]
    
    //预览相册
    const [modalInfo,setModalInfo] = useState({
        esv_remark:'',
    })
    const [openPreview, setOpenPreview] = useState(false);
    const Ok = () => {setOpenPreview(false);};
    const Cancel = () => {setOpenPreview(false);};
    const modalPreview = <Modal title='预览相册' open={openPreview} onOk={Ok} onCancel={Cancel} okText='关闭'>
                            <div>
                                <div style={{padding:'10px'}}>
                                    <div style={{marginTop:'10px'}}>
                                        <div dangerouslySetInnerHTML={{__html:modalInfo.esv_remark}} style={{maxWidth:"470px",overflow:"hidden"}}></div>
                                    </div>
                                </div>
                            </div>                
                        </Modal>

    return (
        <Main t1='团队列表' n1='/AlbumGroup?esv_id=7' t2={`团号${param.get('ebn_team_num')}`} t3='相册' t4={param.get('name')} t5='分组'>
            <div>
                <AlbumSectionAdd epag_epa_id={param.get('id')}/>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalPreview}         
        </Main>
    )
}

export default AlbumSection