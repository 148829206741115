import React,{useState,useEffect,useRef}from 'react'
import { Modal,message,Form,Input} from 'antd';
import {useTourGuideUpdateQuery, useTourGuideUpdateSaveMutation } from '../../store/ReactWebSiteApi';

function GuideEdit(props) {
    const [form] = Form.useForm(); 
    const [open, setOpen] = useState(false);
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({name:''});
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useTourGuideUpdateQuery({tgu_id:info.tgu_id},{skip:!info.tgu_id})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);                
                editForm.current.setFieldsValue({
                    tgu_name:dataEdit.Result.tgu_name,                  
                    tgu_phone:dataEdit.Result.tgu_phone,                  
                })
                setInfo(prevState=>({...prevState,
                    tgu_name:dataEdit.Result.tgu_name,                  
                    tgu_phone:dataEdit.Result.tgu_phone,      
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useTourGuideUpdateSaveMutation()
    return (
        <>
            <a 
                onClick={() => {
                    setOpen(true);
                    setInfo(prevState=>({...prevState,tgu_id:props.tgu_id}))
                }}>修改</a>
            <Modal
                open={open}
                title='修改导游'
                okText="提交"
                cancelText="取消"
                width={1000}
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateEdit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitUpdateSave({
                        tgu_phone:info.tgu_phone,
                        tgu_name:info.tgu_name,
                        tgu_uid:localStorage.getItem('eu_id'),
                        tgu_uname:localStorage.getItem('eu_name'),
                        tgu_id:info.tgu_id,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')   
                            setOpen(false)                             
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                    ref={editForm}
                >
                    
                    <Form.Item name="tgu_name" label='导游名称' wrapperCol={{span:10}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,tgu_name:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item name="tgu_phone" label='手机号码' wrapperCol={{span:10}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,tgu_phone:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default GuideEdit