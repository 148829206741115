import React  from 'react'
import Main from '../../cpn/Main'
import {Row } from 'antd'
import Folder from '../../cpn/Folder'
function TaFinanceSetIndex() {
    return (
        <Main t1='财务' n1='/TaFinanceIndex' t2='设置'>
            <Row>                
                <Folder title='客户' link='/TaFinanceClient'/> 
                <Folder title='客户类型' link='/TaFinanceClientType'/> 
                <Folder title='产品' link='/TaFinanceGoods'/> 
                <Folder title='产品类型' link='/TaFinanceGoodsType'/> 
                <Folder title='团队收入单类型' link='/TaFinanceLedgerIncomeType'/> 
                <Folder title='团队支出单类型' link='/TaFinanceLedgerCostType'/> 
                <Folder title='费用支出单类型' link='/TaFinanceExpenseType'/> 
                <Folder title='其他收入单类型' link='/TaFinanceElseIncomeType'/> 
                <Folder title='其他支出单类型' link='/TaFinanceElseCostType'/> 
                <Folder title='资金账户' link='/TaFinanceAccount'/> 
                <Folder title='团队报账单出发日期' link='/TaFinanceLedgerDate'/> 
            </Row>
        </Main>
    )
}

export default TaFinanceSetIndex