import React,{useState,useEffect,useRef}from 'react'
import { Modal,message,Form,Input,} from 'antd';
import { usePhotoAlbumGroupUpdateQuery, usePhotoAlbumGroupUpdateSaveMutation,} from '../store/ReactWebSiteApi';
function AlbumSectionEdit(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState();
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = usePhotoAlbumGroupUpdateQuery({epag_id:props.epag_id},{skip:!open})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    epag_name:dataEdit.Result.epag_name,
                    epag_content:dataEdit.Result.epag_content,
                })
                setInfo(prevState=>({...prevState,
                    epag_name:dataEdit.Result.epag_name,
                    epag_content:dataEdit.Result.epag_content,              
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = usePhotoAlbumGroupUpdateSaveMutation()
    return (
        <>
            <a onClick={() => {setOpen(true)}}>修改</a>
            <Modal
                open={open}
                title='修改分组'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateEdit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitUpdateSave({
                        epag_name:info.epag_name,
                        epag_epa_id:props.epag_epa_id,
                        epag_update_uid:localStorage.getItem('eu_id'),
                        epag_update_uname:localStorage.getItem('eu_name'),
                        epag_id:props.epag_id,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')   
                            setOpen(false)                             
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                    ref={editForm}
                >
                    <Form.Item name="epag_name" label='分组名称' wrapperCol={{span:19}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,epag_name:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default AlbumSectionEdit