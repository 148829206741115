import React, { useState ,useEffect} from 'react'
import { useGetGoodsIDQuery, } from '../../store/ReactWebSiteApi';
import { Button } from 'antd';
import {WechatOutlined} from '@ant-design/icons';
function TaCourseCheck(props) {
    const [info,setInfo] = useState({eowg_content:<div>无</div>});
    const {data,isSuccess} = useGetGoodsIDQuery({eowg_key:props.eowg_key},{skip:!props.eowg_key});
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                setInfo(data.Result)
            }
        }else{
            setInfo({eowg_content:<div>无</div>})
        }
    },[data,isSuccess])
    return (
        <div  style={{padding:"20px 300px 20px 20px",fontSize:"16px",lineHeight:"30px"}}>
            {info ? <div dangerouslySetInnerHTML={{__html:info.eowg_content}}></div> : <></>}
            <div style={{textAlign:'center',margin:"50px 0"}}>
                <Button type='primary' size='large' onClick={() => {window.open('https://work.weixin.qq.com/kfid/kfcc1a9500fb365f216')}}><WechatOutlined /> 不懂就问</Button>
            </div>
        </div>
    )
}

export default TaCourseCheck