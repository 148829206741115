import React from 'react'
import Main from '../../cpn/Main'
import {Row} from 'antd'
import Folder from '../../cpn/Folder'

function LedgerTable() {    
    return (
        <Main  t1='财务' n1='/TaFinanceIndex' t2='团队收支' n2='/LedgerIndex' t3='报表'>
            <Row>                
                <Folder title='部门利润表'  link='/LedgerSumDept' />            
                <Folder title='客户利润表'  link='/LedgerSumClient' />            
                <Folder title='客户类型利润表'  link='/LedgerSumClientType' />            
                <Folder title='产品利润表'  link='/LedgerSumGoods' />            
                <Folder title='产品类型利润表'  link='/LedgerSumGoodsType' />            
                <Folder title='操作员利润表'  link='/LedgerSumOperator' />            
                <Folder title='销售员利润表'  link='/LedgerSumSalesman' />  
                <Folder title='待下账部门表'  link='/LedgerArapDept' />            
                <Folder title='待下账客户表'  link='/LedgerArapClient' />          
                <Folder title='待下账客户对账表'  link='/LedgerArapStatement' />           
            </Row>
        </Main>
    )
}

export default LedgerTable