import React,{useEffect, useState}from 'react'
import { Modal,message,Form,Input,Space, InputNumber, Divider} from 'antd';
import { useDijieBaoJiaDanFeiYongMingXiAddMutation, useDijieBaoJiaDanGuiGeListQuery,  } from '../../../../store/ReactWebSiteApi';
import typeName from './typeName';
import CustomPrice from '../temp/price/CustomPrice';
function FilePriceAdd(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({
        bjdfymx_price:0,
        bjdfymx_num:0,
        ltfymx_type_id:''
    });
    const [submitAdd] = useDijieBaoJiaDanFeiYongMingXiAddMutation()
    useEffect(() => {
        
    },[info.bjdfymx_name, info.bjdfymx_type_id])
    //获取人群类型列表
    const [list,setList] = useState([])
    const [initialValues,setInitialValues] = useState()
    const [ggInfo,setGgInfo] = useState()
    const {data,isSuccess} = useDijieBaoJiaDanGuiGeListQuery({bjdgg_bjd_id:props.bjdfymx_bjd_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log('guige',data);
                const arr = data.Result.map((item) => {
                    const {...obj} = item;
                    return item = obj
                })
                setList(arr)
                //设置表单默认值
                const object = data.Result.reduce((acc, curr) => {
                    acc['ggprice' + curr.bjdgg_id] = ''; // 初始化为空字符串，或者其他你想设置的值
                    acc['ggDesc' + curr.bjdgg_id] = ''; // 初始化为空字符串，或者其他你想设置的值
                    return acc;
                  }, {});               
                setInitialValues(object)
                //设置人群类型id数组
                let namesArray = data.Result.map(obj => obj.bjdgg_id);
                let namesArray2 = data.Result.map(obj => obj.bjdgg_name);
                setGgInfo({ggid:namesArray,ggname:namesArray2})
            }else{
                setList([])
            }
        }
    },[data,isSuccess])
    //模版
    const [openTemplate,setOpenTemplate] = useState(false)
    const getTemplate = <>
            <Form.Item  label='请选择' required>
                <a onClick={() => {setOpenTemplate(true)}}>费用明细模板</a>               
            </Form.Item>        
            <Modal
                open={openTemplate}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenTemplate(false);}}
                onOk={() => {}}
                footer={null}
                width={1000}
                title='费用明细模板'
            >
                <CustomPrice
                    get={true}
                    ltbjd_dept_id={props.ltbjd_dept_id}
                    getPrice={(e) => {
                        const type = typeName(e.ltfymx_type_id)
                        setInfo(prevState=>({
                            ...prevState,
                            bjdfymx_type_id:e.ltfymx_type_id,
                            bjdfymx_name:e.ltfymx_name,
                            bjdfymx_price:e.ltfymx_price,
                            bjdfymx_unit:e.ltfymx_unit,
                            type,
                        }))
                    }}
                    getOpen={(e) => {setOpenTemplate(e)}}
                />
            </Modal>
        </>
    return (
        <>
            <a onClick={() => {setOpen(true)}} >新增</a>
            <Modal
                open={open}
                title='新增费用明细'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={800}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                            let arrprice = []
                            for (let key in values) {
                                if (values.hasOwnProperty(key)) { // 确保key是对象自身的属性
                                    if(key.includes('ggprice')){
                                        arrprice.push(values[key])
                                    }
                                }
                            }
                            let arrdesc = []
                            for (let key in values) {
                                if (values.hasOwnProperty(key)) { // 确保key是对象自身的属性
                                    if(key.includes('ggDesc')){
                                        arrdesc.push(values[key])
                                    }
                                }
                            }
                            submitAdd({
                                bjdfymx_bjd_id:props.bjdfymx_bjd_id,
                                bjdfymx_type_id:info.bjdfymx_type_id,
                                bjdfymx_name:info.bjdfymx_name,
                                bjdfymx_price:info.bjdfymx_price,
                                bjdfymx_unit:info.bjdfymx_unit,
                                bjdfymx_num:info.bjdfymx_num,
                                bjdfymx_uid:localStorage.getItem('eu_id'),
                                bjdfymx_uname:localStorage.getItem('eu_name'),
                                egg_id:ggInfo.ggid,
                                egg_name:ggInfo.ggname,
                                bjdfymxpt_pingtanjiage:arrprice,
                                bjdfymxpt_pingtanshuiming:arrdesc,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpen(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={initialValues}
                    labelCol={{ span: 3 }}
                >                                           
                    {getTemplate}
                    <Form.Item  label='费用类型' wrapperCol={{span:15}} >
                        {info.type}
                    </Form.Item>
                    <Form.Item label='费用名称' wrapperCol={{span:10}} >
                        {info.bjdfymx_name}
                    </Form.Item>
                    <Form.Item  label='单价' style={{marginBottom:"0"}}>
                        <Space>
                            <Form.Item style={{display:'inline-block', width: '70px'}}>
                                {info.bjdfymx_price}
                            </Form.Item>
                            <Form.Item label='单位' style={{display:'inline-block', width: '80px'}}>
                                {info.bjdfymx_unit}  
                            </Form.Item>
                            <Form.Item name="num" label='数量'  style={{display:'inline-block', width: '160px'}}>
                                <InputNumber  onChange={(e) => {setInfo(prevState=>({...prevState,bjdfymx_num:e}))}}/>
                            </Form.Item>
                            <Form.Item>合计：{info.bjdfymx_price*info.bjdfymx_num} 元</Form.Item>
                        </Space>
                    </Form.Item>
                    <Divider>按人均计算</Divider>
                    {list.map((item) => {
                        return <Form.Item key={'gg'+item.bjdgg_id} label={item.bjdgg_name+'单价'} style={{marginBottom:0}}>
                            <Space>
                                <Form.Item name={'ggprice'+item.bjdgg_id}>
                                    <InputNumber  style={{display:'inline-block', width: '100px'}}/>
                                </Form.Item>
                                <Form.Item  name={'ggDesc'+item.bjdgg_id} label='计算说明'  style={{width:"400px"}} initialValue=''>
                                    <Input/>
                                </Form.Item>
                            </Space>
                        </Form.Item>
                    })}
                </Form>
            </Modal>
        </>
    )
}

export default FilePriceAdd