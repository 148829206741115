import React from 'react'
import {Modal} from 'antd'

function ModalDel(props) {
    const {confirm} = Modal; 
        return (
        <a onClick={() => {
            confirm({
                title: '确定删除吗?',
                content: '删除后不能恢复',
                okText: '确认',
                okType: 'danger',
                cancelText: '取消',
                onOk() {props.onOk()},
                onCancel() {
                    // console.log('Cancel');
                },
            }); 
        }}>删除</a>
    )
}

export default ModalDel