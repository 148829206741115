import React from 'react'
import Main from '../../cpn/Main'
import {Row} from 'antd'
import Folder from '../../cpn/Folder'
import { indexFinance } from '../balance/balanceType'

function ExpenseTable() {    
    return (
        <Main  t1='财务' n1={indexFinance} t2='费用支出' n2='/ExpenseIndex' t3='报表'>
            <Row>                
                <Folder title='部门费用表'  link='/ExpenseSumDept' />            
                <Folder title='客户费用表'  link='/ExpenseSumClient' />            
                <Folder title='类型费用表'  link='/ExpenseSumType' />            
                <Folder title='部门利润表'  link='/ExpenseProfitDept' />            
            </Row>

        </Main>
    )
}

export default ExpenseTable