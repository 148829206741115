import React,{useState,useEffect,}from 'react'
import { Form,Select,Space} from 'antd';
import {useGwGoodsTypeListQuery, useGwGoodsTypeSubListQuery, } from '../../store/ReactWebSiteApi';

function GetType(props) {    
    const [type, setType] = useState([]);       
    const [typeId,setTypeId] = useState(props.subname);
    const [subtype,setSubtype] = useState();
    const {data,isSuccess} = useGwGoodsTypeListQuery({eowgt_e_id:localStorage.getItem('e_id')})
    const {data:dataSub,isSuccess:isSuccessSub} = useGwGoodsTypeSubListQuery({eowgt_id:typeId},{refetchOnMountOrArgChange:1})
    useEffect(() => {        
        if(isSuccess){          
            if(data.Status==='success'){   
                console.log(data);             
                const arr = data.Result.map((item) => {
                    const {eowgt_id:key,eowgt_name} = item;                    
                    return item = {value:key,label:eowgt_name}                     
                })
                setType(arr)         
            }          
        }
        
    }, [data, isSuccess,])   
    useEffect(() => {
        if(isSuccessSub){          
            if(dataSub.Status==='success'){ 
                const arr = dataSub.Result.map((item) => {
                    const {eowgts_id:key,eowgts_name} = item;                    
                    return item= {value:key,label:eowgts_name}                     
                })
                setSubtype(arr)         
            }            
        }
    },[dataSub,isSuccessSub,])
    return (
        <>
            <Form.Item 
                label={props.label} 
                required={props.required} 
                     
            >
                <Space>
                    <Form.Item  noStyle name={props.name} >
                        <Select 
                            options={type} 
                            onChange={(e) => {setTypeId(e)}}
                            style={{width:"200px"}}
                        />
                    </Form.Item>
                    <Form.Item  noStyle name={props.subname} >
                        <Select 
                            options={subtype} 
                            onChange={(e) => {
                                // console.log(f);
                                props.getType(typeId,e)
                            }}
                            style={{width:"200px"}}
                        />
                    </Form.Item>
                </Space>
            </Form.Item>
        </>
    )
}

export default GetType