import React,{useState,useEffect,} from 'react'
import {Descriptions, Space,} from 'antd'
import {useBusinessNumUpdateQuery, } from '../../store/ReactWebSiteApi';
import LedgerEdit from './LedgerGroupEdit';
import LedgerDel from './LedgerGroupDel';
import LedgerGroupAudit from './LedgerGroupAudit';
function LedgerGroupInfo(props) {
    // console.log(props,'props');
    const [info,setInfo] = useState({
        profit:'',
        cost:'',
        ebn_update_ctime:"",
        ebn_update_uname:"",
        guige:'',
        ec_name:'',
        ed_name:'',
        eg_name:'',
        ebn_date:'',
        ebn_uname:'',
        ebn_content:'',
        ebn_ctime:'',
        ebn_audit_uname:'',
        ebn_audit_ctime:'',
        ebn_audit2_ctime:'',
        ebn_audit2_uname:'',                    
        ebn_yw_name:'',
    })
    const {data,isSuccess} = useBusinessNumUpdateQuery({ebn_id:props.ebn_id},{refetchOnMountOrArgChange:1})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                // console.log(data);
                let profit =data.Result.profit.toFixed(2)
                let arr = []
                if(data.Result.guige){
                     arr = data.Result.guige.map((item) => {
                        return <span key={item.egg_id}>{item.egg_name}{item.guigeNum}</span>
                    })
                }
                setInfo(prevState=>({...prevState,
                    ec_name:data.Result.ec_name,
                    ed_name:data.Result.ed_name,
                    eg_name:data.Result.eg_name,
                    ebn_date:data.Result.ebn_date,
                    ebn_uname:data.Result.ebn_uname,
                    ebn_content:data.Result.ebn_content,
                    ebn_ctime:data.Result.ebn_ctime,
                    ebn_update_ctime:data.Result.ebn_update_ctime,
                    ebn_update_uname:data.Result.ebn_update_uname,
                    ebn_audit_uname:data.Result.ebn_audit_uname,
                    ebn_audit_ctime:data.Result.ebn_audit_ctime,
                    ebn_audit2_ctime:data.Result.ebn_audit2_ctime,
                    ebn_audit2_uname:data.Result.ebn_audit2_uname,                    
                    ebn_yw_name:data.Result.ebn_yw_name,
                    guige:arr,                      
                    profit,                    
                }))
            }
        }
    },[data,isSuccess,props])
    const items = [
        
        {
            key: '1',
            label: '团号',
            children: props.ebn_team_num,            
        },
        {
            key: '2',
            label: '客户',
            children: info.ec_name,
        },
        {
            key: '8',
            label: '操作员',
            children:  info.ebn_ctime + info.ebn_uname,
        },
        {
            key: '3',
            label: '出发日期',
            children: info.ebn_date,
        },
        {
            key: '4',
            label: '产品',
            children: info.eg_name,
        },    
        {
            key: '10',
            label: '审核',
            children: <>{info.ebn_audit_ctime && <>{info.ebn_audit_ctime + info.ebn_audit_uname}</>}</>,
        },    
        {
            key: '12',
            label: '部门',
            children: info.ed_name,
        },
        {
            key: '5',
            label: '备注',
            children: info.ebn_content,
        },
        {
            key: '11',
            label: '复核',
            children:<>{info.ebn_audit2_ctime && <>{info.ebn_audit2_ctime + info.ebn_audit2_uname}</>}</>,
        },
        {
            key: '7',
            label: '销售员',
            children: info.ebn_yw_name,
        },
        {
            key: '6',
            label: '利润',
            children: info.profit,
        },
        {
            key: '13',
            label: '数量',
            children: <Space>{info.guige}</Space>
        },        
        {
            key: '12',
            label: '操作',
            children:   <Space>                            
                            <LedgerEdit 
                                ebn_id={props.ebn_id}  
                                ebn_dept_id={props.ebn_dept_id}
                                ebn_audit_ctime={info.ebn_audit_ctime} 
                            />
                            <LedgerDel  
                                ebn_id={props.ebn_id}
                                ebn_audit_ctime={info.ebn_audit_ctime} 
                            />
                            <LedgerGroupAudit 
                                ebn_audit2_ctime={info.ebn_audit2_ctime}
                                ebn_audit_ctime={info.ebn_audit_ctime} 
                                ebn_id={props.ebn_id}
                            />
                        </Space>,
        },
    ]
   
    return (
        <>
            <Descriptions
                bordered
                size='small' 
                items={items} 
            />
        </>
        
    )
}

export default LedgerGroupInfo