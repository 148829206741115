import { Descriptions, Flex, Modal, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import GuidePlanAgent from './GuidePlanAgent';
import { useDaoyoujihuadanUpdateQuery } from '../../../../../store/ReactWebSiteApi';
import GuidePlanRoute from './GuidePlanRoute';

function GuidePlanCheck(props) {
    const [open, setOpen] = useState(false);   
    const [info,setInfo] = useState({
        dyjhd_tgu_name:''
    });
    //获取
    const {data,isSuccess} = useDaoyoujihuadanUpdateQuery({dyjhd_id:props.dyjhd_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){        
                setInfo(prevsState=>({...prevsState,
                    dyjhd_tgu_name:data.Result.dyjhd_tgu_name,                         
                    dyjhd_jietuanshijian:data.Result.dyjhd_jietuanshijian,                         
                    dyjhd_songtuanshijian:data.Result.dyjhd_songtuanshijian,                   
                    dyjhd_jietuandidian:data.Result.dyjhd_jietuandidian,                   
                    dyjhd_songtuandidian:data.Result.dyjhd_songtuandidian,       
                    dyjhd_jietuanshuoming:data.Result.dyjhd_jietuanshuoming,       
                    dyjhd_daofufei:data.Result.dyjhd_daofufei,       
                    dyjhd_daofufeishuoming:data.Result.dyjhd_daofufeishuoming,       
                    dyjhd_lth_num:data.Result.dyjhd_lth_num,       
                }))
            }
        }
    },[data,isSuccess,])
    //基本信息
    const items = [        
        {
            key: '1',
            label: '旅行社',
            children: localStorage.getItem('e_name'),
            labelStyle:{width:'90px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '2',
            label: '接团时间',
            children: info.dyjhd_jietuanshijian,
            labelStyle:{width:'90px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '3',
            label: '接团地点',
            children:info.dyjhd_jietuandidian,
            labelStyle:{width:'120px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '4',
            label: '导游',
            children: info.dyjhd_tgu_name,
            labelStyle:{width:'90px'},
            contentStyle:{fontSize:"16px"}
        },   
        {
            key: '5',
            label: '送团时间',
            children: info.dyjhd_songtuanshijian,
            labelStyle:{width:'90px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '6',
            label: '送团地点',
            children:info.dyjhd_songtuandidian,
            labelStyle:{width:'90px'},
            contentStyle:{fontSize:"16px"}
        },                          
                
        {
            key: '9',
            label: '线路名称',
            children: '大小三峡三日游',
            labelStyle:{width:'100px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '8',
            label: '分车号',
            children: '1',
            labelStyle:{width:'90px'},
            contentStyle:{fontSize:"16px"}
        },            
        {
            key: '6',
            label: '团号',
            children:info.dyjhd_lth_num,
            contentStyle:{fontSize:"16px"},
        },
        {
            key: '7',
            label: '接团说明',
            children: info.dyjhd_jietuanshuoming,
            labelStyle:{width:'90px'},
            contentStyle:{fontSize:"16px"},
            span:3
        },
        {
            key: '8',
            label: '导服费',
            children: info.dyjhd_daofufei,
            labelStyle:{width:'100px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '9',
            label: '导服说明',
            children: info.dyjhd_daofufeishuoming,
            labelStyle:{width:'100px'},
            contentStyle:{fontSize:"16px"}
        },                        
        {
            key: '10',
            label: '操作',
            contentStyle:{fontSize:"16px"},
            children:<Space></Space>,
        },
        {
            key: '11',
            label: '附件',
            contentStyle:{fontSize:"16px"},
            children:<Space></Space>,
        },
    ]
    return (
        <>
            <a  onClick={() => {
                setOpen(true);
                }} >{props.dyjhd_tgu_name}</a>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false)}}
                width={1200}
                footer={null}
            >
                <div style={{border:'2px solid #eee',margin:"10px"}}>
                    <Flex 
                        justify='center' 
                        style={{fontSize:'30px',lineHeight:"88px",fontWeight:'bold'}}
                    >导游计划单</Flex>
                    <Descriptions
                        items={items} 
                        bordered 
                        size='small' 
                        labelStyle={{fontSize:"16px"}}
                    />
                    <GuidePlanRoute 
                        dyjhdxc_jhd_id={props.dyjhd_id}
                    />
                    <GuidePlanAgent />
                </div>
            </Modal>
        </>
    )
}

export default GuidePlanCheck