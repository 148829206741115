import React,{useState,useEffect,useRef}from 'react'
import { Table,Space,Modal,message,Form,Input, Radio, Checkbox,} from 'antd';
import Main from '../cpn/Main'
import { useMypxEvaluateQuery, useMypxEvaluateSaveMutation, useMypxListQuery, } from '../store/ReactWebSiteApi';
import TrainPlanCheck from './TrainPlanCheck';
import { indexPersonnel } from '../finance/balance/balanceType';
const { TextArea } = Input;
function TrainJoin() {
    const [form] = Form.useForm();   
    //评价-----------------------------------------------------------------------------------------------------------------------------评价
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState();
    const [infoEdit,setInfoEdit] = useState({ewpter_ewp_id:'',});
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        const val = Object.values(values).join(':')
        // console.log(Object.values(values).join(':'));
        //console.log(rightAnswer);
        
        const arr = Object.keys(values).map(key => values[key]);
        // console.log(arr);
        const newArr = rightAnswer.map((item,index) => {
            if(typeof arr[index]==='number'){
                if(arr[index]===item.ewpt_answer_value.indexOf('1')+1){
                    return '1'
                }else{
                    return '0'
                }
            }else{
                // console.log('item',item);
                const answerArr = item.ewpt_answer_value.map((item,indexN) => {
                    console.log(arr.includes(indexN+1),indexN);
                    if(item==='1'&& arr[index].includes(indexN+1)){
                        return '1'
                    }else if(item==='2'&& !arr[index].includes(indexN+1)){
                        return '1'
                    }else{
                        return '0'
                    }
                })
                //console.log(answerArr);
                if(answerArr.includes('0')){
                    return '0'
                }else{
                    return '1'
                }
            }
        })
        console.log(newArr);
        const num = newArr.filter(function (element) {
            return element === '1';
        }).length;
        console.log(val,num);
        setInfoEdit(prevState=>({...prevState,ewptr_ewpt_value:val,ewptr_result:num}))
        submitUpdateSave({
            ewptr_id:editId,
            ewptr_ewpt_id:infoEdit.ewptr_ewpt_id+'',
            ewptr_ewpt_name:infoEdit.ewptr_ewpt_name+'',
            ewptr_ewpt_value:val,
            ewptr_evaluate_content:infoEdit.ewptr_evaluate_content,
            ewptr_result:num+'',
            ewptr_evaluate_uid:localStorage.getItem('eu_id'),
            ewptr_evaluate_uname:localStorage.getItem('eu_name'),
            
        }).then((res) => {
            console.log(res);
            if (res.data.Status === 'success') {
                message.success('修改成功！')   
                setOpenEdit(false)                             
            }
            if (res.data.Status === 'fail') {
                message.error(`修改失败!${res.data.Message}`)
            }
        })
        setOpenEdit(false);
    };
    
    //答对的题
    const [rightAnswer,setRightAnswer] = useState();
    //问题列表
    const [question,setQuestion] = useState()
    const {data:dataQuestion,isSuccess:isSuccessQuestion} = useMypxEvaluateQuery({ewpter_ewp_id:infoEdit.ewpter_ewp_id,ewptr_id:editId},{skip:!infoEdit.ewpter_ewp_id,})
    useEffect(() => {
        if(isSuccessQuestion){   
            // console.log(dataQuestion);   
            if(dataQuestion.Status==='success'){
                const newArr = dataQuestion.Result.map((item,index) => {
                    const {ewpt_id,ewpt_name,ewpt_answer_name,ewpt_answer_value} = item;                
                    if(ewpt_answer_value.filter(element => element === '1').length>1){
                        const answerArr = ewpt_answer_name.map((item,index) => {
                            return <Checkbox value={index+1} key={index}>{item}</Checkbox>
                        })
                        return  <Form.Item name={ewpt_id} label={ewpt_name} wrapperCol={{span:10}} required key={ewpt_id}>
                                    <Checkbox.Group>
                                        {answerArr}
                                    </Checkbox.Group>
                                </Form.Item>
                    }else{
                        const answerArr = ewpt_answer_name.map((item,index) => {
                            return <Radio value={index+1} key={index}>{item}</Radio>
                        })
                        return  <Form.Item name={ewpt_id} label={ewpt_name} wrapperCol={{span:10}} required  key={ewpt_id}>
                                    <Radio.Group>
                                        {answerArr}
                                    </Radio.Group>
                                </Form.Item>
                    }
                    
                })
                setQuestion(newArr)
                const questionArr = dataQuestion.Result.map((item) => {
                    return item.ewpt_id
                })
                const answerArr = dataQuestion.Result.map((item) => {
                    return item.ewpt_name
                })
                setInfoEdit(prevState=>({...prevState,ewptr_ewpt_id:questionArr,ewptr_ewpt_name:answerArr}))
                const rightArr = dataQuestion.Result.map((item) => {
                    const {ewpt_id,ewpt_answer_value} = item
                    return {ewpt_id,ewpt_answer_value}
                })
                setRightAnswer(rightArr)

            }         
            
        }
    },[dataQuestion, isSuccessQuestion])
    // console.log(rightAnswer);
    const editForm = useRef()
    const [submitUpdateSave] = useMypxEvaluateSaveMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='评价培训任务'
                        okText="提交"
                        cancelText="取消"
                        width={1000}
                        onCancel={() => {setOpenEdit(false);setRightAnswer(0)}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 10 }}
                            ref={editForm}
                        >
                            {question}                            
                            <Form.Item label='评价备注' wrapperCol={{span:10}}>
                                <TextArea onChange={(e) => {setInfoEdit(prevState=>({...prevState,ewptr_evaluate_content:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal>

    //预览---------------------------------------------------------------------------------------------------------------------------预览//
    const [modalInfo,setModalInfo] = useState({
        sst_pic:[],
        picArr:'',
        grade:'',
        docArr:[],
    })
    const [modalOpen, setModalOpen] = useState(false);
    const showModal = () => {setModalOpen(true);};
    const Ok = () => {setModalOpen(false);};
    const Cancel = () => {setModalOpen(false);};
    const modalPreview =  <Modal title='预览' open={modalOpen} onOk={Ok} onCancel={Cancel} okText='关闭'>
                            <div>
                                <div style={{padding:'10px'}}>
                                    <div style={{fontSize:"24px",fontWeight:'600'}}>{modalInfo.ewp_name}</div>
                                    <div style={{marginTop:'10px'}}>
                                        <div dangerouslySetInnerHTML={{__html:modalInfo.ewp_content}} style={{maxWidth:"470px",overflow:"hidden"}}></div>
                                    </div>
                                </div>
                            </div>                
                        </Modal>
    //培训计划列表-----------------------------------------------------------------------------------------------------------------------------培训计划列表
    
    const {data,isSuccess} = useMypxListQuery({ewptr_user_id:localStorage.getItem('eu_id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {ewptr_id:key,ewp_name,ewptr_content,ewptr_uname,ewptr_ctime,ewptr_update_ctime,ewptr_update_uname,ewp_content,ewp_id,ewptr_result,ewptr_evaluate_content,cs_num,ewptr_evaluate_uname,ewptr_evaluate_ctime} = item;
                    return item = {key,ewp_name,ewptr_content,ewptr_uname,ewptr_ctime,ewptr_update_ctime,ewptr_update_uname,ewp_content,ewp_id,ewptr_result,ewptr_evaluate_content,cs_num,ewptr_evaluate_uname,ewptr_evaluate_ctime}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ewptr_ctime;
                    let bTimeString = b.ewptr_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])  
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'type',
            title: '计划名称',
            render:(_,record) => {
                return  <TrainPlanCheck 
                            ewp_name={record.ewp_name}
                            ewp_content={record.ewp_content}
                            ewp_file_file={record.ewp_file_file}
                            ewp_file_name={record.ewp_file_name}
                        />
            }
        },     
        {
            key: 'remark',
            title: '任务备注',
            dataIndex: 'ewptr_content',
        },     
        {
            key: 'status',
            title: '状态',
            render:(_,record) => {
                if(!record.ewptr_result){
                    return<>
                    <div>待评价，共{record.cs_num}个</div>                   
                </>
                }else{
                    return<>已评价，正确{record.ewptr_result}个，共{record.cs_num}个</>
                }
            }
        },  
        {
            key: 'content',
            title: '评价备注',
            dataIndex: 'ewptr_evaluate_content',
        },             
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>                   
                    <div>评价时间：{record.ewptr_evaluate_ctime + record.ewptr_evaluate_uname}</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space>                        
                        <a onClick={() => {
                            showModal(true);                            
                            setModalInfo(prevState=>({
                                ...prevState,
                                ewp_name:record.ewp_name,
                                ewp_content:record.ewp_content,
                            }))
                        }}>阅读教材</a>                        
                        {record.ewptr_result && <a onClick={() => {
                            setEditId(record.key);
                            setOpenEdit(true)
                            setInfoEdit(prevState=>({...prevState,ewpter_ewp_id:record.ewp_id,ewptr_id:record.key}))
                        }}>评价</a>}                        
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main  t1='人事' n1={indexPersonnel} t2='培训' n2='/TrainIndex' t3='我参与的'>
            <Table columns={columns} style={{marginTop:'10px'}} dataSource={list}></Table> 
            {modalEdit} 
            {modalPreview}           
        </Main>
    )
}

export default TrainJoin