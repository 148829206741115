import React ,{useState,} from 'react'
import {Modal,Form,Input,message,} from 'antd';
import { useApplyforInfoAddMutation, } from '../store/ReactWebSiteApi';
const {TextArea} = Input
function SuggestAdd(props) {
    //申请试用
    const [openAdd, setOpenAdd] = useState(false);
    const [form] = Form.useForm();
    const [info,setInfo] = useState({total:'0'});
    const [add] = useApplyforInfoAddMutation({
        eai_content:props.eai_content,
    });
    return (
        <>
            <a onClick={() => {setOpenAdd(true)}}>对本页提出建议</a>
            <Modal
                open={openAdd}
                width={680}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenAdd(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            add({
                                eai_content:info.eai_content,
                                eai_name:localStorage.getItem('eu_name'),
                                eai_phone:localStorage.getItem('eu_ba_phone'),
                                eai_company_name:localStorage.getItem('e_name'),
                            }).then((res) => {
                                console.log(res);
                                if(res.data.Status==='success'){
                                    message.success('提交成功!我们将在工作时间内和你联系！')
                                    setOpenAdd(false);                                    
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('提交成功！'+ res.data.Message)    
                                }
                            })    
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                        
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}                    
                >                    
                    <Form.Item label='建议' wrapperCol={{span:15}} required>
                        <TextArea
                            placeholder='请填写建议内容···'
                            autoSize={{
                                minRows:5
                            }}
                            onChange={(e) => {setInfo(prevState=>({...prevState,eai_content:e.target.value}))}}
                        />
                    </Form.Item>                    
                </Form>
            </Modal>
           
        </>
    )
}

export default SuggestAdd