import React,{useState}from 'react'
import { Modal,message,Form,Input,} from 'antd';
import { useTourGuideAddMutation,  } from '../../store/ReactWebSiteApi';
function GuideAdd(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({
        eg_content:'',
    });
    const [submitAdd] = useTourGuideAddMutation()
    return (
        <>
            <a onClick={() => {setOpen(true)}}>新增</a>
            <Modal
                open={open}
                title='新增导游'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        tgu_phone:info.tgu_phone,
                        tgu_name:info.tgu_name,
                        tgu_uid:localStorage.getItem('eu_id'),
                        tgu_uname:localStorage.getItem('eu_name'),
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                >
                    <Form.Item name="name" label='导游名称' wrapperCol={{span:10}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,tgu_name:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item name="tgu_phone" label='手机号码' wrapperCol={{span:10}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,tgu_phone:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default GuideAdd