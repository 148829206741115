import React, { useEffect, useState } from 'react'
import {Form,DatePicker,} from 'antd'
import dayjs from 'dayjs';
import { useGetDeptTzEndDateQuery } from '../../store/ReactWebSiteApi';
function GetGroupStartDate(props) {
    // console.log(props);
    //获取截止日期
    const [info,setInfo] = useState()
    const {data,isSuccess} = useGetDeptTzEndDateQuery({ed_id:props.ed_id},{refetchOnMountOrArgChange:1})
    useEffect(() => {
        if(isSuccess){
            // console.log(data);
            if(data.Status==='success'){                
                setInfo(data.Result.ed_tz_e_date)
            }
        }
    },[data,isSuccess,props])
    return (
        <>
            <Form.Item name={props.name} label={props.label} required={props.required}>
                <DatePicker 
                    onChange={(e) => {props.getGroupStartDate(e)}} 
                    minDate={info?dayjs(info,'YYYY-MM-DD'):null}
                    disabled={props.disabled}
                />                
            </Form.Item>
        </>
    )
}

export default GetGroupStartDate