import React  from 'react'
import Main from '../../cpn/Main'
import {Row } from 'antd'
import Folder from '../../cpn/Folder'
function AreaIndex() {
    return (
        <Main t1='商城' n1='/GfIndexMall' t2='地区'>
            <Row>
                <Folder title='国家' link='/Country'/>
                <Folder title='省份' link='/Province'/>
                <Folder title='城市' link='/City'/>
                <Folder title='区乡镇' link='/District'/>                   
                <Folder title='街道' link='/Street'/>                    
                <Folder title='地标' link='/Landmark'/>
                <Folder title='目的地' link='/Destination'/>
            </Row>
        </Main>
    )
}

export default AreaIndex