import React,{useState,useEffect} from 'react'
import Main from '../../cpn/Main'
import { Table,Space,DatePicker, Modal, Flex, Descriptions, Button} from 'antd'
import {  useFyClientProfitQuery,} from '../../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import { indexFinance } from '../balance/balanceType';
const {RangePicker} = DatePicker
function ExpenseSumClient() {
    const [open,setOpen] = useState(false)
    const [info,setInfo] = useState({
        s_date:'',
        e_date:'',
        e_id:localStorage.getItem('e_id'),
        eie_type_id:'3'
    })    
    const {data,isSuccess} = useFyClientProfitQuery({
        e_id:localStorage.getItem('e_id'),
        eie_type_id:'3',
        s_date:info.s_date,
        e_date:info.e_date,
    },{refetchOnMountOrArgChange:1});
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                console.log(data);                
                const newArr = data.Result.map(item => {
                    const {ec_id:key,ec_name,amount} = item;
                    return item = {key,ec_name,amount}
                })                
                
                setList(newArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])    
    const columns = [
        {
            key: '1',
            title: '客户',
            dataIndex: 'ec_name',
        },                
        {
            key: '3',
            title: '费用合计',
            dataIndex:'amount'
        },  
       
    ]
    const items = [        
        {
            key:'2',
            label:'费用支出单新增日期',
            children:<>{info.s_date}至{info.e_date}</>,
        },
        {
            key:'3',
            label:'制表人',
            children:<>{localStorage.getItem('ed_name').replace(/"/g,'')} / {localStorage.getItem('eu_name').replace(/"/g,'')}</>
        },
        {
            key:'4',
            label:'制表时间',
            children:<>{dayjs().format('YYYY-MM-DD HH:MM:ss')}</>
        },
    ]
    return (
        <Main  t1='财务' n1={indexFinance} t2='费用支出' n2='/ExpenseIndex' t3='报表' n3='/ExpenseTable' t4='客户费用表'>
            <div style={{marginBottom:'30px'}}>本表说明：按费用支出单的新增日期，统计每个客户的《费用支出单》数量、《费用支出单》金额之和</div>
            <Space>
                <span>费用支出单新增日期：</span>
                <RangePicker
                    onChange={(e) => {
                        if(e){
                            setInfo(prevState=>({...prevState,s_date:dayjs(e[0]).format('YYYY-MM-DD'),e_date:dayjs(e[1]).format('YYYY-MM-DD')})) 
                        }else{
                            setInfo(prevState=>({...prevState,s_date:'',e_date:''}))
                        }
                    }}
                />
                <Button type='primary' onClick={() => {setOpen(true)}}>查询</Button>                
            </Space>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false)}}
                width={1200}
                footer={null}
            >            
                <Flex justify='center'>
                    <h2>客户费用表</h2>
                </Flex>
                <Descriptions items={items} bordered/>
                <Table 
                    columns={columns} 
                    dataSource={list} 
                    pagination={{position:['none'],defaultPageSize:100}}
                    summary={(pageData) => {
                        console.log(pageData);
                        let totalincome_num = 0;
                        pageData.forEach(({amount }) => {
                            totalincome_num += +amount;
                        });
                        return <Table.Summary>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>{(totalincome_num).toFixed(2)}</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                    }}
                />
            </Modal>   
        </Main>
    )
}

export default ExpenseSumClient