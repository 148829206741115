import React from 'react';
import { useNavigate,NavLink} from 'react-router-dom';
import classes from './keeper.module.css';
import { Breadcrumb, Layout, Menu, Space,Modal } from 'antd';
import { useDispatch } from 'react-redux'
import { setLogout } from '../store/loginSlice';
import { TeamOutlined,UserOutlined,ExclamationCircleFilled,PoweroffOutlined} from '@ant-design/icons';
import Foot from '../cpn/Foot';
function MainKeeper(props) {
    const { Header, Content,} = Layout;
    //获取登录状态
    const userName = JSON.parse(localStorage.getItem('eu_name'));
    const userFirmName = JSON.parse(localStorage.getItem('e_name'));
    const userPhone = JSON.parse(localStorage.getItem('eu_ba_phone'));
    const navi = useNavigate();
    const dispatch = useDispatch();
    let items = []
    if(props.t1){
        items=[
            {
                title: <a onClick={() => {props.n1 && navi(props.n1)}}>{props.t1}</a>,
            },
        ]
    }
    if(props.t2){
        items=[
            {
                title: <a onClick={() => {props.n1 && navi(props.n1)}}>{props.t1}</a>,
            },
            {
                title: <a  onClick={() => {props.n2 && navi(props.n2)}}>{props.t2}</a>,
            },
        ]
    }
    if(props.t3){
        items=[
            {
                title: <a onClick={() => {props.n1 && navi(props.n1)}}>{props.t1}</a>,
            },
            {
                title: <a  onClick={() => {props.n2 && navi(props.n2)}}>{props.t2}</a>,
            },
            {
                title: <a  onClick={() => {props.n3 && navi(props.n3)}}>{props.t3}</a>,
            },
        ]
    }
    if(props.t4){
        items=[
            {
                title: <a onClick={() => {props.n1 && navi(props.n1)}}>{props.t1}</a>,
            },
            {
                title: <a  onClick={() => {props.n2 && navi(props.n2)}}>{props.t2}</a>,
            },
            {
                title: <a  onClick={() => {props.n3 && navi(props.n3)}}>{props.t3}</a>,
            },
            {
                title: <a  onClick={() => {props.n4 && navi(props.n4)}}>{props.t4}</a>,
            },
         
        ]
    }
    if(props.t5){
        items=[
            {
                title: <a onClick={() => {props.n1 && navi(props.n1)}}>{props.t1}</a>,
            },
            {
                title: <a  onClick={() => {props.n2 && navi(props.n2)}}>{props.t2}</a>,
            },
            {
                title: <a  onClick={() => {props.n3 && navi(props.n3)}}>{props.t3}</a>,
            },
            {
                title: <a  onClick={() => {props.n4 && navi(props.n4)}}>{props.t4}</a>,
            },
            {
                title: <a  onClick={() => {props.n5 && navi(props.n5)}}>{props.t5}</a>,
            },
            
        ]
    }
    if(props.t6){
        items=[
            {
                title: <a onClick={() => {props.n1 && navi(props.n1)}}>{props.t1}</a>,
            },
            {
                title: <a  onClick={() => {props.n2 && navi(props.n2)}}>{props.t2}</a>,
            },
            {
                title: <a  onClick={() => {props.n3 && navi(props.n3)}}>{props.t3}</a>,
            },
            {
                title: <a  onClick={() => {props.n4 && navi(props.n4)}}>{props.t4}</a>,
            },
            {
                title: <a  onClick={() => {props.n5 && navi(props.n5)}}>{props.t5}</a>,
            },
            {
                title: <a  onClick={() => {props.n6 && navi(props.n6)}}>{props.t6}</a>,
            },
 
        ]
    }
    if(props.t7){
        items=[
            {
                title: <a onClick={() => {props.n1 && navi(props.n1)}}>{props.t1}</a>,
            },
            {
                title: <a  onClick={() => {props.n2 && navi(props.n2)}}>{props.t2}</a>,
            },
            {
                title: <a  onClick={() => {props.n3 && navi(props.n3)}}>{props.t3}</a>,
            },
            {
                title: <a  onClick={() => {props.n4 && navi(props.n4)}}>{props.t4}</a>,
            },
            {
                title: <a  onClick={() => {props.n5 && navi(props.n5)}}>{props.t5}</a>,
            },
            {
                title: <a  onClick={() => {props.n6 && navi(props.n6)}}>{props.t6}</a>,
            },
            {
                title: <a  onClick={() => {props.n7 && navi(props.n7)}}>{props.t7}</a>,
            },
        ]
    }
    if(props.t8){
        items=[
            {
                title: <a onClick={() => {props.n1 && navi(props.n1)}}>{props.t1}</a>,
            },
            {
                title: <a  onClick={() => {props.n2 && navi(props.n2)}}>{props.t2}</a>,
            },
            {
                title: <a  onClick={() => {props.n3 && navi(props.n3)}}>{props.t3}</a>,
            },
            {
                title: <a  onClick={() => {props.n4 && navi(props.n4)}}>{props.t4}</a>,
            },
            {
                title: <a  onClick={() => {props.n5 && navi(props.n5)}}>{props.t5}</a>,
            },
            {
                title: <a  onClick={() => {props.n6 && navi(props.n6)}}>{props.t6}</a>,
            },
            {
                title: <a  onClick={() => {props.n7 && navi(props.n7)}}>{props.t7}</a>,
            },
            {
                title: <a  onClick={() => {props.n8 && navi(props.n8)}}>{props.t8}</a>,
            },
        ]
    }
    if(props.t9){
        items=[
            {
                title: <a onClick={() => {props.n1 && navi(props.n1)}}>{props.t1}</a>,
            },
            {
                title: <a  onClick={() => {props.n2 && navi(props.n2)}}>{props.t2}</a>,
            },
            {
                title: <a  onClick={() => {props.n3 && navi(props.n3)}}>{props.t3}</a>,
            },
            {
                title: <a  onClick={() => {props.n4 && navi(props.n4)}}>{props.t4}</a>,
            },
            {
                title: <a  onClick={() => {props.n5 && navi(props.n5)}}>{props.t5}</a>,
            },
            {
                title: <a  onClick={() => {props.n6 && navi(props.n6)}}>{props.t6}</a>,
            },
            {
                title: <a  onClick={() => {props.n7 && navi(props.n7)}}>{props.t7}</a>,
            },
            {
                title: <a  onClick={() => {props.n8 && navi(props.n8)}}>{props.t8}</a>,
            },
            {
                title: <a  onClick={() => {props.n9 && navi(props.n9)}}>{props.t9}</a>,
            },
        ]
    }
    if(props.t10){
        items=[
            {
                title: <a onClick={() => {props.n1 && navi(props.n1)}}>{props.t1}</a>,
            },
            {
                title: <a  onClick={() => {props.n2 && navi(props.n2)}}>{props.t2}</a>,
            },
            {
                title: <a  onClick={() => {props.n3 && navi(props.n3)}}>{props.t3}</a>,
            },
            {
                title: <a  onClick={() => {props.n4 && navi(props.n4)}}>{props.t4}</a>,
            },
            {
                title: <a  onClick={() => {props.n5 && navi(props.n5)}}>{props.t5}</a>,
            },
            {
                title: <a  onClick={() => {props.n6 && navi(props.n6)}}>{props.t6}</a>,
            },
            {
                title: <a  onClick={() => {props.n7 && navi(props.n7)}}>{props.t7}</a>,
            },
            {
                title: <a  onClick={() => {props.n8 && navi(props.n8)}}>{props.t8}</a>,
            },
            {
                title: <a  onClick={() => {props.n9 && navi(props.n9)}}>{props.t9}</a>,
            },
            {
                title: <a  onClick={() => {props.n10 && navi(props.n10)}}>{props.t10}</a>,
            },
        ]
    }
    if(props.t11){
        items=[
            {
                title: <a onClick={() => {props.n1 && navi(props.n1)}}>{props.t1}</a>,
            },
            {
                title: <a  onClick={() => {props.n2 && navi(props.n2)}}>{props.t2}</a>,
            },
            {
                title: <a  onClick={() => {props.n3 && navi(props.n3)}}>{props.t3}</a>,
            },
            {
                title: <a  onClick={() => {props.n4 && navi(props.n4)}}>{props.t4}</a>,
            },
            {
                title: <a  onClick={() => {props.n5 && navi(props.n5)}}>{props.t5}</a>,
            },
            {
                title: <a  onClick={() => {props.n6 && navi(props.n6)}}>{props.t6}</a>,
            },
            {
                title: <a  onClick={() => {props.n7 && navi(props.n7)}}>{props.t7}</a>,
            },
            {
                title: <a  onClick={() => {props.n8 && navi(props.n8)}}>{props.t8}</a>,
            },
            {
                title: <a  onClick={() => {props.n9 && navi(props.n9)}}>{props.t9}</a>,
            },
            {
                title: <a  onClick={() => {props.n10 && navi(props.n10)}}>{props.t10}</a>,
            },
            {
                title: <a  onClick={() => {props.n11 && navi(props.n11)}}>{props.t11}</a>,
            },
        ]
    }
    //退出登录
    const logout = () => {
        navigate('/login');
        dispatch(setLogout());    
    };
    //跳转
    const navigate = useNavigate(); 
    //点击退出登录
    const { confirm } = Modal;
    const showConfirm = () => {
        confirm({
            title: '确定退出登录吗?',
            icon: <ExclamationCircleFilled />,
            content: '退出后随时回来！',
            okText: '确认',
            cancelText:'取消',
            onOk() {
                logout();
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    };
    return (
        <Layout>
            <Header className={classes.mainHeader}>
                <div className={classes.mainHeaderLogo} onClick={() => {navi('/')}}>BNS 伯纳思</div>
                <div className={classes.mainHeaderApp}>
                    <img src='./img/app/keeper.png' alt='' width={30}/>
                    <span className={classes.mainHeaderAppText}>科财通</span></div>
                <div className={classes.mainHeaderMenu}>
                    <Menu   
                        mode="horizontal" 
                        defaultSelectedKeys={[props.topActive]} 
                        items={
                            [
                                {
                                    key: 'Client',    
                                    label: <NavLink to='/Client'><TeamOutlined /> 客户管理</NavLink>,
                                    onClick: () => {}
                                },
                                {
                                    key: 'Business',    
                                    label: <NavLink to='/Business'><UserOutlined /> 业务管理</NavLink>,
                                    onClick: () => {}
                                },                                		
                                {
                                    key: 'Balance',    
                                    label: <NavLink to='/Balance'><UserOutlined /> 收支管理</NavLink>,
                                    onClick: () => {}
                                },                                		
                                {
                                    key: 'BankAccount',    
                                    label: <NavLink to='/BankAccount'><UserOutlined /> 支付管理</NavLink>,
                                    onClick: () => {}
                                },                                		
                                {
                                    key: 'Supplies',    
                                    label: <NavLink to='/Supplies'><UserOutlined /> 物品管理</NavLink>,
                                    onClick: () => {},
                                    children:[
                                        {
                                            key:'Warehouse',
                                            label: '库房管理',
                                            onClick:() => {navi('/Warehouse')}
                                        },
                                        {
                                            key:'Supplies',
                                            label: '物品管理',
                                            onClick:() => {navi('/Supplies')}
                                        },
                                    ]
                                },                                		
                                		
                               
                            ]
                        } 
                        theme='light'
                        active={props.topActive}
                        style={{minWidth:'600px'}}
				    />
                    <Space className={classes.mainUser}>
                    {userFirmName} {userName} {userPhone}
                    <PoweroffOutlined style={{ fontSize: "20px" }}  onClick={showConfirm} />
                    </Space>
                </div>                
            </Header> 
            <Layout>                
                <Layout className={classes.mainMain}>
                    <Content className={classes.mainContent}>
                        {props.t1 && <Breadcrumb items={items} style={{marginBottom:"20px"}}/>}
                        {props.children}
                    </Content>
                </Layout>
            </Layout>
            <Foot />        
        </Layout>
    )

}
export default MainKeeper