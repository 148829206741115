import React,{useState,useEffect,useRef}from 'react'
import Main from '../../cpn/Main'
import { Table,Button,Space,Modal,message,Form,Input,Select} from 'antd';
import { useDelNewsMutation, useNewsListQuery,useAddNewsMutation,useNewsUpdateQuery ,useUpdateSaveNewsMutation, useNewsStateMutation, useNewsTypeListQuery} from '../../store/ReactWebSiteApi';
import MyEditor from '../../cpn/MyEditor';
function WebNews() {
    //新闻类型列表
    const {data:dataType,isSuccess:isSuccessType} = useNewsTypeListQuery({ent_e_id:localStorage.getItem('e_id')})
    
    const [options, setOptions] = useState();
    
    useEffect(() => {
        if(isSuccessType){            
            if(dataType.Status==='success'){
                // console.log(dataType);
                
                const newArr = dataType.Result.map(item => {
                    const {ent_id:key,ent_name,} = item;
                    return item = {value:key,label:ent_name}
                })   
                setOptions(newArr)
            }else{
                setOptions('')
            }
            
        }
    }, [dataType, isSuccessType])      
    //新增新闻----------------------------------------------------------------------------------------------------------------新增新闻//
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateAdd = (values) => {
        // console.log('Received values of form: ', values);
        addNews(infoAdd).then((res) => {
            // console.log(res);
            if (res.data.Status === 'success') {
                message.success('新增成功！')
                setOpenAdd(false)                                
            }
            if (res.data.Status === 'fail') {
                message.error('新增失败！'+res.data.Message)
            }
        })
    };
    const [infoAdd,setInfoAdd] = useState({
        en_e_id:localStorage.getItem('e_id'),
        en_dept_id:localStorage.getItem('ed_id'),
        en_uid:localStorage.getItem('eu_id'),
        en_uname:localStorage.getItem('eu_name'),
    });
    const [addNews] = useAddNewsMutation()
    // console.log(newsType);
    const modalAdd = <Modal
                        open={openAdd}
                        title='新增新闻'
                        width={1000}
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateAdd(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                                
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                        >
                            <Form.Item name="type" label='新闻类型' wrapperCol={{span:15}} required>
                                <Select options={options}  onChange={(e,f) => {setInfoAdd(prevState=>({...prevState,en_type_id:e,en_type_name:f.label}))}}/>                                
                            </Form.Item>
                            <Form.Item name="name" label='新闻标题' wrapperCol={{span:15}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,en_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="content" label='新闻正文' wrapperCol={{span:15}} required>
                                <MyEditor onChange={(e) => {setInfoAdd(prevState=>({...prevState,en_content:e}))}} value={infoAdd.en_content}/>
                            </Form.Item>
                        </Form>
                    </Modal>
    //修改新闻----------------------------------------------------------------------------------------------------------------修改新闻//
    const editForm = useRef()
    const [editId,setEditId] = useState();
    const {data:dataEdit,isSuccess:isSuccessEdit} = useNewsUpdateQuery({en_id:editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                // console.log(dataEdit);
                editForm.current.setFieldsValue({
                    en_name:dataEdit.Result.en_name,
                    en_content:dataEdit.Result.en_content,
                    en_type_id:[dataEdit.Result.en_type_id],
                })
                setInfoEdit(prevState=>({...prevState,
                    en_name:dataEdit.Result.en_name,
                    en_content:dataEdit.Result.en_content,    
                    en_type_id:dataEdit.Result.en_type_id,            
                    en_type_name:dataEdit.Result.en_type_name,            
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [updateSaveNews] = useUpdateSaveNewsMutation()
    const [openEdit, setOpenEdit] = useState(false);
    const [infoEdit, setInfoEdit] = useState({
        en_e_id:localStorage.getItem('e_id'),
        en_uid:localStorage.getItem('eu_id'),
        en_uname:localStorage.getItem('eu_name'),
    })

    const onCreateEdit = () => {
        updateSaveNews(infoEdit).then((res) => {
            // console.log(res);
            if (res.data.Status === 'success') {
                message.success('修改成功！')
                setOpenEdit(false)                                   
            }
            if (res.data.Status === 'fail') {
                message.error('修改失败！'+res.data.Message)
            }
        })
    }
    const modalEdit =   <Modal
                            open={openEdit}
                            width={1000}
                            title='修改考勤'
                            okText="提交"
                            cancelText="取消"
                            onCancel={() => {setOpenEdit(false);}}
                            onOk={() => {
                                form
                                    .validateFields()
                                    .then((values) => {
                                        form.resetFields();
                                        onCreateEdit(values);
                                    })
                                    .catch((info) => {
                                        console.log('Validate Failed:', info);
                                    });
                                
                            }}
                        >
                            <Form
                                form={form}
                                layout="horizonal"
                                name="form_client_edit"
                                labelCol={{ span: 5 }}
                                ref={editForm}
                            >
                                <Form.Item name="en_type_id" label='新闻类型' wrapperCol={{span:5}} required>
                                    <Select 
                                        options={options}   
                                        onChange={(e,f) => {
                                            // console.log(newsType);
                                            setInfoEdit(prevState=>({...prevState,en_type_id:e,en_type_name:f.label}))
                                        }}
                                    /> 
                                </Form.Item>
                                <Form.Item name="en_name" label='新闻标题' wrapperCol={{span:15}}>
                                    <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,en_name:e.target.value}))}}/>
                                </Form.Item>
                                <Form.Item name="en_content" label='新闻正文' wrapperCol={{span:15}}>
                                    <MyEditor onChange={(e) => {setInfoEdit(prevState=>({...prevState,en_content:e}))}} value={infoAdd.en_content}/>
                                </Form.Item>
                            </Form>
                        </Modal>
    //删除新闻----------------------------------------------------------------------------------------------------------------删除新闻//
    const {confirm} = Modal; 
    const [delBusiness] = useDelNewsMutation()
    //上下架新闻----------------------------------------------------------------------------------------------------------------上下架新闻//
    const [newsState] = useNewsStateMutation()
    //预览新闻---------------------------------------------------------------------------------------------------------------------------预览新闻//
    const [modalInfo,setModalInfo] = useState({
        sst_pic:[],
        picArr:'',
        grade:'',
        docArr:[],
    })
    const [modalOpen, setModalOpen] = useState(false);
    const showModal = () => {setModalOpen(true);};
    const Ok = () => {setModalOpen(false);};
    const Cancel = () => {setModalOpen(false);};
    const modalPreview =  <Modal title='预览' open={modalOpen} onOk={Ok} onCancel={Cancel} okText='关闭'>
                            <div>
                                <div style={{padding:'10px'}}>
                                    <div style={{fontSize:"24px",fontWeight:'600'}}>{modalInfo.en_name}</div>
                                    <div style={{marginTop:'10px'}}>
                                        <div dangerouslySetInnerHTML={{__html:modalInfo.en_content}} style={{maxWidth:"470px",overflow:"hidden"}}></div>
                                    </div>
                                </div>
                            </div>                
                        </Modal>
    //新闻列表----------------------------------------------------------------------------------------------------------------新闻列表//
    const [query,setQuery] = useState('')
    
    const {data, isSuccess} = useNewsListQuery({en_e_id:localStorage.getItem('e_id'),en_type_id:query});
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if (isSuccess) {
            // //console.log(data);
            if(data.Status==='success'){
                
                const newArr = data.Result.map(item => {
                    const {en_id:key,en_name,en_num,en_content,en_uname,en_ctime,en_type_id,en_state,en_type_name} = item;
                    return item = {key,en_name,en_num,en_content,en_uname,en_ctime,en_type_id,en_state,en_type_name}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.en_ctime;
                    let bTimeString = b.en_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    
    
    
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'en_type_name',
            title: '新闻类型',
            dataIndex:'en_type_name',            
        },
        {
            key: 'room',
            title: '新闻标题',
            dataIndex: 'en_name',
        },
        {
            key: 'min',
            title: '阅读次数',
            dataIndex: 'en_num',
        },
        {
            key: 'num',
            title: '状态',            
            dataIndex: 'en_state',
            render:(text) => {
                if(text==='1'){
                    return<>待上架</>
                }else{
                    return<>已上架</>
                }
            }
        },
        {
            key: 'time',
            title: '创建/修改时间',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.en_ctime + record.en_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space>
                        <a onClick={() => {
                            showModal(true);                            
                            setModalInfo(prevState=>({
                                ...prevState,
                                en_name:record.en_name,
                                en_content:record.en_content,
                            }))
                        }}>预览</a>
                        <a onClick={() => {
                            setOpenEdit(true);
                            setEditId(record.key)
                            setInfoEdit(prevState=>({...prevState,en_id:record.key}))
                            }}>修改</a>
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    delBusiness({en_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>
                        {(record.en_state==='1') ? <>
                                <a onClick={() => {
                                    newsState({en_id:record.key,en_state:'1'}).then((res) => {
                                        if(res.data.Status==='success'){
                                            message.info('上架成功！')
                                        }else{
                                            message.error('上架失败！' + res.data.Message)
                                        }
                                    })
                                }}>上架</a>
                            </>:<>
                                <a onClick={() => {
                                    newsState({en_id:record.key,en_state:'2'}).then((res) => {
                                        if(res.data.Status==='success'){
                                            message.info('下架成功！')
                                        }else{
                                            message.error('下架失败！' + res.data.Message)
                                        }
                                    })
                                }}>下架</a>
                            </>}
                    </Space>
                </>
            }
        },
    ]

      
    return (
        <Main   t1='商城' n1='/GfIndexMall' t2='官网' n2='/WebIndex' t3='新闻' >
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true)}}>新增</Button>
                <Select 
                    options={options} 
                    style={{width:"180px",marginLeft:'20px'}} 
                    placeholder='请选择新闻类型' 
                    onChange={(e) => {setQuery(e)}}
                    allowClear
                    onClear={() => {setQuery('')}}
                />
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table>
            {modalPreview}
           
            {modalAdd}            
            {modalEdit}
            
        </Main>
    )
}

export default WebNews