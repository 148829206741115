import React,{useState,useEffect,useRef}from 'react'
import Main from '../cpn/Main'
import classes from './appshop.module.css'
import {  useEntAppNoPayListQuery, useEntMoneyAppAddMutation, useGoodsSpecListQuery, } from '../store/ReactWebSiteApi';
import { Button ,message,Modal,Form,Radio, Space} from 'antd';
import { ShoppingCartOutlined,InboxOutlined} from '@ant-design/icons';
function AppshopNotPay() {   
    
    //购买应用----------------------------------------------------------------------------------------------------------------购买应用//
    const [form] = Form.useForm();
    const editForm = useRef()
    //应用列表
    const [infoApp, setInfoApp] = useState({eail_esv_id:''})    
    //规格列表
    const [optionsType,setOptionsType] = useState();
    const {data:dataType,isSuccess:isSuccessType} = useGoodsSpecListQuery({ess_esv_id:infoApp.eail_esv_id})
    useEffect(() => {
        if(isSuccessType){
            if(dataType.Status==='success'){
                console.log(dataType,'type');
                const newArr = dataType.Result.map(item => {
                    const {ess_id:key,ess_name,ess_price,ess_num,} = item;
                    return item = <Radio value={key} ess_price={ess_price} ess_num={ess_num} eail_ess_name={ess_name}>{ess_name}</Radio>
                }) 
                setOptionsType(newArr)
            }
        }
    },[dataType,isSuccessType])
    const [submitApp] = useEntMoneyAppAddMutation()
    const [openApp, setOpenApp] = useState(false);   

    const onCreateApp = () => {
        submitApp({
            eail_e_id:localStorage.getItem('e_id'),
            eail_amount:-infoApp.ess_price,
            eail_uid:localStorage.getItem('eu_id'),
            eail_uname:localStorage.getItem('eu_name'),
            eail_content:'自行购买',
            eail_type_id:'2',
            eail_type_name:'购买应用', 
            eail_esv_id:infoApp.eail_esv_id, 
            eail_esv_name:infoApp.eail_esv_name,
            eail_ess_id:infoApp.eail_ess_id,
            eail_ess_name:infoApp.eail_ess_name,
            eail_num:infoApp.eail_num,
        }).then((res) => {
            // console.log(res);
            if (res.data.Status === 'success') {
                message.success('购买成功！')
                setOpenApp(false)                                   
            }
            if (res.data.Status === 'fail') {
                message.error('购买失败！'+res.data.Message)
            }
        })
    }
    const modalApp =   <Modal
                            open={openApp}
                            width={1000}
                            title='购买应用'
                            okText="提交"
                            cancelText="取消"
                            onCancel={() => {setOpenApp(false);}}
                            onOk={() => {
                                form
                                    .validateFields()
                                    .then((values) => {
                                        form.resetFields();
                                        onCreateApp(values);
                                    })
                                    .catch((info) => {
                                        console.log('Validate Failed:', info);
                                    });
                                
                            }}
                        >
                            <Form
                                form={form}
                                layout="horizonal"
                                name="form_client_edit"
                                labelCol={{ span: 5 }}
                                ref={editForm}
                            >
                                <Form.Item name="date" label='请选择' wrapperCol={{span:5}} required>
                                    <Radio.Group onChange={(e) => {
                                            setInfoApp(prevState=>({
                                                ...prevState,
                                                ess_price:e.target.ess_price,
                                                eail_ess_id:e.target.value,
                                                eail_ess_name:e.target.eail_ess_name,
                                                eail_num:e.target.ess_num
                                            }))}
                                            }>
                                        <Space direction='vertical'>
                                            {optionsType}
                                        </Space>
                                    </Radio.Group>
                                </Form.Item> 
                            </Form>
                        </Modal>

    //应用列表-------------------------------------------------------------------------------------------------------------------应用列表//
    
    const {data, isSuccess} = useEntAppNoPayListQuery({ea_e_id:localStorage.getItem('e_id')});
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if (isSuccess) {
            //console.log(data);
            if(data.Status==='success'){
                
                const newArr = data.Result.map(item => {
                    const {esv_id:key,esv_name,esv_content,esv_ctime,esv_image2_name,ess_price,esv_state,esv_remark} = item;
                    if(esv_state==='2'){
                        return item = <div key={key} esv_ctime={esv_ctime} className={classes.appshopItem}>
                        <div>
                            <img src={`/api/assets/images/application/${esv_image2_name}`} alt='' width={80}/>
                        </div>
                        <div className={classes.appshopItemMiddle}>
                            <div>
                                <span className={classes.appshopItemMiddleName}>{esv_name}</span>
                                <span className={classes.appshopItemMiddlePrice}>{ess_price}</span>
                            </div>
                            <div className={classes.appshopItemMiddleContent}>
                                {esv_content}
                            </div>
                        </div>
                        <div className={classes.appshopItemRight}>                            
                        <Button onClick={() => {
                                setOpenApp(true);
                                setInfoApp(prevState=>({...prevState,eail_esv_id:key,eail_esv_name:esv_name}))}
                                }><ShoppingCartOutlined /> 购买</Button>
                            <Button onClick={() => {
                                showModal(true);                            
                                setModalInfo(prevState=>({
                                    ...prevState,
                                    esv_name:esv_name,
                                    esv_remark:esv_remark,
                                }))
                            }}><InboxOutlined /> 更多</Button>
                        </div>
                    </div>
                    }else{
                        return <></>
                    }
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.props.esv_ctime;
                    let bTimeString = b.props.esv_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    //了解更多---------------------------------------------------------------------------------------------------------------------------了解更多//
    const [modalInfo,setModalInfo] = useState({
        sst_pic:[],
        picArr:'',
        grade:'',
        docArr:[],
    })
    const [modalOpen, setModalOpen] = useState(false);
    const showModal = () => {setModalOpen(true);};
    const Ok = () => {setModalOpen(false);};
    const Cancel = () => {setModalOpen(false);};
    const modalPreview =  <Modal title={modalInfo.esv_name} open={modalOpen} onOk={Ok} onCancel={Cancel} okText='关闭'>
                            <div>
                                <div style={{padding:'10px'}}>
                                    <div style={{fontSize:"24px",fontWeight:'600'}}>{modalInfo.en_name}</div>
                                    <div style={{marginTop:'10px'}}>
                                        <div dangerouslySetInnerHTML={{__html:modalInfo.esv_remark}} style={{maxWidth:"470px",overflow:"hidden"}}></div>
                                    </div>
                                </div>
                            </div>                
                        </Modal>
    return (
        <Main topActive='SetAppNotPay' app='set'>
            <div className={classes.appshopMain}>{list}</div>
            {modalPreview}
            {modalApp}
        </Main>
    )
}

export default AppshopNotPay