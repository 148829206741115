import React,{useState,useEffect,useRef}from 'react'
import { Table,Button,Space,Modal,message,Form,Input, DatePicker, Upload,} from 'antd';
import {  useNavigate, } from 'react-router-dom';
import { UploadOutlined} from '@ant-design/icons';
import { useClientListQuery, useDelClientMutation,useUpdateSaveClientMutation,useClientUpdateQuery} from '../../store/ReactWebSiteApi';
import GetStaff from '../../cpn/GetUser';
import MainKeeper from '../MainKeeper';

function Inventory() {
    
    const [form] = Form.useForm();
    
    //修改规格-----------------------------------------------------------------------------------------------------------------------------修改规格
    const [openClientEdit, setOpenClientEdit] = useState(false);
    const [clientId,setClientId] = useState()
    const onCreateClientEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenClientEdit(false);
    };
    const [editClient,setEditClient] = useState();
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useClientUpdateQuery({ec_id:clientId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    ec_type_id:[dataEdit.Result.ec_type_id],
                    ec_name:dataEdit.Result.ec_name,
                    ec_content:dataEdit.Result.ec_content,
                })
                setEditClient(prevState=>({...prevState,
                    ec_type_id:dataEdit.Result.ec_type_id,
                    ec_name:dataEdit.Result.ec_name,
                    ec_content:dataEdit.Result.ec_content,              
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [clientUpdate] = useUpdateSaveClientMutation()
    const modalEdit = <Modal
                        open={openClientEdit}
                        title='修改规格'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenClientEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClientEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            clientUpdate({
                                ec_name:editClient.ec_name,
                                ec_update_uid:localStorage.getItem('eu_id'),
                                ec_update_uname:localStorage.getItem('eu_name'),
                                ec_content:editClient.ec_content,
                                ec_id:clientId,
                                ec_e_id:localStorage.getItem('e_id'),
                                ec_type_id:editClient.ec_type_id,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenClientEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >
                           
                            <Form.Item name="ec_name" label='规格名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setEditClient(prevState=>({...prevState,ec_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="ec_content" label='规格备注' wrapperCol={{span:15}}>
                                <Input  onChange={(e) => {setEditClient(prevState=>({...prevState,ec_content:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal>
    //删除规格-----------------------------------------------------------------------------------------------------------------------------删除规格
    const {confirm} = Modal; 
    const [delBusiness] = useDelClientMutation()
    //规格列表-----------------------------------------------------------------------------------------------------------------------------规格列表
    
    const {data,isSuccess} = useClientListQuery({ec_e_id:localStorage.getItem('e_id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                // //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {ec_id:key,ec_name,ec_num,ec_content,ec_uname,ec_ctime,finance_num,ec_type_id} = item;
                    return item = {key,ec_name,ec_num,ec_content,ec_uname,ec_ctime,finance_num,ec_type_id}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ec_ctime;
                    let bTimeString = b.ec_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])    
    const navi = useNavigate();    
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
       
        {
            key: 'type',
            title: '盘存时间',
            dataIndex: 'ec_name',
        },
        {
            key: 'room',
            title: '备注说明',
            dataIndex: 'ec_content',
        },
        {
            key: 'num',
            title: '数量',
            dataIndex: 'finance_num',
        },
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.ec_ctime + record.ec_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>                        
                        <a onClick={() => {
                            setClientId(record.key);
                            setOpenClientEdit(true)
                        }}>修改</a>
                        {record.finance_num === '0' && <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    delBusiness({ec_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>}
                    </Space>
                </>
            }
        },
    ]
//新增盘存-----------------------------------------------------------------------------------------------------------------------------新增盘存
    //获取员工
    const getStaff = (e,f,g) => {
        // console.log(e,f,g);
    }
    const [openStorageIn, setOpenStorageIn] = useState(false);
    const onCreateStorageIn = (values) => {
        console.log('Received values of form: ', values);
        setOpenStorageIn(false);
    };
    const [newStorageIn,setNewStorageIn] = useState({
        ec_e_id:localStorage.getItem('e_id'),
        ec_uname:localStorage.getItem('eu_name'),
        ec_uid:localStorage.getItem('eu_id'),
        ec_content:'',
    });
    // const [StorageInAdd] = useAddStorageInMutation()
    const modalStorageIn= <Modal
                        open={openStorageIn}
                        title='新增出入库'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenStorageIn(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                        >
                            <Form.Item name="type" label='物资名称' wrapperCol={{span:10}} required>
                                <div>华为</div>
                            </Form.Item>
                            <Form.Item name='time' label='盘存时间' wrapperCol={{span:15}} required>
                                <DatePicker showTime />
                            </Form.Item>                           
                            <Form.Item name="num" label='数量' wrapperCol={{span:10}} required extra='入库填正数，出库填负数'>
                                <Input />
                            </Form.Item>                           
                            <GetStaff label='经手人'  getStaff={getStaff}/>
                            <Form.Item name="name" label='备注说明' wrapperCol={{span:15}}>
                                <Input/>
                            </Form.Item>                            
                            <Form.Item label="图片"                         
                                valuePropName='fileList'
                                getValueFromEvent={e => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e && e.fileList;
                                }}
                            >
                                <Upload
                                    name="image_file"
                                    action="/api/ReactApi/zd_single_upload_image"
                                    listType="picture"
                                    className="upload-list-inline"
                                    maxCount={1}
                                    >
                                    <Button icon={<UploadOutlined />}>点击上传</Button>
                                </Upload>
                            </Form.Item>
                        </Form>
                    </Modal>     
    return (
        <MainKeeper  t1='物资管理' t2='物品管理'  t3='华为手机'  t5='盘存列表' >
            <div>
                <Button type='primary' onClick={() => {setOpenStorageIn(true);}}>新增盘存</Button>
            </div>            
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalEdit}            
        </MainKeeper>
    )
}

export default Inventory