import { Button, Form, Input, Modal } from 'antd'
import React, { useState } from 'react'
import GetUserDept from '../../../../../cpn/GetUserDept';

function LineNameQuery(props) {
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({ltxl_dept_id: '',ltxl_name:''})
    return (
        <>
            <Button onClick={() => { setOpen(true)}}>查询</Button>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            props.getQuery(values)
                            setOpen(false)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });                    
                }}
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        <Button onClick={() => {                                        
                            form.resetFields();  
                            }}>重置</Button>
                        <CancelBtn />
                        <OkBtn />
                    </>
                )} 
                title='查询线路名称'
            >
                <Form
                    layout="horizonal"
                    name="form_client"
                    labelCol={{ span: 6 }}
                    initialValues={{ltxl_dept_id: '',ltxl_name:''}}
                    form={form}
                >                                           
                    <GetUserDept 
                        label='部门' 
                        name='ltxl_dept_id'
                        required 
                        wrapperCol={{span:10}}
                        getUserDept={(e) => {setInfo(prevState=>({...prevState,ltxl_dept_id:e}))}}
                        value={info.ltxl_dept_id}
                    />
                    <Form.Item name="ltxl_name" label='线路名称' wrapperCol={{span:10}}>
                        <Input 
                            value={info.ltxl_name}
                            onChange={(e) => {setInfo(prevState=>({...prevState,ltxl_name:e.target.value}))}}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default LineNameQuery