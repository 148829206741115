import React from 'react'
import { useSearchParams } from 'react-router-dom'
import ArapStatementReceiveCheck from './ArapStatementReceiveCheck'

function ArapStatementReceiveCover() {
    const [param] = useSearchParams()
    return (
        <div>
            {param.get('name') + param.get('phone')}给您发了一份对账单，请点击》<ArapStatementReceiveCheck 
                edz_id={param.get('edz_id')}
            />
        </div>
    )
}

export default ArapStatementReceiveCover