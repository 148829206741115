import React,{useState,useEffect,useRef}from 'react'
import Main from '../../cpn/Main'
import { Table,Button,Space,Modal,message,Form,Input,Select, InputNumber, DatePicker} from 'antd';
import { useSystemListQuery, useGoodsSpecListQuery, useEntMoneyAddMutation, useEntMoneyListQuery, useEntMoneyDelMutation, useEntMoneyAppAddMutation} from '../../store/ReactWebSiteApi';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
function FirmRecharge() {
    const [param] = useSearchParams()
    //新增充值----------------------------------------------------------------------------------------------------------------新增充值//
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateAdd = (values) => {
        console.log('Received values of form: ', values);
        submitAdd({
            eai_e_id:param.get('id'),
            eai_amount:infoAdd.eai_amount,
            eai_uid:localStorage.getItem('eu_id'),
            eai_uname:localStorage.getItem('eu_name'),
            eail_content:infoAdd.eail_content,
            eail_type_id:'1',
            eail_type_name:'充值',
        }).then((res) => {
            // console.log(res);
            if (res.data.Status === 'success') {
                message.success('新增成功！')
                setOpenAdd(false)                                
            }
            if (res.data.Status === 'fail') {
                message.error('新增失败！'+res.data.Message)
            }
        })
    };
    const [infoAdd,setInfoAdd] = useState();
    const [submitAdd] = useEntMoneyAddMutation()
    const modalAdd = <Modal
                        open={openAdd}
                        title='新增充值'
                        width={1000}
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateAdd(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                                
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                        >
                            <Form.Item name="name" label='金额' wrapperCol={{span:15}} required>
                                <InputNumber  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eai_amount:e}))}}/>
                            </Form.Item>                           
                            <Form.Item name="content" label='备注说明' wrapperCol={{span:15}} required>
                                <Input onChange={(e) => {setInfoAdd(prevState=>({...prevState,eail_content:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal>
    //购买应用----------------------------------------------------------------------------------------------------------------购买应用//
    const editForm = useRef()
    //应用列表
    const [optionsApp,setOptionsApp] = useState();
    const [infoApp, setInfoApp] = useState({eail_esv_id:''})
    const {data:dataApp,isSuccess:isSuccessApp} = useSystemListQuery({esv_type_id:'1,2,3'})
    useEffect(() => {
        if(isSuccessApp){
            // console.log('dataApp',dataApp);
            if(dataApp.Status==='success'){
                const newArr = dataApp.Result.map(item => {
                    const {esv_id:key,esv_name} = item;
                    return item = {value:key,label:esv_name}
                }) 
                setOptionsApp(newArr)
            }
        }
    },[dataApp,isSuccessApp])
    //规格列表
    const [optionsType,setOptionsType] = useState();
    const {data:dataType,isSuccess:isSuccessType} = useGoodsSpecListQuery({ess_esv_id:infoApp.eail_esv_id})
    useEffect(() => {
        if(isSuccessType){
            if(dataType.Status==='success'){
                console.log(dataType,'type');
                const newArr = dataType.Result.map(item => {
                    const {ess_id:key,ess_name,ess_price,ess_num,} = item;
                    return item = {value:key,label:ess_name,ess_price:ess_price,ess_num:ess_num}
                }) 
                setOptionsType(newArr)
            }
        }
    },[dataType,isSuccessType])
    const [submitApp] = useEntMoneyAppAddMutation()
    const [openApp, setOpenApp] = useState(false);   

    const onCreateApp = () => {
        submitApp({
            eail_e_id:param.get('id'),
            eail_amount:-infoApp.ess_price,
            eail_uid:localStorage.getItem('eu_id'),
            eail_uname:localStorage.getItem('eu_name'),
            eail_content:infoApp.eail_content,
            eail_type_id:'2',
            eail_type_name:'购买应用', 
            eail_esv_id:infoApp.eail_esv_id, 
            eail_esv_name:infoApp.eail_esv_name,
            eail_ess_id:infoApp.eail_ess_id,
            eail_ess_name:infoApp.eail_ess_name,
            eail_num:infoApp.eail_num,
            ea_e_date:infoApp.ea_e_date
        }).then((res) => {
            // console.log(res);
            if (res.data.Status === 'success') {
                message.success('购买成功！')
                setOpenApp(false)                                   
            }
            if (res.data.Status === 'fail') {
                message.error('购买失败！'+res.data.Message)
            }
        })
    }
    const modalApp =   <Modal
                            open={openApp}
                            width={1000}
                            title='购买应用'
                            okText="提交"
                            cancelText="取消"
                            onCancel={() => {setOpenApp(false);}}
                            onOk={() => {
                                form
                                    .validateFields()
                                    .then((values) => {
                                        form.resetFields();
                                        onCreateApp(values);
                                    })
                                    .catch((info) => {
                                        console.log('Validate Failed:', info);
                                    });
                                
                            }}
                        >
                            <Form
                                form={form}
                                layout="horizonal"
                                name="form_client_edit"
                                labelCol={{ span: 5 }}
                                ref={editForm}
                            >
                                <Form.Item name="app" label='应用' wrapperCol={{span:5}} required>
                                    <Select 
                                        options={optionsApp} 
                                        onChange={(_,f) => {
                                            setInfoApp(prevState=>({
                                                ...prevState,
                                                eail_esv_id:f.value,
                                                eail_esv_name:f.label
                                            }))}}
                                    />
                                </Form.Item>
                                <Form.Item name="date" label='规格' wrapperCol={{span:5}} required>
                                    <Select 
                                        options={optionsType} 
                                        onChange={(_,f) => {
                                            setInfoApp(prevState=>({
                                                ...prevState,
                                                ess_price:f.ess_price,
                                                eail_ess_id:f.value,
                                                eail_ess_name:f.label,
                                                eail_num:f.ess_num
                                            }))}}
                                    />
                                </Form.Item>                        
                                <Form.Item name="eail_content" label='备注说明' wrapperCol={{span:15}} required>
                                    <Input onChange={(e) => {setInfoApp(prevState=>({...prevState,eail_content:e.target.value}))}}/>
                                </Form.Item>
                                <Form.Item name="ea_e_date" label='截止日期' wrapperCol={{span:15}} required>
                                    <DatePicker onChange={(e) => {setInfoApp(prevState=>({...prevState,ea_e_date:dayjs(e).format('YYYY-MM-DD')}))}}/>
                                </Form.Item>
                            </Form>
                        </Modal>
    //删除充值----------------------------------------------------------------------------------------------------------------删除充值//
    const {confirm} = Modal; 
    const [submitDel] = useEntMoneyDelMutation()
    const [lastData,setLastData] = useState()

    //账户列表----------------------------------------------------------------------------------------------------------------账户列表//
    
    const {data, isSuccess} = useEntMoneyListQuery({eail_e_id:param.get('id')});
    
    const [list, setList] = useState();
    useEffect(() => {
        if (isSuccess) {
            //console.log(data);
            if(data.Status==='success'){
                
                const newArr = data.Result.map(item => {
                    const {eail_id:key,eail_amount,eail_type_name,eail_content,eail_account_amount,eail_ctime,eail_uname,eail_esv_name,eail_ess_name,eail_num,ea_e_date} = item;
                    return item = {key,eail_amount,eail_type_name,eail_content,eail_account_amount,eail_ctime,eail_uname,eail_esv_name,eail_ess_name,eail_num,ea_e_date}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eail_ctime;
                    let bTimeString = b.eail_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
                const [max] = [...newArr].sort((a, b) => b.key - a.key)
                //console.log(max.key);
                setLastData(max.key)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
        const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'eail_type_name',
            title: '类型',
            dataIndex:'eail_type_name',
        },
        {
            key: 'eail_esv_name',
            title: '应用',
            dataIndex:'eail_esv_name',
        },
        {
            key: 'eail_ess_name',
            title: '规格',
            dataIndex:'eail_ess_name',
        },       
        {
            key: 'eail_num',
            title: '年限',
            dataIndex:'eail_num',
        },
        {
            key: 'eail_amount',
            title: '金额',
            dataIndex:'eail_amount',
        },
        {
            key: 'eail_account_amount',
            title: '账户余额',
            dataIndex:'eail_account_amount',
        },
        {
            key: 'eail_content',
            title: '备注',
            dataIndex:'eail_content',
            
        },      
        {
            key: 'ea_e_date',
            title: '截止日期',
            dataIndex:'ea_e_date',
            
        },      
        {
            key: 'time',
            title: '创建/修改时间',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.eail_ctime + record.eail_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>                        
                        {record.key===lastData && <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({eail_id:record.key,eail_e_id:param.get('id')}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>}
                    </Space>
                </>
            }
        },
    ]

      
    return (
        <Main t1={param.get('name')} t2='账户管理' topActive='BnsFirm' app='goldfish'>
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true)}}>新增充值</Button>
                <Button  onClick={() => {setOpenApp(true)}} style={{marginLeft:'20px'}}>购买应用</Button>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table>
                   
            {modalAdd}            
            {modalApp}
            
        </Main>
    )
}

export default FirmRecharge