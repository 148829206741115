import React from 'react'
import {Modal,message, Button} from 'antd';
import { useDept_delMutation } from '../store/ReactWebSiteApi';
function SetDeptDel(props) {
    const {confirm} = Modal; 
    const [submitDel] = useDept_delMutation()   
    return (
        <>
            {(localStorage.getItem('e_name').replace(/"/g,'') !== props.selectedDept.ed_name) && <Button onClick={() => {
                    confirm({
                        title: '确定删除吗?',
                        content: '删除后不能恢复',
                        okText: '确认',
                        okType: 'danger',
                        cancelText: '取消',
                        onOk() {
                            submitDel({ed_id:props.selectedDept.ed_id}).then((res) => {
                                if (res.data.Status === 'success') {
                                    message.success('删除部门成功！')
                                    // setSelectedDept([res.data.Result.ed_parent_id])
                                    // setAddDeptData(prevState => ({ ...prevState, ed_parent_id: res.data.Result.ed_parent_id }));
                                    // setEditDeptId(+res.data.Result.ed_parent_id);
                                    // refetch();
                                    }
                                    if (res.data.Status === 'fail') {
                                    message.error('删除部门失败！' + res.data.Message)
                                    }
                            })
                        },
                        onCancel() {
                        console.log('Cancel');
                        },
                    })
                }} 
            >删除</Button>}
        </>
    )
}

export default SetDeptDel