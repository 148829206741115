import React,{useState,useEffect,useRef}from 'react'
import { Table,Space,Modal,message,Form,Input,} from 'antd';
import Main from '../cpn/Main';
import { useEntUserAccountUpdateQuery, useEntUserAccountUpdateSaveMutation, useEntUserListQuery } from '../store/React_enterpriseApi';

function WageBank() {
    const {TextArea} = Input;
    const [form] = Form.useForm();  
    //银行卡号---------------------------------------------------------------------------------------------------------------------------------------银行卡号//
    const [editId,setEditId] = useState()
    const [openBank, setOpenBank] = useState(false);
    const [infoBank,setInfoBank] = useState({
        ear_sj_num:0,
        ear_bj_num:0,
        ear_cj_num:0,
        ear_hj_num:0,
        ear_ssj_num:0,
        ear_kg_num:0,
        ear_wc_num:0,
        ear_jb_num:0,
        ear_cd_num:0,
        ear_zt_num:0,
        ear_wjdk_num:0,
        ear_actual_num:0,
    })
    const editForm = useRef()
    // console.log(editForm);
    const {data:dataBank,isSuccess:isSuccessBank} = useEntUserAccountUpdateQuery({eu_id:editId})
    useEffect(() => {
        if(isSuccessBank){
            if(dataBank.Status==='success'){
                // console.log(dataBank,editForm);
                editForm.current.setFieldsValue({
                    eu_user_account:dataBank.Result.eu_user_account,
                })
            }
        }
    },[dataBank,isSuccessBank])
    const [entUserAccountUpdateSave] = useEntUserAccountUpdateSaveMutation()
    const onCreateBank = (values) => {       
        entUserAccountUpdateSave({
            eu_user_account:infoBank.eu_user_account,
            eu_id:editId,
        }).then((res) => {
            // console.log(res);
            if (res.data.Status === 'success') {
                message.success('修改成功！')   
                setOpenBank(false)                             
            }
            if (res.data.Status === 'fail') {
                message.error('修改失败！'+res.data.Message)
            }
        })
    };
    const modalEdit =   <Modal
                            open={openBank}
                            width={800}
                            title={`请填写${infoBank.name}的银行卡`}
                            okText="提交"
                            cancelText="取消"
                            onCancel={() => {setOpenBank(false);}}
                            onOk={() => {
                                form
                                    .validateFields()
                                    .then((values) => {
                                        form.resetFields();
                                        onCreateBank(values);
                                    })
                                    .catch((info) => {
                                        console.log('Validate Failed:', info);
                                    });
                                
                            }}
                        >
                            <Form
                                form={form}
                                layout="horizonal"
                                name="form_client_edit"
                                initialValues={{modifier: 'public',}}
                                labelCol={{ span: 3 }}
                                ref={editForm}
                            >
                                <Form.Item name="eu_user_account" label='银行卡号' wrapperCol={{span:15}}>
                                    <TextArea rows={4} onChange={(e) => {
                                        setInfoBank(prevState=>({...prevState,eu_user_account:e.target.value}))}}/>
                                </Form.Item>
                            </Form>
                        </Modal>
    //用户列表-----------------------------------------------------------------------------------------------------------------------------用户列表
    
    
    const {data,isSuccess} = useEntUserListQuery({e_id:localStorage.getItem('e_id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                // //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {eu_id:key,eu_name,eu_role,eu_esv_id,eu_user_account,eu_ba_phone} = item;
                    return item = {key,eu_name,eu_role,eu_esv_id,eu_user_account,eu_ba_phone}
                })
                const filteredArray = newArr.filter(item => item.eu_role === '1');                                
                setList(filteredArray)
            }else{
                setList()
            }
            
        }
    }, [data, isSuccess])  
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'type',
            title: '姓名',
            render:(_,record) => {
                return <>
                    <div>{record.eu_name}</div>
                    <div>{record.eu_ba_phone}</div>
                </>
            }
        },     
        {
            key: 'eu_user_account',
            title: '银行卡号',
            dataIndex: 'eu_user_account',            
        },           
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>                        
                        <a onClick={() => {                             
                            setEditId(record.key);
                            setInfoBank(prevState=>({...prevState,name:record.eu_name}))
                            setOpenBank(true);
                        }}>银行卡号</a>                   
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main topActive='WageBank' app='wage'>
            <div>
            <Form
            form={form}
            layout="horizonal"
            name="form_client"
            initialValues={{modifier: 'public',}}
            labelCol={{ span: 5 }}
            ref={editForm}
        >
            
        </Form>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalEdit}
        </Main>
    )
}

export default WageBank