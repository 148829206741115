import React, { useEffect, useRef, useState } from 'react'
import { useDijieTemplateDaoYouUpdateQuery, useDijieTemplateDaoYouUpdateSaveMutation,  } from '../../../../../store/ReactWebSiteApi';
import { Form, Input, message, Modal } from 'antd';
import GetUserDept from '../../../../../cpn/GetUserDept';

function OpGuideEdit(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState();
    const editForm = useRef()
    const {data,isSuccess} = useDijieTemplateDaoYouUpdateQuery({ltdy_id:props.ltdy_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                editForm.current.setFieldsValue({
                    ltdy_name:data.Result.ltdy_name,
                    ltdy_content:data.Result.ltdy_content,
                    ltdy_dept_name:data.Result.ltdy_dept_name,
                    ltdy_type_id:data.Result.ltdy_type_id,
                    ltdy_phone:data.Result.ltdy_phone,
                })
                setInfo(prevState=>({...prevState,
                    ltdy_name:data.Result.ltdy_name,
                    ltdy_content:data.Result.ltdy_content, 
                    ltdy_dept_name:data.Result.ltdy_dept_name,
                    ltdy_dept_id:data.Result.ltdy_dept_id,                                 
                    ltdy_phone:data.Result.ltdy_phone,                                 
                }))
            }
        }
    },[data,isSuccess])
    const [submitUpdateSave] = useDijieTemplateDaoYouUpdateSaveMutation()
    return (
        <>
            <a onClick={() => {setOpen(true)}}>修改</a>
            <Modal
                open={open}
                title='修改导游'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateEdit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitUpdateSave({
                        ltdy_content: info.ltdy_content,
                        ltdy_dept_id: info.ltdy_dept_id,
                        ltdy_dept_name: info.ltdy_dept_name,
                        ltdy_e_id: localStorage.getItem('e_id'),
                        ltdy_e_name: localStorage.getItem('e_name'),
                        ltdy_name: info.ltdy_name,
                        ltdy_phone: info.ltdy_phone,
                        ltdy_update_uid: localStorage.getItem('eu_id'),
                        ltdy_update_uname:localStorage.getItem('eu_name'),  
                        ltdy_id:props.ltdy_id,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')   
                            setOpen(false)                             
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                    ref={editForm}
                >
                    <GetUserDept
                        label='部门' 
                        name='ltdy_dept_name'
                        required 
                        wrapperCol={{span:18}}
                        getUserDept={(e,f) => {setInfo(prevsState=>({...prevsState,ltdy_dept_id:e,ltdy_dept_name:f}))}}
                    />  
                    <Form.Item name="ltdy_name" label='姓名' wrapperCol={{span:18}} required>
                        <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,ltdy_name:e.target.value}))}} />  
                    </Form.Item>                              
                    <Form.Item 
                        name="ltdy_phone" 
                        label='手机号码' 
                        wrapperCol={{span:18}} 
                        validateTrigger="onBlur"
                        rules={[{
                            required: true,
                            message: '请填写手机号!',
                        },
                        {
                        pattern: /^1[3-9]\d{9}$/,
                        message: '请输入正确的手机号'
                        }]}
                    >
                        <Input 
                            onChange={(e) => {setInfo(prevsState=>({...prevsState,ltdy_phone:e.target.value}))}} 
                        />  
                    </Form.Item>                              
                    <Form.Item name="ltdy_content" label='备注' wrapperCol={{span:18}}>
                        <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,ltdy_content:e.target.value}))}} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default OpGuideEdit