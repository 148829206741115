import { Select,Form,Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useEntUserListQuery } from '../store/ReactWebSiteApi';

function GetUsers(props) {
        const options = [];
        for (let i = 10; i < 36; i++) {
        options.push({
            label: i.toString(36) + i,
            value: i.toString(36) + i,
        });
    }
    const [list,setList] = useState();
    const {data,isSuccess} =useEntUserListQuery({e_id:localStorage.getItem('e_id')})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                // console.log(data);
                const newArr = data.Result.map((item) => {
                    const {eu_id,eu_name,euda_ed_id}=item;
                    return item = {value:eu_id,label:eu_name,ed_id:euda_ed_id}
                })
                setList(newArr)
            }
        }
    },[data,isSuccess])
    return (
        <>
            <Form.Item 
                label={props.label} 
                wrapperCol={{span:10}} 
                required={props.required}
                name={props.name}
            >
                <Space
                    style={{
                        width: '100%',
                    }}
                    direction="vertical"
                >
                    <Select
                        showSearch
                        optionFilterProp="label"
                        mode="multiple"
                        allowClear
                        style={{
                            width: '100%',
                        }}
                        placeholder="请选择用户"
                        onChange={(e,f) => {props.onChange(e,f)}}
                        options={list}
                        defaultValue={props.defaultId}
                    />                    
                </Space>
            </Form.Item>
        </>
    )
}

export default GetUsers