import React, { useState } from 'react';
import jsonp from 'fetch-jsonp';
import qs from 'qs';
import { Select,Form, } from 'antd';
//选择框
let timeout;
let currentValue;
const fetch = (value, callback) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;
    const fake = () => {
        const str = qs.stringify({
            code: 'utf-8',
            search_key: value,
            action:'search',
            e_id:localStorage.getItem('e_id'),
            eu_id:localStorage.getItem('eu_id'),
        });
        jsonp(`/api/ReactWebSite/ebnJsonp?${str}`)
        .then((response) => response.json())
        .then((d) => {
            console.log(d);
            if (currentValue === value) {
            const { Result } = d;           

            const data = Result.map((item) => ({
                value: item.ebn_team_num,
                text: item.ebn_team_num,
            }));
            callback(data);
            }
        });
    };
    timeout = setTimeout(fake, 300);
};
const GetGroupNum = (props) => {

    const { Option } = Select;
    const [data, setData] = useState([]);
    const [value, setValue] = useState(props.defaultValue);
    const handleSearch = (newValue) => {
        if (newValue) {
            fetch(newValue, setData);
        } else {
            setData([]);
        }
    };
    const handleChange = (newValue,event) => {
            setValue(newValue);            
            if(event){
                props.getGroupNum(event.key,event.children);  
            }
        };
    const options = data.map((d) => <Option key={d.value} value={d.value} content={d.content} type={d.type}>{d.text}</Option>);
    // const defaultOption = <Option key={props.defaultValue}>{props.defaultLabel}</Option>

    
    return (        
        <Form.Item
            label={props.label} 
            wrapperCol={props.wrapperCol} 
            required={props.required}
            name={props.name}
        >
            <Select
                showSearch
                value={value}                        
                placeholder='请在下拉菜单中选择！'
                defaultActiveFirstOption={false}
                suffixIcon={null}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                notFoundContent={null}
                disabled={props.disabled}
                style={props.style}
            >
                {options}
            </Select>
        </Form.Item>
    );
};

export default GetGroupNum;