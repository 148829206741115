import React from 'react'
import Main from '../../cpn/Main'
import {Row} from 'antd'
import Folder from '../../cpn/Folder'
import { indexFinance } from '../balance/balanceType'

function ElseTable() {    
    return (
        <Main   t1='财务' n1={indexFinance} t2='其他收支' n2='/ElseIndex' t3='报表'>
            <Row>                
                <Folder title='部门其他收支表'  link='/ElseSumDept' />            
                <Folder title='客户其他收支表'  link='/ElseSumClient' />                
                <Folder title='其他收入类型表'  link='/ElseIncomeSumType' />                
                <Folder title='其他支出类型表'  link='/ElsePaidSumType' />                
            </Row>

        </Main>
    )
}

export default ElseTable