import React ,{useRef,useState,useEffect} from 'react'
import { useUser_update_saveMutation, useUser_updateQuery } from '../store/ReactWebSiteApi';
import { Form, Input, Modal,message,Radio,} from 'antd';
import GetPost from '../cpn/GetPost';
import GetAllDeptCheckbox from './GetAllDeptCheckBox';
import GetDept from '../cpn/GetDept';
function SetUserEdit(props) {
    const [form] = Form.useForm();
    const editForm = useRef(); 
    //右边的修改用户版面
    const [open, setOpen] = useState(false); 
    //修改用户的部门名称
    //const [editStaffDeptName, setEditStaffDeptName] = useState();
    //上传修改部门的ID,获取服务器数据
    // const { data: editStaffDeptData, isSuccess: editStaffDeptDataIsSuccess } = useAddStaffByIdQuery({ 'ed_id': selectedDept[0] }, {
    //如果部门未选中则跳过，新增状态为false也跳过
    //     skip: !selectedDept[0] || !open,
    //     refetchOnMountOrArgChange:1,
    // });
    //加载数据
    // useEffect(() => {
    //     if (editStaffDeptDataIsSuccess) {      
    //         try {
    //             setEditStaffDeptName(editStaffDeptData.Result.ed_name);
    //         }catch (error) {
    //             console.log(error);
    //         }
    //     }
    // }, [editStaffDeptData, editStaffDeptDataIsSuccess, editStaffDeptName])
    //岗位

    //用户修改数据
    const [info, setInfo] = useState({
        eu_e_id:localStorage.getItem('e_id'),  
        eu_id: '',  
        eu_name: '',
        eu_ba_phone:'',
        eu_uid: localStorage.getItem('eu_id'),
        eu_uname: localStorage.getItem('eu_name'),
        parent_dept_id: props.parent_dept_id,
        eu_station_id:'',
    });   
    const {data,isSuccess} = useUser_updateQuery({eu_id:props.eu_id},{refetchOnMountOrArgChange:1,skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                editForm.current.setFieldsValue({
                    eu_name: data.Result.eu_name,
                    eu_ba_phone: data.Result.eu_ba_phone,
                    eu_role: data.Result.eu_role,
                    eu_dept_id: data.Result.eu_dept_id,
                    ba_show: data.Result.ba_show,
                    eu_show_authority: data.Result.eu_show_authority,                    
                    eu_station_id: [data.Result.ess_id],                    
                    parent_dept_id: [data.Result.ed_id],  
                                      
                })
                setInfo(prevState => ({
                    ...prevState,
                    eu_name: data.Result.eu_name,
                    eu_ba_phone: data.Result.eu_ba_phone,
                    eu_role: data.Result.eu_role,
                    eu_dept_id: data.Result.eu_dept_id,
                    eu_show_authority: data.Result.eu_show_authority,
                    parent_dept_id: data.Result.ed_id,
                    eu_station_id: data.Result.eu_station_id,
                    eu_station_name: data.Result.eu_station_name,
                }))
                    
            }
        }
    },[data,isSuccess])
    const [submitEditUser] = useUser_update_saveMutation();
    return (
        <>
            {props.eu_state==='1' && <a  onClick={() => {
                                setOpen(true);  
                                setInfo(prevState=>({...prevState,eu_id:props.key,}));
                                // setExpandedDept(props.ed_all_parent_id);
                                // setSelectedDept([props.euda_ed_id]) 
                            }}
                        >修改</a>}
            <Modal
                open={open}
                title='修改用户11'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitEditUser({
                        eu_id:props.eu_id,
                        eu_e_id:localStorage.getItem('e_id'),
                        eu_name:info.eu_name,
                        eu_role:info.eu_role,
                        eu_uid: localStorage.getItem('eu_id'),
                        eu_uname: localStorage.getItem('eu_name'),
                        parent_dept_id:info.parent_dept_id,
                        eu_ba_phone:info.eu_ba_phone, 
                        eu_dept_id:info.eu_dept_id,
                        eu_show_authority:info.eu_show_authority,
                        eu_station_id:info.eu_station_id,
                    }).then((res) => {
                            if (res.data.Status === 'success') {
                                message.success('修改用户成功！'); 
                                setOpen(false) 
                            }
                            if (res.data.Status === 'fail') {
                                message.error('修改用户失败！' + res.data.Message);
                            }
                        });
                }}
                width={'800px'}
            >
                <Form
                    name="basic"
                    labelCol={{span: 4,}}
                    wrapperCol={{span: 20,}}
                    initialValues={{remember: true,}}
                    autoComplete="off"
                    ref={editForm}
                >
                    <GetDept 
                        label='部门'
                        wrapperCol={{span:10}}
                        getDetp={(e) => {setInfo(prevState=>({...prevState,parent_dept_id:e}))}} 
                        name='parent_dept_id'
                        required
                    />
                    <Form.Item
                        label="姓名"
                        name="eu_name"
                        rules={[{
                            required: true,
                            message: '请填写姓名!',
                        },]}
                        wrapperCol={{span: 4,}}
                    >
                        <Input onChange={(e) => {setInfo(prevState => ({...prevState,eu_name:e.target.value}))}} />
                    </Form.Item>
                    <Form.Item
                        label="登录手机"
                        name="eu_ba_phone"
                        rules={[{
                            required: true,
                            message: '请填写登录手机!',
                        },
                        {
                        pattern: /^1[3-9]\d{9}$/,
                        message: '请输入正确的手机号'
                            }]}
                        wrapperCol={{span: 4,}}
                    >
                        <Input disabled onChange={(e) => {setInfo(prevState => ({...prevState,eu_ba_phone:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item
                        label="系统权限"
                        name="eu_role"
                        required
                    >
                        <Radio.Group onChange={(e) => {setInfo(prevState => ({...prevState,eu_role:e.target.value}))}}>
                            <Radio value='1'>普通用户</Radio>
                            <Radio value='2'>系统管理员</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <GetPost 
                        label='岗位' 
                        name='eu_station_id'
                        getPost={(e) => {setInfo(prevState=>({...prevState,eu_station_id:e,}))}} 
                        required={true} 
                        wrapperCol={{span:10}}
                    />       
                    <GetAllDeptCheckbox 
                        label='操作部门权限'
                        name='eu_dept_id'
                        required={true} 
                        getAllDept={(e) => {setInfo(prevState => ({...prevState,eu_dept_id:e}))}}
                    />              
                        {/* <Form.Item label='查看权限' name='eu_show_authority' required >
                            <Radio.Group onChange={(e) => {setInfo(prevState => ({...prevState,eu_show_authority:e.target.value}));}}>
                                <Radio value='1'>只能查看自己新建的数据</Radio>
                                <Radio value='2'>可以查看其他人的数据</Radio>
                            </Radio.Group>                            
                        </Form.Item>                                     */}                                    
                    
                </Form>
            </Modal>
        </>
    )
}

export default SetUserEdit