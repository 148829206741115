import React,{useRef, useState}from 'react'
import { Form,Input,message, Radio} from 'antd';
import {  useDijieTemplateJieDaiBiaoZhunAddMutation,} from '../../../../../store/ReactWebSiteApi';
import ModalAdd from '../../../../../cpn/ModalAdd';
import GetUserDept from '../../../../../cpn/GetUserDept';
import { localCostType } from '../../customType';
function StandardAdd(props) {
    const [form] = Form.useForm();  
    const [info,setInfo] = useState();
    const [submitAdd] = useDijieTemplateJieDaiBiaoZhunAddMutation()
    const [open,setOpen] = useState(false)
    const editForm = useRef()
    return (
        <ModalAdd 
            title='接待标准模板'
            onOk={(e) => {
                submitAdd({
                    ltjdbz_e_id:localStorage.getItem('e_id'),
                    ltjdbz_uid:localStorage.getItem('eu_id'),
                    ltjdbz_uname:localStorage.getItem('eu_name'),
                    ltjdbz_dept_id:props.ltbjd_dept_id?props.ltbjd_dept_id:info.ltjdbz_dept_id,
                    ltjdbz_type_id:info.ltjdbz_type_id,
                    ltjdbz_name:info.ltjdbz_name,
                }).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('新增成功！') 
                        setOpen(false)                                                 
                    }
                    if (res.data.Status === 'fail') {
                        message.error('新增失败！'+res.data.Message)
                    }
                })
            }}
            open={open}
            getOpen={(e) => {
                setOpen(prevsState=>e)
                if(e){
                    setInfo(prevState=>({...prevState,ltjdbz_dept_id:'',ltjdbz_name:'',}))
                    form.resetFields(); 
                }
            }}
        >
            <Form
                ref={editForm}
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 3 }}
            >                                           
                {!props.ltbjd_dept_id && <GetUserDept 
                    label='部门' 
                    name='dept'
                    required 
                    wrapperCol={{span:5}}
                    getUserDept={(e) => {setInfo(prevsState=>({...prevsState,ltjdbz_dept_id:e}))}}
                />}
                <Form.Item 
                    name="type" 
                    label='类型' 
                    wrapperCol={{span:20}} 
                    required 
                >
                    <Radio.Group 
                        optionType="button" 
                        options={localCostType}
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltjdbz_type_id:e.target.value}))}}
                    ></Radio.Group>
                </Form.Item>
                <Form.Item name="remark" label='接待标准' wrapperCol={{span:20}} required>
                    <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,ltjdbz_name:e.target.value}))}}/>
                </Form.Item>
            </Form>
        </ModalAdd>
    )
}

export default StandardAdd