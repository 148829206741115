import React from 'react'
import classes from './web.module.css'
function TaPriceIndex() {
    return (
        <div style={{padding:'30px'}}>
            <div className={classes.priceTitle1}>旅当家系统有多个模块，旅行社请点击右侧菜单查看。</div>
            <div style={{marginTop:"20px",fontSize:'20px'}}>如需购买或咨询请点击<a onClick={() => {window.open('https://work.weixin.qq.com/kfid/kfcc1a9500fb365f216')}}>在线客服</a></div>
        </div>
    )
}

export default TaPriceIndex