import React,{useState}from 'react'
import { Modal,message,Form,Input,Button,Space} from 'antd';
import { useGoodsTypeAddMutation,} from '../../store/ReactWebSiteApi';
function GoodsTypeAdd(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState();
    const [submitAdd] = useGoodsTypeAddMutation()
    return (
        <>
            <Button onClick={() => {setOpen(true);}} type='primary'>新增</Button>
            <Modal
                open={open}
                title='新增产品类型'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        egt_name:info,
                        egt_e_id:localStorage.getItem('e_id'),
                        egt_uid:localStorage.getItem('eu_id'),
                        egt_uname:localStorage.getItem('eu_name'),
                        egt_esv_id:props.egt_esv_id
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 7 }}
                >                            
                    <Form.Item label='常用产品类型' name='use' wrapperCol={{span:17}}>
                        <Space wrap>
                            <a onClick={() => {
                                form.setFieldsValue({name:'国内游'})
                                setInfo('国内游')
                                }}
                            >国内游</a>
                            <a onClick={() => {
                                form.setFieldsValue({name:'周边游'})
                                setInfo('周边游')
                                }}
                            >周边游</a>
                            <a  onClick={() => {
                                form.setFieldsValue({name:'出境游'})
                                setInfo('出境游')
                                }}
                            >出境游</a>
                            <a  onClick={() => {
                                form.setFieldsValue({name:'订门票'})
                                setInfo('订门票')
                                }}
                            >订门票</a>
                            <a  onClick={() => {
                                form.setFieldsValue({name:'订房'})
                                setInfo('订房')
                                }}
                            >订房</a>
                            <a  onClick={() => {
                                form.setFieldsValue({name:'包车'})
                                setInfo('包车')
                                }}
                            >包车</a>
                            <a  onClick={() => {
                                form.setFieldsValue({name:'一日游'})
                                setInfo('一日游')
                                }}
                            >一日游</a>
                            <a  onClick={() => {
                                form.setFieldsValue({name:'二日游'})
                                setInfo('二日游')
                                }}
                            >二日游</a>
                            <a  onClick={() => {
                                form.setFieldsValue({name:'三日游'})
                                setInfo('三日游')
                                }}
                            >三日游</a>
                        </Space>
                    </Form.Item>
                    <Form.Item name="name" label='产品类型' wrapperCol={{span:10}} required>
                        <Input  onChange={(e) => {setInfo(e.target.value)}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default GoodsTypeAdd