import React,{useState}from 'react'
import { Modal,message,Form, InputNumber, Input, Radio} from 'antd';
import { useDijieBaoJiaDanDaoYouFeiYongAddMutation, } from '../../../../../store/ReactWebSiteApi';
import { guidePlanAgentType,} from '../../customType';
const {TextArea} = Input
function GuidePlanAgentAdd(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({
        bjddyfy_name:'',
        bjddyfy_content:'',
    });
    const [submitAdd] = useDijieBaoJiaDanDaoYouFeiYongAddMutation()
    return (
        <>
            <a onClick={() => {
                setInfo(prevState=>({
                    ...prevState,                   
                    bjddyfy_name:'',
                    bjddyfy_content:'',
                }))
                setOpen(true)
                }} >新增</a>
            <Modal
                open={open}
                title='新增代收代付'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        bjddyfy_bjd_id:props.bjddyfy_bjd_id,
                        bjddyfy_type:info.bjddyfy_type,
                        bjddyfy_amount:info.bjddyfy_amount,
                        bjddyfy_content:info.bjddyfy_content,
                        bjddyfy_uid:localStorage.getItem('eu_id'),
                        bjddyfy_uname:localStorage.getItem('eu_name'),
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
                width={600}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 4 }}
                >                                           
                    <Form.Item 
                        name="type" 
                        label='类型' 
                        wrapperCol={{span:20}} 
                        required 
                    >
                        <Radio.Group 
                            optionType="button" 
                            options={guidePlanAgentType}
                            onChange={(e) => {
                                setInfo(prevState=>({
                                    ...prevState,
                                    bjddyfy_type:e.target.value
                                }))}}
                        />
                    </Form.Item>
                    <Form.Item label='金额' wrapperCol={{span:20}} >
                        <InputNumber 
                            onChange={(e) => {
                                setInfo(prevState=>({
                                    ...prevState,
                                    bjddyfy_amount:e
                                }))}}
                        />
                    </Form.Item>
                    <Form.Item label='说明' wrapperCol={{span:20}} >
                        <TextArea  
                            onChange={(e) => {
                                setInfo(prevState=>({
                                    ...prevState,
                                    bjddyfy_content:e.target.value
                                }))}}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default GuidePlanAgentAdd