import React,{useState} from 'react'
import {Form,Modal, Input,message, DatePicker,Rate,Button, InputNumber} from 'antd'
import GetUsers from '../cpn/GetUsers';
import MyEditor from '../cpn/MyEditor';
import { useTaskManageAddMutation } from '../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import { FireOutlined} from '@ant-design/icons';
function TaskAdd(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        // console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [submit] = useTaskManageAddMutation();
    const [info,setInfo] = useState({
        et_content: '',
        et_e_id: localStorage.getItem('e_id'),
        et_name: '',
        et_uid: localStorage.getItem('eu_id'),
        et_uname: localStorage.getItem('eu_name'),
        etu_user_id: '',
        etu_user_name: '',
        et_date:'',
        et_grade:'1',
        et_gs:'0'
    });
    return (
        <>
            <Button type='primary' onClick={() => {setOpen(true);}}>新增</Button>
            <Modal
                open={open}
                title='新增任务'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                            // console.log(values);                           
                            submit(info).then((res) => {
                                // console.log(res);
                                if (res.data.Status === 'success') {
                                    props.afterAdd({
                                        etu_user_id:info.etu_user_id,
                                    })
                                    message.success('新增成功！')  
                                    setOpen(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            }) 
                        })
                        .catch((info) => {
                            // console.log('Validate Failed:', info);
                        }); 
                                               
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}                    
                >                                                       
                    <GetUsers 
                        label='参与人' 
                        required='true' 
                        name='charger'
                        onChange={(e,f) => {
                            let etu_user_id;
                            let etu_user_name;
                            etu_user_id = f.map((item) => {
                                return item.value
                            })
                            etu_user_name = f.map((item) => {
                                return item.label
                            })
                            setInfo(prevState=>({...prevState,etu_user_id:etu_user_id+'',etu_user_name:etu_user_name+''}))
                        }}
                    />
                    <Form.Item name="name" label='标题' wrapperCol={{span:15}} required >                        
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,et_name:e.target.value,}))}}/>
                    </Form.Item>                       
                    <Form.Item name="content" label='备注' wrapperCol={{span:15}}>
                        <MyEditor  onChange={(e) => {setInfo(prevState=>({...prevState,et_content:e}))}}/>
                    </Form.Item>
                    <Form.Item name="date" label='截止日期' wrapperCol={{span:15}} required>
                        <DatePicker onChange={(e) => {setInfo(prevState=>({...prevState,et_date:dayjs(e).format('YYYY-MM-DD')}))}}/>
                    </Form.Item>                       
                    <Form.Item name="grade" label='优先级' wrapperCol={{span:15}} required>
                        <Rate 
                            character={<FireOutlined />} 
                            onChange={(e) => {
                                console.log(e);
                                setInfo(prevState=>({...prevState,et_grade:e}))
                            }} 
                            value={info.et_grade}
                        />
                    </Form.Item>
                    <Form.Item name="time" label='工时' wrapperCol={{span:15}} initialValue={'0'}>                        
                        <InputNumber  onChange={(e) => {setInfo(prevState=>({...prevState,et_gs:e,}))}}/>
                    </Form.Item>                       
                </Form>
            </Modal>
        </>
    )
}

export default TaskAdd