import React from 'react'
import BalanceTypeList from '../../finance/balance/BalanceTypeList'
import Main from '../../cpn/Main'
function TaFinanceLedgerCostType() {
    return (
        <Main t1='财务' n1='/TaFinanceIndex' t2='设置' n2='/TaFinanceSetIndex'  t3='团队支出单类型'>
            <BalanceTypeList type_id='2' title='团队支出单类型'/>
        </Main>
    )
}

export default TaFinanceLedgerCostType