import React,{useState,useEffect, useRef} from 'react'
import { Form,Modal, Input,DatePicker,message, } from 'antd'
import { useTaskManageAddMutation } from '../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import GetUsers from '../cpn/GetUsers';
import MyEditor from '../cpn/MyEditor';
function TaskAddCopy(props) {
    const [form] = Form.useForm();
    const onCreate = (values) => {
        // console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [open,setOpen] = useState()
    const [info,setInfo] = useState({
        et_content: '',
        et_e_id: localStorage.getItem('e_id'),
        et_name: '',
        et_uid: localStorage.getItem('eu_id'),
        et_uname: localStorage.getItem('eu_name'),
        etu_user_id: '',
        etu_user_name: '',
        et_date:'',
    });
    
    const editForm = useRef();
    useEffect(() => {
        if(props && open){
            editForm.current.setFieldsValue({
                et_date:dayjs(props.et_date),
                et_content:props.et_content,
                et_name:props.et_name,  
                etu_user_id:props.etu_user_id,               
            });
        }
        setInfo(prevState=>({...prevState,
            et_date:props.et_date,
            et_content:props.et_content,
            et_name:props.et_name, 
            etu_user_name:props.etu_user_name,
            etu_user_id:props.etu_user_id, 
        }))
    },[props,open])
    const [summitUpdateSave] = useTaskManageAddMutation()
    return (
        <>
            <a onClick={() => {setOpen(true)}}>新增</a>
            <Modal
                open={open}
                title='新增任务'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                            // console.log('Validate Failed:', info);
                        }); 
                        summitUpdateSave(info).then((res) => {
                            // console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('新增成功！')                                
                                setOpen(false)                         
                            }
                            if (res.data.Status === 'fail') {
                                message.error('新增失败！'+res.data.Message)
                                
                            }
                        })                           
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                    ref={editForm}
                >               
                   
                    {props.etu_user_id && <GetUsers 
                        defaultId={props.etu_user_id}
                        label='参与人' 
                        required='true' 
                        name='etu_user_id'
                        onChange={(e,f) => {
                            let etu_user_id;
                            let etu_user_name;
                            etu_user_id = f.map((item) => {
                                return item.value
                            })
                            etu_user_name = f.map((item) => {
                                return item.label
                            })
                            setInfo(prevState=>({...prevState,etu_user_id:etu_user_id+'',etu_user_name:etu_user_name+''}))
                        }}
                    />}
                    <Form.Item name="et_name" label='标题' wrapperCol={{span:15}} required >
                        <Input onChange={(e) => {setInfo(prevState=>({...prevState,et_name:e.target.value,}))}}/>
                    </Form.Item>                       
                    <Form.Item name="et_content" label='备注' wrapperCol={{span:15}}>
                        <MyEditor  onChange={(e) => {setInfo(prevState=>({...prevState,et_content:e}))}}/>
                    </Form.Item>
                    <Form.Item name="et_date" label='截止日期' wrapperCol={{span:15}} required>
                        <DatePicker onChange={(e) => {setInfo(prevState=>({...prevState,et_date:dayjs(e).format('YYYY-MM-DD')}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default TaskAddCopy