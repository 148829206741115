import React from 'react'
import 'antd/dist/reset.css';
import { Route, Routes } from 'react-router-dom'
import AlbumGroup from './album/AlbumGroup'
import AlbumIndex from './album/AlbumIndex'
import AlbumGroupDetail from './album/AlbumGroupDetail'
import AlbumSection from './album/AlbumSection'
import AlbumPic from './album/AlbumPic'
import AlbumMan from './album/AlbumMan'
import AppCourse from './gf/app/AppCourse'
import AppIndex from './gf/app/AppIndex'
import AppLimit from './gf/app/AppLimit'
import AppSpec from './gf/app/AppSpec'
import AppSet from './gf/app/AppSet'
import ArapAdd from './finance/arap/ArapAdd'
import ArapList from './finance/arap/ArapList'
import ArapIndex from './finance/arap/ArapIndex'
import AreaIndex from './gf/area/AreaIndex'
import AppPower from './gf/app/AppPower'
import ArapStatementSend from './finance/arap/ArapStatementSend'
import ArapStatementReceive from './finance/arap/ArapStatementReceive'
import ArapStatementReceiveCover from './finance/arap/ArapStatementReceiveCover'
import ArapSumClient from './finance/arap/ArapSumClient'
import ArapSumClientType from './finance/arap/ArapSumClientType'
import ArapSumGoodsType from './finance/arap/ArapSumGoodsType'
import ArapSumGoods from './finance/arap/ArapSumGoods'
import ArapSumOperator from './finance/arap/ArapSumOperator'
import ArapSumSalesman from './finance/arap/ArapSumSalesman'
import ArapSumDept from './finance/arap/ArapSumDept'
import ArapTable from './finance/arap/ArapTable'
import Balance from './keeper/balance/Balance'
import BalanceIndex from './finance/balance/BalanceIndex'
import BalanceTypeList from './finance/balance/BalanceTypeList'
import BankAccount from './keeper/bank/BankAccount'
import BankAccountDetail from './keeper/bank/BankAccountDetail'
import Business from './keeper/business/Business'                                
import City from './gf/area/City'
import CityAdd from './gf/area/CityAdd'
import CityEdit from './gf/area/CityEdit'
import Clocking from './wage/Clocking'
import ClientIndex from './finance/client/ClientIndex'
import Client from './finance/client/Client'
import CommodityType from './gf/commodity/CommodityType'
import Country from './gf/area/Country'
import CountryAdd from './gf/area/CountryAdd'
import CountryEdit from './gf/area/CountryEdit'
import CustomFeedback from './ta/local/custom/temp/feedback/CustomFeedback'
import CustomFile from './ta/local/custom/file/CustomFile'
import CustomFileIndex from './ta/local/custom/CustomFileIndex'
import CustomGoodsLocal from './ta/local/custom/goods/CustomGoodsLocal'
import CustomGoodsOrgan from './ta/local/custom/goods/CustomGoodsOrgan'
import CustomGoodsSelf from './ta/local/custom/goods/CustomGoodsSelf'
import CustomGroup from './ta/local/custom/group/CustomGroup'
import CustomIndex from './ta/local/custom/CustomIndex'
import CustomClient from './ta/local/custom/temp/client/CustomClient'
import CustomCrowd from './ta/local/custom/temp/crowd/CustomCrowd'
import CustomTempIndex from './ta/local/custom/temp/CustomTempIndex'
import CustomLineName from './ta/local/custom/temp/lineName/CustomLineName'
import CustomStandard from './ta/local/custom/temp/standard/CustomStandard'
import CustomNotes from './ta/local/custom/temp/notes/CustomNotes'
import CustomPage from './ta/local/custom/temp/page/CustomPage'
import CustomPrice from './ta/local/custom/temp/price/CustomPrice'
import CustomRoute from './ta/local/custom/temp/route/CustomRoute'
import CustomSelf from './ta/local/custom/temp/self/CustomSelf'
import Destination from './gf/area/Destination'
import DestinationAdd from './gf/area/DestinationAdd'
import DestinationEdit from './gf/area/DestinationEdit'
import District from './gf/area/District'
import DistrictAdd from './gf/area/DistrictAdd'
import DistrictEdit from './gf/area/DistrictEdit'
import ElseIncome from './finance/else/ElseIncome'
import ElseIncomeSumType from './finance/else/ElseIncomeSumType'
import ElseIndex from './finance/else/ElseIndex'
import ElsePaid from './finance/else/ElsePaid'
import ElsePaidSumType from './finance/else/ElsePaidSumType'
import ElseSumClient from './finance/else/ElseSumClient'
import ElseSumDept from './finance/else/ElseSumDept'
import ElseTable from './finance/else/ElseTable'
import EvaluateIndex from './evaluate/EvaluateIndex'
import EvaluateGroup from './evaluate/EvaluateGroup'
import EvaluateGroupDetail from './evaluate/EvaluateGroupDetail'
import EvaluateGroupPoint from './evaluate/EvaluateGroupPoint'
import Expense from './finance/expense/Expense'
import ExpenseIndex from './finance/expense/ExpenseIndex'
import ExpenseProfitDept from './finance/expense/ExpenseProfitDept'
import ExpenseSumClient from './finance/expense/ExpenseSumClient'
import ExpenseSumDept from './finance/expense/ExpenseSumDept'
import ExpenseSumType from './finance/expense/ExpenseSumType'
import ExpenseTable from './finance/expense/ExpenseTable'
import FirmAccount from './gf/firm/FirmAccount'
import FirmApp from './gf/firm/FirmApp'
import FirmIndex from './gf/firm/FirmIndex'
import FirmList from './gf/firm/FirmList'
import FirmOrder from './gf/firm/FirmOrder'
import FirmSumArap from './gf/firm/FirmSumArap'
import FirmSumBalance from './gf/firm/FirmSumBalance'
import FirmSumLedger from './gf/firm/FirmSumLedger'
import FirmSumTask from './gf/firm/FirmSumTask'
import FirmRecharge from './gf/firm/FirmRecharge'
import FirmTypeBalance from './gf/firm/FirmTypeBalance'
import FirmTypeGoods from './gf/firm/FirmTypeGoods'
import FirmUser from './gf/firm/FirmUser'
import FundAccount from './finance/fund/FundAccount'
import FundAdd from './finance/fund/FundAdd'
import FundIndex from './finance/fund/FundIndex'
import FundList from './finance/fund/FundList'
import FundTable from './finance/fund/FundTable'
import FundSumClient from './finance/fund/FundSumClient'
import FundSumClientType from './finance/fund/FundSumClientType'
import FundSumDept from './finance/fund/FundSumDept'
import FundSumGoods from './finance/fund/FundSumGoods'
import FundSumGoodsType from './finance/fund/FundSumGoodsType'
import FundSumOperator from './finance/fund/FundSumOperator'
import FundSumSalesman from './finance/fund/FundSumSalesman'
import GfIndex from './gf/GfIndex'
import GfIndexFinance from './gf/GfIndexFinance'
import GfIndexMall from './gf/GfIndexMall'
import GfIndexPersonnel from './gf/GfIndexPersonnel'
import GoodsIndex from './finance/goods/GoodsIndex'
import GoodsList from './finance/goods/GoodsList'
import GoodsTypeList from './finance/goods/GoodsTypeList'
import GuideIndex from './gf/guide/GuideIndex'
import GuideList from './gf/guide/GuideList'
import Home from './home/Home'
import IndexSecond from './site/IndexSecond'
import InAndOut from './keeper/asset/InAndOut'
import Inventory from './keeper/asset/Inventory'
import Keeper from './keeper/Keeper'
import Landmark from './gf/area/Landmark'
import LandmarkAdd from './gf/area/LandmarkAdd'
import LandmarkEdit from './gf/area/LandmarkEdit'
import LedgerArapClient from './ta/ledger/LedgerArapClient'
import LedgerArapDept from './ta/ledger/LedgerArapDept'
import LedgerArapStatement from './ta/ledger/LedgerArapStatement'
import LedgerBalance from './ta/ledger/LedgerBalance'
import LedgerGroup from './ta/ledger/LedgerGroup'
import LedgerGroupCheck from './ta/ledger/LedgerGroupCheck'
import LedgerIndex from './ta/ledger/LedgerIndex'
import LedgerStartdate from './ta/ledger/LedgerStartdate'
import LedgerSumClient from './ta/ledger/LedgerSumClient'
import LedgerSumClientType from './ta/ledger/LedgerSumClientType'
import LedgerSumDept from './ta/ledger/LedgerSumDept'
import LedgerSumGoods from './ta/ledger/LedgerSumGoods'
import LedgerSumGoodsType from './ta/ledger/LedgerSumGoodsType'
import LedgerSumOperator from './ta/ledger/LedgerSumOperator'
import LedgerSumSalesman from './ta/ledger/LedgerSumSalesman'
import LedgerTable from './ta/ledger/LedgerTable'
import LocalIndex from './ta/local/LocalIndex'
import LocalShop from './ta/local/LocalShop'
import LoginGate from './cpn/LoginGate'
import Login from './site/Login'
import MineIndex from './mine/MineIndex'
import MineInfo from './mine/MineInfo'
import MinePassword from './mine/MinePassword'
import NetTechContract from './gf/netTech/NetTechContract'
import NetTechContractSerial from './gf/netTech/NetTechContractSerial'
import NetTechContractSerialIncome from './gf/netTech/NetTechContractSerialIncome'
import NetTechContractSerialCost from './gf/netTech/NetTechContractSerialCost'
import NetTechGoods from './gf/netTech/NetTechGoods'
import NetTechSerial from './gf/netTech/NetTechSerial'
import NetTechIncome from './gf/netTech/NetTechIncome'
import NetTechCost from './gf/netTech/NetTechCost'
import NoPower from './site/NoPower'
import OrganIndex from './ta/organ/OrganIndex'
import OrganCustomIndex from './ta/organ/custom/OrganCustomIndex'
import PettyCash from './keeper/balance/PettyCash'
import PhoneShop from './ta/organ/phoneShop/PhoneShop'
import PhoneShopGoods from './ta/organ/phoneShop/PhoneShopGoods'
import PhoneShopIndex from './ta/organ/phoneShop/PhoneShopIndex'
import Province from './gf/area/Province'
import ProvinceAdd from './gf/area/ProvinceAdd'
import ProvinceEdit from './gf/area/ProvinceEdit' 
import Profit from './keeper/balance/Profit'
import Personal from './personal/Personal'
import Register from './site/Register'
import SetAppPayed from './set/SetAppPayed'
import SetAppLimits from './set/SetAppLimits'
import SetAppNotPay from './set/SetAppNotPay'
import SetCourse from './set/SetCourse'
import SetDept from './set/SetDept'
import SetIndex from './set/SetIndex'
import SellIncome from './gf/sell/SellIncome'
import SetPost from './set/SetPost'
import SetRole from './set/SetRole'
import SetSubscription from './set/SetSubscription'
import Street from './gf/area/Street'
import StreetAdd from './gf/area/StreetAdd'
import StreetEdit from './gf/area/StreetEdit'
import StudyTourFile from './ta/local/studyTour/file/StudyTourFile'
import StudyTourGroup from './ta/local/studyTour/group/StudyTourGroup'
import StudyTourIndex from './ta/local/studyTour/StudyTourIndex'
import StudyTourTemplateIndex from './ta/local/studyTour/temp/StudyTourTemplateIndex'
import StudyTourCourse from './ta/local/studyTour/temp/course/StudyTourCourse'
import Supplies from './keeper/asset/Supplies'

import TaCourse from './ta/web/TaCourse'
import TaFinanceAccount from './ta/finance/TaFinanceAccount'
import TaFinanceClient from './ta/finance/TaFinanceClient'
import TaFinanceClientType from './ta/finance/TaFinanceClientType'
import TaFinanceElseCostType from './ta/finance/TaFinanceElseCostType'
import TaFinanceElseIncomeType from './ta/finance/TaFinanceElseIncomeType'
import TaFinanceExpenseType from './ta/finance/TaFinanceExpenseType'
import TaFinanceGoods from './ta/finance/TaFinanceGoods'
import TaFinanceGoodsType from './ta/finance/TaFinanceGoodsType'
import TaFinanceIndex from './ta/finance/TaFinanceIndex'
import TaFinanceLedgerCostType from './ta/finance/TaFinanceLedgerCostType'
import TaFinanceLedgerDate from './ta/finance/TaFinanceLedgerDate'
import TaFinanceLedgerIncomeType from './ta/finance/TaFinanceLedgerIncomeType'
import TaFinanceSetIndex from './ta/finance/TaFinanceSetIndex'
import TaHome from './ta/web/TaHome'
import TaIndex from './ta/TaIndex'
import TaIndexPersonnel from './ta/TaIndexPersonnel'

import TaPrice from './ta/web/TaPrice'
import TaPriceIndex from './ta/web/TaPriceIndex'
import TaPriceFinance from './ta/web/TaPriceFinance'
import TaPriceCustom from './ta/web/TaPriceCustom'
import TaPriceUpdate from './ta/web/TaPriceUpdate'
import Task from './train/Task'
import TaskIndex from './task/TaskIndex'
import TaskReceive from './task/TaskReceive'
import TaskSend from './task/TaskSend'
import TaskSumReceive from './task/TaskSumReceive'
import TaskSumSend from './task/TaskSumSend'
import TrainCharge from './train/TrainCharge'
import TrainIndex from './train/TrainIndex'
import TrainJoin from './train/TrainJoin'
import TrainLimits from './train/TrainLimits'
import TrainPlan from './train/TrainPlan'
import TrainQuestion from './train/TrainQuestion'
import TrainTask from './train/TrainTask'
import Wage from './wage/Wage'
import WageBank from './wage/WageBank'
import WageLimits from './wage/WageLimits'
import WageDetail from './wage/WageDetail'
import Warehouse from './keeper/asset/Warehouse'
import WarehouseSupplies from './keeper/asset/WarehouseSupplies'
import WorkPlan from './performance/WorkPlan'
import WorkTask from './performance/WorkTask'
import WorkTaskCharge from './performance/WorkTaskCharge'
import WebIndex from './gf/web/WebIndex'
import WebApply from './gf/web/WebApply'
import WebNews from './gf/web/WebNews'
import WebNewsType from './gf/web/WebNewsType'
import WebGoods from './gf/web/WebGoods'
import WebGoodsType from './gf/web/WebGoodsType'
import WebGoodsTypeSon from './gf/web/WebGoodsTypeSon'
const RouteItem = [        
    {
        path:'/Client',
        element:<Client />,
        ess_menu_id:'49',
    },
    {
        path:'/GoodsList',
        element:<GoodsList />,
        ess_menu_id:'50',
    },
]
function App() {    
    return (    
        <Routes>        
            <Route path='/ArapStatementReceiveCover' element={<ArapStatementReceiveCover />}></Route>   
            <Route path='/Home' element={<Home />}></Route>   
            <Route path='/Login' element={<Login />}></Route> 
            <Route path='/Register' element={<Register />}></Route>
            <Route path='/TaCourse' element={<TaCourse />}></Route>
            <Route path='/TaHome' element={<TaHome />}></Route>
            <Route path='/' element={<TaHome />}></Route>
            <Route path='/TaPrice' element={<TaPrice />}>
                <Route path='/TaPrice/TaPriceIndex' element={<TaPriceIndex />}></Route>
                <Route path='/TaPrice/TaPriceCustom' element={<TaPriceCustom />}></Route>
                <Route path='/TaPrice/TaPriceFinance' element={<TaPriceFinance />}></Route>
                <Route path='/TaPrice/TaPriceUpdate' element={<TaPriceUpdate />}></Route>                  
            </Route>            
            <Route path='/' element={<LoginGate />}> 
                <Route path='/' element={localStorage.getItem('e_soft_name')==='金鱼'?<GfIndex/>:<TaIndex/>}></Route>
                    {RouteItem.map((item,index) =>{
                        if(localStorage.getItem('ess_menu_id')){
                            return <Route 
                            key={index}
                            path={item.path} 
                            element={(localStorage.getItem('eu_role')==='2' || localStorage.getItem('ess_menu_id').includes(item.ess_menu_id)) ? item.element :<NoPower/>}
                            ></Route>
                        }else{
                            return <></>
                        }
                    }                 
                )}
                <Route key='9999' path='/NoPower' element={<NoPower />}></Route>            
                <Route path='/AlbumGroup' element={<AlbumGroup />}></Route>
                <Route path='/AlbumIndex' element={<AlbumIndex />}></Route>        
                <Route path='/AlbumGroupDetail' element={<AlbumGroupDetail />}></Route>
                <Route path='/AlbumSection' element={<AlbumSection />}></Route>
                <Route path='/AlbumPic' element={<AlbumPic />}></Route>
                <Route path='/AlbumMan' element={<AlbumMan />}></Route>
                <Route path='/AppIndex' element={<AppIndex />}></Route>
                <Route path='/AppCourse' element={<AppCourse />}></Route>
                <Route path='/AppLimit' element={<AppLimit />}></Route>
                <Route path='/AppSpec' element={<AppSpec />}></Route>
                <Route path='/AppSet' element={<AppSet />}></Route>            
                <Route path='/ArapAdd' element={<ArapAdd />}></Route>
                <Route path='/ArapList' element={<ArapList />}></Route>
                <Route path='/ArapIndex' element={<ArapIndex />}></Route>            
                <Route path='/ArapStatementSend' element={<ArapStatementSend />}></Route>
                <Route path='/ArapStatementReceive' element={<ArapStatementReceive />}></Route>
                <Route path='/ArapTable' element={<ArapTable />}></Route>
                <Route path='/ArapSumClient' element={<ArapSumClient />}></Route> 
                <Route path='/ArapSumClientType' element={<ArapSumClientType />}></Route> 
                <Route path='/ArapSumDept' element={<ArapSumDept />}></Route>             
                <Route path='/ArapSumGoods' element={<ArapSumGoods />}></Route> 
                <Route path='/ArapSumGoodsType' element={<ArapSumGoodsType />}></Route> 
                <Route path='/ArapSumOperator' element={<ArapSumOperator />}></Route> 
                <Route path='/ArapSumSalesman' element={<ArapSumSalesman />}></Route> 
                <Route path='/AreaIndex' element={<AreaIndex />}></Route>
                <Route path='/AppPower' element={<AppPower />}></Route>
                <Route path='/BalanceTypeList' element={<BalanceTypeList />}></Route>
                <Route path='/BalanceIndex' element={<BalanceIndex />}></Route>
                <Route path='/BankAccount' element={<BankAccount />}></Route>
                <Route path='/BankAccountDetail' element={<BankAccountDetail />}></Route>
                <Route path='/Balance' element={<Balance />}></Route>         
                <Route path='/Business' element={<Business />}></Route> 
                <Route path='/City' element={<City />} />
                <Route path='/CityAdd' element={<CityAdd/>} />
                <Route path='/CityEdit/:id' element={<CityEdit />} />
                <Route path='/ClientIndex' element={<ClientIndex />}></Route>   
                <Route path='/CommodityType' element={<CommodityType />}></Route>
                <Route path='/Country' element={<Country />} />
                <Route path='/CountryAdd' element={<CountryAdd/>} />
                <Route path='/CountryEdit/:id' element={<CountryEdit />} />
                <Route path='/CustomFeedback' element={<CustomFeedback />}></Route>        
                <Route path='/CustomFile' element={<CustomFile />}></Route>        
                <Route path='/CustomFileIndex' element={<CustomFileIndex />}></Route>        
                <Route path='/CustomGoodsLocal' element={<CustomGoodsLocal />}></Route>    
                <Route path='/CustomGoodsOrgan' element={<CustomGoodsOrgan />}></Route>    
                <Route path='/CustomGoodsSelf' element={<CustomGoodsSelf />}></Route>    
                <Route path='/CustomGroup' element={<CustomGroup />}></Route>   
                <Route path='/CustomIndex' element={<CustomIndex />}></Route>        
                <Route path='/CustomClient' element={<CustomClient />}></Route>      
                <Route path='/CustomCrowd' element={<CustomCrowd />}></Route>       
                <Route path='/CustomIndex' element={<CustomIndex />}></Route>      
                <Route path='/CustomLineName' element={<CustomLineName />}></Route> 
                <Route path='/CustomNotes' element={<CustomNotes />}></Route> 
                <Route path='/CustomPage' element={<CustomPage />}></Route>       
                <Route path='/CustomPrice' element={<CustomPrice />}></Route>       
                <Route path='/CustomRoute' element={<CustomRoute />}></Route>       
                <Route path='/CustomSelf' element={<CustomSelf />}></Route>       
                <Route path='/CustomStandard' element={<CustomStandard />}></Route>       
                <Route path='/CustomTempIndex' element={<CustomTempIndex />}></Route>       
                <Route path='/Destination' element={<Destination />} /> 
                <Route path='/DestinationAdd' element={<DestinationAdd />} /> 
                <Route path='/DestinationEdit' element={<DestinationEdit />} />    
                <Route path='/District' element={<District />} />
                <Route path='/DistrictAdd' element={<DistrictAdd/>} />
                <Route path='/DistrictEdit/:id' element={<DistrictEdit />} />    
                <Route path='/ElseIncome' element={<ElseIncome />}></Route>
                <Route path='/ElseIncomeSumType' element={<ElseIncomeSumType />}></Route>
                <Route path='/ElseIndex' element={<ElseIndex />}></Route>
                <Route path='/ElsePaid' element={<ElsePaid />}></Route>    
                <Route path='/ElsePaidSumType' element={<ElsePaidSumType />}></Route>    
                <Route path='/ElseSumClient' element={<ElseSumClient />}></Route>    
                <Route path='/ElseSumDept' element={<ElseSumDept />}></Route>    
                <Route path='/ElseTable' element={<ElseTable />}></Route>
                <Route path='/EvaluateIndex' element={<EvaluateIndex />}></Route>
                <Route path='/EvaluateGroup' element={<EvaluateGroup />}></Route>
                <Route path='/EvaluateGroupDetail' element={<EvaluateGroupDetail />}></Route>
                <Route path='/EvaluateGroupPoint' element={<EvaluateGroupPoint />}></Route>
                <Route path='/Expense' element={<Expense />}></Route>
                <Route path='/ExpenseIndex' element={<ExpenseIndex />}></Route>
                <Route path='/ExpenseProfitDept' element={<ExpenseProfitDept />}></Route>
                <Route path='/ExpenseSumClient' element={<ExpenseSumClient />}></Route>
                <Route path='/ExpenseSumDept' element={<ExpenseSumDept />}></Route>
                <Route path='/ExpenseSumType' element={<ExpenseSumType />}></Route>
                <Route path='/ExpenseTable' element={<ExpenseTable />}></Route>   
                <Route path='/FirmAccount' element={<FirmAccount />}></Route>    
                <Route path='/FirmApp' element={<FirmApp />}></Route>    
                <Route path='/FirmIndex' element={<FirmIndex />}></Route>
                <Route path='/FirmList' element={<FirmList />}></Route>
                <Route path='/FirmOrder' element={<FirmOrder />}></Route>        
                <Route path='/FirmRecharge' element={<FirmRecharge />}></Route>
                <Route path='/FirmSumArap' element={<FirmSumArap />}></Route>
                <Route path='/FirmSumBalance' element={<FirmSumBalance />}></Route>
                <Route path='/FirmSumLedger' element={<FirmSumLedger />}></Route>
                <Route path='/FirmSumTask' element={<FirmSumTask />}></Route>
                <Route path='/FirmTypeBalance' element={<FirmTypeBalance />}></Route>
                <Route path='/FirmTypeGoods' element={<FirmTypeGoods />}></Route> 
                <Route path='/FirmUser' element={<FirmUser />}></Route>        
                <Route path='/FundAccount' element={<FundAccount />}></Route>
                <Route path='/FundAdd' element={<FundAdd />}></Route>        
                <Route path='/FundList' element={<FundList />}></Route>        
                <Route path='/FundIndex' element={<FundIndex />}></Route> 
                <Route path='/FundTable' element={<FundTable />}></Route>        
                <Route path='/FundSumClient' element={<FundSumClient />}></Route>        
                <Route path='/FundSumClientType' element={<FundSumClientType />}></Route>        
                <Route path='/FundSumDept' element={<FundSumDept />}></Route>        
                <Route path='/FundSumGoods' element={<FundSumGoods />}></Route>        
                <Route path='/FundSumGoodsType' element={<FundSumGoodsType />}></Route>        
                <Route path='/FundSumOperator' element={<FundSumOperator />}></Route>        
                <Route path='/FundSumSalesman' element={<FundSumSalesman />}></Route>  
                <Route path='/GfIndex' element={<GfIndex />}></Route>
                <Route path='/GfIndexFinance' element={<GfIndexFinance />}></Route>
                <Route path='/GfIndexMall' element={<GfIndexMall />}></Route>
                <Route path='/GfIndexPersonnel' element={<GfIndexPersonnel />}></Route> 
                <Route path='/GoodsIndex' element={<GoodsIndex />}></Route>         
                <Route path='/GoodsTypeList' element={<GoodsTypeList />}></Route>         
                <Route path='/GuideIndex' element={<GuideIndex />}></Route>
                <Route path='/GuideList' element={<GuideList />}></Route>
                <Route path='/InAndOut' element={<InAndOut />}></Route>
                <Route path='/IndexSecond' element={<IndexSecond />}></Route> 
                <Route path='/Inventory' element={<Inventory />}></Route>               
                <Route path='/Keeper' element={<Keeper />}></Route>
                <Route path='/Landmark' element={<Landmark />} /> 
                <Route path='/LandmarkAdd' element={<LandmarkAdd />} /> 
                <Route path='/LandmarkEdit' element={<LandmarkEdit />} />          
                <Route path='/LedgerArapClient' element={<LedgerArapClient />}></Route>
                <Route path='/LedgerArapDept' element={<LedgerArapDept />}></Route>
                <Route path='/LedgerArapStatement' element={<LedgerArapStatement />}></Route>
                <Route path='/LedgerBalance' element={<LedgerBalance />}></Route>                
                <Route path='/LedgerGroupCheck' element={<LedgerGroupCheck />}></Route>
                <Route path='/LedgerIndex' element={<LedgerIndex />}></Route>                
                <Route path='/LedgerGroup' element={<LedgerGroup />}></Route>
                <Route path='/LedgerStartdate' element={<LedgerStartdate />}></Route>
                <Route path='/LedgerSumClient' element={<LedgerSumClient />}></Route>
                <Route path='/LedgerSumClientType' element={<LedgerSumClientType />}></Route>
                <Route path='/LedgerSumDept' element={<LedgerSumDept />}></Route>
                <Route path='/LedgerSumGoods' element={<LedgerSumGoods />}></Route>
                <Route path='/LedgerSumGoodsType' element={<LedgerSumGoodsType />}></Route>
                <Route path='/LedgerSumOperator' element={<LedgerSumOperator />}></Route>
                <Route path='/LedgerSumSalesman' element={<LedgerSumSalesman />}></Route>       
                <Route path='/LedgerTable' element={<LedgerTable />}></Route>       
                <Route path='/LocalIndex' element={<LocalIndex />}></Route>       
                <Route path='/LocalShop' element={<LocalShop />}></Route>       
                {/* <Route path='/MineIndex' element={<MineIndex />}></Route> */}
                <Route path='/MineIndex' element={<MineIndex />}></Route>
                <Route path='/MineInfo' element={<MineInfo />}></Route>
                <Route path='/MinePassword' element={<MinePassword />}></Route>
                <Route path='/NetTechContract' element={<NetTechContract />}></Route>    
                <Route path='/NetTechContractSerial' element={<NetTechContractSerial />}></Route>    
                <Route path='/NetTechContractSerialIncome' element={<NetTechContractSerialIncome />}></Route>    
                <Route path='/NetTechContractSerialCost' element={<NetTechContractSerialCost />}></Route>    
                <Route path='/NetTechGoods' element={<NetTechGoods />}></Route>    
                <Route path='/NetTechSerial' element={<NetTechSerial />}></Route>    
                <Route path='/NetTechIncome' element={<NetTechIncome />}></Route>    
                <Route path='/NetTechCost' element={<NetTechCost />}></Route>  
                <Route path='/OrganIndex' element={<OrganIndex />}></Route>
                <Route path='/OrganCustomIndex' element={<OrganCustomIndex />}></Route>
                <Route path='/PettyCash' element={<PettyCash />}></Route>
                <Route path='/PhoneShop' element={<PhoneShop />}></Route>
                <Route path='/PhoneShopGoods' element={<PhoneShopGoods />}></Route>
                <Route path='/PhoneShopIndex' element={<PhoneShopIndex />}></Route>
                <Route path='/Profit' element={<Profit />}></Route>
                <Route path='/Province' element={<Province />} />
                <Route path='/ProvinceAdd' element={<ProvinceAdd/>} />
                <Route path='/ProvinceEdit/:id' element={<ProvinceEdit />} />
                <Route path='/Personal' element={<Personal />}></Route>
                <Route path='/SellIncome' element={<SellIncome />}></Route>   
                <Route path='/SetAppNotPay' element={<SetAppNotPay />}></Route>
                <Route path='/SetAppPayed' element={<SetAppPayed />}></Route>        
                <Route path='/SetAppLimits' element={<SetAppLimits />}></Route>         
                <Route path='/SetCourse' element={<SetCourse />}></Route>
                <Route path='/SetDept' element={<SetDept />}></Route>           
                <Route path='/SetIndex' element={<SetIndex />}></Route> 
                <Route path='/SetPost' element={<SetPost />}></Route>
                <Route path='/SetRole' element={<SetRole />}></Route>
                <Route path='/SetSubscription' element={<SetSubscription />}></Route>
                <Route path='/Street' element={<Street />} />
                <Route path='/StreetAdd' element={<StreetAdd/>} />
                <Route path='/StreetEdit/:id' element={<StreetEdit />} /> 
                <Route path='/StudyTourIndex' element={<StudyTourIndex />}></Route>
                <Route path='/StudyTourFile' element={<StudyTourFile />}></Route>        
                <Route path='/StudyTourGroup' element={<StudyTourGroup />}></Route>        
                <Route path='/StudyTourTemplateIndex' element={<StudyTourTemplateIndex />}></Route>
                <Route path='/StudyTourCourse' element={<StudyTourCourse />}></Route>
                <Route path='/Supplies' element={<Supplies />}></Route>       
                <Route path='/TaFinanceAccount' element={<TaFinanceAccount />}></Route>
                <Route path='/TaFinanceClient' element={<TaFinanceClient />}></Route>
                <Route path='/TaFinanceClientType' element={<TaFinanceClientType />}></Route>
                <Route path='/TaFinanceElseCostType' element={<TaFinanceElseCostType />}></Route>
                <Route path='/TaFinanceElseIncomeType' element={<TaFinanceElseIncomeType />}></Route>
                <Route path='/TaFinanceExpenseType' element={<TaFinanceExpenseType />}></Route>
                <Route path='/TaFinanceGoods' element={<TaFinanceGoods />}></Route>
                <Route path='/TaFinanceGoodsType' element={<TaFinanceGoodsType />}></Route>
                <Route path='/TaFinanceIndex' element={<TaFinanceIndex />}></Route>  
                <Route path='/TaFinanceLedgerCostType' element={<TaFinanceLedgerCostType />}></Route>
                <Route path='/TaFinanceLedgerDate' element={<TaFinanceLedgerDate />}></Route>
                <Route path='/TaFinanceLedgerIncomeType' element={<TaFinanceLedgerIncomeType />}></Route>
                <Route path='/TaFinanceSetIndex' element={<TaFinanceSetIndex />}></Route>
                <Route path='/TaIndex' element={<TaIndex />}></Route>                    
                <Route path='/TaIndexPersonnel' element={<TaIndexPersonnel />}></Route>
                
                <Route path='/Task' element={<Task />}></Route>
                <Route path='/TaskIndex' element={<TaskIndex />}></Route>
                <Route path='/TaskReceive' element={<TaskReceive />}></Route>
                <Route path='/TaskSend' element={<TaskSend />}></Route>
                <Route path='/TaskSumReceive' element={<TaskSumReceive />}></Route>
                <Route path='/TaskSumSend' element={<TaskSumSend />}></Route>
                <Route path='/TrainCharge' element={<TrainCharge />}></Route>
                <Route path='/TrainIndex' element={<TrainIndex />}></Route>
                <Route path='/TrainJoin' element={<TrainJoin />}></Route>
                <Route path='/TrainLimits' element={<TrainLimits />}></Route>            
                <Route path='/TrainPlan' element={<TrainPlan />}></Route>
                <Route path='/TrainQuestion' element={<TrainQuestion />}></Route>                
                <Route path='/TrainTask' element={<TrainTask />}></Route>
                <Route path='/Wage' element={<Wage />}></Route>
                <Route path='/Warehouse' element={<Warehouse />}></Route>
                <Route path='/WarehouseSupplies' element={<WarehouseSupplies />}></Route>
                <Route path='/WageBank' element={<WageBank />}></Route>
                <Route path='/WageLimits' element={<WageLimits />}></Route>
                <Route path='/WageDetail' element={<WageDetail />}></Route>
                <Route path='/Clocking' element={<Clocking />}></Route>         
                <Route path='/WorkPlan' element={<WorkPlan />}></Route>
                <Route path='/WorkTask' element={<WorkTask />}></Route>
                <Route path='/WorkTaskCharge' element={<WorkTaskCharge />}></Route>        
                <Route path='/WebIndex' element={<WebIndex />}></Route>
                <Route path='/WebApply' element={<WebApply />}></Route>
                <Route path='/WebNews' element={<WebNews />}></Route>
                <Route path='/WebNewsType' element={<WebNewsType />}></Route>
                <Route path='/WebGoods' element={<WebGoods />}></Route>
                <Route path='/WebGoodsType' element={<WebGoodsType />}></Route>
                <Route path='/WebGoodsTypeSon' element={<WebGoodsTypeSon />}></Route>            
            </Route>   
        </Routes>   
    )
}

export default App