import React,{useState,}from 'react'
import { Modal,message,Form,Input, Button, InputNumber, DatePicker,} from 'antd';
import { useEntPayGoodsAddMutation, } from '../../store/ReactWebSiteApi';
import GetGoods from '../../finance/goods/GetGoods';
import dayjs from 'dayjs';
import GetFirm from './GetFirm';
const {TextArea} = Input
function FirmOrderAdd() {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreate = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({       
        epg_uid:localStorage.getItem('eu_id'),
        epg_uname:localStorage.getItem('eu_name'),
    });
    //客户类型
    const [submitAdd] = useEntPayGoodsAddMutation()
    return (
        <>
            <Button type='primary' onClick={() => {setOpen(true)}}>新增</Button>
            <Modal
                open={open}
                title='新增订单'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd(info).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
                width={800}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                >                            
                    <GetFirm 
                        label='企业'
                        wrapperCol={{span:15}}
                        required
                        getFirm={(e) => {
                            setInfo(prevState=>({...prevState,epg_e_id:e}))
                        }}
                    />
                    <GetGoods
                        name='type'
                        label='产品'
                        getGoods={(e) => {setInfo(prevState=>({...prevState,epg_eg_id:e}))}}
                        required
                    />
                    <Form.Item name="name" label='金额' wrapperCol={{span:15}} required>
                        <InputNumber  onChange={(e) => {setInfo(prevState=>({...prevState,epg_amount:e}))}} />
                    </Form.Item>
                    <Form.Item name="date" label='截止日期' wrapperCol={{span:15}} required>
                        <DatePicker  onChange={(e) => {setInfo(prevState=>({...prevState,epg_date:dayjs(e).format('YYYY-MM-DD')}))}} />
                    </Form.Item>
                    <Form.Item name="content" label='备注' wrapperCol={{span:15}}>
                        <TextArea  onChange={(e) => {setInfo(prevState=>({...prevState,epg_content:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default FirmOrderAdd