import { Button, Form, Input, Modal, Radio } from 'antd'
import React, { useState } from 'react'
import { localCostType } from '../../customType';
import GetUserDept from '../../../../../cpn/GetUserDept';

function RouteQuery(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const [info,setInfo] = useState({ltxc_content: '',ltxc_type_id:'',ltxc_name:'',ltxc_dept_id:''})
    return (
        <>
            <Button onClick={() => { setOpen(true)}}  >查询</Button>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            props.getQuery(values)
                            setOpen(false)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });                    
                }}
                title='查询行程安排'
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        <Button onClick={() => {                                        
                            form.resetFields();  
                            }}>重置</Button>
                        <CancelBtn />
                        <OkBtn />
                    </>
                )}
                width={800}                
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    labelCol={{ span: 4 }}
                    initialValues={{ltxc_content: '',ltxc_type_id:'',ltxc_name:'',ltxc_dept_id:''}}
                >                                           
                    <GetUserDept
                        label='部门' 
                        name='ltxc_dept_id'
                        wrapperCol={{span:10}}
                        getUserDept={(e) => {setInfo(prevState=>({...prevState,ltxc_dept_id:e}))}}
                        value={info.ltxc_dept_id}
                    />
                    <Form.Item 
                        name="ltxc_type_id" 
                        label='类型' 
                        wrapperCol={{span:20}}               
                    >
                        <Radio.Group 
                            optionType="button"                             
                            options={localCostType}
                            value={info.ltxc_type_id}
                            onChange={(e) => {setInfo(prevState=>({...prevState,ltxc_type_id:e.target.value}))}}
                        ></Radio.Group>
                    </Form.Item>
                    <Form.Item name="ltxc_name" label='名称' wrapperCol={{span:10}}>
                        <Input 
                            value={info.ltxc_name}
                            onChange={(e) => {setInfo(prevState=>({...prevState,ltxc_name:e.target.value}))}}
                        />
                    </Form.Item>
                    <Form.Item name="ltxc_content" label='描述' wrapperCol={{span:10}}>
                        <Input 
                            value={info.ltxc_content}
                            onChange={(e) => {setInfo(prevState=>({...prevState,ltxc_content:e.target.value}))}}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default RouteQuery