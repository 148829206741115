import { Select,Form, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGoodsListQuery,useGoodsTypeListQuery } from '../../store/ReactWebSiteApi';
import SetGoods from './SetGoods';

function GetGoods(props) {
    
    const [type,setType] = useState();
    const {data:dataType, isSuccess:isSuccessType} = useGoodsTypeListQuery({egt_e_id:localStorage.getItem('e_id'),egt_esv_id:props.eg_esv_id});
    useEffect(() => {
        if(isSuccessType){
            if(dataType.Status==='success'){
                console.log(dataType);
                const newArr = dataType.Result.map((item) => {
                    const {egt_id,egt_name,}=item;
                    return item = {value:egt_id,label:egt_name}
                })
                setType(newArr)
            }
        }
    },[dataType,isSuccessType])
    const [typeId,setTypeId] = useState(props.typeName)
    const [list,setList] = useState();
    const {data, isSuccess} = useGoodsListQuery({eg_e_id:localStorage.getItem('e_id'),eg_esv_id:props.eg_esv_id,eg_egt_id:typeId},{refetchOnMountOrArgChange:1});
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                const newArr = data.Result.map((item) => {
                    const {eg_id,eg_name,}=item;
                    return item = {value:eg_id,label:eg_name}
                })
                setList(newArr)
            }
        }
    },[data,isSuccess])
    return (
        <>
            <Form.Item 
                label={props.label} 
                required={props.required}       
            >
                <Space>
                    <Form.Item noStyle name={props.typeName}>
                        <Select 
                            options={type} 
                            onChange={(e,f) => {
                                setTypeId(e)}
                            }
                            style={{width:"200px"}}
                        />
                    </Form.Item>
                    <Form.Item noStyle name={props.name}>
                        <Select 
                            options={list} 
                            onChange={(_,f) => {
                                // console.log(f);
                                props.getGoods(f.value,f.label)
                            }}
                            defaultValue={props.defaultId}
                            placeholder={props.defaultId}
                            style={{width:"300px"}}
                        />
                    </Form.Item>
                    <SetGoods />
                </Space>
            </Form.Item>
        </>
    )
}

export default GetGoods