import React, { useEffect, useState } from 'react'
import { message, Modal,  } from 'antd'
import { useDijieBaoJiaDanUpdateQuery, useDijieBaoJiaDanUpdateSaveMutation, } from '../../../../store/ReactWebSiteApi';
function GroupFileDel(props) {
    const [info,setInfo] = useState({
        ltbjd_e_id:localStorage.getItem('e_id'),
        ltbjd_update_uid:localStorage.getItem('eu_id'),
        ltbjd_update_uname:localStorage.getItem('eu_name'),
        ltbjd_id:props.ltbjd_id,
        ltbjd_sp_name:'',
        ltbjd_date:'',
        ltbjd_sp_id:'',
        ltbjd_daoyou:'',
        ltbjd_jietuan_content:'',
        ltbjd_client_name:'',
    });
    //获取
    const {data,isSuccess} = useDijieBaoJiaDanUpdateQuery({ltbjd_id:props.ltbjd_id})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){         
                setInfo(prevsState=>({...prevsState,
                    ltbjd_dept_id:data.Result.ltbjd_dept_id,                  
                    ltbjd_type_id:data.Result.ltbjd_type_id,                  
                    ltbjd_ltxl_name:data.Result.ltbjd_ltxl_name,
                    ltbjd_date:data.Result.ltbjd_date,
                    ltbjd_client_name:data.Result.ltbjd_client_name,
                    ltbjd_content:data.Result.ltbjd_content,
                    ltbjd_yemei:data.Result.ltbjd_yemei,
                    ltbjd_yejiao:data.Result.ltbjd_yejiao,
                    ltbjd_daoyou:data.Result.ltbjd_daoyou,                   
                    ltbjd_jietuan_content:data.Result.ltbjd_jietuan_content,          
                    ed_name:data.Result.ed_name,          
                }))
            }
        }
    },[data,isSuccess,])
    const [updateSave] = useDijieBaoJiaDanUpdateSaveMutation()    
    const {confirm} = Modal; 
    return (
        <a onClick={() => {
            confirm({
                title: '取消导入吗?',
                content: '取消后可以重新导入',
                okText: '确认',
                okType: 'danger',
                cancelText: '取消',
                onOk:() => {
                    updateSave({
                        ltbjd_id:props.ltbjd_id,
                        ltbjd_e_id:localStorage.getItem('e_id'),                            
                        ltbjd_e_name:localStorage.getItem('e_name'),
                        ltbjd_client_name:info.ltbjd_client_name,
                        ltbjd_update_uid:localStorage.getItem('eu_id'),
                        ltbjd_update_uname:localStorage.getItem('eu_name'),
                        ltbjd_sp_id:info.ltbjd_sp_id,
                        ltbjd_sp_name:info.ltbjd_sp_name,
                        ltbjd_dept_id:localStorage.getItem('ed_id'),
                        ltbjd_dept_name:localStorage.getItem('ed_name'),
                        ltbjd_ltxl_name:info.ltbjd_ltxl_name,
                        ltbjd_date:info.ltbjd_date,
                        ltbjd_type_id:info.ltbjd_type_id,
                        ltbjd_content:info.ltbjd_content, 
                        ltbjd_yemei:info.ltbjd_yemei,
                        ltbjd_yejiao:info.ltbjd_yejiao,
                        ltbjd_daoyou:info.ltbjd_daoyou,
                        ltbjd_jietuan_content:info.ltbjd_jietuan_content,
                        ltbjd_kehu_type_id:info.ltbjd_kehu_type_id,
                        ltbjd_tuanhao:'',
                        ltbjd_lth_id:'',
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('取消成功！')                                                                              
                        }
                        if (res.data.Status === 'fail') {
                            message.error('取消失败！'+res.data.Message)
                        }
                    })
                },
                onCancel() {
                    // console.log('Cancel');
                },
            }); 
        }}>取消导入</a>

    )
}

export default GroupFileDel