import { Form, Modal, Space } from 'antd';
import React, { useState } from 'react'
import Feedback from './Feedback';

function FeedbackForm(props) {
    const [open,setOpen] = useState(false)    
    return (
        <Form.Item
            label='意见单模板'                    
            required={props.required}                   
        >
            <Space align='center'>
                <div>{props.ltbjd_daoyou + ' - ' + props.ltbjd_daoyou_phone}</div>                     
                <a onClick={() => {setOpen(true)}}>请选择</a>
                <Modal
                    open={open}
                    okText="提交"
                    cancelText="取消"
                    onCancel={() => {setOpen(false);}}
                    onOk={() => {}}
                    footer={null}
                    width={800}
                    title='意见单模板'
                >
                    <Feedback 
                        type='select'
                        getGuide={(e) => {
                            props.getFeedback({
                                ltbjd_daoyou:e.ltdy_name,
                                ltbjd_daoyou_phone:e.ltdy_phone,
                            })                            
                        }}
                        getOpen={(e) => {setOpen(false)}}
                    />
                </Modal> 
            </Space>
        </Form.Item>   
    )
}

export default FeedbackForm