import React, { useState,useEffect, } from 'react'
import Main from '../cpn/Main'
import { useSearchParams, } from 'react-router-dom'
import {Space,Table, } from 'antd'
import {useUserEbnEvaluateListQuery, } from '../store/ReactWebSiteApi'

function EvaluateGroupDetail() {
    const [param] = useSearchParams()   



    //已评价===========================================================================================================================================已评价//
    const {data:dataEvaluated,isSuccess:isSuccessEvaluated} = useUserEbnEvaluateListQuery({ebn_id:param.get('id')});
    const [list2,setList2] = useState()
    useEffect(() => {
        if(isSuccessEvaluated){
            if(dataEvaluated.Status==='success'){
                console.log(dataEvaluated,'11');
                const newArr = dataEvaluated.Result.map((item) => {
                    const {ed_id:key,tps_phone,ed_et_name,ed_ep_name,ed_score,ed_content,ed_image,ed_ctime,ed_cname,tps_name,tps_type} = item
                    let type = ''
                    switch (tps_type) {
                        case '1':
                            type= '学生'
                            break;
                        case '2':
                            type= '家长'
                            break;
                        case '3':
                            type= '老师'
                            break;
                    
                        
                        default:
                            break;
                    }
                    return item = {ed_id:key,tps_phone,ed_et_name,ed_ep_name,ed_score,ed_content,ed_image,ed_ctime,ed_cname,tps_name,type}
                })
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ed_ctime;
                    let bTimeString = b.ed_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList2(sortArr)
            }

        }

    },[dataEvaluated,isSuccessEvaluated])
    const columns2 = [                                                 
            
        {
            key: 'name',
            title: '姓名',
            dataIndex: 'tps_name',
            render:(_,record) => {
                return<>[{record.type}]{record.tps_name}-{record.tps_phone}</>
            }
        },                         
        {
            key: 'ed_ep_name',
            title: '评价项目',
            dataIndex:'ed_ep_name',
            render:(_,record) => {
                return<>{record.ed_et_name}-{record.ed_ep_name}</>
            }
        },                                   
        {
            key: 'ed_score',
            title: '评分',
            dataIndex:'ed_score',
        },                                   
        {
            key: 'ed_content',
            title: '文字评价',
            dataIndex:'ed_content',
        },                                   
        {
            key: 'ed_image',
            title: '图片评价',
            dataIndex:'ed_image',
            render:(text,record) => {
                if(text){
                    return <><img alt='' src={`/api/assets/images/application/${text[0]}`} width='100px'/></>
                }
            }
        },                                   
        {
            key: 'ed_ctime',
            title: '评价时间',
            dataIndex:'ed_ctime',
            render:(_,record) => {
                return<>{record.ed_ctime}</>
            }
        },                                 
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <Space >                        
                        <a onClick={() => {
                          
                        }}>预览</a>
                                        
                    </Space>
            }
        },
    ]

    return (
        <Main t1='团队列表' n1='/EvaluateGroup?esv_id=25' t2={`团号${param.get('ebn_team_num')}`} t3='已评价'>            
            <Table columns={columns2}  style={{marginTop:'20px'}} dataSource={list2} />
        </Main>
    )
}

export default EvaluateGroupDetail