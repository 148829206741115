import React,{useState,useEffect}from 'react'
import Main from '../cpn/Main'
import { Table,Button,Space,Modal,message,Form,Upload} from 'antd';
import { usePhotoAlbumGroupUploadAddMutation, usePhotoAlbumGroupUploadDelMutation, usePhotoAlbumGroupUploadListQuery,} from '../store/ReactWebSiteApi';
import { useSearchParams } from 'react-router-dom';
import { UploadOutlined} from '@ant-design/icons';
function AlbumCalendar() {
    const [param] = useSearchParams()
    //新增图片-----------------------------------------------------------------------------------------------------------------------------新增图片
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const handleChangeAdd = (e) => {
        console.log(e);
        if (e.file.status === 'done'||e.file.status==='removed') {
            const fileArr = e.fileList.map((item) => {
                const { response } = item
                return response.Result
            })
            console.log(e);
            let fileStr = fileArr + "";  
            if(fileStr === null){
                fileStr = ''
            }
            const nameArr = e.fileList.map((item) => {
                const { name} = item
                return name
            })
            let nameStr = nameArr + ""
            if(nameStr === null){
                nameStr = ''
            }
            setInfoAdd(prevState=>({...prevState,epagi_image_name:nameStr,epagi_image_file:fileStr}))
        }
    }
    const [infoAdd,setInfoAdd] = useState({
        epagi_content:'',
    });
    const [submitAdd] = usePhotoAlbumGroupUploadAddMutation()
    const modalAdd= <Modal
                        open={openAdd}
                        title='新增图片'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClient(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitAdd({
                                epagi_epag_id:param.get('gid'),
                                epagi_image_name:infoAdd.epagi_image_name,
                                epagi_image_file:infoAdd.epagi_image_file,
                                epagi_uid:localStorage.getItem('eu_id'),
                                epagi_uname:localStorage.getItem('eu_name'),
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpenAdd(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 7 }}
                        >                            
                            <Form.Item label="图片"                             
                                valuePropName='fileList'
                                getValueFromEvent={e => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e && e.fileList;
                                }}
                                >
                                <Upload
                                    name="image_file"
                                    action="/api/ReactApi/zd_single_upload_image"
                                    listType="picture"
                                    className="upload-list-inline"
                                    onChange={handleChangeAdd}
                                    >
                                    <Button icon={<UploadOutlined />}>点击上传</Button>
                                </Upload>
                            </Form.Item>
                        </Form>
                    </Modal> 
    
    //删除图片-----------------------------------------------------------------------------------------------------------------------------删除图片
    const {confirm} = Modal; 
    const [submitDel] = usePhotoAlbumGroupUploadDelMutation()
    //图片列表-----------------------------------------------------------------------------------------------------------------------------图片列表
    
    const {data,isSuccess} = usePhotoAlbumGroupUploadListQuery({epagis_epag_id:param.get('gid')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                // //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {epagis_id:key,epagi_name,epagis_image_file,epagi_uname,epagis_ctime,epagi_update_ctime,epagi_update_uname,goods_num} = item;
                    return item = {key,epagi_name,epagis_image_file,epagi_uname,epagis_ctime,epagi_update_ctime,epagi_update_uname,goods_num}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.epagis_ctime;
                    let bTimeString = b.epagis_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])  
    const columns = [
        // {
        //     key: 'id',
        //     title: '编号',
        //     dataIndex: 'key'
        // },
       
        {
            key: 'type',
            title: '图片名称',
            dataIndex: 'epagis_image_file',
            render:(text) => {
                return <img alt='' src={`/api/assets/images/application/${text}`} width='100px'/>
            }
        },                                          
        // {
        //     key: 'time',
        //     title: '操作记录',
        //     render: (_,record) => {
        //         return <>
        //             <div>新建时间：{record.epagi_ctime + record.epagi_uname}</div>
        //             <div>修改时间：{record.epagi_update_ctime + record.epagi_update_uname}</div>
        //         </>
        //     }
        // },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space>
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({epagis_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main t1={param.get('aname')} n1={`/AlbumGroup?esv_id=7&id=${param.get('aid')}&name=${param.get('aname')}`} t2={param.get('gname')} t3='照片'>
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增照片</Button>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalAdd}
        </Main>
    )
}

export default AlbumCalendar