import React,{useState,useEffect,useRef}from 'react'
import Main from '../../cpn/Main'
import { Table,Button,Space,Modal,message,Form,Input, InputNumber,} from 'antd';
import {  useNavigate, } from 'react-router-dom';
import { useGwGoodsTypeAddMutation, useGwGoodsTypeDelMutation, useGwGoodsTypeListQuery, useGwGoodsTypeUpdateQuery, useGwGoodsTypeUpdateSaveMutation,} from '../../store/ReactWebSiteApi';

function WebGoodsType() {
    //新增产品类型-----------------------------------------------------------------------------------------------------------------------------新增产品类型
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [infoAdd,setInfoAdd] = useState({
        eowgt_content:'',
    });
    const [submitAdd] = useGwGoodsTypeAddMutation()
    const modalAdd= <Modal
                        open={openAdd}
                        title='新增产品类型'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClient(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitAdd({
                                eowgt_name:infoAdd.eowgt_name,
                                eowgt_e_id:localStorage.getItem('e_id'),
                                eowgt_uid:localStorage.getItem('eu_id'),
                                eowgt_uname:localStorage.getItem('eu_name'),
                                eowgt_serial_number:infoAdd.eowgt_serial_number,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpenAdd(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 7 }}
                        >   
                            <Form.Item name="name" label='产品类型名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eowgt_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="serial" label='序号' wrapperCol={{span:10}} required>
                                <InputNumber  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eowgt_serial_number:e}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal> 
    //修改产品类型-----------------------------------------------------------------------------------------------------------------------------修改产品类型
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    const [infoEdit,setInfoEdit] = useState();
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useGwGoodsTypeUpdateQuery({eowgt_id:editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    eowgt_name:dataEdit.Result.eowgt_name,
                    eowgt_content:dataEdit.Result.eowgt_content,
                    eowgt_serial_number:dataEdit.Result.eowgt_serial_number,
                })
                setInfoEdit(prevState=>({...prevState,
                    eowgt_name:dataEdit.Result.eowgt_name,
                    eowgt_content:dataEdit.Result.eowgt_content,              
                    eowgt_serial_number:dataEdit.Result.eowgt_serial_number,              
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useGwGoodsTypeUpdateSaveMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改产品类型'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitUpdateSave({
                                eowgt_name:infoEdit.eowgt_name,
                                eowgt_serial_number:infoEdit.eowgt_serial_number,
                                eowgt_e_id:localStorage.getItem('e_id'),
                                eowgt_update_uid:localStorage.getItem('eu_id'),
                                eowgt_update_uname:localStorage.getItem('eu_name'),
                                eowgt_id:editId,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 7 }}
                            ref={editForm}
                        >
                            <Form.Item name="eowgt_name" label='产品类型名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eowgt_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="eowgt_serial_number" label='序号' wrapperCol={{span:10}} required>
                                <InputNumber  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eowgt_serial_number:e}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal>
    //删除产品类型-----------------------------------------------------------------------------------------------------------------------------删除产品类型
    const {confirm} = Modal; 
    const [submitDel] = useGwGoodsTypeDelMutation()
    //产品类型列表-----------------------------------------------------------------------------------------------------------------------------产品类型列表
    
    const {data,isSuccess} = useGwGoodsTypeListQuery({eowgt_e_id:localStorage.getItem('e_id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {eowgt_id:key,eowgt_name,eowgt_content,eowgt_uname,eowgt_ctime,eowgt_update_ctime,eowgt_update_uname,sub_num,eowgt_serial_number} = item;
                    return item = {key,eowgt_name,eowgt_content,eowgt_uname,eowgt_ctime,eowgt_update_ctime,eowgt_update_uname,sub_num,eowgt_serial_number}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eowgt_serial_number;
                    let bTimeString = b.eowgt_serial_number;
                    return aTimeString - bTimeString;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    const navi = useNavigate();   
    const columns = [
        {
            key: 'eowgt_serial_number',
            title: '序号',
            dataIndex: 'eowgt_serial_number'
        },
        {
            key: 'tag',
            title: '产品类型名称',
            dataIndex: 'eowgt_name',
        },                 
        {
            key: 'sub_num',
            title: '产品子类型数量',
            dataIndex: 'sub_num',
        }, 
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },       
              
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.eowgt_ctime + record.eowgt_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>                        
                        <a onClick={() => {
                            setEditId(record.key);
                            setOpenEdit(true)
                        }}>修改</a>
                        {record.sub_num==='0' && <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({eowgt_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>}
                        <a onClick={() => {navi(`/WebGoodsTypeSon?esv_id=21&id=${record.key}&name=${record.eowgt_name}`)}}>子类型</a>
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main  t1='商城' n1='/GfIndexMall' t2='官网' n2='/WebIndex' t3='教程分类' >
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增</Button>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalAdd}
            {modalEdit}            
        </Main>
    )
}

export default WebGoodsType