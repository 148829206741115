import React from 'react'
import Main from '../../../../../cpn/Main'
import Client from './Client'
function CustomClient() {
    return (
        <Main   t1='地接业务' n1='/LocalIndex' t2='定制游' n2='/CustomIndex' t3='模板' n3='/CustomTempIndex' t4='组团社'>
            <Client />
        </Main>
    )
}

export default CustomClient