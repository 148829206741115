import React, { useEffect, useState } from 'react'
import { useDijieBaoJiaDanListQuery } from '../../../../store/ReactWebSiteApi';
import FileCheck from '../file/FileCheck';
import {Table } from 'antd';
import GroupFileDel from './GroupFileDel';
import GroupFileAdd from './GroupFileAdd';

function GroupFile(props) {
    const [list,setList] = useState()
    const {data, isSuccess} = useDijieBaoJiaDanListQuery({
        ltbjd_e_id:localStorage.getItem('e_id'),       
        ltbjd_tuanhao:props.lth_num,
    },{refetchOnMountOrArgChange:1});
    useEffect(() => {
        if (isSuccess) {
            // console.log(data);
            if(data.Status==='success'){                
                const arr = data.Result.map(item => {
                    const {ltbjd_id:key,ltbjd_yemei,ltbjd_yejiao,ltbjd_tuanhao,ltbjd_daoyou,ltbjd_jietuan_content,ltbjd_fenchexuhao,ltbjd_client_name,ltbjd_uname,ltbjd_dept_id,ltbjd_ctime,ltbjd_ltxl_name,ltbjd_date,ed_name,ltbjd_content,ltbjd_type_id,ltbjd_lth_id,ltbjd_daoyou_phone,ltbjd_daoyou_read_time,ltbjd_del_state
                    } = item;
                     return item = {key,ltbjd_yemei,ltbjd_yejiao,ltbjd_tuanhao,ltbjd_daoyou,ltbjd_jietuan_content,ltbjd_fenchexuhao,ltbjd_client_name,ltbjd_uname,ltbjd_dept_id,ltbjd_ctime,ltbjd_ltxl_name,ltbjd_date,ed_name,ltbjd_content,ltbjd_type_id,ltbjd_lth_id,ltbjd_daoyou_phone,ltbjd_daoyou_read_time,ltbjd_del_state
                     }
                })
                const sortArr = arr.sort( (a, b) => {      
                    let aTimeString = a.ltbjd_ctime;
                    let bTimeString = b.ltbjd_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
                
            }else{
                setList('')
            }            
        }
        
    }, [data, isSuccess, ])  
    const column = [
        {
            key:'1',
            title:'报价单',
            dataIndex:'key',
            width:'160px',
            render:(_,record) => {                
                return  <FileCheck 
                            ltbjd_id={record.key}
                            text={record.key}
                            ltbjd_dept_id={record.ltbjd_dept_id}
                            customType={record.ltbjd_type_id}
                        />
            }
        },
        {
            key:'2',
            title:'组团社',
            dataIndex:'ltbjd_client_name'
        },
        {
            key:'3',
            title:'线路名称',
            dataIndex:'ltbjd_ltxl_name'
        }, 
        {
            key:'4',
            title:'出发日期',
            dataIndex:'ltbjd_date'
        },
       
        {
            key:'5',
            title:'部门',
            dataIndex:'ed_name'
        },                     
        {
            key:'6',
            title:'备注',
            dataIndex:'ltbjd_content'
        },                                             
        {
            key:'10',
            title:'操作',
            width:"120px",
            render:(_,record) => {
                return <GroupFileDel ltbjd_id={record.key}/>
            }
        },
       
    ]
    return (
        <>
            <div style={{marginLeft:'20px',marginTop:'10px'}}>
                <GroupFileAdd 
                    lth_date={props.lth_date} 
                    lth_ltkh_id={props.lth_ltkh_id}
                    text='添加' 
                    lth_num={props.lth_num}   
                    lth_xianlu_name={props.lth_xianlu_name} 
                    ltbjd_kehu_type_id={props.ltbjd_kehu_type_id}    
                    ltbjd_lth_id={props.lth_id}                 
                />
            </div>
            <Table 
                columns={column} 
                dataSource={list} 
                pagination={{position:['none']}}
            />
        </>
    )
}

export default GroupFile