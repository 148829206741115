import React,{useState,useEffect,useRef}from 'react'
import {Modal,message,Form,Input,} from 'antd';
import { useGoodsTypeUpdateQuery, useGoodsTypeUpdateSaveMutation } from '../../store/ReactWebSiteApi';
function GoodsTypeEdit(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({egt_id:""});
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useGoodsTypeUpdateQuery({egt_id:info.egt_id},{skip:!info.egt_id})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    egt_name:dataEdit.Result.egt_name,
                    egt_content:dataEdit.Result.egt_content,
                })
                setInfo(prevState=>({...prevState,
                    egt_name:dataEdit.Result.egt_name,
                    egt_content:dataEdit.Result.egt_content,              
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useGoodsTypeUpdateSaveMutation()
    return (
        <>
            <a onClick={() => {
                setOpen(true);
                setInfo(prevState=>({...prevState,egt_id:props.egt_id}))
                }}>修改</a>
            <Modal
                open={open}
                title='修改产品类型'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateEdit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitUpdateSave({
                        egt_name:info.egt_name,
                        egt_e_id:localStorage.getItem('e_id'),
                        egt_update_uid:localStorage.getItem('eu_id'),
                        egt_update_uname:localStorage.getItem('eu_name'),
                        egt_id:props.egt_id,
                        egt_esv_id:props.egt_esv_id,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')   
                            setOpen(false)                             
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 7 }}
                    ref={editForm}
                >
                    <Form.Item name="egt_name" label='产品类型名称' wrapperCol={{span:10}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,egt_name:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default GoodsTypeEdit