import React,{useState,useEffect}from 'react'
import { Modal,Descriptions,Image,Space, Flex} from 'antd';
import { useIncomeExpenditureUpdateQuery} from '../../store/ReactWebSiteApi';
import LedgerGroupBalanceEdit from './LedgerGroupBalanceEdit';
import LedgerGroupBalanceDel from './LedgerGroupBalanceDel';
import LedgerGroupBalanceAudit from './LedgerGroupBalanceAudit';
import {FileTextOutlined} from '@ant-design/icons';
function LedgerGroupBalanceCheck(props) {
    const [open,setOpen] = useState()
    const [info,setInfo] = useState([])
    const {data,isSuccess} = useIncomeExpenditureUpdateQuery({eie_id:props.eie_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                let arr =[]
                if(data.Result.eie_image_file && data.Result.eie_image_file !== ['null']){
                    console.log(arr);
                    arr = data.Result.eie_image_file.map((item,index) => {                    
                        return  <Image width={60} key={index} src={`/api/assets/images/application/${item}`} />     
                    })
                }                                
                let array = [
                    {
                        key:"11",
                        label: '团号',
                        children: data.Result.ebn_team_num,
                    },
                    {
                        label: '客户',
                        children: data.Result.ec_name,        
                        key:"4",
                    },
                    {
                        label: '审核',
                        children: data.Result.eie_audit1_ctime && <>{data.Result.eie_audit1_ctime+data.Result.eie_audit1_uname}</>,
                        key:"16",
                    }, 
                    {
                        key:"12",
                        label: '出发日期',
                        children: data.Result.ebn_date,
                    },
                    {
                        label: '产品',
                        children: data.Result.eg_name,      
                        key:"134",
                    },
                    {
                        label: '复核',
                        children: data.Result.eie_audit2_ctime && <>{data.Result.eie_audit2_ctime+data.Result.eie_audit2_uname}</>,
                        key:"18",
                    },
                    {
                        label: '部门',
                        children: data.Result.ed_name,
                        key:"2",
                    },
                    {
                        label: '类型',
                        children: <>{data.Result.eie_finance_sub_type_name}</>,
                        key:"21",
                    },
                    {
                        label: '单价',
                        children: data.Result.eie_price,
                        key:"6",
                    },
                    {
                        label: '销售员',
                        children: data.Result.ebn_yw_name,   
                        key:"22",
                    },        
                    {
                        label: '金额',
                        children: data.Result.eie_amount,
                        key:"8",
                    },
                    {
                        label: '数量',
                        children: data.Result.eie_count,
                        key:"7",
                    }, 
                    {
                        label: '新增',
                        children: <>{data.Result.eie_ctime+data.Result.eie_uname}</>,
                        key:"11",
                    }, 
                    {
                        label: '备注',
                        children: data.Result.eie_content,
                        key:"9",
                    },
                    {
                        key:"1",
                        label: '编号',
                        children: data.Result.eie_id,
                    },     
                    {
                        label: '附件',
                        children: arr.length > 0 && <Image.PreviewGroup>{arr}</Image.PreviewGroup>,
                        key:"10",
                    },
                    {
                        label: '合同编号',
                        children: data.Result.eie_eci_id,
                        key:"111",
                    },       
                    {
                        label: '修改',
                        children: data.Result.eie_update_ctime && <>{data.Result.eie_update_ctime+data.Result.eie_update_uname}</>,
                        key:"14",
                    },
                    {
                        label: '下账时间',
                        children: data.Result.eie_hx_ctime && <>{data.Result.eie_hx_ctime+data.Result.eie_hx_uname}</>,
                        key:"20",
                    },      
                    {
                        label: '下账凭证',
                        children: data.Result.eie_hx_number,
                        key:"22",
                    },
                    {
                        label: '转账时间',
                        children: data.Result.eie_bank_ctime && <>{data.Result.eie_bank_ctime+data.Result.eie_bank_uname}</>,
                        key:"23",
                    },      
                    {
                        label: '转账凭证',
                        children: data.Result.eie_bank_log_id,
                        key:"24",
                    },
                    {
                        label: '操作',
                        children: <Space>
                            <LedgerGroupBalanceEdit
                                eie_id={props.eie_id}
                                title={props.title}
                                jbr_label={props.jbr_label}
                                eie_jbr_uname={props.eie_jbr_uname} 
                                eie_esv_id={props.eie_esv_id}
                                eie_jbr_uid={props.eie_jbr_uid}
                                eie_type_id={props.eie_type_id}
                                eie_finance_sub_type_id={props.eie_finance_sub_type_id}
                                eie_finance_sub_type_name={props.eie_finance_sub_type_name}                             
                                eie_ebn_id={props.eie_ebn_id}                            
                                eie_eg_id={props.eie_eg_id}
                                guige={props.guige}
                                eie_audit1_ctime={data.Result.eie_audit1_ctime}
                                eie_hx_ctime={data.Result.eie_hx_ctime}
                            />
                            <LedgerGroupBalanceDel 
                                eie_audit1_ctime={data.Result.eie_audit1_ctime}
                                eie_hx_ctime={data.Result.eie_hx_ctime}
                                eie_id={props.eie_id}
                            />
                            <LedgerGroupBalanceAudit 
                                eie_audit1_ctime={data.Result.eie_audit1_ctime}
                                eie_audit2_ctime={data.Result.eie_audit2_ctime}
                                eie_id={props.eie_id}
                            />
                        </Space>,
                        key:"23",
                    },
                ]
                console.log(array);
                const newArr = array.filter(item => {
                    if(item.children !== '' && item.children !== null && item.children !== '0' && item.children !== false){
                        return true
                    }else{
                        return false
                    }
                });
                setInfo(newArr)
            }
        }
    },[data, isSuccess, props])

   
    return (
        <>
            <a onClick={() => {
                setOpen(true)}}>
                    <Space><FileTextOutlined/> SZD{props.eie_id}</Space>
                </a>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                width={1190}
                onCancel={() => {setOpen(false);}} 
                footer={null}                                      
            >
                <div style={{border:'2px solid #eee',margin:"10px"}}>
                    <Flex justify='center'><h2>{props.title}</h2></Flex>
                    <Descriptions                                  
                        items={info} 
                        bordered
                    />
                </div>                
            </Modal>
        </>
    )
}

export default LedgerGroupBalanceCheck