import React,{useState,useEffect} from 'react'
import Main from '../../cpn/Main'
import { useAddStreetMutation } from '../../store/ReactWebSiteApi'
import { message,Button, Form, Input, Row,  Col,Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useGetProvinceQuery } from '../../store/ReactWebSiteApi';
import { useGetCityQuery } from '../../store/ReactWebSiteApi';
import { useGetDistrictQuery } from '../../store/ReactWebSiteApi';
function StreetAdd(props) {  
  //获取省份数据
  const [province, setProvince] = useState();
  const [selectProvince, setSelectProvince] = useState();
  //获取区县数据
  const [city, setCity] = useState();
  const [selectCity, setSelectCity] = useState();  
  //获取区县数据
  const [district, setDistrict] = useState();
  const [selectDistrict, setSelectDistrict] = useState();
  //API
  const { data: dataProvince, isSuccess: isSuccessProvince } = useGetProvinceQuery();
  const { data: dataCity, isSuccess: isSuccessCity} = useGetCityQuery({ province_id: selectProvince,action:'search' })
  const { data: dataDistrict, isSuccess: isSuccessDistrict} = useGetDistrictQuery({province_id: selectProvince,city_id: selectCity,action:'search' }, {
    refetchOnMountOrArgChange: true,
  });
  //省选择后加载
  useEffect(() => {
    if (isSuccessProvince) {
      console.log(dataProvince);
      const newArr = dataProvince.Result.map((item) => {
        const { ctap_id:value, ctap_name:label } = item
        return item = { value, label }
      })
      setProvince(newArr);
    }
    
  }, [dataProvince, isSuccessProvince,])
  //城市选择后加载
  useEffect(() => {
    if (isSuccessCity) {
      const newArr = dataCity.Result.map((item) => {
        const { ctaci_id:value, ctaci_name:label } = item
        return item = { value, label }
      })
      setCity(newArr);
    }
  }, [dataCity, isSuccessCity, selectProvince])
  //区县选择后加载
  useEffect(() => {
    if (isSuccessDistrict) {
      const newArr = dataDistrict.Result.map((item) => {
        const { ctad_id:value, ctad_name:label } = item
        return item = { value, label }
      })
      setDistrict(newArr);
    }
  }, [dataDistrict, isSuccessDistrict, selectCity])
  //绑定查询省
  const handleChange = (e) => {
    setSelectProvince(e);
    setSelectCity(undefined);
    setStreet(prevState => ({ ...prevState, ctas_province_id: e}));
  }
  //绑定查询城市
  const handleChangeCity = (e) => {    
    setSelectCity(e)
    setStreet(prevState => ({ ...prevState, ctas_city_id: e}));
  }
   //绑定查询区县
   const handleChangedistrict = (e) => {    
    setSelectDistrict(e)
    setStreet(prevState => ({ ...prevState, ctas_district_id: e}));
  }
  //新增数据
  const [street, setStreet] = useState({
    ctas_province_id: '',
    ctas_city_id: '',
    ctas_district_id:'',
    ctas_name:'',
    ctas_remark: '',
  });
  //绑定区县名称
  const nameInput = (e) => {
    setStreet(prevState =>({...prevState,ctas_name:e.target.value}))
  }
  //绑定区县备注
  const remarkInput = (e) => {
    setStreet(prevState =>({...prevState,ctas_remark:e.target.value}))
  }
  //链接
  const navi = useNavigate();
  //上传数据
  const [addStreet] = useAddStreetMutation();
  //提交对话框表单
  const onFinish = () => {
    addStreet(street).then(
      (res) => {
        if (res.data.Status === 'success') {
          message.success('新增街道成功！')
          navi('/StreetList');                                    
        }
        if (res.data.Status === 'fail') {
          message.error('新增街道失败！'+res.data.Message)
        }
      })
  }
  const onFinishFailed = () => {}
  return (
    <Main title1='系统' title2='地区' title3='街道' title4='新增区县'>
      <Row>
        <Col span={12}>
          <Form
              name="basic"
              labelCol={{span: 4,}}
              wrapperCol={{span: 20,}}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
          >
            <Form.Item
              label="1、选择区县"
              name="province"
              required
            >              
              <Select
              style={{ width: 120,}}
              onChange={handleChange}
              options={province}
              value={selectProvince}
              placeholder='请选择省'
              />
              <Select
                style={{ width: 120,}}
                onChange={handleChangeCity}
                options={city}
                value={selectCity}
                placeholder='请选择城市'
              />
              <Select
              style={{ width: 120,}}
              onChange={handleChangedistrict}
              options={district}
              value={selectDistrict}
              placeholder='请选择区县'
            />
            </Form.Item>        
            <Form.Item
                label="2、填写区县名称"
                name="name"
                rules={[{
                    required: true,
                    message: '请填写区县名称！',
                },]}
                wrapperCol={{
                    span: 4,
                }}
            >
                <Input value={street.ctas_name} onChange={nameInput} />
            </Form.Item>
            <Form.Item
                label="3、填写备注"
                name='remark'              
            >
              <Input value={street.ctas_remark} onChange={remarkInput} />
            </Form.Item>                    
            <Form.Item
                wrapperCol={{
                    offset: 2,
                    span: 16,
                }}
            >
              <Button type="primary" htmlType="submit">提交</Button>
            </Form.Item>
          </Form>          
        </Col>
      </Row>
    </Main>
  )
}
export default StreetAdd