import { Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import {useTourGuideListQuery } from '../../store/ReactWebSiteApi';
function Guide(props) {
    const [list, setList] = useState();
    const {data,isSuccess} = useTourGuideListQuery({ltdy_e_id:localStorage.getItem('e_id')},{refetchOnMountOrArgChange:1})
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                const newArr = data.Result.map(item => {
                    const {tgu_id:key,tgu_name,tgu_phone,tgu_uname,tgu_ctime,} = item;
                    return item = {key,tgu_name,tgu_phone,tgu_uname,tgu_ctime,}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.tgu_ctime;
                    let bTimeString = b.tgu_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])    
    const columns = [
        {
            key:'1',
            title:'导游',
            dataIndex: 'tgu_name',
        },
        {
            key:'2',
            title:'手机号码',
            dataIndex: 'tgu_phone',
        },
        {
            key:'3',
            title:'',
            dataIndex: 'key',
        },
        {
            key:'5',
            title:'操作',
            render:(_,record) => {
                return <Space>
                    <a onClick={() => {
                            props.getGuide({tgu_id:record.key,tgu_name:record.tgu_name,tgu_phone:record.tgu_phone,})
                            props.getOpen(false)
                            }}>选择</a>
                </Space>
            }
        },
    ]
    return (
        <>
            <Table dataSource={list} columns={columns}/>
        </>
    )
}

export default Guide