import { message, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDijieTemplateDaoYouDelMutation, useDijieTemplateDaoYouListQuery } from '../../../../../store/ReactWebSiteApi';
import ModalDel from '../../../../../cpn/ModalDel';
import FeedbackAdd from './FeedbackAdd';

function Feedback(props) {
    const [list, setList] = useState();
    const {data,isSuccess} = useDijieTemplateDaoYouListQuery({ltdy_e_id:localStorage.getItem('e_id')},{refetchOnMountOrArgChange:1})
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                const newArr = data.Result.map(item => {
                    const {ltdy_id:key,ltdy_name,ltdy_phone,ltdy_content,ltdy_uname,ltdy_ctime,ltdy_dept_name,ect_name,ltdy_update_ctime,ltdy_update_uname,ltdy_type_id} = item;
                    return item = {key,ltdy_name,ltdy_phone,ltdy_content,ltdy_uname,ltdy_ctime,ltdy_dept_name,ect_name,ltdy_update_ctime,ltdy_update_uname,ltdy_type_id}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ltdy_ctime;
                    let bTimeString = b.ltdy_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])  
    const [del] = useDijieTemplateDaoYouDelMutation()    
    const columns = [
        {
            key:'1',
            title:'意见单模板',
            dataIndex: 'ltdy_name',
        },    
        {
            key:'4',
            title:'部门',
            dataIndex: 'ltdy_dept_name',
        },
        {
            key:'5',
            title:'操作',
            render:(_,record) => {
                return <Space>
                    
                    <ModalDel onOk={() => {
                            del({ltdy_id:record.key}).then((res) => {
                                if (res.data.Status === 'success') {
                                    message.success('删除成功！')                                                                              
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('删除失败！'+res.data.Message)
                                }
                            })
                        }} />
                    {props.type==='select' && <a onClick={() => {
                            props.getFeedback({ltdy_name:record.ltdy_name,ltdy_phone:record.ltdy_phone,})
                            props.getOpen(false)
                            }}>选择</a>}   
                </Space>
            }
        },
    ]
    return (
        <>
            <FeedbackAdd />
            <Table dataSource={list} columns={columns}/>
        </>
    )
}

export default Feedback