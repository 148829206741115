export default function useInAndOut(initialState) {
    let inAndOut
    switch (initialState) {
        case '1'://销售收入
            inAndOut = 1
            break;
        case '2'://成本支出
            inAndOut = -1
            break;
        case '3'://费用支出
            inAndOut = -1
            break;
        case '4'://其他收入
            inAndOut = 1
            break;
        case '5'://其他支出
            inAndOut = -1
            break;              
        default:
            break;
    }
    return inAndOut
}
