import React, { useEffect, useState } from 'react'
import TaTop from './TaTop'
import {  Layout, Menu,} from 'antd';
import { useGetGoodsTreeQuery } from '../../store/ReactWebSiteApi';
import TaCourseCheck from './TaCourseCheck';
const {  Content,  Sider } = Layout;

function TaCourse() {
    const {data,isSuccess} = useGetGoodsTreeQuery({eowg_e_id:'218'});
    const [list,setList] = useState();
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                setList(data.Result)
            }
        }
    },[data,isSuccess])
    const [id,setId] = useState('20240807102750788538')
    return (
        <>
            <TaTop activeKey='1'></TaTop>
            <Content style={{padding: '0 10px',}}>                
                <Layout
                    style={{padding: '24px 0',background: '#fff',borderRadius:'20px',}}
                >
                    <Sider
                        style={{background: '#fff',}}
                        width={360}
                    >
                        <Menu
                            mode="inline"
                            style={{height: '100%',}}
                            items={list}
                            defaultSelectedKeys={['20240807102750788538']}
                            onClick={(e) => {
                                console.log(e);
                                setId(e.key)
                            }}
                            defaultOpenKeys={['20240807102750788542','20240807102750788547']}
                            
                        />
                    </Sider>
                    <Content
                        style={{
                        padding: '0 24px',
                        minHeight: 280,
                        }}
                    >
                        <TaCourseCheck eowg_key={id} />
                    </Content>
                </Layout>
            </Content>
        </>
    )
}

export default TaCourse