import React from 'react'
import Main from '../../../cpn/Main'
import { Row } from 'antd';
import Folder from '../../../cpn/Folder';
function CustomFileIndex() {
    return (
        <Main t1='地接业务' n1='/LocalIndex' t2='定制游' n2='/CustomIndex' t3='销售文件'>
            <Row>
                <Folder title='文件列表' link='/CustomFile'/>          
                <Folder title='文件模板' link='/CustomTemplateIndex'/>    
            </Row>                    
        </Main>
    )
}

export default CustomFileIndex