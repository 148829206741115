import React,{useState,}from 'react'
import { Modal,message,Form,Input, Button, Space,} from 'antd';
import { useClientTypeAddMutation,  } from '../../store/ReactWebSiteApi';

function ClientTypeAdd() {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        // console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState();
    const [submitAdd] = useClientTypeAddMutation()
    console.log(info);
    return (
        <>
            <Button type='primary'  onClick={() => {setOpen(true);}}>新增</Button>
            <Modal
                        open={open}
                        title='新增客户类型'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpen(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClient(values);
                                })
                                .catch((info) => {
                                    // console.log('Validate Failed:', info);
                                });
                            submitAdd({
                                ect_name:info,
                                ect_e_id:localStorage.getItem('e_id'),
                                ect_uid:localStorage.getItem('eu_id'),
                                ect_uname:localStorage.getItem('eu_name'),
                            }).then((res) => {
                                // console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpen(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 7 }}
                        >                            
                            <Form.Item label='常用客户类型' name='use' wrapperCol={{span:17}}>
                                <Space wrap>
                                    <a onClick={() => {
                                        form.setFieldsValue({name:'员工'})
                                        setInfo('员工')
                                        }}
                                    >员工</a>
                                    <a onClick={() => {
                                        form.setFieldsValue({name:'老板和股东'})
                                        setInfo('老板和股东')
                                        }}
                                    >老板和股东</a>
                                    <a  onClick={() => {
                                        form.setFieldsValue({name:'政府部门'})
                                        setInfo('政府部门')
                                        }}
                                    >政府部门</a>
                                    <a  onClick={() => {
                                        form.setFieldsValue({name:'供应商'})
                                        setInfo('供应商')
                                        }}
                                    >供应商</a>
                                    <a  onClick={() => {
                                        form.setFieldsValue({name:'组团社'})
                                        setInfo('组团社')
                                        }}
                                    >组团社</a>
                                    <a  onClick={() => {
                                        form.setFieldsValue({name:'门市散客'})
                                        setInfo('门市散客')
                                        }}
                                    >门市散客</a>
                                    <a  onClick={() => {
                                        form.setFieldsValue({name:'抖音'})
                                        setInfo('抖音')
                                        }}
                                    >抖音</a>
                                    <a  onClick={() => {
                                        form.setFieldsValue({name:'飞猪'})
                                        setInfo('飞猪')
                                        }}
                                    >飞猪</a>
                                    <a  onClick={() => {
                                        form.setFieldsValue({name:'携程'})
                                        setInfo('携程')
                                        }}
                                    >携程</a>
                                    <a  onClick={() => {
                                        form.setFieldsValue({name:'美团'})
                                        setInfo('美团')
                                        }}
                                    >美团</a>
                                </Space>
                            </Form.Item>
                            <Form.Item 
                                name="name" 
                                label='客户类型' 
                                wrapperCol={{span:10}} 
                                required                                
                            >
                                <Input            
                                    onChange={(e) => {setInfo(e.target.value)}}
                                />
                            </Form.Item>
                        </Form>
                    </Modal>
        </>
    )
}

export default ClientTypeAdd