import React from 'react'
import { Modal,} from 'antd';
function ModalEdit(props) {
    return(
        <>
            {!props.link && <a onClick={() => {
                props.getOpen(true)
            }}>修改</a>}
            <Modal
                width={props.width}                        
                open={props.open}
                title={`修改${props.title}`}
                okText="提交"
                cancelText="取消"
                onCancel={() => {props.getOpen(false)}}
                onOk={() => {props.onOk()}}
            >
                {props.children}
            </Modal>
        </>
    )
}

export default ModalEdit