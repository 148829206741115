import { Button, Form, Input, Modal,} from 'antd'
import React, { useState } from 'react'
import GetUserDept from '../../../../../cpn/GetUserDept';

function NotesQuery(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const [info,setInfo] = useState({ltzysx_dept_id: '',ltzysx_content:'',ltzysx_name:''})
    return (
        <>
            <Button onClick={() => { setOpen(true)}}  >查询</Button>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            props.getQuery(values)
                            setOpen(false)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });                    
                }}
                title='查询注意事项'
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        <Button onClick={() => {                                        
                            form.resetFields();  
                            }}>重置</Button>
                        <CancelBtn />
                        <OkBtn />
                    </>
                )}            
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    labelCol={{ span: 4 }}
                    initialValues={{ltzysx_dept_id: '',ltzysx_content:'',ltzysx_name:''}}
                >                                           
                    <GetUserDept 
                        label='部门' 
                        name='ltzysx_dept_id'
                        wrapperCol={{span:10}}
                        getUserDept={(e) => {setInfo(prevState=>({...prevState,ltzysx_dept_id:e}))}}
                        value={info.ltzysx_dept_id}
                    />
                    <Form.Item 
                        name="ltzysx_name" 
                        label='标题' 
                        wrapperCol={{span:10}}                    
                    >
                        <Input 
                            value={info.ltzysx_name}
                            onChange={(e) => {setInfo(prevState=>({...prevState,ltzysx_name:e.target.value}))}}
                        />
                    </Form.Item>
                    <Form.Item name="ltzysx_content" label='描述' wrapperCol={{span:10}}>
                        <Input 
                            value={info.ltzysx_content}
                            onChange={(e) => {setInfo(prevState=>({...prevState,ltzysx_content:e.target.value}))}}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default NotesQuery