import React,{useState,useEffect}from 'react'
import Main from '../../cpn/Main'
import { Table,DatePicker} from 'antd';
import { useProfitListQuery } from '../../store/ReactWebSiteFinanceApi';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;
function Loan() {
    const [query,setQuery] = useState({
        ecal_e_id:localStorage.getItem('e_id')
    })
    
    const {data, isSuccess} = useProfitListQuery(query);
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if (isSuccess) {
            //console.log(data);
            if(data.Status==='success'){
                
                setList([
                    {
                        key:1,
                        s_date:data.Result.s_date,
                        e_date:data.Result.e_date,
                        income_num:data.Result.income_num,
                        disburse_num:data.Result.disburse_num,
                        profit_num:data.Result.profit_num,
                    }
                ])
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    const columns = [
        {
            key: 's_date',
            title: '开始日期',
            dataIndex: 's_date'
        },      
        {
            key: 'e_date',
            title: '开始日期',
            dataIndex: 'e_date'
        },      
        {
            key: 'income_num',
            title: '营业收入',
            dataIndex: 'income_num'
        },      
        {
            key: 'disburse_num',
            title: '费用支出',
            dataIndex:'disburse_num'
        },
        {
            key: 'profit_num',
            title: '利润',
            dataIndex: 'profit_num',
        },     
    ]

      
    return (
        <Main t1='财务管理' t2='收支管理' t3='利润表'>    
            <RangePicker onChange={(e) => {
                setQuery(prevState=>({
                    ...prevState,
                    s_date:dayjs(e[0]).format('YYYY-MM-DD'),
                    e_date:dayjs(e[1]).format('YYYY-MM-DD')
                    }))
                }}
            />
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table>
        </Main>
    )
}

export default Loan