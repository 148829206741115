import React,{useState,useEffect,useRef}from 'react'

import { Table,Space,Modal,message,Form,Input, } from 'antd';
import { useClientTypeDelMutation, useClientTypeListQuery, useClientTypeUpdateQuery, useClientTypeUpdateSaveMutation } from '../../store/ReactWebSiteApi';
import ClientTypeAdd from './ClientTypeAdd';

function SetClientType() {
    const [open,setOpen] = useState(false);
    const [form] = Form.useForm();
    //修改客户类型-----------------------------------------------------------------------------------------------------------------------------修改客户类型
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {
        // console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    const [infoEdit,setInfoEdit] = useState();
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useClientTypeUpdateQuery({ect_id:editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                // console.log(dataEdit);
                editForm.current.setFieldsValue({
                    ect_name:dataEdit.Result.ect_name,
                })
                setInfoEdit(prevState=>({...prevState,
                    ect_name:dataEdit.Result.ect_name,
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useClientTypeUpdateSaveMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改客户类型'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    // console.log('Validate Failed:', info);
                                });
                            submitUpdateSave({
                                ect_name:infoEdit.ect_name,
                                ect_e_id:localStorage.getItem('e_id'),
                                ect_uid:localStorage.getItem('eu_id'),
                                ect_uname:localStorage.getItem('eu_name'),
                                ect_id:editId,
                            }).then((res) => {
                                // console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >
                            <Form.Item name="ect_name" label='客户类型名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ect_name:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal>
    //删除客户类型-----------------------------------------------------------------------------------------------------------------------------删除客户类型
    const {confirm} = Modal; 
    const [submitDel] = useClientTypeDelMutation()
    //客户类型列表-----------------------------------------------------------------------------------------------------------------------------客户类型列表
    
    const {data,isSuccess} = useClientTypeListQuery({ect_e_id:localStorage.getItem('e_id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {ect_id:key,ect_name,ect_content,ect_uname,ect_ctime,ect_update_ctime,ect_update_uname,num} = item;
                    return item = {key,ect_name,ect_content,ect_uname,ect_ctime,ect_update_ctime,ect_update_uname,num}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ect_ctime;
                    let bTimeString = b.ect_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])      
    const columns = [

       
        {
            key: 'type',
            title: '客户类型名称',
            dataIndex: 'ect_name',
        },           
        {
            key: 'ect_content',
            title: '备注',
            dataIndex: 'ect_content',
        },           
        {
            key: 'num',
            title: '客户数量',
            dataIndex: 'num',
        }, 
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },      
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.ect_ctime + record.ect_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title:'操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>                        
                        <a onClick={() => {
                            setEditId(record.key);
                            setOpenEdit(true)
                        }}>修改</a>
                        {+record.num === 0 && <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({ect_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                // console.log('Cancel');
                                },
                            });
                        }}>删除</a>}
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <>
            <Form.Item>
                <a onClick={() => {setOpen(true)}}>设置</a>
            </Form.Item>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {}}
                footer={null}
                width={800}
                title='设置客户类型'
            >
                <Space  style={{marginBottom:"10px"}}>
                    <ClientTypeAdd />
                </Space>
                <Table columns={columns} dataSource={list} pagination={{position:['none']}}></Table> 
            </Modal>
            
            {modalEdit}            
        </>
    )
}

export default SetClientType