import React ,{useState} from 'react'
import {Modal,} from 'antd';
function AppCoursePreview(props) {
    const [open, setOpen] = useState(false);
    const Ok = () => {setOpen(false);};
    const Cancel = () => {setOpen(false);};
    return (
        <>
            <a onClick={() => {setOpen(true)}}>详情</a>
            <Modal title={props.esvs_name} open={open} onOk={Ok} onCancel={Cancel} okText='关闭' footer={null}>
                <div>                                        
                    <div dangerouslySetInnerHTML={{__html:props.esvs_content}} style={{maxWidth:"470px",overflow:"hidden"}}></div>
                </div>                
            </Modal>
        </>
    )
}

export default AppCoursePreview