import { Select,Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGetUserDeptManageQuery } from '../store/ReactWebSiteApi';

function GetUserDept(props) {
    const [list,setList] = useState('');
    const {data, isSuccess} = useGetUserDeptManageQuery({eu_id:localStorage.getItem('eu_id')});
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                const newArr = data.Result.map((item) => {
                    const {ed_id,ed_name,}=item;
                    return item = {value:ed_id,label:ed_name}
                })
                if(props.selectAll){
                    newArr.unshift({value:'',label:'所有部门'})
                    setList(newArr)
                }else{
                    setList(newArr)  
                }              
            }
        }
    },[data, isSuccess, props.selectAll])
    // console.log(props.initialValue);
    console.log(list.length);
    return (
        <>
            <Form.Item 
                label={props.label} 
                wrapperCol={props.wrapperCol} 
                required={props.required} 
                name={props.name}      
                initialValue={props.initialValue}      
            >
                <Select 
                    options={list} 
                    onChange={(_,f) => {
                        // console.log(f);
                        props.getUserDept(f.value,f.label)
                    }}
                    style={props.style}
                    disabled={props.initialValue}
                />
            </Form.Item>
        </>
    )
}

export default GetUserDept