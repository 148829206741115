import React,{useState,useEffect,useRef}from 'react'
import { Table,Image,Space,Modal,message,Form,Input,Upload,Button, Select, DatePicker} from 'antd';
import Main from '../../cpn/Main'
import { useBusinessNumAddMutation, useContractDelMutation, useContractListQuery, useContractUpdateQuery, useContractUpdateSaveMutation, useGoodsListQuery } from '../../store/ReactWebSiteApi';
import ImagePreview from '../../cpn/ImagePreview'
import GetStaff from '../../cpn/GetUser';
import { UploadOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

function NetTechContract() {
    const [form] = Form.useForm();    
    //修改合同-----------------------------------------------------------------------------------------------------------------------------修改合同
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    //获取员工
    const getStaff = (e,f,g) => {
        // console.log(e,f,g);
        setInfoEdit(prevState=>({...prevState,eci_jsr_uid:e,}))
    }
    //默认图片
    const [defaultPic,setDefaultPic] = useState();
    //上传图片
    const handleEdit = (e) => {
        if (e.file.status === 'done'||e.file.status==='removed') {
            const fileArr = e.fileList.map((item) => {
                const { response } = item
                return response.Result
            })
            console.log(e);
            let fileStr = fileArr + "";  
            if(fileStr === null){
                fileStr = ''
            }
            const nameArr = e.fileList.map((item) => {
                const { name} = item
                return name
            })
            let nameStr = nameArr + ""
            setInfoEdit(prevState=>({...prevState,eci_image_file:fileStr,eci_image_name:nameStr}))
        }
    }   
    const [infoEdit,setInfoEdit] = useState({name:''});
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useContractUpdateQuery({eci_id:editId},{skip:!editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                //默认图片
                if (dataEdit.Result.eci_image_file) {
                    let options = [];
                    let size = dataEdit.Result.eci_image_file.length;
                    for (let i = 0; i < size; i++) {
                        let a = {};
                        a.uid = i;
                        a.url = `/api/assets/images/application/${dataEdit.Result.eci_image_file[i]}`
                        a.response = {Result:dataEdit.Result.eci_image_file[i]}
                        options.push(a);
                    }
                    //console.log(options);
                    setDefaultPic(<Upload
                        name="image_file"
                        action="/api/ReactApi/zd_single_upload_image"
                        listType="picture"
                        defaultFileList={options}
                        className="upload-list-inline"
                        onChange={handleEdit}
                        >
                        <Button icon={<UploadOutlined />}>点击上传</Button>
                    </Upload>)
                }else{
                    setDefaultPic(<Upload
                        name="image_file"
                        action="/api/ReactApi/zd_single_upload_image"
                        listType="picture"
                        className="upload-list-inline"
                        onChange={handleEdit}
                        >
                        <Button icon={<UploadOutlined />}>点击上传</Button>
                    </Upload>)
                }
                editForm.current.setFieldsValue({
                    eci_name:dataEdit.Result.eci_name,
                    eci_content:dataEdit.Result.eci_content,
                })
                setInfoEdit(prevState=>({...prevState,
                    eci_name:dataEdit.Result.eci_name,
                    eci_content:dataEdit.Result.eci_content,
                    eu_name:dataEdit.Result.eu_name,  
                    eci_image_name:dataEdit.Result.eci_image_name,
                    eci_image_file:dataEdit.Result.eci_image_file,            
                    eci_client_id:dataEdit.Result.eci_client_id,            
                    eci_jsr_uid:dataEdit.Result.eci_jsr_uid,            
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useContractUpdateSaveMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改合同'
                        okText="提交"
                        cancelText="取消"
                        width={1000}
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitUpdateSave({
                                eci_esv_id:'6',
                                eci_e_id:localStorage.getItem('e_id'),
                                eci_dept_id:localStorage.getItem('ed_id'),
                                eci_update_uid:localStorage.getItem('eu_id'),
                                eci_update_uname:localStorage.getItem('eu_name'),
                                eci_name:infoEdit.eci_name,
                                eci_client_id:infoEdit.eci_client_id,
                                eci_s_date:'',
                                eci_e_date:'',
                                eci_content:infoEdit.eci_content,
                                eci_image_name:infoEdit.eci_image_name,
                                eci_image_file:infoEdit.eci_image_file,
                                eci_jsr_uid:infoEdit.eci_jsr_uid,
                                eci_id:editId,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >
                            <Form.Item name="name" label='客户名称' wrapperCol={{span:10}} required>
                                {infoEdit.name}
                            </Form.Item>
                            <Form.Item name="eci_name" label='合同名称' wrapperCol={{span:15}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eci_name:e.target.value}))}}/>
                            </Form.Item>                            
                            <Form.Item name="eci_content" label='备注' wrapperCol={{span:15}}>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eci_content:e.target.value}))}}/>
                            </Form.Item>
                            <GetStaff getStaff={getStaff} label='经手人' defaultId={infoEdit.eu_name}/>
                            <Form.Item label="图片"                             
                                valuePropName='fileList'
                                getValueFromEvent={e => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e && e.fileList;
                                }}
                                >
                                {defaultPic}
                            </Form.Item>
                        </Form>
                    </Modal>
    //删除合同-----------------------------------------------------------------------------------------------------------------------------删除合同
    const {confirm} = Modal; 
    const [submitDel] = useContractDelMutation()
    //合同列表-----------------------------------------------------------------------------------------------------------------------------合同列表
    
    const {data,isSuccess} = useContractListQuery({eci_e_id:localStorage.getItem('e_id'),eci_esv_id:'6'})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {eci_id:key,eci_name,eci_content,eci_uname,eci_ctime,eci_update_ctime,eci_update_uname,ec_name,eci_image_file,eu_name} = item;
                    return item = {key,eci_name,eci_content,eci_uname,eci_ctime,eci_update_ctime,eci_update_uname,ec_name,eci_image_file,eu_name}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eci_ctime;
                    let bTimeString = b.eci_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    const navi = useNavigate();
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'name',
            title: '客户',
            dataIndex: 'ec_name',
        },     
        {
            key: 'type',
            title: '合同名称',
            dataIndex: 'eci_name',
        },     
        {
            key: 'content',
            title: '备注',
            dataIndex: 'eci_content',
        },    
        {
            key: 'jsr',
            title: '经手人',
            dataIndex: 'eu_name',
        },    
        {
            key: 'pic',
            title: '图片',
            dataIndex: 'eci_image_file',
            render: (text) => {
                let newArr
                if (text) {
                    newArr = text.map((item,index) => {
                        return <Image src={`/api/assets/images/application/${item}`} key={index}  />
                    })
                    return <ImagePreview
                        firstPic={`/api/assets/images/application/${text[0]}`}
                        pics={newArr}
                    />
                }
            }
        }, 
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.eci_ctime + record.eci_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>    
                        <a onClick={() => {
                            setEditId(record.key);
                            setInfoEdit(prevState=>({...prevState,name:record.ec_name}))
                            setOpenEdit(true)
                        }}>修改</a>
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({eci_id:record.key}).then((res) => {
                                        console.log(res);
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>
                        <a onClick={() => {navi(`/NetTechContractSerial?id=${record.key}&name=${record.eci_name}&clientName=${record.ec_name}`)}}>业务流水</a>
                    </Space>
                </>
            }
        },
    ]
    //新增流水号-----------------------------------------------------------------------------------------------------------------------------新增流水号
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [infoAdd,setInfoAdd] = useState();
    //产品列表
    
    const {data:dataGoods,isSuccess:isSuccessGoods} = useGoodsListQuery({eg_e_id:localStorage.getItem('e_id'),eg_esv_id:'6'})
    
    const [listGoods, setListGoods] = useState();
    
    useEffect(() => {
        if(isSuccessGoods){            
            if(dataGoods.Status==='success'){
                console.log(dataGoods);
                
                const newArr = dataGoods.Result.map(item => {
                    const {eg_id:key,eg_name} = item;
                    return item = {value:key,label:eg_name}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eg_ctime;
                    let bTimeString = b.eg_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setListGoods(sortArr)
            }else{
                setListGoods('')
            }
            
        }
    }, [dataGoods, isSuccessGoods])   
    const [submitAdd] = useBusinessNumAddMutation()
    const modalAdd= <Modal
                        open={openAdd}
                        title='新增流水号'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        width={1000}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClient(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitAdd({
                                ebn_esv_id:'6',
                                ebn_e_id:localStorage.getItem('e_id'),
                                ebn_dept_id:localStorage.getItem('ed_id'),
                                ebn_eci_id:infoAdd.ebn_eci_id,
                                ebn_eg_id:infoAdd.ebn_eg_id,
                                ebn_date:infoAdd.ebn_date,
                                ebn_content:infoAdd.ebn_content,
                                ebn_uid:localStorage.getItem('eu_id'),
                                ebn_uname:localStorage.getItem('eu_name'),
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpenAdd(false)
                                    navi('/NetTechSerial')                      
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 3 }}
                        >
                            <Form.Item name="content" label='产品' wrapperCol={{span:15}} required>
                                <Select options={listGoods} onChange={(e) => {setInfoAdd(prevState=>({...prevState,ebn_eg_id:e}))}}/>
                            </Form.Item>
                            <Form.Item name="date" label='日期' wrapperCol={{span:15}} required>
                                <DatePicker options={listGoods} onChange={(e) => {setInfoAdd(prevState=>({...prevState,ebn_date:dayjs(e).format('YYYY-MM-DD')}))}}/>
                            </Form.Item>
                            <Form.Item name="name" label='备注' wrapperCol={{span:10}}>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ebn_content:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal> 
    return (
        <Main  topActive='NetTechContract' app='netTech'>   
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增合同</Button>
            </div>           
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalEdit} 
            {modalAdd} 
        </Main>
    )
}

export default NetTechContract