import React,{useState,}from 'react'
import {Modal,Form, Button,DatePicker,Input} from 'antd';
import GetClient from '../../finance/client/GetClient'
import GetDept from '../../cpn/GetDept';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;
function ArapCreditQuery(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    return (
        <>
            <Button style={{marginLeft:'10px'}} onClick={() => {setOpen(true)}}>查询</Button>
            <Modal 
                open={open} 
                title='查询团队收支单'
                okText="提交"
                cancelText="取消"
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        <Button onClick={() => {                                                  
                            localStorage.setItem('LedgerBalance_query_dept','')
                            localStorage.setItem('LedgerBalance_query_client','')
                            localStorage.setItem('LedgerBalance_query_team_num','')
                            localStorage.setItem('LedgerBalance_query_arrive_date_s','')
                            localStorage.setItem('LedgerBalance_query_arrive_date_e','')
                            localStorage.setItem('LedgerBalance_query_date_s','')
                            localStorage.setItem('LedgerBalance_query_date_e','')
                            localStorage.setItem('LedgerBalance_query_action','')
                            setOpen(false)
                            props.getQuery({action:'',eie_client_id:'',eie_arrive_date_s:'',ebn_team_num:'',eie_dept_id:'',ebn_date_s:''})                                    
                            form.resetFields();        
                            }}>重置</Button>
                        <CancelBtn />
                        <OkBtn />
                    </>
                )}
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            console.log(values);
                            props.getQuery({
                                action:"search",
                                eie_arrive_date_s:values.eie_arrive_date_s?dayjs(values.eie_arrive_date_s[0]).format('YYYY-MM-DD'):'',
                                eie_arrive_date_e:values.eie_arrive_date_s?dayjs(values.eie_arrive_date_s[1]).format('YYYY-MM-DD'):'',
                                ebn_date_s:values.ebn_date_s?dayjs(values.ebn_date_s[0]).format('YYYY-MM-DD'):'',
                                ebn_date_e:values.ebn_date_s?dayjs(values.ebn_date_s[1]).format('YYYY-MM-DD'):'',
                                eie_dept_id:values.eie_dept_id,
                                eie_client_id:values.eie_client_id,
                                ebn_team_num:values.ebn_team_num,                                
                            })
                            setOpen(false)  
                            localStorage.setItem('LedgerBalance_query_dept',values.eie_dept_id)
                            localStorage.setItem('LedgerBalance_query_client',values.eie_client_id)
                            localStorage.setItem('LedgerBalance_query_team_num',values.ebn_team_num)
                            localStorage.setItem('LedgerBalance_query_arrive_date_s',values.eie_arrive_date_s?dayjs(values.eie_arrive_date_s[0]).format('YYYY-MM-DD'):'')
                            localStorage.setItem('LedgerBalance_query_arrive_date_e',values.eie_arrive_date_s?dayjs(values.eie_arrive_date_s[1]).format('YYYY-MM-DD'):'')
                            localStorage.setItem('LedgerBalance_query_date_s',values.ebn_date_s?dayjs(values.ebn_date_s[0]).format('YYYY-MM-DD'):'')
                            localStorage.setItem('LedgerBalance_query_date_e',values.ebn_date_s?dayjs(values.ebn_date_s[1]).format('YYYY-MM-DD'):'')
                            localStorage.setItem('LedgerBalance_query_action','search')
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });           
                }}
            >
                <Form 
                    form={form}
                    layout="horizonal"
                    name="form_in_modal"
                    initialValues={{                        
                        eie_client_id:localStorage.getItem('LedgerBalance_query_client'),
                        // eie_arrive_date_s:[
                        //     localStorage.getItem('LedgerBalance_query_arrive_date_s')?localStorage.getItem('LedgerBalance_query_arrive_date_s'):'',
                        //     localStorage.getItem('LedgerBalance_query_arrive_date_e')?localStorage.getItem('LedgerBalance_query_arrive_date_e'):''
                        // ],
                        // ebn_date_s:[
                        //     localStorage.getItem('LedgerBalance_query_date_s')?localStorage.getItem('LedgerBalance_query_date_s'):'',
                        //     localStorage.getItem('LedgerBalance_query_date_e')?localStorage.getItem('LedgerBalance_query_date_e'):''
                        // ],
                        ebn_team_num:localStorage.getItem('LedgerBalance_query_team_num'),
                        eie_dept_id:localStorage.getItem('LedgerBalance_query_dept'),
                    }}
                    labelCol={{ span: 5 }}   
                >
                    <GetClient 
                        label='客户'  
                        wrapperCol={{span:15}} 
                        name='eie_client_id' 
                        style={{width:'290px'}} 
                        getClient={(e) => {}} 
                    />                    
                    <Form.Item 
                        name='ebn_date_s' 
                        label='出发日期'
                    >                                    
                        <RangePicker />
                    </Form.Item>
                    <Form.Item 
                        name='ebn_team_num' 
                        label='团号' 
                        wrapperCol={{span:10}}
                    >                                    
                        <Input />
                    </Form.Item>
                    <GetDept 
                        label='部门' 
                        wrapperCol={{span:10}} 
                        name='eie_dept_id' 
                        getDept={() => {}}
                    />
                </Form>
            </Modal>
        </>
    )
}

export default ArapCreditQuery