import React,{useState,useEffect, useRef} from 'react'
import { Form,Modal, Input,message, } from 'antd'
import { useBusinessNumUpdateQuery, useBusinessNumUpdateSaveMutation,} from '../../store/ReactWebSiteApi';
import GetGoods from '../../finance/goods/GetGoods';
import GetClient from '../../finance/client/GetClient';
import dayjs from 'dayjs';
import GetUser from '../../cpn/GetUser';
import GetUserDept from '../../cpn/GetUserDept';
import GetGroupStartDate from './GetGroupStartDate';
const { TextArea } = Input;
function LedgerGroupEdit(props) {
    const [form] = Form.useForm();
    const onCreate = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [open,setOpen] = useState()
    const [info,setInfo] = useState({ebn_id:'',ebn_dept_id:props.ebn_dept_id});
    const {data,isSuccess} = useBusinessNumUpdateQuery({ebn_id:props.ebn_id},{skip:!open})
    const editForm = useRef();
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                editForm.current.setFieldsValue({
                    ebn_date:dayjs(data.Result.ebn_date),
                    ebn_content:data.Result.ebn_content,
                    ebn_dept_id:data.Result.ebn_dept_id,
                    ebn_client_id:[data.Result.ec_name],
                    ebn_eg_id:[data.Result.ebn_eg_id],
                    eg_egt_id:[data.Result.eg_egt_id],
                    yw:[data.Result.ebn_yw_id]
                });
                setInfo(prevState=>({...prevState,
                    ebn_eg_id:data.Result.ebn_eg_id,
                    ebn_date:data.Result.ebn_date,
                    ebn_content:data.Result.ebn_content,
                    ebn_client_id:data.Result.ebn_client_id,
                    ec_name:data.Result.ec_name,
                    ebn_dept_id:data.Result.ebn_dept_id,
                    ebn_yw_id:data.Result.ebn_yw_id,
                    ebn_yw_name:data.Result.ebn_yw_name,                    
                }))
            }
        }
    },[data,isSuccess])
    const [summitUpdateSave] = useBusinessNumUpdateSaveMutation()
    return (
        <>
            {!props.ebn_audit_ctime && <a onClick={() => {setOpen(true)}}>修改</a>}
            <Modal
                open={open}
                title='修改团队报账单'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        }); 
                        summitUpdateSave({
                            ebn_esv_id:props.ebn_esv_id,
                            ebn_e_id:localStorage.getItem('e_id'),
                            ebn_dept_id:info.ebn_dept_id,
                            ebn_eci_id:props.ebn_eci_id,
                            ebn_eg_id:info.ebn_eg_id,
                            ebn_date:info.ebn_date,
                            ebn_content:info.ebn_content,
                            ebn_update_uid:localStorage.getItem('eu_id'),
                            ebn_update_uname:localStorage.getItem('eu_name'),
                            ebn_client_id:info.ebn_client_id,
                            ebn_id:props.ebn_id,
                            ebn_yw_id:info.ebn_yw_id,
                            ebn_yw_name:info.ebn_yw_name,
                        }).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('修改成功！')                                
                                setOpen(false)                         
                            }
                            if (res.data.Status === 'fail') {
                                message.error('修改失败！'+res.data.Message)
                                
                            }
                        })                           
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                    ref={editForm}
                >               
                   
                    <GetUserDept 
                        label='部门' 
                        getUserDept={(e,f) => {
                            setInfo(prevState=>({...prevState,ebn_dept_id:e}))
                        }}
                        wrapperCol={{span:10}}
                        required
                        name='ebn_dept_id'
                    />
                    <GetGroupStartDate 
                        getGroupStartDate={(e) => {setInfo(prevState=>({...prevState,ebn_date:dayjs(e).format('YYYY-MM-DD')}))}} 
                        ed_id={info.ebn_dept_id}
                        name='ebn_date'
                        disabled={true}
                        label='出发日期'
                        required
                    />                        
                    
                    {info.ec_name && <GetClient 
                        label='团队客户'
                        wrapperCol={{span:20}}
                        getClient={(e,f) => {
                            console.log(e);
                            setInfo(prevState=>({...prevState,ebn_client_id:e}))
                        }} 
                        required
                        name='ebn_client_id'
                        defaultValue={info.ec_name}
                        style={{width:"200px"}}
                        clientAdd='true'
                    />  }  
                    <GetGoods 
                        getGoods={(e,f) => {
                            setInfo(prevState=>({...prevState,ebn_eg_id:e}))
                        }} 
                        label='产品' 
                        eg_esv_id={props.ebn_esv_id}
                        wrapperCol={{span:10}}  
                        eg_dept_id={props.ebn_dept_id}
                        name='ebn_eg_id'
                        required
                        typeName='eg_egt_id'
                    />                              
                    
                    <Form.Item name="ebn_content" label='备注' wrapperCol={{span:15}} required>
                        <TextArea  onChange={(e) => {setInfo(prevState=>({...prevState,ebn_content:e.target.value}))}}/>
                    </Form.Item>
                    <GetUser  wrapperCol={{span:5}}  label='销售员' name='yw' required={false} getUser={(e,f) => {setInfo(prevState=>({...prevState,ebn_yw_id:e,ebn_yw_name:f}))}}/>
                </Form>
            </Modal>
        </>
  )
}

export default LedgerGroupEdit