import React from 'react'
import Main from '../cpn/Main'
import Index from '../cpn/Index';
import { PictureOutlined} from '@ant-design/icons';
function AlbumIndex() {
    const menu = [
        {
            router:'/AlbumGroup',
            icon:<PictureOutlined />,
            name:'团队列表',
            key:'1'
        },      
    ]
    return (
        <Main>
            <Index  esv_id='7' menu={menu}/>           
        </Main>
    )
}

export default AlbumIndex