import React, { useEffect, useRef, useState } from 'react'
import ModalEdit from '../../../../../cpn/ModalEdit'
import { Form, Input,message, Select } from 'antd'
import GetUserDept from '../../../../../cpn/GetUserDept';
import UploadOne from '../../../../../cpn/UploadOne';
import { useDijieTemplateTaiTouUpdateQuery, useDijieTemplateTaiTouUpdateSaveMutation } from '../../../../../store/ReactWebSiteApi';
import { customAllType } from '../../customType';

function PageEdit(props) {
    const [form] = Form.useForm();  
    const editForm = useRef()
    const [info,setInfo] = useState({
        lttt_e_id:localStorage.getItem('e_id'),
        lttt_update_uid:localStorage.getItem('eu_id'),
        lttt_update_uname:localStorage.getItem('eu_name'),
        lttt_id:props.lttt_id,
    });
    const [open,setOpen] = useState()
    //获取
    const {data,isSuccess} = useDijieTemplateTaiTouUpdateQuery({lttt_id:props.lttt_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                const picArr = data.Result.lttt_image.map((item,index) => {
                    return {
                        uid:index,
                        url:item,
                        thumbUrl:item,
                        status: 'done',
                    }
                })
                editForm.current.setFieldsValue({
                    lttt_dept_id:data.Result.lttt_dept_id,
                    pic:picArr,
                    lttt_phone:data.Result.lttt_phone,
                    lttt_name:data.Result.lttt_name,
                })
                setInfo(prevsState=>({...prevsState,
                    lttt_dept_id:data.Result.lttt_dept_id,
                    lttt_phone:data.Result.lttt_phone,
                    lttt_image:data.Result.lttt_image,
                    lttt_name:data.Result.lttt_name,
                }))
            }
        }
    },[data,isSuccess,])
    const [updateSave] = useDijieTemplateTaiTouUpdateSaveMutation()
    return (
        <ModalEdit
            getOpen={(e) => {setOpen(prevsState=>e );}}
            onOk={() => {
                updateSave(info).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('修改成功！') 
                        setOpen(false)                                                 
                    }
                    if (res.data.Status === 'fail') {
                        message.error('修改失败！'+res.data.Message)
                    }
                })
            }}
            open={open}
            title='页眉页脚模板'
        >
            <Form
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 3 }}
                ref={editForm}
            >                                           
                <GetUserDept 
                    label='部门' 
                    required 
                    name='lttt_dept_id'
                    wrapperCol={{span:5}}
                    getUserDept={(e) => {setInfo(prevsState=>({...prevsState,lttt_dept_id:e}))}}
                />
                <Form.Item name="lttt_name" label='用途' wrapperCol={{span:10}} required>
                    <Select 
                        options={customAllType}
                        onChange={(e) => {
                            setInfo(prevsState=>({...prevsState,lttt_name:e}))
                        }}
                    />
                </Form.Item>
                <UploadOne 
                    label='页眉图片' 
                    fileStr={(e) => {setInfo(prevsState=>({...prevsState,lttt_image:e}))}}
                    name='pic'
                />
                <Form.Item name="lttt_phone" label='页脚文字' wrapperCol={{span:20}}>
                    <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,lttt_phone:e.target.value}))}}/>
                </Form.Item>
            </Form>
        </ModalEdit>
    )
}

export default PageEdit