import React  from 'react'
import Main from '../../cpn/Main'
import {Row } from 'antd'
import Folder from '../../cpn/Folder'
function TaFinanceIndex() {
    return (
        <Main t1='财务'>
            <Row>
            {(localStorage.getItem('eu_role')==='2' ||(localStorage.getItem('ess_menu_id').includes('51'))) && <Folder title='团队收支' link='/LedgerIndex'/>}
            {(localStorage.getItem('eu_role')==='2' ||(localStorage.getItem('ess_menu_id').includes('52'))) && <Folder title='费用支出' link='/ExpenseIndex'/>}
            {(localStorage.getItem('eu_role')==='2' ||(localStorage.getItem('ess_menu_id').includes('53'))) && <Folder title='其他收支' link='/ElseIndex'/>}
            {(localStorage.getItem('eu_role')==='2' ||(localStorage.getItem('ess_menu_id').includes('54'))) && <Folder title='下账' link='/ArapIndex'/>}                    
            {(localStorage.getItem('eu_role')==='2' ||(localStorage.getItem('ess_menu_id').includes('55'))) && <Folder title='转账' link='/FundIndex'/>}
            {(localStorage.getItem('eu_role')==='2' ||(localStorage.getItem('ess_menu_id').includes('58'))) && <Folder title='设置' link='/TaFinanceSetIndex'/>}
            
            </Row>         
        </Main>
    )
}

export default TaFinanceIndex