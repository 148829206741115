import React,{useState,useEffect,useRef}from 'react'
import { Modal,message,Form, Input,} from 'antd';
import {useTestUpdateQuery, useTestUpdateSaveMutation } from '../store/ReactWebSiteApi';
const { TextArea } = Input;
function TrainTaskEdit(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({name:''});
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useTestUpdateQuery({ewptr_id:props.ewptr_id},{skip:!open})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);                
                editForm.current.setFieldsValue({                    
                    ewptr_content:dataEdit.Result.ewptr_content,
                })
                setInfo(prevState=>({...prevState,
                    ewptr_content:dataEdit.Result.ewptr_content,       
                    ewptr_user_id:dataEdit.Result.ewptr_user_id,       
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useTestUpdateSaveMutation()
    return (
        <>
            <a onClick={() => {setOpen(true);}}>修改</a>
            <Modal
                open={open}
                title='修改培训任务'
                okText="提交"
                cancelText="取消"
                width={1000}
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateEdit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitUpdateSave({
                        ewptr_id:props.ewptr_id,                               
                        ewptr_content:info.ewptr_content,
                        ewptr_update_uid:localStorage.getItem('eu_id'),
                        ewptr_update_uname:localStorage.getItem('eu_name'),
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')   
                            setOpen(false)                             
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                    ref={editForm}
                >
                    <Form.Item labelCol={{ span: 5 }} label='被培训人' >
                        <div>{info.eu_name}</div>
                    </Form.Item>                            
                    <Form.Item labelCol={{ span: 5 }} label='备注' name='ewptr_content'>
                        <TextArea rows={5} onChange={(e) => {setInfo(prevState=>({...prevState,ewptr_content:e.target.value}))}}/>
                    </Form.Item>                            
                </Form>
            </Modal> 
        </>
    )
}

export default TrainTaskEdit