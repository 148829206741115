import React,{useState,useEffect,useRef } from 'react'
import Main from '../../cpn/Main'
import { useGetStreetQuery} from '../../store/ReactWebSiteApi'
import { Table,  Button, Form, Input, Space,Select} from 'antd';
import { Link,useNavigate } from 'react-router-dom';
import { useGetProvinceQuery } from '../../store/ReactWebSiteApi';
import { useGetCityQuery } from '../../store/ReactWebSiteApi';
import { useGetDistrictQuery } from '../../store/ReactWebSiteApi';

function Street(props) {
  //获取省份数据
  const [province, setProvince] = useState();
  const [selectProvince, setSelectProvince] = useState();
  //获取城市数据
  const [city, setCity] = useState();
  const [selectCity, setSelectCity] = useState();
  //获取区县数据
  const [district, setDistrict] = useState();
  const [selectDistrict, setSelectDistrict] = useState();
  //查询数据
  const [queryData, setQueryData] = useState({
    province_id: '',
    city_id: '',
    district_id:'',
    search_key: '',
    action:'search',
  });
  //API
  const { data: dataProvince, isSuccess: isSuccessProvince } = useGetProvinceQuery();
  const { data: dataCity, isSuccess: isSuccessCity} = useGetCityQuery({ province_id: selectProvince,action:'search' })
  const { data: dataDistrict, isSuccess: isSuccessDistrict} = useGetDistrictQuery({province_id: selectProvince,city_id: selectCity,action:'search' }, {
    refetchOnMountOrArgChange: true,
  });
  //省选择后加载
  useEffect(() => {
    if (isSuccessProvince) {
      console.log(dataProvince);
      const newArr = dataProvince.Result.map((item) => {
        const { ctap_id:value, ctap_name:label } = item
        return item = { value, label }
      })
      setProvince(newArr);
    }
    
  }, [dataProvince, isSuccessProvince,])
  //城市选择后加载
  useEffect(() => {
    if (isSuccessCity) {
      const newArr = dataCity.Result.map((item) => {
        const { ctaci_id:value, ctaci_name:label } = item
        return item = { value, label }
      })
      setCity(newArr);
    }
  }, [dataCity, isSuccessCity, selectProvince])
  //区县选择后加载
  useEffect(() => {
    if (isSuccessDistrict) {
      const newArr = dataDistrict.Result.map((item) => {
        const { ctad_id:value, ctad_name:label } = item
        return item = { value, label }
      })
      setDistrict(newArr);
    }
  }, [dataDistrict, isSuccessDistrict, selectCity])
  

  //设置链接
  const navi = useNavigate();  
  
  //绑定查询省
  const handleChange = (e) => {
    setSelectProvince(e);
    setSelectCity(undefined);
    setQueryData(prevState => ({ ...prevState, province_id: e}));
  }
  //绑定查询城市
  const handleChangeCity = (e) => {    
    setSelectCity(e)
    setQueryData(prevState => ({ ...prevState, city_id: e}));
  }
  //绑定查询区县
  const handleChangeDistrict = (e) => {    
    setSelectDistrict(e)
    setQueryData(prevState => ({ ...prevState, district_id: e}));
  }
  //绑定查询区县名称
  const StreetNameInput = (e) => {
    setQueryData(prevState => ({ ...prevState, search_key: e.target.value }));
  }
  //引入表单
  const queryForm = useRef();
  //点击重置按钮
  const reset = () => {
    queryForm.current.resetFields();
    setQueryData(prevState => ({ ...prevState, search_key: '',province_id:'',city_id:'',district_id:'', }))
    setSelectProvince(undefined);
    setSelectCity(undefined);
    setSelectDistrict(undefined)
  }
  //列表数据
  const [StreetList, setStreetList] = useState();
  //API
  const { data, isSuccess} = useGetStreetQuery(queryData,{refetchOnMountOrArgChange:1});
  useEffect(() => {
    if (isSuccess) {
      if (data.Status === 'success') {
        const newArr = data.Result.map((item) => {
          const { ctas_id: key, ctap_name, ctaci_name,ctas_remark,ctad_name,ctas_name } = item;
          return item = {key, ctap_name, ctaci_name,ctas_remark,ctad_name,ctas_name }
        })
        setStreetList(newArr);
      }
    }
  },[data,isSuccess,queryData])
  //表头
  const columns = [
    {
      title: 'ID',
      dataIndex: 'key',
      width: '3%',
      defaultSortOrder: 'descend',
      sorter:  (a, b) => {
          return a.key - b.key;
        } 
    },        
    {
      title: '省份',
      dataIndex: 'ctap_name',
      width:'15%'
    },
    {
      title: '城市',
      dataIndex: 'ctaci_name',
      width:'15%'
    },
    {
      title: '区县',
      dataIndex: 'ctad_name',
      width:'15%'
    },
    {
      title: '街道',
      dataIndex: 'ctas_name',
      width:'15%'
    },
    {
      title: '备注',
      dataIndex: 'ctas_remark',
      width:'15%'
    },        
    {
      title: '操作',
      width: '15%',
      render: (record) => (
        <Space size="middle">
          <a onClick={() => {
            navi(`/EditStreet/${record.key}`)   
          }}>修改</a>
        </Space>
      )
    }
  ];
    
  //列表分页
  const onChange = (pagination, filters, sorter, extra) => {
      console.log('params', pagination, filters, sorter, extra);
  };
  
  return (
    <Main title1={props.title1} title2={props.title2} title3={props.title3}>
      <div style={{ marginBottom: '20px', display: 'flex' }}>
        <Link to='/AddStreet'><Button type="primary">新增街道</Button></Link>
        <Form
          name='query'
          style={{ marginLeft: '30px' }}
          layout={'inline'}
          ref={queryForm}
        >
          <Form.Item>
            <Select
              style={{ width: 120,}}
              onChange={handleChange}
              options={province}
              value={selectProvince}
              placeholder='请选择省'
            />
            <Select
              style={{ width: 120,}}
              onChange={handleChangeCity}
              options={city}
              value={selectCity}
              placeholder='请选择城市'
            />
            <Select
              style={{ width: 120,}}
              onChange={handleChangeDistrict}
              options={district}
              value={selectDistrict}
              placeholder='请选择区县'
            />
          </Form.Item>
          <Form.Item name='ctas_name' label="街道" >
              <Input placeholder="请输入街道名称" value={queryData.search_key} onChange={StreetNameInput} />
          </Form.Item>
          <Button onClick={reset}>重置</Button>
        </Form>
      </div>
      <Table
          columns={columns}
          dataSource={StreetList}
          onChange={onChange}
          pagination={{ showTotal: total => `共${StreetList.length}条数据`, showSizeChange: true, showQuickJumper: true }} />
    </Main>
  )
}
export default Street