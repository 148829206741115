import React,{useState,useEffect} from 'react'
import Main from '../../cpn/Main'
import { useAddCityMutation } from '../../store/ReactWebSiteApi'
import { message,Button, Form, Input, Row,  Col,Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useGetProvinceQuery } from '../../store/ReactWebSiteApi';
function CityAdd(props) {  
  //获取省数据
  const [province, setProvince] = useState();
  const [selectProvince, setSelectProvince] = useState();
  //API
  const { data: dataProvince, isSuccess: isSuccessProvince } = useGetProvinceQuery();
  useEffect(() => {
    if (isSuccessProvince) {
      console.log(dataProvince);
      const newArr = dataProvince.Result.map((item) => {
        const { ctap_id:value, ctap_name:label } = item
        return item = { value, label }
      })
      setProvince(newArr);
    }
  },[dataProvince,isSuccessProvince])
  //新增数据
  const [city, setCity] = useState({
    ctaci_province_id:'',
    ctaci_name:'',
    ctaci_remark: '',
  });
   //绑定查询省
   const handleChange = (e) => {    
    setCity(prevState => ({ ...prevState, ctaci_province_id: e }))
    setSelectProvince(e);
  }
  //绑定城市名称
  const nameInput = (e) => {
    setCity(prevState =>({...prevState,ctaci_name:e.target.value}))
  }
  //绑定城市备注
  const remarkInput = (e) => {
    setCity(prevState =>({...prevState,ctaci_remark:e.target.value}))
  }
  //链接
  const navigate = useNavigate();
  //上传数据
  const [addCity] = useAddCityMutation();
  //提交对话框表单
  const onFinish = () => {
    addCity(city).then(
      (res) => {
        if (res.data.Status === 'success') {
          message.success('新增城市成功！')
          navigate('/city');                                    
        }
        if (res.data.Status === 'fail') {
          message.error('新增城市失败！'+res.data.Message)
        }
      })
  }
  const onFinishFailed = () => {}
  return (
    <Main title1='系统' title2='地区' title3='城市' title4='新增城市'>
      <Row>
        <Col span={12}>
          <Form
              name="basic"
              labelCol={{
                  span: 4,
              }}
              wrapperCol={{
                  span: 20,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
          >
            <Form.Item
              label="1、选择省份"
              name="province"
              required
            >
              <Select
                style={{ width: 120,}}
                onChange={handleChange}
                options={province}
                value={selectProvince}
                placeholder='请选择省'
              />
            </Form.Item>        
            <Form.Item
                label="2、填写城市名称"
                name="name"
                rules={[{
                    required: true,
                    message: '请填写城市名称！',
                },]}
                wrapperCol={{
                    span: 4,
                }}
            >
                <Input value={city.ctaci_name} onChange={nameInput} />
            </Form.Item>
            <Form.Item
                label="3、填写备注"
                name='remark'              
            >
              <Input value={city.ctaci_remark} onChange={remarkInput} />
            </Form.Item>                    
            <Form.Item
                wrapperCol={{
                    offset: 2,
                    span: 16,
                }}
            >
              <Button type="primary" htmlType="submit">提交</Button>
            </Form.Item>
          </Form>          
        </Col>
      </Row>
    </Main>
  )
}
export default CityAdd