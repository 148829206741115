import React,{useState,useEffect,}from 'react'
import { Table, Space,} from 'antd';
import {useGetDuiZhangQuery, useIncomeExpenditureHxListQuery,} from '../../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import ArapStatementSendAudit from './ArapStatementSendAudit';
import ModalCheck from '../../cpn/ModalCheck';
function ArapStatementSendCheck(props) {
    const [open,setOpen] = useState(false);
    const [isEdit,setIsEdit] = useState(true);
    //获取对账单详情
    const [info,setInfo] = useState({
        edz_s_date:"",
        edz_e_date:"",
        edz_amount:"",
        edz_uname:"",
        edz_send_dept_name:"",
        edz_send_phone:"",
        edz_audit_ctime:"",
        edz_affirm_ctime:"",
        send_e_name:'',
    })
    const {data:dataInfo,isSuccess:isSuccessInfo} = useGetDuiZhangQuery({edz_id:props.edz_id},{skip:!open,refetchOnMountOrArgChange:1})
    useEffect(() => {
        if(isSuccessInfo){
            if(dataInfo.Status==='success'){
                console.log(dataInfo);
                setInfo(prevState=>({
                    ...prevState,
                    edz_s_date:dataInfo.Result.edz_s_date,
                    edz_e_date:dataInfo.Result.edz_e_date,
                    edz_amount:dataInfo.Result.edz_amount,
                    edz_uname:dataInfo.Result.edz_uname,
                    edz_send_dept_name:dataInfo.Result.edz_send_dept_name,
                    edz_send_phone:dataInfo.Result.edz_send_phone,
                    edz_audit_ctime:dataInfo.Result.edz_audit_ctime,
                    edz_affirm_ctime:dataInfo.Result.edz_affirm_ctime,
                    edz_send_e_id:dataInfo.Result.edz_send_e_id,
                    edz_ec_id:dataInfo.Result.edz_ec_id,
                    edz_uid:dataInfo.Result.edz_uid,
                    send_e_name:dataInfo.Result.send_e_name,
                    ec_name:dataInfo.Result.ec_name,
                }))
            }
        }
    },[dataInfo,isSuccessInfo])
    const items = [
        {
            key:'1',
            label:'收件人',
            children:<>{info.ec_name}</>,
        },
        {
            key:'2',
            label:'出发日期',
            children:<>{dayjs(info.ebn_date_s).format('YYYY年MM月DD日')}至{dayjs(info.ebn_date_e).format('YYYY年MM月DD日')}</>,
        },
        {
            key:'3',
            label:<>{info.edz_amount < 0 ? `我公司应付金额`:`贵公司应付金额`}</>,
            children:<>{info.edz_amount}</>,
        },
        {
            key:'4',
            label:'发件人部门',
            children:<>{info.edz_send_dept_name?info.edz_send_dept_name.replace(/"/g,''):''}</>,
        },
        {
            key:'5',
            label:'发件人',
            children:<>{info.edz_uname.replace(/"/g,'')}{info.edz_send_phone}</>,
        },
        {
            key:'6',
            label:'审核状态',
            children: <Space>{info.edz_audit_ctime?`已审核${info.edz_audit_ctime}`:'未审核'} 
                        {isEdit && <ArapStatementSendAudit 
                            edz_id={props.key} 
                            edz_audit_ctime={info.edz_audit_ctime}
                            edz_affirm_ctime={info.edz_affirm_ctime}
                        />}
                    </Space>,
        },
        {
            key:'7',
            label:'对账状态',
            children:<>{info.edz_affirm_ctime?`已确认${info.edz_audit_ctime}`:'未确认'}</>,
        },
        {
            key:'8',
            label:'编号',
            children:<>{props.edz_id}</>,
        },
    ]
    const [list,setList] = useState([]);
    const {data,isSuccess} = useIncomeExpenditureHxListQuery({
        eie_e_id:info.edz_send_e_id,
        action:'search',
        eie_client_id:info.edz_ec_id,
        ebn_date_s:info.edz_s_date,
        ebn_date_e:info.edz_e_date,
        hx_type:2,
        eu_id:info.edz_uid,
        eie_jbr_uid:''
    },{skip:!open,refetchOnMountOrArgChange:1})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                const arr = data.Result.map((item) => {
                    const {eie_id:key,ec_name,eie_ebn_id,eg_name,eie_amount,ebn_team_num,eie_content,ebn_date} = item
                    return item = {key,ec_name,eie_ebn_id,eg_name,eie_amount,ebn_team_num,eie_content,ebn_date}
                })
                setList(arr)
            }
        }
    },[data,isSuccess])
    

    const columnDesc = [
        {
            key: 'id',
            title: '团号',
            dataIndex: 'ebn_team_num'
        },                                  
        {
            key: 'ebn_date',
            title: '出发日期',
            dataIndex: 'ebn_date'
        },                                  
        {
            key: 'eg_name',
            title: '产品',
            dataIndex: 'eg_name',
        },   
        {
            key: 'eie_content',
            title: '备注',
            dataIndex: 'eie_content',
        },  
        {
            key: 'eie_amount',
            title: '金额',
            dataIndex: 'eie_amount',
        },             
            
    ]
    const getOpen = (e) => {console.log(e);setOpen(e)}
    return (        
        <ModalCheck  
            firmName={info.send_e_name}
            isEdit={isEdit}
            title='对账单' 
            width={1050} 
            Descriptions={items} 
            getOpen={getOpen}
            getIsEdit={(e) => {console.log(e);setIsEdit(e)}}
            shareText={`https://i.bnsrj.com/ArapStatementReceiveCover?edz_id=${props.edz_id}&name=${info.edz_uname}&phone=${info.edz_send_phone}`}
        >
            <Table 
                columns={columnDesc} 
                dataSource={list} 
                style={{marginTop:"10px"}} 
                pagination={{
                    defaultPageSize:20,
                    showSizeChanger:true,
                    showTotal:(total) => `共${total}条`,
                }}
            />
        </ModalCheck>
    )
}

export default ArapStatementSendCheck