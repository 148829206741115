import { Form, Modal, Space } from 'antd';
import React, { useState } from 'react'
import Client from './Client';

function ClientForm(props) {
    console.log(props,'11');
    const [open,setOpen] = useState(false)    
    return (
        <Form.Item
            label='组团社'                    
            required                    
        >
            <Space align='center'>
                <div>{props.ltbjd_client_name + ' - ' + props.lth_phone}</div>                     
                <a onClick={() => {setOpen(true)}}>请选择</a>
                <Modal
                    open={open}
                    okText="提交"
                    cancelText="取消"
                    onCancel={() => {setOpen(false);}}
                    onOk={() => {}}
                    footer={null}
                    width={800}
                    title='选择组团社'
                >
                    <Client 
                        type='select'
                        getClient={(e) => {
                            props.getClient({
                                ltbjd_ltkh_id:e.ltkh_id,
                                ltbjd_client_name:e.ltkh_name,
                                ltkh_phone:e.ltkh_phone,
                            })                            
                        }}
                        getOpen={(e) => {setOpen(false)}}
                    />
                </Modal> 
            </Space>
        </Form.Item>   
    )
}

export default ClientForm