import { message, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import GuidePlanAdd from './GuidePlanAdd'
import GuidePlanCheck from './GuidePlanCheck'
import GuidePlanCopy from './GuidePlanCopy'
import { useDaoyoujihuadanDelMutation, useDaoyoujihuadanListQuery } from '../../../../../store/ReactWebSiteApi'
import GuidePlanEdit from './GuidePlanEdit'
import ModalDel from '../../../../../cpn/ModalDel'

function GuidePlan(props) {
    const [list,setList] = useState()
    const {data, isSuccess} = useDaoyoujihuadanListQuery({dyjhd_lth_id:props.dyjhd_lth_id},{refetchOnMountOrArgChange:1});
    useEffect(() => {
        if (isSuccess) {
            console.log(data);
            if(data.Status==='success'){                
                const newArr = data.Result.map(item => {
                    const {dyjhd_id:key,dyjhd_tgu_name,dyjhd_jietuanshuoming,lth_uname,lth_ctime,lth_date,lth_kehu_name,lth_xianlu_name,lth_dept_name,lth_phone,lth_ltkh_id,} = item;                    
                    return item = {key,dyjhd_tgu_name,dyjhd_jietuanshuoming,lth_uname,lth_ctime,lth_date,lth_kehu_name,lth_xianlu_name,lth_dept_name,lth_phone,lth_ltkh_id,}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.lth_ctime;
                    let bTimeString = b.lth_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    //删除
    const [del] = useDaoyoujihuadanDelMutation()
    const column = [             
        {
            key:'1',
            title:'导游计划单',
            dataIndex:'lth_num',  
            width:'200px',
            render:(_,record) => {
                return <GuidePlanCheck 
                            dyjhd_id={record.key}
                            dyjhd_tgu_name={record.dyjhd_tgu_name}
                        />
            }         
        },    
        {
            key:'2',
            title:'分车序号', 
            width:'100px',           
        },          
        {
            key:'4',
            title:'备注',
            dataIndex:'dyjhd_jietuanshuoming'            
        },
        {
            key:'5',
            title:'操作',
            width:"200px",
            render:(_,record) => {
                return  <Space>
                            <GuidePlanEdit
                                dyjhd_id={record.key}
                            />
                            <ModalDel onOk={() => {
                                del({dyjhd_id:record.key}).then((res) => {
                                    console.log(res);
                                    if (res.data.Status === 'success') {
                                        message.success('删除成功！')                                                                              
                                    }
                                    if (res.data.Status === 'fail') {
                                        message.error('删除失败！'+res.data.Message)
                                    }
                                })
                            }} />
                            <GuidePlanCopy
                            />
                        </Space>
            }
        },
    ]
    return (
        <>
            <div style={{marginLeft:'20px',marginTop:'10px'}}>
                <GuidePlanAdd 
                    dyjhd_lth_id={props.dyjhd_lth_id}
                    dyjhd_lth_num={props.dyjhd_lth_num}
                />
            </div>
            <Table 
                columns={column}
                dataSource={list}
                pagination={{position:['none']}}
            />
        </>
    )
}

export default GuidePlan