import React, { useEffect, useRef, useState } from 'react'
import ModalEdit from '../../../../../cpn/ModalEdit'
import { DatePicker, Form,Input,InputNumber,message, } from 'antd'
import dayjs from 'dayjs';
import { useDaoyoujihuadanUpdateQuery, useDaoyoujihuadanUpdateSaveMutation } from '../../../../../store/ReactWebSiteApi';
import GuideForm from '../../../../guide/GuideForm';
import UploadOne from '../../../../../cpn/UploadOne';
const {TextArea} = Input
function GuidePlanEdit(props) {
    const [form] = Form.useForm();  
    const editForm = useRef()
    const [info,setInfo] = useState({
        lth_e_id:localStorage.getItem('e_id'),
        lth_update_uid:localStorage.getItem('eu_id'),
        lth_update_uname:localStorage.getItem('eu_name'),
        lth_id:props.lth_id,
        lth_date:'',
        lth_client_name:'',
    });
    const [open,setOpen] = useState()
    //获取
    const {data,isSuccess} = useDaoyoujihuadanUpdateQuery({dyjhd_id:props.dyjhd_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){               
                editForm.current.setFieldsValue({
                    "dyjhd_daofufei" : data.Result.dyjhd_daofufei,
                    "dyjhd_daofufeishuoming" : data.Result.dyjhd_daofufeishuoming,
                    "dyjhd_fujian_name" : data.Result.dyjhd_fujian_name,
                    "dyjhd_jietuandidian" :data.Result.dyjhd_jietuandidian,
                    "dyjhd_jietuanshijian" :dayjs(data.Result.dyjhd_jietuanshijian),
                    "dyjhd_jietuanshuoming" :data.Result.dyjhd_jietuanshuoming,
                    "dyjhd_lth_id" : data.Result.dyjhd_lth_id,
                    "dyjhd_lth_num" : data.Result.dyjhd_lth_num,
                    "dyjhd_songtuandidian" : data.Result.dyjhd_songtuandidian,
                    "dyjhd_songtuanshijian" :dayjs(data.Result.dyjhd_songtuanshijian),
                    "dyjhd_tgu_id" : data.Result.dyjhd_tgu_id,
                    "dyjhd_tgu_name" : data.Result.dyjhd_tgu_name,           
                })                                   
                setInfo(prevsState=>({...prevsState,
                    "dyjhd_daofufei" : data.Result.dyjhd_daofufei,
                    "dyjhd_daofufeishuoming" : data.Result.dyjhd_daofufeishuoming,
                    "dyjhd_fujian_name" : data.Result.dyjhd_fujian_name,
                    "dyjhd_jietuandidian" :data.Result.dyjhd_jietuandidian,
                    "dyjhd_jietuanshijian" :data.Result.dyjhd_jietuanshijian,
                    "dyjhd_jietuanshuoming" :data.Result.dyjhd_jietuanshuoming,
                    "dyjhd_lth_id" : data.Result.dyjhd_lth_id,
                    "dyjhd_lth_num" : data.Result.dyjhd_lth_num,
                    "dyjhd_songtuandidian" : data.Result.dyjhd_songtuandidian,
                    "dyjhd_songtuanshijian" :data.Result.dyjhd_songtuanshijian,
                    "dyjhd_tgu_id" : data.Result.dyjhd_tgu_id,
                    "dyjhd_tgu_name" : data.Result.dyjhd_tgu_name,       
                }))
            }
        }
    },[data,isSuccess])
    const [updateSave] = useDaoyoujihuadanUpdateSaveMutation()
    
    return (
        <ModalEdit
            getOpen={(e) => {setOpen(prevsState=>e );}}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        //获取产品人群类型
                        let arr = []
                        for (let key in values) {
                            if (values.hasOwnProperty(key)) { // 确保key是对象自身的属性
                                if(key.includes('egg')){
                                    arr.push(values[key])
                                }
                            }
                        } 
                        console.log(values);                       
                        updateSave({
                            "dyjhd_id" : props.dyjhd_id,
                            "dyjhd_daofufei" : info.dyjhd_daofufei,
                            "dyjhd_daofufeishuoming" : info.dyjhd_daofufeishuoming,
                            "dyjhd_fujian_name" : info.dyjhd_fujian_name,
                            "dyjhd_jietuandidian" :info.dyjhd_jietuandidian,
                            "dyjhd_jietuanshijian" :info.dyjhd_jietuanshijian,
                            "dyjhd_jietuanshuoming" :info.dyjhd_jietuanshuoming,
                            "dyjhd_lth_id" : info.dyjhd_lth_id,
                            "dyjhd_lth_num" : info.dyjhd_lth_num,
                            "dyjhd_songtuandidian" : info.dyjhd_songtuandidian,
                            "dyjhd_songtuanshijian" :info.dyjhd_songtuanshijian,
                            "dyjhd_tgu_id" : info.dyjhd_tgu_id,
                            "dyjhd_tgu_name" : info.dyjhd_tgu_name,
                            "dyjhd_update_uid" : localStorage.getItem('eu_id'),
                            "dyjhd_update_uname" :  localStorage.getItem('eu_name'),
                        }).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('修改成功！') 
                                setOpen(false)                                                 
                            }
                            if (res.data.Status === 'fail') {
                                message.error('修改失败！'+res.data.Message)
                            }
                        })
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
                
            }}
            open={open}
            title='导游计划单'
        >
            <Form
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 4 }}
                ref={editForm}
            >
                <Form.Item name="fenche" label='分车序号' wrapperCol={{span:10}} required>
                    <InputNumber
                    />
                </Form.Item>
                <GuideForm 
                    required
                    tgu_name={info.dyjhd_tgu_name}
                    getGuide={(e) => {
                        setInfo(prevState=>({
                            ...prevState,
                            dyjhd_tgu_id:e.tgu_id,
                            dyjhd_tgu_name:e.tgu_name
                        }))
                    }}
                />
                <Form.Item 
                    label='接团时间' 
                    name="dyjhd_jietuanshijian" 
                    wrapperCol={{span:15}} 
                >
                    <DatePicker
                        showTime
                        format="YYYY-MM-DD HH:mm"
                        onChange={(e) => {
                            console.log(e);
                            setInfo(prevState=>({...prevState,dyjhd_jietuanshijian:dayjs(e).format('YYYY-MM-DD HH:mm')}))
                        }}  
                    />
                </Form.Item>
                <Form.Item 
                    label='接团地点' 
                    name="dyjhd_songtuandidian" 
                    wrapperCol={{span:15}} 
                >
                        <Input
                            onChange={(e) => {
                            setInfo(prevState=>({...prevState,dyjhd_songtuandidian:e.target.value}))
                        }}  
                    />
                </Form.Item>
                <Form.Item 
                    label='送团时间' 
                    name="dyjhd_songtuanshijian" 
                    wrapperCol={{span:15}} 
                >
                        <DatePicker
                        showTime
                        format="YYYY-MM-DD HH:mm"
                        onChange={(e) => {
                            console.log(e);
                            setInfo(prevState=>({...prevState,dyjhd_songtuanshijian:dayjs(e).format('YYYY-MM-DD HH:mm')}))
                        }}  
                    />
                </Form.Item>
                <Form.Item 
                    label='送团地点' 
                    name="dyjhd_jietuandidian" 
                    wrapperCol={{span:15}} 
                >
                    <Input
                            onChange={(e) => {
                            setInfo(prevState=>({...prevState,dyjhd_jietuandidian:e.target.value}))
                        }}  
                    />
                </Form.Item>
                <Form.Item 
                    label='接团说明' 
                    name="dyjhd_jietuanshuoming" 
                    wrapperCol={{span:15}}                         
                >
                    <TextArea 
                        autoSize={{
                            minRows: 4,                                
                        }}
                        onChange={(e) => {
                            setInfo(prevState=>({...prevState,dyjhd_jietuanshuoming:e.target.value}))
                        }}   
                    />
                </Form.Item>
                <Form.Item 
                    label='导服费' 
                    name="dyjhd_daofufei" 
                    wrapperCol={{span:15}} 
                >
                    <InputNumber     
                        onChange={(e) => {
                        setInfo(prevState=>({...prevState,dyjhd_daofufei:e}))
                    }}                          
                    />
                </Form.Item>
                <Form.Item 
                    label='导服费说明' 
                    name="dyjhd_daofufeishuoming" 
                    wrapperCol={{span:15}} 
                >
                    <Input
                        onChange={(e) => {
                            setInfo(prevState=>({...prevState,dyjhd_daofufeishuoming:e.target.value}))
                        }}                       
                    />
                </Form.Item>
                <UploadOne 
                        name='pic'
                        label='附件' 
                        fileStr={(e) => {setInfo(prevsState=>({...prevsState,dyjhd_fujian_name:e}))}}
                    />
            </Form>
        </ModalEdit>
    )
}

export default GuidePlanEdit