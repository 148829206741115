import React,{useState,useEffect,useRef}from 'react'
import { Table,Button,Space,Modal,message,Form,Input, Upload,Image} from 'antd';
import { UploadOutlined} from '@ant-design/icons';
import ImagePreview from '../../cpn/ImagePreview'
import { usePropertyAddMutation, usePropertyDelMutation, usePropertyListQuery, usePropertyUpdateQuery, usePropertyUpdateSaveMutation,} from '../../store/ReactWebSiteFinanceApi';
import { useNavigate } from 'react-router-dom';
import MainKeeper from '../MainKeeper';
function Supplies() {
    //新增物品-----------------------------------------------------------------------------------------------------------------------------新增物品
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateAdd = (values) => {
        console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [infoAdd,setInfoAdd] = useState({
        ep_e_id:localStorage.getItem('e_id'),
        ep_uname:localStorage.getItem('eu_name'),
        ep_uid:localStorage.getItem('eu_id'),
        ep_content:'',
    });
    //上传图片
    const handleChange = (e) => {
        if (e.file.status === 'done'||e.file.status==='removed') {
            const fileArr = e.fileList.map((item) => {
                const { response } = item
                return response.Result
            })
            console.log(e);
            let fileStr = fileArr + "";  
            if(fileStr === null){
                fileStr = ''
            }
            // const nameArr = e.fileList.map((item) => {
            //     const { name} = item
            //     return name
            // })
            // let nameStr = nameArr + ""
            setInfoAdd(prevState=>({...prevState,ep_image_name:fileStr}))
        }
    }
    const [warehouseAdd] = usePropertyAddMutation();
    const modalAdd= <Modal
                        open={openAdd}
                        title='新增物品'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateAdd(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            warehouseAdd(infoAdd).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpenAdd(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                        >
                            
                            <Form.Item name="name" label='物品名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ep_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="content" label='备注' wrapperCol={{span:15}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ep_content:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item label="图片"                         
                                valuePropName='fileList'
                                getValueFromEvent={e => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e && e.fileList;
                                }}
                            >
                                <Upload
                                    name="image_file"
                                    action="/api/ReactApi/zd_single_upload_image"
                                    listType="picture"
                                    className="upload-list-inline"
                                    onChange={handleChange}
                                    >
                                    <Button icon={<UploadOutlined />}>点击上传</Button>
                                </Upload>
                            </Form.Item>
                        </Form>
                    </Modal> 
    //修改物品-----------------------------------------------------------------------------------------------------------------------------修改物品
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    const [infoEdit,setInfoEdit] = useState();
    const editForm = useRef()
    //默认图片
    const [defaultPic,setDefaultPic] = useState();
    //上传图片
    const handleEdit = (e) => {
        if (e.file.status === 'done'||e.file.status==='removed') {
            const fileArr = e.fileList.map((item) => {
                const { response } = item
                return response.Result
            })
            console.log(e);
            let fileStr = fileArr + "";  
            if(fileStr === null){
                fileStr = ''
            }
            // const nameArr = e.fileList.map((item) => {
            //     const { name} = item
            //     return name
            // })
            // let nameStr = nameArr + ""
            setInfoEdit(prevState=>({...prevState,ep_image_name:fileStr}))
        }
    }
    const {data:dataEdit,isSuccess:isSuccessEdit} = usePropertyUpdateQuery({ep_id:editId},{skip:!editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                //默认图片
                if (dataEdit.Result.ep_image_name) {
                    let options = [];
                    let size = dataEdit.Result.ep_image_name.length;
                    for (let i = 0; i < size; i++) {
                        let a = {};
                        a.uid = i;
                        a.url = `/api/assets/images/application/${dataEdit.Result.ep_image_name[i]}`
                        a.response = {Result:dataEdit.Result.ep_image_name[i]}
                        options.push(a);
                    }
                    //console.log(options);
                    setDefaultPic(<Upload
                        name="image_file"
                        action="/api/ReactApi/zd_single_upload_image"
                        listType="picture"
                        defaultFileList={options}
                        className="upload-list-inline"
                        onChange={handleEdit}
                        >
                        <Button icon={<UploadOutlined />}>点击上传</Button>
                    </Upload>)
                }else{
                    setDefaultPic(<Upload
                        name="image_file"
                        action="/api/ReactApi/zd_single_upload_image"
                        listType="picture"
                        className="upload-list-inline"
                        onChange={handleEdit}
                        >
                        <Button icon={<UploadOutlined />}>点击上传</Button>
                    </Upload>)
                }
                editForm.current.setFieldsValue({                    
                    ep_name:dataEdit.Result.ep_name,
                    ep_content:dataEdit.Result.ep_content,
                })
                setInfoEdit(prevState=>({...prevState,
                    ep_name:dataEdit.Result.ep_name,
                    ep_content:dataEdit.Result.ep_content,   
                    ep_image_name:dataEdit.Result.ep_image_name,                

                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [warehouseUpdateSave] = usePropertyUpdateSaveMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改物品'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            warehouseUpdateSave({
                                ep_name:infoEdit.ep_name,
                                ep_update_uid:localStorage.getItem('eu_id'),
                                ep_update_uname:localStorage.getItem('eu_name'),
                                ep_content:infoEdit.ep_content,
                                ep_id:editId,
                                ep_e_id:localStorage.getItem('e_id'),
                                ep_image_name:infoEdit.ep_image_name,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >
                           
                            <Form.Item name="ep_name" label='物品名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ep_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="ep_content" label='物品备注' wrapperCol={{span:15}}>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ep_content:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item label="图片"                             
                                valuePropName='fileList'
                                getValueFromEvent={e => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e && e.fileList;
                                }}
                                >
                                {defaultPic}
                            </Form.Item>
                        </Form>
                    </Modal>
    //删除物品-----------------------------------------------------------------------------------------------------------------------------删除物品
    const {confirm} = Modal; 
    const [warehouseDel] = usePropertyDelMutation()
    //物品列表-----------------------------------------------------------------------------------------------------------------------------物品列表
    
    const {data,isSuccess} = usePropertyListQuery({ep_e_id:localStorage.getItem('e_id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {ep_id:key,ep_name,ep_num,ep_content,ep_uname,ep_ctime,ep_image_name,ep_update_ctime,ep_update_uname,kc_num,log_num,wp_amount} = item;
                    return item = {key,ep_name,ep_num,ep_content,ep_uname,ep_ctime,ep_image_name,ep_update_ctime,ep_update_uname,kc_num,log_num,wp_amount}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ep_ctime;
                    let bTimeString = b.ep_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])    
    const navi = useNavigate()
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },       
        {
            key: 'pic',
            title: '图片',
            dataIndex: 'ep_image_name',
            render: (text) => {
                let newArr
                if (text) {
                    newArr = text.map((item,index) => {
                        return <Image src={`/api/assets/images/application/${item}`} key={index}  />
                    })
                    return <ImagePreview
                        firstPic={`/api/assets/images/application/${text[0]}`}
                        pics={newArr}
                    />
                }
            }
        },
        {
            key: 'room',
            title: '物品名称',
            dataIndex: 'ep_name',
        },
        {
            key: 'kc_num',
            title: '库存数',
            dataIndex: 'kc_num',
        },
        {
            key: 'wp_amount',
            title: '合计金额',
            dataIndex: 'wp_amount',
        },
        {
            key: 'log_num',
            title: '出入库次数',
            dataIndex: 'log_num',
        },
        {
            key: 'num',
            title: '备注',
            dataIndex: 'ep_content',
        },        
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.ep_ctime + record.ep_uname}</div>
                    <div>修改时间：{record.ep_update_ctime + record.ep_update_uname}</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>                        
                        <a onClick={() => {
                            setEditId(record.key);
                            setOpenEdit(true)
                        }}>修改</a>
                        {record.log_num ==='0' && <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    warehouseDel({ep_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>}
                        <a onClick={() => {navi(`/InAndOut?id=${record.key}&name=${record.ep_name}`, { state: {openKeys:'asset',active:"Supplies"}})}}>出入库</a>
                        <a onClick={() => {navi('/Inventory', { state: {openKeys:'asset',active:"Supplies"}})}}>盘存</a>
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <MainKeeper t1='物品管理' t2='物品管理'  topActive='Supplies'>
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增物品</Button>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalAdd}
            {modalEdit}            
        </MainKeeper>
    )
}

export default Supplies