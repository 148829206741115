import React,{useState,useEffect,useRef}from 'react'
import { Table,Space,Modal,message,Form,Input,Button,InputNumber} from 'antd';
import Main from '../../cpn/Main'
import { useSearchParams } from 'react-router-dom';
import GetStaff from '../../cpn/GetUser';
import { useIncomeExpenditureAddMutation, useIncomeExpenditureDelMutation, useIncomeExpenditureListQuery, useIncomeExpenditureUpdateQuery, useIncomeExpenditureUpdateSaveMutation } from '../../store/ReactWebSiteApi';
const { TextArea } = Input;

function NetTechContractSerialCost() {
    const [param] = useSearchParams();
    const [form] = Form.useForm();    
    //新增成本支出-----------------------------------------------------------------------------------------------------------------------------新增成本支出
    const [openAdd, setOpenAdd] = useState(false);
    
    //获取员工
    const getStaff = (e,f,g) => {
        // console.log(e,f,g);
        setInfoAdd(prevState=>({...prevState,eie_jbr_uid:e,ef_responsible_uname:f,}))
    }  
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [infoAdd,setInfoAdd] = useState({eie_price:'',eie_count:''});     
    const [submitAdd] = useIncomeExpenditureAddMutation()
    const modalAdd= <Modal
                        open={openAdd}
                        title='新增成本支出'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        width={1000}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClient(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitAdd({
                                eie_esv_id:'6',
                                eie_e_id:localStorage.getItem('e_id'),
                                eie_dept_id:localStorage.getItem('ed_id'),
                                eie_client_id:param.get('clientId'),
                                eie_eci_id:param.get('id'),
                                eie_eg_id:param.get('goodsId'),
                                eie_ebn_id:param.get('serial'),
                                eie_price:infoAdd.eie_price,
                                eie_count:infoAdd.eie_count,
                                eie_amount:infoAdd.eie_price*infoAdd.eie_count*-1,
                                eie_jbr_uid:infoAdd.eie_jbr_uid,
                                eie_content:infoAdd.eie_content,
                                eie_uid:localStorage.getItem('eu_id'),
                                eie_uname:localStorage.getItem('eu_name'),
                                eie_type_id:'2',
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpenAdd(false)                      
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 3 }}
                        >
                            <Form.Item label='客户'>{param.get('clientName')}</Form.Item>
                            <Form.Item label='合同'>{param.get('name')}</Form.Item>                              
                            <Form.Item label='业务流水号'>{param.get('serial')}</Form.Item>                              
                            <Form.Item>
                                <Space>
                                    <Form.Item name="price" label='单价' wrapperCol={{span:5}} required>
                                        <InputNumber 
                                            min={0.01}
                                            onChange={(e) => {                            
                                                setInfoAdd(prevState=>({...prevState,eie_price:e}))                           
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item name="count" label='数量' wrapperCol={{span:5}} required>
                                        <InputNumber 
                                            min={1}
                                            onChange={(e) => {                            
                                                setInfoAdd(prevState=>({...prevState,eie_count:e}))                           
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item label='金额' wrapperCol={{span:5}} required>
                                        <InputNumber 
                                            min={0.01}
                                            value={infoAdd.eie_price*infoAdd.eie_count}
                                            readOnly
                                        />
                                    </Form.Item>
                                </Space>
                            </Form.Item>                            
                            <Form.Item name="remark" label='备注' wrapperCol={{span:15}} required>
                                <TextArea rows={4}  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eie_content:e.target.value}))}}/>
                            </Form.Item>
                            <GetStaff label='经手人'  getStaff={getStaff}/>
                        </Form>
                    </Modal> 
    //修改成本支出-----------------------------------------------------------------------------------------------------------------------------修改成本支出
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenEdit(false);
    };    

    const [infoEdit,setInfoEdit] = useState({name:''});
    const editForm = useRef()
    //获取员工
    const getStaffEdit = (e,f,g) => {
        // console.log(e,f,g);
        setInfoEdit(prevState=>({...prevState,eie_jbr_uid:e,ef_responsible_uname:f,}))
    } 
    const {data:dataEdit,isSuccess:isSuccessEdit} = useIncomeExpenditureUpdateQuery({eie_id:editId},{skip:!editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);                
                editForm.current.setFieldsValue({
                    eie_price:dataEdit.Result.eie_price,
                    eie_count:dataEdit.Result.eie_count,
                    eie_content:dataEdit.Result.eie_content,
                })
                setInfoEdit(prevState=>({...prevState,
                    eie_price:dataEdit.Result.eie_price,
                    eie_count:dataEdit.Result.eie_count,
                    eie_content:dataEdit.Result.eie_content,        
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useIncomeExpenditureUpdateSaveMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改成本支出'
                        okText="提交"
                        cancelText="取消"
                        width={1000}
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitUpdateSave({
                                eie_esv_id:'6',
                                eie_e_id:localStorage.getItem('e_id'),
                                eie_dept_id:localStorage.getItem('ed_id'),
                                eie_client_id:param.get('clientId'),
                                eie_eci_id:param.get('id'),
                                eie_eg_id:param.get('goodsId'),
                                eie_ebn_id:param.get('serial'),
                                eie_price:infoEdit.eie_price,
                                eie_count:infoEdit.eie_count,
                                eie_amount:infoEdit.eie_price*infoEdit.eie_count*-1,
                                eie_jbr_uid:infoEdit.eie_jbr_uid,
                                eie_content:infoEdit.eie_content,
                                eie_update_uid:localStorage.getItem('eu_id'),
                                eie_update_uname:localStorage.getItem('eu_name'),
                                eie_type_id:'2',
                                eie_id:editId,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >
                            <Form.Item label='客户'>{param.get('clientName')}</Form.Item>
                            <Form.Item label='合同'>{param.get('name')}</Form.Item>                              
                            <Form.Item label='业务流水号'>{param.get('serial')}</Form.Item>                              
                            <Form.Item>
                                <Space>
                                    <Form.Item name="eie_price" label='单价' wrapperCol={{span:5}} required>
                                        <InputNumber 
                                            min={0.01}
                                            onChange={(e) => {                            
                                                setInfoEdit(prevState=>({...prevState,eie_price:e}))                           
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item name="eie_count" label='数量' wrapperCol={{span:5}} required>
                                        <InputNumber 
                                            min={1}
                                            onChange={(e) => {                            
                                                setInfoEdit(prevState=>({...prevState,eie_count:e}))                           
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item label='金额' wrapperCol={{span:5}} required>
                                        <InputNumber 
                                            min={0.01}
                                            value={infoEdit.eie_price*infoEdit.eie_count}
                                            readOnly
                                        />
                                    </Form.Item>
                                </Space>
                            </Form.Item>                            
                            <Form.Item name="eie_content" label='备注' wrapperCol={{span:15}} required>
                                <TextArea rows={4}  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eie_content:e.target.value}))}}/>
                            </Form.Item>
                            <GetStaff label='经手人'  getStaff={getStaffEdit} defaultId={infoEdit.eu_name}/>                            
                        </Form>
                    </Modal>
    //删除成本支出-----------------------------------------------------------------------------------------------------------------------------删除成本支出
    const {confirm} = Modal; 
    const [submitDel] = useIncomeExpenditureDelMutation()
    //成本支出列表-----------------------------------------------------------------------------------------------------------------------------成本支出列表
    
    const {data,isSuccess} = useIncomeExpenditureListQuery({
        eie_e_id:localStorage.getItem('e_id'),
        eie_esv_id:'6',
        action:'search',
        eie_type_id:'2',
        eie_eci_id:param.get('id'),
        eie_ebn_id:param.get('serial'),
    })
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {eie_id:key,eie_amount,ec_name,eie_ebn_id,eie_content,eie_uname,eie_ctime,eie_update_ctime,eie_update_uname,eie_price,eie_image_file,eie_count,eci_name,eg_name,eu_name} = item;
                    return item = {key,eie_amount,ec_name,eie_ebn_id,eie_content,eie_uname,eie_ctime,eie_update_ctime,eie_update_uname,eie_price,eie_image_file,eie_count,eci_name,eg_name,eu_name}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eie_ctime;
                    let bTimeString = b.eie_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },         
        {
            key: 'client',
            title: '客户',
            dataIndex: 'ec_name',
        },            
        {
            key: 'contract',
            title: '合同',
            dataIndex: 'eci_name',
        },  
        {
            key: 'eie_ebn_id',
            title: '流水号',
            dataIndex: 'eie_ebn_id',
        },            
        {
            key: 'goods',
            title: '产品',
            dataIndex: 'eg_name',
        },            
                  
        {
            key: 'eie_price',
            title: '单价',
            dataIndex: 'eie_price',
        },            
        {
            key: 'eie_count',
            title: '数量',
            dataIndex: 'eie_count',
        },            
        {
            key: 'eie_amount',
            title: '金额',
            dataIndex: 'eie_amount',
        },            
        {
            key: 'eie_content',
            title: '备注',
            dataIndex: 'eie_content',
        },            
        {
            key: 'eu_name',
            title: '经办人',
            dataIndex: 'eu_name',
        },            
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.eie_ctime + record.eie_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>    
                        <a onClick={() => {
                            setEditId(record.key);
                            setInfoEdit(prevState=>({...prevState,eu_name:record.eu_name}))
                            setOpenEdit(true)
                        }}>修改</a>
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({eie_id:record.key}).then((res) => {
                                        console.log(res);
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                    console.log('Cancel');
                                },
                            });
                        }}>删除</a>
                        <a onClick={() => {navigator(`/NetTechContractSerialIncome?id=${param.get('id')}&name=${param.get('name')}&clientName=${param.get('clientName')}&serial=${record.key}`)}}>成本支出</a>
                        <a>成本支出</a>
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main  topActive='NetTechContract' app='netTech' t1='合同管理' t2={param.get('clientName')+ '-' + param.get('name')} t3={'业务流水号' + param.get('serial')} t4='成本支出'>
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增成本支出</Button>
            </div>   
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalAdd} 
            {modalEdit} 
        </Main>
    )
}

export default NetTechContractSerialCost