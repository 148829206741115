import React,{useState,useEffect,} from 'react'
import { Table,Space,Tag, Checkbox,} from 'antd'
import { useBusinessNumListQuery, } from '../../store/ReactWebSiteApi';
import { useSelector } from 'react-redux';
import Main from '../../cpn/Main';
import LedgerGroupAdd from './LedgerGroupAdd';
import LedgerGroupEdit from './LedgerGroupEdit';
import LedgerGroupDel from './LedgerGroupDel';
import LedgerGroupQuery from './LedgerGroupQuery';
import LedgerGroupCheck from './LedgerGroupCheck';
import {FileTextOutlined} from '@ant-design/icons';

function LedgerGroup() {
    let cur_dept_id = useSelector(state=>state.dept)
    //查询======================================================================================================================================//查询=
    const [query,setQuery] = useState({
        ebn_e_id:localStorage.getItem('e_id'),
        eu_id:localStorage.getItem('eu_id'),
        ebn_ctime:localStorage.getItem('ebn_query_dateNew')?localStorage.getItem('ebn_query_dateNew'):'',
        action:localStorage.getItem('ebn_query_action')?localStorage.getItem('ebn_query_action'):'',
        ebn_eci_id:'',
        ebn_dept_id:localStorage.getItem('ebn_query_dept')?localStorage.getItem('ebn_query_dept'):'',  
        ebn_client_id:localStorage.getItem('ebn_query_client')?localStorage.getItem('ebn_query_client'):'',
        ebn_uid:localStorage.getItem('ebn_query_uid')?localStorage.getItem('ebn_query_uid'):'',
        ebn_date_s:localStorage.getItem('ebn_query_date_s')?localStorage.getItem('ebn_query_date_s'):'',
        ebn_date_e:localStorage.getItem('ebn_query_date_e')?localStorage.getItem('ebn_query_date_e'):'',
        ebn_content:localStorage.getItem('ebn_query_ec_content')?localStorage.getItem('ebn_query_ec_content'):'',   
        ebn_team_num:localStorage.getItem('ebn_query_team_num')?localStorage.getItem('ebn_query_team_num'):'',
        ebn_yw_id:localStorage.getItem('ebn_query_yw_id')?localStorage.getItem('ebn_query_yw_id'):'',
    }); 
    //台账列表=============================================================================================================================台账列表//
    const {data,isSuccess} = useBusinessNumListQuery(query,{refetchOnMountOrArgChange:1,})
    
    const [list, setList] = useState();   
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {ebn_id:key,guige,ebn_client_id,ebn_yw_name,ebn_team_num,ebn_dept_id,photo_album_id,expenditure_num,income_num,ed_name,profit,ebn_name,ebn_content,ebn_uname,ebn_ctime,ebn_update_ctime,ebn_update_uname,ec_name,ebn_image_file,ebn_date,eg_name,ebn_eg_id,ebn_audit2_ctime,ebn_audit2_uname,ebn_audit_ctime,ebn_audit_uname} = item;
                    return item = {key,guige,ebn_client_id,ebn_yw_name,ebn_team_num,ebn_dept_id,photo_album_id,expenditure_num,income_num,ed_name,profit,ebn_name,ebn_content,ebn_uname,ebn_ctime,ebn_update_ctime,ebn_update_uname,ec_name,ebn_image_file,ebn_date,eg_name,ebn_eg_id,ebn_audit2_ctime,ebn_audit2_uname,ebn_audit_ctime,ebn_audit_uname}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ebn_team_num;
                    let bTimeString = b.ebn_team_num;
                    return bTimeString - aTimeString;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }            
        }
    }, [data, isSuccess,cur_dept_id])  

    const columns = [
        {
            key: '1',
            title: '团队报账单',
            dataIndex: 'ebn_team_num', 
            sorter: {
                compare: (a, b) => a.ebn_team_num - b.ebn_team_num,
            },
            width:'7%',
            render:(_,record) => {
                return  <LedgerGroupCheck 
                            text={<Space><FileTextOutlined/>BZD{record.ebn_team_num}</Space>} 
                            ebn_id={record.key} 
                            ebn_dept_id={record.ebn_dept_id}
                            ebn_team_num={record.ebn_team_num}
                        />
            }          
        }, 
        {
            key: '2',
            title: '出发日期',
            dataIndex: 'ebn_date',
            width:'6%'  
        }, 
        {
            key: '3',
            title: '部门',
            dataIndex: 'ed_name', 
            width:'6%'            
        },  
        {
            key: '4',
            title: '销售员',
            dataIndex: 'ebn_yw_name',
            width:'6%'  
        },  
        {
            key: '5',
            title: '客户',
            dataIndex: 'ec_name',
            width:'6%'  
        },         
        {
            key: '6',
            title: '产品',
            dataIndex: 'eg_name',
            width:'10%',
            hidden:'true',  
            render:(text,record) => {
                let arr = []
                if(record.guige){
                    arr = record.guige.map((item) => {
                        return <span key={item.egg_id}>{item.egg_name}{item.guigeNum}</span>
                    })
                }
                return <>
                    <div>{text}</div>
                    <Space>{arr}</Space>
                </>
            }
        },     
        {
            key: '7',
            title: '备注',
            dataIndex:"ebn_content",
            render:(text) => {
                return <div style={{maxWidth:"260px",}}>{text}</div>
            }
            
        }, 
        {
            key: '8',
            title: '收入',
            dataIndex: 'income_num',
            width:'4%'  
        }, 
        {
            key: '9',
            title: '支出',
            dataIndex: 'expenditure_num',
            width:'4%'  
        }, 
        {
            key: '10',
            title: '利润',
            render:(_,record) => {
                return <>{record.profit.toFixed(2)}</>
            },
            width:'4%'  
        },     
        {
            key: '11',
            title: '操作员',
            render:(_,record) => {
                return<>{record.ebn_ctime + ' ' + record.ebn_uname.replace(/"/g,'')}</>
            },
            width:'8%'  
        },            
        // {
        //     key: 'id',
        //     title: '编号',
        //     dataIndex: 'key',           
        // }, 
              
        {
            key: '12',
            title: '审核',
            render:(_,record) => {
                return <>
                {record.ebn_audit_ctime && <div><Tag color='volcano'>{record.ebn_audit_uname}审核</Tag></div>}
                {record.ebn_audit2_ctime && <div style={{marginTop:"10px"}}><Tag color='green'>{record.ebn_audit2_uname}审核</Tag></div>}
                </>
            },
            width:'8%'  
        },             
        {
            key: '13',
            width:'7%',  
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>
                        <Space>
                            {/* <LedgerGroupCheck 
                                text='查看' 
                                ebn_id={record.key} 
                                ebn_dept_id={record.ebn_dept_id}
                                ebn_team_num={record.ebn_team_num}
                            /> */}
                            {/* <a onClick={() => {navi(`/LedgerGroupCheck?esv_id=${param.get('esv_id')}&id=${record.key}&ebn_team_num=${record.ebn_team_num}&ebn_dept_id=${record.ebn_dept_id}&ledgerName=${param.get('ledgerName')}&ebn_client_id=${record.ebn_client_id}&ec_name=${record.ec_name}`)}}>查看</a> */}
                            <LedgerGroupEdit 
                                ebn_audit_ctime={record.ebn_audit_ctime} 
                                ebn_id={record.key}  
                                ebn_dept_id={record.ebn_dept_id} 
                            />
                            <LedgerGroupDel 
                                ebn_id={record.key}
                                ebn_audit_ctime={record.ebn_audit_ctime} 
                            />
                            {/* <LedgerGroupAudit 
                                ebn_audit2_ctime={record.ebn_audit2_ctime}
                                ebn_audit_ctime={record.ebn_audit_ctime} 
                                ebn_id={record.key}
                            /> */}
                            {/* {!record.ebn_audit2_ctime && (record.ebn_audit_ctime ? <>                                
                                <a 
                                    onClick={() => {
                                    submitAuditCancle({
                                        ebn_id:'14',
                                        rankNum:'1',
                                    }).then((res) => {
                                        //console.log(res);
                                        if (res.data.Status === 'success') {
                                            message.success('取消审核成功！')  
                                        }
                                        if (res.data.Status === 'fail') {
                                            message.error('取消审核失败！'+res.data.Message)
                                        }
                                    })
                                }}>取消审核</a></>:<a  
                                    onClick={() => {
                                        submitAudit({
                                            ebn_id:'14',
                                            ebn_audit_uid:localStorage.getItem('eu_id'),
                                            ebn_audit_uname:localStorage.getItem('eu_name'),
                                            rankNum:'1',
                                        }).then((res) => {
                                            //console.log(res);
                                            if (res.data.Status === 'success') {
                                                message.success('审核成功！') 
                                            }
                                            if (res.data.Status === 'fail') {
                                                message.error('审核失败！'+res.data.Message)
                                            }
                                        })
                                    }}>审核</a>)}
                                    <> {record.ebn_audit_ctime && (record.ebn_audit2_ctime ? <>
                                        <a 
                                        onClick={() => {
                                        submitAuditCancle({
                                            ebn_id:'14',
                                            rankNum:'2',
                                        }).then((res) => {
                                            //console.log(res);
                                            if (res.data.Status === 'success') {
                                                message.success('取消复核成功！')  
                                            }
                                            if (res.data.Status === 'fail') {
                                                message.error('取消复核失败！'+res.data.Message)
                                            }
                                        })
                                    }}>取消复核</a></>:<a  
                                    onClick={() => {
                                        submitAudit({
                                            ebn_id:'14',
                                            ebn_audit_uid:localStorage.getItem('eu_id'),
                                            ebn_audit_uname:localStorage.getItem('eu_name'),
                                            rankNum:'2',
                                        }).then((res) => {
                                            //console.log(res);
                                            if (res.data.Status === 'success') {
                                                message.success('复核成功！')  
                                            }
                                            if (res.data.Status === 'fail') {
                                                message.error('复核失败！'+res.data.Message)
                                            }
                                        })
                                    }}>复核</a>)}</> */}
                        </Space>
                        
                    </Space>
                </>
            }
        },
    ]
    //当前页码
    const [current, setCurrent] = useState(localStorage.getItem('ebn_query_page')?localStorage.getItem('ebn_query_page'):'1');
    //当前每页数量
    const [pageSize,setPageSize] = useState(localStorage.getItem('ebn_query_page_size')?localStorage.getItem('ebn_query_page_size'):"20")
    //列显示   
    const defaultCheckedList = columns.map((item) => item.key);
    const [checkedList, setCheckedList] = useState(localStorage.getItem('groupListColumn')?localStorage.getItem('groupListColumn').split(','):defaultCheckedList);
    const options = columns.map(({ key, title }) => ({
        label: title,
        value: key,
    }));
    const newColumns = columns.map((item) =>{
        if(localStorage.getItem('groupListColumn')){
            return {...item,hidden:!localStorage.getItem('groupListColumn').includes(item.key)}
        }else{
            return {...item,hidden: !checkedList.includes(item.key),}
        }
    });
    const getQuery = (values) => {
        console.log(values);
        setQuery(prevState=>({
            ...prevState,            
            action:values.action,
            ebn_dept_id:values.ebn_dept_id?values.ebn_dept_id:'',
            ebn_client_id:values.ebn_client_id?values.ebn_client_id:'',
            ebn_uid:values.ebn_uid?values.ebn_uid:'',
            ebn_team_num:values.ebn_team_num?values.ebn_team_num:'',
            ebn_date_s:values.ebn_date_s?values.ebn_date_s:'',
            ebn_date_e:values.ebn_date_s?values.ebn_date_e:'',
            ebn_ctime:values.ebn_ctime?values.ebn_ctime:'',
            ebn_content:values.ebn_content?values.ebn_content:'',
            ebn_yw_id:values.ebn_yw_id?values.ebn_yw_id:'',
        }))
    }
    return (
        <Main  t1='财务' n1='/TaFinanceIndex' t2='团队收支' n2='/LedgerIndex' t3='团队报账单'>
            <Space style={{marginBottom:"10px"}} wrap>
                <LedgerGroupAdd />
                <LedgerGroupQuery  getQuery={getQuery} />
            </Space>
            <Table 
                columns={newColumns} 
                dataSource={list} 
                pagination={{
                    showSizeChanger:true,
                    showTotal:(total) => `共${total}条`,
                    current:current,
                    onChange:(e,f) => {
                        setCurrent(e)
                        setPageSize(f)
                        localStorage.setItem('ebn_query_page',e);
                        localStorage.setItem('ebn_query_page_size',f);
                    },
                    pageSize:pageSize,
                    showTitle:true
                }}/>
            <div style={{textAlign:'right',width:"100%"}}>
                <span>列显示：</span>
                <Checkbox.Group 
                    value={localStorage.getItem('groupListColumn')?localStorage.getItem('groupListColumn').split(','):defaultCheckedList}
                    options={options}
                    onChange={(value) => {
                        localStorage.setItem('groupListColumn',value+'')
                        setCheckedList(value);
                    }}>产品 
                </Checkbox.Group>                
            </div>
        </Main>
    )
}

export default LedgerGroup