import React from 'react'
import Main from '../../../../../cpn/Main'
import LineName from './LineName'
function CustomTemplateLineName() {
    return (
        <Main t1='定制游' n1='/CustomIndex' t2='模板' n2='/CustomTempIndex' t3='线路名称'>
            <LineName />
        </Main>
    )
}

export default CustomTemplateLineName