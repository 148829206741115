import React, { useEffect, useState } from 'react';
import jsonp from 'fetch-jsonp';
import qs from 'qs';
import { Select,Form, Space,} from 'antd';
import ClientAdd from './ClientAdd';
import SetClient from './SetClient';
//选择框
let timeout;
let currentValue;
const fetch = (value, callback) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;
    const fake = () => {
        const str = qs.stringify({
            code: 'utf-8',
            search_key: value,
            action:'search',
            e_id:localStorage.getItem('e_id'),
        });
        jsonp(`/api/ReactWebSite/clientJsonp?${str}`)
        .then((response) => response.json())
        .then((d) => {
            if (currentValue === value) {
            const { Result } = d;                    
            const data = Result.map((item) => ({
                value: item.ec_id,
                text: item.ec_name,
                content:item.ec_content,
                type:item.ec_type_id,
            }));
            callback(data);
            }
        });
    };
    timeout = setTimeout(fake, 300);
};
const GetClient = (props) => {    
    const ebn_client_id = props.ebn_client_id;
    const ebn_client_name = props.ec_name;
    const { Option } = Select;
    const [data, setData] = useState([]);
    const [value, setValue] = useState(props.defaultValue);
    const handleSearch = (newValue) => {
        if (newValue) {
            fetch(newValue, setData);
        } else {
            setData([]);
        }
    };
    const handleChange = (newValue,event) => {
            setValue(newValue);            
            if(event){
                props.getClient(event.key,event.children);  
            }
            console.log(newClient)
        };
    const options = data.map((d) => <Option key={d.value} value={d.value} content={d.content} type={d.type}>{d.text}</Option>);
    // const defaultOption = <Option key={props.defaultValue}>{props.defaultLabel}</Option>
    //新增客户
    const [newClient,setNewClient] = useState({ec_id:"",ec_name:""});
    useEffect(() => {
        if(newClient.ec_id){
            props.getClient(newClient.ec_id,newClient.ec_name);  
        }
    },[newClient, props,])
    return (        
        <Form.Item
            label={props.label} 
            wrapperCol={props.wrapperCol} 
            required={props.required}
            style={props.FormStyle}
        >
            <Space align='center'>
                {newClient.ec_id ? <>{newClient.ec_name} <a onClick={() => {setNewClient(prevState=>({...prevState,ec_id:'',ec_name:''}))}}>取消</a></> : <>
                <Form.Item  name={props.name} style={{marginBottom:'0px'}}>
                    <Select
                        showSearch
                        value={value}                        
                        placeholder={props.placeholder}
                        defaultActiveFirstOption={false}
                        suffixIcon={null}
                        filterOption={false}
                        onSearch={handleSearch}
                        onChange={handleChange}
                        notFoundContent={null}
                        disabled={props.disabled}
                        style={props.style}
                    >
                        {options}
                    </Select>
                </Form.Item>
                {props.clientAdd && <ClientAdd getNewClient={(e) => {
                    setValue('');
                    setNewClient(prevState=>({...prevState,ec_id:e.ec_id,ec_name:e.ec_name}))
                }}/>}</>}
                {(ebn_client_id && !newClient.ec_id) && <>
                    <a onClick={() => {
                                setValue('');
                                setNewClient(prevState=>({...prevState,ec_id:ebn_client_id,ec_name:ebn_client_name}))
                        }}>{ebn_client_name}</a>
                    </>}
                <SetClient />
            </Space>
        </Form.Item>
    );
};

export default GetClient;