import React, { useState } from 'react'
import { Button,Input, Form,Modal,DatePicker,Radio} from 'antd'
import GetTaskUser from './GetTaskUser'
import dayjs from 'dayjs';
const {RangePicker} = DatePicker
function TaskSendQuery(props) {
    const [form] = Form.useForm();
    const [open,setOpen] = useState(false);
    
    return (
        <>
            <Button onClick={() => {setOpen(true)}}>查询</Button>
            <Modal
                open={open}
                title='查询任务'
                okText="提交"
                cancelText="关闭"
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        <Button onClick={() => {   
                            localStorage.setItem('task_etu_user_id','')
                            localStorage.setItem('task_et_uid','')
                            localStorage.setItem('task_et_status','')
                            localStorage.setItem('task_et_name','')
                            localStorage.setItem('task_et_e_date','')
                            localStorage.setItem('task_et_s_date','')
                            localStorage.setItem('task_action','')
                            localStorage.setItem('task_type','')
                            form.resetFields();
                            props.getQuery({action:'',etu_user_id:'',et_status:'',et_name:'',et_e_date:'',et_s_date:''})  
                            setOpen(false)                                        
                            }}>重置</Button>
                        <CancelBtn />
                        <OkBtn />
                    </>
                )}
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            localStorage.setItem('task_action','search')
                            localStorage.setItem('task_type',values.type)
                            localStorage.setItem('task_etu_user_id',values.etu_user_id)
                            localStorage.setItem('task_et_status',values.et_status)
                            localStorage.setItem('task_et_name',values.et_name)
                            localStorage.setItem('task_et_e_date',values.et_s_date?dayjs(values.et_s_date[1]).format('YYYY-MM-DD'):'')
                            localStorage.setItem('task_et_s_date',values.et_s_date?dayjs(values.et_s_date[0]).format('YYYY-MM-DD'):'')                            
                            props.getQuery({
                                action:"search",
                                etu_user_id:values.etu_user_id,
                                et_status:values.et_status,
                                et_name:values.et_name,
                                et_s_date:values.et_s_date?dayjs(values.et_s_date[0]).format('YYYY-MM-DD'):'',
                                et_e_date:values.et_s_date?dayjs(values.et_s_date[1]).format('YYYY-MM-DD'):'',                       
                            })
                            
                            setOpen(false)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    
                }}
            >
                <Form 
                    layout='horizontal' 
                    name="form_client" 
                    initialValues={{modifier: 'public',}} 
                    form={form}
                    labelCol={{span:5}}
                >
                    <Form.Item 
                        name='et_status' 
                        initialValue={localStorage.getItem('task_et_status')?localStorage.getItem('task_et_status'):'0'}
                        label='任务状态' 
                    >
                        <Radio.Group>
                            <Radio value='0'>全部</Radio>
                            <Radio value='1'>待完成</Radio>
                            <Radio value='2'>待验收</Radio>
                            <Radio value='3'>已验收</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <GetTaskUser 
                        name='etu_user_id'
                        label="参与人" 
                        style={{width:"140px"}}
                        getUser={(e) => {}}
                        initialValue={localStorage.getItem('task_etu_user_id')?[localStorage.getItem('task_etu_user_id')]:''}
                    />

                    <Form.Item 
                        name='et_name'
                        label='标题' 
                        wrapperCol={{span:10}}
                        initialValue={localStorage.getItem('task_et_name')?localStorage.getItem('task_et_name'):''}
                    >
                        <Input placeholder='请填写关键字..' />
                    </Form.Item>                    
                    <Form.Item 
                        label='截止日期' 
                        name='et_s_date' 
                        initialValue={localStorage.getItem('task_et_s_date')?[localStorage.getItem('task_et_s_date'),localStorage.getItem('task_et_e_date')]:''}>
                        <RangePicker />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default TaskSendQuery