import React from 'react'
import Main from '../../cpn/Main'
import { DatabaseOutlined} from '@ant-design/icons';
import Index from '../../cpn/Index';

function BalanceIndex() {
    const menu = [
        {
            router:'/BalanceTypeList',
            icon:<DatabaseOutlined />,
            name:'收支子类型',
            key:'1'
        },                  
    ]
    return (
        <Main>
            <Index esv_id='6' menu={menu}/>
        </Main>
    )
}

export default BalanceIndex