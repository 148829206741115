import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import form_data from "./formData";

const reactapiApi = createApi({
    reducerPath: 'reactapiApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/reactapi'        
    }),
    tagTypes:['account',],
    endpoints(build) {
        return {            
            //账号列表
            zd_system_account_list: build.query({
                query(user) {                    
                    return {
                        url: '/zd_system_account_list',
                        method: 'post',
                        body: form_data(user)
                    };                      
                },
                providesTags:['account'],
            }),         
        }
    }
})

export const {   
    useZd_system_account_listQuery,
    
} = reactapiApi;
export default reactapiApi;