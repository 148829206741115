import React from 'react'
import {  Affix, Menu, Space, } from 'antd';
import { useNavigate, } from 'react-router-dom';
function TaTop(props) {
    const navi = useNavigate();
    const items = [
        {
            label:  <Space  style={{letterSpacing:"2px",fontWeight:"bold"}}>
                        <img src='./img/home/logo.png' alt='' width={26} />
                        <span>旅当家</span>
                        <span>lvdangjia.net</span>
                    </Space>,
            key: '0',
        },
        {
            label: '快速上手',
            key: '1',
        },       
        {
            label: '免费试用',
            key: '2',
        },
        {
            label: '系统登录',
            key: '3',
        },        
    ]
    return (
        <Affix offsetTop='0'>
            <div style={{display:'flex',alignItems:"center",justifyContent:'center',width:"100vw",backgroundColor:'#fff',}}>
                <Menu 
                    defaultValue={['0']}
                    activeKey={props.activeKey}
                    items={items} 
                    mode='horizontal' 
                    style={{fontSize:"18px",width:"600px"}}
                    onClick={(e) => {
                        switch (e.key) {
                            case '0':
                                navi('/TaHome')
                                break;
                            case '1':
                                navi('/TaPrice/TaPriceIndex')
                                break;
                            case '2':
                                navi('/Register')
                                break;
                            case '3':
                                navi('/Login')
                                break;                         
                            default:
                                break;
                        }                        
                    }}
                />  
            </div>                              
         </Affix>
    )
}

export default TaTop