import React,{useState,useEffect } from 'react'
import Main from '../../cpn/Main'
import { useGetProvinceQuery} from '../../store/ReactWebSiteApi'
import { Table,  Button, Form, Input, Space} from 'antd';
import { Link,useNavigate } from 'react-router-dom';

function Province(props) {
  //设置链接
  const navi = useNavigate();  
  //查询数据
  const [queryData, setQueryData] = useState({
    search_key: '',
    action:'search',
  });
  //绑定查询省份名称
  const provinceNameInput = (e) => {
    setQueryData(prevState => ({ ...prevState, search_key: e.target.value }));
  }
  //列表数据
  const [provinceList, setProvinceList] = useState();
  //API
  const { data, isSuccess } = useGetProvinceQuery(queryData);
  useEffect(() => {
    if (isSuccess) {
      if (data.Status === 'success') {
        const newArr = data.Result.map((item) => {
          const { ctap_id: key, ctap_name, ctap_remark } = item;
          return item = {key,ctap_name, ctap_remark }
        })
        setProvinceList(newArr);
      }
    }
  },[data,isSuccess])
  //表头
  const columns = [
    {
      title: 'ID',
      dataIndex: 'key',
      width: '3%',
      defaultSortOrder: 'descend',
      sorter:  (a, b) => {
          return a.key - b.key;
        } 
    },
        
    {
      title: '省份',
      dataIndex: 'ctap_name',
      width:'15%'
    },
    {
      title: '备注',
      dataIndex: 'ctap_remark',
      width:'15%'
    },        
    {
      title: '操作',
      width: '15%',
      render: (record) => (
        <Space size="middle">
          <a onClick={() => {
            navi(`/ProvinceEdit/${record.key}`)   
          }}>修改</a>
        </Space>
      )
    }
  ];
    
  //列表分页
  const onChange = (pagination, filters, sorter, extra) => {
      console.log('params', pagination, filters, sorter, extra);
  };
  
  return (
    <Main   t1='商城' n1='/GfIndexMall' t2='地区' n2='/AreaIndex' t3="省份">
      <div style={{ marginBottom: '20px', display: 'flex' }}>
        <Link to='/ProvinceAdd'><Button type="primary">新增省份</Button></Link>
        <Form
            name='query'
            style={{ marginLeft: '30px' }}
            layout={'inline'}
        >
            <Form.Item name='ctap_name' label="省份" >
                <Input placeholder="请输入省份名称" value={queryData} onChange={provinceNameInput} />
            </Form.Item>
        </Form>
      </div>
      <Table
          columns={columns}
          dataSource={provinceList}
          onChange={onChange}
          pagination={{ showTotal: total => `共${provinceList.length}条数据`, showSizeChange: true, showQuickJumper: true }} />
    </Main>
  )
}
export default Province