import React from 'react'
import Main from '../../../cpn/Main'
import { Row } from 'antd';
import Folder from '../../../cpn/Folder';
function CustomIndex() {
    return (
        <Main t1='地接业务' n1='/LocalIndex' t2='定制游'>
            <Row>
                <Folder title='报价单' link='/CustomFile'/>           
                <Folder title='团队计划' link='/CustomGroup'/>
                <Folder title='销售协议' link='/CustomPact'/>
                <Folder title='模板' link='/CustomTempIndex'/> 
            </Row>                    
        </Main>
    )
}

export default CustomIndex