import React from 'react'
import { message,Modal} from 'antd'
import { useUser_delMutation } from '../store/ReactWebSiteApi';

function SetUserDel(props) {    
    const {confirm} = Modal; 
    const [submitDel] = useUser_delMutation()   
    return (
        <>
            {(props.eu_trail_tag === 0 && props.eu_state==='1') && 
                                <a onClick={() => {
                                    confirm({
                                        title: `${props.eu_name}尚未登录系统，可以删除。`,
                                        okText: '确认',
                                        okType: 'danger',
                                        cancelText: '取消',
                                        onOk() {
                                            submitDel({eu_id:props.eu_id}).then((res) => {
                                                if (res.data.Status === 'success') {
                                                    message.info('删除成功！');
                                                }
                                                if (res.data.Status === "fail") {
                                                    message.error('删除失败！'+res.data.Message)
                                                }
                                            })
                                        },
                                        onCancel() {
                                        console.log('Cancel');
                                        },
                                    })
                                }}>删除</a>}
        </>
    )
}

export default SetUserDel