import React,{useState,useEffect,useRef}from 'react'
import { Table,Button,Space,Modal,message,Form,Input, Radio,} from 'antd';
import { useSearchParams, } from 'react-router-dom';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Main from '../cpn/Main'
import { useTopicAddMutation, useTopicDelMutation, useTopicListQuery, useTopicUpdateQuery, useTopicUpdateSaveMutation } from '../store/ReactWebSiteApi';
const { TextArea } = Input;
function TrainQuestion() {
    const [param] = useSearchParams()
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        let answerStr = '';
        let valueStr = '';
        if(values.users){
            //答案
            const answerArr = values.users.map((item) => {
                const { first } = item
                return first
                })
            answerStr = answerArr + ""
            const valueArr = values.users.map((item) => {
                    const { last} = item 
                    return last
                })
            valueStr = valueArr + ""
        }
        submitAdd({
            ewpt_ewp_id:param.get('id'),
            ewpt_name:infoAdd.ewpt_name,
            ewpt_answer_name:answerStr,
            ewpt_answer_value:valueStr,
            ewpt_uid:localStorage.getItem('eu_id'),
            ewpt_uname:localStorage.getItem('eu_name'),
        }).then((res) => {
            console.log(res);
            if (res.data.Status === 'success') {
                message.success('新增成功！')  
                setOpenAdd(false)                         
            }
            if (res.data.Status === 'fail') {
                message.error('新增失败！'+res.data.Message)
            }
        })
        setOpenAdd(false);
    };
    const [infoAdd,setInfoAdd] = useState({
        ewpt_content:'',
    });
    const [submitAdd] = useTopicAddMutation()
    const modalAdd= <Modal
                        open={openAdd}
                        title='新增问答'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        width={1000}
                        onOk={(values) => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClient(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            console.log(values);
                            
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 3 }}
                        >
                           
                            <Form.Item name="name" label='提问' wrapperCol={{span:18}} required>
                                <TextArea  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ewpt_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="en_content" label='可选答案' wrapperCol={{span:18}} required>
                                <Form.List name="users">
                                    {(fields, { add, remove }) => (
                                        <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Space
                                                key={key}
                                                style={{
                                                    display: 'flex',
                                                    marginBottom: 8,
                                                }}
                                                align="baseline"
                                            >
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'first']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Missing first name',
                                                        },
                                                    ]}
                                                    
                                                >
                                                    <Input placeholder="可选答案"  style={{width:'500px'}}/>
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'last']}
                                                    rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing last name',
                                                    },
                                                    ]}
                                                >
                                                    <Radio.Group>
                                                        <Radio value='1'>正确</Radio>
                                                        <Radio value='2'>错误</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                    添加可选答案
                                            </Button>
                                        </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Form.Item>
                        </Form>
                    </Modal> 
    //修改测试题-----------------------------------------------------------------------------------------------------------------------------修改测试题
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState();
    const [submitUpdateSave] = useTopicUpdateSaveMutation()
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        let answerStr = '';
        let valueStr = '';
        if(values.users){
            //答案
            const answerArr = values.users.map((item) => {
                const { first } = item
                return first
                })
            answerStr = answerArr + ""
            const valueArr = values.users.map((item) => {
                    const { last} = item 
                    return last
                })
            valueStr = valueArr + ""
        }
        submitUpdateSave({
            ewpt_ewp_id:param.get('id'),
            ewpt_name:infoEdit.ewpt_name,
            ewpt_answer_name:answerStr,
            ewpt_answer_value:valueStr,
            ewpt_update_uid:localStorage.getItem('eu_id'),
            ewpt_update_uname:localStorage.getItem('eu_name'),
            ewpt_id:editId
        }).then((res) => {
            console.log(res);
            if (res.data.Status === 'success') {
                message.success('修改成功！')  
                setOpenAdd(false)                         
            }
            if (res.data.Status === 'fail') {
                message.error('修改失败！'+res.data.Message)
            }
        })
        setOpenEdit(false);
    };
    const [infoEdit,setInfoEdit] = useState();
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useTopicUpdateQuery({ewpt_id:editId})
    //默认测试题
    const [defaultQuestion,setDefaultQuestion] = useState();
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                if(dataEdit.Result.ewpt_answer_name){
                    let options = [];
                    let size = dataEdit.Result.ewpt_answer_name.length;
                    for (let i = 0; i < size; i++) {
                        let a = {};
                        a.first = dataEdit.Result.ewpt_answer_name[i];
                        a.last = dataEdit.Result.ewpt_answer_value[i];
                        options.push(a);
                    }
                    setDefaultQuestion(<Form.List name="users" initialValue={options}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                            <Space
                                key={key}
                                style={{
                                display: 'flex',
                                marginBottom: 8,
                                alignItems:'center',
                                }}
                                align="baseline"
                            >
                                <Form.Item
                                    {...restField}
                                    name={[name, 'first']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Missing first name',
                                        },
                                    ]}
                                    
                                >
                                    <Input placeholder="可选答案"  style={{width:'500px'}}/>
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'last']}
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Missing last name',
                                    },
                                    ]}
                                >
                                    <Radio.Group>
                                        <Radio value='1'>正确</Radio>
                                        <Radio value='2'>错误</Radio>
                                    </Radio.Group>
                                </Form.Item>                                
                                <MinusCircleOutlined onClick={() => remove(name)}/>
                            </Space>
                            ))}
                            <Form.Item style={{width:'420px'}}>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>添加可选答案</Button>
                            </Form.Item>
                        </>
                    )}
                  </Form.List>)
                  }else{
                    setDefaultQuestion(<Form.List name="users">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                            <Space
                                key={key}
                                style={{
                                display: 'flex',
                                marginBottom: 8,
                                alignItems:'center'
                                }}
                                align="baseline"
                            >
                                <Form.Item
                                    {...restField}
                                    name={[name, 'first']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Missing first name',
                                        },
                                    ]}
                                    
                                >
                                    <Input placeholder="可选答案"  style={{width:'500px'}}/>
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'last']}
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Missing last name',
                                    },
                                    ]}
                                >
                                    <Radio.Group>
                                        <Radio value='1'>正确</Radio>
                                        <Radio value='2'>错误</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                            </Space>
                            ))}
                            <Form.Item style={{width:'420px'}}>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>添加可选答案</Button>
                            </Form.Item>
                        </>
                    )}
                  </Form.List>)
                  
                  }
                editForm.current.setFieldsValue({
                    ewpt_name:dataEdit.Result.ewpt_name,
                    ewpt_content:dataEdit.Result.ewpt_content,
                })
                setInfoEdit(prevState=>({...prevState,
                    ewpt_name:dataEdit.Result.ewpt_name,
                    ewpt_content:dataEdit.Result.ewpt_content,              
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    

    const modalEdit = <Modal
                        open={openEdit}
                        title='修改问答'
                        okText="提交"
                        cancelText="取消"
                        width={1000}
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >
                            <Form.Item label='培训计划' wrapperCol={{span:10}} required>
                                {param.get('name')}
                            </Form.Item>
                            <Form.Item name="ewpt_name" label='提问' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ewpt_name:e.target.value}))}}/>
                            </Form.Item>
                            {defaultQuestion}
                        </Form>
                    </Modal>
    //删除测试题-----------------------------------------------------------------------------------------------------------------------------删除测试题
    const {confirm} = Modal; 
    const [submitDel] = useTopicDelMutation()
    //测试题列表-----------------------------------------------------------------------------------------------------------------------------测试题列表
    
    const {data,isSuccess} = useTopicListQuery({ewpt_ewp_id:param.get('id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {ewpt_id:key,ewpt_name,ewpt_answer_name,ewpt_answer_value,ewpt_uname,ewpt_ctime,ewpt_update_ctime,ewpt_update_uname} = item;
                    return item = {key,ewpt_name,ewpt_answer_name,ewpt_answer_value,ewpt_uname,ewpt_ctime,ewpt_update_ctime,ewpt_update_uname}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ewpt_ctime;
                    let bTimeString = b.ewpt_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
       
        {
            key: 'type',
            title: '提问',
            dataIndex: 'ewpt_name',
        },
        {
            key: 'room',
            title: '可选答案',
            render:(_,record) => {
                if(record.ewpt_answer_name){
                    const newArr = record.ewpt_answer_name.map((item,index) => {
                        return <div key={index}>{index+1}、{item}。{record.ewpt_answer_value[index]==='1'?"（✓）":'（×）'}</div>
                    })
                    return newArr
                }
            
            }
        },       
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.ewpt_ctime + record.ewpt_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>                        
                        <a onClick={() => {
                            setEditId(record.key);
                            setOpenEdit(true)
                        }}>修改</a>
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({ewpt_id:record.key}).then((res) => {
                                        console.log(res);
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main t1='培训计划' t2={param.get('name')} t3='测试题' n1='/TrainPlan?esv_id=5' >
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增</Button>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalAdd}
            {modalEdit}            
        </Main>
    )
}

export default TrainQuestion