import { Col,} from 'antd'
import React from 'react'
import classes from '../site/index.module.css'
function Program(props) {
    return (
        <Col span={2} className={classes.programItem} onClick={() => {props.onClick()}}>
            <div style={{height:"70%",display:"flex",alignContent:"center"}}>{props.icon}</div>
            <div style={{height:"30%"}}>{props.title}</div>
        </Col>
    )
}

export default Program