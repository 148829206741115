import React, { useEffect, useState } from 'react'
import { Space, Table ,message} from 'antd'
import LineNameAdd from './LineNameAdd'
import { useDijieTemplateXianLuDelMutation, useDijieTemplateXianLuListQuery } from '../../../../../store/ReactWebSiteApi'
import ModalDel from '../../../../../cpn/ModalDel'
import LineNameEdit from './LineNameEdit'
import LineNameQuery from './LineNameQuery'
function LineName(props) {
    const [list,setList] = useState()
    const [query,setQuery] = useState({ltxl_e_id:localStorage.getItem('e_id')})
    const {data, isSuccess} = useDijieTemplateXianLuListQuery(query,{refetchOnMountOrArgChange:1});
    useEffect(() => {
        if (isSuccess) {
            console.log(data);
            if(data.Status==='success'){                
                const newArr = data.Result.map(item => {
                    const {ltxl_id:key,ltxl_name,ltxl_uname,ltxl_ctime,ed_name} = item;                    
                    return item = {key,ltxl_name,ltxl_uname,ltxl_ctime,ed_name}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ltxl_ctime;
                    let bTimeString = b.ltxl_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    //删除
    const [del] = useDijieTemplateXianLuDelMutation()
    const column = [             
        {
            key:'11',
            title:'线路名称模板',
            dataIndex:'ltxl_name'
        },     
        {
            key:'11',
            title:'部门',
            dataIndex:'ed_name'
        },                        
        {
            key:'6',
            title:'操作',
            render:(_,record) => {
                return <Space>
                    <LineNameEdit ltxl_id={record.key} />
                    <ModalDel onOk={() => {
                        del({ltxl_id:record.key}).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                    {props.get && <a onClick={() => {
                        props.getName(record.ltxl_name)
                        props.getOpen(false)     
                        }}>选择</a>}
                </Space>
            }
        },
    ]
    return (
        <>
            <Space>
                <LineNameAdd />
                <LineNameQuery
                        getQuery={(e) => {
                        setQuery(prevState=>({
                            ...prevState,
                            ltxl_dept_id:e.ltxl_dept_id,
                            ltxl_name:e.ltxl_name,
                        }))
                    }}
                />
            </Space>
            <Table columns={column} dataSource={list} style={{marginTop:"10px"}}/>
        </>
    )
}

export default LineName