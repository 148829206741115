import React  from 'react'
import MainKeeper from './MainKeeper'
import classes from './keeper.module.css'
function Keeper() {
    return (
        <MainKeeper topActive='keeper'>
            <div  className={classes.homeTitle}>内帐通</div>
        </MainKeeper>
    )
}

export default Keeper