import React,{useState,useEffect} from 'react'
import Main from '../../cpn/Main'
import { Table,DatePicker,Space, Button, Modal, Flex, Descriptions} from 'antd'
import { useIncomeExpenditureClientStatisticsQuery} from '../../store/ReactWebSiteApi';
import dayjs from 'dayjs';

const {RangePicker} = DatePicker
function LedgerArapClient() {
    const [open,setOpen] = useState(false)
    const [query,setQuery] = useState({
        date_s:dayjs().startOf('month').format('YYYY-MM-DD'),
        date_e:dayjs().endOf('month').format('YYYY-MM-DD'),
        e_id:localStorage.getItem('e_id')
    })
    const {data,isSuccess} = useIncomeExpenditureClientStatisticsQuery(query,{refetchOnMountOrArgChange:1,skip:!open})    
    const [list, setList] = useState();    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {ec_id:key,ec_name,expenditure_num,income_num,} = item;
                    return item = {key,ec_name,expenditure_num,income_num,}
                })                
                
                setList(newArr)
            }else{
                setList()
            }
            
        }
    }, [data, isSuccess])    
    
    const columns = [
        {
            key: 'id',
            title: '客户',
            dataIndex: 'ec_name'
        },         
        {
            key: 'ar',
            title: '团队收入单待下账金额之和',
            dataIndex: 'income_num',
        },             
        {
            key: 'ap',
            title: '团队支出单待下账金额之和',
            dataIndex: 'expenditure_num',
        },             
        {
            key: 'profit',
            title: '差额',
            render:(_,record) => {
                return <>{(+record.income_num + +record.expenditure_num).toFixed(2)}</>
            }
        },          
    ]
    const items = [        
        {
            key:'2',
            label:'团队报账单的出发日期',
            children:<>{query.date_s}至{query.date_e}</>,
        },
        {
            key:'3',
            label:'制表人',
            children:<>{localStorage.getItem('ed_name').replace(/"/g,'')} / {localStorage.getItem('eu_name').replace(/"/g,'')}</>
        },
        {
            key:'4',
            label:'制表时间',
            children:<>{dayjs().format('YYYY-MM-DD HH:MM:ss')}</>
        },
    ]
    return (
        <Main  t1='财务' n1='/TaFinanceIndex' t2='团队收支' n2='/LedgerIndex' t3='报表' n3='/LedgerTable' t4='待下账客户表'>
            <div style={{marginBottom:'30px'}}>本表说明：按团队报账单的出发日期，统计每个客户的团队收入单待下账金额之和、团队支出单待下账金额之和、差额。</div>
            <Space style={{marginBottom:"10px"}}>
                <span>团队报账单的出发日期：</span>
                <RangePicker 
                    defaultValue={[dayjs().startOf('month'),dayjs().endOf('month')]} 
                    onChange={(e) => {
                        if(e){
                            setQuery(prevState=>({...prevState,date_s:dayjs(e[0]).format('YYYY-MM-DD'),date_e:dayjs(e[1]).format('YYYY-MM-DD')})) 
                        }else{
                            setQuery(prevState=>({...prevState,date_s:'',date_e:''}))
                        }
                    }}
                />
                <Button type='primary' onClick={() => {setOpen(true)}}>查询</Button>                
            </Space>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false)}}
                width={1200}
                footer={null}
            >            
                <Flex justify='center'>
                    <h2>待下账客户表</h2>
                </Flex>
                <Descriptions items={items} bordered/>
                <Table 
                    columns={columns} 
                    dataSource={list}
                    summary={(pageData) => {
                        let totalincome_num = 0;
                        let totalnexpenditure_num = 0;
                        pageData.forEach(({ebn_num, income_num,expenditure_num, }) => {                        
                            totalincome_num += +income_num;
                            totalnexpenditure_num += +expenditure_num;
                        });
                        return <Table.Summary>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>{(totalincome_num).toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}>{(totalnexpenditure_num).toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}>{(totalincome_num+totalnexpenditure_num).toFixed(2)}</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                    }}
                />
            </Modal>
        </Main>
    )
}

export default LedgerArapClient