import React from 'react'
import Main from '../../../../../cpn/Main'
import Feedback from './Feedback'
function CustomFeedback() {
    return (
        <Main t1='定制游' n1='/CustomIndex' t2='模板' n2='/CustomTempIndex' t3='意见单'>
            <Feedback />
        </Main>
    )
}

export default CustomFeedback