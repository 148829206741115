import { Button, Form, Input, message, Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useQuanxianUpdateQuery, useQuanxianUpdateSaveMutation } from '../../store/ReactWebSiteApi';

function AppPowerEdit(props) {
    const [form] = Form.useForm();
    const [open,setOpen] = useState(false)
    const [info,setInfo] = useState({qx_content:''})
    const {data,isSuccess} = useQuanxianUpdateQuery({qx_id:props.qx_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                editForm.current.setFieldsValue({
                    qx_content:data.Result.qx_content,
                    qx_name:data.Result.qx_name,

                })
                setInfo(prevState=>({...prevState,
                    qx_content:data.Result.qx_content,
                    qx_name:data.Result.qx_name,
                    qx_parent_id:data.Result.qx_parent_id,
                    
                }))
            }
        }
    },[data,isSuccess])
    const editForm = useRef()
    const [submitAdd] = useQuanxianUpdateSaveMutation()
    return (
        <>
            <Button onClick={() => {setOpen(true)}}>修改</Button>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false)}}
                width={600}
                title='新增旅当家权限'
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        qx_type:'1',
                        qx_update_uid:localStorage.getItem('eu_id'),
                        qx_update_uname:localStorage.getItem('eu_name'),
                        qx_parent_id:info.qx_parent_id,
                        qx_name:info.qx_name,
                        qx_content:info.qx_content,
                        qx_id:props.qx_id,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    labelCol={{span:5}}
                    ref={editForm}
                >
                    <Form.Item label='权限id' wrapperCol={{span:10}}>
                        {props.qx_id}
                    </Form.Item>                    
                    <Form.Item label='权限名称' wrapperCol={{span:10}} name='qx_name'>
                        <Input onChange={(e) => {setInfo(prevState=>({...prevState,qx_name:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item label='备注'  wrapperCol={{span:15}} name='qx_content'>
                        <Input onChange={(e) => {setInfo(prevState=>({...prevState,qx_content:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default AppPowerEdit