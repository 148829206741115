import React,{useState,useEffect} from 'react'
import Main from '../../cpn/Main'
import { useAddDistrictMutation } from '../../store/ReactWebSiteApi'
import { message,Button, Form, Input, Row,  Col,Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useGetProvinceQuery } from '../../store/ReactWebSiteApi';
import { useGetCityQuery } from '../../store/ReactWebSiteApi';
function DistrictAdd(props) {  
  //获取省份数据
  const [province, setProvince] = useState();
  const [selectProvince, setSelectProvince] = useState();
  //获取区县数据
  const [city, setCity] = useState();
  const [selectCity, setSelectCity] = useState();  
  //API
  const { data: dataProvince, isSuccess: isSuccessProvince } = useGetProvinceQuery();
  const { data: dataCity, isSuccess: isSuccessCity,refetch } = useGetCityQuery({ province_id: selectProvince,action:'search' }, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isSuccessProvince) {
      console.log(dataProvince);
      const newArr = dataProvince.Result.map((item) => {
        const { ctap_id:value, ctap_name:label } = item
        return item = { value, label }
      })
      setProvince(newArr);
    }
    
  }, [dataProvince, isSuccessProvince,])
  
  useEffect(() => {
    if (isSuccessCity) {
      const newArr = dataCity.Result.map((item) => {
        const { ctaci_id:value, ctaci_name:label } = item
        return item = { value, label }
      })
      setCity(newArr);
    }
  },[ dataCity, isSuccessCity,selectProvince])
  
  //绑定查询省
  const handleChange = (e) => {
    setSelectProvince(e);
    refetch();
    setSelectCity(undefined);
    setDistrict(prevState => ({ ...prevState, ctad_province_id: e}));
  }
  //绑定查询城市
  const handleChangeCity = (e) => {    
    setSelectCity(e)
    setDistrict(prevState => ({ ...prevState, ctad_city_id: e}));
  }
  //新增数据
  const [district, setDistrict] = useState({
    ctad_province_id: '',
    ctad_city_id:'',
    ctad_name:'',
    ctad_remark: '',
  });
  //绑定区县名称
  const nameInput = (e) => {
    setDistrict(prevState =>({...prevState,ctad_name:e.target.value}))
  }
  //绑定区县备注
  const remarkInput = (e) => {
    setDistrict(prevState =>({...prevState,ctad_remark:e.target.value}))
  }
  //链接
  const navi = useNavigate();
  //上传数据
  const [addDistrict] = useAddDistrictMutation();
  //提交对话框表单
  const onFinish = () => {
    addDistrict(district).then(
      (res) => {
        if (res.data.Status === 'success') {
          message.success('新增区县成功！')
          navi('/district');                                    
        }
        if (res.data.Status === 'fail') {
          message.error('新增区县失败！'+res.data.Message)
        }
      })
  }
  const onFinishFailed = () => {}
  return (
    <Main title1='系统' title2='地区' title3='区县' title4='新增区县'>
      <Row>
        <Col span={12}>
          <Form
              name="basic"
              labelCol={{span: 4,}}
              wrapperCol={{span: 20,}}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
          >
            <Form.Item
              label="1、选择区县"
              name="province"
              required
            >              
              <Select
              style={{ width: 120,}}
              onChange={handleChange}
              options={province}
              value={selectProvince}
              placeholder='请选择省'
              />
              <Select
                style={{ width: 120,}}
                onChange={handleChangeCity}
                options={city}
                value={selectCity}
                placeholder='请选择区县'
              />
            </Form.Item>        
            <Form.Item
                label="2、填写区县名称"
                name="name"
                rules={[{
                    required: true,
                    message: '请填写区县名称！',
                },]}
                wrapperCol={{
                    span: 4,
                }}
            >
                <Input value={district.ctad_name} onChange={nameInput} />
            </Form.Item>
            <Form.Item
                label="3、填写备注"
                name='remark'              
            >
              <Input value={district.ctad_remark} onChange={remarkInput} />
            </Form.Item>                    
            <Form.Item
                wrapperCol={{
                    offset: 2,
                    span: 16,
                }}
            >
              <Button type="primary" htmlType="submit">提交</Button>
            </Form.Item>
          </Form>          
        </Col>
      </Row>
    </Main>
  )
}
export default DistrictAdd