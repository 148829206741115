import React,{useState} from 'react'
import {Form,Modal, Input,message, Button, Space,} from 'antd'
import { useBusinessNumAddMutation, } from '../../store/ReactWebSiteApi';
import GetGoods from '../../finance/goods/GetGoods';
import dayjs from 'dayjs';
import GetUserDept from '../../cpn/GetUserDept';
import GetClient from '../../finance/client/GetClient';
import GetUser from '../../cpn/GetUser';
import GetGroupStartDate from './GetGroupStartDate';
import SetLedgerStartdate from './SetGroupStartDate';
import GetSubscription from '../../set/GetSubscription';

const { TextArea } = Input;
function LedgerGroupAdd(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        // console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({
        ebn_esv_id:props.ebn_esv_id,
        ebn_e_id:localStorage.getItem('e_id'),
        ebn_dept_id:localStorage.getItem('ed_id'),
        ebn_content:'',
        ebn_yw_id:'',
        ebn_yw_name:'',
        ebn_eg_id:'',
    })
    const [submitAdd] = useBusinessNumAddMutation()
    
    return (
        <>
            <GetSubscription type='财务模块'/>
            <Button type='primary' onClick={() => {setOpen(true);}}>新增</Button>
            <Modal
                open={open}
                title='新增团队报账单'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                            console.log(values);
                            if(!info.ebn_client_id){
                                message.error('客户必填！');
                                return false
                            };
                            if(!values.goods){
                                message.error('产品必填！');
                                return false
                            };
                            if(!values.dept || values.dept==='0' || values.dept === 'undefined'){
                                message.error('部门必填！');
                                return false
                            };
                            if(!values.date){
                                message.error('出发日期必填！');
                                return false
                            };
                            submitAdd({
                                ebn_esv_id:'14',
                                ebn_e_id:localStorage.getItem('e_id'),
                                ebn_dept_id:info.ebn_dept_id,
                                ebn_eci_id:'',
                                ebn_eg_id:info.ebn_eg_id,
                                ebn_date:info.ebn_date,
                                ebn_content:info.ebn_content,
                                ebn_uid:localStorage.getItem('eu_id'),
                                ebn_uname:localStorage.getItem('eu_name'),
                                ebn_client_id:info.ebn_client_id,
                                ebn_yw_id:info.ebn_yw_id,
                                ebn_yw_name:info.ebn_yw_name,
                            }).then((res) => {
                                // console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpen(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })    
                        })
                        .catch((info) => {
                            // console.log('Validate Failed:', info);
                        }); 
                                               
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                >                                                       
                    <GetUserDept 
                        label='部门' 
                        getUserDept={(e,f) => {
                            setInfo(prevState=>({...prevState,ebn_dept_id:e}))
                        }}
                        wrapperCol={{span:10}}
                        required
                        name='dept'
                    />
                    <Form.Item  label='出发日期' wrapperCol={{span:15}} style={{marginBottom:"0"}} required>
                        <Space align='center' size='middle'>
                            <GetGroupStartDate 
                                getGroupStartDate={(e) => {setInfo(prevState=>({...prevState,ebn_date:dayjs(e).format('YYYY-MM-DD')}))}} 
                                ed_id={info.ebn_dept_id}
                                name='date'
                            />
                            <SetLedgerStartdate />
                        </Space>
                    </Form.Item>
                    <GetClient 
                        label='客户'
                        wrapperCol={{span:20}}
                        getClient={(e,f) => {
                            setInfo(prevState=>({...prevState,ebn_client_id:e}))
                        }} 
                        style={{width:"200px"}}
                        required
                        name='ebn_client_id'
                        clientAdd='true'
                    />                   
                    <GetGoods 
                        getGoods={(e,f) => {
                            setInfo(prevState=>({...prevState,ebn_eg_id:e}))
                        }} 
                        label='产品' 
                        eg_esv_id={props.ebn_esv_id}
                        wrapperCol={{span:0}}  
                        eg_dept_id={localStorage.getItem('cur_dept_id')}
                        name='goods'
                        required={[
                            {
                                require:true,
                            }
                        ]}
                    />        
                    <Form.Item name="name" label='备注' wrapperCol={{span:15}}>
                        <TextArea  onChange={(e) => {setInfo(prevState=>({...prevState,ebn_content:e.target.value}))}}/>
                    </Form.Item>
                    <GetUser  wrapperCol={{span:5}}  label='销售员' name='yw' required={false} getUser={(e,f) => {setInfo(prevState=>({...prevState,ebn_yw_id:e,ebn_yw_name:f}))}}/>
                </Form>
            </Modal>
        </>
    )
}

export default LedgerGroupAdd