import React,{useState,useEffect}from 'react'
import { Table,Space,} from 'antd';
import {useFinanceSubTypeListQuery,} from '../../store/ReactWebSiteApi';
import BalanceTypeAdd from './BalanceTypeAdd';
import BalanceTypeEdit from './BalanceTypeEdit';
import { useSearchParams } from 'react-router-dom';
import BalanceTypeQuery from './BalanceTypeQuery';
import BalanceTypeDel from './BalanceTypeDel';

import ModalType from '../../cpn/ModalType';
function BalanceTypeList(props) {   
    const [param] = useSearchParams() 
    const [query,setQuery] = useState({efst_e_id:localStorage.getItem('e_id'),efst_finance_type_id:props.type_id})    
    const {data,isSuccess} = useFinanceSubTypeListQuery(query,{refetchOnMountOrArgChange:1})    
    const [list, setList] = useState();    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                console.log(data);                
                const newArr = data.Result.map(item => {
                    const {efst_id:key,efst_finance_type_id,efst_name,efst_content,efst_uname,efst_ctime,efst_update_ctime,efst_update_uname,num,ed_uname} = item;
                    return item = {key,efst_finance_type_id,efst_name,efst_content,efst_uname,efst_ctime,efst_update_ctime,efst_update_uname,num,ed_uname}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.efst_ctime;
                    let bTimeString = b.efst_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])     


    const columns = [    
        // {
        //     key: 'type',
        //     title: '主类型名称',
        //     dataIndex: 'efst_finance_type_id',
        //     width:'10%',
        //     render:(text) => {
        //         const type = balanceType.filter(item => item.value===text);
        //         console.log(type);
        //         if(type[0]){
        //             return <>{type[0].label}</>
        //         }else{
        //             return<></>
        //         }
        //     }
        // },                                          
        {
            key: 'type',
            title: props.title,
            dataIndex: 'efst_name',
            width:'10%',
            render:(text,record) => {
                return  <ModalType text={text} record={record} title={props.title}/>
            }
        },                                          
                                        
        {
            key: 'num',
            title: '收支数量',
            dataIndex: 'num',
            width:'10%',
        },   
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key',
            width:'10%',
        },                                            
        {
            key: 'ed_uname',
            title: '部门',
            dataIndex: 'ed_uname',
            width:'10%',
        },                                            
        // {
        //     key: 'time',
        //     title: '操作记录',
        //     render: (_,record) => {
        //         return <>
        //             <div>新建时间：{record.efst_ctime + record.efst_uname}</div>
        //             <div>修改时间：{record.efst_update_ctime + record.efst_update_uname}</div>
        //         </>
        //     },
        //     width:'10%',
        // },
        {
            key: 'action',
            width:'10%',
            title: '操作',
            render: (_, record) => {                
                return <>
                    <Space>                        
                        <BalanceTypeEdit
                            efst_id={record.key}
                            efst_finance_type_name={param.get('efst_finance_type_name')}
                            efst_finance_type_id={param.get('efst_finance_type_id')}
                            title={props.title}
                        />
                        <BalanceTypeDel efst_id={record.key} num={record.num}/>
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <>
            <Space>
                <BalanceTypeAdd 
                    efst_finance_type_id={props.efst_finance_type_id}
                    title={props.title}
                />
                <BalanceTypeQuery 
                    title={props.title}
                    getQuery={(e) => {
                        setQuery(prevState=>({
                            ...prevState,
                            efst_finance_type_id:props.type_id,
                            efst_name:e.efst_name
                        }))
                    }}
                    type_id={props.type_id}
                />
            </Space>
            <Table columns={columns} dataSource={list} style={{marginTop:"10px"}}></Table>
                    
        </>
    )
}

export default BalanceTypeList