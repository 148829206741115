import React, { useEffect, useState } from 'react'
import { Space,Divider} from 'antd'
import { useTaskMessageListQuery } from '../store/ReactWebSiteApi'
import TaskMessageEdit from './TaskMessageEdit';
import TaskMessageDel from './TaskMessageDel';
function TaskMessageList(props) {
    const [list,setList] = useState();
    const {data,isSuccess} = useTaskMessageListQuery({etul_et_id:props.et_id},{skip:!props.open});
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                const arr = data.Result.map((item) => {
                    const {etul_id:key,etul_uname,etul_content,etul_ctime,etul_uid} = item;
                    
                    return <div key={key} etul_ctime={etul_ctime}>                                                               
                                <div  dangerouslySetInnerHTML={{__html:etul_content}}></div>
                                <Space>
                                    <span>{etul_uname + etul_ctime}</span>
                                    {localStorage.getItem('eu_id')===etul_uid && <TaskMessageEdit 
                                        etul_id={key}  
                                        et_status={props.et_status} 
                                        et_uid={props.et_uid}
                                        etu_user_id={props.etu_user_id}
                                        et_id={props.et_id} 
                                    />}
                                    {localStorage.getItem('eu_id')=== etul_uid &&<TaskMessageDel etul_id={key} />}
                                </Space>
                                <Divider /> 
                            </div>
                })
                const sortArr = arr.sort( (a, b) => {      
                    let aTimeString = a.props.etul_ctime;
                    let bTimeString = b.props.etul_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr);
            }else{
                setList('');
            }
        }
    },[data, isSuccess, props.et_status, props.et_uid, props.etu_user_id, props.et_id, props.etul_uid])
    return (
        <>
            <Divider>以下为留言内容</Divider>
            {list}            
        </>
    )
}

export default TaskMessageList