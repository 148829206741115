import { Space, Table } from 'antd'
import React, { useState } from 'react'
import GuidePlanAdd from './GuidePlanAdd'
import GuidePlanCheck from './GuidePlanCheck'
import GuidePlanCopy from './GuidePlanCopy'

function GuidePlan() {
    const [list] = useState([
        {
            key:'1',
            lth_num:'1',
            driver:'司机张三，13972008078，30人'
        }
    ])
    const column = [             
        {
            key:'1',
            title:'导游计划单',
            dataIndex:'lth_num',  
            width:'200px',
            render:() => {
                return <GuidePlanCheck 
                guideName='张三-13972008078'
            />
            }         
        },    
        {
            key:'2',
            title:'分车序号', 
            width:'100px',           
        },   
        {
            key:'3',
            title:'司机',    
            dataIndex:'driver'        
        },
        {
            key:'4',
            title:'备注',            
        },
        {
            key:'5',
            title:'操作',
            width:"120px",
            render:(_,record) => {
                return <Space>
                    
                    <GuidePlanCopy
                    />
                </Space>
            }
        },
    ]
    return (
        <>
            <div style={{marginLeft:'20px',marginTop:'10px'}}>
                <GuidePlanAdd />
            </div>
            <Table 
                columns={column}
                dataSource={list}
                pagination={{position:['none']}}
            />
        </>
    )
}

export default GuidePlan