import React,{useState} from 'react'
import {Form,Modal,message,} from 'antd'
import MyEditor from '../cpn/MyEditor';
import {useTaskMessageAddMutation } from '../store/ReactWebSiteApi';
function TaskMessageAdd(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        // console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [submit] = useTaskMessageAddMutation();
    const [info,setInfo] = useState({
        etul_content: '',
        etul_et_id: props.et_id,
        etul_uid:localStorage.getItem('eu_id'),
        etul_uname: localStorage.getItem('eu_name'),
        et_status:''
    });
    return (
        <>
            <a onClick={() => {setOpen(true);}}>留言</a>
            <Modal
                open={open}
                title='新增留言'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                            // console.log(values);                           
                            submit(info).then((res) => {
                                // console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpen(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            }) 
                        })
                        .catch((info) => {
                            // console.log('Validate Failed:', info);
                        }); 
                                               
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_add"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}                  
                >                        
                    <Form.Item 
                        name="content" 
                        label='备注' 
                        wrapperCol={{span:15}}
                        // dependencies={['content']}
                        rules={[
                            {
                              required: true,
                            },
                            // ({ getFieldValue }) => ({
                            //     validator(_, value) {
                            //         console.log(value,getFieldValue('content'));
                            //         if (!value) {
                            //             return Promise.resolve();
                            //         }
                            //         return Promise.reject(new Error('The new password that you entered do not match!'));
                            //     },
                            // }),
                        ]}
                    >
                        <MyEditor  onChange={(e) => {setInfo(prevState=>({...prevState,etul_content:e}))}}/>
                    </Form.Item>                      
                </Form>
            </Modal>
        </>
    )
}

export default TaskMessageAdd