import React, { useEffect, useRef, useState } from 'react'
import ModalEdit from '../../../../../cpn/ModalEdit'
import { Form, Input,InputNumber,message, Radio } from 'antd'
import GetUserDept from '../../../../../cpn/GetUserDept';
import { useDijieTemplateFeiYongMingXiUpdateQuery, useDijieTemplateFeiYongMingXiUpdateSaveMutation,} from '../../../../../store/ReactWebSiteApi';
import { localCostType } from '../../customType';

function PriceEdit(props) {
    const [form] = Form.useForm();  
    const editForm = useRef()
    const [info,setInfo] = useState({
        ltfymx_e_id:localStorage.getItem('e_id'),
        ltfymx_update_uid:localStorage.getItem('eu_id'),
        ltfymx_update_uname:localStorage.getItem('eu_name'),
        ltfymx_id:props.ltfymx_id,
    });
    const [open,setOpen] = useState()
    //获取
    const {data,isSuccess} = useDijieTemplateFeiYongMingXiUpdateQuery({ltfymx_id:props.ltfymx_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                editForm.current.setFieldsValue({
                    ltfymx_dept_id:data.Result.ltfymx_dept_id,                  
                    ltfymx_name:data.Result.ltfymx_name,
                    ltfymx_type_id:data.Result.ltfymx_type_id,
                    ltfymx_price:data.Result.ltfymx_price,
                    ltfymx_unit:data.Result.ltfymx_unit,
                })
                setInfo(prevsState=>({...prevsState,
                    ltfymx_dept_id:data.Result.ltfymx_dept_id,
                    ltfymx_name:data.Result.ltfymx_name,
                    ltfymx_type_id:data.Result.ltfymx_type_id,
                    ltfymx_price:data.Result.ltfymx_price,
                    ltfymx_unit:data.Result.ltfymx_unit,
                }))
            }
        }
    },[data,isSuccess,])
    const [updateSave] = useDijieTemplateFeiYongMingXiUpdateSaveMutation()
    return (
        <ModalEdit
            getOpen={(e) => {setOpen(prevsState=>e );}}
            onOk={() => {
                updateSave(info).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('修改成功！') 
                        setOpen(false)                                                 
                    }
                    if (res.data.Status === 'fail') {
                        message.error('修改失败！'+res.data.Message)
                    }
                })
            }}
            open={open}
            title='费用明细模板'
            width={800}
        >
            <Form
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 4 }}
                ref={editForm}
            >                                           
                <GetUserDept 
                    label='部门' 
                    required 
                    name='ltfymx_dept_id'
                    wrapperCol={{span:10}}
                    getUserDept={(e) => {setInfo(prevsState=>({...prevsState,ltfymx_dept_id:e}))}}
                />
                <Form.Item 
                    name="ltfymx_type_id" 
                    label='类型' 
                    wrapperCol={{span:20}} 
                    required 
                    onChange={(e) => {setInfo(prevsState=>({...prevsState,ltfymx_type_id:e.target.value}))}}
                >
                    <Radio.Group optionType="button" options={localCostType}></Radio.Group>
                </Form.Item>
                <Form.Item name="ltfymx_name" label='费用名称' wrapperCol={{span:20}} required>
                    <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,ltfymx_name:e.target.value}))}}/>
                </Form.Item>
                <Form.Item name="ltfymx_price" label='单价' wrapperCol={{span:20}} required>
                    <InputNumber onChange={(e) => {setInfo(prevsState=>({...prevsState,ltfymx_price:e}))}}/>
                </Form.Item>
                <Form.Item name="ltfymx_unit" label='单位' wrapperCol={{span:4}} required extra='例如：元/人，元/间房'>
                    <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,ltfymx_unit:e.target.value}))}}/>
                </Form.Item>
            </Form>
        </ModalEdit>
    )
}

export default PriceEdit