import { Flex, Form, Input, Modal, Rate, } from 'antd'
import React, { useState } from 'react'
const {TextArea} = Input
function FeedBackCheck() {    
    const [open, setOpen] = useState(false);   
    return (
        <>
            <a onClick={() => {
                setOpen(true);
                }} >意见单-123</a>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false)}}
                width={1200}
                footer={null}
            >
                <div style={{border:'2px solid #eee',margin:"10px"}}>
                    <Flex
                        justify='center' 
                        style={{fontSize:'30px',lineHeight:"88px",fontWeight:'bold'}}
                    >游客意见单</Flex>
                    <Form 
                        labelCol={{span:3}}
                    >
                        <Form.Item label='用餐质量'>
                            <Rate></Rate>
                        </Form.Item>
                        <Form.Item label='住宿质量'>
                            <Rate></Rate>
                        </Form.Item>
                        <Form.Item label='用餐质量'>
                            <Rate></Rate>
                        </Form.Item>
                        <Form.Item 
                            wrapperCol={{span:10}}
                            label='其他意见'
                        >
                            <TextArea
                            />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    )
}

export default FeedBackCheck