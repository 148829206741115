import React,{useEffect, useState} from 'react'
import { useIncomeExpenditureHxFinishLogListQuery} from '../../store/ReactWebSiteApi';
import { Modal,message,Form,DatePicker,Input} from 'antd';
import dayjs from 'dayjs';
import GetAccount from './GetAccount';
import { useCapitalAccountDetailAddMutation,useCapitalAccountUpdateQuery } from '../../store/ReactWebSiteApi';
import { useNavigate } from 'react-router-dom';
function FundAddSubmit(props) {
    //获取收支id
    const [open,setOpen] = useState();
    const {data,isSuccess} = useIncomeExpenditureHxFinishLogListQuery({eie_hx_number:props.eie_hx_number},{skip:!open,refetchOnMountOrArgChange:1})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                const arr = data.Result.map(item => item.eie_id)
                console.log(arr);
                setInfo(prevState=>({...prevState,ecal_eie_id:arr+''}))
            }
        }
    },[data,isSuccess])
    const [form] = Form.useForm();    
    const onCreate = (values) => {
        //  console.log('Received values of form: ', values);
         setOpen(false);
     };
    const [info,setInfo] = useState({
        ecal_uid:localStorage.getItem('eu_id'),
        ecal_uname:localStorage.getItem('eu_name'),
        ecal_bank_detail_date:dayjs().format('YYYY-MM-DD'),
        ecal_amount:props.eie_hx_amount,
        ecal_content:'',
    });     
    const [submitAdd] = useCapitalAccountDetailAddMutation();
    const navi = useNavigate()
    //获取账户最后一条记录日期
    const [lastDate,setLastDate] = useState()
    const {data:dataDate,isSuccess:isSuccessDate} = useCapitalAccountUpdateQuery({eca_id:info.ecal_eca_id},{refetchOnMountOrArgChange:1})
    useEffect(() => {
        if(isSuccessDate){
            if(dataDate.Status==='success'){
                setLastDate(dataDate.Result.bank_last_date);
                
            }
        }
    },[dataDate,isSuccessDate,navi])
    return (
        <>
            <a type='primary' onClick={() => {setOpen(true);}} style={props.style}>新增转账凭证</a>
            <Modal
                open={open}
                title='新增转账凭证'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    
                    form
                        .validateFields()
                        .then((values) => {
                            console.log(info);
                            // form.resetFields();
                            onCreate(values);
                            // console.log(values);                                          
                            submitAdd({
                                ecal_uid:localStorage.getItem('eu_id'),
                                ecal_uname:localStorage.getItem('eu_name'),
                                ecal_bank_detail_date:info.ecal_bank_detail_date,
                                ecal_amount:props.eie_hx_amount,
                                ecal_eca_id:info.ecal_eca_id,
                                ecal_content:info.ecal_content,
                                ecal_eie_id:info.ecal_eie_id,
                            }).then((res) => {
                                // console.log(res);
                                if (res.data.Status === 'success') {
                                    Modal.confirm({
                                        title: '新增成功，是否前往转账凭证？',
                                        content: '点击取消，留在本页',
                                        onOk:() => {navi(`/FundList?indexType=1&indexName=财务&esv_id=11&id=${info.ecal_eca_id}&name=${info.accountName}`)},                                                    
                                    }); 
                                    setOpen(false)                      
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })                                           
                            form.resetFields();                        
                        })
                        .catch((info) => {
                            // console.log('Validate Failed:', info);
                        });
                        
                       
                    }}
                >
                <Form
                    form={form}
                    layout="horizonal"
                    name="addForm"
                    initialValues={{modifier: 'public',sum:props.eie_hx_amount}}
                    labelCol={{ span: 3 }}
                >
                    <GetAccount 
                        label='资金帐户' 
                        name='account'
                        getAccount={(e,f) => {
                            setInfo(prevState=>({...prevState,ecal_eca_id:e,accountName:f}))
                        }}
                        wrapperCol={{span:"15"}}                     
                    />                    
                    <Form.Item label='转账日期' name='date'>
                        <DatePicker 
                            value={info.eie_year} 
                            onChange={(e) => {setInfo(prevState=>({...prevState,ecal_bank_detail_date:dayjs(e).format('YYYY-MM-DD')}))}}
                            minDate={lastDate?dayjs(lastDate):''}
                            maxDate={dayjs()}
                        />
                    </Form.Item>
                    <Form.Item label='金额' name='sum' wrapperCol={{span:"3"}}>
                        <Input disabled/>
                    </Form.Item>                            
                    <Form.Item label='备注' name='content' wrapperCol={{span:"15"}}>
                        <Input onChange={(e) => {setInfo(prevState=>({...prevState,ecal_content:e.target.value}))}}/>
                    </Form.Item>                            
                </Form>
            </Modal> 
        </>
    )
}

export default FundAddSubmit