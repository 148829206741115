import React from 'react'
import Main from '../../cpn/Main'
import LedgerStartdate from '../ledger/LedgerStartdate'

function TaFinanceLedgerDate() {
    return (
        <Main  t1='财务' n1='/TaFinanceIndex' t2='设置' n2='/TaFinanceSetIndex' t3='团队报账单出发日期'>
            <LedgerStartdate />
        </Main>
    )
}

export default TaFinanceLedgerDate