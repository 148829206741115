import React,{useState,useEffect,useRef}from 'react'
import { Table,Button,Space,Modal,message,Form,Radio,Input,} from 'antd';
import {  useNavigate, } from 'react-router-dom';
import { useCapitalAccountDelMutation, useCapitalAccountListQuery,useCapitalAccountAddMutation,useCapitalAccountUpdateQuery, useCapitalAccountUpdateSaveMutation } from '../../store/ReactWebSiteFinanceApi';
import MainKeeper from '../MainKeeper';
function BankAccount() {
    //新增账户-------------------------------------------------------------------------------------------------------------------------------------新增账户//
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateAdd = (values) => {
        console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [infoAdd,setInfoAdd] = useState({
        eca_type_id:'',
        eca_uid:localStorage.getItem('eu_id'),
        eca_uname:localStorage.getItem('eu_name'),
        eca_e_id:localStorage.getItem('e_id'),
        eca_bank_content:'',
    });
    const [AddData] = useCapitalAccountAddMutation()
    //修改账户-------------------------------------------------------------------------------------------------------------------------------------修改账户//
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useCapitalAccountUpdateQuery({eca_id:editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                editForm.current.setFieldsValue({
                    eca_type_id:dataEdit.Result.eca_type_id,
                    eca_bank_kh:dataEdit.Result.eca_bank_kh,
                    eca_bank_zh:dataEdit.Result.eca_bank_zh,
                    eca_bank_crad:dataEdit.Result.eca_bank_crad,
                    eca_bank_content:dataEdit.Result.eca_bank_content,
                    eca_wx_phone:dataEdit.Result.eca_wx_phone,
                    eca_wx_account:dataEdit.Result.eca_wx_account,
                    eca_wx_content:dataEdit.Result.eca_wx_content,
                    eca_alipay_phone:dataEdit.Result.eca_alipay_phone,
                    eca_alipay_account:dataEdit.Result.eca_alipay_account,
                    eca_alipay_content:dataEdit.Result.eca_alipay_content,
                })
                setInfoEdit(prevState=>({...prevState,
                    eca_type_id:dataEdit.Result.eca_type_id,
                    eca_bank_kh:dataEdit.Result.eca_bank_kh,
                    eca_bank_zh:dataEdit.Result.eca_bank_zh,
                    eca_bank_crad:dataEdit.Result.eca_bank_crad,
                    eca_bank_content:dataEdit.Result.eca_bank_content,
                    eca_wx_phone:dataEdit.Result.eca_wx_phone,
                    eca_wx_account:dataEdit.Result.eca_wx_account,
                    eca_wx_content:dataEdit.Result.eca_wx_content,
                    eca_alipay_phone:dataEdit.Result.eca_alipay_phone,
                    eca_alipay_account:dataEdit.Result.eca_alipay_account,
                    eca_alipay_content:dataEdit.Result.eca_alipay_content,              
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    const [infoEdit,setInfoEdit] = useState({
        eca_type_id:'',
        eca_uid:localStorage.getItem('eu_id'),
        eca_uname:localStorage.getItem('eu_name'),
        eca_e_id:localStorage.getItem('e_id'),
        eca_id:editId,
    });
    const [EditData] = useCapitalAccountUpdateSaveMutation()
    //删除账户-------------------------------------------------------------------------------------------------------------------------------------删除账户//
    const {confirm} = Modal; 
    const [delAccount] = useCapitalAccountDelMutation()
    //账户列表-------------------------------------------------------------------------------------------------------------------------------------账户列表//
    const {data, isSuccess} = useCapitalAccountListQuery({eca_e_id:localStorage.getItem('e_id')});
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if (isSuccess) {
            //console.log(data);
            if(data.Status==='success'){
                
                const newArr = data.Result.map(item => {
                    const {eca_id:key,eca_type_id,eca_name,eca_bank_kh,eca_bank_zh,eca_bank_crad,eca_ctime,eca_bank_content,eca_uname,eca_amount,finance_num,eca_wx_phone,eca_wx_account,eca_alipay_phone,eca_alipay_account} = item;
                    return item = {key,eca_type_id,eca_name,eca_bank_kh,eca_bank_zh,eca_bank_crad,eca_ctime,eca_bank_content,eca_uname,eca_amount,finance_num,eca_wx_phone,eca_wx_account,eca_alipay_phone,eca_alipay_account}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eca_ctime;
                    let bTimeString = b.eca_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    //获取参数
    const navi = useNavigate();
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'type',
            title: '账户类型',
            render:(_,record) => {
                if(record.eca_type_id==='0'){
                    return<>冲抵账户</>
                }else if(record.eca_type_id==='1'){
                    return<>银行账户</>
                }else if(record.eca_type_id==='2'){
                    return<>微信</>
                }else{
                    return<>支付宝</>
                }
            }
        },
        {
            key: 'client',
            title: '账户信息',
            render:(_,record) => {
                return<>
                    <div>{record.eca_bank_kh}</div>
                    <div>{record.eca_bank_zh}</div>
                    <div>{record.eca_bank_crad}</div>
                </>
            }
        },
        {
            key: 'status',
            title: '账户余额',
            dataIndex: 'eca_amount',
        },
        {
            key: 'finance_num',
            title: '明细笔数',
            dataIndex: 'finance_num',
        },
        {
            key: 'time',
            title: '创建/修改时间',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.eca_ctime + record.eca_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>
                        <a onClick={() => {setEditId(record.key);setOpenEdit(true);setInfoEdit(prevState=>({...prevState,eca_id:record.key}))}}>修改</a>
                        {record.finance_num ==='0' && <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    delAccount({ecal_eca_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>}
                        <a onClick={() => {
                            if(record.eca_type_id==='1' ||record.eca_type_id==='0' ){
                                navi(`/BankAccountDetail?id=${record.key}&name=${record.eca_bank_kh + '/' + record.eca_bank_zh + '/' +record.eca_bank_crad}`)
                            }else if(record.eca_type_id==='2'){
                                navi(`/BankAccountDetail?id=${record.key}&name=${record.eca_wx_phone + '/' + record.eca_wx_account}`)
                            }else{
                                navi(`/BankAccountDetail?id=${record.key}&name=${record.eca_alipay_phone + '/' + record.eca_alipay_account}`)
                            }
                        }}>账户明细</a>
                    </Space>
                </>
            }
        },
    ]

      
    return (
        <MainKeeper t1='支付管理' t2='账户管理' t3='账户列表' topActive='BankAccount'>
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true)}}>新增账户</Button>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table>
            {/* 新增账户         */}
             <Modal
                open={openAdd}
                title='新增账户'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenAdd(false);}}
                onOk={() => {
                    
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateAdd(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    AddData(infoAdd).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            setOpenAdd(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                >
                    <Form.Item name="info" label='账户类型' wrapperCol={{span:10}}>
                            <Radio.Group onChange={(e) => {setInfoAdd(prevState=>({...prevState,eca_type_id:e.target.value}))}}>
                                <Radio value='1'>银行账户</Radio>
                                <Radio value='2'>微信</Radio>
                                <Radio value='3'>支付宝</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {infoAdd.eca_type_id==='1' && <>
                        <Form.Item name="eca_bank_kh" label='开户行' wrapperCol={{span:15}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eca_bank_kh:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="eca_bank_zh" label='账户名称' wrapperCol={{span:15}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eca_bank_zh:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="eca_bank_crad" label='账号' wrapperCol={{span:15}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eca_bank_crad:e.target.value}))}} type='number'/>
                            </Form.Item>
                            <Form.Item name="eca_bank_content" label='备注' wrapperCol={{span:15}}>
                                <Input onChange={(e) => {setInfoAdd(prevState=>({...prevState,eca_bank_content:e.target.value}))}} />
                            </Form.Item>
                        </>}
                        {infoAdd.eca_type_id==='2' && <>
                            <Form.Item name="eca_wx_phone" label='手机号码' wrapperCol={{span:10}}>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eca_wx_phone:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="eca_wx_account" label='微信号' wrapperCol={{span:10}}>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eca_wx_account:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="eca_wx_content" label='备注' wrapperCol={{span:15}}>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eca_wx_content:e.target.value}))}}/>
                            </Form.Item>
                        </>}
                        {infoAdd.eca_type_id==='3' && <>
                            <Form.Item name="eca_alipay_phone" label='手机号码' wrapperCol={{span:10}}>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eca_alipay_phone:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="eca_alipay_account" label='支付宝账号' wrapperCol={{span:10}}>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eca_alipay_account:e.target.value}))}}/>
                            </Form.Item>                            
                            <Form.Item name="eca_alipay_content" label='备注' wrapperCol={{span:15}}>
                                <Input   onChange={(e) => {setInfoAdd(prevState=>({...prevState,eca_alipay_content:e.target.value}))}}/>
                            </Form.Item>
                        </>}
                </Form>
            </Modal>
             {/* 修改账户         */}
             <Modal
                open={openEdit}
                title='修改账户'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenEdit(false);}}
                onOk={() => {
                    
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateEdit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    EditData(infoEdit).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')   
                            setOpenEdit(false)                             
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                    ref={editForm}
                >
                    <Form.Item name="eca_type_id" label='账户类型' wrapperCol={{span:10}}>
                            <Radio.Group onChange={(e) => {setInfoEdit(prevState=>({...prevState,eca_type_id:e.target.value}))}}>
                                <Radio value='1'>银行账户</Radio>
                                <Radio value='2'>微信</Radio>
                                <Radio value='3'>支付宝</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {infoEdit.eca_type_id==='1' && <>
                            <Form.Item name="eca_bank_kh" label='开户行' wrapperCol={{span:15}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eca_bank_kh:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="eca_bank_zh" label='账户名称' wrapperCol={{span:15}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eca_bank_zh:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="eca_bank_crad" label='账号' wrapperCol={{span:15}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eca_bank_crad:e.target.value}))}} type='number'/>
                            </Form.Item>
                            <Form.Item name="eca_bank_content" label='备注' wrapperCol={{span:15}}>
                                <Input onChange={(e) => {setInfoEdit(prevState=>({...prevState,eca_bank_content:e.target.value}))}} />
                            </Form.Item>
                        </>}
                        {infoEdit.eca_type_id==='2' && <>
                            <Form.Item name="eca_wx_phone" label='手机号码' wrapperCol={{span:10}}>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eca_wx_phone:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="eca_wx_account" label='微信号' wrapperCol={{span:10}}>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eca_wx_account:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="eca_wx_content" label='备注' wrapperCol={{span:15}}>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eca_wx_content:e.target.value}))}}/>
                            </Form.Item>
                        </>}
                        {infoEdit.eca_type_id==='3' && <>
                            <Form.Item name="eca_alipay_phone" label='手机号码' wrapperCol={{span:10}}>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eca_alipay_phone:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="eca_alipay_account" label='支付宝账号' wrapperCol={{span:10}}>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eca_alipay_account:e.target.value}))}}/>
                            </Form.Item>                            
                            <Form.Item name="eca_alipay_content" label='备注' wrapperCol={{span:15}}>
                                <Input   onChange={(e) => {setInfoEdit(prevState=>({...prevState,eca_alipay_content:e.target.value}))}}/>
                            </Form.Item>
                        </>}
                </Form>
            </Modal> 
        </MainKeeper>
    )
}

export default BankAccount