import React,{useState,useEffect,useRef}from 'react'
import { Table,Button,Space,Modal,message,Form,InputNumber,Input, Cascader} from 'antd';
import { useSearchParams, } from 'react-router-dom';
import { useYfykAddMutation, useYfykDelMutation, useYfykListQuery, useYfykUpdateQuery, useYfykUpdateSaveMutation } from '../store/ReactWebSiteFinanceApi';
import Main from '../cpn/Main'
const { TextArea } = Input;
//应发应扣项目
const options = [
    {
        value:'1',
        label:'应发',
        children:[
            {
                value:'11',
                label:'销售提成',
            },
            {
                value:'12',
                label:'加班费',
            },
            {
                value:'13',
                label:'全勤奖',
            },
        ]
    },
    {
        value:'2',
        label:'应扣',
        children:[
            {
                value:'11',
                label:'社保个人部分',
            },
            {
                value:'12',
                label:'违纪扣款',
            },
            {
                value:'13',
                label:'事假',
            },
        ]
    },

]
function WageDetail() {
    const [param] = useSearchParams();
    //新增应发应扣-------------------------------------------------------------------------------------新增应发应扣//
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const [infoAdd,setInfoAdd] = useState({ 
        ews_ew_id:param.get('id'),
        ews_uid:localStorage.getItem('eu_id'),
        ews_uname:localStorage.getItem('eu_name'),
    });
    const onCreateAdd = (values) => {
        console.log('Received values of form: ', values);
        yfykAdd(infoAdd).then((res) => {
            if (res.data.Status === 'success') {
                message.info('新增成功！');
            }
            if (res.data.Status === "fail") {
                message.error('新增失败！'+res.data.Message)
            }
        })
        setOpenAdd(false);
    };
    const [yfykAdd] = useYfykAddMutation();
    //修改应发应扣-------------------------------------------------------------------------------------修改应发应扣//
    const editForm = useRef()
    const [openEdit, setOpenEdit] = useState(false);
    const [infoEdit, setInfoEdit] = useState({
        ews_ew_id:param.get('id'),
        ews_update_uid:localStorage.getItem('eu_id'),
        ews_update_uname:localStorage.getItem('eu_name'),
    })
    const [editId,setEditId] = useState();
    const {data:dataEdit,isSuccess:isSuccessEdit} = useYfykUpdateQuery({ews_id:editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    type:[dataEdit.Result.ews_main_id,dataEdit.Result.ews_sub_id],
                    ews_amount:dataEdit.Result.ews_amount,
                    ews_content:dataEdit.Result.ews_content,
                })
                setInfoEdit(prevState=>({...prevState,
                    ews_amount:dataEdit.Result.ews_amount,
                    ews_content:dataEdit.Result.ews_content,
                    ews_main_id:dataEdit.Result.ews_main_id,
                    ews_main_name:dataEdit.Result.ews_main_name,
                    ews_sub_id:dataEdit.Result.ews_sub_id,
                    ews_sub_name:dataEdit.Result.ews_sub_name,                    
                }))
            }
        }
    },[dataEdit, isSuccessEdit])
    const [yfykUpdateSave] = useYfykUpdateSaveMutation()
    const onCreateEdit = () => {
        console.log(infoEdit);
        yfykUpdateSave(infoEdit).then((res) => {
            console.log(res);
            if (res.data.Status === 'success') {
                message.success('修改成功！')   
                setOpenEdit(false)                             
            }
            if (res.data.Status === 'fail') {
                message.error('修改失败！'+res.data.Message)
            }
        })
    }
    //删除应发应扣---------------------------------------------------------------------------------------------------------------删除应发应扣//
    const {confirm} = Modal; 
    const [yfykDel] = useYfykDelMutation()
    //应发应扣列表---------------------------------------------------------------------------------------------------应发应扣列表//
    const {data,isSuccess} = useYfykListQuery({ews_ew_id:param.get('id')});
    const [list,setList] = useState();
    
    useEffect(() => {
        if (isSuccess) {
            //console.log(data);
            if(data.Status==='success'){
                
                const newArr = data.Result.map(item => {
                    const {ews_id:key,ews_main_name,ews_main_id,ews_sub_name,ews_amount,ews_content,ews_uname,ews_ctime,finance_num} = item;
                    return item = {key,ews_main_name,ews_main_id,ews_sub_name,ews_amount,ews_content,ews_uname,ews_ctime,finance_num}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    if(a.ews_main_id==='1'){
                        let aTimeString = a.ews_ctime;
                        let bTimeString = b.ews_ctime;
                        let aTime = new Date(aTimeString).getTime();
                        let bTime = new Date(bTimeString).getTime();
                        return bTime - aTime;     
                    }else{
                        let aTimeString = a.ews_ctime;
                        let bTimeString = b.ews_ctime;
                        let aTime = new Date(aTimeString).getTime();
                        let bTime = new Date(bTimeString).getTime();
                        return bTime - aTime;  
                    }
                })
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess]) 
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'room',
            title: '类型',            
            dataIndex: 'ews_main_name',
            render:(text,record) => {
                if(record.ews_main_id==='1'){
                    return<span style={{color:'red'}}>{text}</span>
                }else{
                    return<span style={{color:'green'}}>{text}</span>
                }
            }
        },
        {
            key: 'ews_sub_name',
            title: '子类型',            
            dataIndex: 'ews_sub_name',
        },
        {
            key: 'type',
            title: '金额',            
            dataIndex: 'ews_amount',
            render:(text,record) => {
                if(record.ews_main_id==='1'){
                    return<span style={{color:'red'}}>{text}</span>
                }else{
                    return<span style={{color:'green'}}>{text}</span>
                }
            }
        },            
        {
            key: 'dayPlan',
            title: '备注',            
            dataIndex: 'ews_content',
        },        
        {
            key: 'time',
            title: '创建/修改时间',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.ews_ctime + record.ews_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>
                        <a onClick={() => {
                            setEditId(record.key);
                            setOpenEdit(true)
                            setInfoEdit(prevState=>({...prevState,ews_id:record.key}))
                        }}>修改</a>
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    yfykDel({ews_id:record.key,ews_ew_id:param.get('id')}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>
                    </Space>
                </>
            }
        },
    ]
    return (
        <Main t1='工资条' t2={param.get('name')+param.get('month')} t3='应发应扣' topActive='Wage' app='wage'>
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true)}}>新增应发应扣</Button>
                <span style={{marginLeft:'20px'}}>{param.get('name') + ' ： ' +  param.get('month')}</span>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table>
            {/* 新增应发应扣        ------------------------------------------------------------------------------------------------------------*/}
            <Modal
                open={openAdd}
                title='新增应发应扣'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenAdd(false);}}
                onOk={() => {
                    
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateAdd(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 7 }}
                >
                    <Form.Item name="content" label='类型' wrapperCol={{span:15}} required>
                        <Cascader 
                            options={options} 
                            onChange={(e,f) => {
                                setInfoAdd(prevState=>({
                                    ...prevState,
                                    ews_main_id:f[0].value,
                                    ews_main_name:f[0].label,
                                    ews_sub_id:f[1].value,
                                    ews_sub_name:f[1].label,
                                }))}}
                        />
                    </Form.Item>                
                    <Form.Item name="ews_amount" label='金额' wrapperCol={{span:15}} required>
                        <InputNumber onChange={(e) => {setInfoAdd(prevState=>({...prevState,ews_amount:e}))}}/>
                    </Form.Item>
                    <Form.Item name="remark" label='备注' wrapperCol={{span:15}}>
                        <TextArea rows={4} onChange={(e) => {setInfoAdd(prevState=>({...prevState,ews_content:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>           
            {/* 修改应发应扣        ------------------------------------------------------------------------------------------------------------修改应发应扣*/}
            <Modal
                open={openEdit}
                title='修改应发应扣'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenEdit(false);}}
                onOk={() => {
                    
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateEdit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    ref={editForm}
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 7 }}
                >
                    <Form.Item name="type" label='类型' wrapperCol={{span:15}} required>
                        <Cascader 
                            options={options} 
                            onChange={(e,f) => {
                                setInfoEdit(prevState=>({
                                    ...prevState,
                                    ews_main_id:f[0].value,
                                    ews_main_name:f[0].label,
                                    ews_sub_id:f[1].value,
                                    ews_sub_name:f[1].label,
                                }))}}
                        />
                    </Form.Item>                
                    <Form.Item name="ews_amount" label='金额' wrapperCol={{span:15}} required>
                        <InputNumber onChange={(e) => {setInfoEdit(prevState=>({...prevState,ews_amount:e}))}}/>
                    </Form.Item>
                    <Form.Item name="ews_content" label='备注' wrapperCol={{span:15}}>
                        <TextArea rows={4} onChange={(e) => {setInfoEdit(prevState=>({...prevState,ews_content:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>           
        </Main>
    )
}

export default WageDetail