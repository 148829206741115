import React,{useState,useEffect,}from 'react'
import { Table,Image} from 'antd';
import ImagePreview from '../../cpn/ImagePreview'
import { useSearchParams } from 'react-router-dom';
import MainKeeper from '../MainKeeper';
import { useGetWarehouseQuery } from '../../store/ReactWebSiteFinanceApi';
function WarehouseSupplies() {
    const [param] = useSearchParams();
    //物品列表-----------------------------------------------------------------------------------------------------------------------------物品列表
    
    const {data,isSuccess} = useGetWarehouseQuery({eiw_ewi_id:param.get('id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {eiw_ep_id:key,eiw_ep_name,eiw_ep_num,eiw_ep_content,eiw_ep_uname,eiw_ep_ctime,eiw_ep_image_name,eiw_ep_update_ctime,eiw_ep_update_uname,kc_num,log_num} = item;
                    return item = {key,eiw_ep_name,eiw_ep_num,eiw_ep_content,eiw_ep_uname,eiw_ep_ctime,eiw_ep_image_name,eiw_ep_update_ctime,eiw_ep_update_uname,kc_num,log_num}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eiw_ep_ctime;
                    let bTimeString = b.eiw_ep_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])    
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },       
        {
            key: 'pic',
            title: '图片',
            dataIndex: 'eiw_ep_image_name',
            render: (text) => {
                let newArr
                if (text) {
                    newArr = text.map((item,index) => {
                        return <Image src={`/api/assets/images/application/${item}`} key={index}  />
                    })
                    return <ImagePreview
                        firstPic={`/api/assets/images/application/${text[0]}`}
                        pics={newArr}
                    />
                }
            }
        },
        {
            key: 'room',
            title: '物品名称',
            dataIndex: 'eiw_ep_name',
        },
        {
            key: 'kc_num',
            title: '库存数',
            dataIndex: 'kc_num',
        },
        {
            key: 'log_num',
            title: '库存价值',
            dataIndex: 'log_num',
        },
        {
            key: 'num',
            title: '备注',
            dataIndex: 'eiw_ep_content',
        },        
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.eiw_ep_ctime + record.eiw_ep_uname}</div>
                    <div>修改时间：{record.eiw_ep_update_ctime + record.eiw_ep_update_uname}</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    
                </>
            }
        },
    ]
    
    return (
        <MainKeeper t1='物资管理' t2='库房管理' t3={param.get('name')} t4='物品列表' >            
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
        </MainKeeper>
    )
}

export default WarehouseSupplies