import React,{useState,}from 'react'
import { Button,Modal,message,Form,Radio,Input, InputNumber,} from 'antd';
import { useCapitalAccountAddMutation,} from '../../store/ReactWebSiteApi';
function FundAccountAdd() {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreate = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({
        eca_type_id:'1',
        eca_uid:localStorage.getItem('eu_id'),
        eca_uname:localStorage.getItem('eu_name'),
        eca_e_id:localStorage.getItem('e_id'),
        eca_bank_content:'',
        eca_wx_content:'',
        eca_alipay_content:'',
        eca_amount:'',
        eca_alipay_account:'',
        eca_wx_account:'',
        eca_wx_phone:'',
        eca_alipay_phone:'',
    });
    const [AddData] = useCapitalAccountAddMutation()
    return (
        <>
            <Button type='primary' onClick={() => {setOpen(true)}}>新增</Button>
            <Modal
                open={open}
                title='新增支付账户'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {                    
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    AddData({
                        eca_type_id:info.eca_type_id,
                        eca_bank_kh:info.eca_bank_kh,
                        eca_bank_content:info.eca_bank_content,
                        eca_alipay_account:info.eca_alipay_account,
                        eca_alipay_content:info.eca_alipay_content,
                        eca_alipay_phone:info.eca_alipay_phone,
                        eca_amount:info.eca_amount,
                        eca_bank_crad: info.eca_bank_crad,
                        eca_bank_zh: info.eca_bank_zh,
                        eca_e_id: localStorage.getItem('e_id'),
                        eca_uid: localStorage.getItem('eu_id'),
                        eca_uname: localStorage.getItem('eu_name'),
                        eca_wx_account: info.eca_wx_account,
                        eca_wx_content:info.eca_wx_content, 
                        eca_wx_phone: info.eca_wx_phone,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                >
                    <Form.Item name="info" label='账户类型' wrapperCol={{span:15}} initialValue='1'>
                        <Radio.Group 
                            onChange={(e) => {                               
                                setInfo(prevState=>({...prevState,eca_type_id:e.target.value,}))
                            }} 
                            initialValues='1'
                            buttonStyle='solid'
                        >
                            <Radio value='1'>银行账户</Radio>
                            <Radio value='2'>微信</Radio>
                            <Radio value='3'>支付宝</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {info.eca_type_id==='1' && <>
                    <Form.Item name="eca_bank_kh" label='开户行' wrapperCol={{span:15}} required>
                            <Input  onChange={(e) => {setInfo(prevState=>({...prevState,eca_bank_kh:e.target.value}))}}/>
                        </Form.Item>
                        <Form.Item name="eca_bank_zh" label='账户名称' wrapperCol={{span:15}} required>
                            <Input  onChange={(e) => {setInfo(prevState=>({...prevState,eca_bank_zh:e.target.value}))}}/>
                        </Form.Item>
                        <Form.Item name="eca_bank_crad" label='账号' wrapperCol={{span:15}} required>
                            <Input  onChange={(e) => {setInfo(prevState=>({...prevState,eca_bank_crad:e.target.value}))}} />
                        </Form.Item>
                        <Form.Item name="eca_amount" label='账户余额' wrapperCol={{span:15}} required>
                            <InputNumber min={0.00}  onChange={(e) => {setInfo(prevState=>({...prevState,eca_amount:e}))}}/>
                        </Form.Item>
                        <Form.Item name="eca_bank_content" label='备注' wrapperCol={{span:15}} >
                            <Input  onChange={(e) => {setInfo(prevState=>({...prevState,eca_bank_content:e.target.value}))}} />
                        </Form.Item>
                    </>}
                    {info.eca_type_id==='2' && <>
                        <Form.Item name="eca_wx_phone" label='手机号码' wrapperCol={{span:10}} required>
                            <Input  onChange={(e) => {setInfo(prevState=>({...prevState,eca_wx_phone:e.target.value}))}}/>
                        </Form.Item>
                        <Form.Item name="eca_wx_account" label='微信号' wrapperCol={{span:10}} required>
                            <Input  onChange={(e) => {setInfo(prevState=>({...prevState,eca_wx_account:e.target.value}))}}/>
                        </Form.Item>
                        <Form.Item name="eca_amount" label='账户余额' wrapperCol={{span:15}} required>
                            <InputNumber min={0.00}  onChange={(e) => {setInfo(prevState=>({...prevState,eca_amount:e}))}} />
                        </Form.Item>
                        <Form.Item name="eca_wx_content" label='备注' wrapperCol={{span:15}} >
                            <Input  onChange={(e) => {setInfo(prevState=>({...prevState,eca_wx_content:e.target.value}))}} />
                        </Form.Item>
                    </>}
                    {info.eca_type_id==='3' && <>
                        <Form.Item name="eca_alipay_phone" label='手机号码' wrapperCol={{span:10}} required>
                            <Input  onChange={(e) => {setInfo(prevState=>({...prevState,eca_alipay_phone:e.target.value}))}}/>
                        </Form.Item>
                        <Form.Item name="eca_alipay_account" label='支付宝账号' wrapperCol={{span:10}} required>
                            <Input  onChange={(e) => {setInfo(prevState=>({...prevState,eca_alipay_account:e.target.value}))}}/>
                        </Form.Item>
                        <Form.Item name="eca_amount" label='账户余额' wrapperCol={{span:15}} required>
                            <InputNumber min={0.00}   onChange={(e) => {setInfo(prevState=>({...prevState,eca_amount:e}))}} />
                        </Form.Item>
                        <Form.Item name="eca_alipay_content" label='备注' wrapperCol={{span:15}} >
                            <Input  onChange={(e) => {setInfo(prevState=>({...prevState,eca_alipay_content:e.target.value}))}} />
                        </Form.Item>  
                    </>}
                </Form>
            </Modal>
        </>
    )
}

export default FundAccountAdd