import React from 'react'
import { Form } from 'antd'
import GetType from './GetType';
import ModalQuery from '../../cpn/ModalQuery';
function WebGoodsQuery(props) {
    const [form] = Form.useForm();   
 
    return (
        <ModalQuery title='产品'>
            <Form
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 3 }}
            >               
                <GetType
                    label='类型'
                    name='eowg_type_id' 
                    subname='eowg_sub_type_id'
                    getType={(e,f) => {
                        
                    }}
                />                  
            </Form>
        </ModalQuery>
    )
}

export default WebGoodsQuery