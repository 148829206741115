import React from 'react'
import Main from '../../../../../cpn/Main'
import Crowd from './Crowd'
function CustomCrowd() {
    return (
        <Main t1='定制游' n1='/CustomIndex' t2='模板' n2='/CustomTempIndex' t3='人群类型'>
            <Crowd />
        </Main>
    )
}

export default CustomCrowd