import React, { useState,useEffect,useRef } from 'react'
import Main from '../cpn/Main'
import { useSearchParams, } from 'react-router-dom'
import { Form,Modal,message,Input,Space,Table, Select, Button} from 'antd'
import { useEntEvaluatePointAddMutation, useEntEvaluatePointDelMutation, useEntEvaluatePointListQuery, useEntEvaluatePointUpdateQuery, useEntEvaluatePointUpdateSaveMutation, useEvaluatePointListQuery,  useTourGuideListQuery, } from '../store/ReactWebSiteApi'
import GetStudyEvaluateType from './GetStudyEvaluateType'
function EvaluateGroupPoint() {
    const [form] = Form.useForm();
    const [param] = useSearchParams()   
    const [info,setInfo] = useState({et_id:'',eep_content:''})    
    const [point,setPoint] = useState()
    //获取评价点列表 
    
    const {data:dataInfo,isSuccess:isSuccessInfo} = useEvaluatePointListQuery({et_id:info.eep_et_id,},{refetchOnMountOrArgChange:"1"})
    useEffect(() => {
        if(isSuccessInfo){
            if(dataInfo.Status==='success'){
                console.log(dataInfo);
                const arr = dataInfo.Result.map((item) => {
                    const {ep_id,ep_name} = item;
                    return {value:ep_id,label:ep_name}
                })
                setPoint(arr)
            }
        }

    },[dataInfo,isSuccessInfo])
    //获取导游列表
    const [guide,setGuide] = useState();
    const {data:dataGuide,isSuccess:isSuccessGuide} = useTourGuideListQuery()
    useEffect(() => {
        if(isSuccessGuide){
            if(dataGuide.Status==='success'){
                console.log(dataGuide);
                const arr = dataGuide.Result.map((item) => {
                    const {tgu_id,tgu_name,} = item;
                    return {value:tgu_id,label:tgu_name}
                })
                setGuide(arr)
            }
        }

    },[dataGuide,isSuccessGuide])
    //新增评价点-----------------------------------------------------------------------------------------------------------------------------新增评价点
    const [openAddGroup, setOpenAddGroup] = useState(false);
    const onCreateClientGroup = (values) => {
        console.log('Received values of form: ', values);
        setOpenAddGroup(false);
    };

    const [submitAddGroup] = useEntEvaluatePointAddMutation()
    const modalAddGroup= <Modal
                        open={openAddGroup}
                        title='新增评价点'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAddGroup(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClientGroup(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitAddGroup({
                                eep_e_id:localStorage.getItem('e_id'),
                                eep_ebn_id:param.get('id'),
                                eep_et_id:info.eep_et_id,
                                eep_et_name:info.eep_et_name,
                                eep_ep_id:info.eep_ep_id,
                                eep_ep_name:info.eep_ep_name,
                                eep_content:info.eep_content,
                                eep_cid:localStorage.getItem('eu_id'),
                                eep_cname:localStorage.getItem('eu_name'),
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpenAddGroup(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                        >                            
                            <Form.Item name="type" label='服务类型' wrapperCol={{span:19}} required>
                                <GetStudyEvaluateType getStudyEvaluateType={(e,f) => {setInfo(prevState=>({...prevState,eep_et_id:e,eep_et_name:f}))}} />
                            </Form.Item>
                            {info.eep_et_id==='5'?<Form.Item name="name" label='导游名称' wrapperCol={{span:19}} required>
                                <Select  onChange={(e,f) => {
                                    setInfo(prevState=>({...prevState,eep_ep_id:e,eep_ep_name:f.label}));
                                    console.log(e,f);
                                    }} options={guide}/>
                            </Form.Item>:<Form.Item name="name" label='服务名称' wrapperCol={{span:19}} required>
                                <Select  onChange={(e,f) => {
                                    setInfo(prevState=>({...prevState,eep_ep_id:e,eep_ep_name:f.label}));
                                    console.log(e,f);
                                    }} options={point}/>
                            </Form.Item>}
                            <Form.Item name="content" label='备注' wrapperCol={{span:19}} required>
                                <Input onChange={(e) => {setInfo(prevState=>({...prevState,eep_content:e.target.value}))}} />
                            </Form.Item>
                        </Form>
                    </Modal> 
    //修改评价点-----------------------------------------------------------------------------------------------------------------------------修改评价点
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    const [infoEdit,setInfoEdit] = useState({eep_content:''});
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useEntEvaluatePointUpdateQuery({eep_id:editId},{skip:!editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    eep_ep_name:dataEdit.Result.eep_ep_name,
                    eep_content:dataEdit.Result.eep_content,
                })
                setInfoEdit(prevState=>({...prevState,
                    eep_content:dataEdit.Result.eep_content,              
                    eep_et_id:dataEdit.Result.eep_et_id,              
                    eep_et_name:dataEdit.Result.eep_et_name,              
                    eep_ep_id:dataEdit.Result.eep_ep_id,              
                    eep_ep_name:dataEdit.Result.eep_ep_name,              
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useEntEvaluatePointUpdateSaveMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改评价点'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitUpdateSave({
                                eep_e_id:localStorage.getItem('e_id'),
                                eep_ebn_id:param.get('id'),
                                eep_et_id:infoEdit.eep_et_id,
                                eep_et_name:infoEdit.eep_et_name,
                                eep_ep_id:infoEdit.eep_ep_id,
                                eep_ep_name:infoEdit.eep_ep_name,
                                eep_content:infoEdit.eep_content,
                                eep_uid:localStorage.getItem('eu_id'),
                                eep_uname:localStorage.getItem('eu_name'),
                                eep_id:editId,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >
                            <Form.Item name="eep_ep_name" label='评价点名称' wrapperCol={{span:19}} required>
                                <Input />
                            </Form.Item>
                            <Form.Item name="eep_content" label='备注' wrapperCol={{span:19}} required>
                                <Input onChange={(e) => {setInfoEdit(prevState=>({...prevState,eep_content:e.target.value}))}} />
                            </Form.Item>
                        </Form>
                    </Modal>
    //删除评价点-----------------------------------------------------------------------------------------------------------------------------删除评价点
    const {confirm} = Modal; 
    const [submitDel] = useEntEvaluatePointDelMutation()
    
    //评价点列表-----------------------------------------------------------------------------------------------------------------------------评价点列表
    
    const {data,isSuccess} = useEntEvaluatePointListQuery({eep_e_id:localStorage.getItem('e_id'),eep_ebn_id:param.get('id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){     
            //console.log(data);       
            if(data.Status==='success'){                
                
                const newArr = data.Result.map(item => {
                    const {eep_id:key,eep_ep_name,eep_et_name,eep_uname,eep_ctime,eep_content,} = item;
                    return item = {key,eep_ep_name,eep_et_name,eep_uname,eep_ctime,eep_content}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eep_ctime;
                    let bTimeString = b.eep_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])    
    const columns = [                                                 
        {
            key: 'image_num',
            title: '评价点',
            dataIndex:'eep_ep_name',
        },      
        {
            key: 'eep_content',
            title: '备注',
            dataIndex:'eep_content',
        },      
        {
            key: 'type',
            title: '类型',
            dataIndex: 'eep_et_name',
        },                                    
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <Space >                        
                        <a onClick={() => {
                            setEditId(record.key);
                            setOpenEdit(true)
                        }}>修改</a>
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({eep_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>                        
                    </Space>
            }
        },
    ]

    

    return (
        <Main t1='团队列表' n1='/EvaluateGroup?esv_id=25' t2={`团号${param.get('ebn_team_num')}`} t3='评价点'>
            <Space>
                <Button onClick={() => {setOpenAddGroup(true)}} type='primary'>新增</Button>
            </Space> 
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list} />
            {modalAddGroup}
            {modalEdit}            
        </Main>
    )
}

export default EvaluateGroupPoint