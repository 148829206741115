import '@wangeditor/editor/dist/css/style.css' // 引入 css

import React, { useState, useEffect } from 'react'
import { Editor, Toolbar,} from '@wangeditor/editor-for-react'

function MyEditor(props) {
    // editor 实例
    const [editor, setEditor] = useState(null)                   // JS 语法
    // 工具栏配置
    const toolbarConfig = { }                        // JS 语法
    // 编辑器配置
    const editorConfig = {                         // JS 语法
        placeholder: '请输入内容...',
        MENU_CONF: {}
    }
    // 及时销毁 editor ，重要！
    useEffect(() => {
        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        }
    }, [editor])
    
    editorConfig.MENU_CONF['uploadImage'] = {
        server: '/api/ReactApi/zd_single_upload_image',
        fieldName: 'image_file',
        // onSuccess(file, res) {          // JS 语法
        //     console.log(`${file.name} 上传成功`, res)
        // },
        // onFailed(file, res) {           // JS 语法
        //     console.log(`${file.name} 上传失败`, res)
        // },
        customInsert(res,insertFn) {  // TS 语法
            // customInsert(res, insertFn) {                  // JS 语法
                // res 即服务端的返回结果
        
                // 从 res 中找到 url alt href ，然后插入图片
                insertFn(`/api/assets/images/application/${res.Result}`)
            },
    }
    return (
        <>
            <div style={{ border: '1px solid #ccc', zIndex: 100}}>
                <Toolbar
                    editor={editor}
                    defaultConfig={toolbarConfig}
                    mode="default"
                    style={{ borderBottom: '1px solid #ccc' }}
                />
                <Editor
                    defaultConfig={editorConfig}
                    value={props.value}
                    onCreated={setEditor}
                    onChange={(e) => {props.onChange(e.getHtml())}}
                    mode="default"
                    style={{ height: '500px', overflowY: 'hidden'}}
                />
            </div>
        </>
    )
}

export default MyEditor