import { Form, Modal, Space } from 'antd';
import React, { useState } from 'react'
import Guide from './Guide';

function GuideForm(props) {
    const [open,setOpen] = useState(false)    
    return (
        <Form.Item
            label='导游'                    
            required={props.required}                   
        >
            <Space align='center'>
                <div>{props.tgu_id + ' - ' + props.tgu_name}</div>                     
                <a onClick={() => {setOpen(true)}}>请选择</a>
                <Modal
                    open={open}
                    okText="提交"
                    cancelText="取消"
                    onCancel={() => {setOpen(false);}}
                    onOk={() => {}}
                    footer={null}
                    width={800}
                    title='选择导游'
                >
                    <Guide 
                        type='select'
                        getGuide={(e) => {
                            props.getGuide({
                                tgu_id:e.tgu_id,
                                tgu_name:e.tgu_name,
                            })                            
                        }}
                        getOpen={(e) => {setOpen(false)}}
                    />
                </Modal> 
            </Space>
        </Form.Item>   
    )
}

export default GuideForm