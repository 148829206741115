import React,{useState,}from 'react'
import { Modal,message,Form,Input,Button,Space} from 'antd';
import { useFinanceSubTypeAddMutation,  } from '../../store/ReactWebSiteApi';
import GetUserDept from '../../cpn/GetUserDept';
function BalanceTypeAdd(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState();
    const [submitAdd] = useFinanceSubTypeAddMutation()
    return (
        <>
            <Button type='primary' onClick={() => {setOpen(true)}}>新增</Button>
            <Modal
                open={open}
                title={`新增${props.title}`}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });                            
                    submitAdd({
                        efst_e_id:localStorage.getItem('e_id'),
                        efst_finance_type_id:props.efst_finance_type_id,
                        efst_name:info.efst_name,                               
                        efst_uid:localStorage.getItem('eu_id'),
                        efst_uname:localStorage.getItem('eu_name'),
                        efst_content:'',
                        efst_dept_id:info.efst_dept_id,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！') 
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',type:props.type_id}}
                    labelCol={{ span: 7 }}
                >                            
                    {/* <Form.Item name="type" label='主类型' wrapperCol={{span:10}} required>
                        <Select options={balanceType} onChange={(e,f) => {setInfo(prevState=>({...prevState,typeName:f.label,efst_finance_type_id:e}))}} disabled />
                    </Form.Item> */}
                    {props.type_id==='1'&&<Form.Item label='常用类型' name='use' wrapperCol={{span:17}}>
                        <Space wrap>
                            <a onClick={() => {
                                form.setFieldsValue({name:'团款'})
                                setInfo('团款')
                                }}
                            >团款</a>
                            <a onClick={() => {
                                form.setFieldsValue({name:'代收款'})
                                setInfo('代收款')
                                }}
                            >代收款</a>                                                
                            <a  onClick={() => {
                                form.setFieldsValue({name:'景区返利'})
                                setInfo('景区返利')
                                }}
                            >景区返利</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'导游返点'})
                                setInfo('导游返点')
                                }}
                            >导游返点</a>                            
                        </Space>
                    </Form.Item>}
                    {props.type_id==='2'&&<Form.Item label='常用类型' name='use' wrapperCol={{span:17}}>
                        <Space wrap>
                            <a onClick={() => {
                                form.setFieldsValue({name:'门票费'})
                                setInfo('门票费')
                                }}
                            >门票费</a>
                            <a onClick={() => {
                                form.setFieldsValue({name:'住宿费'})
                                setInfo('住宿费')
                                }}
                            >住宿费</a>                                                
                            <a  onClick={() => {
                                form.setFieldsValue({name:'交通费'})
                                setInfo('交通费')
                                }}
                            >交通费</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'包车费'})
                                setInfo('包车费')
                                }}
                            >包车费</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'餐费'})
                                setInfo('餐费')
                                }}
                            >餐费</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'导游费'})
                                setInfo('导游费')
                                }}
                            >导游费</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'保险费'})
                                setInfo('保险费')
                                }}
                            >保险费</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'杂费'})
                                setInfo('杂费')
                                }}
                            >杂费</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'飞机票'})
                                setInfo('飞机票')
                                }}
                            >飞机票</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'火车票'})
                                setInfo('火车票')
                                }}
                            >火车票</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'船票'})
                                setInfo('船票')
                                }}
                            >船票</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'签证费'})
                                setInfo('签证费')
                                }}
                            >签证费</a>                            
                        </Space>
                    </Form.Item>}
                    {props.type_id==='3'&&<Form.Item label='常用类型' name='use' wrapperCol={{span:17}}>
                        <Space wrap>
                            <a onClick={() => {
                                form.setFieldsValue({name:'电话费'})
                                setInfo('电话费')
                                }}
                            >电话费</a>
                            <a onClick={() => {
                                form.setFieldsValue({name:'房租'})
                                setInfo('房租')
                                }}
                            >房租</a>                                                
                            <a  onClick={() => {
                                form.setFieldsValue({name:'工资'})
                                setInfo('工资')
                                }}
                            >工资</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'社保'})
                                setInfo('社保')
                                }}
                            >社保</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'水电费'})
                                setInfo('水电费')
                                }}
                            >水电费</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'物业费'})
                                setInfo('物业费')
                                }}
                            >物业费</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'招待费'})
                                setInfo('招待费')
                                }}
                            >招待费</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'杂费'})
                                setInfo('杂费')
                                }}
                            >杂费</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'办公用品'})
                                setInfo('办公用品')
                                }}
                            >办公用品</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'员工福利'})
                                setInfo('员工福利')
                                }}
                            >员工福利</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'税金'})
                                setInfo('税金')
                                }}
                            >税金</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'银行账户管理费'})
                                setInfo('银行账户管理费')
                                }}
                            >银行账户管理费</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'转账手续费'})
                                setInfo('转账手续费')
                                }}
                            >转账手续费</a>                            
                        </Space>
                    </Form.Item>}
                    {props.type_id==='4'&&<Form.Item label='常用类型' name='use' wrapperCol={{span:17}}>
                        <Space wrap>
                            <a onClick={() => {
                                form.setFieldsValue({name:'导游还款收入'})
                                setInfo('导游还款收入')
                                }}
                            >导游还款收入</a>
                            <a onClick={() => {
                                form.setFieldsValue({name:'股东增资收入'})
                                setInfo('股东增资收入')
                                }}
                            >股东增资收入</a>                                                
                            <a  onClick={() => {
                                form.setFieldsValue({name:'银行贷款收入'})
                                setInfo('银行贷款收入')
                                }}
                            >银行贷款收入</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'借款收入'})
                                setInfo('借款收入')
                                }}
                            >借款收入</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'代收款'})
                                setInfo('代收款')
                                }}
                            >代收款</a>                                                                                
                        </Space>
                    </Form.Item>}
                    {props.type_id==='5'&&<Form.Item label='常用类型' name='use' wrapperCol={{span:17}}>
                        <Space wrap>
                            <a onClick={() => {
                                form.setFieldsValue({name:'导游借款支出'})
                                setInfo('导游借款支出')
                                }}
                            >导游借款支出</a>
                            <a onClick={() => {
                                form.setFieldsValue({name:'股东减资支出'})
                                setInfo('股东减资支出')
                                }}
                            >股东减资支出</a>                                                
                            <a  onClick={() => {
                                form.setFieldsValue({name:'银行还贷支出'})
                                setInfo('银行还贷支出')
                                }}
                            >银行还贷支出</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'还款支出'})
                                setInfo('还款支出')
                                }}
                            >还款支出</a>                            
                            <a  onClick={() => {
                                form.setFieldsValue({name:'代付款'})
                                setInfo('代付款')
                                }}
                            >代付款</a>                                                                                
                        </Space>
                    </Form.Item>}
                    <GetUserDept label='部门' name='dept' getUserDept={(e) => {setInfo(prevState=>({...prevState,efst_dept_id:e}))}} required/>
                    <Form.Item name="name" label='类型' wrapperCol={{span:10}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,efst_name:e.target.value}))}}/>
                    </Form.Item>                    
                </Form>
            </Modal>                    
        </>
    )
}

export default BalanceTypeAdd