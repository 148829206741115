import React ,{useRef,useState,useEffect} from 'react'
import { useDept_update_saveMutation, useDept_updateQuery,  } from '../store/ReactWebSiteApi';
import { Form, Input, Modal,message,Button,} from 'antd';
function SetDeptEdit(props) {
    const [form] = Form.useForm();  
    const editRef = useRef();
    const [editForm] = Form.useForm();
    //设置修改部门数据
    const [info, setInfo] = useState();   
    //新增修改部门对话框
    const [open, setOpen] = useState(false);    
    //上传修改部门的ID,获取服务器数据
    const { data, isSuccess} = useDept_updateQuery({ed_id:props.selectedDept.ed_id },{refetchOnMountOrArgChange:1,skip:!open});
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                //设置修改部门的默认值
                editRef.current.setFieldsValue({ed_name: data.Result.ed_name })   
                setInfo(prevState => ({
                    ...prevState,
                    ed_id: data.Result.ed_id,
                    ed_name: data.Result.ed_name,
                    ed_e_id: data.Result.ed_e_id,
                    ed_parent_id: data.Result.ed_parent_id,                    
                    })); 
            }else{
                console.log('111');
            }
        }
    },[data,isSuccess])
    //修改服务器数据
    const [editDept] = useDept_update_saveMutation();    
    //提交修改表单
    const onEditDept = (values) => {
        editDept({
            ed_name:info.ed_name,
            ed_e_id:localStorage.getItem('e_id'),
            ed_parent_id:info.ed_parent_id,
            ed_uid:localStorage.getItem('eu_id'),
            ed_uname:localStorage.getItem('eu_name'),
            ed_id:props.selectedDept.ed_id,
        }).then((res) => {
            if (res.data.Status === 'success') {
                message.success('修改部门成功！')
                setOpen(false);
            }
            if (res.data.Status === 'fail') {
                message.error('修改部门失败！' + res.data.Message)
            }
        });
        // console.log('Received values of form: ', values);    
    };
    return (
        <>
            {(localStorage.getItem('e_name').replace(/"/g,'') !== props.selectedDept.ed_name) && <Button onClick={() => {setOpen(true)}}>修改</Button>}
            <Modal  forceRender
                open={open}
                title="修改部门名称"
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false)}}
                onOk={() => {                   
                editForm
                    .validateFields()
                    .then((values) => {
                        form.resetFields();  
                        onEditDept(values);                       
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
                }}
            >
                <Form
                    form={editForm}
                    labelCol={{span: 5,}}
                    wrapperCol={{span: 19,}}
                    name="editDept" 
                    ref={editRef}
                >
                    <Form.Item                      
                        name="ed_name"
                        label="部门名称"
                        rules={[
                        {
                            required: true,
                            message: '请填写子部门名称！',
                        }]}
                    >
                        <Input onChange={(e) => {setInfo(prevState => ({...prevState,ed_name: e.target.value}));}}  />
                    </Form.Item>
                </Form>
            </Modal>     
        </>
    )
}

export default SetDeptEdit