import React,{useState,useEffect,useRef}from 'react'
import { Table,Button,Space,Modal,message,Form,Input,Select,} from 'antd';
import { useClientListQuery, useDelClientMutation, useUpdateSaveClientMutation,useClientUpdateQuery, useGetClientUidQuery,} from '../../store/ReactWebSiteApi';
import GetClientType from './GetClientType';
import ClientAdd from './ClientAdd';
function SetClient() {
    const [openWhole,setOpenWhole] = useState(false)
    const [form] = Form.useForm();
    //修改客户-----------------------------------------------------------------------------------------------------------------------------修改客户
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    const [infoEdit,setInfoEdit] = useState();
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useClientUpdateQuery({ec_id:editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    ec_type_id:[dataEdit.Result.ec_type_id],
                    ec_name:dataEdit.Result.ec_name,
                    ec_content:dataEdit.Result.ec_content,
                })
                setInfoEdit(prevState=>({...prevState,
                    ec_type_id:dataEdit.Result.ec_type_id,
                    ec_name:dataEdit.Result.ec_name,
                    ec_content:dataEdit.Result.ec_content,              
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const getClientTypeEdit =  (e) => {setInfoEdit(prevState=>({...prevState,ec_type_id:e}))}
    const [submitUpdateSave] = useUpdateSaveClientMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改客户'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitUpdateSave({
                                ec_name:infoEdit.ec_name,
                                ec_update_uid:localStorage.getItem('eu_id'),
                                ec_update_uname:localStorage.getItem('eu_name'),
                                ec_content:infoEdit.ec_content,
                                ec_id:editId,
                                ec_e_id:localStorage.getItem('e_id'),
                                ec_type_id:infoEdit.ec_type_id,
                                ec_esv_id:'9',
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >
                            <GetClientType 
                                required 
                                name="ec_type_id" 
                                label='客户类型' 
                                getClientType={getClientTypeEdit}
                            />
                            <Form.Item name="ec_name" label='客户名称' wrapperCol={{span:15}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ec_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="ec_content" label='客户备注' wrapperCol={{span:15}}>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ec_content:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal>
    //删除客户-----------------------------------------------------------------------------------------------------------------------------删除客户
    const {confirm} = Modal; 
    const [delBusiness] = useDelClientMutation()
    //查询客户===============================================================================================查询客户//
    const [openQuery,setOpenQuery] = useState();
    const [query,setQuery] = useState({ec_e_id:localStorage.getItem('e_id'),ec_action:'search'})
    const {data:dataUid,isSuccess:isSuccessUid} = useGetClientUidQuery({ec_e_id:localStorage.getItem("e_id")},{skip:!openQuery})
    const [handler,setHandler] = useState()
    useEffect(() => {
        if(isSuccessUid){
            if(dataUid.Status==='success'){
                const arr = dataUid.Result.map((item) => {
                    const {ec_uid,ec_uname} = item;
                    return item = {value:ec_uid,label:ec_uname}
                })
                setHandler(arr)
            }
        }
    },[dataUid,isSuccessUid])
    const modalQuery = <Modal
                            open={openQuery}
                            title='查询客户'
                            okText="提交"
                            cancelText="取消"
                            footer={(_, { OkBtn, CancelBtn }) => (
                                <>
                                    <Button onClick={() => {                                        
                                        form.resetFields();
                                        setQuery(prevState=>({...prevState,ec_action:''}));
                                        setOpenQuery(false)
                                        }}>重置</Button>
                                    <CancelBtn />
                                    <OkBtn />
                                </>
                            )}
                            onCancel={() => {setOpenQuery(false);}}
                            onOk={() => {
                                form
                                    .validateFields()
                                    .then((values) => {
                                        setQuery(prevState=>({...prevState,ec_action:'search'}))
                                        setOpenQuery(false)
                                    })
                                    .catch((info) => {
                                        console.log('Validate Failed:', info);
                                    });
                                
                            }}
                        >
                            <Form
                                form={form}
                                layout="horizonal"
                                name="form_in_modal"
                                initialValues={{modifier: 'public',}}
                                labelCol={{ span: 5 }}
                            >
                                <GetClientType 
                                    name="typeQuery" 
                                    label='客户类型' 
                                    getClientType={(e) => {setQuery(prevState=>({...prevState,ec_type_id:e}))}}
                                />
                                <Form.Item label='客户名称' wrapperCol={{span:15}} name='ec_name'>
                                    <Input 
                                        onChange={(e) => {setQuery(prevState=>({...prevState,ec_name:e.target.value}))}}
                                        value={query.ec_name}
                                    />
                                </Form.Item>                                                   
                                <Form.Item name="ec_content" label='备注' wrapperCol={{span:15}}>
                                    <Input
                                        value={query.ec_content} 
                                        onChange={(e) => {                            
                                            setQuery(prevState=>({...prevState,ec_content:e.target.value}))                           
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item name='ec_handler' label='操作员' wrapperCol={{span:15}}>
                                    <Select 
                                        options={handler} 
                                        onChange={(e) => {setQuery(prevState=>({...prevState,ec_uid:e}))}}
                                    />
                                </Form.Item>                                
                            </Form>
                        </Modal>
    //客户列表-----------------------------------------------------------------------------------------------------------------------------客户列表
    const {data,isSuccess} = useClientListQuery(query,{refetchOnMountOrArgChange:1})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {ec_id:key,ec_name,ec_num,ec_content,ec_uname,ec_ctime,finance_num,ec_type_id,ect_name,ec_update_ctime,ec_update_uname} = item;
                    return item = {key,ec_name,ec_num,ec_content,ec_uname,ec_ctime,finance_num,ec_type_id,ect_name,ec_update_ctime,ec_update_uname}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ec_ctime;
                    let bTimeString = b.ec_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])       
    const columns = [
              
        {
            key: 'type',
            title: '客户名称',
            dataIndex: 'ec_name',
        },
        {
            key: 'room',
            title: '备注',
            dataIndex: 'ec_content',
        },
        {
            key: 'ect_name',
            title: '客户类型',
            dataIndex: 'ect_name',
        },
        {
            key: 'num',
            title: '收支单数量',
            dataIndex: 'finance_num',
        },
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        }, 
        // {
        //     key: 'time',
        //     title: '记录',
        //     render: (_,record) => {
        //         return <>
        //             <div>{record.ec_ctime + record.ec_uname}新建</div>
        //             {record.ec_update_ctime && <div>{record.ec_update_ctime + record.ec_update_uname}修改</div>}
        //         </>
        //     }
        // },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space>                        
                        <a onClick={() => {
                            setEditId(record.key);
                            setOpenEdit(true)
                        }}>修改</a>
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    delBusiness({ec_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>                                                
                    </Space>
                </>
            }
        },
    ]


    return (
        <>
            <a onClick={() => {setOpenWhole(true)}}>设置</a>
            <Modal
                open={openWhole}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenWhole(false);}}
                onOk={() => {}}
                footer={null}
                width={800}
                title='设置客户'
            >
                <Space style={{marginBottom:"10px"}}>                
                    <ClientAdd getNewClient={() => {}} type='primary'/>                
                    <Button>
                        <a onClick={() => {setOpenQuery(true);}}>查询</a>
                    </Button>
                </Space>
                <Table columns={columns} dataSource={list} pagination={{showSizeChanger:true,defaultPageSize:'20',showTotal:(total) => `共${total}条`}}></Table> 
            </Modal>            
            {modalEdit}             
            {modalQuery}           
        </>
    )
}

export default SetClient