import React, { useEffect, useState } from 'react'
import { useEntPayGoodsListQuery, useEntUpdateQuery } from '../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import { Modal } from 'antd';
function GetSubscription(props) {
    let type
    if(props.type==='财务模块'){
        type= '2'
    }
    //获取订阅日期
    const {data,isSuccess} = useEntPayGoodsListQuery({epg_e_id:localStorage.getItem('e_id'),epg_eg_id:type})    
    const [isPay, setIsPay] = useState({status:'1',text:''});    
    //获取企业注册日期
    const {data:dataFirm,isSuccess:isSuccessFirm} = useEntUpdateQuery({e_id:localStorage.getItem('e_id')});

    useEffect(() => {
        if(isSuccess && isSuccessFirm){            
            if(data.Status==='success'&&dataFirm.Status==='success'){    
                console.log(data); 
                let arr = data.Result           
                let lastElement = arr[arr.length - 1];
                let aTime = new Date(lastElement.epg_date).getTime();
                let bTime = new Date(dayjs().format('YYYY-MM-DD')).getTime();
                if(aTime < bTime){
                    setIsPay({status:"2",text:`抱歉，您的${props.type}订阅已到期，请联系客服继续订阅！`})
                }
            }
            if(data.Status==='fail'&& dataFirm.Status==='success'){
                if(dayjs().format('YYYY-MM-DD')>dayjs(dayjs(dataFirm.Result.e_ctime).add(30, 'day')).format('YYYY-MM-DD')){
                    setIsPay({status:"3",text:`抱歉，您的${props.type}30天试用期已结束，请联系客服付费订阅！`})
                }
            }            
        }
    }, [data, isSuccess,dataFirm,isSuccessFirm,props])       
    return (
        <>
            {(isPay.status==='2' || isPay.status==='3') && <Modal
                open={true}
                footer={null}
                closable={false}
            >
                <div>{isPay.text}<a onClick={() => {window.open('https://work.weixin.qq.com/kfid/kfcc1a9500fb365f216')}}>在线客服</a></div>
                </Modal>}
        </>
    )
}

export default GetSubscription