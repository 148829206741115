import React ,{useState} from 'react'
import { Form, Input, Modal,Radio, Button} from 'antd';
function SetUserQuery(props) {   
    const [open,setOpen] = useState()
    const [form] = Form.useForm();
    return (
        <>
            <Button
                onClick={() => {setOpen(true)}}
            >查询</Button>
            <Modal
                open={open}
                title='查询用户'
                okText="提交"
                cancelText="关闭"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            props.getUserQuery(values);
                            setOpen(false)                         
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    
                }}
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        <Button onClick={() => {                                        
                            form.resetFields();
                            props.getUserQuery({
                                e_id:localStorage.getItem('e_id'),
                                ed_id:props.ed_id,
                                eu_name:'',
                                eu_ba_phone:'',
                                eu_state:'0'
                            })                             
                            setOpen(false)
                            }}>重置</Button>
                        <CancelBtn />,
                        <OkBtn />
                    </>
                )}
                width={'800px'}
            >
                <Form
                    name="basic"
                    labelCol={{span: 4,}}
                    wrapperCol={{span: 20,}}
                    initialValues={{remember: true,eu_name:'',eu_ba_phone:'',eu_state:''}}
                    autoComplete="off"
                    form={form}
                >
                    
                    <Form.Item
                        label="姓名"
                        name="eu_name"
                        wrapperCol={{span: 4,}}
                    >
                        <Input onChange={() => {}} />
                    </Form.Item>
                    <Form.Item
                        label="登录手机"
                        name="eu_ba_phone"                                    
                        wrapperCol={{span: 4,}}
                    >
                        <Input onChange={() => {}} />
                    </Form.Item>
                    <Form.Item label='用户状态' name='eu_state' initialValue={'0'}>
                        <Radio.Group  onChange={() => {}} >
                            <Radio value='0'>全部</Radio>
                            <Radio value='1'>已启用</Radio>
                            <Radio value='2'>已禁用</Radio>
                        </Radio.Group>                            
                    </Form.Item>                   
                </Form>
            </Modal> 
        </>
    )
}

export default SetUserQuery