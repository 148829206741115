import React ,{useEffect, useState,} from 'react'
import Main from '../../cpn/Main'
import { Space,Table } from 'antd'
import ArapStatementSendAdd from './ArapStatementSendAdd'
import ArapStatementSendCheck from './ArapStatementSendCheck';
import { useDuizhangSendListQuery } from '../../store/ReactWebSiteApi';
import ArapStatementSendQuery from './ArapStatementSendQuery';
import ArapStatementSendAudit from './ArapStatementSendAudit';
import dayjs from 'dayjs';
import ArapStatementSendDel from './ArapStatementSendDel';
function ArapStatementSend() {
    //获取对账单明细
    const [query,setQuery] = useState({edz_ec_id:'',edz_s_date:'',edz_e_date:'',edz_audit_status:'',edz_affirm_status:''})
    const {data,isSuccess} = useDuizhangSendListQuery({
        edz_send_e_id:localStorage.getItem('e_id'),
        edz_ec_id:query.edz_ec_id,
        edz_s_date:query.edz_s_date,
        edz_e_date:query.edz_e_date,
        edz_audit_status:query.edz_audit_status,
        edz_affirm_status:query.edz_affirm_status,
    })
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                const arr = data.Result.map((item) => {
                    const {edz_id:key,edz_s_date,ec_name,edz_e_date,edz_num,edz_audit_ctime,edz_amount,edz_ec_id,edz_uname,edz_affirm_ctime,edz_read_ctime} = item;
                    return item = {key,edz_s_date,ec_name,edz_e_date,edz_num,edz_audit_ctime,edz_amount,edz_ec_id,edz_uname,edz_affirm_ctime,edz_read_ctime}
                })
                setList(arr)
            }else{
                setList([])
            }
        }
    },[data,isSuccess])
    const [list, setList] = useState();
    const columns = [
         
        {
            key: 'id',
            title: '客户',
            dataIndex: 'ec_name',           
        },         
        {
            key: 'date',
            title: '起止日期',
            render:(_,record) => {
                return<>
                    {dayjs(record.edz_s_date).format('YYYY年MM月DD日')} 至 {dayjs(record.edz_e_date).format('YYYY年MM月DD日')}
                </>
            }
        },        
        {
            key: 'ap',
            title: '金额',
            dataIndex: 'edz_amount',
        }, 
        {
            key: '1',
            title: '笔数',
            dataIndex: 'edz_num'
        },            
        {
            key: '2',
            title: '审核状态',
            dataIndex: 'edz_audit_ctime',
            render:(text) => {
                if(text){
                    return<>已审核</>
                }else{
                    return<>未审核</>
                }
            }
        },            
        {
            key: '3',
            title: '对账状态',
            dataIndex: 'edz_affirm_ctime',
            render:(text,record) => {
                let confirm                
                if(text){confirm = '已确认'}else{confirm = '未确认'}
                let read                
                if(record.edz_read_ctime){read = '已读'}else{read = '未读'}
                return <>{read}，{confirm}</>
            }
            
        },            
        {
            key: 'profit',
            title: '操作',
            render:(_,record) => {
                return  <Space>
                            <ArapStatementSendDel  
                                edz_id={record.key} 
                                edz_audit_ctime={record.edz_audit_ctime}
                                edz_affirm_ctime={record.edz_affirm_ctime}
                            />
                            <ArapStatementSendCheck 
                                ec_name={record.ec_name}
                                edz_id={record.key}
                            />
                            <ArapStatementSendAudit 
                                edz_id={record.key} 
                                edz_audit_ctime={record.edz_audit_ctime}
                                edz_affirm_ctime={record.edz_affirm_ctime}
                            />
                            {/* {(record.edz_audit_ctime && !record.edz_affirm_ctime) &&<Copy text={`${record.ec_name}：请确认${dayjs(record.edz_s_date).format('YYYY年MM月DD日')} 至 ${dayjs(record.edz_e_date).format('YYYY年MM月DD日')}的对账单，来自【${localStorage.getItem('e_name')}-${record.edz_uname}】,复制下面链接到电脑浏览器：https://i.bnsrj.com/ArapStatementReceive?esv_id=15&edz_id=${record.key}&e_id=${localStorage.getItem('e_id')}`} />} */}
                        </Space>
            }
        },         
    ]
    
    return (
        <Main t1='对账单'>
            <Space  style={{marginBottom:"10px"}}>
                <ArapStatementSendAdd />
                <ArapStatementSendQuery 
                    getQuery={(e) => {
                        console.log(e);
                        setQuery(prevState=>({
                            ...prevState,
                            edz_ec_id:e.edz_ec_id,
                            edz_s_date:e.edz_month?dayjs(e.edz_month[0]).format('YYYY-MM-DD'):'',
                            edz_e_date:e.edz_month?dayjs(e.edz_month[1]).format('YYYY-MM-DD'):'',
                            edz_audit_status:e.edz_audit_status,                                
                            edz_affirm_status:e.edz_affirm_status, 
                        }))
                    }}
                />
            </Space>
            <Table 
                columns={columns} 
                dataSource={list}                
             />
        </Main>
    )
}

export default ArapStatementSend