import React, { useEffect, useState,useCallback } from 'react';
import { useNavigate} from 'react-router-dom';
import classes from './main.module.css';
import { Breadcrumb, Layout, Space, } from 'antd';
import { setLogout} from '../store/loginSlice'
import { useDispatch } from 'react-redux'
import MainLogout from './MainLogout';
import MainSwitch from './MainSwitch';
import {FolderOpenOutlined} from '@ant-design/icons';
import logo from '../svg/logo.svg'
import SuggestAdd from './SuggestAdd';
const { Header, Content,} = Layout;
function Main(props) {
    const navi = useNavigate();   
    //软件名称
    let e_soft_name
    if(localStorage.getItem('e_soft_name')==='旅当家'){
        e_soft_name = <Space><img src={logo} alt='' width={30} onClick={() => {navi('/TaIndex')}} />旅当家</Space>
    }else if(localStorage.getItem('e_soft_name')==='金鱼'){
        e_soft_name = <Space><img src='./img/app/goldfish.png' alt='' width={26}  />金鱼</Space>
    }else{
        e_soft_name = <>请退出系统重新登录</>
    }
    //退出登录=====================================================================================================================//退出登录
    const dispatch = useDispatch();
    const logout = useCallback(() => {
        navi('/login');
        dispatch(setLogout());    
    },[dispatch, navi]);
    //面包屑导航========================================================================================================================================   
    const [bread,setBread] = useState([
        {title: <a onClick={() => {
            if(localStorage.getItem('e_soft_name')==='金鱼'){
                navi('/GfIndex')
                document.title='金鱼'
            }else{
                navi('/TaIndex'); 
            }
            }}><FolderOpenOutlined /> 桌面</a>},            
    ]    )
    function addItem(newItem) {
        // 创建一个新的数组，将新数据添加到这个数组，然后用 setItems 更新状态
        setBread(prevItems => [...prevItems, newItem]);
      }
    useEffect(() => {
        if(!localStorage.getItem('e_soft_name')||localStorage.getItem('e_soft_name')==='null' ){
            logout()
        }        
        if(props.t1){
            addItem({title: <a onClick={() => {props.n1 && navi(props.n1)}}><FolderOpenOutlined/> {props.t1}</a>})
        }
        if(props.t2){
            addItem({title: <a onClick={() => {props.n2 && navi(props.n2)}}><FolderOpenOutlined/> {props.t2}</a>})
        }
        if(props.t3){
            addItem({title: <a onClick={() => {props.n3 && navi(props.n3)}}><FolderOpenOutlined/> {props.t3}</a>})
        }
        if(props.t4){
            addItem({title: <a onClick={() => {props.n4 && navi(props.n4)}}><FolderOpenOutlined/> {props.t4}</a>})
        }
        if(props.t5){
            addItem({title: <a onClick={() => {props.n5 && navi(props.n5)}}><FolderOpenOutlined/> {props.t5}</a>})
        }
        if(props.t6){
            addItem({title: <a onClick={() => {props.n6 && navi(props.n6)}}><FolderOpenOutlined/> {props.t6}</a>})
        }
        if(props.t7){
            addItem({title: <a onClick={() => {props.n7 && navi(props.n7)}}><FolderOpenOutlined/> {props.t7}</a>})
        }
        if(props.t8){
            addItem({title: <a onClick={() => {props.n8 && navi(props.n8)}}><FolderOpenOutlined/> {props.t8}</a>})
        }
        if(props.t9){
            addItem({title: <a onClick={() => {props.n9 && navi(props.n9)}}><FolderOpenOutlined/> {props.t9}</a>})
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    
    return (
        <>
            <Layout>
                <Header className={classes.mainHeader}>
                    <Space size='large'>
                        <span>{e_soft_name}</span>
                        <Breadcrumb items={bread}  separator='>' />
                    </Space>
                    <div>
                        {/* <span>{localStorage.getItem('e_name').replace(/"/g,'')}</span> */}
                        {/* {(localStorage.getItem('e_name')!==localStorage.getItem('ed_name')) && <span><ApartmentOutlined /> {localStorage.getItem('ed_name').replace(/"/g,'')}</span>} */}
                        <MainSwitch />                  
                        <span>{localStorage.getItem('ed_name').replace(/"/g,'')} / </span>                    
                        <MainLogout />
                    </div>                
                </Header> 
                <Layout>                
                    <Layout>                   
                        <Content className={classes.mainContent}>{props.children}</Content>
                        <div style={{textAlign:'right',margin:'0 30px 30px 0'}}>
                            <SuggestAdd />
                        </div>
                    </Layout>
                </Layout>     
            </Layout>
        </>
    )

}
export default Main