import React from 'react'
import { message,Modal} from 'antd'
import { useUser_stateMutation } from '../store/ReactWebSiteApi';
function SetUserDisable(props) {
    const {confirm} = Modal;    
    const [disableStaff] = useUser_stateMutation();
    return (
        <>
            {(props.eu_trail_tag === 1 && props.eu_state==='1') && <a onClick={() => {
                                    confirm({
                                        title: '该用户有登录记录，可以禁用',
                                        content: '禁用后可以恢复',
                                        okText: '确认',
                                        okType: 'danger',
                                        cancelText: '取消',
                                        onOk() {
                                            if (props.key === localStorage.getItem('eu_id')) {
                                                message.error('不能禁用当前操作员！');
                                                return false;
                                            }
                                            disableStaff({
                                                eu_id: props.eu_id,
                                                eu_state: 1,
                                            }).then((res) => {      
                                                    if (res.data.Status === 'success') {
                                                    message.success('禁用用户成功！')   
                                                    }
                                                    if (res.data.Status === 'fail') {
                                                    message.error('禁用用户失败！' + res.data.Message)
                                                    }
                                                });
                                        },
                                        onCancel() {
                                        console.log('Cancel');
                                        },
                                    })
                                }}>禁用</a>}
                                 {(props.eu_trail_tag === 1 && props.eu_state==='2') && <a onClick={() => {
                                    confirm({
                                        title: `启用${props.eu_name}?`,
                                        okText: '确认',
                                        okType: 'danger',
                                        cancelText: '取消',
                                        onOk() {                                            
                                            disableStaff({
                                                eu_id: props.eu_id,
                                                eu_state: 2,
                                            }).then((res) => {      
                                                    if (res.data.Status === 'success') {
                                                    message.success('启用成功！')   
                                                    }
                                                    if (res.data.Status === 'fail') {
                                                    message.error('启用失败！' + res.data.Message)
                                                    }
                                                });
                                        },
                                        onCancel() {
                                        console.log('Cancel');
                                        },
                                    })
                                }}>启用</a>}
        </>
    )
}

export default SetUserDisable