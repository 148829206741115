import React,{useState,useEffect,useRef}from 'react'
import { Modal,message,Form, Input, TreeSelect} from 'antd';
import {  useAppStationUpdateQuery, useAppStationUpdateSaveMutation, useQuanxianTreeListQuery,  } from '../store/ReactWebSiteApi';
function SetRoleEdit(props) {
    const [form] = Form.useForm(); 
    const {data,isSuccess} = useQuanxianTreeListQuery({qx_type:localStorage.getItem('e_soft_name')==='金鱼'?'1':'2'})    
    const [list, setList] = useState([]);    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){                                
                setList([data.Result])
            }else{
                setList()
            }
            
        }
    }, [data, isSuccess])      
    //修改岗位-----------------------------------------------------------------------------------------------------------------------------修改岗位
    const [info,setInfo] = useState('');
    const [openEdit, setOpenEdit] = useState(false);
    const {data:dataLimit,isSuccess:isSuccessLimit} = useAppStationUpdateQuery({ess_id:props.ess_id},{refetchOnMountOrArgChange:1,skip:!openEdit})
    useEffect(() => {
        if(isSuccessLimit){
            if(dataLimit.Status==='success'){
                console.log(dataLimit);
                let arr = dataLimit.Result.ess_menu_id.split(',')
                editForm.current.setFieldsValue({
                    ess_name:dataLimit.Result.ess_name,
                    ess_menu_id:arr,
                })
                setInfo(prevState=>({...prevState,
                    ess_name:dataLimit.Result.ess_name,
                    ess_menu_id:dataLimit.Result.ess_menu_id,
                }))
            }            
        }
    },[dataLimit,isSuccessLimit])    
    const editForm = useRef()
    const [submitUpdateSave] = useAppStationUpdateSaveMutation()    
    return (
        <>
            <a onClick={() => {setOpenEdit(true)}}>修改</a>
            <Modal
                open={openEdit}
                width={800}
                title='修改岗位'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenEdit(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            submitUpdateSave({
                                ess_e_id:localStorage.getItem('e_id'),
                                ess_name:info.ess_name,
                                ess_menu_id:info.ess_menu_id,
                                ess_update_uid:localStorage.getItem('eu_id'),
                                ess_update_uname:localStorage.getItem('eu_name'),
                                ess_id:props.ess_id,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })     
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    labelCol={{ span: 5 }}
                    ref={editForm}
                >
                    <Form.Item name="ess_name" label='岗位名称' wrapperCol={{span:15}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,ess_name:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item 
                        name="ess_menu_id" 
                        label='权限' 
                        wrapperCol={{span:18}} 
                        required
                        initialValue={info.ess_menu_id}
                    >
                        <TreeSelect
                            showSearch
                            style={{
                                width: '100%',
                            }}
                            placeholder="请选择岗位的权限（多选）"
                            allowClear
                            multiple
                            treeDefaultExpandAll
                            onChange={(e) => {
                                setInfo(prevState=>({...prevState,ess_menu_id:e}))
                            }}
                            treeData={list}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default SetRoleEdit