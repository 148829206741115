import React, { useEffect, useRef, useState } from 'react'
import ModalEdit from '../../../cpn/ModalEdit'
import { Form,Input,message,  } from 'antd'
import { useEntDianPuUpdateSaveMutation,  } from '../../../store/ReactWebSiteApi';
import GetUserDept from '../../../cpn/GetUserDept';
import { useEntDianPuUpdateQuery } from '../../../store/ReactWebSiteApi';
import GetUser from '../../../cpn/GetUser';
function PhoneShopEdit(props) {
    const [form] = Form.useForm();  
    const editForm = useRef()
    const [info,setInfo] = useState({
        sp_country_id:'',
        sp_country_name:'',
        sp_province_id:'',
        sp_province_name:'',
        sp_city_id:'',
        sp_city_name:'',
    });
    const [open,setOpen] = useState()
    //获取
    const {data,isSuccess} = useEntDianPuUpdateQuery({edp_id:props.edp_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){               
                editForm.current.setFieldsValue({
                    edp_dept_name:data.Result.edp_dept_name,                               
                    edp_fuzeren_name:data.Result.edp_fuzeren_name,                               
                    edp_name:data.Result.edp_name,                               
                })                                   
                setInfo(prevsState=>({...prevsState,
                    edp_dept_name:data.Result.edp_dept_name,                               
                    edp_fuzeren_name:data.Result.edp_fuzeren_name,                               
                    edp_name:data.Result.edp_name, 
                }))
            }
        }
    },[data,isSuccess,])
    const [updateSave] = useEntDianPuUpdateSaveMutation()
    
    return (
        <ModalEdit
            getOpen={(e) => {setOpen(prevsState=>e );}}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        //获取产品人群类型
                        let arr = []
                        for (let key in values) {
                            if (values.hasOwnProperty(key)) { // 确保key是对象自身的属性
                                if(key.includes('egg')){
                                    arr.push(values[key])
                                }
                            }
                        } 
                        console.log(values);                       
                        updateSave({
                            edp_e_id:localStorage.getItem('e_id'),
                            edp_e_name:localStorage.getItem('e_name'),
                            edp_dept_id:info.edp_dept_id,
                            edp_dept_name:info.edp_dept_name,
                            edp_fuzeren_id:info.edp_fuzeren_id,
                            edp_fuzeren_name:info.edp_fuzeren_name,
                            edp_name:info.edp_name,
                            edp_update_uid:localStorage.getItem('eu_id'),
                            edp_update_uname:localStorage.getItem('eu_name'),
                            edp_id:props.edp_id,
                        }).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('修改成功！') 
                                setOpen(false)                                                 
                            }
                            if (res.data.Status === 'fail') {
                                message.error('修改失败！'+res.data.Message)
                            }
                        })
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
                
            }}
            open={open}
            title='店铺'
            width={1000}
        >
            <Form
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 4 }}
                ref={editForm}
            >
               <GetUserDept
                    label='部门' 
                    name='edp_dept_name'
                    required 
                    wrapperCol={{span:5}}
                    getUserDept={(e,f) => {setInfo(prevsState=>({...prevsState,edp_dept_id:e,edp_dept_name:f}))}}
                />  
                <GetUser 
                    label='负责人' 
                    name='edp_fuzeren_name'
                    required 
                    wrapperCol={{span:5}}
                    getUser={(e,f) => {setInfo(prevsState=>({...prevsState,edp_fuzeren_id:e,edp_fuzeren_name:f}))}}
                />
                <Form.Item name="edp_name" label='店铺名称' wrapperCol={{span:20}} required>
                    <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,edp_name:e.target.value}))}} style={{width:"500px"}}/>     
                </Form.Item>
                
            </Form>
        </ModalEdit>
    )
}

export default PhoneShopEdit