import React,{useState,useEffect,useRef}from 'react'
import { Table,Space,Modal,message,Form,Input,Button} from 'antd';
import Main from '../../cpn/Main'
import { useGoodsAddMutation, useGoodsDelMutation, useGoodsListQuery, useGoodsUpdateQuery, useGoodsUpdateSaveMutation } from '../../store/ReactWebSiteApi';

function NetTechGoods() {
    const [form] = Form.useForm();    
    //新增产品-----------------------------------------------------------------------------------------------------------------------------新增产品
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [infoAdd,setInfoAdd] = useState({
        eg_content:'',
    });
    const [submitAdd] = useGoodsAddMutation()
    const modalAdd= <Modal
                        open={openAdd}
                        title='新增产品'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        width={1000}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClient(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitAdd({
                                eg_esv_id:'6',
                                eg_name:infoAdd.eg_name,
                                eg_uid:localStorage.getItem('eu_id'),
                                eg_uname:localStorage.getItem('eu_name'),
                                eg_e_id:localStorage.getItem('e_id'),
                                eg_dept_id:localStorage.getItem('ed_id'),
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpenAdd(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 3 }}
                        >
                           
                            <Form.Item name="name" label='名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eg_name:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal> 
    //修改产品-----------------------------------------------------------------------------------------------------------------------------修改产品
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    
    const [infoEdit,setInfoEdit] = useState({name:''});
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useGoodsUpdateQuery({eg_id:editId},{skip:!editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);                
                editForm.current.setFieldsValue({
                    eg_name:dataEdit.Result.eg_name,
                })
                setInfoEdit(prevState=>({...prevState,
                    eg_name:dataEdit.Result.eg_name,           
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useGoodsUpdateSaveMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改产品'
                        okText="提交"
                        cancelText="取消"
                        width={1000}
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitUpdateSave({
                                eg_esv_id:'6',
                                eg_name:infoEdit.eg_name,
                                eg_uid:localStorage.getItem('eu_id'),
                                eg_uname:localStorage.getItem('eu_name'),
                                eg_e_id:localStorage.getItem('e_id'),
                                eg_dept_id:localStorage.getItem('ed_id'),
                                eg_id:editId,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >
                            
                            <Form.Item name="eg_name" label='产品名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eg_name:e.target.value}))}}/>
                            </Form.Item>                            
                             
                        </Form>
                    </Modal>
    //删除产品-----------------------------------------------------------------------------------------------------------------------------删除产品
    const {confirm} = Modal; 
    const [submitDel] = useGoodsDelMutation()
    //产品列表-----------------------------------------------------------------------------------------------------------------------------产品列表
    
    const {data,isSuccess} = useGoodsListQuery({eg_e_id:localStorage.getItem('e_id'),eg_esv_id:'6'})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {eg_id:key,eg_name,eg_content,eg_uname,eg_ctime,eg_update_ctime,eg_update_uname,ec_name,eg_image_file,eu_name} = item;
                    return item = {key,eg_name,eg_content,eg_uname,eg_ctime,eg_update_ctime,eg_update_uname,ec_name,eg_image_file,eu_name}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eg_ctime;
                    let bTimeString = b.eg_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },         
        {
            key: 'type',
            title: '产品名称',
            dataIndex: 'eg_name',
        },            
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.eg_ctime + record.eg_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>    
                        <a onClick={() => {
                            setEditId(record.key);
                            setInfoEdit(prevState=>({...prevState,name:record.ec_name}))
                            setOpenEdit(true)
                        }}>修改</a>
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({eg_id:record.key}).then((res) => {
                                        console.log(res);
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main  topActive='NetTechGoods' app='netTech'>
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增产品</Button>
            </div>            
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalAdd} 
            {modalEdit} 
        </Main>
    )
}

export default NetTechGoods