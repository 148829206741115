import { Button, Divider, Form, Input, Rate } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDijieBaoJiaDanJieDaiBiaoZhunListQuery } from '../../../../store/ReactWebSiteApi'
import { localCostType } from '../customType';
const {TextArea} = Input
function FileGuestFeedback(props) {
    const [form] = Form.useForm();  
    const [list,setList] = useState([])
    const {data,isSuccess} = useDijieBaoJiaDanJieDaiBiaoZhunListQuery({bjdjdbz_bjd_id:props.bjdjdbz_bjd_id},{refetchOnFocus:true})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                // console.log(data);
                const arr = data.Result.map((item) => {
                    const {bjdjdbz_id:key,bjdjdbz_name,bjdjdbz_ctime,bjdjdbz_type_id,bjdjdbz_uname} = item
                    let arr = localCostType.filter((item) => item.value===bjdjdbz_type_id)
                    let type = arr[0].label 
                    return item = {key,bjdjdbz_name,bjdjdbz_ctime,bjdjdbz_type_id,type,bjdjdbz_uname}
                })
                setList(arr)
            }else{
                setList([])
            }
        }
    },[data,isSuccess])
    return (
        <>
            <Divider style={{fontSize:"24px"}}>请在行程结束后，对本次服务做出评价</Divider>
            <div>
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                    style={{marginTop:'10px'}}
                >
                    {list.map((item) => {
                        return <Form.Item label={item.type} required>
                            <Rate></Rate>
                        </Form.Item>
                    })}
                    
                    <Form.Item 
                        label='其他说明' 
                        wrapperCol={{span:10}}
                    >
                        <TextArea />
                    </Form.Item>
                    <Form.Item 
                        label='姓名' 
                        wrapperCol={{span:5}}
                        required
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item 
                        label='手机号码'
                        wrapperCol={{span:5}}
                        required
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item 
                        label='短信验证码'
                        wrapperCol={{span:5}}
                        required
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item 
                        wrapperCol={{offset:3,span:5}}
                    >
                        <Button type='primary'>提交</Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}

export default FileGuestFeedback