import React ,{useState,useEffect}from 'react'
import { Descriptions} from 'antd'
import classes from '../set/set.module.css'
import { useUser_updateQuery ,useGetEnterpriseDeptQuery} from '../store/ReactWebSiteApi';
import Program from '../cpn/Program';
import personal from '../svg/personal.svg'
import ModalPreview from '../cpn/ModalPreview';
function MineInfo() {    
    const [info, setInfo] = useState({time:'',dept:''});   
    const {data,isSuccess} = useUser_updateQuery({eu_id:localStorage.getItem('eu_id')})
    const {data:dataAllDept,isSuccess:isSuccessAllDept} = useGetEnterpriseDeptQuery({e_id:localStorage.getItem('e_id')})
    useEffect(() => {
        if(isSuccess && isSuccessAllDept){
            if(data.Status==='success' && dataAllDept.Status==='success'){
                console.log(data,dataAllDept);
                setInfo(data.Result.eu_dept_id)
                const arr = dataAllDept.Result.map((item) => {
                    if(data.Result.eu_dept_id.includes(item.ed_id)){
                        return item.ed_name+'、'
                    }else{
                        return null
                    }
                })
                setInfo({dept:arr,time:data.Result.eu_ctime})
            }
        }
    },[data,isSuccess,dataAllDept,isSuccessAllDept])
    const descriptions = [
        {
            label: '姓名',
            children: localStorage.getItem('eu_name').replace(/"/g,''),            
        },
        {
            label: '登录手机',
            children: localStorage.getItem('eu_ba_phone'),
        },
        {
            label: '部门',
            children: localStorage.getItem('ed_name').replace(/"/g,''),
        },
        {
            label: '系统权限',
            children: localStorage.getItem('eu_role')==='1'?'普通用户':"系统管理员",
        },
        {
            label: '岗位',
            children: localStorage.getItem('eu_role')==='1'&& localStorage.getItem('ess_name').replace(/"/g,''),
        },
        {
            label: '企业',
            children: localStorage.getItem('e_name').replace(/"/g,''),
        },     
        {
            label: '注册时间',
            children: info.time,
        },   
        {
            label: '部门操作权限',
            children: info.dept,
        },        
    ]
    const [open,setOpen] = useState(false)
    return (
        <>
            <Program title='我的资料'  onClick={() => {setOpen(true)}} icon={<img alt='' src={personal} width={54}/>}/>
            <ModalPreview  
                open={open} 
                getOpen={(e) => {setOpen(prevsState=>e );}}
                link='false'
                width={1000}
            >
                <Descriptions 
                    title='我的资料' 
                    className={classes.settingMainBorder} 
                    items={descriptions} 
                    bordered
                />
            </ModalPreview>            
        </>
    )
}

export default MineInfo