import React,{useState,useEffect,}from 'react'
import { Table,Space,} from 'antd';
import Main from '../cpn/Main';
import { useAppStationListQuery, } from '../store/ReactWebSiteApi';
import SetPostEdit from './SetPostEdit';
import SetPostAdd from './SetPostAdd';
import SetPostDel from './SetPostDel';
function SetPost() {
    
    const {data,isSuccess} = useAppStationListQuery({ess_e_id:localStorage.getItem('e_id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {ess_id:key,ess_name,num,ess_esv,ess_uname,ess_ctime,ess_update_ctime,ess_update_uname} = item;
                    return item = {key,ess_name,num,ess_esv,ess_uname,ess_ctime,ess_update_ctime,ess_update_uname}
                })
                // const filteredArray = newArr.filter(item => item.ess_role === '0');                                
                setList(newArr)
            }else{
                setList()
            }
            
        }
    }, [data, isSuccess])  
    const columns = [
        {
            key: 'type',
            title: '岗位',
            dataIndex:'ess_name',
        },
        {
            key: 'num',
            title: '用户数',
            dataIndex:'num',
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space>                        
                        <SetPostEdit ess_id={record.key}/>
                        <SetPostDel ess_id={record.key} num={record.num}/>                                          
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main t1='岗位'>
            <div style={{marginBottom:"10px"}}>
                <SetPostAdd />
            </div>
            <Table columns={columns} dataSource={list}></Table> 
                  
        </Main>
    )
}

export default SetPost