import React from 'react'
import { message,Modal} from 'antd'
import { useDuizhangAffirmMutation, } from '../../store/ReactWebSiteApi';
function ArapStatementReceiveAudit(props) {    
    const {confirm} = Modal; 
    const [submit] = useDuizhangAffirmMutation() 
    return (
        <>
            {!props.edz_affirm_ctime && <a onClick={() => {
                confirm({
                    title: '确认该对账单吗？',
                    content: '确认后不能取消',
                    okText: '确认',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        submit({
                            edz_id:props.edz_id,
                            edz_affirm_uid:localStorage.getItem('eu_id'),
                            edz_affirm_uname:localStorage.getItem('eu_name'),
                            edz_affirm_status:1,
                        }).then((res) => {
                            // console.log(res);
                            if (res.data.Status === 'success') {
                                message.info('确认成功！');
                            }
                            if (res.data.Status === "fail") {
                                message.error('确认失败！'+res.data.Message)
                            }
                        })
                    },
                    onCancel() {
                        // console.log('Cancel');
                    },
                }); 
                
            }}>确认</a>}
        </>
    )
}

export default ArapStatementReceiveAudit