import React,{useState,useEffect,useRef}from 'react'
import Main from '../../cpn/Main'
import { Table,Button,Space,Modal,message,Form,Input,} from 'antd';
import { useSearchParams, } from 'react-router-dom';
import {useAppSettingAddMutation, useAppSettingDelMutation, useAppSettingListQuery, useAppSettingUpdateQuery, useAppSettingUpdateSaveMutation,} from '../../store/ReactWebSiteApi';
import classes from '../../cpn/cpn.module.css'
function AppSet() {
    const [param] = useSearchParams()
    //新增设置-----------------------------------------------------------------------------------------------------------------------------新增设置
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateClient = (values) => {
        // console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [infoAdd,setInfoAdd] = useState({
        esvs_content:'',
    });
    const [submitAdd] = useAppSettingAddMutation()
    const modalAdd= <Modal
                        open={openAdd}
                        title='新增设置'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClient(values);
                                })
                                .catch((info) => {
                                    // console.log('Validate Failed:', info);
                                });
                            submitAdd({
                                esvs_esv_id:param.get('id'),
                                esvs_esv_name:param.get('name'),                                
                                esvs_name:infoAdd.esvs_name,
                                esvs_router:infoAdd.esvs_router,
                                esvs_uid:localStorage.getItem('eu_id'),
                                esvs_uname:localStorage.getItem('eu_name'),
                            }).then((res) => {
                                // console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpenAdd(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                        >
                            <Form.Item label='应用名称' wrapperCol={{span:10}} required>
                                {param.get('name')}
                            </Form.Item>
                            <Form.Item name="name" label='设置名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,esvs_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="content" label='路由' wrapperCol={{span:15}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,esvs_router:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal> 
    //修改设置-----------------------------------------------------------------------------------------------------------------------------修改设置
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {
        // console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    const [infoEdit,setInfoEdit] = useState();
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useAppSettingUpdateQuery({esvs_id:editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                // console.log(dataEdit);
                editForm.current.setFieldsValue({
                    esvs_name:dataEdit.Result.esvs_name,
                    esvs_router:dataEdit.Result.esvs_router,
                })
                setInfoEdit(prevState=>({...prevState,
                    esvs_name:dataEdit.Result.esvs_name,
                    esvs_router:dataEdit.Result.esvs_router,           
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useAppSettingUpdateSaveMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改设置'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    // console.log('Validate Failed:', info);
                                });
                            submitUpdateSave({
                                esvs_esv_id:param.get('id'),
                                esvs_esv_name:param.get('name'),                                
                                esvs_name:infoEdit.esvs_name,
                                esvs_router:infoEdit.esvs_router,
                                esvs_update_uid:localStorage.getItem('eu_id'),
                                esvs_update_uname:localStorage.getItem('eu_name'),
                                esvs_id:editId,
                            }).then((res) => {
                                // console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >
                            <Form.Item label='应用名称' wrapperCol={{span:10}} required>
                                {param.get('name')}
                            </Form.Item>
                            <Form.Item name="esvs_name" label='设置名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,esvs_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="esvs_router" label='路由' wrapperCol={{span:15}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,esvs_router:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal>
    //删除设置-----------------------------------------------------------------------------------------------------------------------------删除设置
    const {confirm} = Modal; 
    const [submitDel] = useAppSettingDelMutation()
    //设置列表-----------------------------------------------------------------------------------------------------------------------------设置列表
    
    const {data,isSuccess} = useAppSettingListQuery({esvs_esv_id:param.get('id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {esvs_id:key,esvs_name,esvs_router,esvs_uname,esvs_ctime,esvs_update_ctime,esvs_update_uname,esvs_num,esvs_price,sub_num} = item;
                    return item = {key,esvs_name,esvs_router,esvs_uname,esvs_ctime,esvs_update_ctime,esvs_update_uname,esvs_num,esvs_price,sub_num}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.esvs_ctime;
                    let bTimeString = b.esvs_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
  
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
       
        {
            key: 'type',
            title: '设置名称',
            dataIndex: 'esvs_name',
        },        
        {
            key: 'room',
            title: '路由',
            dataIndex: 'esvs_router',
        },       
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.esvs_ctime + record.esvs_uname}</div>
                    <div>修改时间：{record.esvs_update_ctime + record.esvs_update_uname}</div>
                </>
            }
        },
        {
            key: 'action',
            title: <Space><a>新增</a></Space>,
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>                        
                        <a onClick={() => {
                            setEditId(record.key);
                            setOpenEdit(true)
                        }}>修改</a>
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({esvs_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                // console.log('Cancel');
                                },
                            });
                        }}>删除</a>
                        {/* <a onClick={() => {navi( `/TypeSon?id=${param.get('id')}&name=${param.get('name')}&tid=${record.key}&tname=${record.esvs_name}`, { state: {openKeys:'shop',active:"System"}})}}>子设置</a> */}
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main t1='应用' n1='/BnsApp?esv_id=20'  t2={param.get('name')} t3='设置'>
            <div className={classes.buttonRow}>
                <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增</Button>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalAdd}
            {modalEdit}            
        </Main>
    )
}

export default AppSet