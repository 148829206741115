import React ,{useState}from 'react'
import { Form, Input, Modal,message, Button} from 'antd';
import { useDept_addMutation } from '../store/ReactWebSiteApi';
import { stringify } from 'rc-field-form/es/useWatch';
function SetDeptAdd(props) {
    const [form] = Form.useForm();
    const [info, setInfo] = useState();
    const [Open, setOpen] = useState(false) 
    const [addDept] = useDept_addMutation();
    return (
        <>
            <Button 
                style={{ marginLeft: '15px' }} 
                onClick={() => {
                    if(props.selectedDept.ed_id){
                        setOpen(true)
                    }else{
                        message.error('请先选择一个部门！')
                    }
                    
                }} 
                type='primary'

            >新增</Button>
            <Modal 
                destroyOnClose={true}
                open={Open}
                title="新增部门"
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {                   
                form
                    .validateFields()
                    .then((values) => {
                        addDept({
                            ed_uid:localStorage.getItem('eu_id'),
                            ed_uname:localStorage.getItem('eu_name'),
                            ed_parent_id: props.selectedDept.ed_id,
                            ed_e_id:localStorage.getItem('e_id'),
                            ed_name: info.ed_name,
                        })
                        .then((res) => {
                            if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    let newarr = [];
                                    let newdata = stringify(res.data.Result)
                                    newarr.push(newdata)
                                    setInfo(prevState => ({ ...prevState, ed_parent_id: newarr })); 
                                    props.getNewDept({
                                        expandeDept:props.selectedDept,
                                        selectedDept:newarr,
                                        editDeptId:res.data.Result,
                                    })
                                    setOpen(false);
                                }
                                if (res.data.Status === 'fail') {
                                message.error('新增失败！' + res.data.Message)
                            }
                        });
                        form.resetFields();                         
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
                }}
            >
                <Form
                    form={form}
                    name="addDept" 
                    labelCol={{span: 5,}}
                    wrapperCol={{span: 19,}}              
                >
                    <Form.Item
                        name="title"
                        label="名称"
                        rules={[
                        {
                            required: true,
                            message: '请填写子部门名称！',
                        },
                        ]}
                    >
                        <Input onChange={(e) => { setInfo(prevState => ({ ...prevState, ed_name: e.target.value }))}}  autoFocus={true}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default SetDeptAdd