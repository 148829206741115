import React,{useState,useEffect, useRef} from 'react'
import { Form,Modal,message, } from 'antd'
import { useTaskMessageUpdateQuery, useTaskMessageUpdateSaveMutation,} from '../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import GetUsers from '../cpn/GetUsers';
import MyEditor from '../cpn/MyEditor';
function TaskMessageEdit(props) {
    const [form] = Form.useForm();
    const onCreate = (values) => {
        // console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [open,setOpen] = useState()
    const [info,setInfo] = useState({
        etul_content: '',
        etul_e_id: localStorage.getItem('e_id'),
        etul_name: '',
        etul_update_uid: localStorage.getItem('eu_id'),
        etul_update_uname: localStorage.getItem('eu_name'),
        etu_user_id: '',
        etu_user_name: '',
        etul_date:'',
        etul_id:props.etul_id,
        etul_et_id:props.et_id,
    });
    const {data,isSuccess} = useTaskMessageUpdateQuery({etul_id:props.etul_id},{skip:!open})
    const editForm = useRef();
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);               
                // console.log(etu_user_id);
                editForm.current.setFieldsValue({
                    etul_date:dayjs(data.Result.etul_date),
                    etul_content:data.Result.etul_content,
                    etul_name:data.Result.etul_name,     
                });
                setInfo(prevState=>({...prevState,
                    etul_date:dayjs(data.Result.etul_date),
                    etul_content:data.Result.etul_content,
                    etul_name:data.Result.etul_name,           
                }))
            }
        }
    },[data, isSuccess,])
    const [summitUpdateSave] = useTaskMessageUpdateSaveMutation()
    return (
        <>
            <a onClick={() => {setOpen(true)}}>修改</a>
            <Modal
                open={open}
                title='修改任务'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                            // console.log('Validate Failed:', info);
                        }); 
                        summitUpdateSave(info).then((res) => {
                            // console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('修改成功！')                                
                                setOpen(false)                         
                            }
                            if (res.data.Status === 'fail') {
                                message.error('修改失败！'+res.data.Message)
                                
                            }
                        })                           
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                    ref={editForm}
                >               
                   
                    {info.etu_user_id && <GetUsers 
                        defaultId={info.etu_user_id}
                        label='参与人' 
                        required='true' 
                        name='etu_user_id'
                        onChange={(e,f) => {
                            let etu_user_id;
                            let etu_user_name;
                            etu_user_id = f.map((item) => {
                                return item.value
                            })
                            etu_user_name = f.map((item) => {
                                return item.label
                            })
                            setInfo(prevState=>({...prevState,etu_user_id:etu_user_id+'',etu_user_name:etu_user_name+''}))
                        }}
                    />}
                                       
                    <Form.Item name="etul_content" label='备注' wrapperCol={{span:15}}>
                        <MyEditor  onChange={(e) => {setInfo(prevState=>({...prevState,etul_content:e}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default TaskMessageEdit