import React,{useState,}from 'react'
import {Modal,Form, Button,DatePicker,Radio} from 'antd';
import GetClient from '../client/GetClient'
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;
function ArapStatementSendQuery(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    return (
        <>
            <Button style={{marginLeft:'10px'}} onClick={() => {setOpen(true)}}>查询</Button>
            <Modal 
                open={open} 
                title='查询对账单'
                okText="提交"
                cancelText="取消"
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        <Button onClick={() => {
                            setOpen(false)
                            props.getQuery({
                                edz_ec_id:'',
                                edz_s_date:'',
                                edz_e_date:'',
                                edz_audit_status:'',
                                edz_affirm_status:'',
                            })                                    
                            form.resetFields();        
                            }}>重置</Button>
                        <CancelBtn />
                        <OkBtn />
                    </>
                )}
                width={800}
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            console.log(values);
                            props.getQuery({                                
                                edz_ec_id:values.edz_ec_id,
                                edz_s_date:values.edz_month?dayjs(values.edz_month[0]).format('YYYY-MM-DD'):'',
                                edz_e_date:values.edz_month?dayjs(values.edz_month[1]).format('YYYY-MM-DD'):'',
                                edz_audit_status:values.edz_audit_status,                                
                                edz_affirm_status:values.edz_affirm_status,                                
                            })
                            setOpen(false)                              
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });           
                }}
            >
                <Form 
                    form={form}
                    layout="horizonal"
                    name="form_in_modal"
                    initialValues={{edz_audit_status:'',edz_affirm_status:'',edz_ec_id:'',edz_month:""}}
                    labelCol={{ span: 5 }}   
                >
                    <GetClient 
                        label='客户'  
                        wrapperCol={{span:15}} 
                        name='edz_ec_id' 
                        style={{width:'290px'}} 
                        getClient={(e) => {}} 
                    />
                    <Form.Item name="edz_month" label='出发日期' wrapperCol={{span:15}}>
                        <RangePicker onChange={(e) => {}} />
                    </Form.Item>
                    <Form.Item name='edz_audit_status' label='发送状态'>                                    
                        <Radio.Group>
                            <Radio value='0'>全部</Radio>
                            <Radio value='1'>已发送</Radio>
                            <Radio value='2'>未发送</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item  name='edz_affirm_status' label='对账状态' wrapperCol={{span:10}}>                                    
                        <Radio.Group>
                            <Radio value='0'>全部</Radio>
                            <Radio value='1'>已确认</Radio>
                            <Radio value='2'>未确认</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default ArapStatementSendQuery