export const balanceType = [
    {value:'1',label:'团队收入单'},
    {value:'2',label:'团队支出单'},
    {value:'3',label:'费用支出单'},
    {value:'4',label:'其他收入单'},
    {value:'5',label:'其他支出单'},
]

export const localCostType = [
    {value:'1',label:'门票'},
    {value:'2',label:'住宿'},
    {value:'3',label:'用餐'},
    {value:'4',label:'大交通'},
    {value:'5',label:'导游'},
    {value:'6',label:'保险'},
    {value:'7',label:'购物'},
]
export const localPayType = [
    {value:'1',label:'预付款'},
    {value:'2',label:'团款'},
    {value:'3',label:'尾款'},
]
export const localGuidePayType = [
    {value:'1',label:'导游借款'},
    {value:'2',label:'导游代收'},
    {value:'3',label:'导游现付'},
    {value:'4',label:'导游签单'},
    {value:'5',label:'导服费'},
]
export const localFileType = [
    {value:'1',label:'行程方案'},
    {value:'2',label:'报价单'},
    {value:'3',label:'确认件'},
    {value:'4',label:'导游计划单'},
    {value:'5',label:'结算单'},
    {value:'6',label:'游客行程单'},
]
export const commodityContinent = [
    {value:'1',label:'亚洲'},
    {value:'2',label:'非洲'},
    {value:'3',label:'欧洲'},
    {value:'4',label:'北美洲'},
    {value:'5',label:'南美洲'},
    {value:'5',label:'大洋洲'},
]
export const tourType = [
    {value:'1',label:'定制游'},
    {value:'2',label:'研学'},
]
export const indexFinance = localStorage.getItem('e_soft_name')==='金鱼'?'/GfIndexFinance':'/TaFinanceIndex'
export const indexPersonnel = localStorage.getItem('e_soft_name')==='金鱼'?'/GfIndexPersonnel':'/TaIndexPersonnel'
