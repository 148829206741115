import React from 'react';
import {message, Space} from 'antd'
import {useTaskManageAuditMutation } from '../store/ReactWebSiteApi';

function TaskAuditSend(props) {
    const [submitAudit] = useTaskManageAuditMutation();
    return (
        <Space>
            {(props.et_status==='2' || props.et_status==='3') && <a  onClick={() => {
                    submitAudit({
                        et_id:props.et_id,
                        et_status:'1',
                        et_uid:localStorage.getItem('eu_id'),
                        et_uname:localStorage.getItem('eu_name'),
                    }).then((res) => {
                        //console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！') 
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}>待完成</a>}
            {(props.et_status==='2' || props.et_status==='1') && <a  onClick={() => {
                    submitAudit({
                        et_id:props.et_id,
                        et_status:'3',
                        et_uid:localStorage.getItem('eu_id'),
                        et_uname:localStorage.getItem('eu_name'),
                    }).then((res) => {
                        //console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')  
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}>已验收</a>}                            
        </Space>
    )
}

export default TaskAuditSend