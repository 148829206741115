import React,{useState,useEffect,useRef}from 'react'
import { UploadOutlined,} from '@ant-design/icons';
import { Button,Modal,message,Form,Input,Upload, Select,} from 'antd';
import { useAppSynthesisUpdateQuery, useAppSynthesisUpdateSaveMutation,  } from '../../store/ReactWebSiteApi';
import MyEditor from '../../cpn/MyEditor';
import {appCourseType} from './AppCourseType'
function AppCourseEdit(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({esvs_remark:'',esvs_content:''});
    //官网广告图
    const [defaultSite,setDefaultSite] = useState();
    console.log(defaultSite);
    const homePicEdit = (e) => {
        if (e.file.status === 'done'||e.file.status==='removed') {
            const fileArr = e.fileList.map((item) => {
                const { response } = item
                return response.Result
            })
            console.log(e);
            let fileStr = fileArr + "";  
            if(fileStr === null){
                fileStr = ''
            }
            setInfo(prevState=>({...prevState,esvs_image_name:fileStr}))
        }
    }
    
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useAppSynthesisUpdateQuery({esvs_id:props.esvs_id},{skip:!open})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                // console.log(dataEdit);
                //官网广告图
                if (dataEdit.Result.esvs_image_name) {
                    let options = [];
                    let a = {};
                    a.uid = 1;
                    a.url = `/api/assets/images/application/${dataEdit.Result.esvs_image_name}`
                    a.response = {Result:dataEdit.Result.esvs_image_name}
                    options.push(a);
                    //console.log(options);
                    setDefaultSite(<Upload
                        name="image_file"
                        action="/api/ReactApi/zd_single_upload_image"
                        listType="picture"
                        defaultFileList={options}
                        className="upload-list-inline"
                        onChange={homePicEdit}
                        maxCount={1}
                        >
                        <Button icon={<UploadOutlined />}>点击上传</Button>
                    </Upload>)
                }else{
                    setDefaultSite(<Upload
                        name="image_file"
                        action="/api/ReactApi/zd_single_upload_image"
                        listType="picture"
                        className="upload-list-inline"
                        onChange={homePicEdit}
                        maxCount={1}
                        >
                        <Button icon={<UploadOutlined />}>点击上传</Button>
                    </Upload>)
                }
                
                editForm.current.setFieldsValue({
                    esvs_name:dataEdit.Result.esvs_name,
                    esvs_content:dataEdit.Result.esvs_content,
                    esvs_type_id:[dataEdit.Result.esvs_type_id],

                })
                setInfo(prevState=>({...prevState,
                    esvs_type_id:dataEdit.Result.esvs_type_id,
                    esvs_name:dataEdit.Result.esvs_name,
                    esvs_content:dataEdit.Result.esvs_content,                   
                    esvs_image_name:dataEdit.Result.esvs_image_name,                  
                    esvs_image2_name:dataEdit.Result.esvs_image2_name,                     
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [goodsEdit] = useAppSynthesisUpdateSaveMutation()
    return (
        <>
            <a onClick={() => {setOpen(true)}}>修改</a>
            <Modal
                width={1000}                        
                open={open}
                title='修改应用'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateEdit(values);
                        })
                        .catch((info) => {
                            // console.log('Validate Failed:', info);
                        });
                    goodsEdit({
                        esvs_name:info.esvs_name,
                        esvs_file:info.esvs_file,
                        esvs_type_id:info.esvs_type_id,                                    
                        esvs_update_uid:localStorage.getItem('eu_id'),
                        esvs_update_uname:localStorage.getItem('eu_name'),
                        esvs_content:info.esvs_content,
                        esvs_id:props.esvs_id,       
                        esvs_esv_id:props.esvs_esv_id                          
                    }).then((res) => {
                        // console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')   
                            setOpen(false)                             
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                    ref={editForm}
                >
                    <Form.Item name="esvs_type_id" label='类型' wrapperCol={{span:10}} required>
                        <Select options={appCourseType} onChange={(e) => {setInfo(prevState=>({...prevState,esvs_type_id:e}))}}/>
                    </Form.Item>
                    <Form.Item name="esvs_name" label='标题' wrapperCol={{span:10}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,esvs_name:e.target.value}))}}/>
                    </Form.Item>
                    
                    <Form.Item name="esvs_content" label='图文描述' wrapperCol={{span:15}}>
                        <MyEditor onChange={(e) => {setInfo(prevState=>({...prevState,esvs_content:e}))}}  value={info.esvs_content}/>
                    </Form.Item>                 
                    <Form.Item 
                        label="附件"                         
                        valuePropName='fileList'
                        getValueFromEvent={e => {
                            if (Array.isArray(e)) {
                                return e;
                            }
                            return e && e.fileList;
                        }}
                    >
                        <Upload
                            name="image_file"
                            action="/api/ReactApi/zd_single_upload_image"
                            listType="picture"
                            className="upload-list-inline"
                            onChange={homePicEdit}
                            maxCount={1}
                            >
                            <Button icon={<UploadOutlined />}>点击上传</Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default AppCourseEdit