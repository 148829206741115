import React, { useEffect, useState } from 'react'
import { message, Space, Table } from 'antd'
import { useDijieTemplateZhuYiShiXiangDelMutation, useDijieTemplateZhuYiShiXiangListQuery } from '../../../../../store/ReactWebSiteApi'
import ModalDel from '../../../../../cpn/ModalDel'
import NotesEdit from './NotesEdit'
import NotesQuery from './NotesQuery'
import NotesAdd from './NotesAdd'
function Notes(props) {
    const [list,setList] = useState()
    const [query,setQuery] = useState({ltzysx_e_id:localStorage.getItem('e_id')})
    const {data, isSuccess} = useDijieTemplateZhuYiShiXiangListQuery(query,{refetchOnMountOrArgChange:1});
    useEffect(() => {
        if (isSuccess) {
            console.log(data);
            if(data.Status==='success'){                
                const newArr = data.Result.map(item => {
                    const {ltzysx_id:key,ltzysx_name,ltzysx_uname,ltzysx_ctime,ed_name,ltzysx_content} = item;                    
                    return item = {key,ltzysx_name,ltzysx_uname,ltzysx_ctime,ed_name,ltzysx_content}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ltzysx_ctime;
                    let bTimeString = b.ltzysx_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    //删除
    const [del] = useDijieTemplateZhuYiShiXiangDelMutation()
    const column = [             
        {
            key:'2',
            title:'注意事项模板',
            dataIndex:'ltzysx_name',            
        },                  
        {
            key:'3',
            title:'描述',
            dataIndex:'ltzysx_content',            
        },   
        {
            key:'1',
            title:'部门',
            dataIndex:'ed_name'
        },   
                       
        {
            key:'4',
            title:'操作',
            render:(_,record) => {
                return <Space>
                    <NotesEdit ltzysx_id={record.key} />
                    <ModalDel onOk={() => {
                        del({ltzysx_id:record.key}).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                    {props.get && <a onClick={() => {
                        props.getNotes({
                            ltzysx_name:record.ltzysx_name,
                            ltzysx_content:record.ltzysx_content
                        })
                        props.getOpen(false)     
                        }}>选择</a>}
                </Space>
            }
        },
    ]
    return (
        <>
            <Space>
                <NotesAdd />
                <NotesQuery
                        getQuery={(e) => {
                        setQuery(prevState=>({
                            ...prevState,
                            ltzysx_dept_id:e.ltzysx_dept_id,
                            ltzysx_content:e.ltzysx_content,
                            ltzysx_name:e.ltzysx_name,
                        }))
                    }}
                />
            </Space>
            <Table columns={column} dataSource={list} style={{marginTop:"10px"}}/>
        </>
    )
}

export default Notes