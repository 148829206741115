import React, { useEffect, useState } from 'react'
import { Space ,Table,Tag} from 'antd'
import { useFinanceSubTypeListQuery, useIncomeExpenditureListQuery } from '../../store/ReactWebSiteApi'
import BalanceElseAdd from './BalanceElseAdd'
import BalanceElseEdit from './BalanceElseEdit'
import BalanceElseDel from './BalanceElseDel'
import BalanceElseAudit from './BalanceElseAudit'
import LedgerGroupBalanceCheck from '../../ta/ledger/LedgerGroupBalanceCheck'
import ArapCheck from '../../finance/arap/ArapCheck'
import FundCheck from '../../finance/fund/FundCheck'
function BalanceElseList(props) {
    const {data,isSuccess} = useIncomeExpenditureListQuery({eie_e_id:localStorage.getItem('e_id'),action:'search',eie_type_id:props.main_type_id},{refetchOnMountOrArgChange:1})
    //获取子类型id和名称 
    const [subType,setSubType] = useState([{efst_id:'',efst_name:''}])
    const {data:dataType,isSuccess:isSuccessType} = useFinanceSubTypeListQuery({
        efst_e_id:localStorage.getItem('e_id'),
        efst_finance_type_id:props.eie_type_id,
        efst_name:props.efst_name?props.efst_name:'',
        efst_mode:props.efst_name?'2':''
    })
    useEffect(() => {
        if(isSuccessType){
            if(dataType.Status==='success'){
                // console.log('dataType',dataType);
                setSubType(dataType.Result)
            }
        }
    },[dataType,isSuccessType])
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                const newArr = data.Result.map(item => {
                    const {eie_id:key,eie_type_id,eie_arrive_year,eie_egg_value,eie_arrive_month,eie_arrive_day,eie_finance_sub_type_name,eie_hx_ctime,ed_name,eie_jbr_uname,eie_audit1_ctime,eie_audit1_uname,eie_audit2_ctime,eie_audit2_uname,eie_hx_year,eie_hx_month,eie_hx_day,eie_amount,ec_name,eie_ebn_id,eie_content,eie_uname,eie_ctime,eie_update_ctime,eie_update_uname,eie_price,eie_image_file,eie_count,eci_name,eg_name,eu_name,eie_hx_number,eie_bank_log_id,eie_yw_name} = item;
                    return item = {key,eie_type_id,eie_arrive_year,eie_egg_value,eie_arrive_month,eie_arrive_day,eie_finance_sub_type_name,eie_hx_ctime,ed_name,eie_jbr_uname, eie_audit1_ctime,eie_audit1_uname,eie_audit2_ctime,eie_audit2_uname,eie_hx_year,eie_hx_month,eie_hx_day,eie_amount,ec_name,eie_ebn_id,eie_content,eie_uname,eie_ctime,eie_update_ctime,eie_update_uname,eie_price,eie_image_file,eie_count,eci_name,eg_name,eu_name,eie_hx_number,eie_bank_log_id,eie_yw_name}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eie_ctime;
                    let bTimeString = b.eie_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
                
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key',
            render:(_,record) => {
                return  <LedgerGroupBalanceCheck                         
                            eie_id={record.key}
                            title={props.main_type_name}
                            jbr_label={props.jbr_label}
                            eie_jbr_uname={props.eie_jbr_uname} 
                            eie_esv_id={props.eie_esv_id}
                            eie_jbr_uid={props.eie_jbr_uid}
                            eie_type_id={props.eie_type_id}
                            eie_finance_sub_type_id={subType.eie_finance_sub_type_id}
                            eie_finance_sub_type_name={subType.eie_finance_sub_type_name}
                            eie_ebn_id={props.eie_ebn_id}
                            eie_eg_id={props.ebn_eg_id}
                            guige={props.guige}
                        />
            }
        },        
        {
            key: 'eie_finance_sub_type_name',
            title: '类型',
            dataIndex: 'eie_finance_sub_type_name'
        },  
        {
            key: 'client',
            title: '客户',
            dataIndex: 'ec_name',
        },                           
        {
            key: 'eie_amount',
            title: '金额',
            render:(_,record) => {
                return <>{record.eie_price} × {record.eie_count} = {record.eie_amount}</>
            }
        },        
        {
            key: 'guige',
            title: '数量',
            dataIndex:"guige",
            render:(text) => {
                let arr = []
                if(text){
                    arr = text.map((item) => {
                        return <div key={item.eiegg_egg_id}>{item.eiegg_egg_name+item.eiegg_egg_value}</div>
                    })
                }
                return <Space>{arr}</Space>
            }
        },                     
        {
            key: 'eie_content',
            title: '备注',
            dataIndex: 'eie_content',
        },  
        {
            key: 'eie_yw_name',
            title: '销售员',
            dataIndex:'eie_yw_name',
        },             
                                                 
        {
            key: 'ed_name',
            title: '部门',
            dataIndex: 'ed_name'
        },                                          
                                                
        {
            key: 'status',
            title: '审核',
            render:(_,record) => {
                return<>
                    {!record.eie_audit1_uname && <>待审核</>}
                    {(record.eie_audit1_uname && !record.eie_audit2_uname) && <Tag color='green'>已审核</Tag>}
                    {record.eie_audit2_uname && <Tag color="volcano">已复核</Tag>}
                </>
            }
        },             
        {
            key: 'hx',
            title: '下账转账',
            render:(_,record) => {
                return<>
                    {!record.eie_hx_ctime && <>待下账</>}
                    {(record.eie_hx_ctime && !record.eie_bank_ctime) && <ArapCheck eie_hx_number={record.eie_hx_number} />}
                    {record.eie_bank_ctime && <FundCheck ecal_id={record.eie_bank_log_id} />}
                </>
            },
            width:'6%'
        },      
             
        // {
        //     key: 'time',
        //     title: '操作记录',
        //     render: (_,record) => {
        //         return <>
        //             <div>新建时间：{record.eie_ctime + record.eie_uname}</div>
        //             <div>修改时间：{record.eie_update_ctime + record.eie_update_uname}</div>
        //         </>
        //     }
        // },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                     <Space>
                        <BalanceElseEdit 
                            eie_id={record.key}                            
                            eie_audit1_ctime={record.eie_audit1_ctime}
                            eie_hx_ctime={record.eie_hx_ctime}
                            efst_finance_type_id={props.main_type_id}
                            main_type_name={props.main_type_name}
                        />
                        <BalanceElseDel 
                            eie_audit1_ctime={record.eie_audit1_ctime}
                            eie_hx_ctime={record.eie_hx_ctime}
                            eie_id={record.key}
                        />
                        <BalanceElseAudit
                            eie_audit1_ctime={record.eie_audit1_ctime}
                            eie_audit2_ctime={record.eie_audit2_ctime}
                            eie_id={record.key}
                        />
                    </Space>
                </>
            }
        },
    ]
    return (
        <>
            <Space>
                <BalanceElseAdd 
                    efst_finance_type_id={props.main_type_id}
                    main_type_name={props.main_type_name}
                />
            </Space>
            <Table 
                    columns={columns} 
                    dataSource={list}  
                    style={{marginTop:"10px"}}   
                    pagination={{
                        pageSize:20,
                        showSizeChanger:true,
                        showTotal:(total) => `共${total}条`,
                    }}            
            />
        </>
    )
}

export default BalanceElseList