import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import form_data from "./formData";

const ReactWebSiteFinanceApi = createApi({
    reducerPath: 'ReactWebSiteFinanceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/ReactWebSiteFinance'        
    }),
    tagTypes:['balance','bank','goods','detail','wage','wageDetail','clocking','warehouse','property','inOutWarehouse','workPlan','userTest','topic'],
    endpoints(build) {
        return {
            
            //新增收支
            billAdd: build.mutation({
                query(user) {
                    return {
                        url: '/billAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['balance']
            }),
            //删除收支
            billDel: build.mutation({
                query(user) {
                    return {
                        url: '/billDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['balance']
            }),
            //修改保存收支
            billUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/billUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['balance']
            }),
            //审核收支
            disburseAudit: build.mutation({
                query(user) {
                    return {
                        url: '/disburseAudit',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['balance']
            }),
            //取消审核收支
            disburseAuditCancel: build.mutation({
                query(user) {
                    return {
                        url: '/disburseAuditCancel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['balance']
            }),
            //收支列表
            billList: build.query({
                query(user) {
                    return {
                        url: '/billList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['balance']
            }),
            //收支修改
            billUpdate: build.query({
                query(user) {
                    return {
                        url: '/billUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['balance']
            }),
            //新增账户
            capitalAccountAdd: build.mutation({
                query(user) {
                    return {
                        url: '/capitalAccountAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['bank']
            }),
            //修改保存账户
            capitalAccountUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/capitalAccountUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['bank']
            }),
            //删除账户
            capitalAccountDel: build.mutation({
                query(user) {
                    return {
                        url: '/capitalAccountDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['bank']
            }),
            //账户修改
            capitalAccountUpdate: build.query({
                query(user) {
                    return {
                        url: '/capitalAccountUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['bank']
            }),
            //账户列表
            capitalAccountList: build.query({
                query(user) {
                    return {
                        url: '/capitalAccountList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['bank']
            }),
            //新增产品
            billGoodsAdd: build.mutation({
                query(user) {
                    return {
                        url: '/billGoodsAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['goods']
            }),
            //产品列表
            billGoodsList: build.query({
                query(user) {
                    console.log(user);
                    return {
                        url: '/billGoodsList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['goods']
            }),
            //产品修改
            billGoodsUpdate: build.query({
                query(user) {
                    return {
                        url: '/billGoodsUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['goods']
            }),
            //修改保存产品
            billGoodsUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/billGoodsUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['goods']
            }),
            //删除产品
            billGoodsDel: build.mutation({
                query(user) {
                    return {
                        url: '/billGoodsDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['goods']
            }),
            //新增账户明细
            capitalAccountDetailAdd: build.mutation({
                query(user) {
                    return {
                        url: '/capitalAccountDetailAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['detail','balance']
            }),
            //删除账户明细
            capitalAccountLogDel: build.mutation({
                query(user) {
                    return {
                        url: '/capitalAccountLogDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['detail','balance']
            }),
            //对账账户明细
            capitalAccountConfirm: build.mutation({
                query(user) {
                    return {
                        url: '/capitalAccountConfirm',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['detail']
            }),
            //账户明细列表
            capitalAccountLogList: build.query({
                query(user) {
                    return {
                        url: '/capitalAccountLogList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['detail']
            }),
            //贷款
            loansList: build.query({
                query(user) {
                    return {
                        url: '/loansList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //备用金
            spareList: build.query({
                query(user) {
                    return {
                        url: '/spareList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //利润表
            profitList: build.query({
                query(user) {
                    return {
                        url: '/profitList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
            //新增考勤
            attendanceRecordAdd: build.mutation({
                query(user) {
                    return {
                        url: '/attendanceRecordAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['clocking']
            }),
            //删除考勤
            attendanceRecordDel: build.mutation({
                query(user) {
                    return {
                        url: '/attendanceRecordDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['clocking']
            }),
            //修改保存考勤
            attendanceRecordUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/attendanceRecordUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['clocking']
            }),
            //提交员工确认
            attendanceRecordState: build.mutation({
                query(user) {
                    return {
                        url: '/attendanceRecordState',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['clocking']
            }),
            //老板审核
            attendanceRecordConfirm: build.mutation({
                query(user) {
                    return {
                        url: '/attendanceRecordConfirm',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['clocking']
            }),
            //老板取消审核
            attendanceRecordConfirmCancel: build.mutation({
                query(user) {
                    return {
                        url: '/attendanceRecordConfirmCancel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['clocking']
            }),
            //考勤列表
            attendanceRecordList: build.query({
                query(user) {
                    return {
                        url: '/attendanceRecordList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['clocking']
            }),
            //考勤修改
            attendanceRecordUpdate: build.query({
                query(user) {
                    return {
                        url: '/attendanceRecordUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['clocking']
            }),
            //新增工资条
            wageAdd: build.mutation({
                query(user) {
                    return {
                        url: '/wageAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['wage']
            }),
            //删除工资条
            wageDel: build.mutation({
                query(user) {
                    return {
                        url: '/wageDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['wage']
            }),
            //修改保存工资条
            wageUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/wageUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['wage']
            }),
            //审核工资条
            wageConfirmCancel: build.mutation({
                query(user) {
                    return {
                        url: 'wageConfirmCancel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['wage']
            }),
            //提交工资条
            wageRemitState: build.mutation({
                query(user) {
                    return {
                        url: 'wageRemitState',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['wage']
            }),
            //工资条列表
            wageList: build.query({
                query(user) {
                    return {
                        url: '/wageList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['wage']
            }),
            //工资条修改
            wageUpdate: build.query({
                query(user) {
                    return {
                        url: '/wageUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['wage']
            }),
            //工资条应发应扣列表
            yfykList: build.query({
                query(user) {
                    return {
                        url: '/yfykList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['wageDetail']
            }),
            //工资条应发应扣修改
            yfykUpdate: build.query({
                query(user) {
                    return {
                        url: '/yfykUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['wageDetail']
            }),
            //新增工资条应发应扣
            yfykAdd: build.mutation({
                query(user) {
                    return {
                        url: '/yfykAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['wageDetail','wage']
            }),
            //删除工资条应发应扣
            yfykDel: build.mutation({
                query(user) {
                    return {
                        url: '/yfykDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['wageDetail','wage']
            }),
            //修改保存工资条应发应扣
            yfykUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/yfykUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['wageDetail','wage']
            }),
            //新增库房
            warehouseAdd: build.mutation({
                query(user) {
                    return {
                        url: '/warehouseAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['warehouse']
            }),
            //删除库房
            warehouseDel: build.mutation({
                query(user) {
                    return {
                        url: '/warehouseDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['warehouse']
            }),
            //修改保存库房
            warehouseUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/warehouseUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['warehouse']
            }),           
            //库房列表
            warehouseList: build.query({
                query(user) {
                    return {
                        url: '/warehouseList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['warehouse']
            }),
            //库房修改
            warehouseUpdate: build.query({
                query(user) {
                    return {
                        url: '/warehouseUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['warehouse']
            }),
            //新增物品
            propertyAdd: build.mutation({
                query(user) {
                    return {
                        url: '/propertyAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['property']
            }),
            //删除物品
            propertyDel: build.mutation({
                query(user) {
                    return {
                        url: '/propertyDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['property']
            }),
            //修改保存物品
            propertyUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/propertyUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['property']
            }),           
            //物品列表
            propertyList: build.query({
                query(user) {
                    return {
                        url: '/propertyList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['property']
            }),
            //物品修改
            propertyUpdate: build.query({
                query(user) {
                    return {
                        url: '/propertyUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['property']
            }),
            //获取仓库物品
            getWarehouse: build.query({
                query(user) {
                    return {
                        url: '/getWarehouse',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['property']
            }),
            //新增出入库
            inOutWarehouseAdd: build.mutation({
                query(user) {
                    return {
                        url: '/inOutWarehouseAdd',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['inOutWarehouse']
            }),
            //删除出入库
            inOutWarehouseDel: build.mutation({
                query(user) {
                    return {
                        url: '/inOutWarehouseDel',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['inOutWarehouse']
            }),
            //修改保存出入库
            inOutWarehouseUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/inOutWarehouseUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['inOutWarehouse']
            }),           
            //出入库列表
            inOutWarehouseList: build.query({
                query(user) {
                    return {
                        url: '/inOutWarehouseList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['inOutWarehouse']
            }),
            //出入库修改
            inOutWarehouseUpdate: build.query({
                query(user) {
                    return {
                        url: '/inOutWarehouseUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['inOutWarehouse']
            }),
            //物品出库对应的仓库列表
            getPropertyWarehouse: build.query({
                query(user) {
                    return {
                        url: '/getPropertyWarehouseList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['inOutWarehouse']
            }),
            //仓库对应价格及库存
            getPropertyWarehousePriceList: build.query({
                query(user) {
                    return {
                        url: '/getPropertyWarehousePriceList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['inOutWarehouse']
            }),
            //物品所在所有仓库及所有库存
            propertyAllKc: build.query({
                query(user) {
                    return {
                        url: '/propertyAllKc',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['warehouse']
            }),
           
            
        }
    }
})

export const {   
    useBillListQuery,
    useBillAddMutation,
    useBillDelMutation,
    useBillUpdateQuery,
    useBillUpdateSaveMutation,
    useCapitalAccountListQuery,
    useCapitalAccountAddMutation,
    useCapitalAccountDelMutation,
    useCapitalAccountUpdateQuery,
    useCapitalAccountUpdateSaveMutation,
    useBillGoodsAddMutation,
    useBillGoodsDelMutation,
    useBillGoodsListQuery,
    useBillGoodsUpdateQuery,
    useBillGoodsUpdateSaveMutation,
    useCapitalAccountDetailAddMutation,
    useCapitalAccountLogListQuery,
    useCapitalAccountLogDelMutation,
    useCapitalAccountConfirmMutation,
    useDisburseAuditMutation,
    useDisburseAuditCancelMutation,
    useLoansListQuery,
    useSpareListQuery,
    useProfitListQuery,
    useAttendanceRecordAddMutation,
    useAttendanceRecordListQuery,
    useAttendanceRecordDelMutation,
    useAttendanceRecordUpdateSaveMutation,
    useAttendanceRecordUpdateQuery,
    useAttendanceRecordStateMutation,
    useAttendanceRecordConfirmMutation,
    useAttendanceRecordConfirmCancelMutation,
    useWageAddMutation,
    useWageDelMutation,
    useWageListQuery,
    useWageUpdateQuery,
    useWageUpdateSaveMutation,
    useWageConfirmCancelMutation,
    useWageRemitStateMutation,
    useYfykAddMutation,
    useYfykDelMutation,
    useYfykListQuery,
    useYfykUpdateQuery,
    useYfykUpdateSaveMutation,
    useWarehouseAddMutation,
    useWarehouseDelMutation,
    useWarehouseListQuery,
    useWarehouseUpdateSaveMutation,
    useWarehouseUpdateQuery,
    usePropertyAddMutation,
    usePropertyDelMutation,
    usePropertyListQuery,
    usePropertyUpdateQuery,
    usePropertyUpdateSaveMutation,
    useGetWarehouseQuery,
    useInOutWarehouseAddMutation,
    useInOutWarehouseDelMutation,
    useInOutWarehouseListQuery,
    useInOutWarehouseUpdateQuery,
    useInOutWarehouseUpdateSaveMutation,
    useGetPropertyWarehouseQuery,
    useGetPropertyWarehousePriceListQuery,
    usePropertyAllKcQuery,
    
} = ReactWebSiteFinanceApi;
export default ReactWebSiteFinanceApi;