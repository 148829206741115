import React,{useState,useEffect,useRef}from 'react'
import { Modal,message,Form,Input} from 'antd';
import {useGoodsUpdateQuery, useGoodsUpdateSaveMutation } from '../../store/ReactWebSiteApi';
import GetGoodsType from './GetGoodsType';
import GetDept from '../../cpn/GetDept';

function GoodsEdit(props) {
    const [form] = Form.useForm(); 
    const [open, setOpen] = useState(false);
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({name:''});
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useGoodsUpdateQuery({eg_id:info.eg_id},{skip:!info.eg_id})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);                
                editForm.current.setFieldsValue({
                    eg_name:dataEdit.Result.eg_name,
                    eg_dept_id:dataEdit.Result.eg_dept_id,
                    eg_egt_id:[dataEdit.Result.eg_egt_id],                    
                })
                setInfo(prevState=>({...prevState,
                    eg_name:dataEdit.Result.eg_name,           
                    eg_egt_id:dataEdit.Result.eg_egt_id,           
                    eg_egt_name:dataEdit.Result.eg_egt_name,     
                    eg_dept_id:dataEdit.Result.eg_dept_id,      
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useGoodsUpdateSaveMutation()
    return (
        <>
            <a 
                onClick={() => {
                    setOpen(true);
                    setInfo(prevState=>({...prevState,eg_id:props.eg_id}))
                }}>修改</a>
            <Modal
                open={open}
                title='修改产品'
                okText="提交"
                cancelText="取消"
                width={1000}
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateEdit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitUpdateSave({
                        eg_name:info.eg_name,
                        eg_update_uid:localStorage.getItem('eu_id'),
                        eg_update_uname:localStorage.getItem('eu_name'),
                        eg_e_id:localStorage.getItem('e_id'),
                        eg_dept_id:info.eg_dept_id,
                        eg_id:info.eg_id,
                        eg_egt_id:info.eg_egt_id,
                        eg_egt_name:info.eg_egt_name,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')   
                            setOpen(false)                             
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                    ref={editForm}
                >
                    <GetDept 
                        label='部门' 
                        name='eg_dept_id'
                        required
                        wrapperCol={{span:10}}
                        getDept={(e) => {setInfo(prevState=>({...prevState,eg_dept_id:e,}))}}
                    />
                    <GetGoodsType
                        getGoodsType={(e,f) => {
                            console.log(e,f);
                            setInfo(prevState=>({...prevState,eg_egt_id:e,eg_egt_name:f}))
                        }} 
                        label='产品类型' 
                        name='eg_egt_id'
                        wrapperCol={{span:15}}
                        egt_esv_id={props.eg_esv_id}
                        required
                    />
                    <Form.Item name="eg_name" label='产品名称' wrapperCol={{span:10}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,eg_name:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default GoodsEdit