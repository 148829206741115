
import React,{ useState,}from 'react'
import { Modal,message,Form,DatePicker,Button,} from 'antd';
import { useDuizhangAddMutation, } from '../../store/ReactWebSiteApi';
import GetClient from '../client/GetClient';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;
function ArapStatementSendAdd(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [info,setInfo] = useState({
        edz_send_e_id:localStorage.getItem('e_id'),
        edz_ec_id:'',
        edz_uid:localStorage.getItem('eu_id'),
        edz_uname:localStorage.getItem('eu_name'),        
        edz_send_dept_id:localStorage.getItem('ed_id'), 
        edz_send_dept_name:localStorage.getItem('ed_name'),
        edz_send_phone:localStorage.getItem('eu_ba_phone'),        
    });

    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
   const [submit] = useDuizhangAddMutation()
    return (
        <>
            <Button onClick={() => {setOpen(true)}} type='primary'>新增</Button>
            <Modal
                open={open}
                title='新增对账单'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            submit(info).then((res) => {
                                // console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpen(false)                      
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })    
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });                            
                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',type:props.type_id}}
                    labelCol={{ span: 5 }}
                >                            
                    
                    <GetClient 
                        label='客户'  
                        wrapperCol={{span:10}} 
                        style={{width:'280px'}} 
                        getClient={(e) => {
                            setInfo(prevState=>({...prevState,edz_ec_id:e}))
                        }}
                    />
                    <Form.Item name="date" label='日期' wrapperCol={{span:15}}>
                        <RangePicker onChange={(e) => {
                                setInfo(prevState=>({
                                    ...prevState,
                                    edz_s_date:dayjs(e[0]).format('YYYY-MM-DD'),
                                    edz_e_date:dayjs(e[1]).format('YYYY-MM-DD'),
                                }))
                            }} />
                    </Form.Item>
                </Form>
            </Modal>                    
        </>
    )
}

export default ArapStatementSendAdd