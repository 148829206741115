import React,{useState,useEffect,useRef,}from 'react'
import { Modal,message,Form,Input,Button,Upload, InputNumber,} from 'antd';
import MyEditor from '../../cpn/MyEditor'
import { useGwGoodsUpdateQuery, useGwGoodsUpdateSaveMutation } from '../../store/ReactWebSiteApi';
import { UploadOutlined} from '@ant-design/icons';
import GetType from './GetType';
function WebGoodsEdit(props) {
    const [form] = Form.useForm();           
    const [open, setOpen] = useState(false);
    const [info,setInfo] = useState({name:'',eowg_sub_name:'',eowg_sub_type_id:[]});
    //图片
    const [defaultPic,setDefaultPic] = useState();
    const handleChangeEdit = (e,f) => {
        console.log(e,f);
        if (e.file.status === 'done'||e.file.status==='removed') {
            const fileArr = e.fileList.map((item) => {
                const { response } = item
                return response.Result
            })
            let fileStr = fileArr + "";  
            if(fileStr === null){
                fileStr = ''
            }
            const nameArr = e.fileList.map((item) => {
                const { name} = item
                return name
            })
            let nameStr = nameArr + ""
            setInfo(prevState=>({...prevState,eowg_image_file:fileStr,eowg_image_name:nameStr}))
        }
        
    }
    const onCreateEdit = (values) => {
        // console.log('Received values of form: ', values);
        let arr = []
        for (let propName in values) {
            if(propName.includes('subType')){
                arr.push(values[propName])
            }     
        }
        
        submitUpdateSave({
            eowg_name:info.eowg_name,
            eowg_e_id:localStorage.getItem('e_id'),
            eowg_type_id:info.eowg_type_id, 
            eowg_sub_type_id:info.eowg_sub_type_id,
            eowg_update_uid:localStorage.getItem('eu_id'),
            eowg_update_uname:localStorage.getItem('eu_name'),
            eowg_sub_name:info.eowg_sub_name,
            eowg_content:info.eowg_content,
            eowg_image_name:info.eowg_image_name,
            eowg_image_file:info.eowg_image_file,
            eowg_serial_number:info.eowg_serial_number,
            eowg_id:props.eowg_id,
        }).then((res) => {
            // console.log(res);
            if (res.data.Status === 'success') {
                message.success('修改成功！')   
                setOpen(false)                             
            }
            if (res.data.Status === 'fail') {
                message.error('修改失败！'+res.data.Message)
            }
        })
        setOpen(false);
    };   
    const editForm = useRef()
    const {data,isSuccess} = useGwGoodsUpdateQuery({eowg_id:props.eowg_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                //图片
                //默认图片
                if (data.Result.eowg_image_file && data.Result.eowg_image_file[0] !== 'null') {
                    let options = [];
                    let size = data.Result.eowg_image_file.length;
                    for (let i = 0; i < size; i++) {
                        let a = {};
                        a.uid = i;
                        a.url = `/api/assets/images/application/${data.Result.eowg_image_file[i]}`
                        a.response = {Result:data.Result.eowg_image_file[i]}
                        options.push(a);
                    }
                    //console.log(options);
                    setDefaultPic(<Upload
                        name="image_file"
                        action="/api/ReactApi/zd_single_upload_image"
                        listType="picture"
                        defaultFileList={options}
                        className="upload-list-inline"
                        onChange={handleChangeEdit}
                        >
                        <Button icon={<UploadOutlined />}>点击上传</Button>
                    </Upload>)
                }else{
                    setDefaultPic(<Upload
                        name="image_file"
                        action="/api/ReactApi/zd_single_upload_image"
                        listType="picture"
                        className="upload-list-inline"
                        onChange={handleChangeEdit}
                        >
                        <Button icon={<UploadOutlined />}>点击上传</Button>
                    </Upload>)
                }
                                            
                editForm.current.setFieldsValue({
                    eowg_name:data.Result.eowg_name,
                    eowg_sub_name:data.Result.eowg_sub_name,
                    eowg_content:data.Result.eowg_content,
                    eowg_type_id:data.Result.eowg_type_id,           
                    eowg_sub_type_id:data.Result.eowg_sub_type_id,
                    eowg_serial_number:data.Result.eowg_serial_number,
                })
                setInfo(prevState=>({...prevState,
                    eowg_name:data.Result.eowg_name,           
                    eowg_content:data.Result.eowg_content,           
                    eowg_sub_name:data.Result.eowg_sub_name,           
                    eowg_type_id:data.Result.eowg_type_id,           
                    eowg_sub_type_id:data.Result.eowg_sub_type_id,           
                    eowg_image_name:data.Result.eowg_image_name,           
                    eowg_image_file:data.Result.eowg_image_file,  
                    eowg_sub_type_name:data.Result.eowg_sub_type_name,  
                    eowg_serial_number:data.Result.eowg_serial_number,  
                             
                }))
                
            }
        }
    },[data,isSuccess])
    const [submitUpdateSave] = useGwGoodsUpdateSaveMutation()
   
    return (
        <>
            <a onClick={() => {setOpen(true)}}>修改</a>
            <Modal
                open={open}
                title='修改教程'
                okText="提交"
                cancelText="取消"
                width={1200}
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateEdit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 2 }}
                    ref={editForm}
                >
                    <GetType 
                        label='类型' 
                        name='eowg_type_id' 
                        subname='eowg_sub_type_id'
                        eowg_sub_type_name={info.eowg_sub_type_name}
                        required
                        getType={(e,f) => {
                            setInfo(prevState=>({...prevState,eowg_type_id:e,eowg_sub_type_id:f}))
                        }}
                    />
                    <Form.Item name="eowg_serial_number" label='序号' wrapperCol={{span:3}}>
                        <InputNumber  onChange={(e) => {setInfo(prevState=>({...prevState,eowg_serial_number:e}))}}/>
                    </Form.Item>
                    <Form.Item name="eowg_name" label='标题' wrapperCol={{span:20}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,eowg_name:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item name="eowg_content" label='正文' wrapperCol={{span:20}}>
                        <MyEditor  onChange={(e) => {setInfo(prevState=>({...prevState,eowg_content:e}))}} value={info.eowg_content}/> 
                    </Form.Item>
                    <Form.Item label="图片"                             
                        valuePropName='fileList'
                        getValueFromEvent={e => {
                            if (Array.isArray(e)) {
                                return e;
                            }
                            return e && e.fileList;
                        }}
                        >
                        {defaultPic}
                    </Form.Item>   
                </Form>
            </Modal>
        </>
        
    )
}

export default WebGoodsEdit