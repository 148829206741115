import React,{useState,useEffect, useRef} from 'react'
import { Form,Modal, Input,DatePicker,message,Rate,InputNumber } from 'antd'
import {useTaskManageUpdateQuery, useTaskManageUpdateSaveMutation,} from '../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import GetUsers from '../cpn/GetUsers';
import MyEditor from '../cpn/MyEditor';
import { FireOutlined} from '@ant-design/icons';
function TaskEdit(props) {
    const [form] = Form.useForm();
    const onCreate = (values) => {
        // console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [open,setOpen] = useState()
    const [info,setInfo] = useState({
        et_content: '',
        et_e_id: localStorage.getItem('e_id'),
        et_name: '',
        et_update_uid: localStorage.getItem('eu_id'),
        et_update_uname: localStorage.getItem('eu_name'),
        etu_user_id: '',
        etu_user_name: '',
        et_date:'',
        et_id:props.et_id,
        et_grade:'1',
    });
    const {data,isSuccess} = useTaskManageUpdateQuery({et_id:props.et_id},{skip:!open})
    const editForm = useRef();
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                // console.log(data);
                let etu_user_id;
                etu_user_id = data.Result.user.map((item) => {
                    return item.etu_user_id
                })
                let etu_user_name;
                etu_user_name = data.Result.user.map((item) => {
                    return item.etu_user_name
                })
                // console.log(etu_user_id);
                editForm.current.setFieldsValue({
                    et_date:dayjs(data.Result.et_date),
                    et_content:data.Result.et_content,
                    et_gs:data.Result.et_gs,
                    et_grade:data.Result.et_grade,
                    et_name:data.Result.et_name,
                    etu_user_id:etu_user_id,                    
                });
                setInfo(prevState=>({...prevState,
                    et_date:data.Result.et_date,
                    et_content:data.Result.et_content,
                    et_name:data.Result.et_name, 
                    et_gs:data.Result.et_gs, 
                    etu_user_id:etu_user_id,    
                    etu_user_name:etu_user_name,  
                    et_grade:data.Result.et_grade,           
                }))
            }
        }
    },[data,isSuccess])
    const [summitUpdateSave] = useTaskManageUpdateSaveMutation()
    return (
        <>
            {localStorage.getItem('eu_id')===props.et_uid && <a onClick={() => {setOpen(true)}}>修改</a>}
            <Modal
                open={open}
                title='修改任务'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                            // console.log('Validate Failed:', info);
                        }); 
                        summitUpdateSave(info).then((res) => {
                            // console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('修改成功！')                                
                                setOpen(false)                         
                            }
                            if (res.data.Status === 'fail') {
                                message.error('修改失败！'+res.data.Message)
                                
                            }
                        })                           
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                    ref={editForm}
                >               
                   
                    {info.etu_user_id && <GetUsers 
                        defaultId={info.etu_user_id}
                        label='参与人' 
                        required='true' 
                        name='etu_user_id'
                        onChange={(e,f) => {
                            let etu_user_id;
                            let etu_user_name;
                            etu_user_id = f.map((item) => {
                                return item.value
                            })
                            etu_user_name = f.map((item) => {
                                return item.label
                            })
                            setInfo(prevState=>({...prevState,etu_user_id:etu_user_id+'',etu_user_name:etu_user_name+''}))
                        }}
                    />}
                    <Form.Item name="et_name" label='标题' wrapperCol={{span:15}} required >
                        <Input onChange={(e) => {setInfo(prevState=>({...prevState,et_name:e.target.value,}))}}/>
                    </Form.Item>                       
                    <Form.Item name="et_content" label='备注' wrapperCol={{span:15}}>
                        <MyEditor  onChange={(e) => {setInfo(prevState=>({...prevState,et_content:e}))}}/>
                    </Form.Item>
                    <Form.Item name="et_date" label='截止日期' wrapperCol={{span:15}} required>
                        <DatePicker onChange={(e) => {setInfo(prevState=>({...prevState,et_date:dayjs(e).format('YYYY-MM-DD')}))}}/>
                    </Form.Item>
                    <Form.Item name="et_grade" label='优先级' wrapperCol={{span:15}} required>
                        <Rate 
                            character={<FireOutlined />} 
                            onChange={(e) => {
                                console.log(e);
                                setInfo(prevState=>({...prevState,et_grade:e}))
                            }} 
                            value={info.et_grade}
                        />
                    </Form.Item>
                    <Form.Item name="et_gs" label='工时' wrapperCol={{span:15}} initialValue={'0'}>                        
                        <InputNumber  onChange={(e) => {setInfo(prevState=>({...prevState,et_gs:e,}))}}/>
                    </Form.Item>    
                </Form>
            </Modal>
        </>
    )
}

export default TaskEdit