import React, { useEffect, useRef, useState } from 'react'
import { useDijieTemplateKeHuUpdateQuery, useDijieTemplateKeHuUpdateSaveMutation } from '../../../../../store/ReactWebSiteApi';
import { Form, Input, message, Modal, Radio } from 'antd';
import GetUserDept from '../../../../../cpn/GetUserDept';

function OpClientEdit(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState();
    const editForm = useRef()
    const {data,isSuccess} = useDijieTemplateKeHuUpdateQuery({ltkh_id:props.ltkh_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                editForm.current.setFieldsValue({
                    ltkh_name:data.Result.ltkh_name,
                    ltkh_content:data.Result.ltkh_content,
                    ltkh_dept_name:data.Result.ltkh_dept_name,
                    ltkh_type_id:data.Result.ltkh_type_id,
                })
                setInfo(prevState=>({...prevState,
                    ltkh_name:data.Result.ltkh_name,
                    ltkh_content:data.Result.ltkh_content, 
                    ltkh_dept_name:data.Result.ltkh_dept_name,
                    ltkh_dept_id:data.Result.ltkh_dept_id,                                 
                    ltkh_type_id:data.Result.ltkh_type_id,                                 
                }))
            }
        }
    },[data,isSuccess])
    const [submitUpdateSave] = useDijieTemplateKeHuUpdateSaveMutation()
    return (
        <>
            <a onClick={() => {setOpen(true)}}>修改</a>
            <Modal
                open={open}
                title='修改组团社'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateEdit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitUpdateSave({
                        ltkh_content: info.ltkh_content,
                        ltkh_dept_id: info.ltkh_dept_id,
                        ltkh_dept_name: info.ltkh_dept_name,
                        ltkh_e_id: localStorage.getItem('e_id'),
                        ltkh_e_name: localStorage.getItem('e_name'),
                        ltkh_name: info.ltkh_name,
                        ltkh_type_id: info.ltkh_type_id,
                        ltkh_update_uid: localStorage.getItem('eu_id'),
                        ltkh_update_uname:localStorage.getItem('eu_name'),  
                        ltkh_id:props.ltkh_id,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')   
                            setOpen(false)                             
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                    ref={editForm}
                >
                    <GetUserDept
                        label='部门' 
                        name='ltkh_dept_name'
                        required 
                        wrapperCol={{span:18}}
                        getUserDept={(e,f) => {setInfo(prevsState=>({...prevsState,ltkh_dept_id:e,ltkh_dept_name:f}))}}
                    />
                    <Form.Item name="ltkh_type_id" label='类型' wrapperCol={{span:18}} required>
                        <Radio.Group 
                            options={[
                                {
                                    value:'1',
                                    label:"组团社"
                                },
                                {
                                    value:'2',
                                    label:"游客"
                                },
                                {
                                    value:'3',
                                    label:"单位"
                                },
                            ]}
                            onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkh_type_id:e.target.value}))}}
                        />
                    </Form.Item>
                    <Form.Item name="ltkh_name" label='组团社名称' wrapperCol={{span:15}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,ltkh_name:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item name="ltkh_content" label='组团社备注' wrapperCol={{span:15}}>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,ltkh_content:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default OpClientEdit