import {Modal,} from 'antd'
import React from 'react'
function ModalPreview(props) {   
    return (
        <>
            {!props.link && <a onClick={() => {
                props.getOpen()
                }} >{props.text}</a>}
            <Modal
                open={props.open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {props.getOpen()}}
                width={props.width}
                footer={null}
            >
                {props.children}
            </Modal>
        </>
        
    )
}

export default ModalPreview