import React,{useState,useEffect,}from 'react'
import { Table,Space,Modal,Form,Input,Button} from 'antd';
import { useTourGuideListQuery, } from '../../store/ReactWebSiteApi';
import {useSearchParams } from 'react-router-dom';
import GuideAdd from './GuideAdd';
import GuideEdit from './GuideEdit';
import Main from '../../cpn/Main';
function GuideList() {
    const [param] = useSearchParams()
    const [form] = Form.useForm();      
    //查询导游===============================================================================================查询导游//
    const [openQuery,setOpenQuery] = useState();
    const [query,setQuery] = useState({tgu_e_id:localStorage.getItem('e_id'),tgu_esv_id:param.get('esv_id')})   
    const modalQuery = <Modal
                            open={openQuery}
                            title='查询客户'
                            okText="提交"
                            cancelText="取消"
                            footer={(_, { OkBtn, CancelBtn }) => (
                                <>
                                    <Button onClick={() => {                                        
                                        form.resetFields();
                                        setQuery(prevState=>({...prevState,tgu_phone:''}));
                                        setOpenQuery(false)
                                        }}>重置</Button>
                                    <CancelBtn />
                                    <OkBtn />
                                </>
                            )}
                            onCancel={() => {setOpenQuery(false);}}
                            onOk={() => {
                                form
                                    .validateFields()
                                    .then((values) => {
                                        setQuery(prevState=>({...prevState,ec_action:'search'}))
                                        setOpenQuery(false)
                                    })
                                    .catch((info) => {
                                        console.log('Validate Failed:', info);
                                    });
                                
                            }}
                        >
                            <Form
                                form={form}
                                layout="horizonal"
                                name="form_in_modal"
                                initialValues={{modifier: 'public',}}
                                labelCol={{ span: 5 }}
                            >
                                <Form.Item name="tgu_name" label='手机号码' wrapperCol={{span:10}}>
                                    <Input  onChange={(e) => {setQuery(prevState=>({...prevState,tgu_phone:e.target.value}))}}/>
                                </Form.Item>                                
                            </Form>
                        </Modal>    
    //导游列表-----------------------------------------------------------------------------------------------------------------------------导游列表
    
    const {data,isSuccess} = useTourGuideListQuery(query,{refetchOnMountOrArgChange:1})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {tgu_id:key,tgu_name,tgu_phone,tgu_uname,tgu_ctime,} = item;
                    return item = {key,tgu_name,tgu_phone,tgu_uname,tgu_ctime,}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.tgu_ctime;
                    let bTimeString = b.tgu_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    const columns = [
       
        {
            key: 'tgu_name',
            title: '导游姓名',
            dataIndex: 'tgu_name',
            width:"20%"
        },     
        {
            key: 'tgu_egt_name',
            title: '手机号码',
            dataIndex: 'tgu_phone',
            width:"20%"
        },                         
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },               
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.tgu_ctime + record.tgu_uname}</div>
                    <div>修改时间：{record.tgu_update_ctime + record.tgu_update_uname}</div>
                </>
            }
        },
        {
            key: 'action',
            width:"20%",
            title:  <Space>
                        <GuideAdd/>
                        <a onClick={() => {setOpenQuery(true);}}>查询</a>
                    </Space>,
            render: (_, record) => {
                return <>
                    <Space size='middle'>    
                        <GuideEdit 
                            tgu_id={record.key}
                        />                        
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main t1='导游'>
            <Table columns={columns} dataSource={list}></Table> 
            {modalQuery}
        </Main>
    )
}

export default GuideList