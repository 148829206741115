import React,{useState,useEffect} from 'react'
import { Table } from 'antd'
import Main from '../cpn/Main'
import { useEntPayGoodsListQuery } from '../store/ReactWebSiteApi'
function SetSubscription() {
    const {data,isSuccess} = useEntPayGoodsListQuery({epg_e_id:localStorage.getItem('e_id'),})    
    const [list, setList] = useState();    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                // console.log(data);                
                const newArr = data.Result.map(item => {
                    const {epg_id:key,epg_date,e_name,egt_name,epg_content,eg_name,epg_ctime,epg_amount,epg_uname,epg_update_ctime,epg_update_uname} = item;
                    return item = {key,epg_date,e_name,egt_name,epg_content,eg_name,epg_ctime,epg_amount,epg_uname,epg_update_ctime,epg_update_uname}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.epg_ctime;
                    let bTimeString = b.epg_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])       
    const columns = [              
        {
            key: 'e_name',
            title: '客户名称',
            dataIndex: 'e_name',
        },
        {
            key: 'egt_name',
            title: '产品类型',
            dataIndex: 'egt_name',
        },
        {
            key: 'eg_name',
            title: '产品名称',
            dataIndex: 'eg_name',
        },
        {
            key: 'epg_amount',
            title: '金额',
            dataIndex: 'epg_amount',
        },
        {
            key: 'epg_date',
            title: '截止日期',
            dataIndex: 'epg_date',
        },
        {
            key: 'epg_content',
            title: '备注',
            dataIndex: 'epg_content',
        },
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        }, 
      
    ]
    return (
        <Main  t1='系统设置' n1='/SetIndex' t2='订阅' >            
            <Table dataSource={list} columns={columns}/>
        </Main>
    )
}

export default SetSubscription