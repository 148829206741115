import React from 'react'
import Main from '../cpn/Main'
import { indexPersonnel } from '../finance/balance/balanceType';
import { Row } from 'antd';
import Folder from '../cpn/Folder';
function TrainIndex() {
    return (
        <Main t1='人事' n1={indexPersonnel} t2='培训'>
            <Row>
                <Folder title='我参与的' link='/TrainJoin'/>
                <Folder title='我负责的' link='/TrainCharge'/>
                <Folder title='培训计划' link='/TrainPlan'/>
            </Row>
        </Main>
    )
}
export default TrainIndex