import React  from 'react'
import Main from '../cpn/Main'
import {Row } from 'antd'
import Folder from '../cpn/Folder'
function GfIndexPersonnel() {
    return (
        <Main t1='人事'>
            <Row>
                <Folder title='工资' link='/Wage'/>
                <Folder title='培训' link='/TrainIndex'/>
            </Row>        
        </Main>
    )
}

export default GfIndexPersonnel