import React,{useState,useEffect,useRef}from 'react'
import Main from '../../cpn/Main'
import { Table,Button,Space,Modal,message,Form,Input,InputNumber} from 'antd';
import { useSearchParams, } from 'react-router-dom';
import { useGwGoodsTypeSubAddMutation, useGwGoodsTypeSubDelMutation, useGwGoodsTypeSubListQuery, useGwGoodsTypeSubUpdateQuery, useGwGoodsTypeSubUpdateSaveMutation } from '../../store/ReactWebSiteApi';
import {nanoid} from 'nanoid'
function WebGoodsTypeSon() {
    const [param] = useSearchParams()
    //新增产品子类型-----------------------------------------------------------------------------------------------------------------------------新增产品子类型
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [infoAdd,setInfoAdd] = useState({
        eowgts_content:'',
    });
    const [submitAdd] = useGwGoodsTypeSubAddMutation()
    const modalAdd= <Modal
                        open={openAdd}
                        title='新增产品子类型'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClient(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            
                            submitAdd({
                                eowgts_name:infoAdd.eowgts_name,
                                eowgts_e_id:localStorage.getItem('e_id'),
                                eowgts_uid:localStorage.getItem('eu_id'),
                                eowgts_uname:localStorage.getItem('eu_name'),
                                eowgts_eowgt_id:param.get('id'),
                                eowgts_serial_number:infoAdd.eowgts_serial_number,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpenAdd(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 7 }}
                        >   
                            <Form.Item name="name" label='产品子类型名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eowgts_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="serial" label='序号' wrapperCol={{span:10}} required>
                                <InputNumber  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eowgts_serial_number:e}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal> 
    //修改产品子类型-----------------------------------------------------------------------------------------------------------------------------修改产品子类型
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    const [infoEdit,setInfoEdit] = useState();
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useGwGoodsTypeSubUpdateQuery({eowgts_id:editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    eowgts_name:dataEdit.Result.eowgts_name,
                    eowgts_content:dataEdit.Result.eowgts_content,
                    eowgts_serial_number:dataEdit.Result.eowgts_serial_number,
                })
                setInfoEdit(prevState=>({...prevState,
                    eowgts_name:dataEdit.Result.eowgts_name,
                    eowgts_content:dataEdit.Result.eowgts_content,              
                    eowgts_keyid:dataEdit.Result.eowgts_keyid,              
                    eowgts_serial_number:dataEdit.Result.eowgts_serial_number,
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useGwGoodsTypeSubUpdateSaveMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改产品子类型'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitUpdateSave({
                                eowgts_name:infoEdit.eowgts_name,
                                eowgts_e_id:localStorage.getItem('e_id'),
                                eowgts_update_uid:localStorage.getItem('eu_id'),
                                eowgts_update_uname:localStorage.getItem('eu_name'),
                                eowgts_id:editId,
                                eowgts_eowgt_id:param.get('id'),
                                eowgts_serial_number:infoEdit.eowgts_serial_number,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 7 }}
                            ref={editForm}
                        >
                            <Form.Item name="eowgts_name" label='产品子类型名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eowgts_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="eowgts_serial_number" label='序号' wrapperCol={{span:10}} required>
                                <InputNumber  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eowgts_serial_number:e}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal>
    //删除产品子类型-----------------------------------------------------------------------------------------------------------------------------删除产品子类型
    const {confirm} = Modal; 
    const [submitDel] = useGwGoodsTypeSubDelMutation()
    //产品子类型列表-----------------------------------------------------------------------------------------------------------------------------产品子类型列表
    
    const {data,isSuccess} = useGwGoodsTypeSubListQuery({eowgt_id:param.get('id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                console.log(nanoid());
                
                const newArr = data.Result.map(item => {
                    const {eowgts_id:key,eowgts_name,eowgts_content,eowgts_uname,eowgts_ctime,eowgts_update_ctime,eowgts_update_uname,goods_num,eowgts_serial_number} = item;
                    return item = {key,eowgts_name,eowgts_content,eowgts_uname,eowgts_ctime,eowgts_update_ctime,eowgts_update_uname,goods_num,eowgts_serial_number}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eowgts_serial_number;
                    let bTimeString = b.eowgts_serial_number;
                    return +aTimeString - +bTimeString;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    const columns = [
        {
            key: 'eowgts_serial_number',
            title: '序号',
            dataIndex: 'eowgts_serial_number'
        },
              
        {
            key: 'tag',
            title: '产品子类型名称',
            dataIndex: 'eowgts_name',
        },           
        {
            key: 'goods_num',
            title: '产品数量',
            dataIndex: 'goods_num',
        },       
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },     
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.eowgts_ctime + record.eowgts_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>                        
                        <a onClick={() => {
                            setEditId(record.key);
                            setOpenEdit(true)
                        }}>修改</a>
                        {record.goods_num==='0' && <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({eowgts_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>}
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main  t1='商城' n1='/GfIndexMall' t2='官网' n2='/WebIndex' t3='教程'  n3='/WebGoodsType' t4={param.get('name')}>
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增</Button>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalAdd}
            {modalEdit}            
        </Main>
    )
}

export default WebGoodsTypeSon