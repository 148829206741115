import React from 'react'
import Main from '../../cpn/Main'
import { Row } from 'antd';
import Folder from '../../cpn/Folder';
function GoodsIndex() {
    return (
        <Main>            
            <Row>                
                <Folder title='产品管理'  link='/GoodsList' />          
                <Folder title='产品类型'  link='/GoodsTypeList' />          
            </Row>
        </Main>
    )
}

export default GoodsIndex