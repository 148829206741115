import React,{useState,useEffect,}from 'react'
import { Table,Space,Modal,message,Form,Input,Button} from 'antd';
import {useGoodsDelMutation, useGoodsListQuery, } from '../../store/ReactWebSiteApi';
import GetDept from '../../cpn/GetDept';
import {useSearchParams } from 'react-router-dom';
import GoodsAdd from './GoodsAdd';
import GoodsEdit from './GoodsEdit';
import SetGoodsSpec from './SetGoodsSpec';
import GetGoodsType from './GetGoodsType';
function SetGoods() {
    const [openWhole,setOpenWhole] = useState(false)
    const [param] = useSearchParams()
    const [form] = Form.useForm();  
    //删除产品-----------------------------------------------------------------------------------------------------------------------------删除产品
    const {confirm} = Modal; 
    const [submitDel] = useGoodsDelMutation()
    //查询产品===============================================================================================查询产品//
    const [openQuery,setOpenQuery] = useState();
    const [query,setQuery] = useState({eg_e_id:localStorage.getItem('e_id')})   
    const modalQuery = <Modal
                            open={openQuery}
                            title='查询产品'
                            okText="提交"
                            cancelText="取消"
                            footer={(_, { OkBtn, CancelBtn }) => (
                                <>
                                    <Button onClick={() => {                                        
                                        form.resetFields();
                                        setQuery(prevState=>({...prevState,ec_action:'',eg_name:'',eg_dept_id:'',eg_egt_id:''}));
                                        setOpenQuery(false)
                                        }}>重置</Button>
                                    <CancelBtn />
                                    <OkBtn />
                                </>
                            )}
                            onCancel={() => {setOpenQuery(false);}}
                            onOk={() => {
                                form
                                    .validateFields()
                                    .then((values) => {
                                        setQuery(prevState=>({...prevState,ec_action:'search'}))
                                        setOpenQuery(false)
                                    })
                                    .catch((info) => {
                                        console.log('Validate Failed:', info);
                                    });
                                
                            }}
                        >
                            <Form
                                form={form}
                                layout="horizonal"
                                name="form_in_modal"
                                initialValues={{modifier: 'public',}}
                                labelCol={{ span: 5 }}
                            >
                                <GetDept label='部门' getDept={(e) => {setQuery(prevState=>({...prevState,eg_dept_id:e}))}} style={{width:"200px"}}/>
                                <GetGoodsType 
                                    label='产品类型' 
                                    wrapperCol={{span:15}} 
                                    style={{width:"200px"}}
                                    getGoodsType={(e) => {
                                        setQuery(prevState=>({...prevState,eg_egt_id:e}))
                                    }}
                                />
                                <Form.Item name="eg_name" label='产品名称' wrapperCol={{span:10}}>
                                    <Input  onChange={(e) => {setQuery(prevState=>({...prevState,eg_name:e.target.value}))}}/>
                                </Form.Item>                                
                            </Form>
                        </Modal>    
    //产品列表-----------------------------------------------------------------------------------------------------------------------------产品列表
    
    const {data,isSuccess} = useGoodsListQuery(query,{refetchOnMountOrArgChange:1})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {eg_id:key,ed_name,eg_name,eg_content,eg_uname,eg_ctime,eg_update_ctime,eg_update_uname,ec_name,eg_image_file,eg_egt_name} = item;
                    return item = {key,ed_name,eg_name,eg_content,eg_uname,eg_ctime,eg_update_ctime,eg_update_uname,ec_name,eg_image_file,eg_egt_name}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eg_ctime;
                    let bTimeString = b.eg_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    const columns = [
       
        {
            key: 'eg_name',
            title: '产品名称',
            dataIndex: 'eg_name',
            width:"20%"
        },     
        {
            key: 'eg_egt_name',
            title: '产品类型',
            dataIndex: 'eg_egt_name',
            width:"20%"
        },            
        {
            key: 'ed_name',
            title: '部门',
            dataIndex: 'ed_name',
            width:"20%"
        },            
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },               
        // {
        //     key: 'time',
        //     title: '操作记录',
        //     render: (_,record) => {
        //         return <>
        //             <div>新建时间：{record.eg_ctime + record.eg_uname}</div>
        //             <div>修改时间：{record.eg_update_ctime + record.eg_update_uname}</div>
        //         </>
        //     }
        // },
        {
            key: 'action',
            width:"20%",
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space size='middle'>    
                        <GoodsEdit 
                            eg_id={record.key}
                        />
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({eg_id:record.key}).then((res) => {
                                        console.log(res);
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>
                        {/* <a onClick={() => {navi(`/OtaGoodsProfit?esv_id=22&id=${record.key}&name=${record.eg_name}`)}}>利润</a> */}
                        <SetGoodsSpec goodsName={record.eg_name} egg_eg_id={record.key}/>
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <>
            <a onClick={() => {setOpenWhole(true)}}>设置</a>
            <Modal
                open={openWhole}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenWhole(false);}}
                onOk={() => {}}
                footer={null}
                width={800}
                title='设置产品'
            >
                <Space style={{marginBottom:"20px"}}>
                    <GoodsAdd eg_esv_id={param.get('esv_id')}/>
                    <Button onClick={() => {setOpenQuery(true);}}>查询</Button>
                </Space>
                <Table 
                    columns={columns} 
                    dataSource={list} 
                    pagination={{showSizeChanger:true,
                        showTotal:(total) => `共${total}条`,}}
                />
            </Modal>             
            {modalQuery}
        </>
    )
}

export default SetGoods