import React,{useState,useEffect,useRef}from 'react'
import { Table,Button,Space,Modal,message,Form,Input, InputNumber, Radio,} from 'antd';
import { useSearchParams, } from 'react-router-dom';
import Main from '../cpn/Main'
import { useTaskAddMutation, useTaskDelMutation, useTaskListQuery, useTaskUpdateQuery, useTaskUpdateSaveMutation } from '../store/ReactWebSiteApi';
import MyEditor from '../cpn/MyEditor';
function WorkTask() {
    const [param] = useSearchParams()
    //新增任务-----------------------------------------------------------------------------------------------------------------------------新增任务
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [infoAdd,setInfoAdd] = useState({
        eppt_content:'',
    });
    const [submitAdd] = useTaskAddMutation()
    const modalAdd= <Modal
                        open={openAdd}
                        title='新增任务'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        width={1000}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClient(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitAdd({
                                eppt_esv_id:'7',
                                eppt_e_id:localStorage.getItem('e_id'),
                                eppt_dept_id:localStorage.getItem('ed_id'),
                                eppt_epp_id:param.get('id'),
                                eppt_name:infoAdd.eppt_name,
                                eppt_manhour:infoAdd.eppt_manhour,
                                eppt_content:infoAdd.eppt_content,
                                eppt_uid:localStorage.getItem('eu_id'),
                                eppt_uname:localStorage.getItem('eu_name'),                                
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpenAdd(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 3 }}
                        >                           
                            <Form.Item name="name" label='名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eppt_name:e.target.value}))}}/>
                            </Form.Item>                            
                            <Form.Item name="content" label='备注' wrapperCol={{span:15}}>
                                <MyEditor onChange={(e) => {setInfoAdd(prevState=>({...prevState,eppt_content:e}))}} value={infoAdd.eppt_content}/>
                            </Form.Item>
                            <Form.Item name="time" label='工时' wrapperCol={{span:3}} required>
                                <InputNumber onChange={(e) => {setInfoAdd(prevState=>({...prevState,eppt_manhour:e}))}}/>
                            </Form.Item> 
                        </Form>
                    </Modal> 
    //修改任务-----------------------------------------------------------------------------------------------------------------------------修改任务
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    const [infoEdit,setInfoEdit] = useState();
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useTaskUpdateQuery({eppt_id:editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    eppt_name:dataEdit.Result.eppt_name,
                    eppt_content:dataEdit.Result.eppt_content,
                    eppt_manhour:dataEdit.Result.eppt_manhour,
                })
                setInfoEdit(prevState=>({...prevState,
                    eppt_name:dataEdit.Result.eppt_name,
                    eppt_content:dataEdit.Result.eppt_content,       
                    eppt_manhour:dataEdit.Result.eppt_manhour,       
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useTaskUpdateSaveMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改任务'
                        okText="提交"
                        cancelText="取消"
                        width={1000}
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitUpdateSave({
                                eppt_esv_id:'7',
                                eppt_e_id:localStorage.getItem('e_id'),
                                eppt_dept_id:localStorage.getItem('ed_id'),
                                eppt_epp_id:param.get('id'),
                                eppt_name:infoEdit.eppt_name,
                                eppt_manhour:infoEdit.eppt_manhour,
                                eppt_content:infoEdit.eppt_content,
                                eppt_update_uid:localStorage.getItem('eu_id'),
                                eppt_update_uname:localStorage.getItem('eu_name'),
                                eppt_id:editId,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >
                            <Form.Item name="eppt_name" label='名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eppt_name:e.target.value}))}}/>
                            </Form.Item>                            
                            <Form.Item name="eppt_content" label='备注' wrapperCol={{span:15}}>
                                <MyEditor onChange={(e) => {setInfoEdit(prevState=>({...prevState,eppt_content:e}))}} value={infoAdd.eppt_content}/>
                            </Form.Item>
                            <Form.Item name="eppt_manhour" label='工时' wrapperCol={{span:3}} required>
                                <InputNumber onChange={(e) => {setInfoEdit(prevState=>({...prevState,eppt_manhour:e}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal>
    //删除任务-----------------------------------------------------------------------------------------------------------------------------删除任务
    const {confirm} = Modal; 
    const [submitDel] = useTaskDelMutation()
    //预览---------------------------------------------------------------------------------------------------------------------------预览//
    const [modalInfo,setModalInfo] = useState({
        sst_pic:[],
        picArr:'',
        grade:'',
        docArr:[],
    })
    const [modalOpen, setModalOpen] = useState(false);
    const showModal = () => {setModalOpen(true);};
    const Ok = () => {setModalOpen(false);};
    const Cancel = () => {setModalOpen(false);};
    const modalPreview =  <Modal title='预览' open={modalOpen} onOk={Ok} onCancel={Cancel} okText='关闭'>
                            <div>
                                <div style={{padding:'10px'}}>
                                    <div style={{fontSize:"24px",fontWeight:'600'}}>{modalInfo.eppt_name}</div>
                                    <div style={{marginTop:'10px'}}>
                                        <div dangerouslySetInnerHTML={{__html:modalInfo.eppt_content}} style={{maxWidth:"470px",overflow:"hidden"}}></div>
                                    </div>
                                </div>
                            </div>                
                        </Modal>
    //任务列表-----------------------------------------------------------------------------------------------------------------------------任务列表
    
    const {data,isSuccess} = useTaskListQuery({eppt_esv_id:'7',eppt_e_id:localStorage.getItem('e_id'),eppt_epp_id:param.get('id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {eppt_id:key,eppt_name,eppt_content,eppt_uname,eppt_ctime,eppt_update_ctime,eppt_update_uname,eppt_manhour} = item;
                    return item = {key,eppt_name,eppt_content,eppt_uname,eppt_ctime,eppt_update_ctime,eppt_update_uname,eppt_manhour}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eppt_ctime;
                    let bTimeString = b.eppt_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'type',
            title: '名称',
            dataIndex: 'eppt_name',
        },                         
        {
            key: 'time',
            title: '工时',
            dataIndex: 'eppt_manhour',
        },     
        {
            key: 'status',
            title: '状态',
            render:() => {
                return<>
                    <div>待确认</div>
                    <div>待评价</div>
                    <div>已结束</div>
                </>
            }
        },   
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.eppt_ctime + record.eppt_uname}</div>
                    <div>修改时间：</div>
                    <div>确认时间：</div>
                    <div>评价时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>                        
                        <a onClick={() => {
                            showModal(true);                            
                            setModalInfo(prevState=>({
                                ...prevState,
                                eppt_name:record.eppt_name,
                                eppt_content:record.eppt_content,
                            }))
                        }}>备注说明</a>
                        <a onClick={() => {
                            setEditId(record.key);
                            setOpenEdit(true)
                        }}>修改</a>
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({eppt_id:record.key}).then((res) => {
                                        console.log(res);
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>
                        <a onClick={() =>{setOpenEvaluation(true)}}>评价</a>
                    </Space>
                </>
            }
        },
    ]
    //评价任务-----------------------------------------------------------------------------------------------------------------------------评价任务
    const [openEvaluation, setOpenEvaluation] = useState(false);
    // const [IdEvaluation,setIdEvaluation] = useState()
    // const onCreateEvaluation = (values) => {
    //     console.log('Received values of form: ', values);
    //     setOpenEdit(false);
    // };
    // const [infoEvaluation,setInfoEvaluation] = useState();
    
    // const [submitEvaluation] = useWorkPlanUpdateSaveMutation()
    const modalEvaluation = <Modal
                        open={openEvaluation}
                        title='评价任务'
                        okText="提交"
                        cancelText="取消"
                        width={1000}
                        onCancel={() => {setOpenEvaluation(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitUpdateSave({
                                eppt_name:infoEdit.eppt_name,
                                eppt_content:infoEdit.eppt_content,
                                eppt_update_uid:localStorage.getItem('eu_id'),
                                eppt_update_uname:localStorage.getItem('eu_name'),
                                eppt_id:editId,
                                eppt_e_id:localStorage.getItem('e_id'),
                                eppt_dept_id:localStorage.getItem('ed_id'),
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >
                            <Form.Item name="name" label='评估等级' wrapperCol={{span:15}} required>
                                <Radio.Group>
                                    <Radio value='1'>A 优秀 超越预期</Radio>
                                    <Radio value='2'>B 合格 达到预期</Radio>
                                    <Radio value='3'>C 不合格 不及预期</Radio>
                                    <Radio value='4'>D 太差 未完成</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item name="eppt_content" label='备注' wrapperCol={{span:15}}>
                                <MyEditor onChange={(e) => {setInfoEdit(prevState=>({...prevState,eppt_content:e}))}} value={infoAdd.eppt_content}/>
                            </Form.Item>
                        </Form>
                    </Modal>
    return (
        <Main topActive='WorkPlan' app='performance' t1='工作计划' t2={param.get('name')+'-'+param.get('month')} t3='工作任务'>
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增任务</Button>
                <span style={{marginLeft:"20px"}}>数据汇总：共20项，待确认20项，待评价0项，任务工时合计3000分钟。</span>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalAdd}
            {modalEdit} 
            {modalPreview}           
            {modalEvaluation}           
        </Main>
    )
}

export default WorkTask