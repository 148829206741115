import React from 'react'
import Main from '../../cpn/Main'
import BalanceElseList from '../balance/BalanceElseList'
import { indexFinance } from '../balance/balanceType'
function Expense() {
    return (
        <Main  t1='财务' n1={indexFinance} t2='费用支出' n2='/ExpenseIndex' t3='费用支出单' >
            <BalanceElseList
                main_type_id='3'
                main_type_name='费用支出单'
            />
        </Main>
    )
}

export default Expense