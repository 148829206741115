
import Main from '../cpn/Main'
import React, { useEffect, useState } from 'react'
import { Space,Table,Tag,Rate} from 'antd'
import TaskCheck from './TaskCheck'
import { useTaskManageListQuery } from '../store/ReactWebSiteApi'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn';
import { FireOutlined} from '@ant-design/icons';
import TaskReceiveQuery from './TaskReceiveQuery'
import TaskMessageAdd from './TaskMessageAdd'
import TaskAuditReceive from './TaskAuditReceive'
import TaskDel from './TaskDel'

function TaskReceive() {
    const [list,setList] = useState();
    const [query,setQuery] = useState({
        et_e_id:localStorage.getItem('e_id'),
        action:localStorage.getItem('task_action'),
        etu_user_id:localStorage.getItem('eu_id'),
        et_uid:'',
        et_status:localStorage.getItem('task_et_status'),
        et_name:localStorage.getItem('task_et_name'),
        et_e_date:localStorage.getItem('task_et_e_date'),
        et_s_date:localStorage.getItem('task_et_s_date'),
    });

    const {data,isSuccess} = useTaskManageListQuery(query,{refetchOnMountOrArgChange:1});
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                // console.log(data);
                const arr = data.Result.map((item) => {
                    const {et_id:key,et_name,et_uname,et_ctime,et_uid,et_date,user,et_status,et_content,et_grade,et_gs} = item;
                    const etu_user_id = user.map((item,index) => {
                        return item.etu_user_id
                    })
                    const etu_user_name = user.map((item,index) => {
                        return item.etu_user_name
                    })
                    return item = {key,et_name,et_uname,et_ctime,et_uid,et_date,user,et_status,etu_user_id,etu_user_name,et_content,et_grade,et_gs}
                })
                const sortArr = arr.sort( (a, b) => {      
                    let aTimeString = a.et_ctime;
                    let bTimeString = b.et_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr);
            }else{
                setList('');
            }
        }
    },[data,isSuccess])

    const columns = [
        {
            key: '1',
            title: '状态',
            dataIndex: 'et_name',
            render:(e,f) => {
                let et_status_name
                switch (f.et_status) {
                    case '1':
                        et_status_name = <Tag color='#f50'>待完成</Tag>
                        break;
                    case '2':
                        et_status_name = <Tag color='#2db7f5'>待验收</Tag>
                        break;
                    case '3':
                        et_status_name = <Tag color='#87d068'>已验收</Tag>
                        break;
                    default:
                        break;
                }
                return <>{et_status_name}</>
            },
            width:"5%"
        }, 
        {
            key: '2',
            title: '发送人',
            dataIndex: 'et_uname',            
            width:"5%"
        },
        {
            key: '3',
            title: '时间',
            dataIndex: 'et_ctime', 
            sorter: {
                compare: (a, b) => {
                    let aTimeString = a.et_ctime;
                    let bTimeString = b.et_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;   
                },
            },           
            width:"8%"
        },       
        {
            key: '4',
            title: '任务名称',
            dataIndex: 'et_name',
            render:(e,f) => {                
                return <><TaskCheck et_id={f.key} etu_user_id={f.etu_user_id} name={e}/></>
            },
            width:'40%',
        }, 
        {
            key: '5',
            title: '截止日期',
            dataIndex: 'et_date', 
            render:(text) => {
                return <>{dayjs(text).locale('zh-cn').format('M月DD日(周dd)')}</>
            },
            sorter: {
                compare: (a, b) => new Date(a.et_date).getTime() - new Date(b.et_date).getTime(),
            },
            width:'10%',
        },
        {
            key: '6',
            title: '优先级',
            dataIndex: 'et_grade', 
            render:(e) => {
                return <Rate value={+e} count={+e} character={<FireOutlined />} />
            },
            sorter: {
                compare: (a, b) => a.et_grade - b.et_grade,
            },
        }, 
        {
            key: '9',
            title: '工时',
            dataIndex: 'et_gs', 
        },  
        {
            key: '7',
            title: '编号',
            dataIndex: 'key', 
        },      
        {
            key: '8',
            width:'9%',  
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space>
                        <TaskAuditReceive 
                            et_id={record.key}
                            et_uid={record.et_uid}
                            etu_user_id={record.etu_user_id}
                            et_status={record.et_status}
                        />                        
                        <TaskMessageAdd 
                            et_id={record.key}
                            et_uid={record.et_uid}
                            etu_user_id={record.etu_user_id}
                        />
                        {localStorage.getItem('eu_name')===record.et_uname &&  <TaskDel et_id={record.key} et_uid={record.et_uid} />}                       
                    </Space>
                </>
            }
        },
    ]
    return (
        <Main  t1='任务' n1='/TaskIndex'  t2='任务接收'>
            <Space size='large'  style={{marginBottom:"10px"}}>                 
                <TaskReceiveQuery
                    getQuery={(values) => {
                        console.log(values);
                        setQuery(prevState=>({...prevState,
                            action:values.action,
                            et_status:values.et_status,
                            et_name:values.et_name,
                            et_s_date:values.et_s_date?values.et_s_date:'',
                            et_e_date:values.et_e_date?values.et_e_date:'',  
                        }))  
                    }}
                />
                
            </Space>
            <Table 
                columns={columns} 
                dataSource={list} 
                pagination={{
                    defaultPageSize:50,
                    showSizeChanger:true,
                    showTotal:(total) => `共${total}条`,
                    onChange:(e,f) => {
                        localStorage.setItem('ebn_query_page',e);
                        localStorage.setItem('ebn_query_page_size',f);
                    },
                    showTitle:true
                }}/>
        </Main>
    )
}

export default TaskReceive