import React,{useState,useEffect,useRef}from 'react'
import { Table,Button,Space,Modal,message,Form,DatePicker,InputNumber,Input,Popover} from 'antd';
import {  useNavigate, } from 'react-router-dom';
import { useWageAddMutation, useWageConfirmCancelMutation, useWageDelMutation, useWageListQuery, useWageRemitStateMutation, useWageUpdateQuery, useWageUpdateSaveMutation } from '../store/ReactWebSiteFinanceApi';
import GetUser from '../cpn/GetUser';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { BulbOutlined } from '@ant-design/icons';
import Main from '../cpn/Main'
import { indexPersonnel } from '../finance/balance/balanceType';
const { TextArea } = Input;
function Wage() {
    //新增工资条-------------------------------------------------------------------------------------新增工资条//
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const [infoAdd,setInfoAdd] = useState({
        ew_kqgznum:'',
        ew_content:'',
    });
    //获取员工
    const getUser = (e,f,g) => {
        // console.log(e,f,g);
        setInfoAdd(prevState=>({...prevState,ew_user_id:e,ew_user_name:f,}))
    } 
    const onCreateAdd = (values) => {
        console.log('Received values of form: ', values);
        wageAdd({
            ew_e_id:localStorage.getItem('e_id'),
            ew_dept_id:localStorage.getItem('ed_id'),
            ew_user_id:infoAdd.ew_user_id,            
            ew_user_name:infoAdd.ew_user_name,
            ew_month:infoAdd.ew_month,
            ew_kqgznum:infoAdd.ew_kqgznum,
            ew_ysb_num:infoAdd.ew_ysb_num,
            ew_rgz_num:(infoAdd.ew_kqgznum/infoAdd.ew_ysb_num).toFixed(2),
            ew_kq_num:infoAdd.ew_kq_num,
            ew_content:infoAdd.ew_content,
            ew_jb_num:(infoAdd.ew_kqgznum/infoAdd.ew_ysb_num*infoAdd.ew_kq_num).toFixed(2),
            ew_uid:localStorage.getItem('eu_id'),
            ew_uname:localStorage.getItem('eu_name'),
            ew_esv_id:'3',
        }).then((res) => {
            if (res.data.Status === 'success') {
                message.info('新增成功！');
            }
            if (res.data.Status === "fail") {
                message.error('新增失败！'+res.data.Message)
            }
        })
        setOpenAdd(false);
    };
    const [wageAdd] = useWageAddMutation();
    const modalAdd = <Modal
                        open={openAdd}
                        title='新增工资条'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateAdd(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 7 }}
                        >
                            <Form.Item name="ew_month" label='月份' wrapperCol={{span:15}} required>
                                <DatePicker picker="month" onChange={(e) => {setInfoAdd(prevState=>({...prevState,ew_month:dayjs(e).format('YYYY-MM')}))}}/>
                            </Form.Item>
                            <GetUser label='员工'  getUser={getUser}/>                
                            <Form.Item name="ew_kqgznum" label='考勤工资基数' wrapperCol={{span:15}} required>
                                <InputNumber addonAfter='元/月' onChange={(e) => {setInfoAdd(prevState=>({...prevState,ew_kqgznum:e}))}}/>
                            </Form.Item>
                            <Form.Item name="ew_ysb_num" label='应上班天数' wrapperCol={{span:15}}>
                                <InputNumber onChange={(e) => {setInfoAdd(prevState=>({...prevState,ew_ysb_num:e}))}}/>
                            </Form.Item>
                            <Form.Item  label='日工资基数' wrapperCol={{span:15}}>                        
                                {(infoAdd.ew_kqgznum && infoAdd.ew_ysb_num) ?((infoAdd.ew_kqgznum/infoAdd.ew_ysb_num).toFixed(2)+'元'):'0.00元'}
                            </Form.Item> 
                            <Form.Item name="ew_kq_num" label='考勤天数' wrapperCol={{span:15}}>
                                <InputNumber  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ew_kq_num:e}))}}/>
                            </Form.Item>
                            <Form.Item  label='基本工资' wrapperCol={{span:15}}>
                                {(infoAdd.ew_kqgznum && infoAdd.ew_ysb_num && infoAdd.ew_kq_num) ?((infoAdd.ew_kqgznum/infoAdd.ew_ysb_num*infoAdd.ew_kq_num).toFixed(2)+'元'):'0.00元'}                        
                            </Form.Item> 
                            <Form.Item name="ew_content" label='备注' wrapperCol={{span:15}}>
                                <TextArea rows={4} onChange={(e) => {setInfoAdd(prevState=>({...prevState,ew_content:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal>
    //修改工资条-------------------------------------------------------------------------------------修改工资条//
    const editForm = useRef()
    const [editId,setEditId] = useState();
    const {data:dataEdit,isSuccess:isSuccessEdit} = useWageUpdateQuery({ew_id:editId})
    
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    ew_month:dayjs(dataEdit.Result.ew_month),
                    ew_kqgznum:dataEdit.Result.ew_kqgznum,
                    ew_ysb_num:dataEdit.Result.ew_ysb_num,
                    ew_rgz_num:dataEdit.Result.ew_rgz_num,
                    ew_kq_num:dataEdit.Result.ew_kq_num,
                    ew_jb_num:dataEdit.Result.ew_jb_num,
                    ew_content:dataEdit.Result.ew_content,
                })
                setInfoEdit(prevState=>({...prevState,
                    ew_user_name:dataEdit.Result.ew_user_name,
                    ew_user_id:dataEdit.Result.ew_user_id,
                    ew_kqgznum:dataEdit.Result.ew_kqgznum,
                    ew_ysb_num:dataEdit.Result.ew_ysb_num,
                    ew_rgz_num:dataEdit.Result.ew_rgz_num,
                    ew_kq_num:dataEdit.Result.ew_kq_num,
                    ew_jb_num:dataEdit.Result.ew_jb_num,
                    ew_content:dataEdit.Result.ew_content,
                    ew_month:dataEdit.Result.ew_month,
                }))
            }
        }
    },[dataEdit, isSuccessEdit])
    const [wageUpdateSave] = useWageUpdateSaveMutation()
    const [openEdit, setOpenEdit] = useState(false);
    const [infoEdit, setInfoEdit] = useState('')
    const onCreateEdit = () => {
        wageUpdateSave({
            ew_e_id:localStorage.getItem('e_id'),
            ew_dept_id:localStorage.getItem('ed_id'),
            ew_user_id:infoEdit.ew_user_id,            
            ew_user_name:infoEdit.ew_user_name,
            ew_month:infoEdit.ew_month,
            ew_kqgznum:infoEdit.ew_kqgznum,
            ew_ysb_num:infoEdit.ew_ysb_num,
            ew_rgz_num:(infoEdit.ew_kqgznum/infoEdit.ew_ysb_num).toFixed(2),
            ew_kq_num:infoEdit.ew_kq_num,
            ew_content:infoEdit.ew_content,
            ew_jb_num:(infoEdit.ew_kqgznum/infoEdit.ew_ysb_num*infoEdit.ew_kq_num).toFixed(2),
            ew_update_uid:localStorage.getItem('eu_id'),
            ew_update_uname:localStorage.getItem('eu_name'),
            ew_id:editId,
            ew_esv_id:'3',
        }).then((res) => {
            console.log(res);
            if (res.data.Status === 'success') {
                message.success('修改成功！')   
                setOpenEdit(false)                             
            }
            if (res.data.Status === 'fail') {
                message.error('修改失败！'+res.data.Message)
            }
        })
    }
    const modalEdit =   <Modal
                            open={openEdit}
                            title='新增工资条'
                            okText="提交"
                            cancelText="取消"
                            onCancel={() => {setOpenEdit(false);}}
                            onOk={() => {
                                form
                                    .validateFields()
                                    .then((values) => {
                                        form.resetFields();
                                        onCreateEdit(values);
                                    })
                                    .catch((info) => {
                                        console.log('Validate Failed:', info);
                                    });
                            }}
                        >
                            <Form
                                form={form}
                                layout="horizonal"
                                name="form_client_eidt"
                                initialValues={{modifier: 'public',}}
                                labelCol={{ span: 7 }}
                                ref={editForm}
                            >
                                <Form.Item name="ew_month" label='月份' wrapperCol={{span:15}} required>
                                    <DatePicker picker="month" onChange={(e) => {setInfoEdit(prevState=>({...prevState,ew_month:dayjs(e).format('YYYY-MM')}))}}/>
                                </Form.Item>
                                <GetUser label='员工' defaultId={infoEdit.ew_user_name}/>
                                <Form.Item name="ew_kqgznum" label='考勤工资基数' wrapperCol={{span:15}} required>
                                    <InputNumber addonAfter='元/月' onChange={(e) => {setInfoEdit(prevState=>({...prevState,ew_kqgznum:e}))}}/>
                                </Form.Item>
                                <Form.Item name="ew_ysb_num" label='应上班天数' wrapperCol={{span:15}}>
                                    <InputNumber onChange={(e) => {setInfoEdit(prevState=>({...prevState,ew_ysb_num:e}))}}/>
                                </Form.Item>
                                <Form.Item  label='日工资基数' wrapperCol={{span:15}}>                        
                                    {(infoEdit.ew_kqgznum/infoEdit.ew_ysb_num).toFixed(2)+'元'}
                                </Form.Item> 
                                <Form.Item name="ew_kq_num" label='考勤天数' wrapperCol={{span:15}}>
                                    <InputNumber  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ew_kq_num:e}))}}/>
                                </Form.Item>
                                <Form.Item  label='基本工资' wrapperCol={{span:15}}>
                                    {(infoEdit.ew_kqgznum/infoEdit.ew_ysb_num*infoEdit.ew_kq_num).toFixed(2)+'元'}                        
                                </Form.Item> 
                                <Form.Item name="ew_content" label='备注' wrapperCol={{span:15}}>
                                    <TextArea rows={4} onChange={(e) => {setInfoEdit(prevState=>({...prevState,ew_content:e.target.value}))}}/>
                                </Form.Item>
                            </Form>
                        </Modal>
    //删除工资条-------------------------------------------------------------------------------------------------------------------------------删除工资条//
    const {confirm} = Modal; 
    const [wageDel] = useWageDelMutation()
    //审核工资条-------------------------------------------------------------------------------------------------------------------------------审核工资条//    
    const [wageConfirmCancel] = useWageConfirmCancelMutation()
    //提交工资条-------------------------------------------------------------------------------------------------------------------------------提交工资条//
    const [wageRemitState] = useWageRemitStateMutation()
    //工资条列表-------------------------------------------------------------------------------------------------------------------------------工资条列表//
    
    const {data, isSuccess} = useWageListQuery({ew_e_id:localStorage.getItem('e_id')});
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if (isSuccess) {
            //console.log(data);
            if(data.Status==='success'){
                
                const newArr = data.Result.map(item => {
                    const {ew_id:key,yf_data,yk_data,ew_month,ew_user_name,ew_kqgznum,ew_ysb_num,ew_rgz_num,ew_kq_num,ew_jb_num,ew_content,ew_uname,ew_ctime,ew_state,ew_user_confirm_ctime,ew_confirm_ctime,ew_confirm_uname,ew_user_confirm_uname,ew_remit_ctime,ew_remit_uname,ew_update_ctime,ew_update_uname,ew_user_account} = item;
                    let yf;
                    if(yf_data){
                        yf = yf_data.map((item) => {
                            const {ews_id,ews_amount,ews_sub_name,ews_content} = item;
                            return <div key={ews_id}>{ews_sub_name + " : " + ews_amount} {ews_content && <Popover content={ews_content} title="备注"><BulbOutlined /></Popover>} </div>
                        })
                    }
                    let yf_total = 0
                    if(yf_data){
                        yf_total = yf_data.reduce((acc, cur) => acc + +cur.ews_amount, 0)
                    }
                    let yk;
                    if(yk_data){
                        yk = yk_data.map((item) => {
                            const {ews_id,ews_amount,ews_sub_name,ews_content} = item;
                            return <div key={ews_id}>{ews_sub_name + " : " + ews_amount} {ews_content && <Popover content={ews_content} title="备注"><BulbOutlined /></Popover>} </div>
                        })
                    }
                    let yk_total = 0
                    if(yk_data){
                        yk_total = yk_data.reduce((acc, cur) => acc + +cur.ews_amount, 0)
                    }
                    let sf
                    sf = +ew_jb_num + yf_total - yk_total
                    return item = {key,yf,yf_total,yk,yk_total,sf,ew_month,ew_user_name,ew_kqgznum,ew_ysb_num,ew_rgz_num,ew_kq_num,ew_jb_num,ew_content,ew_uname,ew_ctime,ew_state,ew_user_confirm_ctime,ew_confirm_ctime,ew_confirm_uname,ew_user_confirm_uname,ew_remit_ctime,ew_remit_uname,ew_update_ctime,ew_update_uname,ew_user_account}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ew_ctime;
                    let bTimeString = b.ew_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    //获取参数
    const navi = useNavigate();
    
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'room',
            title: '月份',            
            dataIndex: 'ew_month',
        },
        {
            key: 'type',
            title: '员工姓名',            
            dataIndex: 'ew_user_name',
        },
       
        {
            key: 'num',
            title: '基本工资',            
            render:(_,record) => {
                return<>
                    <div>月工资基数：{record.ew_kqgznum}</div>
                    <div>应上班天数：{record.ew_ysb_num}</div>
                    <div>日工资基数：{record.ew_rgz_num}</div>
                    <div>考勤天数：{record.ew_kq_num}</div>
                    <div>基本工资：{record.ew_jb_num}</div>
                </>
            }
        },               
       
        {
            key: 'dayFact',
            title: '应发',  
            dataIndex: 'yf', 
            render:(text,record) => {
                return<>
                    {text}
                    <div>小计：{record.yf_total}</div>
                </>
            }          
        },
        {
            key: 'kou',
            title: '应扣',  
            dataIndex: 'yk',   
            render:(text,record) => {
                return<>
                    {text}
                    <div>小计：{record.yk_total}</div>
                </>
            } 
        },
        {
            key: 'total',
            title: '实发', 
            dataIndex: 'sf',
        },
        {
            key: 'dayPlan',
            title: '备注/银行卡号',            
            dataIndex: 'ew_content',
            render:(text,record) => {
                return<>
                    {(text && text !=='undefined') && <div>备注：{text}</div>}
                    {record.ew_user_account && <div>银行卡号：{record.ew_user_account}</div>}
                </>
            }
        },
        {
            key: 'state',
            title: '状态',            
            dataIndex: 'ew_state',
            render:(text) => {
                if(text==='0'){
                    return<>待审核</>
                }
                if(text==='1'){
                    return<>已审核</>
                }
                if(text==='2'){
                    return<>已提交</>
                }
                if(text==='3'){
                    return<>已确认</>
                }
            }
        },
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新增：{record.ew_ctime + record.ew_uname}</div>
                    {record.ew_update_ctime && <div>修改：{record.ew_update_ctime + record.ew_update_uname}</div>}
                    {record.ew_confirm_ctime && <div>审核：{record.ew_confirm_ctime + record.ew_confirm_uname}</div>}
                    {record.ew_confirm_ctime && <div>提交：{record.ew_remit_ctime + record.ew_remit_uname}</div>}
                    {record.ew_user_confirm_ctime && <div>确认：{record.ew_user_confirm_ctime + record.ew_user_confirm_uname}</div>}
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>                        
                        {record.ew_state==='0'&& <a onClick={() => {setEditId(record.key);setOpenEdit(true)}}>修改</a>}
                        {record.ew_state==='0'&& <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    wageDel({ew_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>}
                        {record.ew_state==='0' && <a onClick={() => {navi(`/WageDetail?id=${record.key}&name=${record.ew_user_name}&month=${record.ew_month}`, { state: {openKeys:'wage',active:"Wage"}})}}>应发应扣</a>}
                        {/* {((record.ew_state==='0' || record.ew_state==='1') && checkLimit) && <a onClick={() => { */}
                        {(record.ew_state==='0' || record.ew_state==='1') && <a onClick={() => {
                            confirm({
                                title: record.ew_state==='0'?'确定审核吗?':'确定取消吗？',
                                content: record.ew_state==='0'?'审核后可以取消':'取消后可以审核',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                   if(record.ew_state==='0'){
                                        wageConfirmCancel({ew_id:record.key,ew_state:'0',ew_confirm_uid:localStorage.getItem('eu_id'),ew_confirm_uname:localStorage.getItem('eu_name')}).then((res) => {
                                            if (res.data.Status === 'success') {
                                                message.info('审核成功！');
                                            }
                                            if (res.data.Status === "fail") {
                                                message.error('审核失败！'+res.data.Message)
                                            }
                                        })
                                    }else{
                                        wageConfirmCancel({ew_id:record.key,ew_state:'1',ew_confirm_uid:localStorage.getItem('eu_id'),ew_confirm_uname:localStorage.getItem('eu_name')}).then((res) => {
                                            if (res.data.Status === 'success') {
                                                message.info('取消成功！');
                                            }
                                            if (res.data.Status === "fail") {
                                                message.error('取消失败！'+res.data.Message)
                                            }
                                        })
                                    }
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>{record.ew_state==='0'?'审核':'取消审核'}</a>}
                        {(record.ew_state==='1'||record.ew_state==='2') && <a onClick={() => {
                            confirm({
                                title: record.ew_state==='1'?'确定提交吗?':'确定取消提交吗？',
                                content: record.ew_state==='1'?'提交后可以取消':'取消后可以提交',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                   if(record.ew_state==='1'){
                                        wageRemitState({ew_id:record.key,ew_state:'1',ew_remit_uid:localStorage.getItem('eu_id'),ew_remit_uname:localStorage.getItem('eu_name')}).then((res) => {
                                            if (res.data.Status === 'success') {
                                                message.info('提交成功！');
                                            }
                                            if (res.data.Status === "fail") {
                                                message.error('提交失败！'+res.data.Message)
                                            }
                                        })
                                    }else{
                                        wageRemitState({ew_id:record.key,ew_state:'2',ew_remit_uid:localStorage.getItem('eu_id'),ew_remit_uname:localStorage.getItem('eu_name')}).then((res) => {
                                            if (res.data.Status === 'success') {
                                                message.info('取消成功！');
                                            }
                                            if (res.data.Status === "fail") {
                                                message.error('取消失败！'+res.data.Message)
                                            }
                                        })
                                    }
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>{record.ew_state==='1'?'提交':'取消提交'}</a>}
                    </Space>
                </>
            }
        },
    ]
    return (
        <Main t1='人事' n1={indexPersonnel} t2='工资'>
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true)}}>新增工资条</Button>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table>
            {modalAdd}                       
            {modalEdit}                       
        </Main>
    )
}

export default Wage