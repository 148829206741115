import { Select,Form ,} from 'antd'
import React, { useEffect, useState } from 'react'
import { useEvaluateTypeListQuery, } from '../store/ReactWebSiteApi';

function GetStudyEvaluateType(props) {
    const [list,setList] = useState();
    const {data, isSuccess} = useEvaluateTypeListQuery();
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                //console.log(data);
                const newArr = data.Result.map((item) => {
                    const {et_id,et_name,}=item;
                    return item = {value:et_id,label:et_name}
                })
                setList(newArr)
            }
        }
    },[data,isSuccess])
    return (
        <>
            <Form.Item 
                label={props.label} 
                wrapperCol={props.wrapperCol} 
                required            
                name={props.name}    
            >                
                <Select 
                    options={list} 
                    onChange={(_,f) => {
                        // console.log(f);
                        props.getStudyEvaluateType(f.value,f.label)
                    }}
                    placeholder={props.defaultId}
                    style={props.style}
                />            
            </Form.Item>
        </>
    )
}

export default GetStudyEvaluateType