import React  from 'react'
import classes from './home.module.css'
import { useNavigate, } from 'react-router-dom';
import { Affix, Space, Menu} from 'antd';
function HomeTop(props) {
    const navi = useNavigate();
    const items = [
        
        {
            label: '旅当家',
            key: '1',
        },
        {
            label: '联系我们',
            key: '2',
        },        
    ]
    return (
        <>
            <Affix offsetTop='0'>
                <div className={classes.mainHeaderRow}>
                    <div className={classes.mainHeader}>
                        <div className={classes.mainHeaderLogo}>
                            <div>BNS 伯纳思科技</div>
                            <div  className={classes.mainHeaderBottom}>您的系统供应商</div>
                        </div>
                        <Space style={{width:'60%'}} align='center' size='large'>
                            <Menu 
                                items={items} 
                                mode='horizontal' 
                                onClick={(e) => {
                                    switch (e.key) {                                        
                                        case '1':
                                            navi('/TaIndex')
                                            break;
                                        case '2':
                                            window.open('https://work.weixin.qq.com/kfid/kfcc1a9500fb365f216')
                                            break;                                                               
                                        default:
                                            break;
                                    }                        
                                }}
                                style={{fontSize:"18px"}}
                            />                                                        
                        </Space>
                    </div>
                </div>
            </Affix>
        </>
        
    )
}

export default HomeTop