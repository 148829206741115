import React  from 'react'
import Main from '../cpn/Main'
import classes from '../site/site.module.css'
function Personal() {
    return (
        <Main background='gray' active={'Personal'}>
            <div  className={classes.homeTitle}>人事管理</div>
        </Main>
    )
}

export default Personal