import React from 'react'
import Main from '../../cpn/Main'
import { Row } from 'antd';
import Folder from '../../cpn/Folder';

function FirmIndex() {

    return (
        <Main t1='商城' n1='/GfIndexMall' t2='企业'>
            <Row>
                <Folder title='企业' link='/FirmList'/>
                <Folder title='台账汇总' link='/FirmSumLedger'/>
                <Folder title='收支汇总' link='/FirmSumBalance'/>
                <Folder title='往来汇总' link='/FirmSumArap'/>
                <Folder title='任务汇总' link='/FirmSumTask'/>
                <Folder title='订单' link='/FirmOrder'/>
                <Folder title='账号列表' link='/FirmAccount'/>
            </Row>
        </Main>
    )
}

export default FirmIndex