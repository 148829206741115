import React, { useState } from 'react'
import { Button,Form,Modal,Input} from 'antd'
function FirmAccountQuery(props) {
    const [open,setOpen] = useState()
    const [form] = Form.useForm();   
    return (
        <>
            <Button  style={{marginLeft:"15px"}} onClick={() => {setOpen(true)}}>查询</Button>            
            <Modal
                open={open}
                title='查询账号'
                okText="提交"
                cancelText="取消"                
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            props.getQuery({
                                ba_phone:values.ba_phone,                                                    
                            })                      
                            setOpen(false)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_in_modal"
                    initialValues={{modifier: 'public',efst_finance_type_id:props.type_id}}
                    labelCol={{ span: 5 }}
                >                               
                    <Form.Item 
                        name="ba_phone" 
                        label='手机号码' 
                        wrapperCol={{span:15}}
                    >
                        <Input  />
                    </Form.Item>                                                            
                </Form>
            </Modal>
        </>
    )
}

export default FirmAccountQuery