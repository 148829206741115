import React,{useState,useEffect,useRef } from 'react'
import Main from '../../cpn/Main'
import { useGetCityQuery} from '../../store/ReactWebSiteApi'
import { Table,  Button, Form, Input, Space,Select} from 'antd';
import { Link,useNavigate } from 'react-router-dom';
import { useGetProvinceQuery } from '../../store/ReactWebSiteApi';

function City(props) {
  //获取省数据
  const [province, setProvince] = useState();
  const [selectProvince, setSelectProvince] = useState();
  //查询数据
  const [queryData, setQueryData] = useState({
    province_id:'',
    search_key: '',
    action:'search',
  });
  //API
  const { data: dataProvince, isSuccess: isSuccessProvince } = useGetProvinceQuery(queryData);
  useEffect(() => {
    if (isSuccessProvince) {
      console.log(dataProvince);
      const newArr = dataProvince.Result.map((item) => {
        const { ctap_id:value, ctap_name:label } = item
        return item = { value, label }
      })
      setProvince(newArr);
    }
  },[dataProvince,isSuccessProvince,queryData])
  //设置链接
  const navi = useNavigate();  
  
  //绑定查询省
  const handleChange = (e) => {    
    setQueryData(prevState => ({ ...prevState, province_id: e }))
    setSelectProvince(e);
  }
  //绑定查询城市名称
  const cityNameInput = (e) => {
    setQueryData(prevState => ({ ...prevState, search_key: e.target.value }));
  }
  //引入表单
  const queryForm = useRef();
  //点击重置按钮
  const reset = () => {
    queryForm.current.resetFields();
    setQueryData(prevState => ({ ...prevState, search_key: '',province_id:'', }))
    setSelectProvince(undefined)
  }
  //列表数据
  const [cityList, setCityList] = useState();
  //API
  const { data, isSuccess } = useGetCityQuery(queryData,{refetchOnMountOrArgChange:1});
  useEffect(() => {
    if (isSuccess) {
      if (data.Status === 'success') {
        const newArr = data.Result.map((item) => {
          const { ctaci_id: key, ctap_name, ctaci_name,ctaci_remark } = item;
          return item = {key, ctap_name, ctaci_name,ctaci_remark }
        })
        setCityList(newArr);
      }
    }
  },[data,isSuccess])
  //表头
  const columns = [
    {
      title: 'ID',
      dataIndex: 'key',
      width: '3%',
      defaultSortOrder: 'descend',
      sorter:  (a, b) => {
          return a.key - b.key;
        } 
    },        
    {
      title: '省份',
      dataIndex: 'ctap_name',
      width:'15%'
    },
        
    {
      title: '城市',
      dataIndex: 'ctaci_name',
      width:'15%'
    },
    {
      title: '备注',
      dataIndex: 'ctaci_remark',
      width:'15%'
    },        
    {
      title: '操作',
      width: '15%',
      render: (record) => (
        <Space size="middle">
          <a onClick={() => {
            navi(`/CityEdit/${record.key}`)   
          }}>修改</a>
        </Space>
      )
    }
  ];
    
  //列表分页
  const onChange = (pagination, filters, sorter, extra) => {
      console.log('params', pagination, filters, sorter, extra);
  };
  
  return (
    <Main  t1='商城' n1='/GfIndexMall' t2='地区' n2='/AreaIndex' t3="城市">
      <div style={{ marginBottom: '20px', display: 'flex' }}>
        <Link to='/CityAdd'><Button type="primary">新增城市</Button></Link>
        <Form
          name='query'
          style={{ marginLeft: '30px' }}
          layout={'inline'}
          ref={queryForm}
        >
          <Form.Item>
            <Select
              style={{ width: 120,}}
              onChange={handleChange}
              options={province}
              value={selectProvince}
              placeholder='请选择省'
            />
          </Form.Item>
          <Form.Item name='ctaci_name' label="城市" >
              <Input placeholder="请输入城市名称" value={queryData.search_key} onChange={cityNameInput} />
          </Form.Item>
          <Button onClick={reset}>重置</Button>
        </Form>
      </div>
      <Table
          columns={columns}
          dataSource={cityList}
          onChange={onChange}
          pagination={{ showTotal: total => `共${cityList.length}条数据`, showSizeChange: true, showQuickJumper: true }} />
    </Main>
  )
}
export default City