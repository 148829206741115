import React,{useEffect, useState}from 'react'
import {Space,TreeSelect, } from 'antd';
import Main from '../../cpn/Main';
import { useQuanxianTreeListQuery } from '../../store/ReactWebSiteApi';
import { useSearchParams } from 'react-router-dom';
import AppPowerAdd from './AppPowerAdd';
import AppPowerDel from './AppPowerDel';
import AppPowerEdit from './AppPowerEdit';
function AppPower() {
    const [param] = useSearchParams()
    const [power,setPower] = useState({qx_parent_id:''})
    const [tree, setTree] = useState();
    //列表
    const {data,isSuccess} = useQuanxianTreeListQuery({qx_type:param.get('qx_type')})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                setTree([data.Result])
            }
        }
    },[data,isSuccess])
    return (
        <Main  t1='商城' n1='/GfIndexMall' t2='应用管理' n2='/AppIndex' t3={param.get('name')}>
            <Space>                
                <TreeSelect
                    style={{width:"1000px"}}                 
                    onChange={(e,f) => {
                        console.log(e,f);
                        setPower(prevState=>({...prevState,qx_parent_id:e.value,qx_id:e.value,qx_parent_name:e.label}))
                    }}
                    treeData={tree}    
                    treeDefaultExpandAll     
                    labelInValue 
                    treeTitleRender={(e) => e.value+e.title}                         
                />
                <AppPowerAdd qx_type={param.get('qx_type')}  qx_parent_id={power.qx_parent_id} qx_parent_name={power.qx_parent_name}/>
                <AppPowerDel  qx_type={param.get('qx_type')} qx_id={power.qx_id}/>
                <AppPowerEdit  qx_type={param.get('qx_type')}  qx_id={power.qx_id}/>
            </Space>
            
        </Main>
    )
}

export default AppPower