import { Descriptions, Modal, Space } from 'antd'
import React, { useState } from 'react'
import {DatabaseOutlined} from '@ant-design/icons';
function ModalType(props) {
    const [open,setOpen] = useState(false)

    return (
        <>
            <a onClick={() => {setOpen(true)}}>
                <Space>
                    <DatabaseOutlined/>{props.text}
                </Space>
            </a>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false)}}
                footer={null}
                width={600}
            >
                <Descriptions 
                    items={props.items} 
                    column={1}
                    labelStyle={{display:'flex',justifyContent:"flex-end",width:"180px"}}
                />
            </Modal>
        </> 
    )
}

export default ModalType