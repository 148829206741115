import React from 'react'
import classes from './web.module.css'
import { CopyrightOutlined } from '@ant-design/icons';
function Foot() {
    return (
        <div className={classes.footMain}>
            <div><CopyrightOutlined /> 2002 - 现在 bnsrj.com copyRight</div>
            <div style={{marginTop:'30px'}}>
                <a 
                    style={{color:'blue',cursor:'pointer'}} 
                    target="_blank" 
                    onClick={() => {window.open('https://www.bnsrj.com')}} 
                >湖北伯纳思网络科技有限公司</a>
            </div>
            <div style={{marginTop:'30px'}}>
                <a target="_blank" onClick={() => {window.open('https://beian.miit.gov.cn/')}} style={{cursor:'pointer'}}>鄂ICP备18025660号-6</a>
            </div>            
        </div>
    )
}

export default Foot