import { Select,Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGetEnterpriseDeptQuery } from '../store/ReactWebSiteApi';

function GetDept(props) {
    const [list,setList] = useState();
    const {data, isSuccess} = useGetEnterpriseDeptQuery({e_id:localStorage.getItem('e_id')});
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                const newArr = data.Result.map((item) => {
                    const {ed_id,ed_name,}=item;
                    return item = {value:ed_id,label:ed_name}
                })
                if(localStorage.getItem('eu_role')==='2'){
                    setList(newArr)
                }else{
                    const arr = newArr.map((item) => {
                        if(localStorage.getItem('eu_dept_id').includes(item.value)){
                            return {value:item.value,label:item.label}
                        }else{
                            return {value:item.value,label:item.label,disabled:true}
                        }
                    })
                    setList(arr)
                }
                
            }
        }
    },[data,isSuccess])
    // console.log(props.initialValue);
    return (
        <>
            <Form.Item 
                label={props.label} 
                wrapperCol={props.wrapperCol} 
                required={props.required} 
                name={props.name}      
                initialValue={props.initialValue}  
                    
            >
                <Select 
                    options={list} 
                    onChange={(_,f) => {
                        // console.log(f);
                        props.getDept(f.value,f.label)
                    }}
                    style={props.style}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                />
            </Form.Item>
        </>
    )
}

export default GetDept