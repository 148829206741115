import React,{useState,useEffect,} from 'react'
import {  useGetEnterpriseDeptQuery } from '../../store/ReactWebSiteApi';
import { Table,} from 'antd';
import LedgerStartDateEdit from './LedgerStartDateEdit';
function LedgerStartdate() {        
    const [list,setList] = useState();    
    const {data, isSuccess} = useGetEnterpriseDeptQuery({e_id:localStorage.getItem('e_id')});
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                const arr = data.Result.map((item) => {
                    const {ed_id:key,ed_name,ed_tz_e_date}=item;
                    return item = {key,ed_name,ed_tz_e_date}
                })
                setList(arr)
            }else{
                setList('')
            }
        }
    },[data,isSuccess])    
   
    const column = [
        {
            key:'2',
            title:'《团队报账单》出发日期',
            dataIndex:'ed_tz_e_date',
            render:(text,record) => {
               return <LedgerStartDateEdit ed_id={record.key}  text={text} ed_name={record.ed_name} />
            },
            width:'50%'
        },
        {
            key:'1',
            title:'部门',
            dataIndex:'ed_name'
        },               
    ]
    
    return (        
        <Table columns={column} dataSource={list}/>  
    )
}

export default LedgerStartdate