import React from 'react'
import { Card} from 'antd'
import classes from './cpn.module.css'
import { useNavigate } from 'react-router-dom'
function Index(props) {
    const navi = useNavigate();
    const menu = props.menu.map((item) => {
        const {router,icon,name,key} = item
        return <Card.Grid className={classes.cardGrid} onClick={() => {navi(`${router}?esv_id=${props.esv_id}`)}} key={key}>{icon} {name}</Card.Grid>
    })
    return (
        <Card style={{textAlign:'center'}}>
            {menu}           
        </Card>
    )
}

export default Index