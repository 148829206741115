import React from 'react'
import Main from '../../../cpn/Main'
import { Row } from 'antd';
import Folder from '../../../cpn/Folder';
function StudyTourIndex() {
    return (
        <Main t1='计调' n1='/TaOpIndex' t2='研学旅行'>
            <Row>
                <Folder title='文件' link='/StudyTourFile'/>                
                <Folder title='团队计划' link='/StudyTourGroup'/>
                <Folder title='模板' link='/StudyTourTemplateIndex'/>
            </Row>                    
        </Main>
    )
}

export default StudyTourIndex