import React,{useState,useEffect}from 'react'
import { Table,Space} from 'antd';
import { useNavigate, } from 'react-router-dom';
import { useCapitalAccountListQuery,} from '../../store/ReactWebSiteApi';
import FundAccountAdd from './FundAccountAdd';
import FundAccountDel from './FundAccountDel';
function FundAccount() {   
    const {data, isSuccess} = useCapitalAccountListQuery({eca_e_id:localStorage.getItem('e_id')});    
    const [list, setList] = useState();    
    useEffect(() => {
        if (isSuccess) {
            if(data.Status==='success'){
                const newArr = data.Result.map(item => {
                    const {eca_id:key,eca_type_id,eca_name,eca_bank_kh,eca_bank_zh,eca_bank_crad,eca_ctime,eca_bank_content,eca_uname,eca_amount,finance_num,eca_wx_phone,eca_wx_account,eca_wx_content,eca_alipay_phone,eca_alipay_account,eca_alipay_content} = item;
                    return item = {key,eca_type_id,eca_name,eca_bank_kh,eca_bank_zh,eca_bank_crad,eca_ctime,eca_bank_content,eca_uname,eca_amount,finance_num,eca_wx_phone,eca_wx_account,eca_wx_content,eca_alipay_phone,eca_alipay_account,eca_alipay_content}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eca_ctime;
                    let bTimeString = b.eca_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
        }
    }, [data, isSuccess])
    //获取参数
    const navi = useNavigate();
    const columns = [       
        {
            key: 'client',
            title: '资金账户',
            render:(_,record) => {
                if(record.eca_type_id==='1'){
                    return<>
                    <div>开户行：{record.eca_bank_kh}</div>
                    <div>户&emsp;名：{record.eca_bank_zh}</div>
                    <div>账&emsp;号：{record.eca_bank_crad}</div>
                    <div>备&emsp;注：{record.eca_bank_content}</div>
                    </>
                }
                if(record.eca_type_id==='2'){
                    return<>
                    <div>手机号码：{record.eca_wx_phone}</div>
                    <div>微信号：{record.eca_wx_account}</div>
                    <div>备&emsp;注：{record.eca_wx_content}</div>
                    </>
                }
                if(record.eca_type_id==='3'){
                    return<>
                    <div>手机号码：{record.eca_alipay_phone}</div>
                    <div>支付宝账号：{record.eca_alipay_account}</div>
                    <div>备&emsp;注：{record.eca_alipay_content}</div>
                    </>
                }
            }
        },
        {
            key: 'status',
            title: '账户余额',
            dataIndex: 'eca_amount',
        },
        {
            key: 'finance_num',
            title: '转账笔数',
            dataIndex: 'finance_num',
        },        
        {
            key: 'time',
            title: '创建/修改时间',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.eca_ctime + record.eca_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space>
                        <FundAccountDel finance_num={record.finance_num} ecal_eca_id={record.key} />
                        <a onClick={() => {
                            let name
                            if(record.eca_type_id==='1' ||record.eca_type_id==='0' ){
                                name=record.eca_bank_kh + '/' + record.eca_bank_zh + '/' +record.eca_bank_crad
                            }else if(record.eca_type_id==='2'){
                                name= '微信' + record.eca_wx_phone + '/' + record.eca_wx_account
                            }else{
                                name= '支付宝' + record.eca_alipay_phone + '/' + record.eca_alipay_account
                            }
                            navi(`/FundList?indexType=1&indexName=财务&esv_id=15&id=${record.key}&name=${name}`)
                        }}>转账明细</a>
                    </Space>
                </>
            }
        },
    ]

      
    return (
        <>
            <Space>
                <FundAccountAdd />
            </Space>
            <Table columns={columns} style={{marginTop:'10px'}} dataSource={list}></Table>
        </>
    )
}

export default FundAccount