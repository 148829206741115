import React, {useCallback } from 'react';
import { useNavigate,} from 'react-router-dom';
import classes from './main.module.css';
import { Modal, Space,} from 'antd';
import { setLogout} from '../store/loginSlice'
import { useDispatch } from 'react-redux'
import { ExclamationCircleFilled,PoweroffOutlined,} from '@ant-design/icons';

function MainLogout() {
    const navi = useNavigate();   
    const dispatch = useDispatch();
    const logout = useCallback(() => {
        navi('/login');
        dispatch(setLogout());    
    },[dispatch, navi]);    
    const { confirm } = Modal;
    return (        
        <Space 
            onClick={() => {
                confirm({
                    title: '确定退出登录吗?',
                    icon: <ExclamationCircleFilled />,
                    content: '退出后随时回来！',
                    okText: '确认',
                    cancelText:'取消',
                    onOk() {
                        logout();
                    },
                    onCancel() {
                        //console.log('Cancel');
                    },
                });                    
            }} 
            className={classes.mine}
        >
            <span>{localStorage.getItem('eu_name').replace(/"/g,'')}</span>
            <PoweroffOutlined style={{fontSize:"16px"}} />
        </Space>        
    )
}

export default MainLogout