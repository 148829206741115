import React from 'react'
import Main from '../../cpn/Main'
import {Row} from 'antd'
import Folder from '../../cpn/Folder'
import { indexFinance } from '../../finance/balance/balanceType'

function ArapTable() {    
    return (
        <Main t1='财务' n1={indexFinance} t2='下账' n2='/ArapIndex' t3='报表'>
            <Row>                                
                <Folder title='部门利润表'  link='/ArapSumDept' />            
                <Folder title='客户利润表'  link='/ArapSumClient' />            
                <Folder title='客户类型利润表'  link='/ArapSumClientType' />            
                <Folder title='产品利润表'  link='/ArapSumGoods' />            
                <Folder title='产品类型利润表'  link='/ArapSumGoodsType' />            
                <Folder title='操作员利润表'  link='/ArapSumOperator' />            
                <Folder title='销售员利润表'  link='/ArapSumSalesman' />            
            </Row>
        </Main>
    )
}

export default ArapTable