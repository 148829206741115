import React from 'react'
import Main from '../../../../cpn/Main'
import CustomGoods from './CustomGoods'

function CustomGoodsSelf() {
    return (
        <Main  t1='计调' n1='/TaOpIndex' t2='定制游' n2='/CustomIndex' t3='仅自营组团商品'>
            <CustomGoods  type='3'/>
        </Main>
    )
}

export default CustomGoodsSelf