import React from 'react'
import Main from '../../cpn/Main'
import { Card} from 'antd'
import classes from '../../cpn/cpn.module.css'
import { 
    ContainerOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

function GuideIndex() {
    const navi = useNavigate()
    return (
        <Main >
            <Card style={{textAlign:'center'}}>
                <Card.Grid className={classes.cardGrid} onClick={() => {navi('/GuideList?esv_id=24')}}><ContainerOutlined /> 导游</Card.Grid>
            </Card> 
        </Main>
    )
}

export default GuideIndex