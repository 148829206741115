import React from 'react'
import classes from './home.module.css'
import HomeTop from './HomeTop';
import { Col, Row, Space } from 'antd';
import Foot from './Foot';
import PageTitle from '../cpn/PageTitle';
function Home() {
    return (
        <>
            <PageTitle title="伯纳思科技" />
            <HomeTop active='home' />
            <div className={classes.main}>                
                <Row>
                    <Col span={2}></Col>
                    <Col span={10} style={{display:'flex',alignItems:"center",justifyContent:'center'}}>
                        <Space direction='vertical' size='large'  align="end">
                            <div style={{fontSize:"60px",fontWeight:"bold"}}>助力政府和企业数字化转型</div>
                            <h1>打造网络管理新世界</h1>
                            <h3>多年来，伯纳思科技坚持为政府和企业做好幕后的技术服务</h3>
                        </Space>
                    </Col>
                    <Col span={10}>
                        <img src='../../img/home/home1.png' alt='' width='80%'/>
                    </Col>
                    <Col span={2} ></Col>
                </Row>
                <Row>
                    <Col span={2}></Col>                    
                    <Col span={10}>
                        <img src='../../img/home/home2.png' alt='' width='80%'/>
                    </Col>
                    <Col span={10} style={{display:'flex',alignItems:"center",justifyContent:'center'}}>
                        <Space direction='vertical' size='large'  align="start">
                            <div style={{fontSize:"60px",fontWeight:"bold"}}>未来是AI人工智能的时代</div>
                            <h1>拥抱未来才有未来</h1>
                            <h3>伯纳思科技坚持以技术创新为立身之本，拥有一支年轻的技术团队</h3>
                        </Space>
                    </Col>
                    <Col span={2} ></Col>
                </Row>
                <Row>
                    <Col span={2}></Col>
                    <Col span={10} style={{display:'flex',alignItems:"center",justifyContent:'center'}}>
                        <Space direction='vertical' size='large'  align="end">
                            <div style={{fontSize:"60px",fontWeight:"bold"}}>国内领先的系统供应商</div>
                            <h1>合作共赢、诚信为本</h1>
                            <h3>伯纳思科技既有标准化解决方案，也有专属定制化服务，请点击屏幕最上方在线咨询！</h3>
                        </Space>
                    </Col>
                    <Col span={10}>
                        <img src='../../img/home/home3.png' alt='' width='80%'/>
                    </Col>
                    <Col span={2} ></Col>
                </Row>
                {/* <div className={classes.mainFirst}>
                    <div className={classes.mainFirstTop}>
                        <div>
                            <div className={classes.mainFirstTitle}>旅行社 · 旅当家</div>
                            <hr style={{margin:'8px 0'}}></hr>
                            <div className={classes.mainFirstEnglish}>TOUR SERVICE MANAGEMENT SOFTWARE</div>
                            <div className={classes.mainFirstBottom}>
                                <div style={{backgroundColor:'#E20001',padding:'6px 15px',letterSpacing:'3px'}}>全能型ERP</div>
                                <div style={{backgroundColor:'#972034',padding:'6px',flex:'1 auto',textAlign:'center',letterSpacing:'3px'}}>各类旅行社适用</div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.mainFirstCenter}>
                        <div>
                        &emsp;&emsp;旅当家是伯纳思网络科技有限公司开发的一款旅行社管理软件，为旅行社老板、职业经理人、财务、销售人员提供旅行社业务管理、记账、应收应付管理、费用控制等一体化的SaaS产品及服务，并能为旅行社提供有效的收入支出费用管控和业务决策支持。用了旅当家，旅行社能实现全员协同，老板和员工可以同步查看业务数据、报账、看账，合同管理流程化，并嵌入审批体系，有利于提高企业内部的流转效率，实施也简单快捷，用旅当家，将更好地帮助旅行社基于大数据，做好旅行社经营。
                            <br/>
                            &emsp;&emsp;全国统一售价3600元/年
                        </div>
                        <div style={{marginTop:'20px'}}>
                            <Button 
                                shape='round' 
                                style={{fontWeight:"bold",backgroundColor:'#E20001',letterSpacing:'3px'}} 
                                type='primary'
                                onClick={() => {setOpenAdd(true)}}
                            >火热体验中···</Button>
                        </div>
                    </div>
                </div>
                 */}
                {/* <div className={classes.centerRow}>
                    您的旅行社是否正面临以下困扰？
                </div>
                <Row gutter={16} style={{width:"1080px"}}>
                    <Col span={8} className={classes.gridCol}>
                        <Card className={classes.grid} title= {<div className={classes.gridTitle}><div><FrownTwoTone  twoToneColor="#E20001" className={classes.gridLogo}/></div><div>股东因为算账翻脸</div></div>} >
                            <div className={classes.gridContent}>
                                旅行社没有业务台账和费用明细，财务数据不透明，支出不能多人审批，支付余额没有实时更新，财务报表汇算不及时。
                            </div>
                        </Card>
                    </Col>
                    <Col span={8} className={classes.gridCol}>
                        <Card className={classes.grid} title= {<div className={classes.gridTitle}><div><InteractionTwoTone  twoToneColor="#E20001" className={classes.gridLogo}/></div><div>应收应付账目混乱</div></div>} >
                            <div className={classes.gridContent}>
                                旅行社没有实时更新和准确的应收款和应付款数据，销售员收款不及时导致支付占用过大，供应商付款出现多付错付，减少旅行社利润。
                            </div>
                        </Card>
                    </Col>
                    <Col span={8} className={classes.gridCol}>
                        <Card 
                            className={classes.grid} 
                            title= {<div className={classes.gridTitle}><div><TrophyTwoTone  twoToneColor="#E20001" className={classes.gridLogo}/></div><div>销售员提成不透明</div></div>} >
                            <div className={classes.gridContent}>
                                销售员看不到自己的销售业绩，不能和销售目标做有效匹配，提成计算不透明，销售团队士气低下，无法完成既定的销售任务。
                            </div>
                        </Card>
                    </Col>
                    <Col span={8} className={classes.gridCol}>
                        <Card 
                            className={classes.grid} 
                            title= {<div className={classes.gridTitle}><div><RedEnvelopeTwoTone  twoToneColor="#E20001" className={classes.gridLogo}/></div><div>收入管理失控</div></div>} >
                            <div className={classes.gridContent}>
                                团队计划和销售收入没有及时登记，业务员私自卖业务，或者减少销售收入到自己荷包。
                            </div>
                        </Card>
                    </Col>
                    <Col span={8} className={classes.gridCol}>
                        <Card 
                            className={classes.grid} 
                            title= {<div className={classes.gridTitle}><div><DislikeTwoTone  twoToneColor="#E20001" className={classes.gridLogo}/></div><div>工作效率低</div></div>} >
                            <div className={classes.gridContent}>
                                没有完整的“销售-签单-收入-执行-成本-评价”和“一团一清”的工作流程，员工工作随心所欲，无效的事情重复做，工作效率低下，旅行社利润低。
                            </div>
                        </Card>
                    </Col>
                    <Col span={8} className={classes.gridCol}>
                        <Card 
                            className={classes.grid} 
                            title= {<div className={classes.gridTitle}><div><FileExcelTwoTone  twoToneColor="#E20001" className={classes.gridLogo}/></div><div>市场判断失误</div></div>} >
                            <div className={classes.gridContent}>
                                没有准确的产品利润、销售员利润、客户利润、部门利润数据，不能正确决策主攻哪些市场，放弃哪些产品，进而错误判断市场，失去商业机会。
                            </div>
                        </Card>
                    </Col>
                </Row>
                <div className={classes.centerRow}>
                    <Button 
                        size='large' 
                        type="primary" 
                        shape='default'
                        className={classes.bigButton}
                        onClick={() => {setOpenAdd(true)}}
                    >一键获取软件解决方案</Button>
                </div>
                <div className={classes.mainSecond}>
                    <div className={classes.mainSecondTop}>
                        <div>旅当家 · 功能亮点</div>
                        <div className={classes.mainSecondText}>量身定制，满足旅行社管理需求</div>
                    </div>
                    <div className={classes.mainSecondBottom}>
                        <Button 
                            shape='round'
                            style={{fontWeight:"bold",backgroundColor:'#E20001',letterSpacing:'3px'}} 
                            type='primary'
                            onClick={() => {setOpenAdd(true)}}
                            size='large'
                        >免费体验</Button>
                    </div>
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home7.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home8.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home9.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home11.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home12.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home13.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home14.png' width='1080px' alt='' />
                </div>
                
                <div className={classes.centerRow}>
                    <img src='img/home/home16.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home17.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home18.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home19.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home21.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home22.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home23.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home24.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home25.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home27.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home28.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home29.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home30.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home32.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home33.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home34.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home35.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home36.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home37.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home38.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <img src='img/home/home39.png' width='1080px' alt='' />
                </div>
                <div className={classes.centerRow}>
                    <Button 
                        size='large' 
                        type="primary" 
                        shape='default'
                        className={classes.bigButton}
                        onClick={() => {setOpenAdd(true)}}
                    >一键获取软件解决方案</Button>
                </div> */}
            </div>
            <Foot />
        </>
    )
}

export default Home