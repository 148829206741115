import React,{useState,useEffect,useRef}from 'react'
import Main from '../cpn/Main'
import { useAppSettingListQuery, useEntAppListQuery, useEntMoneyAppAddMutation, useGoodsSpecListQuery, } from '../store/ReactWebSiteApi';
import { message,Modal,Form,Radio, Space,Table} from 'antd';
import { useNavigate } from 'react-router-dom';
function SetAppPayed() {
    const navi = useNavigate();
    //类型======================================================================================================================================================类型//
    const [openType,setOpenType] = useState();
    const [appSet,setAppSet] = useState({id:'',list:''});
    const {data:dataSet,isSuccess:isSuccessSet} = useAppSettingListQuery({esvs_esv_id:appSet.id},{skip:!appSet.id})
    useEffect(() => {
        if(isSuccessSet){
            if(dataSet.Status==='success'){
                const arr = dataSet.Result.map((item) => {
                    const {esvs_id:key,esvs_name,esvs_router} = item
                    return <div key={key}>
                        <a onClick={() => {navi(esvs_router)}}>{esvs_name}</a>
                    </div>
                })
                setAppSet(prevState=>({...prevState,list:arr}))
            }else{
                setAppSet(prevState=>({...prevState,list:'暂无设置！'}))
            }
        }
    },[dataSet, isSuccessSet, navi])
    const onOkType = () => {setOpenType(false)};
    const onCancelType = () => {setOpenType(false)};    
    const modalType =   <Modal title='请选择数据类型' open={openType} onOk={onOkType} onCancel={onCancelType} okText='关闭'>
                            {appSet.list}            
                        </Modal>

    //续费======================================================================================================================================================续费//
    const [form] = Form.useForm();
    const editForm = useRef()
    //应用列表
    const [infoApp, setInfoApp] = useState({eail_esv_id:''})    
    //规格列表
    const [optionsType,setOptionsType] = useState();
    const {data:dataType,isSuccess:isSuccessType} = useGoodsSpecListQuery({ess_esv_id:infoApp.eail_esv_id})
    useEffect(() => {
        if(isSuccessType){
            if(dataType.Status==='success'){
                console.log(dataType,'type');
                const newArr = dataType.Result.map(item => {
                    const {ess_id:key,ess_name,ess_price,ess_num,} = item;
                    return item = <Radio value={key} ess_price={ess_price} ess_num={ess_num} eail_ess_name={ess_name} key={key}>{ess_name}</Radio>
                }) 
                setOptionsType(newArr)
            }
        }
    },[dataType,isSuccessType])
    const [submitApp] = useEntMoneyAppAddMutation()
    const [openApp, setOpenApp] = useState(false);   

    const onCreateApp = () => {
        submitApp({
            eail_e_id:localStorage.getItem('e_id'),
            eail_amount:-infoApp.ess_price,
            eail_uid:localStorage.getItem('eu_id'),
            eail_uname:localStorage.getItem('eu_name'),
            eail_content:'自行购买',
            eail_type_id:'2',
            eail_type_name:'购买应用', 
            eail_esv_id:infoApp.eail_esv_id, 
            eail_esv_name:infoApp.eail_esv_name,
            eail_ess_id:infoApp.eail_ess_id,
            eail_ess_name:infoApp.eail_ess_name,
            eail_num:infoApp.eail_num,
        }).then((res) => {
            // console.log(res);
            if (res.data.Status === 'success') {
                message.success('购买成功！')
                setOpenApp(false)                                   
            }
            if (res.data.Status === 'fail') {
                message.error('购买失败！'+res.data.Message)
            }
        })
    }
    const modalApp =   <Modal
                            open={openApp}
                            width={1000}
                            title='购买应用'
                            okText="提交"
                            cancelText="取消"
                            onCancel={() => {setOpenApp(false);}}
                            onOk={() => {
                                form
                                    .validateFields()
                                    .then((values) => {
                                        form.resetFields();
                                        onCreateApp(values);
                                    })
                                    .catch((info) => {
                                        console.log('Validate Failed:', info);
                                    });
                                
                            }}
                        >
                            <Form
                                form={form}
                                layout="horizonal"
                                name="form_client_edit"
                                labelCol={{ span: 5 }}
                                ref={editForm}
                            >
                                <Form.Item name="date" label='请选择' wrapperCol={{span:5}} required>
                                    <Radio.Group onChange={(e) => {
                                            setInfoApp(prevState=>({
                                                ...prevState,
                                                ess_price:e.target.ess_price,
                                                eail_ess_id:e.target.value,
                                                eail_ess_name:e.target.eail_ess_name,
                                                eail_num:e.target.ess_num
                                            }))}
                                            }>
                                        <Space direction='vertical'>
                                            {optionsType}
                                        </Space>
                                    </Radio.Group>
                                </Form.Item> 
                            </Form>
                        </Modal>
    
    //应用列表-------------------------------------------------------------------------------------------------------------------应用列表//
    
    const {data, isSuccess} = useEntAppListQuery({ea_e_id:localStorage.getItem('e_id')});
    
    const [list, setList] = useState();

    
    useEffect(() => {
        if (isSuccess) {
            //console.log(data);
            if(data.Status==='success'){
                
                const newArr = data.Result.map(item => {
                    const {esv_id:key,esv_name,esv_content,esv_ctime,esv_image2_name,ess_price,esv_state,esv_remark,ea_ctime} = item;
                    if(esv_state==='2'){
                        return item = {key,esv_name,esv_content,esv_ctime,esv_image2_name,ess_price,esv_state,esv_remark,ea_ctime}
                    }else{
                        return <></>
                    }
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ea_ctime;
                    let bTimeString = b.ea_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess,]) 
    
    const columns = [               
        {
            key: 'name',
            title: '应用',
            width:'20%',
            dataIndex:'esv_name',
            render:(text,record) => {
                return <Space><img src={`/api/assets/images/application/${record.esv_image2_name}`} alt='' width='60'/> {text} </Space>
            }
        },                                            
        {
            key: 'time',
            title: '购买时间',
            width:'20%',
            dataIndex:'ea_ctime',
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>   
                        <a onClick={() => {navi(`/SetAppLimits?esv_id=19&id=${record.key}&name=${record.esv_name}`)}}>用户权限</a>                     
                        <a onClick={() => {
                            setAppSet(prevState=>({...prevState,id:record.key}))
                            setOpenType(true);
                        }}>类型</a>                     
                        <a onClick={() => {                        
                            setInfoApp(prevState=>({...prevState,eail_esv_id:record.key}))
                            setOpenApp(true);
                            }}>续费</a>                     
                        <a onClick={() => {
                            setModalOpen(true)
                            setModalInfo(prevState=>({
                                ...prevState,
                                esv_name:record.esv_name,
                                esv_remark:record.esv_remark,
                            }))
                        }}>描述</a>
                    </Space>
                </>
            }
        },
    ]
    //应用描述---------------------------------------------------------------------------------------------------------------------------应用描述//
    const [modalInfo,setModalInfo] = useState({
        sst_pic:[],
        picArr:'',
        grade:'',
        docArr:[],
    })
    const [modalOpen, setModalOpen] = useState(false);
    const Ok = () => {setModalOpen(false);};
    const Cancel = () => {setModalOpen(false);};
    const modalPreview =  <Modal title={modalInfo.esv_name} open={modalOpen} onOk={Ok} onCancel={Cancel} okText='关闭'>
                            <div>
                                <div style={{padding:'10px'}}>
                                    <div style={{fontSize:"24px",fontWeight:'600'}}>{modalInfo.en_name}</div>
                                    <div style={{marginTop:'10px'}}>
                                        <div dangerouslySetInnerHTML={{__html:modalInfo.esv_remark}} style={{maxWidth:"470px",overflow:"hidden"}}></div>
                                    </div>
                                </div>
                            </div>                
                        </Modal>
    return (
        <Main t1='应用管理'>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table>
            {modalPreview}            
            {modalApp}
            {modalType}
        </Main>
    )
}

export default SetAppPayed