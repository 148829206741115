import React from 'react'
import Main from '../../../../cpn/Main'

function StudyTourFile() {
    return (
        <Main t1='计调' n1='/TaOpIndex' t2='研学旅行' n2='/StudyTourIndex' t3='文件'>
            
        </Main>
    )
}

export default StudyTourFile