import React,{useState,useEffect,}from 'react'
import { Table,} from 'antd';
import { useIncomeExpenditureHxFinishListQuery,} from '../../store/ReactWebSiteApi';
import FundAddSubmit from './FundAddSubmit';
import Main from '../../cpn/Main'
import ArapEnterCheck from '../arap/ArapCheck';
import { indexFinance } from '../balance/balanceType';
function FundAdd() {      
    const {data,isSuccess} = useIncomeExpenditureHxFinishListQuery({eie_e_id:localStorage.getItem('e_id'),bank_state:'2'})    
    const [list, setList] = useState();    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                console.log(data);
                const newArr = data.Result.map(item => {
                    const {eie_hx_number:key,eie_bank_log_id,eie_bank_ctime,eie_amount,eie_hx_content,ec_name,hx_num,eie_hx_amount,eie_hx_ctime,eie_hx_uname,eie_update_ctime,eie_update_uname,eie_price,eie_image_file,eie_count,eci_name,eg_name,eu_name,eie_bank_date} = item;
                    return item = {key,eie_bank_log_id,eie_bank_ctime,eie_amount,eie_hx_content,ec_name,hx_num,eie_hx_amount,eie_hx_ctime,eie_hx_uname,eie_update_ctime,eie_update_uname,eie_price,eie_image_file,eie_count,eci_name,eg_name,eu_name,eie_bank_date}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eie_hx_ctime;
                    let bTimeString = b.eie_hx_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    const columns = [
        {
            key: 'id',
            title: '下账凭证',
            dataIndex: 'key',
            render:(_,record) => {
                return  <ArapEnterCheck  
                            eie_hx_number={record.key} 
                            hx_num={record.hx_num} 
                            eie_hx_amount={record.eie_hx_amount}
                            ec_name={record.ec_name}
                            eie_hx_content={record.eie_hx_content}
                            eie_hx_ctime={record.eie_hx_ctime}
                            eie_hx_uname={record.eie_hx_uname}
                            eie_bank_date={record.eie_bank_date}
                            eie_bank_log_id={record.eie_bank_log_id}
                        />
            }           
        },         
        {
            key: 'client',
            title: '客户',
            dataIndex: 'ec_name',            
        },            
        {
            key: 'bishu',
            title: '下账数量',
            dataIndex: 'hx_num',
        },            
        {
            key: 'eie_hx_amount',
            title: '下账金额',
            dataIndex: 'eie_hx_amount',
        },                         
        {
            key: 'remark',
            title: '下账备注',
            dataIndex:'eie_hx_content',
        },            
        {
            key: 'num',
            title: '下账时间',
            dataIndex:'eie_hx_ctime',
            render:(text,record) => {
                return<>
                    {text}{record.eie_hx_uname}
                </>
            }
        },                                          
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <FundAddSubmit eie_hx_amount={record.eie_hx_amount} eie_hx_number={record.key}/>
            }
        },
    ]
    return (
        <Main   t1='财务' n1={indexFinance} t2='转账' n2='/FundIndex' t3='新增转账凭证' >
            <Table 
                columns={columns}  
                dataSource={list} 
                pagination={{
                    pageSize:50,
                    showSizeChanger:true,
                    showTotal:(total) => `共${total}条`,
                }} 
            />            
        </Main>
    )
    
}

export default FundAdd