import React,{useState} from 'react'
import Main from '../../cpn/Main'
import { message,Button, Form, Input, Row,  Col,Radio} from 'antd'
import { useNavigate } from 'react-router-dom'
import { useDestinationAddMutation } from '../../store/ReactWebSiteApi'
function DestinationAdd() {  
  const [infoAdd, setInfoAdd] = useState({
    d_uid: localStorage.getItem('eu_id'),
    d_uname:localStorage.getItem('eu_name'),
  });
  //链接
  const navigate = useNavigate();
  //上传数据
  const [destinationAdd] = useDestinationAddMutation();
  //提交对话框表单
  const onFinish = () => {
    destinationAdd(infoAdd).then(
      (res) => {
        if (res.data.Status === 'success') {
          message.success('新增成功！')
          navigate('/Destination');                                    
        }
        if (res.data.Status === 'fail') {
          message.error('新增失败！'+res.data.Message)
        }
      })
  }
  const onFinishFailed = () => {}
  return (
    <Main title1='系统' title2='地区' title3='目的地' title4='新增地标'>
      <Row>
        <Col span={12}>
          <Form
              name="basic"
              labelCol={{
                  span: 4,
              }}
              wrapperCol={{
                  span: 20,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
          >
            <Form.Item
                label="1、选择地区"
                name="d_type_id"
                rules={[{
                    required: true,
                    message: '请选择地区！',
                },]}
                wrapperCol={{span: 10,}}
            >
              <Radio.Group onChange={(e) => {setInfoAdd(prevState=>({...prevState,d_type_id:e.target.value}))}}>
                <Radio value={'1'}>国内</Radio>
                <Radio value={'2'}>国外</Radio>
              </Radio.Group>
            </Form.Item>        
            <Form.Item
              label="2、填写名称"
              name="d_name"
              rules={[{
                  required: true,
                  message: '请填写名称！',
              },]}
              wrapperCol={{ span: 5 }}
            >
              <Input onChange={(e) => {setInfoAdd(prevState=>({...prevState,d_name:e.target.value}))}} />
            </Form.Item>
            <Form.Item
              label="3、填写拼音"
              name='d_abbreviation'    
              wrapperCol={{ span: 5 }}
              rules={[{
                required: true,
                message: '请填写拼音！',
            },]}
            >
              <Input onChange={(e) => {setInfoAdd(prevState=>({...prevState,d_abbreviation:e.target.value}))}} />
            </Form.Item>                    
            <Form.Item
                wrapperCol={{
                    offset: 2,
                    span: 16,
                }}
            >
              <Button type="primary" htmlType="submit">提交</Button>
            </Form.Item>
          </Form>          
        </Col>
      </Row>
    </Main>
  )
}
export default DestinationAdd