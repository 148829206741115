import React,{useRef, useState}from 'react'
import { Form,Input,message,Select} from 'antd';
import { useDijieTemplateTaiTouAddMutation,} from '../../../../../store/ReactWebSiteApi';
import ModalAdd from '../../../../../cpn/ModalAdd';
import GetUserDept from '../../../../../cpn/GetUserDept';
import UploadOne from '../../../../../cpn/UploadOne';
const {TextArea} = Input
function PageAdd(props) {
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        lttt_e_id:localStorage.getItem('e_id'),
        lttt_uid:localStorage.getItem('eu_id'),
        lttt_uname:localStorage.getItem('eu_name'),
    });
    const [submitAdd] = useDijieTemplateTaiTouAddMutation()
    const [open,setOpen] = useState(false)
    const editForm = useRef()
    return (
        <ModalAdd 
            title='页眉页脚模板'
            onOk={(e) => {
                submitAdd({
                    lttt_e_id:localStorage.getItem('e_id'),
                    lttt_uid:localStorage.getItem('eu_id'),
                    lttt_uname:localStorage.getItem('eu_name'),
                    lttt_dept_id:props.ltbjd_dept_id?props.ltbjd_dept_id:info.lttt_dept_id,
                    lttt_image:info.lttt_image,
                    lttt_phone:info.lttt_phone,
                    lttt_name:info.lttt_name,
                }).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('新增成功！') 
                        setOpen(false)                                                 
                    }
                    if (res.data.Status === 'fail') {
                        message.error('新增失败！'+res.data.Message)
                    }
                })
            }}
            open={open}
            getOpen={(e) => {
                setOpen(prevsState=>e)
                if(e){
                    setInfo(prevState=>({...prevState,lttt_dept_id:'',lttt_image:'',lttt_phone:''}))
                    form.resetFields(); 
                }
            }}
        >
            <Form
                ref={editForm}
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 6 }}
            >                                           
                {!props.ltbjd_dept_id && <GetUserDept 
                    label='部门' 
                    name='dept'
                    required 
                    wrapperCol={{span:10}}
                    getUserDept={(e) => {setInfo(prevsState=>({...prevsState,lttt_dept_id:e}))}}
                />}
                <UploadOne 
                    required
                    name='pic'
                    label='页眉图片' 
                    fileStr={(e) => {setInfo(prevsState=>({...prevsState,lttt_image:e}))}}
                />
                <Form.Item name="remark" label='页脚文字' wrapperCol={{span:10}}>
                    <TextArea onChange={(e) => {setInfo(prevsState=>({...prevsState,lttt_phone:e.target.value}))}}/>
                </Form.Item>
            </Form>
        </ModalAdd>
    )
}

export default PageAdd