import React, { useState,useEffect, } from 'react';
import { Form,Checkbox,} from 'antd';
import { useGetEnterpriseDeptQuery } from '../store/ReactWebSiteApi';
function GetAllDeptCheckbox(props) {
    //获取企业全部部门
    const [allDept,setAllDept] = useState([])
    const {data:dataAllDept,isSuccess:isSuccessAllDept} = useGetEnterpriseDeptQuery({e_id:localStorage.getItem('e_id')})
    useEffect(() => {
        if(isSuccessAllDept){
            if(dataAllDept.Status==="success"){
                const arr = dataAllDept.Result.map((item) => {
                    const {ed_id,ed_name} = item
                    return <Checkbox value={ed_id}>{ed_name}</Checkbox>
                })               
                setAllDept(arr)
            }
        }
    },[dataAllDept,isSuccessAllDept])   
    return (
        <Form.Item
            label={props.label}
            name={props.name}
            wrapperCol={props.wrapperCol}
            required={props.required}
        >
            <Checkbox.Group onChange={(e) => {props.getAllDept(e)}}>
                {allDept}
            </Checkbox.Group>
        </Form.Item>
    )
}

export default GetAllDeptCheckbox