import React from 'react'
import Main from '../../cpn/Main'
import BalanceElseList from '../../finance/balance/BalanceElseList'
function SellIncome() {    
    return (
        <Main t1='财务' n1='/GfIndexFinance' t2='销售收入'>
            <BalanceElseList
                main_type_id='1'
                main_type_name='销售收入单'
            />
        </Main>
    )
}

export default SellIncome