import { Button, Modal } from 'antd'
import React, { useState } from 'react'

function GuidePublicBtn() {
    const [open,setOpen] = useState(false)
    return (
        <>
            <Button 
                key='6'
                type='primary' 
                onClick={() => {
                    setOpen(true)
                }}
            >导当家公众号</Button>
            <Modal
                open={open}
                onCancel={() => {setOpen(false)}}
                footer={null}
            >
                导游微信搜索“导当家”公众号可以查看导游计划单，并填写导游报账单！
            </Modal>
        </>
    )
}

export default GuidePublicBtn