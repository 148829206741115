import React, { useEffect, useRef, useState } from 'react'
import ModalEdit from '../../../../../cpn/ModalEdit'
import { Form, Input,message } from 'antd'
import GetUserDept from '../../../../../cpn/GetUserDept';
import { useDijieTemplateXianLuUpdateQuery, useDijieTemplateXianLuUpdateSaveMutation } from '../../../../../store/ReactWebSiteApi';

function LineNameEdit(props) {
    const [form] = Form.useForm();  
    const editForm = useRef()
    const [info,setInfo] = useState({
        ltxl_e_id:localStorage.getItem('e_id'),
        ltxl_update_uid:localStorage.getItem('eu_id'),
        ltxl_update_uname:localStorage.getItem('eu_name'),
        ltxl_id:props.ltxl_id,
    });
    const [open,setOpen] = useState()
    //获取
    const {data,isSuccess} = useDijieTemplateXianLuUpdateQuery({ltxl_id:props.ltxl_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                editForm.current.setFieldsValue({
                    ltxl_dept_id:data.Result.ltxl_dept_id,                  
                    ltxl_name:data.Result.ltxl_name,
                })
                setInfo(prevsState=>({...prevsState,
                    ltxl_dept_id:data.Result.ltxl_dept_id,
                    ltxl_name:data.Result.ltxl_name,
                }))
            }
        }
    },[data,isSuccess,])
    const [updateSave] = useDijieTemplateXianLuUpdateSaveMutation()
    return (
        <ModalEdit
            getOpen={(e) => {setOpen(prevsState=>e );}}
            onOk={() => {
                updateSave(info).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('修改成功！') 
                        setOpen(false)                                                 
                    }
                    if (res.data.Status === 'fail') {
                        message.error('修改失败！'+res.data.Message)
                    }
                })
            }}
            open={open}
            title='线路名称模板'
        >
            <Form
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 3 }}
                ref={editForm}
            >                                           
               <GetUserDept 
                    label='部门' 
                    required 
                    name='ltxl_dept_id'
                    wrapperCol={{span:5}}
                    getUserDept={(e) => {setInfo(prevsState=>({...prevsState,ltxl_dept_id:e}))}}
                />
                <Form.Item name="ltxl_name" label='线路名称' wrapperCol={{span:20}}>
                    <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,ltxl_name:e.target.value}))}}/>
                </Form.Item>
            </Form>
        </ModalEdit>
    )
}

export default LineNameEdit