import React,{useState,useEffect}from 'react'
import { Table,Space,Form, Modal} from 'antd';
import {useFinanceSubTypeListQuery,} from '../../store/ReactWebSiteApi';
import BalanceTypeAdd from './BalanceTypeAdd';
import BalanceTypeEdit from './BalanceTypeEdit';
import BalanceTypeQuery from './BalanceTypeQuery';
import BalanceTypeDel from './BalanceTypeDel';
import ModalType from '../../cpn/ModalType';
function SetBalanceType(props) {   
    const [open,setOpen] = useState(false);
    const [query,setQuery] = useState({efst_e_id:localStorage.getItem('e_id'),efst_finance_type_id:props.efst_finance_type_id})    
    const {data,isSuccess} = useFinanceSubTypeListQuery(query,{refetchOnMountOrArgChange:1,skip:!open})    
    const [list, setList] = useState();    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                console.log(data);                
                const newArr = data.Result.map(item => {
                    const {efst_id:key,efst_finance_type_id,efst_name,efst_content,efst_uname,efst_ctime,efst_update_ctime,efst_update_uname,num,ed_name} = item;
                    return item = {key,efst_finance_type_id,efst_name,efst_content,efst_uname,efst_ctime,efst_update_ctime,efst_update_uname,num,ed_name}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.efst_ctime;
                    let bTimeString = b.efst_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])     


    const columns = [    
        // {
        //     key: 'type',
        //     title: '主类型名称',
        //     dataIndex: 'efst_finance_type_id',
        //     width:'10%',
        //     render:(text) => {
        //         const type = balanceType.filter(item => item.value===text);
        //         console.log(type);
        //         if(type[0]){
        //             return <>{type[0].label}</>
        //         }else{
        //             return<></>
        //         }
        //     }
        // },                                          
        {
            key: 'type',
            title: props.title,
            dataIndex: 'efst_name',
            width:'40%',
            render:(text,record) => {
                const items = [
                    {
                        key:'1',
                        label:<>{props.title}名称</>,
                        children:<>{record.efst_name}</>
                    },
                    {
                        key:'2',
                        label:'编号',
                        children:<>{record.key}</>
                    },
                ]
                return  <ModalType text={text} items={items} title={props.title}/>
            }
        },    
        {
            key: 'ed_name',
            title: '部门',
            dataIndex: 'ed_name',
            width:'40%',
        },                                         

        // {
        //     key: 'num',
        //     title: '收支数量',
        //     dataIndex: 'num',
        //     width:'10%',
        // },   
        // {
        //     key: 'id',
        //     title: '编号',
        //     dataIndex: 'key',
        //     width:'10%',
        // },                                            
        // {
        //     key: 'time',
        //     title: '操作记录',
        //     render: (_,record) => {
        //         return <>
        //             <div>新建时间：{record.efst_ctime + record.efst_uname}</div>
        //             <div>修改时间：{record.efst_update_ctime + record.efst_update_uname}</div>
        //         </>
        //     },
        //     width:'10%',
        // },
        {
            key: 'action',
            width:'20%',
            title: '操作',
            render: (_, record) => {                
                return <>
                    <Space>                        
                        <BalanceTypeEdit
                            efst_id={record.key}
                            efst_finance_type_id={props.efst_finance_type_id}
                            title={props.title}
                        />
                        <BalanceTypeDel efst_id={record.key} num={record.num}/>
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <>
            <Form.Item>
                <a onClick={() => {setOpen(true)}}>设置</a>
            </Form.Item>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {}}
                footer={null}
                width={800}
                title={`设置${props.title}`}
            >
                <Space style={{marginTop:"20px"}}>
                    <BalanceTypeAdd 
                        efst_finance_type_id={props.efst_finance_type_id}
                        title={props.title}
                    />
                    <BalanceTypeQuery 
                        title={props.title}
                        getQuery={(e) => {
                            setQuery(prevState=>({
                                ...prevState,
                                efst_finance_type_id:props.type_id,
                                efst_name:e.efst_name
                            }))
                        }}
                        type_id={props.type_id}
                    />
                </Space>
                <Table columns={columns} dataSource={list} style={{marginTop:"10px"}} pagination={{position:['none']}}></Table>
            </Modal>
        </>
    )
}

export default SetBalanceType