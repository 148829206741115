const form_data = (values) => {
    let formData = new FormData();
    for (let key in values) {
    //    if (values[key]) {
            formData.append(`${key}`, `${values[key]}`);
    //    }
    }
    return formData
}

export default form_data;