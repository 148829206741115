import React,{useState} from 'react'
import Main from '../../cpn/Main'
import { useAddCountryMutation } from '../../store/ReactWebSiteApi'
import { message,Button, Form, Input, Row, Radio, Col } from 'antd'
import {useNavigate} from 'react-router-dom'
function AddCountry(props) {  
  //获取操作员
  const ctac_uname = localStorage.getItem('userName');
  const ctac_uid = localStorage.getItem('userId'); 
  //新增数据
  const [country, setCountry] = useState({
    ctac_continent_id:'',
    ctac_continent_name:'',
    ctac_name:'',
    ctac_remark: '',
    ctac_uid:+ctac_uid,
    ctac_uname:ctac_uname,
  });
  //绑定洲
  const continentInput = (e) => {
    setCountry(prevState => ({ ...prevState, ctac_continent_id: e.target.value }))
    switch (e.target.value) {
      case '1':
        setCountry(prevState => ({ ...prevState, ctac_continent_name:'亚洲' }))
        break;
      case '2':
        setCountry(prevState => ({ ...prevState, ctac_continent_name:'欧洲' }))
        break;
      case '3':
        setCountry(prevState => ({ ...prevState, ctac_continent_name:'北美洲' }))
        break;
      case '4':
        setCountry(prevState => ({ ...prevState, ctac_continent_name:'大洋洲' }))
        break;
      case '5':
        setCountry(prevState => ({ ...prevState, ctac_continent_name:'非洲' }))
        break;
      case '6':
        setCountry(prevState => ({ ...prevState, ctac_continent_name:'南美洲' }))
        break;
      default:
        break;
    }
  }
  //绑定国家名称
  const nameInput = (e) => {
    setCountry(prevState =>({...prevState,ctac_name:e.target.value}))
  }
  //绑定国家备注
  const remarkInput = (e) => {
    setCountry(prevState =>({...prevState,ctac_remark:e.target.value}))
  }
  //链接
  const navigate = useNavigate();
  //上传数据
  const [addCountry] = useAddCountryMutation();
  //提交对话框表单
  const onFinish = () => {
    console.log(country);
    
    addCountry(country).then(
      (res) => {
        if (res.data.Status === 'success') {
          message.success('新增国家成功！')
          navigate('/Country');                                    
        }
        if (res.data.Status === 'fail') {
          message.error('新增国家失败！'+res.data.Message)
        }
      })
  }
  const onFinishFailed = () => {}
  return (
    <Main title1='系统' title2='地区' title3='国家' title4='新增国家'>
      <Row>
        <Col span={12}>
          <Form
              name="basic"
              labelCol={{
                  span: 4,
              }}
              wrapperCol={{
                  span: 20,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
          >
            <Form.Item
                required
                label="1、选择洲"
                name="continent"                            
                wrapperCol={{ span: 15 }}                            
                rules={[{
                  required:true,
                      }]}
            >             
              <Radio.Group value={country.continent_id} onChange={continentInput}>
                <Radio value='1'>亚洲</Radio>
                <Radio value='2'>欧洲</Radio>
                <Radio value='3'>北美洲</Radio>
                <Radio value='4'>大洋洲</Radio>
                <Radio value='5'>非洲</Radio>
                <Radio value='6'>南美洲</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
                label="2、填写国家名称"
                name="name"
                rules={[{
                    required: true,
                    message: '请填写国家名称！',
                },]}
                wrapperCol={{
                    span: 4,
                }}
            >
                <Input value={country.ctac_name} onChange={nameInput} />
            </Form.Item>
            <Form.Item
                label="3、填写备注"
                name='remark'
                rules={[{
                    required: true,
                    message: '请填写备注！',
                },]}
            >
              <Input value={country.ctac_remark} onChange={remarkInput} />
            </Form.Item>                    
            <Form.Item
                wrapperCol={{
                    offset: 2,
                    span: 16,
                }}
            >
              <Button type="primary" htmlType="submit">提交</Button>
            </Form.Item>
          </Form>          
        </Col>
      </Row>
    </Main>
  )
}
export default AddCountry