import { Select,Form, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useFinanceSubTypeListQuery,} from '../../store/ReactWebSiteApi';
import SetBalanceType from './SetBalanceType';

function GetBalanceType(props) {
    const [list,setList] = useState();
    const {data, isSuccess} = useFinanceSubTypeListQuery({
        efst_e_id:localStorage.getItem('e_id'),
        efst_finance_type_id:props.efst_finance_type_id,
        ect_name:props.ect_name,
        ect_mode:props.ect_mode
    });
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                const newArr = data.Result.map((item) => {
                    const {efst_id,efst_name,}=item;
                    return item = {value:efst_id,label:efst_name}
                })
                setList(newArr)
            }else{
                setList([])
            }
        }
    },[data,isSuccess])
    return (
        <>
            <Form.Item 
                label={props.label} 
                wrapperCol={props.wrapperCol} 
                required={props.required}          
                style={{marginBottom:"0"}}                  
            >
                <Space>
                    <Form.Item name={props.name}    >
                        <Select 
                            options={list} 
                            onChange={(e,f) => {
                                console.log(e,f);
                                props.getBalanceType(f.value,f.label)
                            }}
                            defaultValue={props.defaultId}
                            placeholder={props.defaultId}
                            style={{width:'300px'}}
                        />
                    </Form.Item>
                    <SetBalanceType efst_finance_type_id={props.efst_finance_type_id} title={props.title+'类型'}/>
                </Space>
            </Form.Item>
        </>
    )
}

export default GetBalanceType