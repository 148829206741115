import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import form_data from "./formData";

const React_enterpriseApi = createApi({
    reducerPath: 'React_enterpriseApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/React_enterprise'        
    }),
    tagTypes:['bank'],
    endpoints(build) {
        return {
            //员工列表
            entUserList: build.query({
                query(user) {
                    return {
                        url: '/entUserList',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['bank']
            }),
            //银行卡号修改
            entUserAccountUpdate: build.query({
                query(user) {
                    return {
                        url: '/entUserAccountUpdate',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['bank']
            }),
            //修改保存银行卡号
            entUserAccountUpdateSave: build.mutation({
                query(user) {
                    return {
                        url: '/entUserAccountUpdateSave',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags:['bank']
            }),
        }
    }
})

export const {
    useEntUserListQuery,
    useEntUserAccountUpdateQuery,
    useEntUserAccountUpdateSaveMutation,

} = React_enterpriseApi;
export default React_enterpriseApi;