import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";


import form_data from "./formData";
const zd_enterprise_deptApi = createApi({
    reducerPath: 'zd_enterprise_deptApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/zd_enterprise_dept'
    }),
    tagTypes: ['dept'],
    endpoints(build) {
        return {
            allDept: build.query({
                query(user) {
                    return {
                        url: '/all_dept',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dept'],
            }),
            addDept: build.mutation({
                query(user) {
                    return {
                        url: '/dept_add',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                invalidatesTags: ['dept']
            }),
            getDeptById: build.query({
                query(user) {
                    return {
                        url: '/dept_update',
                        method: 'post',
                        body: form_data(user)
                    }
                },               
            }),
            editDept: build.mutation({
                query(user) {
                    return {
                        url: '/dept_update_save',
                        method: 'post',
                        body: form_data(user)
                    }
                }, 
                invalidatesTags: ['dept']
            }),
            addStaffById: build.query({
                query(user) {
                    return {
                        url: '/user_add_dept',
                        method: 'post',
                        body: form_data(user)
                    }
                },               
            }),
            deleteDept: build.mutation({
                query(user) {
                    return {
                        url: '/dept_del',
                        method: 'post',
                        body: form_data(user)
                    }
                }, 
                invalidatesTags: ['dept']
            }),
            //获取企业部门
            getEnterpriseDept: build.query({
                query(user) {
                    return {
                        url: '/getEnterpriseDept',
                        method: 'post',
                        body: form_data(user)
                    }
                },
                providesTags:['dept'],
            }),
            //获取员工部门操作权限
            getUserDeptManage: build.query({
                query(user) {
                    return {
                        url: '/getUserDeptManage',
                        method: 'post',
                        body: form_data(user)
                    }
                },
            }),
        }
    }
})

export const { 
    useAllDeptQuery,
    useAddDeptMutation,
    useGetDeptByIdQuery,
    useEditDeptMutation,
    useAddStaffByIdQuery,
    useDeleteDeptMutation,
    useGetEnterpriseDeptQuery,
    useGetUserDeptManageQuery,
} = zd_enterprise_deptApi;
export default zd_enterprise_deptApi;