import { createSlice } from "@reduxjs/toolkit";

const deptSlice = createSlice({
    name: "dept",
    initialState: () => {
        return {
            cur_dept_id: localStorage.getItem('cur_dept_id'),
            cur_dept_name: localStorage.getItem('cur_dept_name'),
        }
    },
    reducers: {
        setDept(state, action) {
            state.cur_dept_id = action.payload.cur_dept_id;
            state.cur_dept_name = action.payload.cur_dept_name;
            localStorage.setItem('cur_dept_id', state.cur_dept_id);
            localStorage.setItem('cur_dept_name', state.cur_dept_name);
        },
    }
})

export const {setDept} = deptSlice.actions;
export const {reducer:deptReducer} = deptSlice;