import React,{useState,useEffect,}from 'react'
import { Table,Space,} from 'antd';
import Main from '../cpn/Main'
import { useTestListQuery, } from '../store/ReactWebSiteApi';
import TrainTaskAdd from './TrainTaskAdd';
import { useSearchParams } from 'react-router-dom';
import TrainTaskDel from './TrainTaskDel';
import TrainTaskEdit from './TrainTaskEdit';
function TrainTask() {
    const [param] = useSearchParams()
    
    const {data,isSuccess} = useTestListQuery({ewptr_ewp_id:param.get('id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {ewptr_id:key,eu_name,eu_ba_phone,ewptr_uname,ewptr_ctime,ewptr_update_ctime,ewptr_update_uname,ewptr_content,ewptr_result,ewptr_evaluate_content,cs_num,ewptr_evaluate_uname,ewptr_evaluate_ctime} = item;
                    return item = {key,eu_name,eu_ba_phone,ewptr_uname,ewptr_ctime,ewptr_update_ctime,ewptr_update_uname,ewptr_content,ewptr_result,ewptr_evaluate_content,cs_num,ewptr_evaluate_uname,ewptr_evaluate_ctime}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ewptr_ctime;
                    let bTimeString = b.ewptr_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])  
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
       
        {
            key: 'type',
            title: '被培训人',
            render:(_,record) => {
                return<>{record.eu_name +record.eu_ba_phone}</>
            }
        },
        {
            key: 'room',
            title: '备注',
            dataIndex: 'ewptr_content',
        },       
        {
            key: 'status',
            title: '状态',
            render:(_,record) => {
                if(!record.ewptr_result){
                    return<>
                    <div>待评价，共{record.cs_num}个</div>                   
                </>
                }else{
                    return<>已评价，正确{record.ewptr_result}个，共{record.cs_num}个</>
                }
            }
        },    
        {
            key: 'remark',
            title: '评价备注',
            dataIndex: 'ewptr_evaluate_content',
        },         
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>                    
                    <div>新建时间：{record.ewptr_ctime + record.ewptr_uname}</div>
                    <div>修改时间：{record.ewptr_update_ctime + record.ewptr_update_uname}</div>
                    <div>评价时间：{record.ewptr_evaluate_ctime + record.ewptr_evaluate_uname}</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>
                        <TrainTaskEdit  ewptr_id={record.key} />                             
                        <TrainTaskDel ewptr_id={record.key} />                        
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main  t1='培训计划' t2={param.get('name')} t3='培训任务' n1='/TrainPlan?esv_id=5'>
            <Space>
                <TrainTaskAdd ewptr_ewp_id={param.get('id')}/>
            </Space>
            <Table columns={columns} style={{marginTop:'10px'}} dataSource={list}></Table> 
        </Main>
    )
}

export default TrainTask