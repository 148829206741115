import { Button, Upload,Form } from 'antd'
import React from 'react'
import { UploadOutlined,} from '@ant-design/icons';
import { imgSrcWhole } from './imgSrc';
function UploadOne(props) {
    //上传首页图片
    const homeChange = (e) => {
        console.log(e,'eee');
        if (e.file.status === 'done'||e.file.status==='removed') {
            const fileArr = e.fileList.map((item) => {
                if(item.response){
                    const { response } = item
                    return imgSrcWhole + response.Result
                }else{
                    return item.url
                }
            })
            
            let fileStr = fileArr + "";  
            if(fileStr === null){
                fileStr = ''
            }
            props.fileStr(fileStr)
        }
    }
    console.log(props);
    return (
        <Form.Item 
            label={props.label}                         
            valuePropName='fileList'
            getValueFromEvent={e => {
                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;
            }}
            name={props.name}
            required={props.required}
        >
            <Upload
                name="image_file"
                action="/api/ReactApi/zd_single_upload_image"
                listType="picture"
                className="upload-list-inline"
                onChange={homeChange}
                maxCount={props.maxCount?props.maxCount:''}
                defaultFileList={props.defaultFileList}
            >
                <Button icon={<UploadOutlined />}>点击上传</Button>
            </Upload>
        </Form.Item>
    )
}

export default UploadOne