import React, { useState,useEffect, } from 'react';
import { Space, Tag,Table} from 'antd';
import SetUserDel from './SetUserDel';
import SetUserDisable from './SetUserDisable';
import { useAll_dept_userQuery, } from '../store/ReactWebSiteApi';
import SetUserEdit from './SetUserEdit';
import SetUserQuery from './SetUserQuery';
import SetUserAdd from './SetUserAdd';
function SetUserList(props) {
    //设置列表数据
    const [list, setList] = useState([]);
    const [query,setQuery] = useState({
        e_id: localStorage.getItem('e_id'), 
        ed_id: props.selectedDept.ed_id,
        eu_state:'',
        eu_name:'',
        eu_ba_phone:"",
    });
    const { data, isSuccess } = useAll_dept_userQuery({
        e_id: localStorage.getItem('e_id'), 
        ed_id: props.selectedDept.ed_id,
        eu_state:query.eu_state,
        eu_name:query.eu_name,
        eu_ba_phone:query.eu_ba_phone,
    },{refetchOnMountOrArgChange:1,skip:!props.selectedDept.ed_id});
    useEffect(() => { 
        if (isSuccess) {                   
            if (data.Status === 'success') {        
                console.log(data,'data');     
                const staffarr = data.Result.map(item => {
                        const { eu_name,eu_role, ess_name,eu_ba_phone, eu_ctime, eu_id: key, ed_name, ed_combination,ed_all_parent_id,eu_trail_tag,euda_ed_id,eu_state,eu_station_id} = item;
                        return item = {eu_role,ess_name,eu_ba_phone,key,eu_ctime,ed_name,ed_combination,ed_all_parent_id,eu_trail_tag,eu_name,euda_ed_id,eu_state,eu_station_id};
                    })
                //根据修改时间排序
                const timer = (a, b) => {
                    let aTimeString = a.eu_ctime;
                    let bTimeString = b.eu_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;
                }
                const staffArray = staffarr.sort(timer);  
                    setList(staffArray);        
            }else{
                setList([]);          
            }            
        }
        
    }, [data, isSuccess])
    //用户列表
    const columns = [
        {
            title: '姓名',
            dataIndex: 'eu_name',
            key: 'name',                    
        },
          
        {
            title: '登录手机',
            dataIndex: 'eu_ba_phone',
            key: 'phone',
        },
      
      
        {
            title: '系统权限',
            key: 'es',
            dataIndex: 'eu_role', 
            render:(text) => {
                if(text === '1'){
                    return '普通用户'
                }else{
                    return '系统管理员'
                }
            }          
        },        
        {
            title: '岗位',
            key: 'ess_name',
            dataIndex: 'ess_name', 
            // render:(text,record) => {
            //     if(record.eu_role==='1'){
            //         return text
            //     }else{
            //         return '系统管理员'
            //     }
            // }          
        },
        {
            title: '状态',
            key: 'status', 
            render:(_,record) => {
                if(record.eu_state==='2'){
                    return <Tag  color="red">已禁用</Tag>
                }else{
                    return <Tag color='green'>已启用</Tag> 
                }            
            }            
        },    
        {
            title: '部门',
            dataIndex: 'ed_name',
            key: 'ed_name',
        },       
        {
            title: '操作',
            key: 'action',
            render: (_, record) => {
                return  <Space>
                            <SetUserEdit 
                                parent_dept_id={props.selectedDept.ed_id}
                                eu_state={record.eu_state}
                                eu_id={record.key}
                                deptName={props.selectedDept.ed_name}  
                            />
                            <SetUserDel 
                                eu_trail_tag={record.eu_trail_tag} 
                                eu_state={record.eu_state}
                                eu_id={record.key}
                                eu_name={record.eu_name}
                            />
                            <SetUserDisable 
                                eu_trail_tag={record.eu_trail_tag} 
                                eu_state={record.eu_state}
                                eu_id={record.key}
                                eu_name={record.eu_name}
                            />
                              
                        </Space >
            }        
        },
    ];
    //列表分页
    function onChange(pagination, filters, sorter, extra) { }
    return (
        <>
            <div>用户列表</div>
            <Space style={{marginTop:"20px"}}>                       
                <SetUserAdd 
                    parent_dept_id={props.selectedDept.ed_id}
                    deptName={props.selectedDept.ed_name}
                />
                <SetUserQuery 
                    ed_id={props.selectedDept.ed_id}
                    getUserQuery={(e) => {
                        setQuery(prevState=>({
                            ...prevState,
                            eu_name:e.eu_name,
                            eu_ba_phone:e.eu_ba_phone,
                            eu_state:e.eu_state
                        }))
                    }}
                />
            </Space>
            <Table
                style={{marginTop:"20px"}}
                columns={columns}
                dataSource={list}
                onChange={onChange}
                locale={props.selectedDept.ed_id?{emptyText:'暂无用户'}:{emptyText:'请先选择部门！'}}
                pagination={{ 
                    showTotal: total => `共${list.length}条数据`, 
                    showSizeChange: true, 
                    showQuickJumper: true,
                    defaultPageSize:50, 
                }}
                
            />
        </>
    )
}

export default SetUserList