import React, { useState } from 'react'
import {Modal,} from 'antd';
import { useAppSynthesisUserReadLogMutation } from '../store/ReactWebSiteApi';
function CourseDesc(props) {
    const [open,setOpen] = useState(false);    
    const Ok = () => {setOpen(false);};
    const Cancel = () => {setOpen(false);};
    //阅读日志
    const [readLog] = useAppSynthesisUserReadLogMutation()
    return (        
        <>
            <a 
                onClick={() => {
                    setOpen(true);
                    readLog({
                        eusl_uid:localStorage.getItem('eu_id'),
                        eusl_uname:localStorage.getItem('eu_name'),
                        eusl_esvs_id:props.esvs_id,
                    }).then((res) => {
                        if (res.data.Status === 'success') {
                            console.log(res);
                        }
                        if (res.data.Status === "fail") {
                            console.log(res);
                        }
                    })
                }}
                style={{fontSize:'16px'}}
            >{props.esvs_name}</a>
            <Modal title={props.esvs_name} open={open} onOk={Ok} onCancel={Cancel} okText='关闭' footer={null}>                                   
                <div dangerouslySetInnerHTML={{__html:props.esvs_content}} style={{maxWidth:"1420px",overflow:"hidden"}}></div>                               
            </Modal>
        </>
    )
}

export default CourseDesc