import React,{useState,useEffect,useRef}from 'react'
import Main from '../../cpn/Main'
import { Table,Button,Space,Modal,message,Form,Input,} from 'antd';
import {useEntAddMutation, useEntListQuery, useEntUpdateSaveMutation, useEntUpdateQuery} from '../../store/ReactWebSiteApi';
import { useNavigate } from 'react-router-dom';
function FirmList() {
    //新增企业----------------------------------------------------------------------------------------------------------------新增企业//
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateAdd = (values) => {
        console.log('Received values of form: ', values);
        submitAdd({
            e_name:infoAdd.e_name,
            eu_name:infoAdd.eu_name,
            eu_ba_phone:infoAdd.eu_ba_phone,
            e_content:infoAdd.e_content,
            e_soft_name:infoAdd.e_soft_name,
            e_uid:localStorage.getItem('eu_id'),
            e_uname:localStorage.getItem('eu_name'),
        }).then((res) => {
            // console.log(res);
            if (res.data.Status === 'success') {
                message.success('新增成功！')
                setOpenAdd(false)                                
            }
            if (res.data.Status === 'fail') {
                message.error('新增失败！'+res.data.Message)
            }
        })
    };
    const [infoAdd,setInfoAdd] = useState({
        e_e_id:localStorage.getItem('e_id'),
        e_dept_id:localStorage.getItem('ed_id'),
        e_uid:localStorage.getItem('eu_id'),
        e_uname:localStorage.getItem('eu_name'),
    });
    const [submitAdd] = useEntAddMutation()
    const modalAdd = <Modal
                        open={openAdd}
                        title='新增企业'
                        width={1000}
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateAdd(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                                
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                        >
                            <Form.Item name="name" label='企业名称' wrapperCol={{span:15}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,e_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="admin" label='管理员姓名' wrapperCol={{span:15}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eu_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="phone" label='手机号码' wrapperCol={{span:15}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eu_ba_phone:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="soft" label='软件名称' wrapperCol={{span:15}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,e_soft_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="content" label='备注说明' wrapperCol={{span:15}} required>
                                <Input onChange={(e) => {setInfoAdd(prevState=>({...prevState,e_content:e.target.value}))}} />
                            </Form.Item>
                        </Form>
                    </Modal>
    //修改企业----------------------------------------------------------------------------------------------------------------修改企业//
    const editForm = useRef()
    const [editId,setEditId] = useState();
    const {data:dataEdit,isSuccess:isSuccessEdit} = useEntUpdateQuery({e_id:editId},{skip:!editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    e_name:dataEdit.Result.e_name,
                    e_content:dataEdit.Result.e_content,
                    e_soft_name:dataEdit.Result.e_soft_name,
                })
                setInfoEdit(prevState=>({...prevState,
                    e_name:dataEdit.Result.e_name,
                    e_content:dataEdit.Result.e_content,           
                    e_soft_name:dataEdit.Result.e_soft_name,           
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submintEdit] = useEntUpdateSaveMutation()
    const [openEdit, setOpenEdit] = useState(false);
    const [infoEdit, setInfoEdit] = useState()
    const onCreateEdit = () => {
        submintEdit({
            e_name:infoEdit.e_name,
            e_content:infoEdit.e_content,
            e_soft_name:infoEdit.e_soft_name,
            e_uid:localStorage.getItem('eu_id'),
            e_uname:localStorage.getItem('eu_name'),
            e_id:editId,
        }).then((res) => {
            // console.log(res);
            if (res.data.Status === 'success') {
                message.success('修改成功！')
                setOpenEdit(false)                                   
            }
            if (res.data.Status === 'fail') {
                message.error('修改失败！'+res.data.Message)
            }
        })
    }
    const modalEdit =   <Modal
                            open={openEdit}
                            width={1000}
                            title='修改企业'
                            okText="提交"
                            cancelText="取消"
                            onCancel={() => {setOpenEdit(false);}}
                            onOk={() => {
                                form
                                    .validateFields()
                                    .then((values) => {
                                        form.resetFields();
                                        onCreateEdit(values);
                                    })
                                    .catch((info) => {
                                        console.log('Validate Failed:', info);
                                    });
                                
                            }}
                        >
                            <Form
                                form={form}
                                layout="horizonal"
                                name="form_client_edit"
                                labelCol={{ span: 5 }}
                                ref={editForm}
                            >
                                <Form.Item name="e_name" label='企业名称' wrapperCol={{span:15}} required>
                                    <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,e_name:e.target.value}))}}/>
                                </Form.Item>
                                <Form.Item name="e_soft_name" label='软件名称' wrapperCol={{span:15}} required>
                                    <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,e_soft_name:e.target.value}))}}/>
                                </Form.Item>
                                <Form.Item name="e_content" label='备注说明' wrapperCol={{span:15}} required>
                                    <Input onChange={(e) => {setInfoEdit(prevState=>({...prevState,e_content:e.target.value}))}} />
                                </Form.Item>
                            </Form>
                        </Modal>
    
    
    //企业列表----------------------------------------------------------------------------------------------------------------企业列表//
    //查询企业
    const [query,setQuery] = useState({
        e_name:'',       
    })
    const [open,setOpen] = useState();
    
    const {data, isSuccess} = useEntListQuery(query);
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if (isSuccess) {
            //console.log(data);
            if(data.Status==='success'){
                
                const newArr = data.Result.map(item => {
                    const {e_id:key,admin,e_name,e_num,e_content,e_uname,e_ctime,e_type_id,e_state,amount,e_soft_name} = item;
                    return item = {key,admin,e_name,e_num,e_content,e_uname,e_ctime,e_type_id,e_state,amount,e_soft_name}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.e_ctime;
                    let bTimeString = b.e_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    
    
    const navi = useNavigate();
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'e_type_id',
            title: '企业名称',
            dataIndex:'e_name',
           
        },
       
        {
            key: 'room',
            title: '管理员',
            render:(_,record) => {
                if(record.admin){
                    return<>{record.admin.eu_name + ' - '+record.admin.eu_ba_phone}</>
                }
            }
        },        
        {
            key: 'account',
            title: '账户余额',
            dataIndex:'amount'
        },
        {
            key: 'e_soft_name',
            title: '软件名称',
            dataIndex:'e_soft_name',
           
        },
        {
            key: 'num',
            title: '备注',    
            dataIndex:'e_content'                    
        },
        {
            key: 'time',
            title: '创建/修改时间',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.e_ctime + record.e_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space >                       
                        <a onClick={() => {
                            setOpenEdit(true);
                            setEditId(record.key)                            
                            }}>修改</a>
                        <a onClick={() => {navi(`/FirmUser?esv_id=27&id=${record.key}&name=${record.e_name}`)}}>用户</a>                       
                        <a onClick={() => {navi(`/FirmRecharge?esv_id=27&id=${record.key}&name=${record.e_name}`)}}>账户</a>
                        <a onClick={() => {navi(`/FirmApp?esv_id=27&id=${record.key}&name=${record.e_name}`)}}>应用</a>
                        <a onClick={() => {navi(`/FirmPost?esv_id=27&id=${record.key}&name=${record.e_name}`)}}>岗位</a>
                        <a onClick={() => {
                            setFirm({id:record.key,name:record.e_name})
                            setOpenType(true);
                        }}>数据类型</a>
                    </Space>
                </>
            }
        },
    ]
    //数据类型======================================================================================================================================================数据类型//
    const [openType,setOpenType] = useState();
    const [firm,setFirm] = useState({id:'',name:''});    
    const onOkType = () => {setOpenType(false)};
    const onCancelType = () => {setOpenType(false)};    
    const modalType =   <Modal title='请选择数据类型' open={openType} onOk={onOkType} onCancel={onCancelType} okText='关闭'>
                            <div>
                                <p>
                                    <a onClick={() => {navi(`/FirmTypeGoods?id=${firm.id}&name=${firm.name}`)}}>产品类型</a>
                                </p>
                                <p>
                                    <a onClick={() => {navi(`/FirmTypeBalance?id=${firm.id}&name=${firm.name}&type=3`)}}>借款类型</a>                                    
                                </p>
                                <p>
                                    <a onClick={() => {navi(`/FirmTypeBalance?id=${firm.id}&name=${firm.name}&type=4`)}}>费用类型</a>
                                </p>
                            </div>          
                        </Modal>
    
    return (
        <Main  t1='商城' n1='/GfIndexMall' t2='企业' n2='/FirmIndex' t3='企业列表'>
            <Space>
                <Button type='primary' ><a onClick={() => {setOpenAdd(true)}}>新增</a></Button>
                <Button><a onClick={() => {setOpen(true)}}>查询</a></Button>
            </Space>
            <Table columns={columns} dataSource={list} style={{marginTop:"10px"}}></Table>
            {modalAdd}            
            {modalEdit}
            {modalType}
            <Modal
                open={open}
                title='查询'
                okText="提交"
                cancelText="关闭"
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        <Button onClick={() => {                                        
                            form.resetFields();
                            setQuery({
                                e_name:query.e_name,                                
                            });
                            setOpen(false)
                            }}>重置</Button>
                        <CancelBtn />
                    </>
                )}
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            setQuery(prevState=>({...prevState,ec_action:'search'}))
                            setOpen(false)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_in_modal"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                >
                    
                    <Form.Item name="ec_content" label='企业名称' wrapperCol={{span:15}}>
                        <Input
                            value={query.e_name} 
                            onChange={(e) => {                            
                                setQuery(prevState=>({...prevState,e_name:e.target.value}))                           
                            }}
                        />
                    </Form.Item>                                                                            
                </Form>
            </Modal>
        </Main>
    )
}

export default FirmList