import React, { useState,} from 'react'
import { Modal,Form, Button } from 'antd'
function ModalQuery(props) {
    const [form] = Form.useForm();   
    const [open,setOpen] = useState(false)    
    return (
        <>
            <Button onClick={() => {setOpen(true)}}>查询</Button>
            <Modal
                open={open}
                title={`查询${props.title}`}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={props.width}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            props.getQuery(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });    
                }}
                footer={[
                    <Button>重置</Button>,
                    <Button>关闭</Button>,
                    <Button type='primary' onClick={() => {}}>提交</Button>,
                ]}
            >
                {props.children}
            </Modal>
        </>
    )
}

export default ModalQuery