import React, { useState,useEffect, } from 'react';
import { Row, Col, Tree, Space,} from 'antd';
import Main from '../cpn/Main'
import classes from './set.module.css';
import { useAll_deptQuery} from '../store/ReactWebSiteApi';
import SetDeptAdd from './SetDeptAdd';
import SetDeptDel from './SetDeptDel';
import SetDeptEdit from './SetDeptEdit';
import SetUserList from './SetUserList';
function SetDept() {   
    //设置部门列表
    const [deptList, setDeptList] = useState([]);
    //获取服务器数据
    const { data, isSuccess } = useAll_deptQuery({ 'e_id': localStorage.getItem('e_id')});
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log('allDeptData',data);
                //部门列表   
                let newarray = [];
                newarray.push(data.Result)
                setDeptList(newarray);
            }
        }
    },[data,isSuccess])
    //设置选中的部门
    const [selectedDept, setSelectedDept] = useState({});

    //设置展开的部门
    const [expandedDept, setExpandedDept] = useState([]);
    //展开部门
    const expandedDeptInput = (e) => {
        setExpandedDept(e);
        setSelectedDept([]);
    }          
    return (
        <Main t1='系统设置' n1='/SetIndex'  t2='部门和用户'>
            <Row className={classes.settingMainBorder}>
                <Col span={5} style={{ borderRight: '2px solid #eee' }}>
                    <div>部门列表 > {selectedDept.ed_name}</div>
                    <Space style={{ marginTop: '20px' }} >
                        <SetDeptAdd 
                            selectedDept={selectedDept} 
                            getNewDept={(e) => {
                                setExpandedDept(prevstate => ([...prevstate, e.expandedDept]));
                                setSelectedDept(e.selectedDept);
                            }}
                        />
                        <SetDeptEdit 
                            selectedDept={selectedDept} 
                        />
                        <SetDeptDel 
                            selectedDept={selectedDept} 
                        />
                    </Space>                                       
                    <div style={{marginTop:'20px'}}>
                        <Tree
                            blockNode
                            defaultExpandParent={false}
                            treeData={deptList}
                            selectedKeys={selectedDept.ed_id}
                            onSelect={(e,f) => {
                                setSelectedDept(prevState=>({...prevState,ed_id:e[0],ed_name:f.node.title}))
                                if(e){
                                    
                                }
                            }}
                            expandedKeys={expandedDept}
                            onExpand={expandedDeptInput}  
                            autoExpandParent={false}
                            selectable
                        /> 
                    </div>
                </Col>
                <Col span={19} style={{ paddingLeft: "15px" }}>
                    <SetUserList 
                        selectedDept={selectedDept}
                    />
                </Col>
            </Row>         
        </Main>
    )
}

export default SetDept