import { Form, InputNumber, Modal, } from 'antd';
import React, { useState } from 'react'
import UploadOne from '../../../../../cpn/UploadOne';
import FeedbackForm from '../../temp/feedback/FeedbackForm';
function FeedBackAdd() {
    const [open,setOpen] = useState(false)
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        ltbjd_daoyou:'',
        ltbjd_daoyou_phone:''
    })
    return (
        <>
            <a onClick={() => {setOpen(true);}}>新增意见反馈单</a>
            <Modal
                title='新增意见单'
                onOk={(e) => {                   
                }}
                open={open}
                onCancel={() => {setOpen(false)}}
            >
               <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{
                        modifier: 'public',
                        fenche:'1',
                    }}
                    labelCol={{ span: 6 }}
                >                                                              
                    
                    <FeedbackForm 
                        required
                        ltbjd_daoyou={info.ltbjd_daoyou}
                        ltbjd_daoyou_phone={info.ltbjd_daoyou_phone}
                    />
                    <Form.Item name="fenche" label='分车序号' wrapperCol={{span:10}}>
                        <InputNumber
                        />
                    </Form.Item>
                    <Form.Item 
                        label='游客名单' 
                        name="file" 
                        wrapperCol={{span:15}} 
                        extra={<>上传游客名单，具体格式<a>点击</a></>}
                    >
                        <UploadOne                      
                        />
                    </Form.Item>                    
                </Form>
            </Modal>
        </>
    )
}

export default FeedBackAdd