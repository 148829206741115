import React,{useState,}from 'react'
import { UploadOutlined,} from '@ant-design/icons';
import { Button,Modal,message,Form,Input,Upload, Select,} from 'antd';
import { useAppSynthesisAddMutation,  } from '../../store/ReactWebSiteApi';
import MyEditor from '../../cpn/MyEditor';
import {appCourseType} from './AppCourseType'
function AppCourseAdd(props) {
    //上传首页图片
    const homeChange = (e) => {
        if (e.file.status === 'done'||e.file.status==='removed') {
            const fileArr = e.fileList.map((item) => {
                const { response } = item
                return response.Result
            })
            console.log(e);
            let fileStr = fileArr + "";  
            if(fileStr === null){
                fileStr = ''
            }
            setInfo(prevState=>({...prevState,esv_image_name:fileStr}))
        }
    }
    
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreateAdd = (values) => {
        // console.log('Received values of form: ', values);
        submitAdd(info).then((res) => {
            if (res.data.Status === 'success') {
                message.info('新增成功！');
                setOpen(false);
            }
            if (res.data.Status === "fail") {
                message.error('新增失败！'+res.data.Message)
            }
        })
        
    };
    const [info,setInfo] = useState({
        esvs_uid:localStorage.getItem('eu_id'),
        esvs_uname:localStorage.getItem('eu_name'),
        esvs_esv_id:props.esv_id,        
    });
    const [submitAdd] = useAppSynthesisAddMutation()
  return (
        <>
            <a onClick={() => {setOpen(true)}}>新增</a>
            <Modal
                width={1000}
                open={open}
                title='新增教程'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateAdd(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_add"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                >
                    <Form.Item name="class" label='类型' wrapperCol={{span:10}} required>
                        <Select options={appCourseType} onChange={(e) => {setInfo(prevState=>({...prevState,esvs_type_id:e}))}}/>
                    </Form.Item>
                    <Form.Item name="name" label='标题' wrapperCol={{span:10}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,esvs_name:e.target.value}))}}/>
                    </Form.Item>
                    
                    <Form.Item name="remark" label='图文描述' wrapperCol={{span:15}}>
                        <MyEditor onChange={(e) => {setInfo(prevState=>({...prevState,esvs_content:e}))}}  value={info.esvs_content}/>
                    </Form.Item>                 
                    <Form.Item 
                        label="附件"                         
                        valuePropName='fileList'
                        getValueFromEvent={e => {
                            if (Array.isArray(e)) {
                                return e;
                            }
                            return e && e.fileList;
                        }}
                    >
                        <Upload
                            name="image_file"
                            action="/api/ReactApi/zd_single_upload_image"
                            listType="picture"
                            className="upload-list-inline"
                            onChange={homeChange}
                            maxCount={1}
                            >
                            <Button icon={<UploadOutlined />}>点击上传</Button>
                        </Upload>
                    </Form.Item>                    
                </Form>
            </Modal>
        </>
    )
}

export default AppCourseAdd