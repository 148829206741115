import React from 'react'
import { Modal,message,} from 'antd';
import {usePhotoAlbumGroupZipMutation} from '../store/ReactWebSiteApi';

function AlbumSectionZip(props) {
    const {confirm} = Modal; 
    const [submitZip] = usePhotoAlbumGroupZipMutation()
    return (
        <>
            <a onClick={() => {
                confirm({
                    title: '确定压缩打包吗?',
                    content: '压缩打包后可以下载相册到本地！',
                    okText: '确认',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        submitZip({
                                epa_id:props.epa_id,
                                epa_name:props.epa_name,
                                epag_id:props.epag_id,
                                epag_name:props.epag_name,
                            }).then((res) => {
                            if (res.data.Status === 'success') {
                                message.info('打包成功！');
                            }
                            if (res.data.Status === "fail") {
                                message.error('打包失败！'+res.data.Message)
                            }
                        })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            }}>压缩打包</a>
        </>
    )
}

export default AlbumSectionZip