import React,{useRef, useState}from 'react'
import { Form,Input,message} from 'antd';
import { useDijieTemplateZhuYiShiXiangAddMutation,} from '../../../../../store/ReactWebSiteApi';
import ModalAdd from '../../../../../cpn/ModalAdd';
import GetUserDept from '../../../../../cpn/GetUserDept';
const  {TextArea} = Input
function NotesAdd(props) {
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
       
    });
    const [submitAdd] = useDijieTemplateZhuYiShiXiangAddMutation()
    const [open,setOpen] = useState(false)
    const editForm = useRef()
    return (
        <ModalAdd 
            title='注意事项模板'
            onOk={(e) => {
                submitAdd({
                    ltzysx_e_id:localStorage.getItem('e_id'),
                    ltzysx_uid:localStorage.getItem('eu_id'),
                    ltzysx_uname:localStorage.getItem('eu_name'),
                    ltzysx_dept_id:props.ltbjd_dept_id?props.ltbjd_dept_id:info.ltzysx_dept_id,
                    ltzysx_name:info.ltzysx_name,
                    ltzysx_content:info.ltzysx_content,
                }).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('新增成功！') 
                        setOpen(false)                                                 
                    }
                    if (res.data.Status === 'fail') {
                        message.error('新增失败！'+res.data.Message)
                    }
                })
            }}
            open={open}
            getOpen={(e) => {
                setOpen(prevsState=>e)
                if(e){
                    setInfo(prevState=>({...prevState,ltzysx_dept_id:'',ltzysx_name:'',}))
                    form.resetFields(); 
                }
            }}
        >
            <Form
                ref={editForm}
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 3 }}
            >                                           
                {!props.ltbjd_dept_id && <GetUserDept 
                    label='部门' 
                    name='dept'
                    required 
                    wrapperCol={{span:20}}
                    getUserDept={(e) => {setInfo(prevsState=>({...prevsState,ltzysx_dept_id:e}))}}
                />}
                <Form.Item name="ltzysx_name" label='标题' wrapperCol={{span:20}}>
                    <Input 
                        showCount 
                        maxLength={7}
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltzysx_name:e.target.value}))}}
                    />
                </Form.Item>
                <Form.Item name="ltzysx_content" label='描述' wrapperCol={{span:20}}>
                    <TextArea 
                        autoSize={{
                            minRows: 2,                                
                          }}
                          onChange={(e) => {setInfo(prevsState=>({...prevsState,ltzysx_content:e.target.value}))}}
                    />
                </Form.Item>
            </Form>
        </ModalAdd>
    )
}

export default NotesAdd