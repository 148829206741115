import React ,{useEffect, useState,} from 'react'
import Main from '../../cpn/Main'
import { Space,Table,message} from 'antd'
import { useSearchParams } from 'react-router-dom';
import { useDuizhangReceptionBaningMutation, useDuizhangReceptionListQuery } from '../../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import ArapStatementReceiveCheck from './ArapStatementReceiveCheck';
import ArapStatementReceiveAudit from './ArapStatementReceiveAudit';
function ArapStatementReceive() {
    const [param,setParam] = useSearchParams();
    const [submit] = useDuizhangReceptionBaningMutation()
    useEffect(() => {
        if(param.get('edz_id')===localStorage.getItem('e_id')){
            message.error('对账单不能发给自己哦！')
            return false;
        }
        if(param.get('edz_id')){
            submit({
                edz_id:param.get('edz_id'),
                e_id:localStorage.getItem('e_id')
            }).then((res) => {
                // console.log(res);
                if (res.data.Status === 'success') {
                    message.success('接收成功！')  
                    setParam({
                        esv_id:'12'
                    })
                }
                if (res.data.Status === 'fail') {
                    message.error('接收失败！'+res.data.Message)
                }
            })    
        }
        
    },[param, setParam, submit])
    //对账单接收列表
    const {data,isSuccess} = useDuizhangReceptionListQuery({e_id:localStorage.getItem('e_id')})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                const arr = data.Result.map((item) => {
                    const {edz_id:key,e_id,ec_id,e_name,edz_s_date,ec_name,edz_e_date,edz_num,edz_audit_ctime,edz_amount,edz_ec_id,edz_uname,edz_affirm_ctime,edz_send_e_id,edz_uid} = item;
                    return item = {key,e_id,ec_id,e_name,edz_s_date,ec_name,edz_e_date,edz_num,edz_audit_ctime,edz_amount,edz_ec_id,edz_uname,edz_affirm_ctime,edz_send_e_id,edz_uid}
                })
                setList(arr)
            }else{
                setList([])
            }
        }
    },[data,isSuccess])
    const [list, setList] = useState();
    const columns = [
        {
            key: 'id',
            title: '发送人',
            dataIndex: 'e_name',
            render:(_,record) => {
                return<>{record.e_name}-{record.edz_uname}</>
            }

        },         
        {
            key: 'ar',
            title: '出发日期',
            render:(_,record) => {
                return<>
                    {dayjs(record.edz_s_date).format('YYYY年MM月DD日')} 至 {dayjs(record.edz_e_date).format('YYYY年MM月DD日')}
                </>
            }
        },             
        {
            key: 'ap',
            title: '金额',
            dataIndex: 'edz_amount',
        }, 
        {
            key: '1',
            title: '编号',
            dataIndex: 'key'
        },            
        {
            key: '3',
            title: '发送时间',
            dataIndex: 'edz_audit_ctime'
        },            
        {
            key: '2',
            title: '审核状态',
            dataIndex: 'edz_affirm_ctime',
            render:(text) => {
                if(text){
                    return<>已确认</>
                }else{
                    return<>未确认</>
                }
            }
        },            
        {
            key: 'profit',
            title: '操作',
            render:(_,record) => {
                return  <Space>
                            <ArapStatementReceiveCheck
                                eie_e_id={record.e_id}
                                eie_client_id={record.ec_id}
                                ebn_date_s={record.edz_s_date}
                                ebn_date_e={record.edz_e_date}
                                ec_name={record.ec_name}
                                edz_amount={record.edz_amount}
                                edz_num={record.edz_num}
                                edz_uname={record.edz_uname}
                                eu_id={record.edz_uid}
                            />
                            <ArapStatementReceiveAudit 
                                edz_affirm_ctime={record.edz_affirm_ctime}
                                edz_id={record.key}                                
                            />                                                 
                        </Space>
            }
        },         
    ]
    return (
        <Main t1='对账单接收'>
            <Table 
                columns={columns} 
                dataSource={list}                
             />
        </Main>
    )
}

export default ArapStatementReceive