import { Select,Form, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useClientTypeListQuery,} from '../../store/ReactWebSiteApi';
import SetClientType from './SetClientType';

function GetClientType(props) {
    const [list,setList] = useState();
    const {data, isSuccess} = useClientTypeListQuery({ect_e_id:localStorage.getItem('e_id')});
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                const newArr = data.Result.map((item) => {
                    const {ect_id,ect_name,}=item;
                    return item = {value:ect_id,label:ect_name}
                })
                setList(newArr)
            }
        }
    },[data,isSuccess])
    return (
        <>
            <Form.Item
                label={props.label} 
                wrapperCol={props.wrapperCol} 
                required={props.required} 
                style={{marginBottom:"0"}}
            >
                <Space>
                    <Form.Item                            
                        name={props.name} 
                    >
                        <Select 
                            options={list} 
                            onChange={(_,f) => {
                                // console.log(f);
                                props.getClientType(f.value,f.label)
                            }}
                            defaultValue={props.defaultId}
                            placeholder={props.defaultId}
                            style={{width:'300px'}}
                        />
                    
                    </Form.Item>
                    <SetClientType />
                </Space>
            </Form.Item>
        </>
    )
}

export default GetClientType