import React,{useState,}from 'react'
import { Button,Modal,message,Form,Input,Upload} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useWorkPlanAddMutation, } from '../store/ReactWebSiteApi';
import MyEditor from '../cpn/MyEditor';

function TrainPlanAdd() {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({
        ewp_content:'',
        ewp_file_name:'',
        ewp_file_file:''
    });
    //上传
    const onChange = (e) => {
        if (e.file.status === 'done'||e.file.status==='removed') {
            const fileArr = e.fileList.map((item) => {
                const { response } = item
                return response.Result
            })
            console.log(e);
            let fileStr = fileArr + "";  
            if(fileStr === null){
                fileStr = ''
            }
            const nameArr = e.fileList.map((item) => {
                const { name} = item
                return name
            })
            let nameStr = nameArr + ""
            setInfo(prevState=>({...prevState,ewp_file_file:fileStr,ewp_file_name:nameStr}))
        }
    }
    
    const [submitAdd] = useWorkPlanAddMutation()
    return (
        <>
            <Button type='primary' onClick={() => {setOpen(true);}}>新增</Button>
            <Modal
                open={open}
                title='新增培训计划'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        ewp_name:info.ewp_name,
                        ewp_content:info.ewp_content,
                        ewp_file_name:info.ewp_file_name,
                        ewp_file_file:info.ewp_file_file,
                        ewp_uid:localStorage.getItem('eu_id'),
                        ewp_uname:localStorage.getItem('eu_name'),
                        ewp_e_id:localStorage.getItem('e_id'),
                        ewp_dept_id:localStorage.getItem('ed_id'),
                        ewp_esv_id:'5',
                        
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                >
                    
                    <Form.Item name="name" label='标题' wrapperCol={{span:15}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,ewp_name:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item name="content" label='正文' wrapperCol={{span:15}} required>
                        <MyEditor onChange={(e) => {setInfo(prevState=>({...prevState,ewp_content:e}))}} value={info.ewp_content}/>                                
                    </Form.Item>
                    <Form.Item label="附件"   
                                          
                        valuePropName='fileList'
                        getValueFromEvent={e => {
                            if (Array.isArray(e)) {
                                return e;
                            }
                            return e && e.fileList;
                        }}
                    >
                        <Upload
                            name="image_file"
                            action="/api/ReactApi/zd_single_upload_image"
                            listType="text"
                            className="upload-list-inline"
                            onChange={onChange}
                            maxCount={1}
                            >
                            <Button icon={<UploadOutlined />}>点击上传</Button>
                        </Upload>
                    </Form.Item>                    
                </Form>
            </Modal>
        </>
    )
}

export default TrainPlanAdd