import React from 'react'
import Main from '../../cpn/Main'
import { Row} from 'antd';
import Folder from '../../cpn/Folder';
import { indexFinance } from '../balance/balanceType';

function FundTable() {
    return (
        <Main t1='财务' n1={indexFinance} t2='转账' n2='/FundIndex' t3='报表'>            
            <Row>                           
                <Folder title='部门利润表'  link='/FundSumDept' />     
                <Folder title='客户利润表'  link='/FundSumClient' />       
                <Folder title='客户类型利润表'  link='/FundSumClientType' />  
                <Folder title='产品利润表'  link='/FundSumGoods' />        
                <Folder title='产品类型利润表'  link='/FundSumGoodsType' />
                <Folder title='操作员利润表'  link='/FundSumOperator' />         
                <Folder title='销售员利润表'  link='/FundSumSalesman' />  
                      
            </Row>
        </Main>
    )
}

export default FundTable