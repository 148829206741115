import React,{useState,useEffect,useRef}from 'react'
import Main from '../../cpn/Main'
import { Table,Button,Space,Modal,message,Form,Input,Radio} from 'antd';
import {useEntAddMutation,  useEntAdminListQuery, useEntAdminUpdateQuery, useEntAdminUpdateSaveMutation, useEntAdminStateMutation} from '../../store/ReactWebSiteApi';
import { useSearchParams } from 'react-router-dom';
function FirmUser() {
    const [param] = useSearchParams()
    //新增用户----------------------------------------------------------------------------------------------------------------新增用户//
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateAdd = (values) => {
        console.log('Received values of form: ', values);
        submitAdd({
            e_name:infoAdd.e_name,
            eu_name:infoAdd.eu_name,
            eu_ba_phone:infoAdd.eu_ba_phone,
            e_content:infoAdd.e_content,
            e_uid:localStorage.getItem('eu_id'),
            e_uname:localStorage.getItem('eu_name'),
        }).then((res) => {
            // console.log(res);
            if (res.data.Status === 'success') {
                message.success('新增成功！')
                setOpenAdd(false)                                
            }
            if (res.data.Status === 'fail') {
                message.error('新增失败！'+res.data.Message)
            }
        })
    };
    const [infoAdd,setInfoAdd] = useState({
        e_e_id:localStorage.getItem('e_id'),
        e_dept_id:localStorage.getItem('ed_id'),
        e_uid:localStorage.getItem('eu_id'),
        e_uname:localStorage.getItem('eu_name'),
    });
    const [submitAdd] = useEntAddMutation()
    const modalAdd = <Modal
                        open={openAdd}
                        title='新增用户'
                        width={1000}
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateAdd(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                                
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                        >
                            <Form.Item name="admin" label='姓名' wrapperCol={{span:15}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eu_name:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="phone" label='手机号码' wrapperCol={{span:15}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,eu_ba_phone:e.target.value}))}}/>
                            </Form.Item>
                            <Form.Item name="role" label='角色' wrapperCol={{span:15}} required>
                                <Radio value='1'>管理员</Radio>
                                <Radio value='2'>普通用户</Radio>
                            </Form.Item>
                        </Form>
                    </Modal>
    //修改用户----------------------------------------------------------------------------------------------------------------修改用户//
    const editForm = useRef()
    const [editId,setEditId] = useState();
    const {data:dataEdit,isSuccess:isSuccessEdit} = useEntAdminUpdateQuery({eu_id:editId},{skip:!editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    eu_name:dataEdit.Result.eu_name,
                    eu_ba_phone:dataEdit.Result.eu_ba_phone,
                })
                setInfoEdit(prevState=>({...prevState,
                    eu_name:dataEdit.Result.eu_name,
                    eu_ba_phone:dataEdit.Result.eu_ba_phone,        
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submintEdit] = useEntAdminUpdateSaveMutation()
    const [openEdit, setOpenEdit] = useState(false);
    const [infoEdit, setInfoEdit] = useState()
    const onCreateEdit = () => {
        submintEdit({
            eu_name:infoEdit.eu_name,
            eu_ba_phone:infoEdit.eu_ba_phone,
            eu_id:editId,
        }).then((res) => {
            // console.log(res);
            if (res.data.Status === 'success') {
                message.success('修改成功！')
                setOpenEdit(false)                                   
            }
            if (res.data.Status === 'fail') {
                message.error('修改失败！'+res.data.Message)
            }
        })
    }
    const modalEdit =   <Modal
                            open={openEdit}
                            width={1000}
                            title='修改用户'
                            okText="提交"
                            cancelText="取消"
                            onCancel={() => {setOpenEdit(false);}}
                            onOk={() => {
                                form
                                    .validateFields()
                                    .then((values) => {
                                        form.resetFields();
                                        onCreateEdit(values);
                                    })
                                    .catch((info) => {
                                        console.log('Validate Failed:', info);
                                    });
                                
                            }}
                        >
                            <Form
                                form={form}
                                layout="horizonal"
                                name="form_client_edit"
                                labelCol={{ span: 5 }}
                                ref={editForm}
                            >
                                <Form.Item name="eu_name" label='姓名' wrapperCol={{span:15}} required>
                                    <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eu_name:e.target.value}))}}/>
                                </Form.Item>
                                <Form.Item name="eu_ba_phone" label='手机号码' wrapperCol={{span:15}} required>
                                    <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,eu_ba_phone:e.target.value}))}}/>
                                </Form.Item>
                            </Form>
                        </Modal>
    
    
    //修改用户状态----------------------------------------------------------------------------------------------------------------修改用户状态//
    const [submitState] = useEntAdminStateMutation()

    //用户列表----------------------------------------------------------------------------------------------------------------用户列表//
    
    const {data, isSuccess} = useEntAdminListQuery({eu_e_id:param.get('id')});
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if (isSuccess) {
            //console.log(data);
            if(data.Status==='success'){
                
                const newArr = data.Result.map(item => {
                    const {eu_id:key,eu_ba_phone,eu_name,eu_num,eu_content,eu_uname,eu_ctime,eu_role,eu_state,amount} = item;
                    return item = {key,eu_ba_phone,eu_name,eu_num,eu_content,eu_uname,eu_ctime,eu_role,eu_state,amount}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.e_ctime;
                    let bTimeString = b.e_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'e_type_id',
            title: '姓名',
            dataIndex:'eu_name',
           
        },
        {
            key: 'room',
            title: '手机号码',
            dataIndex:'eu_ba_phone',
        },
        {
            key: 'account',
            title: '角色',
            dataIndex:'eu_role',
            render:(text) => {
                if(text==='2'){
                    return <>管理员</>
                }else{
                    return <>普通用户</>
                }
            }
        },      
        {
            key: 'status',
            title: '状态',
            dataIndex:'eu_state',
            render:(text) => {
                if(text==='2'){
                    return <>禁用</>
                }else{
                    return <>启用</>
                }
            }
        },      
        {
            key: 'time',
            title: '创建/修改时间',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.eu_ctime + record.eu_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>                       
                        <a onClick={() => {
                            setOpenEdit(true);
                            setEditId(record.key)                            
                            }}>修改</a>
                        {(record.eu_state==='1') ? <>
                                <a onClick={() => {
                                    submitState({eu_id:record.key,eu_state:'1'}).then((res) => {
                                        if(res.data.Status==='success'){
                                            message.info('禁用成功！')
                                        }else{
                                            message.error('禁用失败！' + res.data.Message)
                                        }
                                    })
                                }}>禁用</a>
                            </>:<>
                                <a onClick={() => {
                                    submitState({eu_id:record.key,eu_state:'2'}).then((res) => {
                                        if(res.data.Status==='success'){
                                            message.info('启用成功！')
                                        }else{
                                            message.error('启用失败！' + res.data.Message)
                                        }
                                    })
                                }}>启用</a>
                            </>}                       
                    </Space>
                </>
            }
        },
    ]

      
    return (
        <Main t1='企业' n1='/BnsFirm?esv_id=20'  t2={param.get('name')} t3='用户'>
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true)}}>新增用户</Button>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table>
            {modalAdd}            
            {modalEdit}
            
        </Main>
    )
}

export default FirmUser