import React,{useState,useEffect,}from 'react'
import { Space,Modal,message,Form,Checkbox, Input,Button,} from 'antd';
import { useAppStationAddMutation,  useGetEntAppMenuOperationQuery,  } from '../store/ReactWebSiteApi';
function SetPostAdd() {
    const [form] = Form.useForm(); 
    //获取企业应用权限
    const {data:dataApp,isSuccess:isSuccessApp} = useGetEntAppMenuOperationQuery({esv_type_id:localStorage.getItem('e_id')==='218'?'1,3':'1,5'})
    //新增
    const [infoApp,setInfoApp] = useState()
    useEffect(() => {
        if(isSuccessApp){
            if(dataApp.Status==='success'){
                console.log(dataApp);
                const arr = dataApp.Result.map((item) => {
                    const {esv_id,esv_name,menu,esv_image2_name} = item; 
                    let menuCheckbox                   
                    if(menu){
                        menuCheckbox = menu.map((item) => {
                            const {esvm_id,esvm_name} = item
                            return <Checkbox value={esvm_id} key={esvm_id}>{esvm_name}</Checkbox>
                        })
                    }                    
                    return  <Form.Item 
                                label={<Space><img src={`/api/assets/images/application/${esv_image2_name}`} alt='' width={30} />{esv_name}</Space>} 
                                name={'menu'+esv_id}  
                                wrapperCol={{span:15}} 
                                key={esv_id} 
                                esv_id={esv_id}
                            >
                                <Checkbox.Group onChange={(e) => {setInfo(prevState=>({...prevState,ess_menu_id:e}))}}>
                                    {menuCheckbox}
                                </Checkbox.Group>
                            </Form.Item>                            
                })   
                // 删除系统管理和我的
                let idsToRemove = ['8','19'];                
                const array = arr.filter(item => !idsToRemove.includes(item.props.esv_id));
            
                const sortArr = array.sort( (a, b) => {      
                    let aTime = a.props.esv_id;
                    let bTime = b.props.esv_id;
                    return aTime - bTime;     
                    },)
                setInfoApp(sortArr)                              
            }
        }
    },[dataApp, isSuccessApp]) 
    //新增岗位----------------------------------------------------------------------------------------------------------------新增岗位//
    const [open, setOpen] = useState(false);
    const onCreateAdd = (values) => {
         console.log('Received values of form: ', values);        
    };
    const [info,setInfo] = useState();
    const [submitAdd] = useAppStationAddMutation()
    
    return (
        <>
            <Button type='primary' onClick={() => {setOpen(true)}}>新增</Button>
            <Modal
                open={open}
                title='新增岗位'
                width={1000}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    let menuArr = [];
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            console.log(values);
                            for (let key in values) {                                        
                                if (key.includes("menu") && values[key]) { // 确保属性是对象自身的，而不是从原型链继承的
                                    menuArr.push(values[key][0])
                                    console.log('menuArr',menuArr);
                                }
                            }
                            setInfo(prevState=>({...prevState,menuArr}))                                    
                            onCreateAdd(values);
                            menuArr.push('15')
                            submitAdd({
                                ess_e_id:localStorage.getItem('e_id'),
                                ess_name:info.ess_name,
                                ess_menu_id:menuArr?menuArr:info.menuArr,
                                ess_uid:localStorage.getItem('eu_id'),
                                ess_uname:localStorage.getItem('eu_name'),
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')   
                                    setOpen(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })     
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                >
                    <Form.Item label='常用岗位' name='use' wrapperCol={{span:17}}>
                        <Space wrap>
                            <a onClick={() => {
                                form.setFieldsValue({admin:'门市接待',menu8:['15'],menu9:['4'],menu12:['3'],menu14:['10'],menu26:['17'],})
                                setInfo(prevState=>({...prevState,ess_name:'门市接待',menuArr:['4','3','10','17']}))
                                }}
                            >门市接待</a>                            
                            <a onClick={() => {
                                form.setFieldsValue({admin:'计调',menu8:['15'],menu9:['4'],menu12:['3'],menu14:['10'],menu26:['17'],})
                                setInfo(prevState=>({...prevState,ess_name:'计调',menuArr:['4','3','10','17']}))
                                }}
                            >计调</a>                            
                            <a onClick={() => {
                                form.setFieldsValue({admin:'电商客服',menu8:['15'],menu9:['4'],menu12:['3'],menu14:['10'],menu26:['17'],})
                                setInfo(prevState=>({...prevState,ess_name:'电商客服',menuArr:['4','3','10','17']}))
                                }}
                            >电商客服</a>                            
                            <a onClick={() => {
                                form.setFieldsValue({admin:'财务',menu8:['15'],menu9:['4'],menu10:['19'],menu11:['21'],menu12:['3'],menu14:['10'],menu26:['17'],})
                                setInfo(prevState=>({...prevState,ess_name:'财务',menuArr:['4','19','21','3','10','17']}))
                                }}
                            >财务</a>                            
                        </Space>
                    </Form.Item>
                    <Form.Item name="admin" label='岗位名称' wrapperCol={{span:8}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,ess_name:e.target.value}))}}/>
                    </Form.Item>
                    {infoApp}
                </Form>
            </Modal>
        </>
    )
}

export default SetPostAdd