import React ,{useState} from 'react'
import {Modal,} from 'antd';
function TrainPlanCheck(props) {
    const [open, setOpen] = useState(false);
    const Ok = () => {setOpen(false);};
    const Cancel = () => {setOpen(false);};
    let arr
    if(props.ewp_file_name){
        arr = props.ewp_file_name.map((item,index) => {
            return <div key={index}>{item}</div>
        })
    }else{
        arr = '暂无附件'
    }
    return (
        <>
            <a onClick={() => {setOpen(true)}}>{props.ewp_name}</a>
            <Modal 
                title={props.ewp_name} 
                open={open} 
                onOk={Ok} 
                onCancel={Cancel} 
                okText='关闭' 
                footer={null}
                width={800}
            >                                 
                <div dangerouslySetInnerHTML={{__html:props.ewp_content}} style={{maxWidth:"800px",overflow:"hidden"}}></div>
                <span>附件：{arr}</span>
            </Modal>
        </>
    )
}

export default TrainPlanCheck