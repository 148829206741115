import React,{useState,useEffect}from 'react'
import { Table,Space,Modal,message} from 'antd';
import {  useSearchParams, } from 'react-router-dom';
import { useCapitalAccountConfirmMutation, useCapitalAccountLogDelMutation, useCapitalAccountLogListQuery } from '../../store/ReactWebSiteFinanceApi';
import MainKeeper from '../MainKeeper';
function BankAccountDetail() {
    const [param] = useSearchParams()
    
    const {data, isSuccess} = useCapitalAccountLogListQuery({ecal_eca_id:param.get('id')});
    
    const [list, setList] = useState();
    const [lastData,setLastData] = useState()
    
    useEffect(() => {
        if (isSuccess) {
            //console.log(data);
            if(data.Status==='success'){
                
                const newArr = data.Result.map(item => {
                    const {ecal_id:key,ecal_dz_ctime,ecal_dz_uname,ecal_state,ecal_main_name,ecal_sublevel_name,ecal_amount,ecal_ec_name,ecal_responsible_uname,ecal_content,ecal_efg_name,ecal_ctime,ecal_uname,ecal_bank_detail_date,ecal_remain_amount} = item;
                    return item = {key,ecal_dz_ctime,ecal_dz_uname,ecal_state,ecal_main_name,ecal_sublevel_name,ecal_amount,ecal_ec_name,ecal_responsible_uname,ecal_content,ecal_efg_name,ecal_ctime,ecal_uname,ecal_bank_detail_date,ecal_remain_amount}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ecal_bank_detail_date;
                    let bTimeString = b.ecal_bank_detail_date;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
                const [max] = [...newArr].sort((a, b) => b.key - a.key)
                //console.log(max.key);
                setLastData(max.key)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    //删除
    const {confirm} = Modal; 
    const [delAccount] = useCapitalAccountLogDelMutation()
    //对账
    const [capitalAccountConfirm] = useCapitalAccountConfirmMutation()
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'time',
            title: '交易时间',            
            dataIndex:'ecal_bank_detail_date',
        },       
        {
            key: 'min',
            title: '金额',
            dataIndex: 'ecal_amount',
            render:(text) => {
                if(text>0){
                    return <>
                        <span style={{color:'red',fontWeight:'bold'}}>{text}</span>
                    </>
                }else{
                    return <>
                        <span style={{color:'green',fontWeight:'bold'}}>{text}</span>
                    </>
                }
            }
        },
        {
            key: 'remain',
            title: '账户余额',
            dataIndex:'ecal_remain_amount'
        },
        {
            key: 'client',
            title: '客户',
            dataIndex:'ecal_ec_name'
        },
        {
            key: 'state',
            title: '状态',            
            dataIndex:'ecal_state',
            render:(text,record) => {
                if(text==='0'){
                    return<>未对账</>
                }else{
                    return <>
                        <div>已对账，对账时间：</div>
                        {record.ecal_dz_uname && <div>{record.ecal_dz_ctime + ',' + record.ecal_dz_uname.replace(/"/g,'')}</div>}
                    </>
                }
            }
        },
        {
            key: 'time',
            title: '新增时间',
            render: (_,record) => {
                return <>
                    <div>{record.ecal_ctime+ ',' + record.ecal_uname.replace(/"/g,'')}</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>
                        {(record.key===lastData && record.ecal_state==='0') && <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    delAccount({ecal_id:record.key,ecal_eca_id:param.get('id')}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>}
                        {(record.key===lastData && record.ecal_state==='0') && <a onClick={() => {
                            confirm({
                                title: '确定对账吗?',
                                content: '对账后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    capitalAccountConfirm({ecal_id:record.key,ecal_dz_uid:localStorage.getItem('eu_id'),ecal_dz_uname:localStorage.getItem('eu_name')}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('对账成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('对账失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>对账</a>}
                    </Space>
                </>
            }
        },
    ]

      
    return (
        <MainKeeper t1='支付管理' t2='账户管理' t3='账户列表' t4='账户明细'  topActive='BankAccount'>
            <div>账户名称：{param.get('name')}</div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table>
          
        </MainKeeper>
    )
}

export default BankAccountDetail