import React  from 'react'
import Main from '../../cpn/Main'
import {Row } from 'antd'
import Folder from '../../cpn/Folder'
function OrganIndex() {
    return (
        <Main t1='组团业务'>
            <Row>                
                <Folder title='店铺' link='/PhoneShop'/> 
                <Folder title='定制游' link='/OrganCustomIndex'/> 
            </Row>
        </Main>
    )
}

export default OrganIndex