import React  from 'react'
import Main from '../cpn/Main'
import {Row } from 'antd'
import Folder from '../cpn/Folder'
function GfIndexFinance() {
    return (
        <Main t1='财务'>
            <Row>
                <Folder title='销售收入' link='/SellIncome'/>
                <Folder title='费用支出' link='/ExpenseIndex'/>
                <Folder title='其他收支' link='/ElseIndex'/>                    
                <Folder title='下账' link='/ArapIndex'/>                    
                <Folder title='转账' link='/FundIndex'/>
                <Folder title='客户' link='/Client'/>
                <Folder title='产品' link='/GoodsIndex'/>
            </Row>          
        </Main>
    )
}

export default GfIndexFinance