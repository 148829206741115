import React,{useState,}from 'react'
import { Modal,message,Form,Input, Button,} from 'antd';
import { useAddClientMutation, } from '../../store/ReactWebSiteApi';
import GetClientType from './GetClientType';

function ClientAdd(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreate = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({
        ec_e_id:localStorage.getItem('e_id'),
        ec_uname:localStorage.getItem('eu_name'),
        ec_uid:localStorage.getItem('eu_id'),
        ec_content:'',
        ec_esv_id:'9'
    });
    //客户类型
    const [submitAdd] = useAddClientMutation()
    return (
        <>
            <Button size='mini' onClick={() => {setOpen(true)}} type={props.type}>新增</Button>
            <Modal
                open={open}
                title='新增客户'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd(info).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            props.getNewClient(res.data.Result)
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                >                            
                    <GetClientType
                        name='type'
                        label='客户类型'
                        getClientType={(e) => {setInfo(prevState=>({...prevState,ec_type_id:e}))}}
                        required
                    />
                    <Form.Item name="name" label='客户名称' wrapperCol={{span:15}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,ec_name:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item name="content" label='客户备注' wrapperCol={{span:15}}>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,ec_content:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default ClientAdd