import React from 'react'
import { message,} from 'antd'
import { useDuizhangAuditMutation } from '../../store/ReactWebSiteApi';
function ArapStatementSendAudit(props) {    
    const [submit] = useDuizhangAuditMutation() 
    return (
        <>
            {!props.edz_affirm_ctime && (props.edz_audit_ctime ? <a onClick={() => {
                submit({
                    edz_id:props.edz_id,
                    edz_audit_uid:localStorage.getItem('eu_id'),
                    edz_audit_uname:localStorage.getItem('eu_name'),
                    edz_audit_status:2,
                }).then((res) => {
                    // console.log(res);
                    if (res.data.Status === 'success') {
                        message.info('取消审核成功！');
                    }
                    if (res.data.Status === "fail") {
                        message.error('取消审核失败！'+res.data.Message)
                    }
                })
            }}>取消审核</a> : <a onClick={() => {
                submit({
                    edz_id:props.edz_id,
                    edz_audit_uid:localStorage.getItem('eu_id'),
                    edz_audit_uname:localStorage.getItem('eu_name'),
                    edz_audit_status:1,
                }).then((res) => {
                    // console.log(res);
                    if (res.data.Status === 'success') {
                        message.info('审核成功！');
                    }
                    if (res.data.Status === "fail") {
                        message.error('审核失败！'+res.data.Message)
                    }
                })
            }}>审核</a>)}
        </>
    )
}

export default ArapStatementSendAudit