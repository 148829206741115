import React from 'react'
import { Modal,Button,} from 'antd';
function ModalAdd(props) {
    return (
        <>
            <Button type='primary' onClick={() => {props.getOpen(true);}} >新增</Button>
            <Modal
                open={props.open}
                title={`新增${props.title}`}
                okText="提交"
                cancelText="取消"
                onCancel={() => {props.getOpen(false)}}
                width={props.width}
                onOk={() => {props.onOk()}}
            >
                {props.children}
            </Modal>   
        </>
    )
}

export default ModalAdd