import React ,{useState,useRef,useEffect}from 'react'
import { Form, Input, Modal,message, Button, Radio, Space} from 'antd';
import GetPost from '../cpn/GetPost';
import { useRegisterCodeMutation, useUser_addMutation } from '../store/ReactWebSiteApi';
import GetAllDeptCheckbox from './GetAllDeptCheckBox';
function SetUserAdd(props) {
    //验证码倒计时
    const [isNote, setIsNote] = useState(false);
    const [time, setTime] = useState(null);
    const timeRef = useRef();
    useEffect(() => {
        if (time && time !== 0) {
            timeRef.current = setTimeout(() => {
                setTime((time) => time - 1);
            }, 1000);
        } else {
            setIsNote(false);
        }
        return () => {
            clearInterval(timeRef.current);
        };
    }, [time]);

    //获取验证码数据
    const [getCode] = useRegisterCodeMutation();
    //获取验证码
    const getCodeBtn = () => {
        setTime(60);
        setIsNote(true);
        getCode({
            phone:info.eu_ba_phone,
        }).then((res) => {
            console.log(res);
            if(res.data.Status==='success'){
                setTime(60);
                setIsNote(true);
            }
            if (res.data.Status === 'fail') {
                message.error('该手机号码未注册！'+ res.data.Message)    
            }
        })    
    }
    const [form] = Form.useForm();
    const [open,setOpen] = useState()
    //新增用户需要提交的数据
    const [info,setInfo] = useState({
        eu_e_id:localStorage.getItem('e_id'),
        eu_ba_phone:'',	
        eu_name: '',
        eu_uid: localStorage.getItem('eu_id'),
        eu_uname: localStorage.getItem('eu_name'),
        eu_role:'',
        parent_dept_id: props.parent_dept_id,
        eu_dept_id:props.eu_dept_id,
        eu_show_authority:''
    });

    //上传服务器
    const [addStaff] = useUser_addMutation();
    return (
        <>
            {((localStorage.getItem('e_name').replace(/"/g,'') !== props.deptName) && props.deptName) && <Button 
                type='primary'
                onClick={() => {                                          
                    if (!props.parent_dept_id) {
                        message.error('请先选择部门');
                        return false;
                    }
                    setInfo(prevState => ({...prevState,parent_dept_id: props.parent_dept_id}));    
                    setOpen(true);
                }}
            >新增</Button>}
            <Modal
                open={open}
                title='新增用户'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            if(!values.operatorDept){
                                message.error('至少选择一个部门！')
                                return false
                            }
                            form.resetFields();
                            addStaff({
                                eu_e_id:localStorage.getItem('e_id'),
                                eu_ba_phone:info.eu_ba_phone,	
                                eu_name:info.eu_name,
                                eu_uid: localStorage.getItem('eu_id'),
                                eu_uname: localStorage.getItem('eu_name'),
                                eu_role:info.eu_role,
                                parent_dept_id: props.parent_dept_id,
                                eu_dept_id:info.eu_dept_id,
                                eu_show_authority:info.eu_show_authority,
                                eu_station_id:info.eu_station_id,
                                phone_code:info.phone_code,
                            }).then((res) => {
                                    if (res.data.Status === 'success') {
                                        message.success('新增用户成功！');
                                        form.resetFields(); 
                                        setOpen(false)                                             
                                    }
                                    if (res.data.Status === 'fail') {
                                        message.error('新增用户失败！' + res.data.Message);
                                    }
                                });
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    
                }}
                width={'800px'}
            >
                <Form
                    name="basic"
                    labelCol={{span: 4,}}
                    wrapperCol={{span: 20,}}
                    initialValues={{remember: true,}}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item                          
                        label="部门"
                        wrapperCol={{ span: 8, }}
                    >
                        {props.deptName}
                    </Form.Item>
                    <Form.Item
                        label="姓名"
                        name="name"
                        rules={[{
                            required: true,
                            message: '请填写姓名!',
                        },]}
                        wrapperCol={{span: 10,}}
                    >
                        <Input value={info.eu_name} onChange={(e) => {setInfo(prevState => ({...prevState,eu_name:e.target.value}))}} />
                    </Form.Item>
                    <Form.Item
                        label="登录手机"
                        name="userPhone"
                        rules={[{
                            required: true,
                            message: '请填写登录手机!',
                        },
                        {
                        pattern: /^1[3-9]\d{9}$/,
                        message: '请输入正确的手机号'
                            }]}
                        wrapperCol={{span: 10,}}
                    >
                        <Input value={info.eu_ba_phone} onChange={(e) => {setInfo(prevState => ({...prevState,eu_ba_phone:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item
                        label='验证码'
                        required
                    >
                        <Space>
                                <Form.Item
                                    name="codeAdd"                              
                                    validateTrigger="onBlur"    
                                    noStyle
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入短信验证码!',
                                        },
                                        {
                                            pattern: new RegExp('^\\d{6}$'),
                                            message: '请输入正确的6位数字验证码!',
                                        },
                                    ]}
                                    >
                                        <Input 
                                            placeholder='请输入短信验证码' 
                                            onChange={(e) => {setInfo(prevState=>({...prevState,phone_code:e.target.value}))}} 
                                        />
                                </Form.Item>                            
                                {isNote ? <Button disabled style={{width:'100%',}} >{time}秒后重新发送</Button> : <Button style={{width:'100%',}}  onClick={getCodeBtn}>获取验证码</Button>}
                                </Space>
                    </Form.Item>
                    <Form.Item
                        label="系统权限"
                        name="system"
                        required
                    >
                        <Radio.Group onChange={(e) => {setInfo(prevState => ({...prevState,eu_role:e.target.value}))}}>
                            <Radio value='1'>普通用户</Radio>
                            <Radio value='2'>系统管理员</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {info.eu_role==='1' && <GetPost 
                        label='岗位' 
                        getPost={(e) => {setInfo(prevState=>({...prevState,eu_station_id:e,}))}} 
                        required={true} 
                        wrapperCol={{span:10}}
                    />}                                
                    <GetAllDeptCheckbox 
                        label='操作部门权限'
                        name='operatorDept'
                        required={true} 
                        getAllDept={(e) => {setInfo(prevState => ({...prevState,eu_dept_id:e}))}}
                    />                                              
                </Form>
            </Modal>
        </>
    )
}

export default SetUserAdd