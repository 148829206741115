import React,{ useState}from 'react'
import { DatePicker, Form,Input,message, Modal, Space, } from 'antd';
import { useDijieBaoJiaDanAddMutation,} from '../../../../store/ReactWebSiteApi';
import ModalAdd from '../../../../cpn/ModalAdd';
import dayjs from 'dayjs';
import LineName from '../temp/lineName/LineName';
import Client from '../temp/client/Client';
import UploadOne from '../../../../cpn/UploadOne';
const { TextArea } = Input;
function FileAdd(props) {
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        ltbjd_date:'',
        ltbjd_client_name:'',
        ltbjd_content:'',
        ltbjd_kehu_type_id:'',
        ltbjd_tuanhao:'',
        ltbjd_lth_id:'',
        ltbjd_yemei:'1',
        ltbjd_yejiao:'1',
        ltbjd_type_id:'',
    });
    const [submitAdd] = useDijieBaoJiaDanAddMutation()
    const [open,setOpen] = useState(false)    
    //线路名称
    const [openName,setOpenName] = useState(false)
    //组团社
    const [openClient,setOpenClient] = useState(false)
    return (
        <ModalAdd 
            title='报价单'
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {                       
                        form.resetFields();
                       
                        //获取产品人群类型
                        let arr = []
                        for (let key in values) {
                            if (values.hasOwnProperty(key)) { // 确保key是对象自身的属性
                                if(key.includes('egg')){
                                    arr.push(values[key])
                                }
                            }
                        }                        
                        submitAdd({
                            ltbjd_client_name:info.ltbjd_client_name,
                            ltbjd_date:info.ltbjd_date,
                            ltbjd_dept_id:localStorage.getItem('ed_id'),
                            ltbjd_dept_name:localStorage.getItem('ed_name'),
                            ltbjd_e_id:localStorage.getItem('e_id'),
                            ltbjd_e_name:localStorage.getItem('e_name'),
                            ltbjd_content:info.ltbjd_content,                            
                            ltbjd_ltxl_name: info.ltbjd_ltxl_name,
                            ltbjd_uid: localStorage.getItem('eu_id'),
                            ltbjd_uname: localStorage.getItem('eu_name'),                
                            ltbjd_tuanhao:info.ltbjd_tuanhao,
                            ltbjd_lth_id:info.ltbjd_lth_id,
                            ltbjd_ltkh_id:info.ltbjd_ltkh_id,
                            ltbjd_phone:info.ltbjd_phone,
                        }).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('新增成功！') 
                                setOpen(false)                                                 
                            }
                            if (res.data.Status === 'fail') {
                                message.error('新增失败！'+res.data.Message)
                            }
                        })
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
                
            }}
            open={open}
            getOpen={(e) => {
                setOpen(prevsState=>e)
                if(e){
                    setInfo(prevState=>({...prevState,ltbjd_dept_id:'',ltbjd_image:'',ltbjd_phone:''}))
                    form.resetFields(); 
                }
            }}
            width={800}
        >
            <Form
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 4 }}
            >
                <Form.Item
                    label='组团社'                    
                    required                    
                >
                    <Space align='center'>
                        <div>{info.ltbjd_client_name + ' - ' + info.ltbjd_phone}</div>                     
                        <a onClick={() => {setOpenClient(true)}}>请选择</a>
                        <Modal
                            open={openClient}
                            okText="提交"
                            cancelText="取消"
                            onCancel={() => {setOpenClient(false);}}
                            onOk={() => {}}
                            footer={null}
                            width={800}
                            title='选择组团社'
                        >
                            <Client
                                type='select'
                                getClient={(e) => {
                                    setInfo(prevState=>({
                                        ...prevState,
                                        ltbjd_ltkh_id:e.ltkh_id,
                                        ltbjd_client_name:e.ltkh_name,
                                        ltbjd_phone:e.ltkh_phone,
                                    }))                                    
                                }}
                                getOpen={(e) => {setOpenClient(false)}}
                            />
                        </Modal> 
                    </Space>
                </Form.Item>                     
                <Form.Item  label='线路名称' required >
                    <Space>
                        <div>{info.ltbjd_ltxl_name}</div>
                        <a onClick={() => {setOpenName(true)}}>请选择</a>
                    </Space>               
                    <Modal
                        open={openName}
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenName(false);}}
                        onOk={() => {}}
                        footer={null}
                        width={1000}
                        title='线路名称模板'
                    >
                        <LineName
                            get={true}
                            ltbjd_dept_id={props.ltbjd_dept_id}
                            getName={(e) => {setInfo(prevState=>({...prevState,ltbjd_ltxl_name:e}))}}
                            getOpen={(e) => {setOpenName(e)}}
                        />
                    </Modal>
                </Form.Item>                         
                <Form.Item name="date" label='出发日期' wrapperCol={{span:19}} required>
                    <DatePicker  
                        value={info.ltbjd_date}
                        onChange={(e) => {setInfo(prevState=>({...prevState,ltbjd_date:dayjs(e).format('YYYY-MM-DD')}))}}
                    />
                </Form.Item>                
                <Form.Item  label='备注' wrapperCol={{span:19}}>
                    <TextArea 
                        onChange={(e) => {setInfo(prevState=>({...prevState,ltbjd_content:e.target.value}))}}
                        autoSize={{
                            minRows: 2,                                
                            }}
                    />
                </Form.Item>                
                <Form.Item  label='附件' wrapperCol={{span:19}}>
                    <UploadOne/>
                </Form.Item>                
            </Form>
        </ModalAdd>
    )
}

export default FileAdd