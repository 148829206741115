import React,{useState,useEffect,useRef } from 'react'
import Main from '../../cpn/Main'
import { useGetDistrictQuery} from '../../store/ReactWebSiteApi'
import { Table,  Button, Form, Input, Space,Select} from 'antd';
import { Link,useNavigate } from 'react-router-dom';
import { useGetProvinceQuery } from '../../store/ReactWebSiteApi';
import { useGetCityQuery } from '../../store/ReactWebSiteApi';

function District(props) {
  //获取省份数据
  const [province, setProvince] = useState();
  const [selectProvince, setSelectProvince] = useState();
  //获取城市数据
  const [city, setCity] = useState();
  const [selectCity, setSelectCity] = useState();
  //查询数据
  const [queryData, setQueryData] = useState({
    province_id: '',
    city_id:'',
    search_key: '',
    action:'search',
  });
  //API
  const { data: dataProvince, isSuccess: isSuccessProvince } = useGetProvinceQuery();
  const { data: dataCity, isSuccess: isSuccessCity,refetch } = useGetCityQuery({ province_id: selectProvince,action:'search' }, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isSuccessProvince) {
      console.log(dataProvince);
      const newArr = dataProvince.Result.map((item) => {
        const { ctap_id:value, ctap_name:label } = item
        return item = { value, label }
      })
      setProvince(newArr);
    }
    
  }, [dataProvince, isSuccessProvince,])
  
  useEffect(() => {
    if (isSuccessCity) {
      const newArr = dataCity.Result.map((item) => {
        const { ctaci_id:value, ctaci_name:label } = item
        return item = { value, label }
      })
      setCity(newArr);
    }
  },[ dataCity, isSuccessCity,selectProvince])
  //设置链接
  const navi = useNavigate();  
  
  //绑定查询省
  const handleChange = (e) => {
    setSelectProvince(e);
    refetch();
    setSelectCity(undefined);
    setQueryData(prevState => ({ ...prevState, province_id: e}));
  }
  //绑定查询城市
  const handleChangeCity = (e) => {    
    setSelectCity(e)
    setQueryData(prevState => ({ ...prevState, city_id: e}));
  }
  //绑定查询区县名称
  const DistrictNameInput = (e) => {
    setQueryData(prevState => ({ ...prevState, search_key: e.target.value }));
  }
  //引入表单
  const queryForm = useRef();
  //点击重置按钮
  const reset = () => {
    queryForm.current.resetFields();
    setQueryData(prevState => ({ ...prevState, search_key: '',province_id:'',city_id:'', }))
    setSelectProvince(undefined);
    setSelectCity(undefined)
  }
  //列表数据
  const [DistrictList, setDistrictList] = useState();
  //API
  const { data, isSuccess } = useGetDistrictQuery(queryData,{refetchOnMountOrArgChange:1});
  useEffect(() => {
    if (isSuccess) {
      if (data.Status === 'success') {
        const newArr = data.Result.map((item) => {
          const { ctad_id: key, ctap_name, ctaci_name,ctad_remark,ctad_name } = item;
          return item = {key, ctap_name, ctaci_name,ctad_remark,ctad_name }
        })
        setDistrictList(newArr);
      }
    }
  },[data,isSuccess,queryData])
  //表头
  const columns = [
    {
      title: 'ID',
      dataIndex: 'key',
      width: '3%',
      defaultSortOrder: 'descend',
      sorter:  (a, b) => {
          return a.key - b.key;
        } 
    },        
    {
      title: '省份',
      dataIndex: 'ctap_name',
      width:'15%'
    },
    {
      title: '城市',
      dataIndex: 'ctaci_name',
      width:'15%'
    },
    {
      title: '区县',
      dataIndex: 'ctad_name',
      width:'15%'
    },
    {
      title: '备注',
      dataIndex: 'ctad_remark',
      width:'15%'
    },        
    {
      title: '操作',
      width: '15%',
      render: (record) => (
        <Space size="middle">
          <a onClick={() => {
            navi(`/DistrictEdit/${record.key}`)   
          }}>修改</a>
        </Space>
      )
    }
  ];
    
  //列表分页
  const onChange = (pagination, filters, sorter, extra) => {
      console.log('params', pagination, filters, sorter, extra);
  };
  
  return (
    <Main title1={props.title1} title2={props.title2} title3={props.title3}>
      <div style={{ marginBottom: '20px', display: 'flex' }}>
        <Link to='/DistrictAdd'><Button type="primary">新增区县</Button></Link>
        <Form
          name='query'
          style={{ marginLeft: '30px' }}
          layout={'inline'}
          ref={queryForm}
        >
          <Form.Item>
            <Select
              style={{ width: 120,}}
              onChange={handleChange}
              options={province}
              value={selectProvince}
              placeholder='请选择省'
            />
            <Select
              style={{ width: 120,}}
              onChange={handleChangeCity}
              options={city}
              value={selectCity}
              placeholder='请选择城市'
            />
          </Form.Item>
          <Form.Item name='ctaci_name' label="城市" >
              <Input placeholder="请输入城市名称" value={queryData.search_key} onChange={DistrictNameInput} />
          </Form.Item>
          <Button onClick={reset}>重置</Button>
        </Form>
      </div>
      <Table
          columns={columns}
          dataSource={DistrictList}
          onChange={onChange}
          pagination={{ showTotal: total => `共${DistrictList.length}条数据`, showSizeChange: true, showQuickJumper: true }} />
    </Main>
  )
}
export default District