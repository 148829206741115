import React,{useState,useEffect,useRef}from 'react'
import { Table,Space,Modal,message,Form,Input} from 'antd';
import Main from '../../cpn/Main'
import { useBusinessNumDelMutation, useBusinessNumListQuery, useGoodsAddMutation, useGoodsUpdateQuery, useGoodsUpdateSaveMutation } from '../../store/ReactWebSiteApi';

function NetTechSerial() {
    const [form] = Form.useForm();    
    //新增流水号-----------------------------------------------------------------------------------------------------------------------------新增流水号
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [infoAdd,setInfoAdd] = useState({
        ebn_content:'',
    });
    const [submitAdd] = useGoodsAddMutation()
    const modalAdd= <Modal
                        open={openAdd}
                        title='新增流水号'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        width={1000}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClient(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitAdd({
                                ebn_esv_id:'6',
                                ebn_name:infoAdd.ebn_name,
                                ebn_uid:localStorage.getItem('eu_id'),
                                ebn_uname:localStorage.getItem('eu_name'),
                                ebn_e_id:localStorage.getItem('e_id'),
                                ebn_dept_id:localStorage.getItem('ed_id'),
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpenAdd(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 3 }}
                        >
                           
                            <Form.Item name="name" label='名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ebn_name:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal> 
    //修改流水号-----------------------------------------------------------------------------------------------------------------------------修改流水号
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    
    const [infoEdit,setInfoEdit] = useState({name:''});
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useGoodsUpdateQuery({ebn_id:editId},{skip:!editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);                
                editForm.current.setFieldsValue({
                    ebn_name:dataEdit.Result.ebn_name,
                })
                setInfoEdit(prevState=>({...prevState,
                    ebn_name:dataEdit.Result.ebn_name,           
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useGoodsUpdateSaveMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改流水号'
                        okText="提交"
                        cancelText="取消"
                        width={1000}
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitUpdateSave({
                                ebn_esv_id:'6',
                                ebn_name:infoEdit.ebn_name,
                                ebn_uid:localStorage.getItem('eu_id'),
                                ebn_uname:localStorage.getItem('eu_name'),
                                ebn_e_id:localStorage.getItem('e_id'),
                                ebn_dept_id:localStorage.getItem('ed_id'),
                                ebn_id:editId,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >
                            
                            <Form.Item name="ebn_name" label='流水号名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ebn_name:e.target.value}))}}/>
                            </Form.Item>                            
                             
                        </Form>
                    </Modal>
    //删除流水号-----------------------------------------------------------------------------------------------------------------------------删除流水号
    const {confirm} = Modal; 
    const [submitDel] = useBusinessNumDelMutation()
    //流水号列表-----------------------------------------------------------------------------------------------------------------------------流水号列表
    
    const {data,isSuccess} = useBusinessNumListQuery({ebn_e_id:localStorage.getItem('e_id'),ebn_esv_id:'6'})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {ebn_id:key,ebn_name,ebn_content,ebn_uname,ebn_ctime,ebn_update_ctime,ebn_update_uname,ec_name,ebn_image_file,ebn_date} = item;
                    return item = {key,ebn_name,ebn_content,ebn_uname,ebn_ctime,ebn_update_ctime,ebn_update_uname,ec_name,ebn_image_file,ebn_date}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ebn_ctime;
                    let bTimeString = b.ebn_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },         
        {
            key: 'client',
            title: '客户',
            dataIndex: 'ebn_date',
        },            
        {
            key: 'contract',
            title: '合同',
            dataIndex: 'ebn_date',
        },            
        {
            key: 'goods',
            title: '产品',
            dataIndex: 'ebn_date',
        },            
        {
            key: 'type',
            title: '日期',
            dataIndex: 'ebn_date',
        },            
        {
            key: 'ebn_content',
            title: '备注',
            dataIndex: 'ebn_content',
        },            
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.ebn_ctime + record.ebn_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>    
                        <a onClick={() => {
                            setEditId(record.key);
                            setInfoEdit(prevState=>({...prevState,name:record.ec_name}))
                            setOpenEdit(true)
                        }}>修改</a>
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({ebn_id:record.key}).then((res) => {
                                        console.log(res);
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                    console.log('Cancel');
                                },
                            });
                        }}>删除</a>
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main  topActive='NetTechSerial' app='netTech'>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalAdd} 
            {modalEdit} 
        </Main>
    )
}

export default NetTechSerial