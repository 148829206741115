import React,{useRef, useState}from 'react'
import { Form,Input,message, Radio} from 'antd';
import { useDijieTemplateXingChengAddMutation,} from '../../../../../store/ReactWebSiteApi';
import ModalAdd from '../../../../../cpn/ModalAdd';
import GetUserDept from '../../../../../cpn/GetUserDept';
import UploadOne from '../../../../../cpn/UploadOne';
import { localCostType } from '../../customType';
const { TextArea } = Input;
function RouteAdd(props) {
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        ltxc_image:'',
        ltxc_content:'',
    });
    const [submitAdd] = useDijieTemplateXingChengAddMutation()
    const [open,setOpen] = useState(false)
    const editForm = useRef()
    return (
        <ModalAdd 
            title='行程安排模板'
            onOk={(e) => {
                submitAdd({
                    ltxc_e_id:localStorage.getItem('e_id'),
                    ltxc_uid:localStorage.getItem('eu_id'),
                    ltxc_uname:localStorage.getItem('eu_name'),
                    ltxc_dept_id:props.ltbjd_dept_id?props.ltbjd_dept_id:info.ltxc_dept_id,
                    ltxc_type_id:info.ltxc_type_id,
                    ltxc_name:info.ltxc_name,
                    ltxc_image:info.ltxc_image,
                    ltxc_content:info.ltxc_content,
                    ltxc_daoyoujihua:info.ltxc_daoyoujihua,
                }).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('新增成功！') 
                        setOpen(false)                                                 
                    }
                    if (res.data.Status === 'fail') {
                        message.error('新增失败！'+res.data.Message)
                    }
                })
            }}
            open={open}
            getOpen={(e) => {
                setOpen(prevsState=>e)
                if(e){
                    setInfo(prevState=>({...prevState,ltxc_dept_id:'',ltxc_name:'',}))
                    form.resetFields(); 
                }
            }}
            width={800}
        >
            <Form
                ref={editForm}
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 3 }}
            >                                           
                {!props.ltbjd_dept_id && <GetUserDept 
                    label='部门' 
                    name='dept'
                    required 
                    wrapperCol={{span:10}}
                    getUserDept={(e) => {setInfo(prevsState=>({...prevsState,ltxc_dept_id:e}))}}
                />}
                <Form.Item 
                    name="type" 
                    label='类型' 
                    wrapperCol={{span:20}} 
                    required 
                    onChange={(e) => {setInfo(prevsState=>({...prevsState,ltxc_type_id:e.target.value}))}}
                >
                    <Radio.Group optionType="button" options={localCostType}></Radio.Group>
                </Form.Item>
                <Form.Item name="name" label='名称' wrapperCol={{span:10}} required>
                    <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,ltxc_name:e.target.value}))}}/>
                </Form.Item>
                <UploadOne 
                    name='pic'
                    label='线路图片' 
                    fileStr={(e) => {setInfo(prevsState=>({...prevsState,ltxc_image:e}))}}
                />
                <Form.Item name="remark" label='线路描述' wrapperCol={{span:20}}>
                    <TextArea  
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltxc_content:e.target.value}))}}
                        autoSize={{
                            minRows: 3,
                            }}
                    />
                </Form.Item>
                <Form.Item name="guide" label='导游计划' wrapperCol={{span:20}}>
                    <TextArea  
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltxc_daoyoujihua:e.target.value}))}}
                        autoSize={{
                            minRows: 3,
                            }}
                    />
                </Form.Item>
            </Form>
        </ModalAdd>
    )
}

export default RouteAdd