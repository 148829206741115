import React from 'react';
 
const PageTitle = ({ title }) => {
  React.useEffect(() => {
    document.title = title;
  }, [title]);
 
  return null; // 因为这个组件不渲染任何东西
};
 
export default PageTitle;