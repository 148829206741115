import React,{useState,useEffect,useRef}from 'react'
import { Table,Button,Space,Modal,message,Form,DatePicker,InputNumber} from 'antd';
import {  useNavigate, } from 'react-router-dom';
import Main from '../cpn/Main'
import GetStaff from '../cpn/GetUser';
import dayjs from 'dayjs';
import { usePerformancePlanAddMutation, usePerformancePlanDelMutation, usePerformancePlanListQuery, usePerformancePlanUpdateQuery, usePerformancePlanUpdateSaveMutation } from '../store/ReactWebSiteApi';
import MyEditor from '../cpn/MyEditor';
function WorkPlan() {
    //新增工作计划-----------------------------------------------------------------------------------------------------------------------------新增工作计划
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [infoAdd,setInfoAdd] = useState({
        epp_content:'',
    });
    const getStaff = (e) => {
        setInfoAdd(prevState=>({...prevState,epp_user_id:e}))
    }
    const [submitAdd] = usePerformancePlanAddMutation()
    const modalAdd= <Modal
                        open={openAdd}
                        title='新增工作计划'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        width={1000}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClient(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitAdd({
                                epp_esv_id:'7',
                                epp_e_id:localStorage.getItem('e_id'),
                                epp_dept_id:localStorage.getItem('ed_id'),
                                epp_user_id:infoAdd.epp_user_id,
                                epp_moneth:dayjs(infoAdd.epp_moneth).format('YYYY-MM'),
                                epp_manhour:infoAdd.epp_manhour,
                                epp_content:infoAdd.epp_content,
                                epp_uid:localStorage.getItem('eu_id'),
                                epp_uname:localStorage.getItem('eu_name'),
                                
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpenAdd(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 3 }}
                        >                           
                            <GetStaff label='员工' getStaff={getStaff}/>
                            <Form.Item name="name" label='月份' wrapperCol={{span:10}} required>
                                <DatePicker picker="month" onChange={(e) => {setInfoAdd(prevState=>({...prevState,epp_moneth:dayjs(e)}))}}/>
                            </Form.Item>                            
                            <Form.Item name="content" label='备注' wrapperCol={{span:15}}>
                               <MyEditor onChange={(e) => {setInfoAdd(prevState=>({...prevState,epp_content:e}))}} value={infoAdd.epp_content}/>
                            </Form.Item>
                            <Form.Item name="time" label='计划工时' wrapperCol={{span:3}} required>
                                <InputNumber  onChange={(e) => {setInfoAdd(prevState=>({...prevState,epp_manhour:e}))}}/>
                            </Form.Item> 
                        </Form>
                    </Modal> 
    //修改工作计划-----------------------------------------------------------------------------------------------------------------------------修改工作计划
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    const [infoEdit,setInfoEdit] = useState({name:''});
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = usePerformancePlanUpdateQuery({epp_id:editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    epp_moneth:dayjs(dataEdit.Result.epp_moneth),
                    epp_manhour:dataEdit.Result.epp_manhour,
                    epp_content:dataEdit.Result.epp_content,
                })
                setInfoEdit(prevState=>({...prevState,
                    epp_moneth:dataEdit.Result.epp_moneth,
                    epp_manhour:dataEdit.Result.epp_manhour,
                    epp_content:dataEdit.Result.epp_content,              
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = usePerformancePlanUpdateSaveMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改工作计划'
                        okText="提交"
                        cancelText="取消"
                        width={1000}
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitUpdateSave({
                                epp_esv_id:'7',
                                epp_e_id:localStorage.getItem('e_id'),
                                epp_dept_id:localStorage.getItem('ed_id'),
                                epp_user_id:infoEdit.epp_user_id,
                                epp_moneth:dayjs(infoEdit.epp_moneth).format('YYYY-MM'),
                                epp_manhour:infoEdit.epp_manhour,
                                epp_content:infoEdit.epp_content,
                                epp_update_uid:localStorage.getItem('eu_id'),
                                epp_update_uname:localStorage.getItem('eu_name'),
                                epp_id:editId,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 5 }}
                            ref={editForm}
                        >
                            <Form.Item label='员工' wrapperCol={{span:10}} required>
                                {infoEdit.name}
                            </Form.Item>
                            <Form.Item name="epp_moneth" label='月份' wrapperCol={{span:10}} required>
                                <DatePicker picker="month" disabled/>
                            </Form.Item>                            
                            <Form.Item name="epp_content" label='备注' wrapperCol={{span:15}}>
                                <MyEditor onChange={(e) => {setInfoEdit(prevState=>({...prevState,epp_content:e}))}} value={infoAdd.epp_content}/>
                            </Form.Item>
                            <Form.Item name="epp_manhour" label='计划工时' wrapperCol={{span:3}} required>
                                <InputNumber  onChange={(e) => {setInfoEdit(prevState=>({...prevState,epp_manhour:e}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal>
    //删除工作计划-----------------------------------------------------------------------------------------------------------------------------删除工作计划
    const {confirm} = Modal; 
    const [submitDel] = usePerformancePlanDelMutation()
    //预览---------------------------------------------------------------------------------------------------------------------------预览//
    const [modalInfo,setModalInfo] = useState({
        sst_pic:[],
        picArr:'',
        grade:'',
        docArr:[],
    })
    const [modalOpen, setModalOpen] = useState(false);
    const showModal = () => {setModalOpen(true);};
    const Ok = () => {setModalOpen(false);};
    const Cancel = () => {setModalOpen(false);};
    const modalPreview =  <Modal title='预览' open={modalOpen} onOk={Ok} onCancel={Cancel} okText='关闭'>
                            <div>
                                <div style={{padding:'10px'}}>
                                    <div style={{fontSize:"24px",fontWeight:'600'}}>{modalInfo.epp_name}</div>
                                    <div style={{marginTop:'10px'}}>
                                        <div dangerouslySetInnerHTML={{__html:modalInfo.epp_content}} style={{maxWidth:"470px",overflow:"hidden"}}></div>
                                    </div>
                                </div>
                            </div>                
                        </Modal>
    //工作计划列表-----------------------------------------------------------------------------------------------------------------------------工作计划列表
    
    const {data,isSuccess} = usePerformancePlanListQuery({epp_esv_id:'7',epp_e_id:localStorage.getItem('e_id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {epp_id:key,eu_name,epp_moneth,epp_content,epp_uname,epp_ctime,epp_update_ctime,epp_update_uname,epp_manhour,eu_id} = item;
                    return item = {key,eu_name,epp_moneth,epp_content,epp_uname,epp_ctime,epp_update_ctime,epp_update_uname,epp_manhour,eu_id}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.epp_ctime;
                    let bTimeString = b.epp_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])  
    const navi = useNavigate();  
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'type',
            title: '员工',
            dataIndex: 'eu_name',
        },     
        {
            key: 'month',
            title: '月度',
            dataIndex: 'epp_moneth',            
        },     
        
        {
            key: 'timeplan',
            title: '计划工时',
            dataIndex: 'epp_manhour',
        },              
        {
            key: 'status',
            title: '任务数据',
            render:() => {
                return<>
                    <div>共20条</div>
                    <div>待确认0条</div>
                    <div>待评价0条</div>
                    <div>任务工时200分钟</div>
                </>
            }
        },     
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.epp_ctime + record.epp_uname}</div>
                    <div>修改时间：{record.epp_update_ctime + record.epp_update_uname}</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>                        
                        <a onClick={() => {
                            showModal(true);                            
                            setModalInfo(prevState=>({
                                ...prevState,
                                epp_name:record.epp_name,
                                epp_content:record.epp_content,
                            }))
                        }}>备注说明</a>
                        <a onClick={() => {
                            setEditId(record.key);
                            setOpenEdit(true)
                            setInfoEdit(prevState=>({...prevState,name:record.eu_name,epp_user_id:record.eu_id}))
                        }}>修改</a>
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({epp_id:record.key}).then((res) => {
                                        console.log(res);
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>
                        <a onClick={() =>{navi(`/WorkTask?id=${record.key}&name=${record.eu_name}&month=${dayjs(record.epp_moneth).format('YYYY-MM')}`)}}>工作任务</a>
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main topActive='WorkPlan' app='performance'>
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增工作计划</Button>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalAdd}
            {modalEdit} 
            {modalPreview}           
        </Main>
    )
}

export default WorkPlan