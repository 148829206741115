import React, { useEffect, useState } from 'react'
import classes from './home.module.css'
import { Modal, List, Row, Col, } from 'antd'
import { useSearchParams } from 'react-router-dom'
import {useNewsListQuery } from '../store/ReactWebSiteApi'
function News(props) {
    const [param] = useSearchParams();         
     const {data, isSuccess} = useNewsListQuery({en_e_id:'218',en_type_id:props.en_type_id});     
     const [list, setList] = useState();     
     useEffect(() => {
         if (isSuccess) {
            // console.log(data);
            if(data.Status==='success'){                
                const newArr = data.Result.map(item => {
                    const {en_id:key,en_name,en_num,en_content,en_ctime,en_state,en_type_name} = item;
                    return item = {key,en_name,en_num,en_content,en_ctime,en_state,en_type_name}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.en_ctime;
                    let bTimeString = b.en_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }             
        }
    }, [data, isSuccess])
    //预览
    const [modalInfo,setModalInfo] = useState({
        sst_pic:[],
        picArr:'',
        grade:'',
        docArr:[],
    })
    const [modalOpen, setModalOpen] = useState(false);
    const showModal = () => {setModalOpen(true);};
    const Ok = () => {setModalOpen(false);};
    const Cancel = () => {setModalOpen(false);};
    return (
        <Row style={{marginTop:"30px"}}>            
            <Col span={5} ></Col>
            <Col span={14} >
            <div className={classes.newsMain}>                
                <div className={classes.newsType}>{param.get('name')}</div> 
                <List
                    itemLayout="horizontal"
                    dataSource={list}
                    renderItem={(item, index) => {
                        if(item.en_state==='2'){
                            return (
                                <List.Item key={item.key}>
                                    <List.Item.Meta
                                        title={<a onClick={() => {
                                            showModal(true);                            
                                            setModalInfo(prevState=>({
                                                ...prevState,
                                                en_name:item.en_name,
                                                en_content:item.en_content,
                                            }))
                                            
                                        }}
                                        style={{fontSize:'24px'}}
                                        > {item.en_name}</a>}
                                    />
                                </List.Item>
                            )
                        }else{
                            return <div key={item.key}></div>
                            
                        }
                        
                    }}
                >
                </List>
                <Modal open={modalOpen} onOk={Ok} onCancel={Cancel} okText='关闭'>
                    <div>
                        <div style={{padding:'10px'}}>
                            <div style={{fontSize:"24px",fontWeight:'600'}}>{modalInfo.en_name}</div>
                            <div style={{marginTop:'10px'}}>
                                <div dangerouslySetInnerHTML={{__html:modalInfo.en_content}} style={{maxWidth:"470px",overflow:"hidden"}}></div>
                            </div>
                        </div>
                    </div>                
                </Modal>
            </div>
            </Col>
            <Col span={5}></Col>
        </Row>
    )
}

export default News