import React,{useState,useEffect, useRef}from 'react'
import { Table,Button,Space,Modal,message,Form,DatePicker,InputNumber, Divider,Input} from 'antd';
import { useAttendanceRecordAddMutation, useAttendanceRecordConfirmCancelMutation, useAttendanceRecordConfirmMutation, useAttendanceRecordDelMutation, useAttendanceRecordListQuery, useAttendanceRecordStateMutation, useAttendanceRecordUpdateQuery, useAttendanceRecordUpdateSaveMutation } from '../store/ReactWebSiteFinanceApi';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import GetStaff from '../cpn/GetUser';
import useHandle from '../hooks/useHandle';
import Main from '../cpn/Main'

const { TextArea } = Input;

function Clocking() {
    //新增考勤-------------------------------------------------------------------------------------新增考勤//
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const [infoAdd,setInfoAdd] = useState({
        ear_sj_num:0,
        ear_bj_num:0,
        ear_cj_num:0,
        ear_hj_num:0,
        ear_ssj_num:0,
        ear_kg_num:0,
        ear_wc_num:0,
        ear_jb_num:0,
        ear_cd_num:0,
        ear_zt_num:0,
        ear_wjdk_num:0,
        ear_actual_num:0,
    })
    //获取员工
    const getStaff = (e,f,g) => {
        // console.log(e,f,g);
        setInfoAdd(prevState=>({...prevState,ear_user_id:e,ear_user_name:f,}))
    } 
    const onCreateAdd = (values) => {       
        attendanceRecordAdd({
            ear_esv_id:'3',
            ear_e_id:localStorage.getItem('e_id'),
            ear_uid:localStorage.getItem('eu_id'),
            ear_uname:localStorage.getItem('eu_name'),
            ear_month:infoAdd.ear_month,
            ear_ykq_num:infoAdd.ear_ykq_num,
            ear_user_id:infoAdd.ear_user_id,
            ear_user_name:infoAdd.ear_user_name,
            ear_wc_num:infoAdd.ear_wc_num,
            ear_jb_num:infoAdd.ear_jb_num,
            ear_sj_num:infoAdd.ear_sj_num,
            ear_bj_num:infoAdd.ear_bj_num,
            ear_cj_num:infoAdd.ear_cj_num,
            ear_hj_num:infoAdd.ear_hj_num,
            ear_ssj_num:infoAdd.ear_ssj_num,
            ear_cd_num:infoAdd.ear_cd_num,
            ear_zt_num:infoAdd.ear_zt_num,
            ear_wjdk_num:infoAdd.ear_wjdk_num,
            ear_kg_num:infoAdd.ear_kg_num,
            ear_actual_num:infoAdd.ear_ykq_num-infoAdd.ear_sj_num-infoAdd.ear_bj_num-infoAdd.ear_hj_num-infoAdd.ear_ssj_num-infoAdd.ear_cj_num-infoAdd.ear_kg_num
        }).then((res) => {
            console.log(res);
            if (res.data.Status === 'success') {
                message.success('新增成功！')   
                setOpenAdd(false)                             
            }
            if (res.data.Status === 'fail') {
                message.error('新增失败！'+res.data.Message)
            }
        })
    };
    
    const [attendanceRecordAdd] = useAttendanceRecordAddMutation()
    //修改考勤-------------------------------------------------------------------------------------修改考勤//
    const editForm = useRef()
    const [editId,setEditId] = useState();
    const {data:dataEdit,isSuccess:isSuccessEdit} = useAttendanceRecordUpdateQuery({ear_id:editId})
    
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    ear_month:dayjs(dataEdit.Result.ear_month),
                    ear_actual_num:dataEdit.Result.ear_actual_num,
                    ear_ykq_num:dataEdit.Result.ear_ykq_num,
                    ear_wc_num:dataEdit.Result.ear_wc_num,
                    ear_jb_num:dataEdit.Result.ear_jb_num,
                    ear_sj_num:dataEdit.Result.ear_sj_num,
                    ear_bj_num:dataEdit.Result.ear_bj_num,
                    ear_cj_num:dataEdit.Result.ear_cj_num,
                    ear_hj_num:dataEdit.Result.ear_hj_num,
                    ear_ssj_num:dataEdit.Result.ear_ssj_num,
                    ear_cd_num:dataEdit.Result.ear_cd_num,
                    ear_zt_num:dataEdit.Result.ear_zt_num,
                    ear_wjdk_num:dataEdit.Result.ear_wjdk_num,
                    ear_kg_num:dataEdit.Result.ear_kg_num,
                    ear_content:dataEdit.Result.ear_content,
                })
                setInfoEdit(prevState=>({...prevState,
                    ear_month:dataEdit.Result.ear_month,
                    ear_actual_num:dataEdit.Result.ear_actual_num,            
                    ear_user_name:dataEdit.Result.ear_user_name,   
                    ear_ykq_num:dataEdit.Result.ear_ykq_num,   
                    ear_wc_num:dataEdit.Result.ear_wc_num,      
                    ear_jb_num:dataEdit.Result.ear_jb_num,
                    ear_sj_num:dataEdit.Result.ear_sj_num,
                    ear_bj_num:dataEdit.Result.ear_bj_num,
                    ear_cj_num:dataEdit.Result.ear_cj_num,
                    ear_hj_num:dataEdit.Result.ear_hj_num,
                    ear_ssj_num:dataEdit.Result.ear_ssj_num,
                    ear_cd_num:dataEdit.Result.ear_cd_num,
                    ear_zt_num:dataEdit.Result.ear_zt_num,
                    ear_wjdk_num:dataEdit.Result.ear_wjdk_num,
                    ear_kg_num:dataEdit.Result.ear_kg_num,
                    ear_content:dataEdit.Result.ear_content,
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [attendanceRecordUpdateSave] = useAttendanceRecordUpdateSaveMutation()
    const [openEdit, setOpenEdit] = useState(false);
    const [infoEdit, setInfoEdit] = useState({
        ear_sj_num:0,
        ear_bj_num:0,
        ear_cj_num:0,
        ear_hj_num:0,
        ear_ssj_num:0,
        ear_kg_num:0,
        ear_wc_num:0,
        ear_jb_num:0,
        ear_cd_num:0,
        ear_zt_num:0,
        ear_wjdk_num:0,
    })

    const onCreateEdit = () => {
        attendanceRecordUpdateSave({
            ear_esv_id:'3',
            ear_id:editId,
            ear_e_id:localStorage.getItem('e_id'),
            ear_update_uid:localStorage.getItem('eu_id'),
            ear_update_uname:localStorage.getItem('eu_name'),
            ear_month:infoEdit.ear_month,
            ear_ykq_num:infoEdit.ear_ykq_num,
            ear_user_id:infoEdit.ear_user_id,
            ear_user_name:infoEdit.ear_user_name,
            ear_wc_num:infoEdit.ear_wc_num,
            ear_jb_num:infoEdit.ear_jb_num,
            ear_sj_num:infoEdit.ear_sj_num,
            ear_bj_num:infoEdit.ear_bj_num,
            ear_cj_num:infoEdit.ear_cj_num,
            ear_hj_num:infoEdit.ear_hj_num,
            ear_ssj_num:infoEdit.ear_ssj_num,
            ear_cd_num:infoEdit.ear_cd_num,
            ear_zt_num:infoEdit.ear_zt_num,
            ear_wjdk_num:infoEdit.ear_wjdk_num,
            ear_kg_num:infoEdit.ear_kg_num,
            ear_actual_num:infoEdit.ear_ykq_num-infoEdit.ear_sj_num-infoEdit.ear_bj_num-infoEdit.ear_hj_num-infoEdit.ear_ssj_num-infoEdit.ear_cj_num-infoEdit.ear_kg_num
        }).then((res) => {
            console.log(res);
            if (res.data.Status === 'success') {
                message.success('修改成功！')   
                setOpenEdit(false)                             
            }
            if (res.data.Status === 'fail') {
                message.error('修改失败！'+res.data.Message)
            }
        })
    }
    //删除考勤-------------------------------------------------------------------------------------------------------------------删除考勤//
    const {confirm} = Modal; 
    const [delBusiness] = useAttendanceRecordDelMutation()
    //考勤列表-------------------------------------------------------------------------------------------------------------------考勤列表//
    
    const {data, isSuccess} = useAttendanceRecordListQuery({ear_e_id:localStorage.getItem('e_id')});
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if (isSuccess) {
            //console.log(data);
            if(data.Status==='success'){
                
                const newArr = data.Result.map(item => {
                    const {ear_id:key,ear_state,ear_actual_num,ear_user_name,ear_month,ear_content,ear_uname,ear_ctime,ear_ykq_num,ear_wc_num,ear_jb_num,ear_sj_num,ear_bj_num,ear_cj_num,ear_hj_num,ear_ssj_num,ear_cd_num,ear_zt_num,ear_wjdk_num,ear_kg_num,ear_update_ctime,ear_update_uname,ear_qz_ctime,ear_qz_uname,ear_confirm_ctime,ear_confirm_uname} = item;
                    return item = {key,ear_state,ear_actual_num,ear_user_name,ear_month,ear_content,ear_uname,ear_ctime,ear_ykq_num,ear_wc_num,ear_jb_num,ear_sj_num,ear_bj_num,ear_cj_num,ear_hj_num,ear_ssj_num,ear_cd_num,ear_zt_num,ear_wjdk_num,ear_kg_num,ear_update_ctime,ear_update_uname,ear_qz_ctime,ear_qz_uname,ear_confirm_ctime,ear_confirm_uname}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ear_ctime;
                    let bTimeString = b.ear_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'room',
            title: '月份',            
            dataIndex: 'ear_month',
        },
        {
            key: 'type',
            title: '员工',            
            dataIndex: 'ear_user_name',
        },
       
        {
            key: 'num',
            title: '考勤天数',            
            render:(_,record) => {
                return<>
                    <div>应考勤天数：{record.ear_ykq_num}</div>
                    <div>外出天数：{record.ear_wc_num}</div>
                    <div>加班天数：{record.ear_jb_num}</div>
                    <div>请假天数：{Number(record.ear_sj_num)+Number(record.ear_bj_num)+Number(record.ear_cj_num)+Number(record.ear_hj_num)+Number(record.ear_ssj_num)}</div>
                    <div>实际考勤天数：{record.ear_actual_num}</div>
                </>
            }
        },
        {
            key: 'wage',
            title: '请假',            
            dataIndex: 'finance_num',
            render:(_,record) => {
                return <>
                    <div>事假：{record.ear_sj_num}</div>
                    <div>病假：{record.ear_bj_num}</div>
                    <div>产假：{record.ear_cj_num}</div>
                    <div>婚假：{record.ear_hj_num}</div>
                    <div>丧假：{record.ear_ssj_num}</div>
                </>
            }
        },
        {
            key: 'wei',
            title: '违规',            
            dataIndex: 'finance_num',
            render:(_,record) => {
                return <>
                    <div>迟到：{record.ear_cd_num}</div>
                    <div>早退：{record.ear_zt_num}</div>
                    <div>忘记打卡：{record.ear_wjdk_num}</div>
                    <div>旷工：{record.ear_kg_num}</div>
                </>
            }
        },
        {
            key: 'remark',
            title: '备注',            
            dataIndex: 'ear_content',
        },
        {
            key: 'state',
            title: '状态', 
            dataIndex:'ear_state',
            render:(text) => {
                if(text==='0'){
                    return<>待提交</>
                }
                if(text==='1'){
                    return<>待确认</>
                }
                if(text==='2'){
                    return<>已确认</>
                }
                if(text==='3'){
                    return<>已审核</>
                }
            }           
        },
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新增：{record.ear_ctime + record.ear_uname}</div>
                    {record.ear_update_ctime &&<div>提交：{record.ear_update_ctime + record.ear_update_uname}</div>}
                    {record.ear_qz_ctime && <div>确认：{record.ear_qz_ctime + record.ear_qz_uname}</div>}
                    {record.ear_confirm_ctime && <div>审核：{record.ear_confirm_ctime + record.ear_confirm_uname}</div>}
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>
                        {(record.ear_state==='0'|| record.ear_state==='1') && <a  onClick={() => {
                            confirm({
                                title: record.ear_state==='0'?'确定提交吗?':'确定取消吗？',
                                content: record.ear_state==='0'?'提交后可以取消':'取消后可以重新提交',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    if(record.ear_state==='0'){
                                        attendanceRecordState({ear_id:record.key,ear_state:'2',ear_update_uid:localStorage.getItem('eu_id'),ear_update_uname:localStorage.getItem('eu_name')}).then((res) => {
                                            if (res.data.Status === 'success') {
                                                message.info('提交成功！');
                                            }
                                            if (res.data.Status === "fail") {
                                                message.error('提交失败！'+res.data.Message)
                                            }
                                        })
                                    }else{
                                        attendanceRecordState({ear_id:record.key,ear_state:'1',ear_update_uid:localStorage.getItem('eu_id'),ear_update_uname:localStorage.getItem('eu_name')}).then((res) => {
                                            if (res.data.Status === 'success') {
                                                message.info('取消成功！');
                                            }
                                            if (res.data.Status === "fail") {
                                                message.error('取消失败！'+res.data.Message)
                                            }
                                        })
                                    }
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>{record.ear_state==='0'?'提交':'取消提交'}</a>}
                        {record.ear_state==='0' &&  <a onClick={() => {
                            setEditId(record.key);
                            setOpenEdit(true);
                        }}>修改</a>}
                        {record.ear_state==='0' && <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    delBusiness({ear_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>}
                        {(record.ear_state==='2' && checkLimit) && <a onClick={() => {
                            confirm({
                                title: '确定审核吗?',
                                content: '审核后可以取消',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    attendanceRecordConfirm({ear_id:record.key,ear_confirm_uid:localStorage.getItem('eu_id'),ear_confirm_uname:localStorage.getItem('eu_name')}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('审核成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('审核失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>审核</a>}
                        {(record.ear_state==='3'&& checkLimit) && <a onClick={() => {
                            confirm({
                                title: '确定取消吗?',
                                content: '取消后可以审核',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    attendanceRecordConfirmCancel({ear_id:record.key,ear_confirm_uid:localStorage.getItem('eu_id'),ear_confirm_uname:localStorage.getItem('eu_name')}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('取消成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('取消失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>取消审核</a>}
                    </Space>
                </>
            }
        },
    ]
    //提交员工确认·-------------------------------------------------------------------------------------------提交员工确认·//
    const [attendanceRecordState] = useAttendanceRecordStateMutation()
    //审核--------------------------------------------------------------------------------------------------------------------------审核//
    const checkLimit = useHandle('1')
    const [attendanceRecordConfirm] = useAttendanceRecordConfirmMutation();
    const [attendanceRecordConfirmCancel] = useAttendanceRecordConfirmCancelMutation();
    return (
        <Main  topActive='Clocking' app='wage'>
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true)}}>新增考勤</Button>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table>
            {/* 新增考勤        */}
            <Modal
                open={openAdd}
                title='新增考勤'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenAdd(false);}}
                onOk={() => {
                    setInfoAdd(prevState=>({...prevState,ear_actual_num:infoAdd.ear_ykq_num-infoAdd.ear_sj_num-infoAdd.ear_bj_num-infoAdd.ear_hj_num-infoAdd.ear_ssj_num-infoAdd.ear_cj_num-infoAdd.ear_kg_num}))
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateAdd(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                >
                    <Form.Item name="ear_month" label='月份' wrapperCol={{span:15}} required>
                        <DatePicker picker="month" onChange={(e) => {setInfoAdd(prevState=>({...prevState,ear_month:dayjs(e).format('YYYY-MM')}))}}/>
                    </Form.Item>
                    <GetStaff label='员工'  getStaff={getStaff}/>
                    <Form.Item name="ear_ykq_num" label='应考勤天数' wrapperCol={{span:15}} required>
                        <InputNumber onChange={(e) => {setInfoAdd(prevState=>({...prevState,ear_ykq_num:e}))}}/>
                    </Form.Item>
                    <Form.Item name="ear_wc_num" label='外出天数' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ear_wc_num:e}))}}/>
                    </Form.Item>
                    <Form.Item name="ear_jb_num" label='加班天数' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ear_jb_num:e}))}}/>
                    </Form.Item>
                    <Divider>请假</Divider>
                    <Form.Item name="ear_sj_num" label='事假天数' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ear_sj_num:e}))}}/>
                    </Form.Item>                                            
                    <Form.Item name="ear_bj_num" label='病假天数' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ear_bj_num:e}))}}/>
                    </Form.Item>                                            
                    <Form.Item name="ear_cj_num" label='产假天数' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ear_cj_num:e}))}}/>
                    </Form.Item>                                            
                    <Form.Item name="ear_hj_num" label='婚假天数' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ear_hj_num:e}))}}/>
                    </Form.Item>                                            
                    <Form.Item name="ear_ssj_num" label='丧家天数' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ear_ssj_num:e}))}}/>
                    </Form.Item>                                            
                    <Form.Item name="sang" label='小计' wrapperCol={{span:15}}>
                        {infoAdd.ear_sj_num+infoAdd.ear_bj_num}                        
                    </Form.Item>                                            
                    <Divider>违规</Divider>
                    <Form.Item name="ear_cd_num" label='迟到' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ear_cd_num:e}))}}/>
                    </Form.Item>                                            
                    <Form.Item name="ear_zt_num" label='早退' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ear_zt_num:e}))}}/>
                    </Form.Item>                                       
                    <Form.Item name="ear_wjdk_num" label='忘记打卡' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ear_wjdk_num:e}))}}/>
                    </Form.Item>                                            
                    <Form.Item name="ear_kg_num" label='旷工' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ear_kg_num:e}))}}/>
                    </Form.Item>
                    <Divider>备注</Divider>                     
                    <Form.Item name="ear_actual_num" label='实际考勤天数' wrapperCol={{span:15}}>
                        {infoAdd.ear_ykq_num ? (infoAdd.ear_ykq_num-infoAdd.ear_sj_num-infoAdd.ear_bj_num-infoAdd.ear_hj_num-infoAdd.ear_ssj_num-infoAdd.ear_cj_num-infoAdd.ear_kg_num):0}
                    </Form.Item>
                    <Form.Item name="ear_content" label='备注' wrapperCol={{span:15}}>
                        <TextArea rows={4}  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ear_content:e}))}}/>
                    </Form.Item>

                </Form>
            </Modal>
            {/* 修改考勤        */}
            <Modal
                open={openEdit}
                title='修改考勤'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenEdit(false);}}
                onOk={() => {
                    setInfoEdit(prevState=>({...prevState,ear_actual_num:infoEdit.ear_ykq_num-infoEdit.ear_sj_num-infoEdit.ear_bj_num-infoEdit.ear_hj_num-infoEdit.ear_ssj_num-infoEdit.ear_cj_num-infoEdit.ear_kg_num}))
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateEdit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    labelCol={{ span: 5 }}
                    ref={editForm}
                >
                    <Form.Item name="ear_month" label='月份' wrapperCol={{span:15}} required>
                        <DatePicker picker="month" onChange={(e) => {setInfoEdit(prevState=>({...prevState,ear_month:dayjs(e).format('YYYY-MM')}))}}/>
                    </Form.Item>
                    <GetStaff label='员工'  defaultId={infoEdit.ear_user_name} />
                    <Form.Item name="ear_ykq_num" label='应考勤天数' wrapperCol={{span:15}} required>
                        <InputNumber onChange={(e) => {setInfoEdit(prevState=>({...prevState,ear_ykq_num:e}))}}/>
                    </Form.Item>
                    <Form.Item name="ear_wc_num" label='外出天数' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ear_wc_num:e}))}}/>
                    </Form.Item>
                    <Form.Item name="ear_jb_num" label='加班天数' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ear_jb_num:e}))}}/>
                    </Form.Item>
                    <Divider>请假</Divider>
                    <Form.Item name="ear_sj_num" label='事假天数' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ear_sj_num:e}))}}/>
                    </Form.Item>                                            
                    <Form.Item name="ear_bj_num" label='病假天数' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ear_bj_num:e}))}}/>
                    </Form.Item>                                            
                    <Form.Item name="ear_cj_num" label='产假天数' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ear_cj_num:e}))}}/>
                    </Form.Item>                                            
                    <Form.Item name="ear_hj_num" label='婚假天数' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ear_hj_num:e}))}}/>
                    </Form.Item>                                            
                    <Form.Item name="ear_ssj_num" label='丧家天数' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ear_ssj_num:e}))}}/>
                    </Form.Item>                                            
                    <Form.Item name="sang" label='小计' wrapperCol={{span:15}}>
                        {infoEdit.ear_sj_num+infoEdit.ear_bj_num}                        
                    </Form.Item>                                            
                    <Divider>违规</Divider>
                    <Form.Item name="ear_cd_num" label='迟到' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ear_cd_num:e}))}}/>
                    </Form.Item>                                            
                    <Form.Item name="ear_zt_num" label='早退' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ear_zt_num:e}))}}/>
                    </Form.Item>                                       
                    <Form.Item name="ear_wjdk_num" label='忘记打卡' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ear_wjdk_num:e}))}}/>
                    </Form.Item>                                            
                    <Form.Item name="ear_kg_num" label='旷工' wrapperCol={{span:15}}>
                        <InputNumber  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ear_kg_num:e}))}}/>
                    </Form.Item>
                    <Divider>备注</Divider>                     
                    <Form.Item name="ear_actual_num" label='实际考勤天数' wrapperCol={{span:15}}>
                        {infoEdit.ear_ykq_num-infoEdit.ear_sj_num-infoEdit.ear_bj_num-infoEdit.ear_hj_num-infoEdit.ear_ssj_num-infoEdit.ear_cj_num-infoEdit.ear_kg_num}
                    </Form.Item>
                    <Form.Item name="ear_content" label='备注' wrapperCol={{span:15}}>
                        <TextArea rows={4}  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ear_content:e}))}}/>
                    </Form.Item>

                </Form>
            </Modal>
        </Main>
    )
}

export default Clocking