import React,{useState} from 'react'
import Main from '../../cpn/Main'
import { useAddProvinceMutation } from '../../store/ReactWebSiteApi'
import { message,Button, Form, Input, Row,  Col } from 'antd'
import {useNavigate} from 'react-router-dom'
function ProvinceAdd(props) {  
 
  //新增数据
  const [province, setProvince] = useState({
    ctap_name:'',
    ctap_remark: '',
  });
  
  //绑定国家名称
  const nameInput = (e) => {
    setProvince(prevState =>({...prevState,ctap_name:e.target.value}))
  }
  //绑定国家备注
  const remarkInput = (e) => {
    setProvince(prevState =>({...prevState,ctap_remark:e.target.value}))
  }
  //链接
  const navigate = useNavigate();
  //上传数据
  const [addProvince] = useAddProvinceMutation();
  //提交对话框表单
  const onFinish = () => {
    console.log(province);    
    addProvince(province).then(
      (res) => {
        if (res.data.Status === 'success') {
          message.success('新增省份成功！')
          navigate('/province');                                    
        }
        if (res.data.Status === 'fail') {
          message.error('新增省份失败！'+res.data.Message)
        }
      })
  }
  const onFinishFailed = () => {}
  return (
    <Main title1='系统' title2='地区' title3='省份' title4='新增省份'>
      <Row>
        <Col span={12}>
          <Form
              name="basic"
              labelCol={{
                  span: 4,
              }}
              wrapperCol={{
                  span: 20,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
          >         
            <Form.Item
                label="1、填写省份名称"
                name="name"
                rules={[{
                    required: true,
                    message: '请填写省份名称！',
                },]}
                wrapperCol={{
                    span: 4,
                }}
            >
                <Input value={province.ctac_name} onChange={nameInput} />
            </Form.Item>
            <Form.Item
                label="2、填写备注"
                name='remark'
                rules={[{
                    required: true,
                    message: '请填写备注！',
                },]}
            >
              <Input value={province.ctac_remark} onChange={remarkInput} />
            </Form.Item>                    
            <Form.Item
                wrapperCol={{
                    offset: 2,
                    span: 16,
                }}
            >
              <Button type="primary" htmlType="submit">提交</Button>
            </Form.Item>
          </Form>          
        </Col>
      </Row>
    </Main>
  )
}
export default ProvinceAdd