import React,{useState,}from 'react'
import { Button,Modal,message,Form,Input,} from 'antd';
import { usePhotoAlbumGroupAddMutation, } from '../store/ReactWebSiteApi';

function AlbumSectionAdd(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({epag_content:''});
    const [submitAdd] = usePhotoAlbumGroupAddMutation()
    return (
        <>
            <Button onClick={() => {setOpen(true)}} type='primary'>新增</Button>
            <Modal
                open={open}
                title='新增分组'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        epag_epa_id:props.epag_epa_id,
                        epag_name:info.epag_name,
                        epag_uid:localStorage.getItem('eu_id'),
                        epag_uname:localStorage.getItem('eu_name'),
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                >                            
                    <Form.Item name="name" label='分组名称' wrapperCol={{span:19}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,epag_name:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default AlbumSectionAdd