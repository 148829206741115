import { Button, Form, Input, message, Modal } from 'antd'
import React, { useState } from 'react'
import { useQuanxianAddMutation } from '../../store/ReactWebSiteApi';

function AppPowerAdd(props) {
    const [form] = Form.useForm();
    const [open,setOpen] = useState(false)
    const [info,setInfo] = useState({qx_content:''})
    const [submitAdd] = useQuanxianAddMutation()
    return (
        <>
            <Button type='primary' onClick={() => {setOpen(true)}}>新增</Button>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false)}}
                width={600}
                title='新增旅当家权限'
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        qx_type:props.qx_type,
                        qx_uid:localStorage.getItem('eu_id'),
                        qx_uname:localStorage.getItem('eu_name'),
                        qx_parent_id:props.qx_parent_id,
                        qx_name:info.qx_name,
                        qx_content:info.qx_content,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    labelCol={{span:5}}
                >
                    <Form.Item label='父级权限' wrapperCol={{span:10}}>
                        {props.qx_parent_name}
                    </Form.Item>                    
                    <Form.Item label='权限名称' wrapperCol={{span:10}}>
                        <Input onChange={(e) => {setInfo(prevState=>({...prevState,qx_name:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item label='备注'  wrapperCol={{span:15}}>
                        <Input onChange={(e) => {setInfo(prevState=>({...prevState,qx_content:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default AppPowerAdd