import React from 'react'
import { useIncomeExpenditureAuditMutation,useIncomeExpenditureCancelAuditMutation} from '../../store/ReactWebSiteApi';
import { message,Space} from 'antd';
function BalanceElseAudit(props) {
    const [submitAudit] = useIncomeExpenditureAuditMutation()
    const [submitAuditCancle] = useIncomeExpenditureCancelAuditMutation()
    return (
        <Space>
            {
                (!props.eie_audit1_ctime && !props.eie_audit2_ctime)&& <a 
                onClick={() => {
                    submitAudit({
                        eie_id:props.eie_id,
                        rankNum:1,
                        uid:localStorage.getItem('eu_id'),
                        uname:localStorage.getItem('eu_name'),                                
                    }).then((res) => {
                        // console.log(res);
                        if (res.data.Status === 'success') {
                            message.info('审核成功！');
                        }
                        if (res.data.Status === "fail") {
                            message.error('审核失败！'+res.data.Message)
                        }})
                }}
                key='audit1'
            >审核</a>}
            {(props.eie_audit1_ctime && !props.eie_audit2_ctime)&& <a 
                key='cancel1'
                onClick={() => {
                    submitAuditCancle({
                        eie_id:props.eie_id,
                        rankNum:1,                                                                
                    }).then((res) => {
                        // console.log(res);
                        if (res.data.Status === 'success') {
                            message.info('取消审核成功！');
                        }
                        if (res.data.Status === "fail") {
                            message.error('取消审核失败！'+res.data.Message)
                        }})
                }}>取消审核</a>}
            {(props.eie_audit1_ctime && !props.eie_audit2_ctime)&& <a 
                key='audit1'
                onClick={() => {
                    submitAudit({
                        eie_id:props.eie_id,
                        rankNum:2,
                        uid:localStorage.getItem('eu_id'),
                        uname:localStorage.getItem('eu_name'),                                
                    }).then((res) => {
                        // console.log(res);
                        if (res.data.Status === 'success') {
                            message.info('复核成功！');
                        }
                        if (res.data.Status === "fail") {
                            message.error('复核失败！'+res.data.Message)
                        }})
                }}>复核</a>}
            {props.eie_audit2_ctime && <a
            key='cancel2' 
            onClick={() => {
                submitAuditCancle({
                    eie_id:props.eie_id,
                    rankNum:2,                                                                
                }).then((res) => {
                    // console.log(res);
                    if (res.data.Status === 'success') {
                        message.info('取消复核成功！');
                    }
                    if (res.data.Status === "fail") {
                        message.error('取消复核失败！'+res.data.Message)
                    }})
            }}>取消复核</a>
            }
        </Space>
    )
}

export default BalanceElseAudit