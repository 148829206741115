import { Select,Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTaskManageFaBuUserListQuery } from '../store/ReactWebSiteApi';

function GetTaskSender(props) {
    const [list,setList] = useState();
    const {data,isSuccess} =useTaskManageFaBuUserListQuery({et_e_id:localStorage.getItem('e_id'),eu_id:localStorage.getItem('eu_id')})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                // console.log(data);
                const newArr = data.Result.map((item) => {
                    const {et_uid,et_uname}=item;
                    return item = {value:et_uid,label:et_uname,}
                })
                setList(newArr)
            }
        }
    },[data,isSuccess])
    return (
        <>
            <Form.Item 
                label={props.label} 
                wrapperCol={props.wrapperCol} 
                required={props.required}
                name={props.name}
                initialValue={props.initialValue}
            >
                <Select 
                    options={list} 
                    onChange={(_,f) => {
                        // console.log(f);
                        props.getUser(f.value,f.label,)
                    }}
                    placeholder={props.defaultId}
                    style={props.style}
                />
            </Form.Item>
        </>
    )
}

export default GetTaskSender