import React,{useState,useEffect,useRef}from 'react'
import { Modal,message,Form,Input} from 'antd';
import { useFinanceSubTypeUpdateQuery,  useFinanceSubTypeUpdateSaveMutation,} from '../../store/ReactWebSiteApi';
import GetUserDept from '../../cpn/GetUserDept';
function BalanceTypeEdit(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({efst_id:'',efst_content:''});
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useFinanceSubTypeUpdateQuery({efst_id:info.efst_id},{refetchOnMountOrArgChange:1})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                console.log(dataEdit);
                editForm.current.setFieldsValue({
                    efst_name:dataEdit.Result.efst_name,
                    efst_content:dataEdit.Result.efst_content,
                    efst_finance_type_id:dataEdit.Result.efst_finance_type_id,
                    efst_dept_id:dataEdit.Result.efst_dept_id,
                })
                setInfo(prevState=>({...prevState,
                    efst_name:dataEdit.Result.efst_name,
                    efst_content:dataEdit.Result.efst_content,   
                    efst_finance_type_id:dataEdit.Result.efst_finance_type_id,           
                    efst_dept_id:dataEdit.Result.efst_dept_id,           
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useFinanceSubTypeUpdateSaveMutation()
    
    return (
        <>
            <a onClick={() => {
                setOpen(true);
                setInfo(prevState=>({...prevState,efst_id:props.efst_id}))
            }}>修改</a> 
            <Modal
                open={open}
                title={`修改${props.title}`}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateEdit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });                            
                    submitUpdateSave({
                        efst_name:info.efst_name,    
                        efst_dept_id:info.efst_dept_id,    
                        efst_content:info.efst_content,
                        efst_finance_type_id:info.efst_finance_type_id,                            
                        efst_e_id:localStorage.getItem('e_id'),
                        efst_update_uid:localStorage.getItem('eu_id'),
                        efst_update_uname:localStorage.getItem('eu_name'),
                        efst_id:info.efst_id,                                        
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')   
                            setOpen(false)                             
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public'}}
                    labelCol={{ span: 7 }}
                    ref={editForm}
                >
                    {/* <Form.Item name="efst_finance_type_id" label='主类型名称' wrapperCol={{span:10}} required >
                        <Select 
                            disabled
                            options={balanceType} 
                            onChange={(e,f) => {setInfo(prevState=>({...prevState,typeName:f.label,efst_finance_type_id:e}))}}/>
                    </Form.Item> */}
                    <GetUserDept label='部门' name='efst_dept_id' getUserDept={(e) => {setInfo(prevState=>({...prevState,efst_dept_id:e}))}} required/>
                    <Form.Item name="efst_name" label='类型名称' wrapperCol={{span:10}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,efst_name:e.target.value}))}}/>
                    </Form.Item>
                    {/* <Form.Item name="efst_content" label='备注' wrapperCol={{span:10}}>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,efst_content:e.target.value}))}}/>
                    </Form.Item> */}
                </Form>
            </Modal>        
        </>
    )
}

export default BalanceTypeEdit