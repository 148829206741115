import React,{useState,useEffect,useRef}from 'react'
import { Button,Modal,message,Form,Input,Upload} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {useWorkPlanUpdateQuery, useWorkPlanUpdateSaveMutation } from '../store/ReactWebSiteApi';
import MyEditor from '../cpn/MyEditor';
function TrainPlanEdit(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({
        ewp_content:'',
        ewp_file_name:'',
        ewp_file_file:''
    });
    const editForm = useRef()
    //上传
    const [defaultSite,setDefaultSite] = useState();
    const homePicEdit = (e) => {
        if (e.file.status === 'done'||e.file.status==='removed') {
            const fileArr = e.fileList.map((item) => {
                const { response } = item
                return response.Result
            })
            console.log(fileArr);
            let fileStr = fileArr + "";  
            if(fileStr === []){
                fileStr = ''
            }
            const nameArr = e.fileList.map((item) => {
                const { name} = item
                return name
            })
            let nameStr = nameArr + ""
            if(nameStr === []){
                nameStr = ''
            }
            setInfo(prevState=>({...prevState,ewp_file_name:nameStr,ewp_file_file:fileStr}))
        }
    }
    
    const {data,isSuccess} = useWorkPlanUpdateQuery({ewp_id:props.ewp_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                //上传
                if (data.Result.ewp_file_file) {
                    let options = [];
                    let a = {};
                    a.uid = 1;
                    a.url = `/api/assets/images/application/${data.Result.ewp_file_file}`
                    a.response = {Result:data.Result.ewp_file_file}
                    options.push(a);
                    //console.log(options);
                    setDefaultSite(<Upload
                        name="image_file"
                        action="/api/ReactApi/zd_single_upload_image"
                        listType="text"
                        defaultFileList={options}
                        className="upload-list-inline"
                        onChange={homePicEdit}
                        maxCount={1}
                        >
                        <Button icon={<UploadOutlined />}>点击上传</Button>
                    </Upload>)
                }else{
                    setDefaultSite(<Upload
                        name="image_file"
                        action="/api/ReactApi/zd_single_upload_image"
                        listType="picture"
                        className="upload-list-inline"
                        onChange={homePicEdit}
                        maxCount={1}
                        >
                        <Button icon={<UploadOutlined />}>点击上传</Button>
                    </Upload>)
                }
                
                editForm.current.setFieldsValue({
                    ewp_name:data.Result.ewp_name,
                    ewp_content:data.Result.ewp_content,
                })
                setInfo(prevState=>({...prevState,
                    ewp_name:data.Result.ewp_name,
                    ewp_content:data.Result.ewp_content,              
                    ewp_file_name:data.Result.ewp_file_name,              
                    ewp_file_file:data.Result.ewp_file_file,              
                }))
            }
        }
    },[data,isSuccess,])
    const [submitUpdateSave] = useWorkPlanUpdateSaveMutation()
    return (
        <>
            <a onClick={() => {setOpen(true)}}>修改</a>
            <Modal
                open={open}
                title='修改培训计划'
                okText="提交"
                cancelText="取消"
                width={1000}
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateEdit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitUpdateSave({
                        ewp_name:info.ewp_name,
                        ewp_content:info.ewp_content,
                        ewp_update_uid:localStorage.getItem('eu_id'),
                        ewp_update_uname:localStorage.getItem('eu_name'),
                        ewp_id:props.ewp_id,
                        ewp_e_id:localStorage.getItem('e_id'),
                        ewp_dept_id:localStorage.getItem('ed_id'),
                        ewp_file_name:info.ewp_file_name,
                        ewp_file_file:info.ewp_file_file,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')   
                            setOpen(false)                             
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                    ref={editForm}
                >                            
                    <Form.Item name="ewp_name" label='标题' wrapperCol={{span:10}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,ewp_name:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item name="ewp_content" label='正文' wrapperCol={{span:15}}>
                        <MyEditor onChange={(e) => {setInfo(prevState=>({...prevState,ewp_content:e}))}}/>
                    </Form.Item>
                    <Form.Item label="附件"                      
                        valuePropName='fileList'
                        getValueFromEvent={e => {
                            if (Array.isArray(e)) {
                                return e;
                            }
                            return e && e.fileList;
                        }}
                        >
                        {defaultSite}
                    </Form.Item>                    
                </Form>
            </Modal>
        </>
    )
}

export default TrainPlanEdit