import React,{useState,useEffect} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {  useGetUserPowerQuery, useUserUseAppLogMutation } from '../store/ReactWebSiteApi'
import Main from '../cpn/Main'
import { Row } from 'antd'
import Folder from '../cpn/Folder'
function IndexSecond() {
    const [param] = useSearchParams()
    const navi = useNavigate(); 
    // console.log(auth);  
    //上传用户应用打开数量
    const [submitAdd] = useUserUseAppLogMutation()
    //应用列表=============================================================================================================================================应用列表//
    const {data, isSuccess} = useGetUserPowerQuery({
        eu_id:localStorage.getItem('eu_id'),esv_type_id:localStorage.getItem('e_id')==='218'?'1,3':'1,5'});    
    const [list, setList] = useState();    
    useEffect(() => {
        if (isSuccess) {
            if(data.Status==='success' && data.Result.eu_user_esv_id){
                // console.log(data);
                const newArr = data.Result.eu_user_esv_id.map(item => {
                    const {esv_id:key,esv_name,esv_state,esv_router,msgNum,esv_cost_type} = item;
                    if(esv_state==='2' && esv_cost_type===param.get('indexType')){
                        return item =  <Folder title={esv_name} link={`${esv_router}?indexName=${param.get('indexName')}&indexType=${param.get('indexType')}&esv_id=${key}&msgNum=${msgNum}`} />
                    }else{
                        return <div key={key}></div>
                    }
                })                       
                setList(newArr) 
            }else if(data.Status==='success' && data.Result.station_data){
                // console.log(data);
                const newArr = data.Result.station_data[0].esv.map(item => {
                    const {esv_id:key,esv_name,esv_state,esv_router,esv_cost_type,msgNum} = item;
                    if(esv_state==='2' && esv_cost_type===param.get('indexType')){
                        return item =   <Folder title={esv_name} link={`${esv_router}?indexName=${param.get('indexName')}&indexType=${param.get('indexType')}&esv_id=${key}&msgNum=${msgNum}`} />
                        }else{
                            return <div key={key}></div>
                        }
                })
                setList(newArr) 
            }else{
                setList([])
            }
            
        }
    }, [data, isSuccess, navi, param, submitAdd])   
    return (
        <Main >
            <Row>                    
                {list}
            </Row>
        </Main>
    )
}

export default IndexSecond