import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
    name: "login",
    initialState: () => {
        const token = localStorage.getItem('token');
        if (!token) {
            return {
                isLog: false,
                token:null,
                eu_ba_phone: null,
                eu_ba_id: null,
                eu_name:null,
                eu_role:null,
                e_id:null,
                e_name:null,
                ed_id:null,
                ed_name:null,
                eu_id: null,
                expirationTime: 0,
                ess_menu_id:null,
                eu_operate_id:null,
                eu_esv_id:null,
                ess_name:null,
                esv:null,
                eu_dept_id:null,
                e_soft_name:null,
            }
        }        
        return {
            isLog: true,
            token,
            eu_ba_phone: JSON.parse(localStorage.getItem('eu_ba_phone')),
            eu_ba_id: JSON.parse(localStorage.getItem('eu_ba_id')),
            eu_name:JSON.parse(localStorage.getItem('eu_name')),
            eu_role:JSON.parse(localStorage.getItem('eu_role')),
            e_id:JSON.parse(localStorage.getItem('e_id')),
            e_name:JSON.parse(localStorage.getItem('e_name')),
            ed_id:JSON.parse(localStorage.getItem('ed_id')),
            ed_name:JSON.parse(localStorage.getItem('ed_name')),
            eu_id:JSON.parse(localStorage.getItem('eu_id')),
            expirationTime:+JSON.parse(localStorage.getItem('expirationTime')),
            ess_menu_id:localStorage.getItem('ess_menu_id'),
            eu_operate_id:localStorage.getItem('eu_operate_id'),
            eu_esv_id:localStorage.getItem('eu_esv_id'),
            ess_name:localStorage.getItem('ess_name'),
            esv:localStorage.getItem('esv'),
            eu_dept_id:localStorage.getItem('eu_dept_id'),
            e_soft_name:localStorage.getItem('e_soft_name'),
        }
    },
    reducers: {
        setLogin(state, action) {
            state.isLog = true;
            state.token = action.payload.token;
            state.eu_ba_phone = action.payload.eu_ba_phone;
            state.eu_ba_id = action.payload.eu_ba_id;
            state.eu_name = action.payload.eu_name;
            state.eu_role = action.payload.eu_role;
            state.e_id = action.payload.e_id;
            state.e_name = action.payload.e_name;
            state.ed_id = action.payload.ed_id;
            state.ed_name = action.payload.ed_name;
            state.eu_id = action.payload.eu_id;
            state.ess_menu_id = action.payload.ess_menu_id;
            state.eu_operate_id = action.payload.eu_operate_id;
            state.eu_esv_id = action.payload.eu_esv_id;
            state.ess_name = action.payload.ess_name;
            state.esv = action.payload.esv;
            state.eu_dept_id = action.payload.eu_dept_id;
            state.e_soft_name = action.payload.e_soft_name;
            const currentTime = Date.now();
            const timeout = 1000 * 60 * 60 * 24 * 30;
            // const timeout = 10000;
            state.expirationTime = currentTime + timeout;
            localStorage.setItem('token', state.token);
            localStorage.setItem('eu_ba_phone', JSON.stringify(state.eu_ba_phone));
            localStorage.setItem('eu_ba_id', JSON.stringify(state.eu_ba_id));
            localStorage.setItem('eu_name', JSON.stringify(state.eu_name));
            localStorage.setItem('eu_role', JSON.stringify(state.eu_role));
            localStorage.setItem('e_id', JSON.stringify(state.e_id));
            localStorage.setItem('e_name', JSON.stringify(state.e_name));
            localStorage.setItem('ed_id', JSON.stringify(state.ed_id));
            localStorage.setItem('ed_name', JSON.stringify(state.ed_name));
            localStorage.setItem('eu_id', JSON.stringify(state.eu_id));
            localStorage.setItem('expirationTime', state.expirationTime+"");
            localStorage.setItem('ess_menu_id', state.ess_menu_id);
            localStorage.setItem('eu_operate_id', state.eu_operate_id);
            localStorage.setItem('eu_esv_id', state.eu_esv_id);
            localStorage.setItem('ess_name', state.ess_name);
            localStorage.setItem('esv', state.esv);
            localStorage.setItem('eu_dept_id', state.eu_dept_id);
            localStorage.setItem('e_soft_name', state.e_soft_name);
        },
        setLogout(state, action) {
            state.isLog = false;
            state.token = null;
            localStorage.removeItem('token');
            localStorage.removeItem('expirationTime');
            if (!state.isRemeberUser) {
                localStorage.removeItem('eu_ba_id');
                localStorage.removeItem('eu_name');
                localStorage.removeItem('eu_role');                
                localStorage.removeItem('e_id');                
                localStorage.removeItem('e_name');                
                localStorage.removeItem('ed_id');                
                localStorage.removeItem('eu_id');                
                localStorage.removeItem('ed_name');                
                localStorage.removeItem('ess_menu_id');                
                localStorage.removeItem('eu_operate_id');                
                localStorage.removeItem('eu_esv_id');                
                localStorage.removeItem('ess_name');                
                localStorage.removeItem('esv');                
                localStorage.removeItem('eu_dept_id');                
                localStorage.removeItem('e_soft_name');                
            }
        }        
    }
})

export const {setLogin,setLogout} = loginSlice.actions;
export const {reducer:loginReducer} = loginSlice;