import React,{useRef, useState}from 'react'
import { Form,Input,message,} from 'antd';
import { useDijieTemplateKeChengAddMutation,} from '../../../../../store/ReactWebSiteApi';
import ModalAdd from '../../../../../cpn/ModalAdd';
import GetUserDept from '../../../../../cpn/GetUserDept';
import UploadOne from '../../../../../cpn/UploadOne';
import MyEditor from '../../../../../cpn/MyEditor';
const { TextArea } = Input;
function OpCourseAdd(props) {
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        ltkc_image:'',
        ltkc_content:'',
    });
    const [submitAdd] = useDijieTemplateKeChengAddMutation()
    const [open,setOpen] = useState(false)
    const editForm = useRef()
    return (
        <ModalAdd 
            title='课程安排模板'
            onOk={(e) => {
                submitAdd({
                    ltkc_dept_id: info.ltkc_dept_id,
                    ltkc_dept_name: info.ltkc_dept_name,
                    ltkc_didian: info.ltkc_didian,
                    ltkc_e_id: localStorage.getItem('e_id'),
                    ltkc_e_name: localStorage.getItem('e_name'),
                    ltkc_guimo: info.ltkc_guimo,
                    ltkc_image: info.ltkc_image,
                    ltkc_leirong: info.ltkc_leirong,
                    ltkc_liucheng: info.ltkc_liucheng,
                    ltkc_mubiao: info.ltkc_mubiao,
                    ltkc_name: info.ltkc_name,
                    ltkc_shichang: info.ltkc_shichang,
                    ltkc_shizi: info.ltkc_shizi,
                    ltkc_uid: localStorage.getItem('eu_id'),
                    ltkc_uname:localStorage.getItem('eu_name'),
                    ltkc_xueduan: info.ltkc_xueduan,
                    ltkc_yongju: info.ltkc_yongju,
                }).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('新增成功！') 
                        setOpen(false)                                                 
                    }
                    if (res.data.Status === 'fail') {
                        message.error('新增失败！'+res.data.Message)
                    }
                })
            }}
            open={open}
            getOpen={(e) => {
                setOpen(prevsState=>e)
                if(e){
                    setInfo(prevState=>({...prevState,ltkc_dept_id:'',ltkc_name:'',}))
                    form.resetFields(); 
                }
            }}
            width={800}
        >
            <Form
                ref={editForm}
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 3 }}
            >                                           
                {!props.ltbjd_dept_id && <GetUserDept 
                    label='部门' 
                    name='dept'
                    required 
                    wrapperCol={{span:10}}
                    getUserDept={(e,f) => {
                        console.log(e,f);
                        setInfo(prevsState=>({...prevsState,ltkc_dept_id:e,ltkc_dept_name:f}))
                    }}
                />}
                <Form.Item name="name" label='课程名称' wrapperCol={{span:10}} required>
                    <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkc_name:e.target.value}))}}/>
                </Form.Item>
                <UploadOne 
                    name='pic'
                    label='课程图片' 
                    fileStr={(e) => {setInfo(prevsState=>({...prevsState,ltkc_image:e}))}}
                />
                <Form.Item name="remark" label='课程内容' wrapperCol={{span:20}}>
                    <TextArea  
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkc_leirong:e.target.value}))}}
                        autoSize={{
                            minRows: 3,
                            }}
                    />
                </Form.Item>
                <Form.Item name="guide" label='课程目标' wrapperCol={{span:20}}>
                    <TextArea  
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkc_mubiao:e.target.value}))}}
                        autoSize={{
                            minRows: 3,
                            }}
                    />
                </Form.Item>
                <Form.Item name="time" label='课程时长' wrapperCol={{span:5}}>
                    <Input 
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkc_shichang:e.target.value}))}}
                    />
                </Form.Item>
                <Form.Item name="student" label='适用学段' wrapperCol={{span:20}}>
                    <Input 
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkc_xueduan:e.target.value}))}}
                    />
                </Form.Item>
                <Form.Item name="site" label='研学地点' wrapperCol={{span:20}}>
                    <Input 
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkc_didian:e.target.value}))}}
                    />
                </Form.Item>
                <Form.Item name="num" label='接待规模' wrapperCol={{span:20}}>
                    <Input 
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkc_guimo:e.target.value}))}}
                    />
                </Form.Item>
                <Form.Item name="teacher" label='配备师资' wrapperCol={{span:20}}>
                    <Input 
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkcltkc_shizi_didian:e.target.value}))}}
                    />
                </Form.Item>
                <Form.Item name="tool" label='研学用具' wrapperCol={{span:20}}>
                    <Input 
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkc_yongju:e.target.value}))}}
                    />
                </Form.Item>
                <Form.Item name="arrange" label='研学流程' wrapperCol={{span:20}}>
                    <MyEditor 
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkc_liucheng:e}))}}
                    />
                </Form.Item>
            </Form>
        </ModalAdd>
    )
}

export default OpCourseAdd