import { Button, Form, Input, message, Modal, TreeSelect } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAppStationAddMutation, useQuanxianTreeListQuery } from '../store/ReactWebSiteApi';
function SetRoleAdd() {
    const [open,setOpen] = useState(false)
    const [form] = Form.useForm();     

    const {data,isSuccess} = useQuanxianTreeListQuery({qx_type:localStorage.getItem('e_soft_name')==='金鱼'?'1':'2'})    
    const [list, setList] = useState([]);    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){                                
                setList([data.Result])
            }else{
                setList()
            }
            
        }
    }, [data, isSuccess])  
    //新增岗位
    const [info,setInfo] = useState();
    const [submitAdd] = useAppStationAddMutation()
    return (
        <>
            <div style={{marginBottom:"10px"}}>
                <Button type='primary' onClick={() => {setOpen(true)}}>新增</Button>
            </div>
            <Modal
                open={open}
                title='新增岗位'
                width={1000}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {                    
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields(); 
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        ess_e_id:localStorage.getItem('e_id'),
                        ess_name:info.ess_name,
                        ess_menu_id:info.ess_menu_id,
                        ess_uid:localStorage.getItem('eu_id'),
                        ess_uname:localStorage.getItem('eu_name'),
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                >
                    <Form.Item name="admin" label='岗位名称' wrapperCol={{span:8}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,ess_name:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item name="ad" label='权限' wrapperCol={{span:18}} required>
                        <TreeSelect
                            showSearch
                            style={{
                                width: '100%',
                            }}
                            placeholder="请选择岗位的权限（多选）"
                            allowClear
                            multiple
                            treeDefaultExpandAll
                            onChange={(e) => {
                                setInfo(prevState=>({...prevState,ess_menu_id:e}))
                            }}
                            treeData={list}
                            treeCheckable
                            
                        />
                    </Form.Item>                    
                </Form>
            </Modal>   
        </>
    )
}

export default SetRoleAdd