import {Descriptions,Flex, Modal, Space} from 'antd'
import React,{useEffect, useState}from 'react'
import { useYewuTuanHaoUpdateQuery } from '../../../../store/ReactWebSiteApi';
import GroupFile from './GroupFile';
import GuidePlan from './guidePlan/GuidePlan';
import FeedBack from './feedback/FeedBack';

function GroupCheck(props) {
    console.log(props);
    const [open, setOpen] = useState(false);       
    const [info,setInfo] = useState({
        lth_dept_id:"",                         
        lth_dept_name:"",                         
        lth_date:"",    
        lth_type:"",                   
        lth_kehu_name:"",                   
        lth_xianlu_name:"",                   
        lth_fenche:"", 
    })
    //获取
    const {data,isSuccess} = useYewuTuanHaoUpdateQuery({lth_id:props.lth_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){                     
                setInfo(prevsState=>({...prevsState,
                    lth_dept_id:data.Result.lth_dept_id,                         
                    lth_id:data.Result.lth_id,                         
                    lth_dept_name:data.Result.lth_dept_name,                         
                    lth_date:data.Result.lth_date,                    
                    lth_kehu_name:data.Result.lth_kehu_name,                   
                    lth_xianlu_name:data.Result.lth_xianlu_name,      
                    lth_num:data.Result.lth_num,       
                    lth_ltkh_id:data.Result.lth_ltkh_id,       
                    lth_ltkh_type_id:data.Result.lth_ltkh_type_id,  
                    lth_phone:data.Result.lth_phone,  
                    lth_content:data.Result.lth_content,  
                }))
            }
        }
    },[data,isSuccess,])
    //基本信息
    const items = [        
        {
            key: '1',
            label: '团号',
            children: info.lth_num,
            labelStyle:{width:'140px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '2',
            label: '线路名称',
            children: info.lth_xianlu_name,
            labelStyle:{width:'100px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '3',
            label: '出发日期',
            children: info.lth_date,
            labelStyle:{width:'100px'},
            contentStyle:{fontSize:"16px"}
        },                
        {
            key: '4',
            label:'组团社',
            children:info.lth_kehu_name + ' - ' + info.lth_phone,
            contentStyle:{fontSize:"16px"}
        },                
        
        {
            key: '5',
            label: '部门',
            children: info.lth_dept_name,
            contentStyle:{fontSize:"16px"}
        },
        
        {
            key: '8',
            label: '操作',
            contentStyle:{fontSize:"16px"},
            children:<Space></Space>,
        },
        {
            key: '6',
            label: '备注',
            children: info.lth_content,
            contentStyle:{fontSize:"16px"}
        },
    ]
    return (
        <>
            <a onClick={() => {
                setOpen(true);
                }} >{props.lth_num}</a>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false)}}
                width={1200}
                footer={null}
            >
                <div style={{border:'2px solid #eee',margin:"10px"}}>
                    <Flex 
                        justify='center' 
                        style={{fontSize:'30px',lineHeight:"88px",fontWeight:'bold'}}
                    >团队计划</Flex>
                    <Descriptions 
                        items={items} 
                        bordered 
                        size='small' 
                        labelStyle={{fontSize:"16px"}}
                    />                    
                    <GroupFile
                        lth_num={info.lth_num}
                    />
                    <GuidePlan
                        dyjhd_lth_id={info.lth_id}
                        dyjhd_lth_num={info.lth_num}
                    />
                    <FeedBack
                    />
                </div>
            </Modal>
        </>
        
    )
}

export default GroupCheck