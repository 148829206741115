import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store';
import zhCN from 'antd/locale/zh_CN';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <ConfigProvider locale={zhCN} theme={{ token: { colorPrimary: '#17a2b8' } }}> */}
      <ConfigProvider locale={zhCN} theme={{
    components: {
      Table: {
        /* 这里是你的组件 token */
        cellFontSizeSM:'16px'
      },
    },
  }}>
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </Provider>
);
