import React,{useState,useEffect}from 'react'
import { Table,Space,Modal,message, Form,} from 'antd';
import { useGoodsTypeDelMutation, useGoodsTypeListQuery } from '../../store/ReactWebSiteApi';
import GoodsTypeAdd from './GoodsTypeAdd';
import GoodsTypeEdit from './GoodsTypeEdit';
import { useSearchParams } from 'react-router-dom';
function SetGoodsType() {   
    const [open,setOpen] = useState(false);
    const [param] = useSearchParams()
    //删除产品类型-----------------------------------------------------------------------------------------------------------------------------删除产品类型
    const {confirm} = Modal; 
    const [submitDel] = useGoodsTypeDelMutation()
    //产品类型列表-----------------------------------------------------------------------------------------------------------------------------产品类型列表
    
    const {data,isSuccess} = useGoodsTypeListQuery({egt_e_id:localStorage.getItem('e_id'),egt_esv_id:param.get('esv_id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {egt_id:key,egt_name,egt_content,egt_uname,egt_ctime,egt_update_ctime,egt_update_uname,goods_num} = item;
                    return item = {key,egt_name,egt_content,egt_uname,egt_ctime,egt_update_ctime,egt_update_uname,goods_num}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.egt_ctime;
                    let bTimeString = b.egt_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])      
    const columns = [
        {
            key: 'type',
            title: '产品类型名称',
            dataIndex: 'egt_name',
        },                            
        {
            key: 'goods_num',
            title: '产品数量',
            dataIndex: 'goods_num',
        },  
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },     
        // {
        //     key: 'time',
        //     title: '操作记录',
        //     render: (_,record) => {
        //         return <>
        //             <div>新建时间：{record.egt_ctime + record.egt_uname}</div>
        //             <div>修改时间：{record.egt_update_ctime + record.egt_update_uname}</div>
        //         </>
        //     }
        // },
        {
            key: 'action',
            title:'操作',
            render: (_, record) => {
                return <>
                    <Space >                        
                        <GoodsTypeEdit
                            egt_id={record.key}
                            egt_esv_id={param.get('esv_id')}
                        />
                        <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({egt_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <>
            <Form.Item>
                <a onClick={() => {setOpen(true)}}>设置</a>
            </Form.Item>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {}}
                footer={null}
                width={800}
                title='设置产品类型'
            >
                <Space>
                    <GoodsTypeAdd />
                </Space>
                <Table columns={columns} dataSource={list} style={{marginTop:'10px'}}  pagination={{position:['none']}}></Table>
            </Modal>
             
        </>
    )
}

export default SetGoodsType