import React, { useEffect, useRef, useState } from 'react'
import ModalEdit from '../../../../../cpn/ModalEdit'
import { Form, Input,message } from 'antd'
import GetUserDept from '../../../../../cpn/GetUserDept';
import { useDijieTemplateZhuYiShiXiangUpdateQuery, useDijieTemplateZhuYiShiXiangUpdateSaveMutation } from '../../../../../store/ReactWebSiteApi';
const  {TextArea} = Input
function NotesEdit(props) {
    const [form] = Form.useForm();  
    const editForm = useRef()
    const [info,setInfo] = useState({
        ltzysx_e_id:localStorage.getItem('e_id'),
        ltzysx_update_uid:localStorage.getItem('eu_id'),
        ltzysx_update_uname:localStorage.getItem('eu_name'),
        ltzysx_id:props.ltzysx_id,
    });
    const [open,setOpen] = useState()
    //获取
    const {data,isSuccess} = useDijieTemplateZhuYiShiXiangUpdateQuery({ltzysx_id:props.ltzysx_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                editForm.current.setFieldsValue({
                    ltzysx_dept_id:data.Result.ltzysx_dept_id,                  
                    ltzysx_name:data.Result.ltzysx_name,
                    ltzysx_content:data.Result.ltzysx_content,
                })
                setInfo(prevsState=>({...prevsState,
                    ltzysx_dept_id:data.Result.ltzysx_dept_id,
                    ltzysx_name:data.Result.ltzysx_name,
                    ltzysx_content:data.Result.ltzysx_content,
                }))
            }
        }
    },[data,isSuccess,])
    const [updateSave] = useDijieTemplateZhuYiShiXiangUpdateSaveMutation()
    return (
        <ModalEdit
            getOpen={(e) => {setOpen(prevsState=>e );}}
            onOk={() => {
                updateSave(info).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('修改成功！') 
                        setOpen(false)                                                 
                    }
                    if (res.data.Status === 'fail') {
                        message.error('修改失败！'+res.data.Message)
                    }
                })
            }}
            open={open}
            title='注意事项模板'
            width={800}
        >
            <Form
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 3 }}
                ref={editForm}
            >                                           
                <GetUserDept 
                    label='部门' 
                    required 
                    name='ltzysx_dept_id'
                    wrapperCol={{span:5}}
                    getUserDept={(e) => {setInfo(prevsState=>({...prevsState,ltzysx_dept_id:e}))}}
                />
                <Form.Item name="ltzysx_name" label='标题' wrapperCol={{span:20}}>
                    <Input 
                        showCount 
                        maxLength={7}
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltzysx_name:e.target.value}))}}
                    />
                </Form.Item>
                <Form.Item name="ltzysx_content" label='描述' wrapperCol={{span:20}}>
                    <TextArea 
                        autoSize={{
                            minRows: 2,                                
                          }}
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltzysx_content:e.target.value}))}}
                    />
                </Form.Item>
            </Form>
        </ModalEdit>
    )
}

export default NotesEdit