import React, { useState } from 'react'
import ModalAdd from '../../../../../cpn/ModalAdd';
import { Form, Input, message,Button, Space } from 'antd';
import GetUserDept from '../../../../../cpn/GetUserDept';
import { useDijieTemplateDaoYouAddMutation } from '../../../../../store/ReactWebSiteApi';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
function FeedbackAdd() {
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        sp_country_id:'',
        sp_country_name:'',
        sp_province_id:'',
        sp_province_name:'',
        sp_city_id:'',
        sp_city_name:'',
    });
    console.log(info);
    const [open,setOpen] = useState(false)
    const [add]  =useDijieTemplateDaoYouAddMutation()
    
    return (
        <>
            <ModalAdd
                title='意见单模板'
                onOk={(e) => {
                    add({
                        ltdy_content: info.ltdy_content,
                        ltdy_dept_id: info.ltdy_dept_id,
                        ltdy_dept_name: info.ltdy_dept_name,
                        ltdy_e_id: localStorage.getItem('e_id'),
                        ltdy_e_name: localStorage.getItem('e_name'),
                        ltdy_name: info.ltdy_name,
                        ltdy_phone: info.ltdy_phone,
                        ltdy_uid: localStorage.getItem('eu_id'),
                        ltdy_uname: localStorage.getItem('eu_name'), 
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！') 
                            setOpen(false)                                                 
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
                open={open}
                getOpen={(e) => {
                    setOpen(prevsState=>e)
                    if(e){
                        setInfo(prevState=>({...prevState,lttt_dept_id:'',lttt_image:'',lttt_phone:''}))
                        form.resetFields(); 
                    }
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                >   
                    <GetUserDept
                        label='部门' 
                        name='dept'
                        required 
                        wrapperCol={{span:18}}
                        getUserDept={(e,f) => {setInfo(prevsState=>({...prevsState,ltdy_dept_id:e,ltdy_dept_name:f}))}}
                    />  
                    <Form.Item name="remark" label='模板名称' wrapperCol={{span:18}} required>
                        <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,ltdy_name:e.target.value}))}} />  
                    </Form.Item>             
                    <Form.Item
                        label='评价项目'
                        required
                        wrapperCol={{span:18}}
                    >
                        <Form.List 
                            name="users"
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space
                                            key={key}
                                            align="baseline"
                                        >
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'first']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请填写评价项目！',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="填写评价项目" />
                                            </Form.Item>                                   
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button 
                                            type="dashed" 
                                            onClick={() => add()} block icon={<PlusOutlined />}
                                        >增加评价项目</Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>       
                    </Form.Item>
                </Form>
            </ModalAdd>
        </>
    )
}

export default FeedbackAdd