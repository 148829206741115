import React, { useEffect, useState } from 'react'
import Main from '../../../cpn/Main'
import { Space, Table } from 'antd'
import PhoneShopAdd from './PhoneShopAdd'
import { useNavigate } from 'react-router-dom'
import { useEntDianPuListQuery } from '../../../store/ReactWebSiteApi'
import PhoneShopEdit from './PhoneShopEdit'

function PhoneShop() {
    const navi = useNavigate()

    const [list,setList] = useState()
    const {data, isSuccess} = useEntDianPuListQuery({edp_e_id:localStorage.getItem('e_id'),},{refetchOnMountOrArgChange:1});
    useEffect(() => {
        if (isSuccess) {
            console.log(data);
            if(data.Status==='success'){                
                const newArr = data.Result.map(item => {
                    const {edp_id:key,edp_name,edp_fuzeren_name,edp_dept_name,edp_ctime} = item;                    
                    return item = {key,edp_name,edp_fuzeren_name,edp_dept_name,edp_ctime}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.edp_ctime;
                    let bTimeString = b.edp_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    const column = [
        {
            key:'8',
            title:'名称',
            dataIndex:'edp_name',           
        },     
        {
            key:'2',
            title:'负责人',
            dataIndex:'edp_fuzeren_name'
        },
                 
        {
            key:'12',
            title:'部门',
            dataIndex:'edp_dept_name'
        },      
        {
            key:'6',
            title:'操作',
            render:(_,record) => {
                return <Space> 
                    <a>查看</a>
                    <PhoneShopEdit edp_id={record.key} />
                    <a onClick={() => {navi(`/PhoneShopGoods?id=${record.key}`)}}>商品管理</a>
                </Space>
            }
        },
    ]
    return (
        <Main t1='组团销售' n1='/TaOrganIndex' t2='手机门店'>
            <Space>
                <PhoneShopAdd />
            </Space>
            <Table columns={column} dataSource={list} style={{marginTop:"10px"}}/>
        </Main>
    )
}

export default PhoneShop