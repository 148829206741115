import React from 'react'
import Main from '../../cpn/Main'
import { Row, } from 'antd';
import Folder from '../../cpn/Folder';
import { indexFinance } from '../balance/balanceType';
function ElseIndex() {
    return (
        <Main t1='财务' n1={indexFinance} t2='其他收支'>           
            <Row>                
                <Folder title='其他收入单'  link='/ElseIncome' />            
                <Folder title='其他支出单'  link='/ElsePaid' />            
                <Folder title='报表'  link='/ElseTable' />            
            </Row>
        </Main>
    )
}

export default ElseIndex