import React from 'react'
import Main from '../cpn/Main'
import { Row } from 'antd';
import classes from '../cpn/subIndex.module.css'
import Folder from '../cpn/Folder';
function TaskIndex() {
    return (
        <Main t1='任务'>
            <Row className={classes.row}>                
                <Folder title='我的任务' link='/TaskReceive'/>                
                <Folder title='任务布置' link='/TaskSend'/>                
            </Row>                
        </Main>
    )
}
export default TaskIndex