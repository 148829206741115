import React,{useState,useEffect,useRef}from 'react'
import Main from '../../cpn/Main'
import { Table,Button,Space,Modal,message,Form,Input,} from 'antd';
import { useNewsTypeAddMutation, useNewsTypeDelMutation, useNewsTypeListQuery, useNewsTypeUpdateQuery, useNewsTypeUpdateSaveMutation } from '../../store/ReactWebSiteApi';

function WebNewsType() {
    //新增新闻类型-----------------------------------------------------------------------------------------------------------------------------新增新闻类型
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateClient = (values) => {
        // console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [infoAdd,setInfoAdd] = useState({
        ent_content:'',
    });
    const [submitAdd] = useNewsTypeAddMutation()
    const modalAdd= <Modal
                        open={openAdd}
                        title='新增新闻类型'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenAdd(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateClient(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitAdd({
                                ent_name:infoAdd.ent_name,
                                ent_e_id:localStorage.getItem('e_id'),
                                ent_uid:localStorage.getItem('eu_id'),
                                ent_uname:localStorage.getItem('eu_name'),
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpenAdd(false)                         
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 7 }}
                        >                            
                            <Form.Item name="name" label='新闻类型名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoAdd(prevState=>({...prevState,ent_name:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal> 
    //修改新闻类型-----------------------------------------------------------------------------------------------------------------------------修改新闻类型
    const [openEdit, setOpenEdit] = useState(false);
    const [editId,setEditId] = useState()
    const onCreateEdit = (values) => {
        // console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    const [infoEdit,setInfoEdit] = useState();
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useNewsTypeUpdateQuery({ent_id:editId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                // console.log(dataEdit);
                editForm.current.setFieldsValue({
                    ent_name:dataEdit.Result.ent_name,
                })
                setInfoEdit(prevState=>({...prevState,
                    ent_name:dataEdit.Result.ent_name,
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [submitUpdateSave] = useNewsTypeUpdateSaveMutation()
    const modalEdit = <Modal
                        open={openEdit}
                        title='修改新闻类型'
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            submitUpdateSave({
                                ent_name:infoEdit.ent_name,
                                ent_e_id:localStorage.getItem('e_id'),
                                ent_update_uid:localStorage.getItem('eu_id'),
                                ent_update_uname:localStorage.getItem('eu_name'),
                                ent_id:editId,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpenEdit(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 7 }}
                            ref={editForm}
                        >
                            <Form.Item name="ent_name" label='新闻类型名称' wrapperCol={{span:10}} required>
                                <Input  onChange={(e) => {setInfoEdit(prevState=>({...prevState,ent_name:e.target.value}))}}/>
                            </Form.Item>
                        </Form>
                    </Modal>
    //删除新闻类型-----------------------------------------------------------------------------------------------------------------------------删除新闻类型
    const {confirm} = Modal; 
    const [submitDel] = useNewsTypeDelMutation()
    //新闻类型列表-----------------------------------------------------------------------------------------------------------------------------新闻类型列表
    
    const {data,isSuccess} = useNewsTypeListQuery({ent_e_id:localStorage.getItem('e_id')})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                // //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {ent_id:key,ent_name,ent_uname,ent_ctime,ent_update_ctime,ent_update_uname,news_num} = item;
                    return item = {key,ent_name,ent_uname,ent_ctime,ent_update_ctime,ent_update_uname,news_num}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ent_ctime;
                    let bTimeString = b.ent_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])      
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
       
        {
            key: 'type',
            title: '新闻类型名称',
            dataIndex: 'ent_name',
        },                           
        {
            key: 'num',
            title: '新闻数量',
            dataIndex: 'news_num',
        },       
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.ent_ctime + record.ent_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>                        
                        <a onClick={() => {
                            setEditId(record.key);
                            setOpenEdit(true)
                        }}>修改</a>
                        {!record.news_num && <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    submitDel({ent_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>}
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main   t1='商城' n1='/GfIndexMall' t2='官网' n2='/WebIndex' t3='教程分类' >
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增新闻类型</Button>
            </div>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalAdd}
            {modalEdit}            
        </Main>
    )
}

export default WebNewsType