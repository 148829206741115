import React, { useEffect, useState } from 'react'
import { Space, Table } from 'antd'
import CustomGoodsLineAdd from './CustomGoodsLineAdd'

function CustomGoodsLine() {
    const [list,setList] = useState([
        {
            dept:'地接部',
            client:'张三',
            goods:'长江三峡三日游',
            guige:'成人3人，儿童1人',
            date:'2024年8月30日',
            name:'长江三峡尊享报价',
            type:'地接报价',
            op:'李四 2024-07-07 20:20:20',
            key:'1'
        }
    ])
    useEffect(() => {setList()},[])
    const column = [        
        {
            key:'8',
            title:'线路名称',
            dataIndex:'name'
        },       
        {
            key:'8',
            title:'备注说明',
            dataIndex:'type'
        },       
        {
            key:'12',
            title:'编号',
            dataIndex:'id'
        },
        {
            key:'5',
            title:'操作员',
            dataIndex:'op'
        },
        {
            key:'6',
            title:'操作',
            render:() => {
                return <Space> 
                </Space>
            }
        },
    ]
    return (
        <>
            
            <Space>
                <CustomGoodsLineAdd />
            </Space>
            <Table columns={column} dataSource={list} style={{marginTop:"10px"}}/>
        </>
    )
}

export default CustomGoodsLine