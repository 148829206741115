import React,{useState,useEffect,useRef}from 'react'
import { Table,Space,Modal,message,Form,Checkbox, Divider,} from 'antd';
import Main from '../cpn/Main';
import { MenuName } from '../cpn/MenuName';
import { useAllDeptUserSearchQuery, usePurviewSettingUpdateQuery, usePurviewSettingUpdateSaveMutation, } from '../store/ReactWebSiteApi';
function TrainLimits() {
    const [form] = Form.useForm();   
    //权限设置-----------------------------------------------------------------------------------------------------------------------------权限设置
    const [editId,setEditId] = useState();
    const [infoEdit,setInfoEdit] = useState({
        eu_esv_id:['s'],
        eu_name:'',
    });
    const {data:dataLimit,isSuccess:isSuccessLimit} = usePurviewSettingUpdateQuery({eu_id:editId},{skip:!editId})
    useEffect(() => {
        if(isSuccessLimit){
            console.log(dataLimit);
            if(dataLimit.Result.eu_esv_id){
                if(dataLimit.Result.eu_esv_id.includes('5')){
                    editForm.current.setFieldsValue({
                        eu_esv_id:'5',
                        eu_menu_id:dataLimit.Result.eu_menu_id,
                    })
                }
            }
            setInfoEdit(prevState=>({...prevState,
                eu_esv_id:dataLimit.Result.eu_esv_id,
                eu_esv_name:dataLimit.Result.eu_esv_name,
                eu_menu_id:dataLimit.Result.eu_menu_id,
                eu_menu_name:dataLimit.Result.eu_menu_name,
                eu_operate_id:dataLimit.Result.eu_operate_id,
                eu_operate_name:dataLimit.Result.eu_operate_name,
            }))
        }
    },[dataLimit,isSuccessLimit])
    const [openEdit, setOpenEdit] = useState(false);
    const editForm = useRef()
    const [submitUpdateSave] = usePurviewSettingUpdateSaveMutation()
    
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        console.log(infoEdit.eu_esv_id);
        submitUpdateSave({
            eu_id:editId,
            // eu_esv_id:null,
            // eu_esv_name:null,
            // eu_menu_id:null,
            // eu_menu_name:null,
            // eu_operate_id:null,
            // eu_operate_name:null,
            eu_esv_id:infoEdit.eu_esv_id,
            eu_esv_name:infoEdit.eu_esv_name,
            eu_menu_id:infoEdit.eu_menu_id,
            eu_menu_name:infoEdit.eu_menu_name,
            eu_operate_id:infoEdit.eu_operate_id,
            eu_operate_name:infoEdit.eu_operate_name,
        }).then((res) => {
            console.log(res);
            if (res.data.Status === 'success') {
                message.success('修改成功！')   
                setOpenEdit(false)                             
            }
            if (res.data.Status === 'fail') {
                message.error(`修改失败!${res.data.Message}`)
            }
        })
        setOpenEdit(false);
    };
   
    const modalEdit = <Modal
                        open={openEdit}
                        width={800}
                        title={`请设置${infoEdit.eu_name}在培训应用的权限`}
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenEdit(false);}}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields();
                                    onCreateEdit(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                            
                        }}
                    >
                        <Form
                            form={form}
                            layout="horizonal"
                            name="form_client_edit"
                            initialValues={{modifier: 'public',}}
                            labelCol={{ span: 3 }}
                            ref={editForm}
                        >
                            <Divider >应用权限</Divider>
                            <Form.Item  wrapperCol={{span:21}} name='eu_esv_id' extra='勾选后，用户桌面显示应用图标。'>
                                <Checkbox.Group onChange={(e) => {                                    
                                    if(e.length===1){
                                        console.log(infoEdit.eu_esv_id)
                                        let newArrId = infoEdit.eu_esv_id;
                                        newArrId = [...newArrId,'5']
                                        
                                        let newArrName = infoEdit.eu_esv_name
                                        newArrName = [...newArrName,'培训']
                                        setInfoEdit(prevState=>({...prevState,
                                            eu_esv_id:newArrId,
                                            eu_esv_name:newArrName,
                                        }))
                                    }else{
                                        setInfoEdit(prevState=>({...prevState,
                                            eu_esv_id:infoEdit.eu_esv_id.filter(item => item !== '5'),
                                            eu_esv_name:infoEdit.eu_esv_name.filter(item => item !== '培训'),
                                        }))
                                    }
                                }}>
                                    <Checkbox value='5'>允许使用</Checkbox>                                    
                                </Checkbox.Group>
                            </Form.Item>                         
                            <Divider >菜单权限</Divider>
                            <Form.Item wrapperCol={{span:21}}  extra='勾选后，用户显示相应菜单。' name='eu_menu_id'>
                                <Checkbox.Group onChange={(e) => {
                                    
                                    let removeArr = ['51', '52','53','54'];
                                    const filteredArr = infoEdit.eu_menu_id.filter(item => !removeArr.includes(item))
                                    let newArrId = filteredArr.concat(e);
                                    setInfoEdit(prevState=>({...prevState,eu_menu_id:newArrId}))
                                }}>
                                    <Checkbox value='51'>培训计划</Checkbox>
                                    <Checkbox value='52'>我参加的</Checkbox>
                                    <Checkbox value='53'>我负责的</Checkbox>
                                    <Checkbox value='54'>设置</Checkbox>
                                </Checkbox.Group>
                            </Form.Item>
                            <Divider >操作权限</Divider>
                            <Form.Item wrapperCol={{span:21}}>
                                <Checkbox.Group>
                                    <Space>                                        
                                        <Checkbox value='512'>修改培训计划</Checkbox>                                    
                                    </Space>
                                </Checkbox.Group>
                            </Form.Item>
                        </Form>
                    </Modal>

    
    //用户列表-----------------------------------------------------------------------------------------------------------------------------用户列表
    
    
    const {data,isSuccess} = useAllDeptUserSearchQuery({e_id:localStorage.getItem('e_id'),eu_state:'1',action:'search'})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                //console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {eu_id:key,eu_name,eu_role,eu_esv_id,eu_menu_id,eu_ba_phone} = item;
                    return item = {key,eu_name,eu_role,eu_esv_id,eu_menu_id,eu_ba_phone}
                })
                const filteredArray = newArr.filter(item => item.eu_role === '1');                                
                setList(filteredArray)
            }else{
                setList()
            }
            
        }
    }, [data, isSuccess])  
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'type',
            title: '姓名',
            render:(_,record) => {
                return <>
                    <div>{record.eu_name}</div>
                    <div>{record.eu_ba_phone}</div>
                </>
            }
        },     
        {
            key: 'eu_esv_id',
            title: '应用权限',
            dataIndex: 'eu_esv_id',
            render:(text) => {
                if(text){
                    if(text.includes('5')){
                        return <>允许使用</>
                    }else{
                        return <>禁止</>
                    }
                }else{
                    return <>禁止</>
                }
            }
        },                    
        {
            key: 'eu_menu_id',
            title: '菜单权限',
            dataIndex: 'eu_menu_id',
            render:(text) => {                
                if(text){
                    const newArr = MenuName.map((item) => {
                        if(text.includes(item.id)){
                            return <div>{item.name}</div>
                        }else{
                            return<></>
                        }                    
                    })
                    return newArr
                }else{
                    return <></>
                }          
            }
        },                    
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                if(record.ewptr_evaluate_uname){
                    return <>                   
                    <div>修改时间：{record.ewptr_evaluate_ctime + " " + record.ewptr_evaluate_uname.replace(/"/g,'')}</div>
                </>
                }
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>                        
                        <a onClick={() => {
                            setOpenEdit(true); 
                            setEditId(record.key);
                            setInfoEdit(prevState=>({...prevState,eu_name:record.eu_name}))                                                       
                        }}>设置权限</a>                   
                    </Space>
                </>
            }
        },
    ]
    
    return (
        <Main topActive='TrainSetting' app='train'>
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table> 
            {modalEdit}           
        </Main>
    )
}

export default TrainLimits