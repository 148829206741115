import React,{useState,useEffect,}from 'react'
import Main from '../../cpn/Main'
import { Table,Button,Space,} from 'antd';
import { useAppSynthesisListQuery,} from '../../store/ReactWebSiteApi';
import { useSearchParams } from 'react-router-dom';
import AppCourseEdit from './AppCourseEdit';
import AppCoursePreview from './AppCoursePreview';
import AppCourseAdd from './AppCourseAdd';
import AppCourseDel from './AppCourseDel';
import { appCourseType } from './AppCourseType';
function AppCourse() {      
    const [param] = useSearchParams(); 
    
    const {data, isSuccess} = useAppSynthesisListQuery({esvs_esv_id:param.get('id'),eu_id:localStorage.getItem('eu_id')},{refetchOnMountOrArgChange:1});
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if (isSuccess) {
            console.log(data);
            if(data.Status==='success'){
                
                const newArr = data.Result.map(item => {
                    const {esvs_id:key,esvs_name,esvs_type_id,esvs_content,esvs_uname,esvs_ctime,esvs_image2_name,esvs_image_name,} = item;
                    return item = {key,esvs_name,esvs_type_id,esvs_content,esvs_uname,esvs_ctime,esvs_image2_name,esvs_image_name,}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.esvs_ctime;
                    let bTimeString = b.esvs_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])  
    const columns = [        
        {
            key: 'type',
            title: '类型',            
            dataIndex: 'esvs_type_id',
            render:(text) => {
                let result
                for(let i = 0;i<appCourseType.length;i++){
                    if(appCourseType[i].value===text){
                        result = appCourseType[i].label
                    }
                }
                return result
            }
        },
        {
            key: 'name',
            title: '教程标题',            
            dataIndex: 'esvs_name',
        },
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },     
        {
            key: 'time',
            title: '操作记录',
            render: (_,record) => {
                return <>{record.esvs_ctime + record.esvs_uname}</>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space>    
                        <AppCoursePreview esvs_name={record.esvs_name} esvs_content={record.esvs_content} /> 
                        <AppCourseEdit esvs_id={record.key}  esvs_esv_id={param.get('id')}/>
                        <AppCourseDel esvs_id={record.key} />
                    </Space>
                </>
            }
        },
    ]   
    return (
        <Main t1={param.get('name')} t2='教程'>
            <Space>
                <Button type='primary' ><AppCourseAdd esv_id={param.get('id')}/></Button>
            </Space> 
            <Table 
                columns={columns} 
                dataSource={list} 
                pagination={{showSizeChanger:true,defaultPageSize:'50',showTotal:(total) => `共${total}条`}} 
                style={{marginTop:"20px"}}
            />
        </Main>
    )
}

export default AppCourse