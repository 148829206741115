import React,{useState}from 'react'
import { Modal,message,Form,Input,Button} from 'antd';
import { useGoodsAddMutation,  } from '../../store/ReactWebSiteApi';
import GetGoodsType from './GetGoodsType';
import GetDept from '../../cpn/GetDept';
function GoodsAdd(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({
        eg_content:'',
    });
    const [submitAdd] = useGoodsAddMutation()
    return (
        <>
            <Button  onClick={() => {setOpen(true)}} type='primary'>新增</Button>
            <Modal
                open={open}
                title='新增产品'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        eg_dept_id:info.eg_dept_id,
                        eg_name:info.eg_name,
                        eg_uid:localStorage.getItem('eu_id'),
                        eg_uname:localStorage.getItem('eu_name'),
                        eg_e_id:localStorage.getItem('e_id'),
                        eg_egt_id:info.eg_egt_id,
                        eg_egt_name:info.eg_egt_name,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                >
                    <GetDept 
                        label='部门' 
                        required
                        wrapperCol={{span:5}}
                        getDept={(e) => {setInfo(prevState=>({...prevState,eg_dept_id:e,}))}}
                    />                    
                    <GetGoodsType
                        getGoodsType={(e,f) => {
                            setInfo(prevState=>({...prevState,eg_egt_id:e,eg_egt_name:f}))
                        }}
                        label='产品类型'
                        egt_esv_id={props.eg_esv_id}
                        wrapperCol={{span:15}}
                        style={{width:"200px"}}
                        required
                    />                            
                    <Form.Item name="name" label='产品名称' wrapperCol={{span:10}} required>
                        <Input  onChange={(e) => {setInfo(prevState=>({...prevState,eg_name:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default GoodsAdd