import {Form,InputNumber, message, Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDijieBaoJiaDanGuiGeUpdateQuery, useDijieBaoJiaDanGuiGeUpdateSaveMutation } from '../../../../store/ReactWebSiteApi';
import CustomCrowd from '../temp/crowd/CustomCrowd';
function FileTypeEdit(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const {data,isSuccess} = useDijieBaoJiaDanGuiGeUpdateQuery({bjdgg_id:props.bjdgg_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                editForm.current.setFieldsValue({
                    bjdgg_name:data.Result.bjdgg_name,
                    bjdgg_num:data.Result.bjdgg_num
                })
                setInfo(prevState=>({...prevState,
                    bjdgg_name:data.Result.bjdgg_name,
                    bjdgg_num:data.Result.bjdgg_num, 
                }))
            }
        }
    },[data,isSuccess])
    const [submitAdd] = useDijieBaoJiaDanGuiGeUpdateSaveMutation()
    const [info,setInfo] = useState({bjdgg_name:''});
    const editForm = useRef();
    useEffect(() => {
        if(editForm.current){
            editForm.current.setFieldsValue({        
                bjdgg_num:info.bjdgg_num,               
            })
        }
    },[info.bjdgg_name,info.bjdgg_num])
     //模版
     const [openTemplate,setOpenTemplate] = useState(false)
     const getTemplate = <>
             <Form.Item  label='请选择' required>
                 <a onClick={() => {setOpenTemplate(true)}}>人群类型模板</a>               
             </Form.Item>        
             <Modal
                 open={openTemplate}
                 okText="提交"
                 cancelText="取消"
                 onCancel={() => {setOpenTemplate(false);}}
                 onOk={() => {}}
                 footer={null}
                 width={1000}
                 title='人群类型模板'
             >
                 <CustomCrowd
                     get={true}
                     ltbjd_dept_id={props.ltbjd_dept_id}
                     getType={(e) => {
                         setInfo(prevState=>({
                             ...prevState,
                             bjdgg_name:e.bjdgg_name,
                         }))
                     }}
                     getOpen={(e) => {setOpenTemplate(e)}}
                 />
             </Modal>
         </>
    return (
        <>
            <a onClick={() => {setOpen(true)}}>修改</a>
            <Modal
                open={open}
                width={800}
                title='修改人群类型数量'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        bjdgg_bjd_id:props.bjdgg_bjd_id,
                        bjdgg_name:info.bjdgg_name,
                        bjdgg_num:info.bjdgg_num,
                        bjdgg_update_uid:localStorage.getItem('eu_id'),
                        bjdgg_update_uname:localStorage.getItem('eu_name'),
                        bjdgg_id:props.bjdgg_id,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')                              
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                    style={{marginTop:'10px'}}
                    ref={editForm}
                >
                    {getTemplate}
                    <Form.Item label='人群类型' wrapperCol={{span:20}} >
                        {info.bjdgg_name}
                    </Form.Item>
                    <Form.Item label='数量' wrapperCol={{span:20}} required >
                        <InputNumber value={info.bjdgg_num} onChange={(e) => {setInfo(prevState=>({...prevState,bjdgg_num:e}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default FileTypeEdit