import { Button, Flex,Modal,Descriptions,message} from 'antd'
import React,{useState}from 'react'
import {EditOutlined,EyeOutlined,CloseOutlined,DeleteOutlined,ShareAltOutlined} from '@ant-design/icons';

function ModalCheck(props) {
    const [open, setOpen] = useState(false);
    const [isEdit,setIsEdit] = useState(props.isEdit)    
    let Footer = [
        <Button icon={<ShareAltOutlined />}  
            key='1'  
            size='large' 
            type='primary' 
            onClick={async () => {
                try {
                    await navigator.clipboard.writeText(props.shareText);                                      
                    // 可以添加一些UI状态反馈，例如显示"已复制"
                    Modal.info({
                        title: '以下内容已复制，去好友微信粘贴吧！',
                        content: ( <div>{props.shareText}</div>),
                        onOk() {},
                    });
                }   catch (err) {
                    message.error('分享链接复制失败',);
                }                                  
            }}
        >分享</Button>,
        <Button 
            icon={isEdit?<EyeOutlined />:<EditOutlined />} 
            key='2' 
            onClick={() => {
                if(isEdit){
                    setIsEdit(false);
                    props.getIsEdit(false)
                }else{
                    setIsEdit(true);
                    props.getIsEdit(true)
                }
            }}
            disabled={props.editBtnDisabled}  
            type='primary' 
            size='large'
        >{isEdit?'预览':'编辑'}</Button>,
        <Button 
            icon={<DeleteOutlined />}  
            key='3' 
            size='large' 
            type='primary'
            disabled={props.deleteBtnDisabled}  
        >删除</Button>,
        <Button 
            icon={<CloseOutlined />}  
            key='4' 
            size='large' 
            type='primary' 
            onClick={() => {setOpen(false)}}
        >关闭</Button>,
    ]
    if(props.footer){
        Footer.push(props.footer)
    }
    if(props.shareBtnHide){
        Footer.splice(0, 1)
    }
    if(props.editBtnHide){
        Footer.splice(0, 1)
    }
    if(props.deleteBtnHide){
        Footer.splice(0, 1)
    }
    return (
        <>
            <a onClick={() => {
                props.getOpen(true)
                setOpen(true);
                }} >查看</a>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);props.getOpen(false)}}
                width={props.width}
                footer={Footer}
            >
                <Flex justify='center' vertical align='center'>
                    <h2>{props.firmName}</h2>
                    <h2>{props.title}</h2>
                </Flex>
                <Descriptions 
                    bordered
                    size='small' 
                    items={props.Descriptions} 
                />
                <>
                    {props.children}
                </>
            </Modal>
        </>
        
    )
}

export default ModalCheck