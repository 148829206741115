import { DatePicker, Form, Input, InputNumber, message, Modal, } from 'antd';
import React, { useState } from 'react'
import GuideForm from '../../../../guide/GuideForm';
import UploadOne from '../../../../../cpn/UploadOne';
import { useDaoyoujihuadanAddMutation } from '../../../../../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
const {TextArea} = Input
function GuidePlanAdd(props) {
    const navi = useNavigate()
    const [open,setOpen] = useState(false)
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        dyjhd_tgu_name:'',
    })
    const [add] = useDaoyoujihuadanAddMutation()
    return (
        <>
            <a onClick={() => {setOpen(true);}}>新增导游计划单</a>
            <Modal
                title='新增导游计划单'
                onOk={(e) => {    
                    add({
                        "dyjhd_daofufei" : info.dyjhd_daofufei,
                        "dyjhd_daofufeishuoming" : info.dyjhd_daofufeishuoming,
                        "dyjhd_fujian_name" : info.dyjhd_fujian_name,
                        "dyjhd_jietuandidian" :info.dyjhd_jietuandidian,
                        "dyjhd_jietuanshijian" :info.dyjhd_jietuanshijian,
                        "dyjhd_jietuanshuoming" :info.dyjhd_jietuanshuoming,
                        "dyjhd_lth_id" : props.dyjhd_lth_id,
                        "dyjhd_lth_num" : props.dyjhd_lth_num,
                        "dyjhd_songtuandidian" : info.dyjhd_songtuandidian,
                        "dyjhd_songtuanshijian" :info.dyjhd_songtuanshijian,
                        "dyjhd_tgu_id" : info.dyjhd_tgu_id,
                        "dyjhd_tgu_name" : info.dyjhd_tgu_name,
                        "dyjhd_uid" : localStorage.getItem('eu_id'),
                        "dyjhd_uname" :  localStorage.getItem('eu_name'),
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！') 
                            navi('/CustomGroup')
                            setOpen(false)                                                 
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })               
                }}
                open={open}
                onCancel={() => {setOpen(false)}}
            >
               <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"                    
                    labelCol={{ span: 6 }}
                >                                                              
                    <Form.Item name="fenche" label='分车序号' wrapperCol={{span:10}} required>
                        <InputNumber 
                        />
                    </Form.Item>
                    <GuideForm 
                        required
                        tgu_name={info.dyjhd_tgu_name}
                        getGuide={(e) => {
                            setInfo(prevState=>({
                                ...prevState,
                                dyjhd_tgu_id:e.tgu_id,
                                dyjhd_tgu_name:e.tgu_name
                            }))
                        }}
                    />
                    <Form.Item 
                        label='接团时间' 
                        name="s_time" 
                        wrapperCol={{span:15}} 
                    >
                        <DatePicker
                            showTime
                            format="YYYY-MM-DD HH:mm"
                            onChange={(e) => {
                                console.log(e);
                                setInfo(prevState=>({...prevState,dyjhd_jietuanshijian:dayjs(e).format('YYYY-MM-DD HH:mm')}))
                            }}  
                        />
                    </Form.Item>
                    <Form.Item 
                        label='接团地点' 
                        name="s_site" 
                        wrapperCol={{span:15}} 
                    >
                         <Input
                             onChange={(e) => {
                                setInfo(prevState=>({...prevState,dyjhd_songtuandidian:e.target.value}))
                            }}  
                        />
                    </Form.Item>
                    <Form.Item 
                        label='送团时间' 
                        name="e_time" 
                        wrapperCol={{span:15}} 
                    >
                         <DatePicker
                            showTime
                            format="YYYY-MM-DD HH:mm"
                            onChange={(e) => {
                                console.log(e);
                                setInfo(prevState=>({...prevState,dyjhd_songtuanshijian:dayjs(e).format('YYYY-MM-DD HH:mm')}))
                            }}  
                        />
                    </Form.Item>
                    <Form.Item 
                        label='送团地点' 
                        name="e_site" 
                        wrapperCol={{span:15}} 
                    >
                        <Input
                             onChange={(e) => {
                                setInfo(prevState=>({...prevState,dyjhd_jietuandidian:e.target.value}))
                            }}  
                        />
                    </Form.Item>
                    <Form.Item 
                        label='接团说明' 
                        name="remark" 
                        wrapperCol={{span:15}}                         
                    >
                        <TextArea 
                            autoSize={{
                                minRows: 4,                                
                            }}
                            onChange={(e) => {
                                setInfo(prevState=>({...prevState,dyjhd_jietuanshuoming:e.target.value}))
                            }}   
                        />
                    </Form.Item>
                    <Form.Item 
                        label='导服费' 
                        name="pay" 
                        wrapperCol={{span:15}} 
                    >
                        <InputNumber     
                          onChange={(e) => {
                            setInfo(prevState=>({...prevState,dyjhd_daofufei:e}))
                        }}                          
                        />
                    </Form.Item>
                    <Form.Item 
                        label='导服费说明' 
                        name="payRemark" 
                        wrapperCol={{span:15}} 
                    >
                        <Input
                            onChange={(e) => {
                                setInfo(prevState=>({...prevState,dyjhd_daofufeishuoming:e.target.value}))
                            }}                       
                        />
                    </Form.Item>
                    <UploadOne 
                            name='pic'
                            label='附件' 
                            fileStr={(e) => {setInfo(prevsState=>({...prevsState,dyjhd_fujian_name:e}))}}
                        />
                </Form>
            </Modal>
        </>
    )
}

export default GuidePlanAdd