import React,{useEffect, useRef, useState}from 'react'
import { Modal,message,Form, InputNumber, Input, Radio} from 'antd';
import { useDijieBaoJiaDanDaoYouFeiYongUpdateQuery, useDijieBaoJiaDanDaoYouFeiYongUpdateSaveMutation,   } from '../../../../../store/ReactWebSiteApi';
import { localGuidePayType } from '../../customType';
const {TextArea} = Input
function OpFileGuidePayEdit(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({
        bjddyfy_name:'',
        bjddyfy_content:'',
    });
    const editForm = useRef()
    //获取
    const {data,isSuccess} = useDijieBaoJiaDanDaoYouFeiYongUpdateQuery({bjddyfy_id:props.bjddyfy_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){               
                editForm.current.setFieldsValue({
                    bjddyfy_amount:data.Result.bjddyfy_amount,                         
                    bjddyfy_type:data.Result.bjddyfy_type,                   
                    bjddyfy_content:data.Result.bjddyfy_content,  
                })                                   
                setInfo(prevsState=>({...prevsState,                  
                    bjddyfy_amount:data.Result.bjddyfy_amount,                         
                    bjddyfy_type:data.Result.bjddyfy_type,                   
                    bjddyfy_content:data.Result.bjddyfy_content,           
                }))
            }
        }
    },[data,isSuccess,])
    const [submitAdd] = useDijieBaoJiaDanDaoYouFeiYongUpdateSaveMutation()

    return (
        <>
            <a onClick={() => {
                setInfo(prevState=>({
                    ...prevState,                   
                    bjddyfy_name:'',
                    bjddyfy_content:'',
                }))
                setOpen(true)
                }} >修改</a>
            <Modal
                open={open}
                title='修改导游费用'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={800}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        bjddyfy_bjd_id:props.bjddyfy_bjd_id,
                        bjddyfy_id:props.bjddyfy_id,
                        bjddyfy_type:info.bjddyfy_type,
                        bjddyfy_amount:info.bjddyfy_amount,
                        bjddyfy_content:info.bjddyfy_content,
                        bjddyfy_update_uid:localStorage.getItem('eu_id'),
                        bjddyfy_update_uname:localStorage.getItem('eu_name'),
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')                              
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 4 }}
                    ref={editForm}
                >                                           
                    <Form.Item 
                        name="bjddyfy_type" 
                        label='类型' 
                        wrapperCol={{span:20}} 
                        required 
                    >
                        <Radio.Group 
                            optionType="button" 
                            options={localGuidePayType}
                            onChange={(e) => {
                                setInfo(prevState=>({
                                    ...prevState,
                                    bjddyfy_type:e.target.value
                                }))}}
                        />
                    </Form.Item>
                    <Form.Item label='金额' wrapperCol={{span:20}} name='bjddyfy_amount' >
                        <InputNumber 
                            onChange={(e) => {
                                setInfo(prevState=>({
                                    ...prevState,
                                    bjddyfy_amount:e
                                }))}}
                        />
                    </Form.Item>
                    <Form.Item label='结算方式' wrapperCol={{span:20}} name='bjddyfy_content'>
                        <TextArea  
                            onChange={(e) => {
                                setInfo(prevState=>({
                                    ...prevState,
                                    bjddyfy_content:e.target.value
                                }))}}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default OpFileGuidePayEdit