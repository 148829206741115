import React,{useState,useEffect,useRef}from 'react'
import { Table,Button,Space,Modal,message,Form,Input} from 'antd';
import { useBillGoodsDelMutation, useBillGoodsListQuery,useBillGoodsAddMutation, useBillGoodsUpdateSaveMutation,useBillGoodsUpdateQuery} from '../../store/ReactWebSiteFinanceApi';
import MainKeeper from '../MainKeeper';
function Business() {
    //新增业务----------------------------------------------------------------------------------------------------------------------------------------新增业务
    const [form] = Form.useForm();
    const [openAdd, setOpenAdd] = useState(false);
    const onCreateAdd = (values) => {
        console.log('Received values of form: ', values);
        setOpenAdd(false);
    };
    const [newGoods,setNewGoods] = useState();
    const [goodsAdd] = useBillGoodsAddMutation()
    //修改业务----------------------------------------------------------------------------------------------------------------------------------------修改业务
    const [openEdit, setOpenEdit] = useState(false);
    const [goodsId,setGoodsId] = useState()
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpenEdit(false);
    };
    const [editGoods,setEditGoods] = useState();
    const editForm = useRef()
    const {data:dataEdit,isSuccess:isSuccessEdit} = useBillGoodsUpdateQuery({efg_id:goodsId})
    useEffect(() => {
        if(isSuccessEdit){
            if(dataEdit.Status==='success'){
                editForm.current.setFieldsValue({
                    efg_name:dataEdit.Result.efg_name,
                    efg_content:dataEdit.Result.efg_content,
                })
                setEditGoods(prevState=>({...prevState,
                    efg_name:dataEdit.Result.efg_name,
                    efg_content:dataEdit.Result.efg_content,                  
                }))
            }
        }
    },[dataEdit,isSuccessEdit])
    const [goodsEdit] = useBillGoodsUpdateSaveMutation()
    //删除业务----------------------------------------------------------------------------------------------------------------------------------------删除业务//
    const {confirm} = Modal; 
    const [delBusiness] = useBillGoodsDelMutation()
    //业务列表----------------------------------------------------------------------------------------------------------------------------------------业务列表//
    
    const {data, isSuccess} = useBillGoodsListQuery({efg_e_id:localStorage.getItem('e_id')});
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if (isSuccess) {
            //console.log(data);
            if(data.Status==='success'){
                
                const newArr = data.Result.map(item => {
                    const {efg_id:key,efg_name,efg_num,efg_content,efg_uname,efg_ctime,finance_num} = item;
                    return item = {key,efg_name,efg_num,efg_content,efg_uname,efg_ctime,finance_num}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.efg_ctime;
                    let bTimeString = b.efg_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    const columns = [
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        },
        {
            key: 'type',
            title: '业务名称',            
            dataIndex: 'efg_name',
        },
        {
            key: 'room',
            title: '备注',            
            dataIndex: 'efg_content',
        },
        {
            key: 'num',
            title: '收支笔数',            
            dataIndex: 'finance_num',
        },
        {
            key: 'time',
            title: '创建/修改时间',
            render: (_,record) => {
                return <>
                    <div>新建时间：{record.efg_ctime + record.efg_uname}</div>
                    <div>修改时间：</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>                        
                        <a onClick={() => {setGoodsId(record.key);setOpenEdit(true)}}>修改</a>
                        {record.finance_num === '0' && <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '删除后不能恢复',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    delBusiness({efg_id:record.key}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>}
                    </Space>
                </>
            }
        },
    ]
    
    

      
    return (
        <MainKeeper topActive='Business'>
            <div>
                <Button type='primary' onClick={() => {setOpenAdd(true);}}>新增业务</Button>
            </div>
            {/* 新增业务         */}
            <Modal
                open={openAdd}
                title='新增业务'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenAdd(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateAdd(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    goodsAdd({
                        efg_e_id:localStorage.getItem('e_id'),
                        efg_dept_id:localStorage.getItem('ed_id'),
                        efg_uid:localStorage.getItem('eu_id'),
                        efg_uname:localStorage.getItem('eu_name'),
                        efg_name:newGoods.efg_name,
                        efg_content:newGoods.efg_content,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            setOpenAdd(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                >
                    <Form.Item name="name" label='业务名称' wrapperCol={{span:10}} required>
                        <Input  onChange={(e) => {setNewGoods(prevState=>({...prevState,efg_name:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item name="content" label='业务备注' wrapperCol={{span:15}}>
                        <Input  onChange={(e) => {setNewGoods(prevState=>({...prevState,efg_content:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
            {/* 修改业务         */}
            <Modal
                open={openEdit}
                title='修改业务'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenEdit(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateEdit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    goodsEdit({
                        efg_name:editGoods.efg_name,
                        efg_update_uid:localStorage.getItem('eu_id'),
                        efg_update_uname:localStorage.getItem('eu_name'),
                        efg_content:editGoods.efg_content,
                        efg_id:goodsId,
                        efg_e_id:localStorage.getItem('e_id'),
                        efg_dept_id:localStorage.getItem('ed_id'),
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')   
                            setOpenEdit(false)                             
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                    ref={editForm}
                >
                    <Form.Item name="efg_name" label='业务名称' wrapperCol={{span:10}} required>
                        <Input  onChange={(e) => {setEditGoods(prevState=>({...prevState,efg_name:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item name="efg_content" label='业务备注' wrapperCol={{span:15}}>
                        <Input  onChange={(e) => {setEditGoods(prevState=>({...prevState,efg_content:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal> 
            <Table columns={columns} style={{marginTop:'20px'}} dataSource={list}></Table>
        </MainKeeper>
    )
}

export default Business