import React  from 'react'
import Main from '../cpn/Main'
import {Row } from 'antd'
import Folder from '../cpn/Folder'
function TaIndex() {
    return (
        <Main >
            <Row>
            {(localStorage.getItem('eu_name')==='"林未来"' ||localStorage.getItem('eu_name')==='"柏菁"'||localStorage.getItem('eu_name')==='"张宇"') && <Folder title='地接' link='/LocalIndex'/>}                      
            {(localStorage.getItem('eu_name')==='"林未来"' ||localStorage.getItem('eu_name')==='"柏菁"'||localStorage.getItem('eu_name')==='"张宇"') && <Folder title='组团' link='/OrganIndex'/>}                      
                
                       
                {(localStorage.getItem('eu_role')==='2' ||(localStorage.getItem('ess_menu_id').includes('47'))) && <Folder title='财务' link='/TaFinanceIndex'/>}                     
                {(localStorage.getItem('eu_role')==='2' ||(localStorage.getItem('ess_menu_id').includes('48'))) && <Folder title='人事' link='/TaIndexPersonnel'/>}     
                {localStorage.getItem('eu_role') ==='2' && <Folder title='系统设置' link='/SetIndex'/>}
                <Folder title='我的' link='/MineIndex?esv_id=8'/>
            </Row>
        </Main>
    )
}

export default TaIndex