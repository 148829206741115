import React,{useState,useEffect} from 'react'
import Main from '../../cpn/Main'
import { Table,DatePicker,Space} from 'antd'
import { useEntRegisterRenWuListQuery,} from '../../store/ReactWebSiteApi';
import dayjs from 'dayjs';
const {RangePicker} = DatePicker
function FirmSumTask() {
    const [query,setQuery] = useState({
        day_s:dayjs().startOf('month').format('YYYY-MM-DD'),
        day_e:dayjs().endOf('month').format('YYYY-MM-DD'),
        e_id:localStorage.getItem('e_id')
    })
    
    const {data,isSuccess} = useEntRegisterRenWuListQuery(query,{refetchOnMountOrArgChange:1})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {e_id:key,e_name,num,} = item;
                    return item = {key,e_name,num,}
                })                
                setList(newArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])    
    
    const columns = [
        {
            key: 'id',
            title: '公司',
            dataIndex: 'e_name'
        },         
        {
            key: 'ar',
            title: '任务数量',
            dataIndex: 'num',
        },                                  
    ]
    return (
        <Main t1='商城' n1='/GfIndexMall' t2='企业' n2='/FirmIndex' t3='任务汇总'>
            <Space style={{marginBottom:"10px"}}>
                <span>新增日期：</span>
                <RangePicker 
                    onChange={(e) => {
                        if(e){
                            setQuery(prevState=>({...prevState,date_s:dayjs(e[0]).format('YYYY-MM-DD'),date_e:dayjs(e[1]).format('YYYY-MM-DD')})) 
                        }else{
                            setQuery(prevState=>({...prevState,date_s:'',date_e:''}))
                        }
                    }}
                />                
            </Space>
            <Table 
                columns={columns} 
                dataSource={list} 
                summary={(pageData) => {
                    console.log(pageData);
                    let totalincome_num = 0;
                    pageData.forEach(({num, }) => {                        
                        totalincome_num += +num;
                    });
                    return <Table.Summary>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>{(totalincome_num)}</Table.Summary.Cell>
                                   
                                </Table.Summary.Row>
                            </Table.Summary>
                }}
            />
        </Main>
    )
}

export default FirmSumTask